import React, { Component } from "react";
import {
  Radio,
  Select,
  Input,
  ProductSearch,
} from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";
import ItemTable from "./ItemTable";

class BXATP extends Component {
  constructor(props) {
    super(props);
    this.submitHandler = this.submitHandler.bind(this);
    this.validationHandler = this.validationHandler.bind(this);
    this.selectOnEnter = this.selectOnEnter.bind(this);
  }

  selectOnEnter(e) {
    e && e.preventDefault();
  }
  validationHandler(parent) {
    let validations = JSON.parse(JSON.stringify(parent.state.validations));
    let formData = JSON.parse(JSON.stringify(parent.state.values));
    const comboDiscount = formData.itemDiscountType === "COMBO_DISCOUNT";
    const entityType = formData.entityType.toLowerCase();
    if (comboDiscount) {
      (validations[entityType] || []).forEach((validation) => {
        delete validation.t;
        delete validation.v;
      });
    } else {
      delete validations.rule;
    }
    parent.setState({
      validations,
    });
  }

  submitHandler(data) {
    let formData = Object.assign({}, data);
    const comboDiscount = formData.itemDiscountType === "COMBO_DISCOUNT";
    const entityType = formData.entityType.toLowerCase();
    let buy = {};
    data.offerType = data.type;
    formData[entityType].forEach((item) => {
      let res = { q: Number(item.q) };
      if (!comboDiscount) {
        res.t = item.t;
        res.v = Number(item.v);
      }
      buy[item.id] = res;
    });
    let total = comboDiscount && formData.rule.total;
    formData.rule = {
      buy: buy,
      // entity: {
      //   type: formData.entityType === 'PRODUCT' ? 'VARIANT' : formData.entityType,
      //   id: formData.entityType === 'PRODUCT' ? null : 0
      // },
      limit: formData.rule ? Number(formData.rule.limit) : null,
    };
    if (comboDiscount) {
      total.v = Number(total.v);
      formData.rule.total = total;
    }
    delete formData.product;
    delete formData.brand;
    delete formData.category;
    return formData;
  }
  render() {
    let {
      entityType,
      discountTypes,
      itemDiscountTypes,
      _this,
      hasOfferStarted,
    } = this.props;
    const { values } = _this.state;
    const { itemDiscountType } = _this.state.values;
    entityType = this.props.entityTypeMap[entityType];
    const comboDiscount = itemDiscountType === "COMBO_DISCOUNT";
    const items = values[entityType];
    const itemsLength = items ? items.length : 0;
    return (
      <div className="offers-form">
        <div className="offer-fields">
          <Radio
            name="discount-type-main"
            label={getMessage("offer.main.discount.type")}
            placeholder={getMessage("offer.main.discouont.type.placeholder")}
            options={itemDiscountTypes}
            {..._this.generateStateMappers({
              stateKeys: ["itemDiscountType"],
              loseEmphasisOnFill: true,
            })}
            readOnly={hasOfferStarted}
          />
          {comboDiscount && (
            <React.Fragment>
              <Select
                name="discount-type"
                required
                label={getMessage("offer.discounttype")}
                placeholder={getMessage("offer.discounttype.placeholder")}
                options={discountTypes}
                {..._this.generateStateMappers({
                  stateKeys: ["rule", "total", "t"],
                  loseEmphasisOnFill: true,
                })}
                onChange={_this.handleDiscountChange}
                readOnly={hasOfferStarted}
              />
              <Input
                name="discount-value"
                type="number"
                step={0.01}
                min={0}
                max={
                  values &&
                  values.rule &&
                  values.rule.total &&
                  values.rule.total.t === "PERCENT_OFF"
                    ? 100
                    : Number.MAX_SAFE_INTEGER
                }
                required
                label={getMessage("offer.discountvalue")}
                placeholder={getMessage("offer.discountvalue.placeholder")}
                {..._this.generateStateMappers({
                  stateKeys: ["rule", "total", "v"],
                  loseEmphasisOnFill: true,
                })}
                readOnly={hasOfferStarted}
              />
            </React.Fragment>
          )}
        </div>
        {entityType === "product" && (
          <ProductSearch
            required
            className="product-searchable-max"
            key={`item-searchable-pdt`}
            name={`item-searchable-pdt`}
            label={getMessage(`offer.${entityType}`)}
            placeholder={getMessage(`offer.${entityType}.placeholder`)}
            onChange={_this.addItem}
            readOnly={hasOfferStarted}
            selectOnEnter={this.selectOnEnter}
          />
        )}
        <div>
          {itemsLength ? (
            <ItemTable
              comboDiscount={comboDiscount}
              entityType={entityType}
              items={items}
              discountTypes={discountTypes}
              _this={_this}
              hasOfferStarted={hasOfferStarted}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default BXATP;
