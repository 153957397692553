import React, { forwardRef, useImperativeHandle, useState } from "react";
import { getMessage } from "../../../../lib/translator";
import { Dialog, Popup } from "../../../../components/Popup";
import API from "../../../../lib/api";
import { getSession } from "../../../../lib/auth";
import CreateNewPackageForm from "../../../settings/OrderPackageConfig/Table/CreateNewPackageForm.js";

const CreatePackage = forwardRef((props, ref) => {
  const organizationId = getSession()?.organization?.id?.toString?.();
  const [showPackagePopup, setShowPackagePopup] = useState(false);
  const [createPackageError, setCreatePackageError] = useState(false);
  const [createPackageSuccess, setCreatePackageSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [deletePackage, setDeletePackage] = useState(null);

  useImperativeHandle(ref, () => ({
    childMethod() {
      handleClickShowPackage();
    },
  }));

  const handleCloseCreatePackage = () => {
    setShowPackagePopup(false);
  };
  const handleClickShowPackage = () => {
    setShowPackagePopup(true);
  };

  const handleSubmitAddPackage = (formData) => {
    setSubmitting(true);
    const createPackageAPI = new API({ url: "/order-service/package" });
    createPackageAPI
      .post({ organizationId, ...formData })
      .then((response) => {
        setSubmitting(false);
        handleCloseCreatePackage();
        setCreatePackageSuccess(true);
        props.getPackageConfiguration(formData?.type);
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        handleCloseCreatePackage();
        setCreatePackageError(true);
      });
  };

  const handleSubmitDeletePackage = () => {
    const deletePackageAPI = new API({
      url: `/order-service/package/${deletePackage}`,
    });
    deletePackageAPI
      .delete({ organizationId })
      .then((response) => {
        setDeletePackage(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const dismissPackageError = () => {
    setCreatePackageError(false);
  };
  const dismissPackageSuccess = () => {
    setCreatePackageSuccess(false);
  };

  const dismissDeletePackage = () => {
    setDeletePackage(null);
  };

  const errorDialogTitle = getMessage(
    "orderPackageConfig.createPackage.errorMsg"
  );
  const errorDialogInfo = getMessage(
    "orderPackageConfig.createPackage.errorMsg.dsc"
  );

  const successDialogTitle = getMessage(
    "orderPackageConfig.createPackage.successMsg"
  );
  const successDialogInfo = getMessage(
    "orderPackageConfig.createPackage.successMsg.dsc"
  );

  return (
    <>
      <Popup
        show={showPackagePopup}
        close={handleCloseCreatePackage}
        heading={getMessage("orderPackageConfig.createNew.heading")}
      >
        <CreateNewPackageForm
          onSubmit={handleSubmitAddPackage}
          submitting={submitting}
        />
      </Popup>
      <Dialog
        title={errorDialogTitle}
        information={errorDialogInfo}
        show={createPackageError}
        close={dismissPackageError}
        closeText={getMessage("ok.text")}
      />
      <Dialog
        className="success"
        title={successDialogTitle}
        information={successDialogInfo}
        show={createPackageSuccess}
        close={dismissPackageSuccess}
        closeText={getMessage("ok.text")}
      />

      <Dialog
        title={getMessage("orderPackageConfig.deletePackage.heading")}
        information={getMessage("orderPackageConfig.deletePackage.subHeading")}
        show={deletePackage}
        close={dismissDeletePackage}
        closeText={getMessage("logistics.config.no")}
        onOk={handleSubmitDeletePackage}
        okText={getMessage("delete.text")}
      />
    </>
  );
});

export default CreatePackage;
