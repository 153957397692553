import React, { Component } from "react";
import { Row, Cell } from "../../../../components/Table";
import Image from "../../../../components/Image";
import { DropDown, DropDownItem, ICONS } from "../../../../components/DropDown";
import { Link } from "react-router-dom";
import { getMessage } from "../../../../lib/translator";
import customerImagePlaceholder from "./customer-image-placeholder.svg";
import { getPrintableTime } from "../../../../lib/datetime";
import { hasPermissions } from "../../../../lib/auth/index";
import { Dialog } from "../../../../components/Popup";
import API from "../../../../lib/api";
import { TABLE_ACTIONS } from "../../../../containers/ListingPage";

class CustomerEnableDisableDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      submitting: false,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit() {
    let id = this.props.id;
    let params = {};
    params["status"] = this.props.status === "ENABLED" ? "DISABLED" : "ENABLED";
    await this.props.onAction(TABLE_ACTIONS.UPDATE, { id }, params);
    this.setState({
      showDialog: false,
    });
    this.props.rerender();
  }

  toggleDialog() {
    this.setState((prevState) => {
      return {
        showDialog: !prevState.showDialog,
      };
    });
  }

  render() {
    return (
      <div>
        <div onClick={this.toggleDialog}>
          {this.props.status === "ENABLED"
            ? getMessage("customer.table.actions.disable")
            : getMessage("customer.table.actions.enable")}
        </div>
        <Dialog
          show={this.state.showDialog}
          title={getMessage("customer.reset.password.title")}
          information={
            this.props.status === "ENABLED"
              ? getMessage("customer.table.actions.disable.information")
              : getMessage("customer.table.actions.enable.information")
          }
          onOk={this.onSubmit}
          close={this.toggleDialog}
          closeText={getMessage("customer.reset.password.cancelText")}
          okText={getMessage("customer.reset.password.confirmText")}
        />
      </div>
    );
  }
}
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessDialog: false,
      showPasswordResetDialog: false,
      submitting: false,
    };
    this.showPasswordReset = this.showPasswordReset.bind(this);
    this.closeDialogs = this.closeDialogs.bind(this);
    this.onSend = this.onSend.bind(this);
  }
  onSend() {
    const api = new API({ url: "/customer-service/reset-password" });
    let params = {};
    params["username"] = this.props.email;

    api
      .post(params)
      .then(
        (response) => {
          this.setState({
            showSuccessDialog: true,
            showPasswordResetDialog: false,
          });
        },
        (error) => {
          this.setState({ formError: error.message });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }
  showPasswordReset() {
    this.setState({
      showPasswordResetDialog: true,
    });
  }
  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
    });
  }
  render() {
    return (
      <div>
        <span onClick={this.showPasswordReset}>
          {getMessage("customer.action.reset.password")}
        </span>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("customer.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("customer.save.success.closeText")}
        />
        <Dialog
          show={this.state.showPasswordResetDialog}
          title={getMessage("customer.reset.password.title")}
          information={getMessage("customer.reset.password.message")}
          onOk={() => this.onSend()}
          close={() => this.setState({ showPasswordResetDialog: false })}
          closeText={getMessage("customer.reset.password.cancelText")}
          okText={getMessage("customer.reset.password.confirmText")}
        />
      </div>
    );
  }
}

const TableView = (page, rerender) => {
  return {
    headers: [
      getMessage("customer.table.header.image"),
      getMessage("customer.table.header.name"),
      getMessage("customer.table.header.contact"),
      getMessage("customer.table.header.joinDate"),
      getMessage("customer.table.header.status"),
      getMessage("customer.table.header.actions"),
    ],
    row: ({
      id,
      image,
      name,
      lastName,
      phones,
      emails,
      status,
      joinedTime,
      lastOrderedOn,
      totalOrders,
      totalAmount,
      onAction,
    }) => {
      const [phone] = phones || []; // Only need the first phone
      const [email] = emails || []; // Only need the first email
      return (
        <Row>
          <Cell className="customer-table-image">
            <Image src={image || customerImagePlaceholder} size="sm" />
          </Cell>
          <Cell className="customer-table-name">
            <Link to={`/${page}/customers/view/${id}`}>
              <span className="customer-name">{name}</span>
            </Link>
          </Cell>
          <Cell className="customer-table-contact">
            <div className="text-nowrap">
              <a href={`tel:${phone && phone.phone ? phone.phone : null}`}>
                {phone && phone.phone ? phone.phone : null}
              </a>
            </div>
            <small className="text-nowrap text-muted">
              {email ? email.email : null}
            </small>
          </Cell>
          <Cell className="customer-table-joining">
            <div className="text-nowrap">
              {joinedTime ? getPrintableTime(joinedTime).split(",")[0] : null}
            </div>
            <small className="text-muted">
              {joinedTime ? getPrintableTime(joinedTime).split(",")[1] : null}
            </small>
          </Cell>
          <Cell className="customer-table-status">
            <div>{status}</div>
          </Cell>
          <Cell className="customer-table-actions">
            <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
              <DropDownItem>
                <CustomerEnableDisableDialog
                  status={status}
                  id={id}
                  onAction={onAction}
                  rerender={rerender}
                />
              </DropDownItem>
              <DropDownItem>
                <Link to={`/${page}/customers/view/${id}`}>
                  {getMessage("product.action.view")}
                </Link>
              </DropDownItem>
              {hasPermissions("customer", "reset-password", "post") && (
                <DropDownItem>
                  <ResetPassword email={email ? email.email : null} />
                </DropDownItem>
              )}
            </DropDown>
          </Cell>
        </Row>
      );
    },
  };
};

export default TableView;
