import React, { Component, Fragment } from "react";
import { BaseForm, Select, Toggle } from "../../../components/Form";
import { getMessage } from "../../../lib/translator";
import API from "../../../lib/api";
import Loader from "../../../components/Loader";
import Image from "../../../components/Image";
import Tabs from "../../../components/Tabs";

import ProviderConfiguration from "./ProviderConfiguration";

import EmailIcon from "./email.png";

class Actions extends Component {
  render() {
    const { parent, icon, allowKey, className } = this.props;
    const targetStatus = !parent.getState([allowKey]);
    return (
      <div className={`actions ${className}`}>
        <Image src={icon} className="flex-center" />
        <Toggle
          name="status"
          icons={false}
          value={parent.getState([allowKey])}
          onChange={() => parent.updateState([allowKey], targetStatus)}
        />
      </div>
    );
  }
}

class CommunicationForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      smsProviders: [],
      tabIndex: 0,
      values: {},
      callProviders: [],
      formError: false,
    };
    //this.communicationValues keeps cache of saved provider config
    this.communicationValues = {};
    this.pages = ["call", "SMS", "EMAIL"];

    this.emailServiceProviderConfig = {
      fields: [
        {
          name: "smtpHost",
          required: false,
          type: "string",
        },
        {
          name: "smtpUsername",
          required: false,
          type: "string",
        },
        {
          name: "smtpPassword",
          required: false,
          type: "password",
        },
        {
          name: "smtpPort",
          required: false,
          type: "number",
        },
        {
          name: "defaultEmail",
          required: false,
          type: "email",
        },
      ],
      id: "EMAIL",
      image: EmailIcon,
    };
  }
  // returns the sliced the specific(sms or call or email saved data) config data
  getStateValues = (tabIndex, provider) => {
    if (tabIndex === 0) {
      let { allowCall, callServiceProvider, callServiceProviderCredentials } =
        this.communicationValues;
      provider = provider || callServiceProvider;
      callServiceProviderCredentials =
        provider === callServiceProvider ? callServiceProviderCredentials : {};
      return {
        values: {
          ...callServiceProviderCredentials,
          provider,
          allowCall,
        },
        allowKey: "allowCall",
      };
    } else if (tabIndex === 1) {
      let { allowSms, smsServiceProvider, smsServiceProviderCredentials } =
        this.communicationValues;
      provider = provider || smsServiceProvider;
      smsServiceProviderCredentials =
        provider === smsServiceProvider ? smsServiceProviderCredentials : {};
      return {
        smsServiceProvider,
        values: {
          ...smsServiceProviderCredentials,
          provider,
          allowSms,
        },
        allowKey: "allowSms",
      };
    } else {
      const { allowEmail, email } = this.communicationValues;
      return {
        values: {
          ...email,
          allowEmail: allowEmail,
        },
        allowKey: "allowEmail",
      };
    }
  };
  getProviderConfig = (tabIndex, provider) => {
    if (tabIndex === 0) {
      return this.state.callProviders?.find(
        (providerConfig) => providerConfig.id === provider
      );
    } else if (tabIndex === 1) {
      return this.state.smsProviders?.find(
        (providerConfig) => providerConfig.id === provider
      );
    } else {
      return this.emailServiceProviderConfig;
    }
  };
  getSmsProviders = () => {
    new API({
      url: "/communication-service/sms-provider",
    })
      .get()
      .then(({ data }) => {
        this.setState({
          smsProviders: data.smsProvider.map((provider) => ({
            ...provider,
            text: provider.name,
            value: provider.id,
          })),
          smsProviderError: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ smsProviderError: error });
      });
  };
  getCallProviders = () => {
    new API({
      url: "/communication-service/call-provider",
    })
      .get()
      .then(({ data }) => {
        this.setState({
          callProviders: data.callProvider.map((provider) => ({
            ...provider,
            text: provider.name,
            value: provider.id,
          })),
          callProviderError: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ callProviderError: error });
      });
  };
  componentDidMount() {
    this.setState({
      loading: true,
    });

    this.api = new API({ url: "/config-service/config/communication" });
    this.api
      .get()
      .then((response) => {
        const data = response.data.communication;
        this.communicationValues = data;
        const state = this.getStateValues(0, data.callServiceProvider);
        this.setState({
          ...state,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false, callProviderError: error });
        console.error(error);
      });
    this.getSmsProviders();
    this.getCallProviders();
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
  }

  changeTab = (tabIndex) => {
    let state = this.getStateValues(tabIndex);
    this.resetForm();
    if (tabIndex === 0 && !this.state.callProviders?.length) {
      this.getCallProviders();
    } else if (tabIndex === 1 && !this.state.smsProviders?.length) {
      this.getSmsProviders();
    }
    this.setState({
      tabIndex,
      ...state,
    });
  };

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    let stateValues = { ...data };
    const { tabIndex, values } = this.state;
    let formData = { ...this.communicationValues };
    this.api = new API({ url: "/config-service/config" });
    const provider = values.provider;
    if (tabIndex === 0) {
      this.communicationValues.callServiceProviderCredentials = data;
      this.communicationValues.callServiceProvider = provider;
      delete stateValues.provider;
      formData.callServiceProvider = provider;
      formData.callServiceProviderCredentials = stateValues;
      formData.allowCall = values.allowCall || false;
    } else if (tabIndex === 1) {
      this.communicationValues.smsServiceProviderCredentials = data;
      this.communicationValues.smsServiceProvider = provider;
      delete stateValues.provider;
      delete stateValues.allowSms;
      formData.smsServiceProvider = provider;
      formData.smsServiceProviderCredentials = stateValues;
      formData.allowSms = values.allowSms || false;
    } else {
      this.communicationValues.email = data;
      formData.email = stateValues;
      formData.allowEmail = values.allowEmail || false;
    }

    delete formData?.smsServiceProviderCredentials?.allowSms;
    delete formData?.callServiceProviderCredentials?.allowCall;
    delete formData?.email?.allowEmail;

    this.api
      .post({ communication: formData })
      .then((response) => {
        this.setState({
          submitting: false,
          formError: null,
        });
      })
      .catch((error) => {
        this.setState({ submitting: false, formError: error });
        console.error(error);
      });
  }
  onProviderChange = (provider) => {
    this.resetForm();
    const stateValues = this.getStateValues(this.state.tabIndex, provider);
    this.setState({
      ...stateValues,
    });
  };

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const {
      loading,
      tabIndex,
      allowKey,
      values = {},
      smsProviders,
      callProviders,
      formError,
      callProviderError,
      smsProviderError,
    } = this.state;
    const selectedProviderConfig = this.getProviderConfig(
      tabIndex,
      values.provider
    );
    return loading ? (
      <Loader />
    ) : (
      <Form>
        <Tabs items={this.pages} onClick={this.changeTab} />
        {formError && (
          <div className="communication-config-api-error">{formError}</div>
        )}
        {(tabIndex === 0 && callProviderError) || (
          <div className="communication-config-api-error">
            {callProviderError}
          </div>
        )}
        {tabIndex === 1 && smsProviderError && (
          <div className="communication-config-api-error">
            {smsProviderError}
          </div>
        )}
        {tabIndex !== 2 && (
          <div className="selection">
            <Select
              name="provider"
              label={getMessage("communication.service.provider")}
              placeholder={getMessage(
                "communication.service.provider.placeholder"
              )}
              options={tabIndex === 0 ? callProviders : smsProviders}
              {...this.generateStateMappers({
                stateKeys: ["provider"],
                loseEmphasisOnFill: true,
              })}
              onChange={this.onProviderChange}
            />
          </div>
        )}

        {selectedProviderConfig && (
          <ProviderConfiguration
            parent={this}
            allowKey={allowKey}
            selectedProviderConfig={selectedProviderConfig}
          />
        )}
        <Fragment>
          <SubmitButton disabled={this.state.submitting}>
            {getMessage("save")}
          </SubmitButton>
          <button className="button" type="button" onClick={this.props.back}>
            {getMessage("cancel")}
          </button>
        </Fragment>
      </Form>
    );
  }
}

export default CommunicationForm;
export { Actions };
