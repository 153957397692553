import React from "react";
import Image from "../../Image";
import imgBlank from "./imgBlank.png";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";
import "./style.css";

export default function Testimonial(props) {
  const { data } = props;
  if (!(data?.title || data?.subtitle)) {
    return (
      <div>
        <Image src={imgBlank} alt="" />
      </div>
    );
  }
  const { slides, title = "", subtitle = "", activeIndex = 0 } = data || {};
  const {
    attribute = "",
    authorImage = "",
    authorName = "",
    testimonial = "",
  } = slides?.[activeIndex] || {};
  const isMultiSlides = Boolean(data?.slides?.length > 1 ?? false);
  return (
    <div className="testimonial-layout">
      <h2 className="title">{title}</h2>
      <h3 className="subtitle">{subtitle}</h3>
      {subtitle && <hr />}
      <div className={`body ${!isMultiSlides ? "center" : ""}`}>
        {isMultiSlides && (
          <div className="btn-left">
            <LeftArrow />
          </div>
        )}
        <div className="main-content">
          <div className="profile-image">
            <img src={authorImage} alt="" />
          </div>
          <div className="testimonial">{testimonial}</div>
          <div className="persona-wrp">
            <div className="name">{authorName}</div>
            {authorName && attribute && <span>,&nbsp;</span>}
            {attribute && <div className="attribute">{attribute}</div>}
          </div>
        </div>
        {isMultiSlides && (
          <div className="btn-right">
            <RightArrow />{" "}
          </div>
        )}
      </div>
    </div>
  );
}
