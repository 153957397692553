import React from "react";
import { BaseForm, CustomerTagSearch } from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";
import API from "../../../../../lib/api";
import "./style.css";

class UserTags extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      apiError: null,
      submitting: false,
    };
  }

  componentDidMount() {
    let values = Object.assign({}, this.state.values);
    let customerId = this.props.customer.id;
    if (customerId) {
      this.api = new API({
        url: `/customer-service/customer/${customerId}/tag`,
      });
      this.api
        .get()
        .then((response) => {
          values["tags"] = response.data.tag;
          this.setState({
            values,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
  }

  render() {
    const { Form } = this.components;
    return (
      <div className="user-tags">
        <Form className="user-tags-form">
          <CustomerTagSearch
            placeholder={getMessage(
              "customer-service-tags.form.otherdetails.placeholder"
            )}
            name="tags"
            tags={this.state.values && this.state.values.tags}
            multiple
            {...this.generateStateMappers({
              stateKeys: ["tags"],
              loseEmphasisOnFill: true,
            })}
            customerId={this.props.customer.id}
          />
        </Form>
      </div>
    );
  }
}
export default UserTags;
