import React from "react";
import {
  BaseForm,
  Select,
  VALIDATION_TYPES,
  Radio,
  Input,
} from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";
import API from "../../../../../lib/api";
import "./style.css";
import { Dialog } from "../../../../../components/Popup";
import { withRouter } from "react-router-dom";

function generateData(oldArray, languageKey) {
  return oldArray.map((data) => {
    let key = data.toLowerCase().replace(/_/g, "");
    return {
      text: getMessage(`orderprocessing.${languageKey}.${key}`),
      value: data,
    };
  });
}
// Function to change if two objects are same . It does not do a deep comparision.
function isObjSame(obj1, obj2) {
  for (let key in obj1) {
    if (obj2[key] !== obj1[key]) {
      return false;
    }
  }
  return true;
}

class InstoreConfigForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.showSuccessDialog = false;
    this.state.showErrorDialog = false;
    this.state.formError = "";
    this.handleClose = this.handleClose.bind(this);
    this.closeDialogs = this.closeDialogs.bind(this);
    this.resetToDefault = this.resetToDefault.bind(this);
  }

  handleClose() {
    this.props.history.goBack();
  }

  resetToDefault() {
    let values = { ...this.state.values };
    const globalConfig = { ...this.props.data.config.globalConfig };
    values = globalConfig;
    this.setState({
      values,
    });
  }

  handleSubmit(data) {
    const { props } = this;
    const id = props.data.id;
    const api = new API({ url: `/account-service/extension/${id}` });
    const storeId = props.storeId;
    const { storeSpecific, globalConfig } = props.config.config;
    let config = {};
    if (storeId) {
      // saving store specific settings
      config["globalConfig"] = { ...globalConfig };
      if (storeSpecific) {
        config["storeSpecific"] = { ...storeSpecific };
      } else {
        config["storeSpecific"] = {};
      }
      config["storeSpecific"][storeId] = data;
      if (isObjSame(data, config["globalConfig"])) {
        delete config["storeSpecific"][storeId];
      }
    } else {
      // saving global settings
      config["globalConfig"] = data;
      if (storeSpecific) {
        config["storeSpecific"] = { ...storeSpecific };
      }
    }
    let params = { status: props.data.status };
    params["config"] = {};
    params["config"]["globalConfig"] = { ...config.globalConfig };
    if (config.storeSpecific) {
      params["config"]["storeSpecific"] = [];
      for (let key in config.storeSpecific) {
        config.storeSpecific[key]["storeId"] = key;
        params["config"]["storeSpecific"].push(config.storeSpecific[key]);
      }
    }
    return api
      .put(params)
      .then(
        (response) => {
          props.updateConfig(response.data.extension.config);
          this.setState({ formError: "", showSuccessDialog: true });
        },
        (error) => {
          this.setState({ formError: error.message, showErrorDialog: true });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleSubmit(data).then(() => {
      this.setState({ submitting: false });
    });
  }

  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    });
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const { data, storeId } = this.props;
    const { values } = this.state;
    const {
      pickingSequence,
      pickerSeesOrder,
      packingOfOrder,
      orderLabelPrinting,
      orderLabelFormat,
      additionalQCStep,
    } = data.allowedConfig;

    const pickingSequenceMethodsOpts =
      data && generateData(pickingSequence.typeMeta, "pickingMethod");
    const pickingViewOptions =
      data && generateData(pickerSeesOrder.typeMeta, "pickingView");
    const OrderLabelPrintingOptions =
      data && generateData(orderLabelPrinting.typeMeta, "orderLabelPrinting");
    const OrderLabelFormatOptions =
      data && generateData(orderLabelFormat.typeMeta, "orderLabelFormat");
    const qualityCheckingOptions =
      data && generateData(additionalQCStep.typeMeta, "qualityChecking");

    let packingDetailsOptions = [];
    packingOfOrder.typeMeta.forEach((data) => {
      if (
        data !== "DONE_BY_CHECKER" ||
        (data === "DONE_BY_CHECKER" &&
          values.additionalQCStep === "DONE_BY_CHECKER")
      ) {
        let key = data.toLowerCase().replace(/_/g, "");
        packingDetailsOptions.push({
          text: getMessage(`orderprocessing.packingDetails.${key}`),
          value: data,
        });
      }
    });
    return (
      <div>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("orderprocessing.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("orderprocessing.save.success.closeText")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage("orderprocessing.save.error.title")}
          close={this.closeDialogs}
          closeText={getMessage("orderprocessing.save.error.closeText")}
        />
        <Form className="instore-config-form">
          <Select
            label={getMessage("orderprocessing.config.pickingMethod.heading")}
            name="pickingSequence"
            placeholder={getMessage(
              "orderprocessing.config.pickingMethod.placeholder"
            )}
            options={pickingSequenceMethodsOpts}
            {...this.generateStateMappers({
              stateKeys: ["pickingSequence"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Radio
            label={getMessage("orderprocessing.config.pickingView.heading")}
            name="pickerSeesOrder"
            options={pickingViewOptions}
            {...this.generateStateMappers({
              stateKeys: ["pickerSeesOrder"],
              loseEmphasisOnFill: true,
            })}
          />
          <Radio
            label={getMessage("orderprocessing.orderLabelPrinting.heading")}
            name="orderLabelPrinting"
            options={OrderLabelPrintingOptions}
            {...this.generateStateMappers({
              stateKeys: ["orderLabelPrinting"],
              loseEmphasisOnFill: true,
            })}
          />
          {this.state.values.orderLabelPrinting !== "NOT_NEEDED" && (
            <Radio
              label={getMessage("orderprocessing.orderLabelFormat.heading")}
              name="orderLabelFormat"
              options={OrderLabelFormatOptions}
              {...this.generateStateMappers({
                stateKeys: ["orderLabelFormat"],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <Radio
            label={getMessage("orderprocessing.qualityChecking.heading")}
            name="additionalQCStep"
            options={qualityCheckingOptions}
            {...this.generateStateMappers({
              stateKeys: ["additionalQCStep"],
              loseEmphasisOnFill: true,
            })}
          />
          <Radio
            label={getMessage("orderprocessing.packingDetails.heading")}
            name="packingOfOrder"
            options={packingDetailsOptions}
            {...this.generateStateMappers({
              stateKeys: ["packingOfOrder"],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage("orderprocessing.qrCodeFormat.heading")}
            placeholder={getMessage("orderprocessing.qrCodeFormat.placeholder")}
            name="qrCodeFormat"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["qrCodeFormat"],
              loseEmphasisOnFill: true,
            })}
          >
            {getMessage("orderprocessing.qrCodeFormat.description")}
          </Input>
          <div className="form-buttons-container">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage("orderprocessing.config.form.submit.text")}
            </SubmitButton>
            {storeId && (
              <button
                type="button"
                className="button reset-default-button"
                onClick={this.resetToDefault}
              >
                {getMessage("orderprocessing.config.form.resetToDefault")}
              </button>
            )}
            <button
              className="button"
              type="button"
              onClick={this.handleClose}
              disabled={this.state.submitting}
            >
              {getMessage("orderprocessing.config.form.cancel.text")}
            </button>
          </div>
        </Form>
      </div>
    );
  }
}
export default withRouter(InstoreConfigForm);
