import { getDefaultStore } from "./containers/StoreSelector";
import { getAuthToken } from "./lib/auth";
import { get } from "./lib/storage";
import { WEBSOCKET_URL, WEBSOCKET_BASE_URL } from "./config/app";

function registerSocket() {
  const authToken = getAuthToken();
  const storeId = get("store") || getDefaultStore().storeId;
  let socket = new WebSocket(
    `${WEBSOCKET_URL}?storeId=${storeId}&guid=${authToken}`
  );
  return socket;
}

function registerInvoiceSockets(referenceNumber) {
  const authToken = getAuthToken();
  let socket = new WebSocket(
    `${WEBSOCKET_BASE_URL}?referenceNumber=${referenceNumber}&guid=${authToken}`
  );
  return socket;
}

export { registerSocket, registerInvoiceSockets };
