import React from "react";
import ProductCollection from "../../../../components/Layout/ProductCollection";
import { Input, Radio, Checkbox } from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";

const SubCategoryCollectionLayout = () => {
  return {
    preview: ProductCollection,
    fields: ({ getState, updateState, parent, page }) => {
      return (
        <div className="subcategory-collection">
          <div className="form-sections">
            <div className="form-section">
              <Input
                label={getMessage("themes.cc.name")}
                placeholder={getMessage("themes.cc.name.placeholder")}
                value={getState(["title"]) || ""}
                onChange={(e) => {
                  updateState(["title"], e);
                }}
              />
            </div>
            <div className="form-section">
              <Input
                label={getMessage("subtitle")}
                placeholder={getMessage("Enter subtitle ")}
                value={getState(["subtitle"]) || ""}
                onChange={(e) => {
                  updateState(["subtitle"], e);
                }}
              />
            </div>
            <div className="form-section">
              <Radio
                label={getMessage("themes.cc.layoutType")}
                name="layoutType"
                options={[
                  {
                    text: getMessage("themes.cc.scroller"),
                    value: "SCROLLER",
                  },
                  {
                    text: getMessage("themes.cc.grid"),
                    value: "GRID",
                  },
                ]}
                value={getState(["layoutType"])}
                onChange={(e) => {
                  updateState(["layoutType"], e);
                }}
              />
            </div>
          </div>
          <div className="form-sections">
            <div className="form-section no-label config">
              <p>{getMessage("themes.cc.config")}</p>
              <Checkbox
                name="pdtCount"
                inlineLabel={getMessage("themes.cc.pdtCount.inline")}
                value={getState(["showProductCount"])}
                onChange={(e) => {
                  updateState(["showProductCount"], e);
                }}
              />
              <Checkbox
                name="subCategory"
                inlineLabel={getMessage("themes.cc.showSubCategory.inline")}
                value={getState(["showSubCategoryLinks"])}
                onChange={(e) => {
                  updateState(["showSubCategoryLinks"], e);
                }}
              />
              <Checkbox
                // label={getMessage('themes.cc.config.image')}
                name="bannerImage"
                inlineLabel={getMessage("themes.cc.config.image")}
                value={getState(["fullImage"])}
                onChange={(e) => {
                  updateState(["fullImage"], e);
                }}
              />
            </div>
          </div>
        </div>
      );
    },
  };
};

export default SubCategoryCollectionLayout;
