import React from "react";
import img from "./form_place_holder.svg";

export default function Image(props) {
  return (
    <div>
      <img src={img} alt="uploaded" />
    </div>
  );
}
