import React, { useEffect } from "react";
import { Input, Radio, Textarea, Upload } from "../../../../components/Form";
import Tabs from "../../../../components/Tabs";
import { getMessage } from "../../../../lib/translator";
import Carousel from "../../../../components/Layout/Carousel";

const COLOR_PATTERN = /^#([A-F0-9]{3}|[A-F0-9]{6})$/i;

const CarouselLayout = ({
  getState,
  updateState,
  parent,
  registerValidation,
}) => {
  // -------------------------sliders--------------------------
  const activeIndex = Number(getState(["activeIndex"])) || 0;
  const emptySlide = {
    question: "",
    description: "",
  };
  const slides = getState(["questions"]) || [emptySlide];
  const currentSlide = slides[activeIndex] || emptySlide;

  if (!getState(["questions"])) {
    updateState(["questions"], slides); // TODO: Move this to componentWillMount of layout
  }

  // Since updateStateRecursively had some issues with this structure
  const newState = (key, value) => {
    parent.setState((prevState) => {
      const newState = Object.assign({}, prevState);
      newState.values.questions[activeIndex][key] = value;
      return prevState;
    });
  };
  const showDelete = slides && slides.length > 1;

  // -------------------------img & color--------------------------
  useEffect(() => {
    if (!getState(["layoutBackground", "backgroundType"])) {
      updateState(["layoutBackground", "backgroundType"], "IMAGE");
    }
    handleThemeColorValidator(
      getState(["layoutBackground", "backgroundColor"]) || "#000000"
    );
    // eslint-disable-next-line
  }, []);
  const handleThemeColorValidator = (value) => {
    const error = {
      patternMismatch: !COLOR_PATTERN.test(value),
      valid: COLOR_PATTERN.test(value),
    };
    registerValidation(["layoutBackground", "backgroundColor"], error);
  };
  const isColorOption =
    getState(["layoutBackground", "backgroundType"]) === "COLOR";
  const colorVal = getState(["layoutBackground", "backgroundColor"]);
  const isColorError = !COLOR_PATTERN.test(colorVal);
  return (
    <>
      <div>
        <div className="flex-around">
          <Tabs
            items={slides.map((slide, index) => (
              <span>
                Slide {index + 1}
                {showDelete && (
                  <span
                    className="delete-tab"
                    onClick={(e) => {
                      slides.splice(index, 1);
                      updateState(
                        ["activeIndex"],
                        index !== 0 && index === slides.length
                          ? index - 1
                          : index
                      );
                    }}
                  >
                    &times;
                  </span>
                )}
              </span>
            ))}
            default={activeIndex}
            active={activeIndex}
            onClick={(value) => {
              updateState(["activeIndex"], value);
            }}
          />
          <button
            className="add-tab"
            onClick={(e) => {
              e.preventDefault();
              const slidesCount = slides.length;
              updateState(["questions"], slides.concat(emptySlide));
              updateState(["activeIndex"], slidesCount);
            }}
          >
            + {getMessage("imageSlideShow.addSlide")}
          </button>
        </div>
        <div className="form-sections">
          <div className="form-section-full">
            <Input
              label="Question"
              placeholder="Please enter question here"
              value={currentSlide["question"]}
              onChange={(value) => {
                newState("question", value);
              }}
            />
            <Textarea
              label="Description"
              placeholder="Please enter description here"
              value={currentSlide["description"]}
              onChange={(value) => {
                newState("description", value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="form-sections rich-text-layout">
        <div className="form-section-full">
          <div className="img-alignment-wrp">
            <Radio
              className="image-alignment"
              name="imgAlignment"
              skin="borderless-skin"
              options={[
                { text: "BACKGROUND IMAGE", value: "IMAGE" },
                { text: "BACKGROUND COLOR", value: "COLOR" },
              ]}
              value={getState(["layoutBackground", "backgroundType"]) || ""}
              onChange={(value) => {
                updateState(["layoutBackground", "backgroundType"], value);
              }}
            />
            {isColorOption ? (
              <div className="bg-color">
                <Input
                  name="bg-color"
                  type="color"
                  value={
                    getState(["layoutBackground", "backgroundColor"]) || ""
                  }
                  onChange={(value) => {
                    updateState(["layoutBackground", "backgroundColor"], value);
                    handleThemeColorValidator(value);
                  }}
                  siblings={{
                    after: (
                      <Input
                        name="bg-color"
                        type="text"
                        value={
                          getState(["layoutBackground", "backgroundColor"]) ||
                          ""
                        }
                        onChange={(value) => {
                          updateState(
                            ["layoutBackground", "backgroundColor"],
                            value
                          );
                          handleThemeColorValidator(value);
                        }}
                        showErrors={isColorError}
                        pattern={COLOR_PATTERN}
                        validationStrings={{
                          patternMismatch: "Please enter valid color",
                        }}
                      />
                    ),
                  }}
                />
              </div>
            ) : (
              <Upload
                placeholder="Click here to upload or drag your image"
                value={getState(["layoutBackground", "backgroundImage"]) || ""}
                onChange={(value) => {
                  updateState(["layoutBackground", "backgroundImage"], value);
                }}
              />
            )}
          </div>
          <Radio
            label="Arrows position"
            className="btn-alignment"
            name="btnAlignment"
            options={[
              { text: "Center", value: "CENTER" },
              { text: "Bottom", value: "BOTTOM" },
            ]}
            value={getState(["arrowsPosition"]) || ""}
            onChange={(value) => {
              updateState(["arrowsPosition"], value);
            }}
          />
        </div>
      </div>
    </>
  );
};
const CarouselLayoutWrp = () => {
  return {
    preview: Carousel,
    fields: ({ getState, updateState, parent, registerValidation }) => (
      <CarouselLayout
        getState={getState}
        updateState={updateState}
        parent={parent}
        registerValidation={registerValidation}
      />
    ),
  };
};

export default CarouselLayoutWrp;
