import React, { Component } from "react";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";
import { getSession } from "../../../lib/auth";

export default class RechargeWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: null,
      rechargeAmount: "",
      processing: false,
      gatewayDetails: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  getCurrentBalance() {
    let api = new API({ url: "/billing-service/balance" });
    api
      .get()
      .then((response) => {
        this.setState({ balance: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleSubmit(e) {
    e.preventDefault();
    let api = new API({ url: "/billing-service/online-payment" });
    this.setState(
      {
        processing: true,
      },
      () => {
        api
          .post({ amount: Number(this.state.rechargeAmount) })
          .then(
            ({ data: { onlinepayment } }) => {
              this.setState(
                {
                  gatewayDetails: onlinepayment,
                },
                () => {
                  this.paymentGatewayForm.submit();
                }
              );
            },
            (error) => {
              this.setState({
                processing: false,
              });
              throw error;
            }
          )
          .catch((error) => {
            console.error(error);
          });
      }
    );
  }
  componentDidMount() {
    this.getCurrentBalance();
  }
  render() {
    return (
      <div className="recharge-widget">
        <div className="balance-indication">
          {this.state.balance !== null ? (
            <div>
              <h3>{`${getSession().organization.currency.symbol} ${
                this.state.balance
              }`}</h3>
              <div className="available-balance-label">
                {getMessage("user.accountBalance.availableBalance.label")}
              </div>
            </div>
          ) : (
            <small className="text-muted">
              {getMessage("user.accountBalance.loader.text")}
            </small>
          )}
        </div>
        <form className="recharge-form-container" onSubmit={this.handleSubmit}>
          <input
            className="input account-recharge-input"
            type="number"
            placeholder={getMessage("user.accountBalance.input.placeholder")}
            value={this.state.rechargeAmount}
            onChange={(e) => {
              this.setState({ rechargeAmount: e.target.value });
            }}
          />
          <button
            className="button primary"
            disabled={
              !(Number(this.state.rechargeAmount) > 0) || this.state.processing
            }
          >
            {this.state.processing
              ? getMessage("user.accountBalance.rechargeButton.processing")
              : getMessage("user.accountBalance.rechargeButton.text")}
          </button>
        </form>
        {this.state.gatewayDetails ? (
          <form
            className="payment-gateway-redirect-form"
            method="POST"
            action={this.state.gatewayDetails.redirectUrl}
            ref={(node) => {
              this.paymentGatewayForm = node;
            }}
          >
            {Object.keys(this.state.gatewayDetails)
              .filter((key) => key !== "redirectUrl")
              .map((key) => (
                <input
                  key={key}
                  type="hidden"
                  name={key.replace(/([A-Z])/g, "_$1").toLowerCase()}
                  value={this.state.gatewayDetails[key]}
                />
              ))}
          </form>
        ) : null}
      </div>
    );
  }
}
