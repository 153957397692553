import React from "react";
import { withRouter } from "react-router-dom";
import Loader from "../../../../../components/Loader";
import { getMessage } from "../../../../../lib/translator";
import { BaseForm, Input } from "../../../../../components/Form";
import API from "../../../../../lib/api";
import { Dialog } from "../../../../../components/Popup";
import "./style.css";

class OrderManagementForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.showLoader = false;
    this.handleClose = this.handleClose.bind(this);
    this.closeDialogs = this.closeDialogs.bind(this);
  }

  componentDidMount() {
    const api = new API({ url: "/config-service/config/passwordPolicy" });
    api
      .get()
      .then((res) => {
        const { customer } = res.data.passwordPolicy;
        this.setState({
          values: customer,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ formError: error.message, showErrorDialog: true });
        if (error.code === 401) {
          throw error;
        }
      });
  }

  handleSubmit(data) {
    const api = new API({ url: `/config-service/config` });
    return api
      .post({ passwordPolicy: { customer: data } })
      .then(() => {
        this.setState({ formError: "", showSuccessDialog: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ formError: error.message, showErrorDialog: true });
        if (error.code === 401) {
          throw error;
        }
      });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleSubmit(data).then(() => {
      this.setState({ submitting: false });
    });
  }

  handleClose() {
    this.props.history.goBack();
  }

  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    });
  }

  render() {
    const { config } = this.props.data;
    const { globalConfig } = config;

    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div className="password-policy">
        <h1 className="title">
          {getMessage("extensions.password-policy.heading")}
        </h1>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("basicInfo.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("basicInfo.save.success.closeText")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage("basicInfo.save.error.title")}
          close={this.closeDialogs}
          closeText={getMessage("basicInfo.save.error.closeText")}
        />
        <h3 className="sub-title">customer password policy</h3>
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <Form className="order-management-form">
            {Object.keys(globalConfig).map((config) => {
              return (
                <Input
                  label={getMessage(
                    `extensions.password-policy.label.${config}`
                  )}
                  placeholder={getMessage(
                    `extensions.password-policy.placeholder.${config}`
                  )}
                  name={config}
                  type="number"
                  min={0}
                  {...this.generateStateMappers({
                    stateKeys: [config],
                    loseEmphasisOnFill: true,
                  })}
                />
              );
            })}
            <div className="form-buttons-container">
              <SubmitButton disabled={this.state.submitting}>
                {getMessage("basicInfo.submit.text")}
              </SubmitButton>
              <button
                className="button"
                type="button"
                onClick={this.handleClose}
                disabled={this.state.submitting}
              >
                {getMessage("basicInfo.cancel.text")}
              </button>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

export default withRouter(OrderManagementForm);
