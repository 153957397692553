import React from "react";
import { getMessage } from "../../../../lib/translator";
import {
  BaseForm,
  Input,
  Select,
  VALIDATION_TYPES,
} from "../../../../components/Form";
import { getCurrency } from "../../../../lib/userDetails";
import API from "../../../../lib/api";
import Loader from "../../../../components/Loader";
import { Dialog } from "../../../../components/Popup";

function checkForNegativeValue(value) {
  if (value && value < 0) {
    return getMessage("input.invalidNumber");
  }
  return "";
}

export default class RefferalManagementForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        bonusValue: 0,
        newCustomerSignupBonusAmount: 0,
        maxBonusValue: 0,
        referrerBonusType: "FLAT",
      },
      showSuccessDialog: false,
      showErrorDialog: false,
    };
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    let api = new API({ url: `/config-service/config/referralManagement` });
    api
      .get()
      .then((response) => {
        if (response.data?.referralManagement) {
          this.setState({
            values: response.data?.referralManagement,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => this.setState({ showLoader: false }));
  }

  onSubmit(data) {
    this.setState({ submitting: true });
    if (this.state.values.referrerBonusType === "FLAT") {
      data.maxBonusValue = 0;
    }

    let referralApi = new API({ url: "/config-service/config" });
    let params = data;
    referralApi
      .post({
        referralManagement: params,
      })
      .then((res) => {
        this.setState({ showSuccessDialog: true });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ showErrorDialog: true });
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  closeDialogs = () => {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    });
  };

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const { bonusValue, newCustomerSignupBonusAmount, maxBonusValue } =
      this.state.values;
    const negRefBonusErr = checkForNegativeValue(bonusValue);
    const negMaxBonusErr = checkForNegativeValue(maxBonusValue);
    const negSignupBonusErr = checkForNegativeValue(
      newCustomerSignupBonusAmount
    );

    return (
      <div>
        <Dialog
          show={this.state.showSuccessDialog}
          title={getMessage("referral.management.success.title")}
          className="success"
          information={getMessage("referral.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("okay.text")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          title={getMessage("referral.management.error.title")}
          information={getMessage("referral.save.error.title")}
          close={this.closeDialogs}
          closeText={getMessage("okay.text")}
        />

        {this.state.showLoader ? (
          <Loader />
        ) : (
          <Form className="Ref-manage-form">
            <div className="referrer-wpr">
              <p className="referrer-heading">
                {getMessage("refferal.managemnet.referrer.heading")}
              </p>
              <div className="referral-section bonus">
                <Input
                  label={getMessage("referral.bonus.text")}
                  className="referral-bonus"
                  placeholder="0"
                  name="referralBonus"
                  type="number"
                  error={negRefBonusErr}
                  siblings={{
                    after: [
                      <div key="referralBonus-type" className="bonus-select">
                        <Select
                          name="type"
                          options={[
                            {
                              text: "%",
                              value: "PERCENTAGE",
                            },
                            {
                              text: getCurrency().symbol,
                              value: "FLAT",
                            },
                          ]}
                          {...this.generateStateMappers({
                            stateKeys: ["referrerBonusType"],
                          })}
                        />
                      </div>,
                    ],
                  }}
                  {...this.generateStateMappers({
                    stateKeys: ["bonusValue"],
                    validationType: VALIDATION_TYPES.ONCHANGE,
                    loseEmphasisOnFill: true,
                  })}
                >
                  {getMessage("referral.bonus.description")}
                </Input>
                {this.state.values?.referrerBonusType &&
                this.state.values.referrerBonusType !== "FLAT" ? (
                  <Input
                    label={getMessage("referral.maxBonus.text")}
                    className="max-bonus"
                    placeholder="0"
                    name="maxBonus"
                    type="number"
                    error={negMaxBonusErr}
                    {...this.generateStateMappers({
                      stateKeys: ["maxBonusValue"],
                      validationType: VALIDATION_TYPES.ONCHANGE,
                      loseEmphasisOnFill: true,
                    })}
                  />
                ) : null}
              </div>
            </div>
            <div className="referee-wpr">
              <p className="referee-heading">
                {getMessage("refferal.managemnet.referee.heading")}
              </p>
              <div className="referral-section bonus">
                <Input
                  label={getMessage("signup.bonus.text")}
                  className="referee-bonus"
                  placeholder="0"
                  name="rsignupBonus"
                  type="number"
                  error={negSignupBonusErr}
                  {...this.generateStateMappers({
                    stateKeys: ["newCustomerSignupBonusAmount"],
                    validationType: VALIDATION_TYPES.ONCHANGE,
                    loseEmphasisOnFill: true,
                  })}
                >
                  {getMessage("signup.bonus.description")}
                </Input>
              </div>
            </div>
            <div className="form-buttons-container">
              <div>
                <SubmitButton disabled={this.state.submitting}>
                  {getMessage("save.text")}
                </SubmitButton>
              </div>
              <div>
                <button
                  className="button"
                  type="button"
                  onClick={this.handleClose}
                  disabled={this.state.submitting}
                >
                  {getMessage("cancel.text")}
                </button>
              </div>
            </div>
          </Form>
        )}
      </div>
    );
  }
}
