import { getSession, isExtensionEnabled } from "../auth";

export const isMarketPlaceSeller = () => {
  const userInfo = getSession()?.user;
  const isSellerAdmin = (userInfo?.roles || []).some(
    (role) => role.name === "SELLER ADMIN"
  );
  const isOwner = userInfo?.isOwner;
  const isMarketplaceEnabled = isExtensionEnabled("MarketPlace");

  return !isOwner && isMarketplaceEnabled && isSellerAdmin;
};

export const isMarketPlaceOwner = () => {
  const userInfo = getSession()?.user;
  const isSellerAdmin = (userInfo?.roles || []).some(
    (role) => role.name === "SELLER ADMIN"
  );
  const isMarketplaceEnabled = isExtensionEnabled("MarketPlace");
  return !isSellerAdmin && isMarketplaceEnabled;
};
