import React, { Component } from "react";

import Upload from "../../../../components/FileUpload";
import { getMessage } from "../../../../lib/translator";
import kmlIcon from "./kml.svg";
import uploadIcon from "./trip-upload.svg";

import "./style.css";

export default class KMLUpload extends Component {
  render() {
    return (
      <div className="kml-upload">
        <div className="bordered-box">
          <div className="text-center">
            <img src={uploadIcon} width="140" alt="" />
          </div>
          <Upload
            name="kmlUpload"
            accept=".kml,application/vnd.google-earth.kml+xml,text/xml,application/xml"
            placeholder={getMessage("productUpload.uploadFileText")}
            uploadButtonText="Upload"
            cancelText="Cancel"
            uploadUrl="/logistics-service/kmlZone"
            validationStrings={{
              invalidFileType: getMessage("input.invalidFileType"),
            }}
            strings={{
              defaultMessage: getMessage("fileUpload.importKML.heading"),
              progressMessage: getMessage("fileUpload.importedKML.heading"),
              completionMessage: getMessage("fileUpload.uploadedKML.heading"),
            }}
            transformSubmit={(data) => {
              data.append("storeId", this.props.storeId);
              return data;
            }}
            uploadKey="kmlFile"
            icon={kmlIcon}
            successCallback={this.props.successCallback}
            errorCallBack={this.props.errorCallBack}
          />
        </div>
      </div>
    );
  }
}
