import React, { Component } from "react";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import PermissionsForm from "./Form";
import Loader from "../../../../components/Loader";
import API from "../../../../lib/api";
import { getAllPermissions } from "../../../../lib/auth";
import { getMessage } from "../../../../lib/translator";

import "./style.css";

function handleException(error) {
  if (error.code === 401) {
    throw error;
  }
}

export default class Permissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      permissions: null,
      loading: false,
      failed: false,
      submitting: false,
    };
    this.allPermissions = this.categorizePermissions(getAllPermissions(), true);
    this.getData = this.getData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.parsePermissions = this.parsePermissions.bind(this);
  }
  parsePermissions(endpointPermissions) {
    let permissions = this.categorizePermissions(
      Object.assign(
        {},
        ...endpointPermissions.map(({ url, allowedMethods }) => ({
          [url]: allowedMethods,
        }))
      )
    );
    return this.prunePermissions(this.allPermissions, permissions);
  }
  getData() {
    this.setState({ loading: true, failed: false });
    let api = new API({
      url: `/account-service/user/${this.props.router.match.params.id}`,
    });
    api
      .get()
      .then(
        (response) => {
          let { endpointPermissions } = response.data.user;

          // sometimes endpointPermissions has duplicate permission objects, objects with same value for key 'url' are appearing
          // for example [{url: 'account-service/user', allowedMethods: {GET: true}}, {url: 'account-service/user', allowedMethods: {PUT: true, POST: false}}]
          // the following code is merging the two entries into single one with allowedMethods object merged, like so:
          // final array: [{{url: 'account-service/user', allowedMethods: {GET: true, PUT: true, POST: false}}}]

          let permissions = this.parsePermissions(
            endpointPermissions.reduce((finalArray, permissionObj) => {
              if (
                finalArray.findIndex((obj) => obj.url === permissionObj.url) >
                -1
              ) {
                let final = finalArray.slice();
                let originalIndex = final.findIndex(
                  (obj) => obj.url === permissionObj.url
                );
                final.splice(
                  final.findIndex((obj) => obj.url === permissionObj.url),
                  1,
                  {
                    ...permissionObj,
                    allowedMethods: {
                      ...permissionObj.allowedMethods,
                      ...final[originalIndex].allowedMethods,
                    },
                  }
                );
                return final;
              } else {
                return finalArray.concat([permissionObj]);
              }
            }, [])
          );
          delete response.data.endpointPermissions;
          this.setState({
            data: response.data.user,
            permissions,
          });
        },
        (err) => {
          this.setState({ failed: true });
          handleException(err);
        }
      )
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  categorizePermissions(uncategorized = {}, prune = false) {
    let result = {};
    Object.keys(uncategorized).map((endpoint) => {
      let [service, api] = endpoint.split("/").filter(Boolean);
      let permissions = uncategorized[endpoint];
      let allKeysFalse = !Object.values(permissions).reduce(
        (acc, val) => acc || val,
        false
      );
      if (prune ? !allKeysFalse : true) {
        if (!(service in result)) {
          result[service] = {};
        }
        result[service][api] = prune
          ? Object.assign(
              {},
              ...Object.keys(permissions)
                .filter((method) => permissions[method])
                .map((method) => ({
                  [method]: permissions[method],
                }))
            )
          : permissions;
      }
      return null;
    });
    return result;
  }
  prunePermissions(allowedPermissions, newPermissions) {
    // Remove all the changes that are not allowed
    let result = {};
    for (let service in allowedPermissions) {
      let apiPermissions = {};
      for (let api in allowedPermissions[service]) {
        let methodPermissions = {};
        for (let method in allowedPermissions[service][api]) {
          if (allowedPermissions[service][api][method]) {
            if (
              newPermissions[service] &&
              newPermissions[service][api] &&
              newPermissions[service][api][method]
            ) {
              methodPermissions[method] = newPermissions[service][api][method];
            }
          }
        }
        if (Object.keys(methodPermissions).length) {
          apiPermissions[api] = methodPermissions;
        }
      }
      if (Object.keys(apiPermissions).length) {
        result[service] = apiPermissions;
      }
    }
    return result;
  }
  handleSubmit(value) {
    const userId = this.props.router.match.params.id;
    let permissions = [];
    for (let service in value) {
      for (let api in value[service]) {
        for (let method in value[service][api]) {
          permissions.push({
            endpoint: `${service}/${api}`,
            method,
            hasPermission: value[service][api][method],
          });
        }
      }
    }
    let api = new API({ url: "/account-service/permission" });
    this.setState({ submitting: true, permissions: value });
    return api
      .put({ userId, permissions })
      .then((response) => {
        this.setState({ submitting: false });
        const { history, location } = this.props;
        history.push(
          location.pathname.replace(/(\/edit-permissions\/.*)$/, "")
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    const { props } = this;
    return (
      <AuthenticatedPage menu={props.menu} className="permissions-page">
        <h1 className="title">
          {this.state.data
            ? getMessage("settings.users.permissionsForm.heading", {
                name: this.state.data.name,
              })
            : getMessage("settings.users.permissionsForm.headingWithoutName")}
        </h1>
        {this.state.data ? (
          <PermissionsForm
            allPermissions={this.allPermissions}
            parsePermissions={this.parsePermissions}
            value={this.state.permissions}
            userId={props.router.match.params.id}
            onSubmit={this.handleSubmit}
            submitting={this.state.submitting}
            disabled={this.state.submitting}
            onCancel={() => {
              const { history, location } = this.props;
              history.push(
                location.pathname.replace(/(\/edit-permissions\/.*)$/, "")
              );
            }}
          />
        ) : (
          <Loader />
        )}
      </AuthenticatedPage>
    );
  }
}
