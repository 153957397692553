import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import TagForm from "./Form";
import TagFilters from "./Filters";
import ProductLinkingForm from "./ProductLinkingForm";
import { Popup } from "../../../components/Popup";
import { getMessage } from "../../../lib/translator";
import TagsImport from "./TagsImport";
import { getSession } from "../../../lib/auth";
import { get } from "../../../lib/storage";
import API from "../../../lib/api";
import { IS_STAGING } from "../../../config/app";

import "./style.css";

import emptyIcon from "./tags-empty.svg";
import Image from "../../../components/Image";

const readBasicConfig = () => {
  let config = window.localStorage.getItem("config-basic");
  if (config) {
    return JSON.parse(config);
  } else {
    return null;
  }
};

const emptyState = {
  icon: emptyIcon,
  message: getMessage("tag.helpItems.message"),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD);
      }}
    >
      + {getMessage("tag.add.text")}
    </button>
  ),
};

const tableProperties = () => {
  return {
    headers: [
      getMessage("tag.header.image"),
      getMessage("tag.header.tag"),
      getMessage("tag.header.productCount"),
      getMessage("tag.header.actions"),
    ],
    row: class TagsTableRow extends Component {
      constructor(props) {
        super(props);
        this.state = {
          productLinkingForm: false,
        };

        this.domain = getSession().organization.domain;
        this.config = readBasicConfig();
        this.isHttpsEnabled = JSON.parse(get("organization")).httpsEnabled;
        this.isEnterprise = JSON.parse(get("organization")).isEnterprise;
        this.showLinkingForm = this.showLinkingForm.bind(this);
        this.hideLinkingForm = this.hideLinkingForm.bind(this);
      }
      showLinkingForm() {
        this.setState({ productLinkingForm: true });
      }
      hideLinkingForm() {
        this.setState({ productLinkingForm: false });
      }
      render() {
        const { id, name, productsCount, onAction, image, slug } = this.props;
        return (
          <Row>
            <Cell>
              <Image size="sm" src={image} bordered alt="tag-image" />
            </Cell>
            <Cell>
              <span
                className="tag-name"
                onClick={() => {
                  onAction(TABLE_ACTIONS.EDIT, { id });
                }}
              >
                {name}
              </span>
            </Cell>
            <Cell className="product-count">
              <span className="text-muted">
                <span className="tag-header-mobileview">{`${getMessage(
                  "brand.header.productCount"
                )}: `}</span>
                <small className="tag-product-count">{`${productsCount}`}</small>
              </span>
            </Cell>
            <Cell className="tag-actions">
              <Popup
                show={this.state.productLinkingForm}
                close={this.hideLinkingForm}
                heading={getMessage("tag.action.linkToProduct.heading", {
                  name,
                })}
              >
                <ProductLinkingForm
                  onSubmit={this.linkProductToTag}
                  tagId={id}
                />
              </Popup>
              <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
                <DropDownItem
                  onClick={() => {
                    onAction(TABLE_ACTIONS.EDIT, { id });
                  }}
                >
                  {getMessage("tag.action.edit")}
                </DropDownItem>
                <DropDownItem
                  onClick={() => {
                    onAction(TABLE_ACTIONS.DELETE, { id });
                  }}
                >
                  {getMessage("tag.action.delete")}
                </DropDownItem>
                <DropDownItem onClick={this.showLinkingForm}>
                  {getMessage("tag.action.linkToProduct")}
                </DropDownItem>
                <DropDownItem>
                  <Link to={`/catalogue/products?tagId=${id}`}>
                    {getMessage("tag.action.seeProducts")}
                  </Link>
                </DropDownItem>
                <DropDownItem>
                  <a
                    href={`${
                      IS_STAGING
                        ? "http://"
                        : this.isEnterprise
                        ? "https://"
                        : this.isHttpsEnabled
                        ? "https://"
                        : "http://"
                    }${
                      (this.config && this.config.frontendUrl) || this.domain
                    }/tag/${slug}`}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {getMessage("tag.action.viewOnWebsite")}
                  </a>
                </DropDownItem>
              </DropDown>
            </Cell>
          </Row>
        );
      }
    },
  };
};
class ImportAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShown: false,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  hideModal() {
    this.setState({
      modalShown: false,
    });
  }

  render() {
    return (
      <div className="import-tags-button">
        {this.state.modalShown && (
          <Popup
            heading={getMessage("Import Tags")}
            show={this.state.modalShown}
            close={this.hideModal}
            className="tags-import-popup"
          >
            <TagsImport uploadUrl="/catalogue-service/product-tag-file" />
          </Popup>
        )}
        <button
          className="primary button"
          onClick={(e) => {
            this.setState({
              modalShown: true,
            });
          }}
        >
          + <span className="text">{getMessage("Import Tags")}</span>
        </button>
      </div>
    );
  }
}

export default class Tags extends Component {
  componentDidMount() {
    const api = new API({ url: `/config-service/config/basic` });
    api
      .get()
      .then((res) => {
        let data = res.data && res.data.basic;
        window.localStorage.setItem("config-basic", JSON.stringify(data));
      })
      .catch((err) => {
        console.error(err);
        if (err.code === 401) throw err;
      });
  }
  render() {
    let props = this.props;
    return (
      <ListingPage
        menu={props.menu}
        className="tags-page"
        title={getMessage("tag.heading")}
        addHeading={getMessage("tag.add.text")}
        editHeading={getMessage("tag.edit.text")}
        api={{
          url: "/catalogue-service/tag",
          transform: (response) => response.data.tag,
        }}
        showLanguageDropDown
        emptyState={emptyState}
        tableProperties={tableProperties(this.state && this.state.isMobileView)}
        headerActions={({ onAction }) => (
          <React.Fragment>
            <ImportAction />
            <button
              className="primary button"
              onClick={() => {
                onAction(TABLE_ACTIONS.ADD);
              }}
            >
              + <span className="text">{getMessage("tag.add.text")}</span>
            </button>
          </React.Fragment>
        )}
        form={{
          component: TagForm,
          allowDelete: true,
          transformSubmit: (formData) => {
            let data = Object.assign({}, formData);
            data.name = data.name.trim();
            delete data.productsCount;
            return data;
          },
        }}
        filters={{
          component: TagFilters,
        }}
      />
    );
  }
}
