import React from "react";
import Image from "../../Image";
import imgHorizontal from "./imgBlank.png";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";
import "./style.css";

export default function Carousel(props) {
  const { data } = props;
  if (!data?.questions?.[0]?.question) {
    return (
      <div>
        <Image src={imgHorizontal} alt="" />
      </div>
    );
  }
  const { questions, arrowsPosition, layoutBackground = {} } = data || {};
  const { question, description } = questions?.[0] || {};
  const btnAlignCenter = arrowsPosition === "CENTER";
  const isMultiQuestion = Boolean(data?.questions?.length > 1 ?? false);

  const styleBgImg = {
    backgroundImage: `url("${layoutBackground?.backgroundImage || ""}")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  const styleBgColor = {
    backgroundColor: layoutBackground?.backgroundColor || "#fbfcfc",
  };
  const bgType = layoutBackground?.backgroundType || "COLOR";
  return (
    <div
      className="carousel-layout"
      style={bgType === "COLOR" ? styleBgColor : styleBgImg}
    >
      <h2 className="title">{question}</h2>
      <div
        className={`body ${
          !isMultiQuestion || !btnAlignCenter ? "center" : ""
        }`}
      >
        {btnAlignCenter && isMultiQuestion && (
          <div className="btn-left">
            <LeftArrow />
          </div>
        )}
        <div className="main-content">
          <div className="answer">{description}</div>
        </div>
        {btnAlignCenter && isMultiQuestion && (
          <div className="btn-right">
            <RightArrow />{" "}
          </div>
        )}
      </div>

      {!btnAlignCenter && isMultiQuestion && (
        <div className="bottom">
          <div className="btn-left">{"<"}</div>
          <div className="btn-right">{">"} </div>
        </div>
      )}
    </div>
  );
}
