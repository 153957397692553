const data = {
  "resetPassword.heading": "Forgot password?",
  "resetPassword.subheading": "Enter your details below",
  "resetPassword.buttonText": "Submit",
  "resetPassword.successMessage": "Your password has been successfully reset",
  "resetPassword.loginLinkText": "Go to login",
  "resetPassword.login": "Login",
  "resetPassword.oldUserText": "Already have an account?",
};

export default data;
