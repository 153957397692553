import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { Row, Cell } from "../../../components/Table";
import StoreSelector, {
  getDefaultStore,
} from "../../../containers/StoreSelector";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { Popup } from "../../../components/Popup";
import ReturnsForm from "./Form/ReturnResolution";
import ReturnsFilter from "./Filters";

import { getMessage } from "../../../lib/translator";
import { getPrintableTime } from "../../../lib/datetime";
import { isExtensionEnabled } from "../../../lib/auth";
import API from "../../../lib/api";

import EmptyIcon from "./returns-empty.svg";
import "./style.css";

const WastageContext = React.createContext("wastage");

const emptyState = (props) => {
  return {
    icon: EmptyIcon,
    message: getMessage("returns.emptyMessage"),
    actions: ({ apiParams, onAction }) => (
      <StoreSelector
        value={apiParams.storeId}
        stores={props.stores}
        onChange={(storeId) => {
          onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId });
        }}
      />
    ),
  };
};

class TableActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDisputePopup: false,
    };
  }
  render() {
    const { props } = this;
    const storeId = props.storeId;
    return (
      <React.Fragment>
        <WastageContext.Consumer>
          {(wastageReasons) => (
            <Popup
              show={this.state.showDisputePopup}
              heading={`${getMessage("return.view.heading")} #${
                props.referenceNumber
              }`}
              close={() => this.setState({ showDisputePopup: false })}
              className="returns-popup"
            >
              <ReturnsForm
                storeId={storeId}
                referenceNumber={props.referenceNumber}
                onAction={props.onAction}
                closeReturnsPopup={() =>
                  this.setState({ showDisputePopup: false })
                }
                wastageReasons={wastageReasons}
                onSuccess={() => this.setState({ showDisputePopup: false })}
              />
            </Popup>
          )}
        </WastageContext.Consumer>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem
            onClick={() => this.setState({ showDisputePopup: true })}
          >
            {getMessage("dispute.view")}
          </DropDownItem>
        </DropDown>
      </React.Fragment>
    );
  }
}

const tableProperties = {
  headers: [
    getMessage("dispute.referenceNumber"),
    getMessage("dispute.orderPlacedOn"),
    getMessage("dispute.actions"),
  ],
  row: ({ referenceNumber, apiParams, createdAt, onAction }) => (
    <Row>
      <Cell>
        <span className="ref-num">
          <Link to={`/operations/orders/${referenceNumber}`}>
            {referenceNumber}
          </Link>
        </span>
      </Cell>
      <Cell>
        <div>
          {createdAt ? getPrintableTime(createdAt).split(", ")[0] : null}
        </div>
        <small className="text-muted">
          {createdAt && getPrintableTime(createdAt).split(", ")[1]}
        </small>
      </Cell>
      <Cell>
        <TableActions
          referenceNumber={referenceNumber}
          onAction={onAction}
          {...apiParams}
        />
      </Cell>
    </Row>
  ),
};

class ReturnsContainer extends Component {
  componentDidMount() {
    const wasteageApi = new API({ url: "/order-service/wastage-reason" });
    wasteageApi
      .get()
      .then(
        (response) => {
          this.setState({
            wastageReasons: response.data.wastagereason,
          });
        },
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    const { props } = this;
    let params = {};
    params.storeId = getDefaultStore(props.stores).storeId;
    params.orderReturnStatus = "PENDING";
    return (
      <WastageContext.Provider value={this.state && this.state.wastageReasons}>
        <ListingPage
          menu={props.menu}
          title={getMessage("return.heading")}
          className="returns-page"
          primaryKey="referenceNumber"
          api={{
            url: "/order-service/order",
            params: params,
            transform: (response) => response.data.order,
          }}
          emptyState={emptyState(props)}
          tableProperties={tableProperties}
          headerActions={({ apiParams, onAction }) => (
            <StoreSelector
              value={apiParams.storeId}
              stores={props.stores}
              onChange={(storeId) => {
                onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId });
              }}
            />
          )}
          filters={{
            component: ReturnsFilter,
          }}
          storeDependent
        />
      </WastageContext.Provider>
    );
  }
}

function getReturnableStatuses() {
  let returnableStatuses = ["COMPLETED"];
  returnableStatuses.push("PACKED");
  if (isExtensionEnabled("LogisticsSupport")) {
    returnableStatuses.push("DISPATCHED");
  }
  return returnableStatuses;
}

export default ReturnsContainer;

export { getReturnableStatuses };
