import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PublicPage from "../../../containers/PublicPage/index";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";
import Loader from "../../../components/Loader";
import VerifiedImage from "./images/verified.svg";
import NotVerifiedImg from "./images/notVerified.svg";
import "./verify.css";

export default function Verify(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");

  useEffect(() => {
    const {
      location: { search = "" },
    } = props;
    if (search.includes("?e=")) {
      const verifyApi = new API({ url: "/account-service/verify" });
      const params = { hash: decodeURIComponent(search.split("=")[1]) };
      setLoading(true);
      verifyApi
        .get(params)
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          const errorMessage =
            error.message?.split(":")?.[1] ??
            error.message ??
            getMessage("error.generic");
          setLoading(false);
          setError(errorMessage);
        });
    }
  }, [props]);

  const handleResendClick = () => {
    setSubmitting(true);
    setFormSuccess("");
    setFormError("");
    const api = new API({ url: "/account-service/resend-verification-email" });
    api
      .post({})
      .then((response) => {
        setSubmitting(false);
        setFormSuccess(response?.message);
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        setFormError(error?.message ?? getMessage("error.generic"));
      });
  };

  const verifyImgSrc = error ? NotVerifiedImg : VerifiedImage;
  const verifyImgAlt = error
    ? getMessage("email.not.verified.text")
    : getMessage("email.verified.text");
  const verifyEmailText = error ? error : getMessage("email.verified");

  return (
    <PublicPage className="eazy-page">
      {loading ? (
        <Loader />
      ) : (
        <div className="verify">
          <img src={verifyImgSrc} alt={verifyImgAlt} />
          <header className="header">
            <h1
              className={["heading", error ? "verify-error-text" : ""].join(
                " "
              )}
            >
              {verifyEmailText}
            </h1>
            {!error && (
              <h2 className="subheading">
                {getMessage("password.sent.to.mobile")}
              </h2>
            )}
          </header>
          {error ? (
            <>
              <button className="button" onClick={handleResendClick}>
                {submitting ? "..." : getMessage("resend.verification.link")}
              </button>
              {(formError || formSuccess) && (
                <div
                  className={[
                    "verify-email-resend-wrp",
                    formError ? "verify-error-text" : "verify-success-text",
                  ].join(" ")}
                >
                  {formError ? formError : formSuccess}
                </div>
              )}
            </>
          ) : (
            <Link to="/login" className="button">
              {getMessage("login.submitText")}
            </Link>
          )}
        </div>
      )}
    </PublicPage>
  );
}
