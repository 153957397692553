import React from "react";
import { BaseForm, Radio } from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";

class DisputesFilter extends BaseForm {
  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;

    return (
      <Form>
        <div className="form-fields">
          <Radio
            label={getMessage("dispute.status")}
            name="status"
            options={[
              {
                text: getMessage("dispute.pending"),
                value: "PENDING",
              },
              {
                text: getMessage("dispute.approved"),
                value: "APPROVED",
              },
              {
                text: getMessage("dispute.rejected"),
                value: "REJECTED",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["disputeStatus"],
              defaultValue: "PENDING",
            })}
          />
        </div>
        <SubmitButton>{getMessage("dispute.save")}</SubmitButton>
      </Form>
    );
  }
}

export default DisputesFilter;
