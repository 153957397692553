import React, { Component } from "react";
import StoreSelector, {
  makestoreDependentComponent,
  getDefaultStore,
} from "../../../../containers/StoreSelector";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import EmptyState from "../../../../components/EmptyState";
import "./style.css";

import EmptyIcon from "../EditSlots/empty.svg"; // TODO: Change this
import { getMessage } from "../../../../lib/translator";
import {
  formatTime,
  getMinutes,
  getPrintableTime,
  getStandradDateTime,
  localTimeToUtc,
  utcToLocalTime,
} from "../../../../lib/datetime";
import { BaseForm, Checkbox, Select } from "../../../../components/Form";
import { Dialog } from "../../../../components/Popup";
import moment from "moment";
import API from "../../../../lib/api";
import { getExtensionDetails, isExtensionEnabled } from "../../../../lib/auth";
import Loader from "../../../../components/Loader";
import { getCutOffMins } from "../StoreSlotConfigure/Form";

class InterfaceContainer extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      values: { DELIVERY: [], PICKUP: [] },
      prevValues: { DELIVERY: [], PICKUP: [] },
      visibleDays: "",
    };

    this.changeStore = this.changeStore.bind(this);
    this.checkIfValueChanged = this.checkIfValueChanged.bind(this);
    this.findIndex = this.findIndex.bind(this);
    this.fetchSlotDetails = this.fetchSlotDetails.bind(this);
    this.handleOrderSelect = this.handleOrderSelect.bind(this);
  }

  handleOrderSelect(val) {
    this.setState({
      selectedOption: val,
    });
  }

  changeStore(storeId) {
    this.setState({ storeId }, this.fetchSlotDetails);
  }

  convertDataToMapState(data) {
    let newState = JSON.parse(JSON.stringify(data));
    newState = Object.keys(data).map(
      (date) =>
        date &&
        data[date].sort((time1, time2) => {
          if (time1.startTime < time2.startTime) {
            return -1;
          } else {
            return 1;
          }
        })
    );

    let modelData = {};
    Object.keys(data).forEach((day) => {
      data[day].forEach((item) => {
        if (item.type === "STANDARD") {
          const localDate = getStandradDateTime(
            utcToLocalTime,
            `${day} ${item.startTime}`
          ).split(" ")[0];
          if (localDate >= moment().format("YYYY-MM-DD")) {
            modelData[localDate] = [];
          }
        } else {
          modelData[day] = [];
        }
      });
    });

    Object.keys(data).forEach((day) => {
      data[day].forEach((item) => {
        if (item.type === "STANDARD") {
          const localDate = getStandradDateTime(
            utcToLocalTime,
            `${day} ${item.startTime}`
          ).split(" ")[0];
          if (localDate >= moment().format("YYYY-MM-DD")) {
            if (localDate !== day) {
              if (
                modelData[day].filter((slot) => slot.id === item.id).length ===
                0
              ) {
                modelData[day].push({});
              }
            }
            modelData[localDate].push(item);
          }
        } else {
          modelData[day].push(item);
        }
      });
    });

    delete modelData[
      moment().add(this.state.visibleDays, "days").format("YYYY-MM-DD")
    ];

    newState = Object.keys(modelData).map(
      (date) =>
        date &&
        modelData[date].sort((time1, time2) => {
          if (time1.startTime < time2.startTime) {
            return -1;
          } else {
            return 1;
          }
        })
    );

    newState = Object.keys(modelData).map((date) => {
      return modelData[date].map((slot) => {
        slot.date = date;
        slot.status = slot.status === "ENABLED";
        return slot;
      });
    });
    return newState;
  }

  fetchSlotDetails() {
    let { pickupSupported, deliverySupported } = this.state;
    this.setState({ loading: true });
    let storeId = getDefaultStore(this.props.stores).storeId;
    this.visibleDaysApi = new API({
      url: `/account-service/extension/${
        getExtensionDetails("DeliverySlots").id
      }`,
    });
    this.visibleDaysApi
      .get()
      .then(async (response) => {
        let visibleDays =
          response.data &&
          response.data.extension.config &&
          response.data.extension.config.globalConfig.visibleDaysForSlot;
        this.setState({ visibleDays });
        let duration = moment.duration({ days: visibleDays - 1 });
        if (deliverySupported) {
          this.slotApi = new API({
            url: `/order-service/store/${storeId}/slot`,
          });
          let slotApiparams = {
            startDate: moment().format("YYYY-MM-DD"),
            endDate: moment(new Date()).add(duration).format("YYYY-MM-DD"),
          };
          await this.slotApi
            .get(slotApiparams)
            .then((response) => {
              let modifiedResponse = this.convertDataToMapState(response.data);
              let newvalues = JSON.parse(JSON.stringify(this.state.values));
              newvalues["DELIVERY"] = JSON.parse(
                JSON.stringify(modifiedResponse)
              );
              this.setState((prevState) => {
                return {
                  values: newvalues,
                  dates: Object.keys(response.data),
                  slots: Object.keys(response.data).length
                    ? response.data[Object.keys(response.data)[0]]
                    : [],
                  loading: false,
                  prevValues: {
                    ...prevState.prevValues,
                    DELIVERY: JSON.parse(JSON.stringify(modifiedResponse)),
                  },
                };
              });
            })
            .catch((error) => {
              console.error(error);
              if (error.code === 401 || error.code === 403) {
                throw error;
              }
            });
        }
        if (pickupSupported) {
          this.pickupSlotsApi = new API({
            url: `/order-service/store/${storeId}/slot`,
          });
          let pickupApiParams = {
            startDate: moment().format("YYYY-MM-DD"),
            endDate: moment(new Date()).add(duration).format("YYYY-MM-DD"),
            orderType: "PICKUP",
          };
          await this.pickupSlotsApi
            .get(pickupApiParams)
            .then((response) => {
              let modifiedResponse = this.convertDataToMapState(response.data);
              let newvalues = JSON.parse(JSON.stringify(this.state.values));
              newvalues["PICKUP"] = JSON.parse(
                JSON.stringify(modifiedResponse)
              );
              this.setState((prevState) => {
                return {
                  pickUpDates: Object.keys(response.data),
                  values: newvalues,
                  pickupSlots: Object.keys(response.data).length
                    ? response.data[Object.keys(response.data)[0]]
                    : [],
                  loading: false,
                  prevValues: {
                    ...prevState.prevValues,
                    PICKUP: JSON.parse(JSON.stringify(modifiedResponse)),
                  },
                };
              });
            })
            .catch((error) => {
              console.error(error);
              if (error.code === 401 || error.code === 403) {
                throw error;
              }
            });
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.code === 401 || error.code === 403) {
          throw error;
        } else if (error.message === "cancelled") {
          return;
        }
        this.setState({
          error: error.message,
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.visibleDaysApi && this.visibleDaysApi.cancel();
    this.slotApi && this.slotApi.cancel();
    this.pickupSlotsApi && this.pickupSlotsApi.cancel();
  }

  transformResponse(response) {
    let modifiedRule = response.data.storeslotrule;
    modifiedRule.cutOff = getCutOffMins(
      modifiedRule.slot.startTime,
      modifiedRule.cutOff
    );
    modifiedRule.slotName =
      modifiedRule.slot.type === "STANDARD"
        ? `${formatTime(modifiedRule.slot.startTime)} - ${formatTime(
            modifiedRule.slot.endTime
          )}`
        : `${getMessage("slots.asap")} ${
            modifiedRule.slot.endTime.split(":")[2]
          } ${getMessage("slots.asap.minute")}`;
    return modifiedRule;
  }

  findIndex(item) {
    let id = item.data.storeslotrule.slot.id;
    return this.state.values.findIndex((value) => value.slot.id === id);
  }

  checkIfValueChanged(data, type) {
    let changedSlots = [];
    data.forEach((date, dateIndex) => {
      date.forEach((slot, slotIndex) => {
        if (
          slot.status !==
          this.state.prevValues[type][dateIndex][slotIndex].status
        ) {
          changedSlots.push(slot);
        }
      });
    });
    return changedSlots;
  }

  onSubmit(formData) {
    let defaultSelected =
      (this.options && this.options.length > 0 && this.options[0].value) ||
      null;
    let val = this.state.selectedOption || defaultSelected;
    this.setState({ submitting: true });
    let data = JSON.parse(JSON.stringify(formData));
    let changedRules = this.checkIfValueChanged(
      JSON.parse(JSON.stringify(data[val])),
      val
    );
    let storeId = getDefaultStore(this.props.stores).storeId;
    changedRules = changedRules.map((rule) => {
      rule.status = rule.status ? "ENABLED" : "DISABLED";
      rule.slotId = rule.id || rule.slotId;
      delete rule.endTime;
      delete rule.startTime;
      delete rule.type;
      delete rule.id;
      return rule;
    });
    /* Grouping by slotId */
    let modifiedChangedRulesObj = {};
    changedRules.forEach((rule) => {
      let preferredDate;
      if (rule.slotId in modifiedChangedRulesObj) {
        modifiedChangedRulesObj[rule.slotId][rule.date] = rule.status;
      } else {
        if (rule.orderType === "DELIVERY") {
          this.state.slots.forEach((slot) => {
            if (slot.id === rule.slotId) {
              if (slot.startTime) {
                const tempTime = getStandradDateTime(
                  utcToLocalTime,
                  `${new Date().toISOString().split("T")[0]} ${slot.startTime}`
                ).split(" ");
                const tempDate = getStandradDateTime(
                  localTimeToUtc,
                  `${rule.date} ${tempTime[1]}`
                ).split(" ");
                preferredDate = tempDate[0].trim();
              } else {
                preferredDate = rule.date;
              }
            }
          });
        }

        if (rule.orderType === "PICKUP") {
          this.state.pickupSlots.forEach((slot) => {
            if (slot.id === rule.slotId) {
              if (slot.startTime) {
                const tempTime = getStandradDateTime(
                  utcToLocalTime,
                  `${new Date().toISOString().split("T")[0]} ${slot.startTime}`
                ).split(" ");
                const tempDate = getStandradDateTime(
                  localTimeToUtc,
                  `${rule.date} ${tempTime[1]}`
                ).split(" ");
                preferredDate = tempDate[0].trim();
              } else {
                preferredDate = rule.date;
              }
            }
          });
        }
        modifiedChangedRulesObj[rule.slotId] = {};
        modifiedChangedRulesObj[rule.slotId][preferredDate] = rule.status;
      }
    });
    let modifiedChangedRules = Object.keys(modifiedChangedRulesObj).map(
      (slotId) => {
        return {
          slotId,
          slotDetails: modifiedChangedRulesObj[slotId],
        };
      }
    );

    if (modifiedChangedRules.length) {
      Promise.all(
        modifiedChangedRules.map((params) => {
          let api = new API({ url: `/order-service/store/${storeId}/slot` });
          return api.put(params);
        })
      )
        .then((response) => {
          this.setState((prevState) => {
            return {
              submitting: false,
              successMessage: true,
              values: { ...prevState.values, [val]: data[val] },
              prevValues: {
                ...prevState.prevValues,
                [val]: JSON.parse(JSON.stringify(data[val])),
              },
              formError: null,
            };
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            submitting: false,
            successMessage: false,
            formError: error.message,
          });
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
        });
    } else {
      this.setState({
        submitting: false,
        successMessage: true,
        formError: null,
      });
    }
  }

  async componentDidMount() {
    this.setState({ showSelector: true });
    this.configApi = new API({ url: "/account-service/config/order" });
    await this.configApi
      .get()
      .then((response) => {
        const orderTypes = response.data.config.order.orderTypes;
        if (orderTypes && orderTypes.includes("PICKUP")) {
          this.setState({
            pickupSupported: true,
          });
        }
        if (orderTypes && orderTypes.includes("DELIVERY")) {
          this.setState({
            deliverySupported: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.code === 401 || error.code === 403) {
          throw error;
        } else {
          this.setState({
            formError: error.message,
          });
        }
      });
    if (this.state.pickupSupported && this.state.deliverySupported) {
      this.options = [
        { text: "DELIVERY", value: "DELIVERY" },
        { text: "PICKUP", value: "PICKUP" },
      ];
    } else if (this.state.pickupSupported) {
      this.options = [{ text: "PICKUP", value: "PICKUP" }];
    } else if (this.state.deliverySupported) {
      this.options = [{ text: "DELIVERY", value: "DELIVERY" }];
    }
    this.fetchSlotDetails();
  }

  render() {
    let { SubmitButton } = this.buttons;
    let { Form } = this.components;
    let defaultSelected =
      (this.options && this.options.length > 0 && this.options[0].value) ||
      null;
    let val = this.state.selectedOption || defaultSelected;
    let tableContent = null;
    if (val === "DELIVERY") {
      tableContent = (
        <React.Fragment>
          {this.state.values["DELIVERY"].length ? (
            <div className="slot-table-header">
              <div className="slot-name-header">
                {getMessage("slots.heading")}
              </div>
              {this.state.slots &&
                this.state.slots.map((slot, index) => (
                  <div className="slot-name" key={`slot-name-${index}`}>
                    {slot.type === "STANDARD"
                      ? `${
                          getPrintableTime(
                            getStandradDateTime(
                              utcToLocalTime,
                              `${new Date().toISOString().split("T")[0]} ${
                                slot.startTime
                              }`
                            )
                          ).split(", ")[1]
                        }
                   - 
                   ${
                     getPrintableTime(
                       getStandradDateTime(
                         utcToLocalTime,
                         `${new Date().toISOString().split("T")[0]} ${
                           slot.endTime
                         }`
                       )
                     ).split(", ")[1]
                   }`
                      : `${getMessage("slots.asap")} ${getMinutes(
                          slot.endTime
                        )} ${getMessage("slots.asap.minute")}`}
                  </div>
                ))}
            </div>
          ) : null}
          <div className="store-slot-rules">
            {this.state.values["DELIVERY"].length > 0 ? (
              this.state.values["DELIVERY"].map((date, dateIndex) => (
                <div
                  className="store-slot-container"
                  key={`slotindex-${dateIndex}`}
                >
                  <div className="slot-date">
                    {this.state.dates[dateIndex] ===
                    moment().format("YYYY-MM-DD")
                      ? getMessage("slots.today")
                      : this.state.dates[dateIndex] ===
                        moment().add(1, "day").format("YYYY-MM-DD")
                      ? getMessage("slots.tomorrow")
                      : moment(this.state.dates[dateIndex]).format(
                          "ddd, MMM Do"
                        )}
                  </div>
                  {this.state.values["DELIVERY"][dateIndex] &&
                    this.state.values["DELIVERY"][dateIndex].map(
                      (slots, slotIndex) => (
                        <Checkbox
                          key={`slot-checkbox-DELIVERY-${dateIndex}-${slotIndex}`}
                          // inlineLabel={getMessage('slots.disable')}
                          name={`status-slot-DELIVERY-${dateIndex}-${slotIndex}`}
                          {...this.generateStateMappers({
                            stateKeys: [
                              "DELIVERY",
                              dateIndex,
                              slotIndex,
                              "status",
                            ],
                            loseEmphasisOnFill: true,
                          })}
                        />
                      )
                    )}
                </div>
              ))
            ) : (
              <div className="no-slots">
                {getMessage("slots.DELIVERY.empty")}
              </div>
            )}
          </div>
        </React.Fragment>
      );
    }

    if (val === "PICKUP") {
      tableContent = (
        <React.Fragment>
          {this.state.values["PICKUP"].length ? (
            <div className="slot-table-header">
              <div className="slot-name-header">
                {getMessage("slots.heading")}
              </div>
              {this.state.pickupSlots &&
                this.state.pickupSlots.map((slot, index) => (
                  <div className="slot-name" key={`slot-name-${index}`}>
                    {slot.type === "STANDARD"
                      ? `${
                          getPrintableTime(
                            getStandradDateTime(
                              utcToLocalTime,
                              `${new Date().toISOString().split("T")[0]} ${
                                slot.startTime
                              }`
                            )
                          ).split(", ")[1]
                        }
                 - 
                 ${
                   getPrintableTime(
                     getStandradDateTime(
                       utcToLocalTime,
                       `${new Date().toISOString().split("T")[0]} ${
                         slot.endTime
                       }`
                     )
                   ).split(", ")[1]
                 }`
                      : `${getMessage("slots.asap")} ${getMinutes(
                          slot.endTime
                        )} ${getMessage("slots.asap.minute")}`}
                  </div>
                ))}
            </div>
          ) : null}
          <div className="store-slot-rules">
            {this.state.values["PICKUP"].length > 0 ? (
              this.state.values["PICKUP"].map((date, dateIndex) => (
                <div
                  className="store-slot-container"
                  key={`slotindex-${dateIndex}`}
                >
                  <div className="slot-date">
                    {this.state.pickUpDates[dateIndex] ===
                    moment().format("YYYY-MM-DD")
                      ? getMessage("slots.today")
                      : this.state.pickUpDates[dateIndex] ===
                        moment().add(1, "day").format("YYYY-MM-DD")
                      ? getMessage("slots.tomorrow")
                      : moment(this.state.pickUpDates[dateIndex]).format(
                          "ddd, MMM Do"
                        )}
                  </div>
                  {this.state.values["PICKUP"][dateIndex] &&
                    this.state.values["PICKUP"][dateIndex].map(
                      (slots, slotIndex) => (
                        <Checkbox
                          key={`slot-checkbox-PICKUP-${dateIndex}-${slotIndex}`}
                          // inlineLabel={getMessage('slots.disable')}
                          name={`status-slot-PICKUP-${dateIndex}-${slotIndex}`}
                          {...this.generateStateMappers({
                            stateKeys: [
                              "PICKUP",
                              dateIndex,
                              slotIndex,
                              "status",
                            ],
                            loseEmphasisOnFill: true,
                          })}
                        />
                      )
                    )}
                </div>
              ))
            ) : (
              <div className="no-slots">{getMessage("slots.PICKUP.empty")}</div>
            )}
          </div>
        </React.Fragment>
      );
    }
    return (
      <div className="slot-store-page">
        {this.state.showSelector && isExtensionEnabled("MultiStoreSupport") && (
          <StoreSelector
            value={
              this.state.storeID || getDefaultStore(this.props.stores).storeId
            }
            onChange={this.changeStore}
            stores={this.props.stores}
          />
        )}
        <h1>{getMessage("slots.store.blocking.title")}</h1>
        {this.state.loading ? (
          <Loader />
        ) : !this.state.values["DELIVERY"].length &&
          !this.state.values["PICKUP"].length ? (
          <EmptyState
            icon={EmptyIcon}
            message={getMessage("slots.blocking.empty.message")}
          />
        ) : (
          <Form>
            <div className="orderType">
              <Select
                name="orderType"
                required="true"
                options={this.options}
                placeholder={"OrderType"}
                value={val}
                onChange={this.handleOrderSelect}
              />
            </div>
            <div className="table-container">{tableContent}</div>
            {(!this.state.values["DELIVERY"].length &&
              !this.state.values["PICKUP"].length) ||
            val === null ? (
              <EmptyState
                icon={EmptyIcon}
                message={getMessage("slots.blocking.empty.message")}
              />
            ) : null}
            {this.state.values["DELIVERY"].length ||
            this.state.values["PICKUP"].length ? (
              <div className="response-status">
                {this.state.formError && (
                  <div className="form-error">{this.state.formError}</div>
                )}
                {this.state.successMessage && (
                  <Dialog
                    show={this.state.successMessage}
                    close={() => this.setState({ successMessage: null })}
                    title={getMessage("customer.address.success")}
                    information={getMessage("extension.slots.saved")}
                    closeText={getMessage("customer.address.okay")}
                    className="success"
                  />
                )}
                {this.state.values[val].length ? (
                  <>
                    <SubmitButton disabled={this.state.submitting}>
                      {getMessage("deliveryArea.slots.save")}
                    </SubmitButton>
                    <button
                      type="button"
                      className="button"
                      onClick={this.props.history.goBack}
                    >
                      {getMessage("slots.cancel")}
                    </button>
                  </>
                ) : null}
              </div>
            ) : null}
          </Form>
        )}
      </div>
    );
  }
}

const InterfaceWithStores = makestoreDependentComponent(InterfaceContainer);

class StoreSlotConfiguration extends Component {
  render() {
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <InterfaceWithStores {...this.props} />
      </AuthenticatedPage>
    );
  }
}

export default StoreSlotConfiguration;
