import { getStores } from "../../lib/auth";
import { get } from "../../lib/storage";

function getDefaultStore(stores) {
  let result = {};
  let orgStores = getStores();
  if (stores && stores.length && get("store")) {
    let store = get("store");
    result = {
      storeId: Number(store),
    };
    return result;
  }
  if (stores && stores.length) {
    result = {
      storeId: stores[0].id,
    };
  }
  if (orgStores && orgStores.length) {
    result = {
      storeId: orgStores[0].id,
    };
  }
  return result;
}

export default getDefaultStore;
