import React from "react";
import { getMessage } from "../../../../../lib/translator";
import { isEnterprise } from "../../../../../lib/auth";

import "./style.css";

const InvoiceSummary = (props) => {
  const { currency, data } = props;
  let {
    shipping = 0.0,
    paid = 0.0,
    couponDiscount = 0.0,
    invoiceAmount = 0.0,
    refundAmount,
    loadedFromInvoice,
    editing = false,
    pendingAmount = 0.0,
    surcharge = 0,
    orderDiscount = [],
    clickAndCollectCharges = 0.0,
    taxBreakdown,
  } = data;
  couponDiscount = Number(couponDiscount);
  paid = Number(paid);
  let paymentAmountText = null;
  pendingAmount = Number(pendingAmount);
  if (!Number(!!refundAmount && Number(refundAmount))) {
    if (invoiceAmount > paid) {
      let amount = (invoiceAmount - paid).toFixed(2);
      if (!editing && pendingAmount >= 0) {
        amount = pendingAmount.toFixed(2);
      }
      paymentAmountText = (
        <div className="flex-around payment-amount">
          <div className="text-muted">
            {getMessage("order.details.summary.pendingAmount")}
          </div>
          <div className="payment-pending-amount">
            <span>{currency.symbol} </span>
            <span className="section-value">{amount}</span>
          </div>
        </div>
      );
    }
  } else {
    paymentAmountText = (
      <div className="flex-around payment-amount">
        <div className="text-muted">
          {getMessage("order.details.summary.refundAmount")}
        </div>
        <div className="payment-refund-amount">
          <span>{currency.symbol} </span>
          <span className="section-value">
            {Number(refundAmount).toFixed(2)}
          </span>
        </div>
      </div>
    );
  }

  const orderSlotDiscount = orderDiscount
    .filter(({ reason }) => reason === "ORDER_SLOT_DISCOUNT")
    .reduce(
      (totalOrderDiscount, { discount }) =>
        totalOrderDiscount + Number(discount),
      0
    );

  const shippingDiscount = orderDiscount
    .filter(({ reason }) => reason === "HANDLING_DISCOUNT")
    .reduce(
      (totalOrderDiscount, { discount }) =>
        totalOrderDiscount + Number(discount),
      0
    );

  const allTaxes = {}; // will Store unique type of taxes
  const reducer = (accumulator, currentValue) =>
    +(+accumulator + +currentValue).toFixed(2); // utility function
  Object.keys(taxBreakdown).forEach((obj) =>
    Object.keys(JSON.parse(obj)).forEach((tax) => {
      if (typeof allTaxes[tax] === "undefined") allTaxes[tax] = 1;
    })
  ); // Store unique type of taxes in allTaxes
  let totalTaxValue = new Array(Object.keys(allTaxes).length).fill(0); // will store Total tax amounts for each type of taxes
  const allTaxPercentages = Object.keys(taxBreakdown).map((taxObj) =>
    Object.keys(allTaxes).map((tax) =>
      typeof JSON.parse(taxObj)[tax] === "undefined"
        ? 0
        : +JSON.parse(taxObj)[tax]
    )
  ); // Tax percentages (taxObj - stores tax percentage for each tax for a single tax Slab like {cgst: 14, igst: 9} )
  const priceBeforeTax = Object.keys(taxBreakdown).map((taxObj) =>
    (
      (taxBreakdown[taxObj] * 100) /
      ((Object.values(JSON.parse(taxObj)).length > 0
        ? +Object.values(JSON.parse(taxObj)).reduce(reducer)
        : 0) +
        100)
    ).toFixed(2)
  ); // Calculate Price before tax for each Tax Slab (taxObj)
  const allTaxAmounts = Object.keys(taxBreakdown).map((taxObj, index) =>
    Object.keys(allTaxes).map((tax) =>
      typeof JSON.parse(taxObj)[tax] === "undefined"
        ? 0
        : +(priceBeforeTax[index] * (+JSON.parse(taxObj)[tax] / 100)).toFixed(2)
    )
  ); // Tax Amounts
  const netAmounts = Object.values(taxBreakdown);
  allTaxAmounts.forEach((taxes) =>
    taxes.forEach((tax, index) => (totalTaxValue[index] += +tax))
  ); // Calculate Total Tax amounts for each type of Tax
  const zeroTaxes = !Object.keys(allTaxes).length;

  return (
    <div className="invoice-container">
      <div className="tax-summary">
        {!isEnterprise() && (
          <table className="table">
            <caption className="caption">
              {getMessage("order.details.summary.taxTitle")}
            </caption>
            <tr className="heading-row">
              {zeroTaxes && (
                <th className="heading">
                  %{getMessage("order.details.summary.tax")}
                </th>
              )}
              {Object.keys(allTaxes).map((tax) => (
                <th className="heading text-uppercase">%{tax}</th>
              ))}
              <th className="heading">
                {getMessage("order.details.summary.price")}
              </th>
              {zeroTaxes && (
                <th className="heading">
                  {getMessage("order.details.summary.taxAmount")}
                </th>
              )}
              {Object.keys(allTaxes).map((tax) => (
                <th className="heading text-uppercase">
                  {currency.symbol} {tax}
                </th>
              ))}
              <th className="heading">
                {getMessage("order.details.summary.netAmount")}
              </th>
            </tr>
            {allTaxPercentages.map((taxes, index) => (
              <tr>
                {zeroTaxes && <td className="column">0%</td>}
                {taxes.map((tax) => (
                  <td className="column">{tax} %</td>
                ))}{" "}
                {/* Tax Percentages */}
                <td className="column">{`${currency.symbol} ${priceBeforeTax[index]}`}</td>
                {zeroTaxes && (
                  <td className="column">{`${currency.symbol}0`}</td>
                )}
                {allTaxAmounts[index].map((taxAmount) => (
                  <td className="column">
                    {currency.symbol} {+taxAmount.toFixed(2)}
                  </td>
                ))}{" "}
                {/* Tax Amounts */}
                <td className="column">{`${currency.symbol} ${+netAmounts[
                  index
                ].toFixed(2)}`}</td>
              </tr>
            ))}
            <tr>
              {zeroTaxes && <td className="column"></td>}
              {Object.keys(allTaxes).map((tax) => (
                <td className="column text-uppercase"></td>
              ))}
              <td className="column">
                {getMessage("order.details.summary.totalTax")} :
              </td>
              {zeroTaxes && (
                <td className="column">{`${currency.symbol} 0`}</td>
              )}
              {totalTaxValue.map((taxValue) => (
                <td className="column">
                  {currency.symbol} {+taxValue.toFixed(2)}
                </td>
              ))}
              <td className="column"></td>
            </tr>
          </table>
        )}
      </div>

      <div className="invoice-summary">
        <div className="flex-around shipping-charge">
          <div className="text-muted">
            {props.data.type === "DELIVERY"
              ? getMessage("order.details.summary.shippingCharge")
              : getMessage("order.details.summary.collectionCharge")}
          </div>
          <div>
            <span>{currency.symbol} </span>
            <span className="section-value">
              {props.data.type === "DELIVERY"
                ? Number(shipping).toFixed(2)
                : Number(clickAndCollectCharges).toFixed(2)}
            </span>
          </div>
        </div>
        {shippingDiscount > 0 && (
          <div className="flex-around shipping-discount">
            <div className="text-muted">
              {props.data.type === "DELIVERY"
                ? getMessage("order.details.summary.shippingDiscount")
                : getMessage("order.details.summary.collectionDiscount")}
            </div>
            <div>
              <span>-{currency.symbol} </span>
              <span className="section-value">
                {Number(shippingDiscount || 0).toFixed(2)}
              </span>
            </div>
          </div>
        )}
        {Boolean(surcharge) && (
          <div className="flex-around slot-charge">
            <div className="text-muted">
              {getMessage("order.details.summary.slotCharge")}
            </div>
            <div>
              {surcharge < 0 && <span>-</span>}
              <span>{currency.symbol} </span>
              <span className="section-value">
                {Number(surcharge).toFixed(2)}
              </span>
            </div>
          </div>
        )}
        {orderSlotDiscount > 0 && (
          <div className="flex-around slot-discount">
            <div className="text-muted">
              {getMessage("order.details.summary.slotDiscount")}
            </div>
            <div>
              <span>-{currency.symbol} </span>
              <span className="section-value">
                {Number(orderSlotDiscount).toFixed(2)}
              </span>
            </div>
          </div>
        )}
        {couponDiscount > 0 && (
          <div className="flex-around discount-amount">
            <div className="text-muted">
              {getMessage("order.details.summary.coupondiscount")}
            </div>
            <div>
              <span>-{currency.symbol}</span>
              <span className="section-value">
                {Number(couponDiscount).toFixed(2)}
              </span>
            </div>
          </div>
        )}
        <div className="flex-around total-amount">
          <div className="text-muted">
            {getMessage("order.details.summary.totalAmount")}:
          </div>
          <div>
            <span>{currency.symbol} </span>
            <span className="section-value">{invoiceAmount}</span>
          </div>
        </div>
        {paymentAmountText}
        {loadedFromInvoice && (
          <div className="tax-disclaimer">
            {getMessage("order.details.invoice.taxDisclaimer")}
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceSummary;
