import React, { Component } from "react";
import Table, { Row } from "../../../../../components/Table";
import { getMessage } from "../../../../../lib/translator";
import "./style.css";

const PackageDataList = (props) => {
  const { packageData, handleCreatePackage, handleSetPackageName } = props;
  return (
    <Table>
      <div>
        {packageData?.map((packageItem, index) => {
          return (
            <Row key={packageItem.id || index}>
              <span
                onClick={() => handleSetPackageName(packageItem.type)}
                className="package-type"
              >
                {packageItem.type}
              </span>
            </Row>
          );
        })}
      </div>
      <Row className="create-package-wrp">
        <div className="create-package" onClick={() => handleCreatePackage()}>
          +
          <span className="text">
            {getMessage("order.pack.create.package")}
          </span>
        </div>
      </Row>
    </Table>
  );
};

class AddPackageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPackageDataList: false,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if ("select-a-package" !== event.target.id) {
      this.setState({
        showPackageDataList: false,
      });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  handleLabelChange = (e) => {
    const value = e.target?.value;
    const currentIndex = this.props.currentIndex;
    this.props.handleUpdateState(
      ["packageList", currentIndex, "labelName"],
      value
    );
  };
  handleWeightChange = (e) => {
    const value = e.target?.value;
    const currentIndex = this.props.currentIndex;
    this.props.handleUpdateState(
      ["packageList", currentIndex, "weight"],
      value
    );
  };
  handleSetPackageName = (packageName) => {
    const currentIndex = this.props.currentIndex;
    const statePathKey = ["packageList", currentIndex, "packageName"];
    this.props.handleUpdateState(statePathKey, packageName);
  };
  handleSelectPackage = (event) => {
    this.setState({
      showPackageDataList: !this.state.showPackageDataList,
    });
    event.stopPropagation();
    event.preventDefault();
  };
  childMethod(packageName) {
    this.handleSetPackageName(packageName);
  }
  render() {
    const {
      currentPackage: { packageName },
      currentIndex,
      packageData,
      handleCreatePackage,
      handleGetState,
    } = this.props;
    const labelNameValue = handleGetState([
      "packageList",
      currentIndex,
      "labelName",
    ]);
    const weightValue = handleGetState(["packageList", currentIndex, "weight"]);
    return (
      <div className="add-package-view">
        <label className="select-package-header">
          {getMessage("order.pack.select.package.label")}
        </label>
        <div className={`select-package ${packageName ? "label-active" : ""}`}>
          <small id="select-a-package" onClick={this.handleSelectPackage}>
            {packageName ? packageName : getMessage("product.form.select")}
          </small>
          {this.state.showPackageDataList && (
            <PackageDataList
              packageData={packageData}
              handleCreatePackage={handleCreatePackage}
              handleSetPackageName={this.handleSetPackageName}
            />
          )}
        </div>

        {packageName && (
          <>
            <div className="item-label">
              <label className="header">
                {getMessage("order.pack.input.label.name.header")}
              </label>
              <input
                placeholder={getMessage(
                  "order.pack.input.label.name.placeholder"
                )}
                className="item-label-input"
                type="text"
                value={labelNameValue || ""}
                onChange={this.handleLabelChange}
              />
            </div>

            <div className="item-weight">
              <label className="header">
                {getMessage("order.pack.input.weight.header")}
              </label>
              <input
                placeholder={getMessage("order.pack.input.weight.placeholder")}
                className="item-weight-input"
                type="number"
                value={weightValue || ""}
                onChange={this.handleWeightChange}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default AddPackageView;
