import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getMessage } from "../../../lib/translator";
import { getSession, saveSession } from "../../../lib/auth";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import API from "../../../lib/api";
import Loader from "../../../components/Loader";
import "./style.css";

export default class extends Component {
  constructor(props) {
    super(props);
    const org = getSession().organization;
    this.state = {
      themes: null,
      submitting: false,
      themeChosen: false,
      currentTheme: org.theme,
    };
    this.org = org;
  }

  componentDidMount() {
    const api = new API({
      url: "/account-service/theme",
    });
    api
      .get()
      .then(
        (response) => {
          this.setState({
            themes: response.data.theme,
          });
        },
        (error) => console.error(error)
      )
      .catch((error) => {
        console.error(error);
      });
  }

  selectTheme(id) {
    this.setState({
      submitting: true,
    });
    const api = new API({
      url: "/account-service/organization",
    });
    const params = {
      id: this.org.id,
      themeId: id,
    };
    api
      .put(params)
      .then(
        (response) => {
          saveSession({
            organization: response.data.organization,
          });
          this.setState({
            themeChosen: true,
          });
        },
        (error) => {
          this.setState({
            submitting: false,
          });
          console.error(error);
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { props } = this;
    if (this.state.themeChosen) {
      return <Redirect to="/settings/themes" />;
    }
    return (
      <AuthenticatedPage menu={props.menu} className="website">
        <h1 className="title">{getMessage("themes.change.heading")}</h1>
        <div className="current-theme media">
          <img
            className="media-figure"
            src={this.state.currentTheme.image.desktop}
            alt="current theme"
          />
          <div className="media-body">
            <h2>
              {getMessage("themes.subheading", {
                name: this.state.currentTheme.name,
              })}
            </h2>
            <p>{this.state.currentTheme.description}</p>
            <Link to="/settings/themes/customize" className="primary button">
              {getMessage("themes.customize.text")}
            </Link>
          </div>
        </div>
        {!this.state.themes ? (
          <Loader />
        ) : (
          <div className="themes">
            {this.state.themes.map((theme, index) => {
              return (
                <div className="theme" key={`theme-${index}`}>
                  <div className="theme-preview">
                    <img src={theme.image.desktop} alt={theme.name} />
                    <div className="actions">
                      <button
                        className="primary button"
                        disabled={this.state.submitting}
                        onClick={(e) => {
                          this.selectTheme(theme.id);
                        }}
                      >
                        {this.state.submitting
                          ? "..."
                          : getMessage("themes.selectTheme.text")}
                      </button>
                    </div>
                  </div>
                  <h3>{theme.name}</h3>
                  <p>{theme.description}</p>
                </div>
              );
            })}
          </div>
        )}
      </AuthenticatedPage>
    );
  }
}
