import React from "react";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import { getMessage } from "../../../../lib/translator";
import { Helmet } from "react-helmet";
import RefferalManagementForm from "./form";

import "./styles.css";

export default function RefferalManagement(props) {
  return (
    <AuthenticatedPage
      className="refferal-management"
      menu={props.menu}
      notShowHelmet={false}
      title={getMessage("menu.item.referral-management")}
    >
      <Helmet title="Zopping - Referral Management" />
      <h1 className="title">{getMessage("menu.item.referral-management")}</h1>
      <RefferalManagementForm />
    </AuthenticatedPage>
  );
}
