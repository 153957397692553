import React, { Component } from "react";
import ListingPageWithRoutes from "../../../containers/ListingPage/listingRouter";
import CustomerDetails from "./Details";
import CustomerFilters from "./Tables/Filters";
import { Popup } from "../../../components/Popup";
import TableView from "./Tables";
import { getMessage } from "../../../lib/translator";
import CustomerImport from "./CustomerImport";
import emptyIcon from "./no-customers.svg";
import offerIcon from "./offers.svg";
import offlineMarketingIcon from "./offline-marketing.svg";
import targetCustomerIcon from "./target-customer.svg";
import "./style.css";
import {
  getStandradDateTime,
  utcToLocalTime,
  localTimeToUtc,
} from "../../../lib/datetime";

const emptyState = {
  icon: emptyIcon,
  message: getMessage("customer.helpItems.message"),
  additionalViews: [CustomerImport],
};

const helpItems = {
  title: getMessage("customer.helpItems.title"),
  instructions: [
    {
      icon: targetCustomerIcon,
      title: getMessage("customer.helpItems.iec.title"),
      description: getMessage("customer.helpItems.iec.description"),
      onAction: (e) => {
        let customerImport = document.getElementById("customerImport");
        customerImport.click();
      },
    },
    {
      icon: offerIcon,
      title: getMessage("customer.helpItems.rpo.title"),
      description: getMessage("customer.helpItems.rpo.description"),
    },
    {
      icon: offlineMarketingIcon,
      title: getMessage("customer.helpItems.om.title"),
      description: getMessage("customer.helpItems.om.description"),
    },
  ],
};

class HeaderActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShown: false,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  hideModal() {
    this.setState({
      modalShown: false,
    });
  }

  render() {
    return (
      <div>
        <Popup
          heading={getMessage("customer.modal.import.text")}
          show={this.state.modalShown}
          close={this.hideModal}
        >
          <CustomerImport />
        </Popup>
        <button
          className="primary button"
          onClick={(e) => {
            this.setState({
              modalShown: true,
            });
          }}
        >
          + <span className="text">{getMessage("customer.import.text")}</span>
        </button>
      </div>
    );
  }
}

// DISCUSS: A better way to get awareness of window's width
// Reasoning: Individual components should not be aware of the window or
// its properties
class Customers extends Component {
  formatDate = (date) => {
    const start = getStandradDateTime(localTimeToUtc, `${date} 00:00:00`);
    const end = getStandradDateTime(localTimeToUtc, `${date} 23:59:59`);
    return `${start},${end}`;
  };
  render() {
    let { props } = this;
    let { page, rerender } = props;
    return (
      <ListingPageWithRoutes
        menu={props.menu}
        className="customers-page"
        title="Customers"
        api={{
          url: "/customer-service/customer",
          headers: { "X-API-VERSION": 2 },
          transform: (response) => {
            const customers = response.data.customer;
            if (Array.isArray(customers)) {
              customers.map((customer) => {
                if (customer.joinedOn) {
                  if (customer.joinedOn.includes("T")) {
                    const newDate = customer.joinedOn.split(/[TZ]/);
                    customer.joinedOn = `${newDate[0]} ${newDate[1]}`;
                  }
                  customer.joinedOn = getStandradDateTime(
                    utcToLocalTime,
                    customer.joinedOn
                  );
                }
                if (customer.joinedTime) {
                  if (customer.joinedTime.includes("T")) {
                    const newDate = customer.joinedTime.split(/[TZ]/);
                    customer.joinedTime = `${newDate[0]} ${newDate[1]}`;
                  }
                  customer.joinedTime = getStandradDateTime(
                    utcToLocalTime,
                    customer.joinedTime
                  );
                }
                if (customer.updatedAt) {
                  if (customer.updatedAt.includes("T")) {
                    const newDate = customer.updatedAt.split(/[TZ]/);
                    customer.updatedAt = `${newDate[0]} ${newDate[1]}`;
                  }
                  customer.updatedAt = getStandradDateTime(
                    utcToLocalTime,
                    customer.updatedAt
                  );
                }
                return customer;
              });
              return customers;
            } else {
              return [customers];
            }
          },
        }}
        storeDependent={page === "operations"}
        emptyState={emptyState}
        helpItems={helpItems}
        tableProperties={TableView(page, rerender)}
        headerActions={page === "operations" ? HeaderActions : null}
        form={{
          component: CustomerDetails,
          options: { page },
        }}
        filters={{
          component: CustomerFilters,
          transformSubmit: (formData) => {
            let data = Object.assign({}, formData);
            if (data.updatedAt) {
              data.updatedAt = this.formatDate(data.updatedAt);
            }
            if (data.joinedOn) {
              data.joinedOn = this.formatDate(data.joinedOn);
            }
            if (data.phone) {
              data.phone = data.phone.split(" ").join("");
            }
            return data;
          },
        }}
        tableDynamic
      />
    );
  }
}

export class CommonComponent extends Component {
  render() {
    let { props } = this;
    return <Customers {...props} />;
  }
}

export default class CustomersListing extends Component {
  render() {
    let { props } = this;
    return <CommonComponent page="operations" {...props} />;
  }
}
