import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import { Input, Select } from "../../../../../components/Form";
import PlacesWithStandaloneSearchBox from "../../../../../components/StandaloneSearchBox";
import ActionButtons from "../ActionButtons";
import { Dialog } from "../../../../../components/Popup";
import { DeliveryFeeFormulaForm } from "..";

import API from "../../../../../lib/api";
import { getCurrency } from "../../../../../lib/userDetails";
import { getMessage } from "../../../../../lib/translator";
import { getSingularPluralForm } from "../../../../../lib/commonlyused";
import emptyIcon from "../../KMLUpload/trip-upload.svg";
import "./style.css";

const preventDefaultBehavior = (e) => {
  e && e.preventDefault && e.preventDefault();
  e && e.stopPropagation && e.stopPropagation();
};

const handleKeyDown = (e) => {
  if (e.which === 13) {
    preventDefaultBehavior(e);
  }
};

const RadialList = ({ pincodes = [], distinctColors }) => {
  return pincodes?.map(({ area, name, id }, index) => {
    return (
      <div className="pincode-item" key={`pincode-${name}-${id}`}>
        <span>
          <span
            className="color-legend"
            style={{
              backgroundColor:
                distinctColors[index % distinctColors.length].value,
            }}
          />
          {name || getMessage("extensions.deliveryArea.polygon.unnamed")}
        </span>
        <span>
          {getCurrency().symbol} {area?.deliveryFee}
        </span>
      </div>
    );
  });
};

const RadialDisplay = (props) => {
  const { polygonGroups, distinctColors } = props;
  const pincodeDisplayRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const [isContentOverflown, setIsContentOverflown] = useState(false);
  const [containerHeight, setContainerHeight] = useState();

  useEffect(() => {
    if (pincodeDisplayRef) {
      const domEl = pincodeDisplayRef.current;
      const containerHeight = domEl.clientHeight;
      const contentHeight = domEl.querySelector(
        ".pincode-group-list-content"
      ).offsetHeight;
      if (contentHeight > containerHeight) {
        setIsContentOverflown(true);
        setContainerHeight(contentHeight + 10); // Adding 10 to match margins
      }
    }
  }, [polygonGroups]);

  const toggleExpand = (e) => {
    e && e.preventDefault && e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <div className="pincode-display-table">
      <div
        className={"pincode-group-list" + (expanded ? " expanded" : "")}
        style={expanded ? { maxHeight: containerHeight + "px" } : {}}
        ref={pincodeDisplayRef}
      >
        <div className="pincode-group-list-content">
          <div className="pincode-group-wrapper">
            <div className="pincode-group-header">
              <span>Zone</span>
              <span>{getMessage("extensions.deliveryArea.deliveryFee")}</span>
            </div>
            <div className="pincode-group">
              <RadialList
                pincodes={polygonGroups}
                distinctColors={distinctColors}
              />
            </div>
          </div>
        </div>
      </div>
      {isContentOverflown && (
        <button className="toggle-expand" onClick={toggleExpand}>
          Show {expanded ? "less" : "more"}
        </button>
      )}
    </div>
  );
};

const AddForm = ({ value = {}, onChange, onSave, setNewLocation }) => {
  const handleChange = (newValue) => {
    onChange({ ...value, ...newValue });
  };

  const handleSubmit = (e) => {
    e && e.preventDefault();
    onSave();
  };

  const handlePlaceSearch = (data) => {
    if (data && data[0] && data[0].geometry && data[0].geometry.location) {
      const location = data[0].geometry.location;
      const latitude = location.lat();
      const longitude = location.lng();

      handleChange({ latitude, longitude });
    }
  };

  useEffect(() => {
    if (value.latitude && value.longitude) {
      const markerLocation = [{ lat: value.latitude, lng: value.longitude }];
      setNewLocation(markerLocation);
    }
  }, [value.latitude, value.longitude, setNewLocation]);

  return (
    <form onSubmit={handleSubmit}>
      <Input
        placeholder="Enter zone name"
        name="zone-name"
        type="text"
        value={value.name}
        onChange={(name) => handleChange({ name })}
      />
      <div className="input-wrapper">
        <PlacesWithStandaloneSearchBox
          element={
            <input
              placeholder="Search location"
              name="landmark"
              type="text"
              className="standalone-searchbox"
              onKeyDown={handleKeyDown}
              required
            />
          }
          onPlacesSearched={handlePlaceSearch}
        />
      </div>
      <div className="inline-blocks">
        <Input
          placeholder="0 km"
          name="startRadius"
          type="number"
          value={value.startRadius}
          onChange={(startRadius) => handleChange({ startRadius })}
          required
        />
        <div className="distance-middle-text">
          {getMessage("radial.form.to")}
        </div>
        <Input
          placeholder="5 km"
          name="endRadius"
          type="number"
          value={value.endRadius}
          onChange={(endRadius) => handleChange({ endRadius })}
          required
        />
      </div>
      <div className="distance-description">
        {getMessage("radial.form.distance.description")}
      </div>
      <DeliveryFeeFormulaForm value={value} onChange={onChange}/>
      <button className="button primary add-submit-btn" type="submit">
        + Add Group
      </button>
    </form>
  );
};

const EditForm = ({
  value = {},
  onChange,
  pincodeGroup,
  action,
  onDeleteZone,
  setNewLocation,
}) => {
  const [selectedGroup, setSelectedGroup] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const groups =
    pincodeGroup &&
    pincodeGroup.map(({ name, id }) => ({
      text: name || getMessage("extensions.deliveryArea.zone.unnamed"),
      value: id,
    }));
  const isEditable = Boolean(selectedGroup);

  const handleSelectGroup = (groupId) => {
    const groupData = pincodeGroup.filter(({ id }) => id === Number(groupId));
    setSelectedGroup(groupId);
    onChange(groupData[0], true);
  };

  const handleChange = (newValue, replace) => {
    if (replace) {
      onChange({ ...newValue });
    } else {
      onChange({ ...value, ...newValue });
    }
  };

  const handlePlaceSearch = (data) => {
    if (data && data[0] && data[0].geometry && data[0].geometry.location) {
      const location = data[0].geometry.location;
      const latitude = location.lat();
      const longitude = location.lng();

      handleChange({ area: { ...value.area, latitude, longitude } });
    }
  };

  const handleShowDeletePopup = (e) => {
    preventDefaultBehavior(e);
    setShowDeletePopup(true);
  };

  const hanldeDeleteZone = (e) => {
    preventDefaultBehavior(e);
    setShowDeletePopup(false);
    onDeleteZone();
  };

  useEffect(() => {
    if (value.area?.latitude || value.area?.longitude) {
      const markerLocation = [
        { lat: value.area.latitude, lng: value.area.longitude },
      ];
      setNewLocation(markerLocation);
    }
  }, [value.area, setNewLocation]);

  return (
    <Fragment>
      {action === "edit" && (
        <Select
          name="group-selector"
          placeholder={getMessage("Select Group")}
          options={groups}
          value={selectedGroup}
          onChange={handleSelectGroup}
        />
      )}
      <Input
        placeholder="Zone name"
        name="zone-name"
        type="text"
        value={value.name}
        onChange={(name) => handleChange({ name })}
        readOnly={!isEditable}
      />
      <div className="input-wrapper">
        <PlacesWithStandaloneSearchBox
          element={
            <input
              placeholder="Search location"
              name="landmark"
              className="standalone-searchbox"
              type="text"
              readOnly={!isEditable}
              onKeyDown={handleKeyDown}
            />
          }
          onPlacesSearched={handlePlaceSearch}
        />
      </div>
      <div className="inline-blocks">
        <Input
          placeholder="0 km"
          name="startRadius"
          type="number"
          value={value.area?.startRadius}
          onChange={(startRadius) =>
            handleChange({ area: { ...value.area, startRadius } })
          }
          readOnly={!isEditable}
          required
        />
        <div className="distance-middle-text">
          {getMessage("radial.form.to")}
        </div>
        <Input
          placeholder="5 km"
          name="endRadius"
          type="number"
          value={value.area?.endRadius}
          onChange={(endRadius) =>
            handleChange({ area: { ...value.area, endRadius } })
          }
          readOnly={!isEditable}
          required
        />
      </div>
      <div className="distance-description">
        {getMessage("radial.form.distance.description")}
      </div>
      <DeliveryFeeFormulaForm value={value} onChange={onChange}/>
      {isEditable && (
        <button className="delete-group-btn" onClick={handleShowDeletePopup}>
          Delete Group
        </button>
      )}
      {showDeletePopup && (
        <Dialog
          show={showDeletePopup}
          className="delivery-group-delete-modal"
          title={getMessage("extensions.deliveryArea.delete.title")}
          close={() => setShowDeletePopup(false)}
          information={getMessage("extensions.deliveryArea.delete.warning")}
          closeText="Cancel"
          okText="Delete"
          onOk={hanldeDeleteZone}
        />
      )}
    </Fragment>
  );
};
export default class RadialFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      data: {},
    };
    this.handleRadialGroupAddEdit = this.handleRadialGroupAddEdit.bind(this);
    this.handleZoneDelete = this.handleZoneDelete.bind(this);
    this.toggleAction = this.toggleAction.bind(this);
    this.handleToggleExpand = this.handleToggleExpand.bind(this);
    this.handleDeleteZone = this.handleDeleteZone.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.toggleLocationError = this.toggleLocationError.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.setError = this.setError.bind(this);
  }

  handleZoneDelete(id) {
    const { action } = this.state;
    if (action === "add") {
      this.clearTempData();
    }
    this.props.handleZoneDelete(id);
  }

  toggleLocationError() {
    this.setState({
      locationError: !this.state.locationError,
    });
  }

  handleRadialGroupAddEdit() {
    const { action, data } = this.state;
    const { storeId, fetchAndUpdate, toggleStoreError, setNewLocation } =
      this.props;

    if (!storeId) {
      toggleStoreError();
      return;
    }

    // unseting the existing error by passing empty param
    this.setError();

    let params, requestMethod, url;

    if (action === "add") {
      url = "/logistics-service/delivery-area";
      requestMethod = "post";
      params = { ...data, configType: "RADIAL", unit: "KILOMETRE" };
    } else if (action === "edit") {
      url = `/logistics-service/delivery-area/${data.id}`;
      requestMethod = "put";
      params = {
        configType: data.configType,
        name: data.name,
        latitude: data.area.latitude,
        longitude: data.area.longitude,
        startRadius: data.area.startRadius,
        endRadius: data.area.endRadius,
        deliveryFee: data.deliveryFee,
        unit: data.area.unit,
        lowerWeightLimit: data?.lowerWeightLimit,
        ratePerWeightUnit: data?.ratePerWeightUnit,
        chargePercentage: data.chargePercentage,
      };
    }
    params.storeId = Number(storeId);

    const api = new API({ url });
    api[requestMethod](params)
      .then(() => fetchAndUpdate(storeId))
      .then(() => {
        this.setAction();
        this.handleDataChange({});
        setNewLocation([]);
      })
      .catch((error) => {
        this.setError(error.message);
        console.error(error);
      });
  }

  toggleAction(nextAction) {
    return (e) => {
      e && e.preventDefault && e.preventDefault();
      const { action } = this.state;
      if (action === nextAction) {
        this.setAction(undefined);
      } else {
        this.setAction(nextAction);
      }
    };
  }

  setAction(action) {
    this.setState({ action });
  }

  setError(error) {
    this.setState({ error });
  }

  handleDataChange(data) {
    this.setState({ data });
  }

  handleToggleExpand() {
    this.props.handleOpenClose("radial");
    this.setAction(undefined);
    this.setState({ data: {} });
  }

  handleDeleteZone() {
    const { action, data } = this.state;
    const { storeId, fetchAndUpdate } = this.props;

    if (action === "add") {
      this.setState({ data: {} });
      this.setAction(undefined);
    } else if (action === "edit") {
      const api = new API({
        url: `/logistics-service/delivery-area/${data.id}`,
      });
      api
        .delete({ id: data.id })
        .then(() => fetchAndUpdate(storeId))
        .then(() => {
          this.setAction(undefined);
          this.setState({ data: {} });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleCancel() {
    this.setError();
    this.setAction();
    this.handleDataChange({});
    this.props.setNewLocation([]);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.active !== this.props.active && !this.props.active) {
      this.handleCancel();
    }

    if (Boolean(prevState.action) ^ Boolean(this.state.action)) {
      this.props.changeEditMode();
    }
  }

  render() {
    const { action, data, error } = this.state;
    const {
      active,
      polygonalArea = [],
      distinctColors,
      setNewLocation,
      editing,
    } = this.props;
    const hasPincodesSet = Boolean(polygonalArea && polygonalArea.length);

    const FormComp = action === "add" ? AddForm : EditForm;
    const hidden = editing && !action;
    return (
      <div
        className={`pincode-form radial-form polygon-form zones-subsection ${
          active ? "" : "collapsed-section"
        } ${hidden ? "hidden" : ""} ${action ? "edit-mode" : ""}`}
      >
        <span onClick={this.handleToggleExpand} className="section-title">
          {getMessage("extensions.deliveryArea.radial.title")}
        </span>
        {!active && (
          <span className="section-subtitle">
            {hasPincodesSet
              ? `${getSingularPluralForm(polygonalArea.length, "zone")} covered`
              : "No zones defined"}
          </span>
        )}
        <span onClick={this.handleToggleExpand} className="open-close-icon">
          {active ? "\u2013" : "+"}
        </span>
        {!(hasPincodesSet || action) ? (
          active && (
            <div className="zones-content empty">
              <img src={emptyIcon} alt="no-pincode" className="empty-section" />
              <button
                onClick={this.toggleAction("add")}
                className="button primary"
              >
                + Add New Zone
              </button>
            </div>
          )
        ) : (
          <div className="zones-content">
            {active && !action && (
              <div className="header-action">
                <button
                  className={
                    "header-action-btn" + (action === "edit" ? " active" : "")
                  }
                  onClick={this.toggleAction("edit")}
                >
                  Edit
                </button>
                <button
                  className={
                    "header-action-btn" + (action === "add" ? " active" : "")
                  }
                  onClick={this.toggleAction("add")}
                >
                  Add New
                </button>
              </div>
            )}
            {action && (
              <FormComp
                value={data}
                action={action}
                onChange={this.handleDataChange}
                clearTempData={this.clearTempData}
                pincodeGroup={polygonalArea}
                onDeleteZone={this.handleDeleteZone}
                onSave={this.handleRadialGroupAddEdit}
                setNewLocation={setNewLocation}
              />
            )}

            {!action && active && hasPincodesSet && (
              <RadialDisplay
                polygonGroups={polygonalArea}
                distinctColors={distinctColors}
              />
            )}
          </div>
        )}
        {action && (
          <ActionButtons
            onSubmit={this.handleRadialGroupAddEdit}
            onCancel={this.handleCancel}
            error={error}
            disabled={!(data.area || data.name)}
            setError={this.setError}
          />
        )}
      </div>
    );
  }
}
