import React, { Component } from "react";
import {
  Input,
  Radio,
  Select,
  ProductSearch,
  BrandSearch,
  CategorySearch,
  Checkbox,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import { isAllRulesValid } from "../../../../lib/commonlyused";
import ItemTable from "./ItemTable";
import deleteIcon from "./delete.svg";

class BMINXGFG extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slotOptions: [],
    };
    this.validationHandler = this.validationHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.handleAddRule = this.handleAddRule.bind(this);
    this.handleDeleteRule = this.handleDeleteRule.bind(this);
  }

  initData() {
    let { method, _this, onUpdateValues } = this.props;
    if (method === "add") {
      let { values } = _this && _this.state;
      if (!(values.rule && values.rule.elementGroups)) {
        onUpdateValues({
          ...values,
          rule: { ...values.rule, elementGroups: [{ total: {} }] },
        });
      }
    }
  }

  validationHandler(parent) {
    let formData = JSON.parse(JSON.stringify(parent.state.values));
    const validations = JSON.parse(JSON.stringify(parent.state.validations));
    const comboDiscount = formData.itemDiscountType === "COMBO_DISCOUNT";
    if (comboDiscount) {
      ((validations.rule && validations.rule.elementGroups) || []).forEach(
        (group) => {
          group.product &&
            group.product.forEach((pdt) => {
              delete pdt.t;
              delete pdt.v;
            });
        }
      );
    } else {
      ((validations.rule && validations.rule.elementGroups) || []).forEach(
        (group) => {
          group.total && delete group.total;
        }
      );
    }
    parent.setState({
      validations,
    });
  }

  submitHandler(formData, buyKey) {
    let data = Object.assign({}, formData);
    const comboDiscount = formData.itemDiscountType === "COMBO_DISCOUNT";
    let nonEntity = false;
    if (!data.includes) {
      nonEntity = true;
    }
    if (!comboDiscount) {
      data.rule && delete data.rule.total;
    }

    const entityType = data.entityType.toLowerCase();
    // let getObject = {}
    let entityIds = [];
    if (entityType === "product") {
      Array.isArray(data[`${buyKey}product`]) &&
        data[`${buyKey}product`].map((item) => {
          entityIds.push(item.id);
          return null;
        });
    } else {
      Array.isArray(data[entityType]) &&
        data[entityType].map((item) => {
          entityIds.push(item.id);
          return null;
        });
    }

    // Array.isArray(data['product']) && data['product'].map(item => {
    //   getObject[item.id] = {q: Number(item.q)}
    //   if (!comboDiscount) {
    //     getObject[item.id].t = item.t
    //     getObject[item.id].v = Number(item.v)
    //   }
    //   return null
    // })

    const newRule = {
      minAmount: data.rule && data.rule.minAmount,
      entityIds: entityIds.length > 0 ? entityIds : undefined,
      entity:
        entityIds.length > 0
          ? {
              type: data.entityType === "PRODUCT" ? "VARIANT" : data.entityType,
            }
          : undefined,
      // get: {...getObject}
      elementGroups:
        data.rule.elementGroups &&
        data.rule.elementGroups.map((group) => {
          let getObject = {};
          group.product &&
            group.product.map((item) => {
              getObject[item.id] = { q: Number(item.q) };
              if (!comboDiscount) {
                getObject[item.id].t = item.t;
                getObject[item.id].v = Number(item.v);
              }
              return null;
            });
          group.get = getObject;
          delete group.product;
          !comboDiscount && delete group.total;
          return group;
        }),
    };
    // if (comboDiscount) {
    //   newRule.total = data.rule.total
    // }
    if (nonEntity && entityIds.length > 0) {
      newRule["non-entity"] = JSON.parse(JSON.stringify(newRule.entity));
      delete newRule.entity;
    }

    data.rule = newRule;
    delete data.itemDiscountType;
    delete data.pwpTagId;
    delete data.includes;
    delete data.ruleDetail;
    delete data.product;
    delete data.category;
    delete data.brand;
    delete data.entity;
    return data;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      this.initData();
    }
  }

  componentDidMount() {
    this.initData();
  }

  handleAddRule(e) {
    e && e.preventDefault();
    let { _this } = this.props;
    let { values } = _this.state;
    let { rule } = values || {};
    let { elementGroups } = rule || {};

    if (!elementGroups) {
      rule = { ...rule, elementGroups: [{ total: {} }] };
    }

    rule = { ...rule, elementGroups: [...rule.elementGroups, { total: {} }] };

    _this.setState({ pressedSubmitWithCurrentData: false });

    this.props.onUpdateValues({ ...values, rule });
  }

  handleDeleteRule(index) {
    let { _this } = this.props;
    let { values } = _this.state;
    let validations = JSON.parse(JSON.stringify(_this.state.validations));
    let validationsRule = validations.rule;
    let { rule } = values || {};

    if (rule && rule.elementGroups) {
      let elementGroups = rule.elementGroups.filter((_, idx) => idx !== index);
      rule = { ...rule, elementGroups };
    }

    if (validationsRule && validationsRule.elementGroups) {
      let elementGroups = validationsRule.elementGroups.filter(
        (_, idx) => idx !== index
      );
      validationsRule = { ...validationsRule, elementGroups };
    }

    this.props.onUpdateValues({ ...values, rule });
    this.props.onUpdateValidations({ ...validations, validationsRule });
  }

  render() {
    let {
      discountTypes,
      itemDiscountTypes,
      _this,
      hasOfferStarted,
      buyKey,
      type,
      method,
    } = this.props;
    const { values } = _this.state;
    const { itemDiscountType } = _this.state.values;
    const includes = values.includes;
    const entityType = values.entityType.toLowerCase();
    const comboDiscount = itemDiscountType === "COMBO_DISCOUNT";
    const buyItems = values[`buy${entityType}`];
    const buyItemsLength = buyItems ? buyItems.length : 0;
    const items = values[entityType];
    const itemsLength = items ? items.length : 0;
    let entityText = "";
    if (entityType === "product") {
      entityText = "Products";
    } else if (entityType === "category") {
      entityText = "Categories";
    } else {
      entityText = "Brands";
    }

    const { rule } = values || {};
    const { elementGroups } = rule || {};

    return (
      <div className="offers-form BMINXGFG-Offer">
        <div className="offer-fields">
          <Radio
            name="discount-type-main"
            label={getMessage("offer.main.discount.type")}
            placeholder={getMessage("offer.main.discouont.type.placeholder")}
            options={itemDiscountTypes}
            {..._this.generateStateMappers({
              stateKeys: ["itemDiscountType"],
              loseEmphasisOnFill: true,
            })}
            readOnly={hasOfferStarted}
          />
          <div className="product-search-BMINXGFG">
            <h3 className="top-o">{getMessage("offer.buy")}</h3>
            {entityType === "product" && (
              <ProductSearch
                required
                label={
                  includes
                    ? `${entityText} ${getMessage("offer.includeText")} :`
                    : `${entityText} ${getMessage("offer.excludeText")} :`
                }
                placeholder={getMessage(`offer.${entityType}.placeholder`)}
                name={`item-searchable-pdt-buy`}
                key={`item-searchable-pdt-buy`}
                className="product-searchable"
                onChange={_this.addItemBuy}
                readOnly={hasOfferStarted}
              />
            )}
            {entityType === "brand" && (
              <BrandSearch
                name="item-serchable-brand"
                className="product-searchable-max"
                label={
                  includes
                    ? `${entityText} ${getMessage("offer.includeText")} :`
                    : `${entityText} ${getMessage("offer.excludeText")} :`
                }
                placeholder={getMessage(`offer.brands.placeholder`)}
                {..._this.generateStateMappers({
                  stateKeys: ["brand"],
                  loseEmphasisOnFill: true,
                })}
                readOnly={hasOfferStarted}
                multiple
              />
            )}
            {entityType === "category" && (
              <CategorySearch
                className="product-searchable-max"
                transformResponse={(response) => response.data.category}
                label={
                  includes
                    ? `${entityText} ${getMessage("offer.includeText")} :`
                    : `${entityText} ${getMessage("offer.excludeText")} :`
                }
                placeholder={getMessage(`offer.categories.placeholder`)}
                {..._this.generateStateMappers({
                  stateKeys: ["category"],
                  loseEmphasisOnFill: true,
                })}
                multiple
                readOnly={hasOfferStarted}
              />
            )}
            <Checkbox
              className="entity-checkbox BMINXGFG"
              label={`Includes`}
              name={`entity`}
              {..._this.generateStateMappers({
                stateKeys: ["includes"],
                loseEmphasisOnFill: true,
              })}
            />
            {entityType === "brand" && itemsLength ? (
              <div>
                <ItemTable
                  entityType={entityType}
                  items={items}
                  discountTypes={discountTypes}
                  _this={_this}
                  buyAny
                  hasOfferStarted={hasOfferStarted}
                />
              </div>
            ) : null}
            {entityType === "category" && itemsLength ? (
              <div>
                <ItemTable
                  entityType={entityType}
                  items={items}
                  discountTypes={discountTypes}
                  _this={_this}
                  buyAny
                  hasOfferStarted={hasOfferStarted}
                />
              </div>
            ) : null}
            {buyItemsLength ? (
              <div>
                <ItemTable
                  comboDiscount
                  entityType={entityType}
                  items={buyItems}
                  discountTypes={discountTypes}
                  _this={_this}
                  buyAny
                  buy={buyKey}
                  hasOfferStarted={hasOfferStarted}
                />
              </div>
            ) : null}
          </div>
          {elementGroups
            ? elementGroups.map((_, index) => {
                const getProductItems =
                  (values &&
                    values.rule &&
                    values.rule.elementGroups &&
                    values.rule.elementGroups[index] &&
                    values.rule.elementGroups[index].product) ||
                  [];
                return (
                  <div className="rule-block" key={`element-group-${index}`}>
                    <div className="row">
                      <div className="offer-rule-header">
                        {Boolean(index) && (
                          <img
                            className="rule-delete-icon"
                            onClick={() => this.handleDeleteRule(index)}
                            src={deleteIcon}
                            alt="delete"
                          />
                        )}
                      </div>
                      <Input
                        name="min-amount"
                        className="shipping-cart"
                        type="number"
                        step="0.01"
                        required
                        readOnly={hasOfferStarted}
                        label={getMessage("offer.min.amount.label")}
                        placeholder={getMessage("offer.min.amount.placeholder")}
                        {..._this.generateStateMappers({
                          stateKeys: [
                            "rule",
                            "elementGroups",
                            index,
                            "minAmount",
                          ],
                          loseEmphasisOnFill: true,
                        })}
                      />
                      {comboDiscount && (
                        <React.Fragment>
                          <Select
                            name="discount-type"
                            required
                            label={getMessage("offer.discounttype")}
                            placeholder={getMessage(
                              "offer.discounttype.placeholder"
                            )}
                            options={discountTypes}
                            {..._this.generateStateMappers({
                              stateKeys: [
                                "rule",
                                "elementGroups",
                                index,
                                "total",
                                "t",
                              ],
                              loseEmphasisOnFill: true,
                            })}
                            onChange={(type) =>
                              _this.handleDiscountChange(type, [
                                "elementGroups",
                                index,
                              ])
                            }
                            readOnly={hasOfferStarted}
                          />
                          <Input
                            name="discount-value"
                            type="number"
                            step={0.01}
                            min={0}
                            max={
                              values &&
                              values.rule &&
                              values.rule.total &&
                              values.rule.total.t === "PERCENT_OFF"
                                ? 100
                                : Number.MAX_SAFE_INTEGER
                            }
                            required
                            label={getMessage("offer.discountvalue")}
                            placeholder={getMessage(
                              "offer.discountvalue.placeholder"
                            )}
                            {..._this.generateStateMappers({
                              stateKeys: [
                                "rule",
                                "elementGroups",
                                index,
                                "total",
                                "v",
                              ],
                              loseEmphasisOnFill: true,
                            })}
                            readOnly={
                              hasOfferStarted ||
                              (elementGroups &&
                                elementGroups[index] &&
                                elementGroups[index].total &&
                                elementGroups[index].total.t === "FREE")
                            }
                          />
                          {/* {
                      (rule && rule.elementGroups && rule.elementGroups[index] && rule.elementGroups[index].total && rule.elementGroups[index].total.t === `PERCENT_OFF`) &&
                      <Input
                        name='max-discount-value'
                        type='number'
                        className='row-element'
                        min={0}
                        label={getMessage('offer.maxdiscountvalue')}
                        placeholder={getMessage('offer.discountvalue.placeholder')}
                        {..._this.generateStateMappers({
                          stateKeys: ['rule', 'elementGroups', index, 'maxDiscount'],
                          loseEmphasisOnFill: true
                        })}
                        readOnly={hasOfferStarted || (rule && rule.elementGroups && rule.elementGroups[index] && rule.elementGroups[index].total && rule.elementGroups[index].total.t === `FREE`)}
                      />
                    } */}
                        </React.Fragment>
                      )}
                    </div>
                    <h3>{getMessage("offer.get")}</h3>
                    <ProductSearch
                      required
                      label={getMessage(`offer.product`)}
                      placeholder={getMessage(`offer.product.placeholder`)}
                      name={`item-searchable-pdt-get`}
                      key={`item-searchable-pdt-get`}
                      className="product-searchable"
                      onChange={(item) =>
                        _this.addItem(item, ["elementGroups", index])
                      }
                      readOnly={hasOfferStarted}
                    />
                    <div>
                      {getProductItems.length > 0 ? (
                        <ItemTable
                          comboDiscount={comboDiscount}
                          entityType={"product"}
                          items={getProductItems}
                          discountTypes={discountTypes}
                          _this={_this}
                          buyKey={buyKey}
                          hasOfferStarted={hasOfferStarted}
                          nestedKeys={["elementGroups", index]}
                          changeKey={[
                            "rule",
                            "elementGroups",
                            index,
                            "product",
                          ]}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              })
            : null}
          {method === "add" && type === "BMINXGFG" && (
            <button
              className="primary add-rule-btn"
              onClick={this.handleAddRule}
              disabled={
                !isAllRulesValid(
                  (rule && rule.elementGroups) || [],
                  ["minAmount", "product", "total"],
                  itemDiscountType
                )
              }
            >
              +
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default BMINXGFG;
