import React from "react";
import { BaseForm } from "../../../../components/Form";
import SingleDatePicker from "../../../../components/Form/Inputs/SingleDatePicker";
import { getMessage } from "../../../../lib/translator";

/**
 * *`VehiclesFiltersForm`*
 * : adds filtering to **`Vehicles`** component
 */
export default class VehiclePlannerFiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    return (
      <Form>
        <div className="form-fields">
          <SingleDatePicker
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ["date"],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage("vehicles.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("vehiclePlanner.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
