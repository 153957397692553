import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  Phone,
  Upload,
} from "../../../../components/Form";
import { isExtensionEnabled } from "../../../../lib/auth";
import { getMessage } from "../../../../lib/translator";
import "./style.css";
import { Helmet } from "react-helmet";
import MetaDataFormFields from "../../../../components/MetaDataFormFields";
import API from "../../../../lib/api";

export default class SellerForm extends BaseForm {
  componentDidMount() {
    if (isExtensionEnabled("EntityMetaData")) {
      this.getSellerMetaData();
    }
  }
  
  getSellerMetaData() {
      return new Promise((resolve, reject) => {
        const api = new API({ url: `/config-service/meta-data` });
        api.get().then((response) => {
          resolve(response.data?.config?.entityMetaData?.seller);
        }, reject);
      }).then((data) => {
        if (data) {
          this.setState({ sellerMetaData: data });
        }
      }).catch((error) => {
        console.error("Error fetching product metadata:", error);
      });
  }
  
  render() {
    const { CancelButton, SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div>
        <Helmet title="Zopping - Add Seller" />
        <Form className="seller-form">
          <Input
            className="title-input"
            label={getMessage("sellers.seller.form.title.heading")}
            placeholder={getMessage("sellers.seller.form.title.placeholder")}
            name="name"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage(
                "sellers.seller.form.title.requiredMessage"
              ),
            }}
          />

          <Phone
            country={this.state.countryCode}
            label={getMessage("sellers.seller.form.phone.heading")}
            placeholder={getMessage("order.form.customer.phone.placeholder")}
            name="phone"
            className="phone-number"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["phone"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            label={getMessage("sellers.seller.form.email.heading")}
            placeholder={getMessage("sellers.seller.form.email.placeholder")}
            className="seller-email"
            name="email"
            type="email"
            {...this.generateStateMappers({
              stateKeys: ["email"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />

          <Input
            className="title-input"
            label={getMessage("sellers.seller.form.address.heading")}
            placeholder={getMessage("sellers.seller.form.address.placeholder")}
            name="address"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["address"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            className="title-input"
            label={getMessage("sellers.seller.form.description.title.heading")}
            placeholder={getMessage(
              "sellers.seller.form.description.title.pleaceholder"
            )}
            name="description"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["description"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Upload
            label={getMessage("sellers.seller.form.image.logo.heading")}
            placeholder={getMessage(
              "sellers.seller.form.description.title.pleaceholder"
            )}
            {...this.generateStateMappers({
              stateKeys: ["logo"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />

          {isExtensionEnabled("EntityMetaData") && (
            <MetaDataFormFields
              metaDataWithType={this.state.sellerMetaData || {}}
              className="metadata-section"
              page="product-form"
              _this={this}
            />
          )}

          <div className="form-buttons">
            <SubmitButton>{getMessage("sellers.confirm.text")}</SubmitButton>
            <CancelButton>{getMessage("sellers.cancel.text")}</CancelButton>
          </div>
        </Form>
      </div>
    );
  }
}
