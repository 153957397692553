const data = {
  "signup.heading": "Create Your Account",
  "signup.subheading": "Enter your details below",
  "signup.buttonText": "Sign Up",
  "signup.email.heading": "EMAIL ADDRESS",
  "signup.email.placeholder": "Enter your email address",
  "signup.name.heading": "NAME",
  "signup.name.placeholder": "Enter your name",
  "signup.phone.heading": "PHONE NUMBER",
  "signup.phone.placeholder": "Enter your phone number",
  "signup.oldUser.message": "Already have an account? ",
  "signup.oldUser.signinText": "Sign In",
  "signup.submitText": "Sign Up",
  "user.not.verified.email":
    "Your email id is not verified, you may not receive order notifications.",
  "resend.verification.link": "Resend verification link",
  "email.verified": "Your email has been verified!",
  "password.sent.to.mobile":
    "Your account password has been sent to your mobile.",
  "email.verified.text": "verified",
  "email.not.verified.text": "not verified",
  "use.password.sent.to.registered.mobile":
    "Please use the password sent to your registered mobile number",
  "account.created": "We have created an account for you!",
  "verification.email.sent":
    "A verification email has been sent to your email address.",
};

export default data;
