import React from "react";
import { BaseForm, Input, Textarea } from "../../../../components/Form";
import Tabs from "../../../../components/Tabs";
import Dialog from "../../../../components/Popup/Dialog";
import API from "../../../../lib/api";
import { isExtensionEnabled } from "../../../../lib/auth";
import { getMessage } from "../../../../lib/translator";
import { withRouter } from "react-router-dom";

import "./style.css";

class SEOSetting extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      submitting: false,
      showSuccessDialog: false,
    };

    this.pages = ["HOME", "PRODUCT", "CATEGORY"];

    if (isExtensionEnabled("ProductTagSupport")) {
      this.pages.push("TAG");
    }

    if (isExtensionEnabled("MultiBrandSupport")) {
      this.pages.push("BRAND");
    }

    this.changePage = this.changePage.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.api = new API({ url: "/account-service/seo" });
    this.api
      .get()
      .then(
        (response) => {
          let transformedResponse = response.data.seo;
          this.seos = response.data.seo;
          let values = {};
          transformedResponse.forEach((seo) => {
            let page = seo.page.name;
            values[page] = {
              description: seo.description,
              title: seo.title,
              keywords: seo.keywords,
            };
          });
          this.setState({
            values: values,
          });
        },
        (error) => console.error(error)
      )
      .catch((error) => {
        console.error(error);
      });
  }
  componentWillUnmount() {
    this.api && this.api.cancel();
  }

  changePage(page) {
    this.setState(
      {
        page: page,
      },
      this.fetchPageData
    );
  }

  closeDialog() {
    this.setState({
      showSuccessDialog: false,
    });
  }

  handleSubmit(data) {
    const pageName = this.pages[this.state.page];
    let seo = this.seos.find((seo) => seo.page.name === pageName) || {};
    const id = seo.id;
    const api = new API({ url: `/account-service/seo/${id}` });

    let thisSeo = data[pageName];
    let params = seo;
    params.title = thisSeo.title;
    params.description = thisSeo.description;
    params.keywords = thisSeo.keywords;
    return api
      .put(params)
      .then(
        (response) => {
          this.setState({
            formError: "",
            showSuccessDialog: true,
          });
        },
        (error) => {
          this.setState({
            formError: error.message,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleSubmit(data)
      .then(() => {
        this.setState({
          submitting: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const page = this.pages[this.state.page];
    const name = page.split(" ")[0];

    return (
      <div className="seo-form">
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          title={getMessage("extensions.seo.success")}
          information={getMessage("extensions.seo.save.successful")}
          close={this.closeDialog}
          closeText={getMessage("extensions.seo.ok")}
        />
        <Form>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <div className="tab-container">
            <Tabs
              items={this.pages.map((page) => `${page}`)}
              default={this.state.page}
              onClick={(index) => this.changePage(index)}
            />
            <div className="tab-details">
              <div className={`${name}-page`}>
                <Input
                  label={getMessage("extensions.seo.title")}
                  name="title"
                  {...this.generateStateMappers({
                    stateKeys: [name, "title"],
                    loseEmphasisOnFill: true,
                  })}
                  maxLength={150}
                >
                  {getMessage("extensions.seo.title.explanation")}
                  <a
                    href="https://support.google.com/webmasters/answer/7451184?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    {getMessage("extensions.seo.read.more")}
                  </a>
                </Input>
                <Textarea
                  label={getMessage("extensions.seo.description")}
                  name="description"
                  {...this.generateStateMappers({
                    stateKeys: [name, "description"],
                    loseEmphasisOnFill: true,
                  })}
                  maxLength={64000}
                >
                  {" "}
                  {getMessage("extensions.seo.description.explanation")}
                  <a
                    href="https://support.google.com/webmasters/answer/7451184?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    {getMessage("extensions.seo.read.more")}
                  </a>
                </Textarea>
                <Textarea
                  label={getMessage("extensions.seo.keywords")}
                  name="keywords"
                  {...this.generateStateMappers({
                    stateKeys: [name, "keywords"],
                    loseEmphasisOnFill: true,
                  })}
                  maxLength={64000}
                >
                  {getMessage("extensions.seo.keywords.explanation")}
                  <a
                    href="https://support.google.com/webmasters/answer/7451184?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    {getMessage("extensions.seo.read.more")}
                  </a>
                </Textarea>
              </div>
              <SubmitButton>{getMessage("extensions.seo.save")}</SubmitButton>
              <button
                type="button"
                className="button"
                onClick={() => this.props.history.goBack()}
              >
                {getMessage("extensions.seo.cancel")}
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(SEOSetting);
