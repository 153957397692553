import React, { Component } from "react";
import InvoiceSummary from "../InvoiceSummary";
import Table, { Header, Row, Cell } from "../../../../../components/Table";
import Image from "../../../../../components/Image";
import { ProductSearch } from "../../../../../components/Form";
import {
  getNestedState,
  updateStateRecursively,
} from "../../../../../lib/stateManagement";
import { isEnterprise } from "../../../../../lib/auth";
import { getMessage } from "../../../../../lib/translator";
import { Dialog } from "../../../../../components/Popup";
import Loader from "../../../../../components/Loader";

import "./style.css";
import editIcon from "../edit-icon.svg";
import { truncateToTwoDecimal } from "../../../../../lib/commonlyused";

class OrderItemsView extends Component {
  constructor(props) {
    super(props);
    this.state = this.generateStateFromProps(props, {
      items: [],
      editing: false,
      saving: false,
      error: false,
      productLookup: {},
      // The index of the item in the row that is to be removed
      // Setting this state will show confirmation popup
      itemToRemove: null,
    });
    this.getNestedState = getNestedState.bind(this);
    this.updateStateRecursively = updateStateRecursively.bind(this);
    this.startEditing = this.startEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
    this.addProductToOrder = this.addProductToOrder.bind(this);
  }
  startEditing() {
    this.setState({ editing: true });
  }
  cancelEditing() {
    this.setState((prevState) =>
      this.generateStateFromProps(this.props, {
        editing: false,
      })
    );
  }
  submitChanges() {
    if (this.props.onChange) {
      // onChange method should return a promise
      this.setState(
        {
          editing: false,
          saving: true,
        },
        () => {
          this.props
            .onChange(this.state.items)
            .then(
              () => {
                this.setState({ error: false });
              },
              () => {
                this.setState({ error: true });
              }
            )
            .then(() => {
              this.setState({ saving: false });
            });
        }
      );
    }
  }
  addProductToOrder(newProduct) {
    if (!newProduct) {
      return;
    }
    // TODO: Find a better way to cause Searchable component to re-render
    this.setState({ productLookup: newProduct }, () => {
      this.setState((prevState) => {
        let newState = Object.assign({}, prevState, {
          productLookup: {},
        });
        // Check if new product exists in the order already
        let index = prevState.items.findIndex(
          (product) => product.id === newProduct.id
        );
        if (index > -1) {
          // Product exists, just increase quantity
          let item = prevState.items[index];
          if (item.isSoldByWeight) {
            let unit = item.variant
              ? item.variant.fullName &&
                item.variant.fullName.split(" ").slice(-1).pop()
              : item.product
              ? item.product.name.split(" ").slice(-1)[0]
              : "";
            unit = unit.toUpperCase();
            let multiplier = unit === "KG" || unit === "L" ? 1000 : 1;
            let weight = item.variant
              ? item.variant.fullName &&
                item.variant.fullName.split(" ").slice(-2)[0]
              : item.product
              ? item.product.name.split(" ").slice(-2)[0]
              : 1;
            weight = Number(weight);
            newState.items[index].orderDetails.orderedQuantity = (
              Number(item.orderDetails.orderedQuantity) +
              multiplier * weight
            ).toFixed(2);
          } else {
            newState.items[index].orderDetails.orderedQuantity = (
              Number(prevState.items[index].orderDetails.orderedQuantity) + 1
            ).toFixed(2);
          }
        } else {
          // Product doesn't exist, create a new entry
          let orderedQuantity = "1.00";
          let unit = null;
          let multiplier = 1;
          let weight = 1;
          if (newProduct.soldByWeight) {
            unit = newProduct.name.split(" ").slice(-1)[0];
            unit = unit.toUpperCase();
            multiplier = unit === "KG" || unit === "L" ? 1000 : 1;
            weight = newProduct.name.split(" ").slice(-2)[0];
            weight = Number(weight);
            orderedQuantity = (multiplier * weight).toFixed(2);
          } else {
            orderedQuantity = "1.00";
          }
          newState.items = [
            ...prevState.items,
            Object.assign(
              { id: newProduct.id, ...newProduct },
              {
                orderDetails: {
                  factorForPutRequest: newProduct.soldByWeight
                    ? multiplier * weight
                    : 1,
                  orderedQuantity,
                  discount: (
                    Number(newProduct.storeSpecificData.discount) /
                    Number(orderedQuantity)
                  ).toFixed(2),
                  mrp: (
                    Number(newProduct.storeSpecificData.mrp) /
                    Number(orderedQuantity)
                  ).toFixed(2),
                },
              }
            ),
          ];
        }
        return newState;
      });
    });
  }
  removeProductFromOrder(index) {
    this.updateStateRecursively(
      ["items", index, "orderDetails", "orderedQuantity"],
      0
    );
    this.setState({ itemToRemove: null });
  }

  generateStateFromProps(props, state) {
    let newState = Object.assign({}, state, {
      items: [...props.data.items],
    });
    // TODO: Use immutable data structures instead
    return JSON.parse(JSON.stringify(newState));
  }
  componentWillReceiveProps(newProps) {
    if (newProps.data.items) {
      this.setState((prevState) =>
        this.generateStateFromProps(newProps, prevState)
      );
    }
  }
  render() {
    const currency = this.props.currency;
    const data = this.props.data;
    let invoiceAmount = data && data.invoiceAmount;
    let loadedFromInvoice = this.props.loadedFromInvoice;
    let productsInOrder = this.state.items;
    const isPendingOrCancelledOrPicking =
      this.props.status === "PENDING" ||
      this.props.status === "CANCELLED" ||
      this.props.status === "PICKING";
    const invoiceQuantity = isPendingOrCancelledOrPicking
      ? "orderedQuantity"
      : "deliveredQuantity";
    let totalMrp = this.state.items.reduce(
      (result, item) =>
        result +
        Number(item.orderDetails.mrp) *
          Number(item.orderDetails[invoiceQuantity]),
      0
    );

    let totalDiscount = this.state.items.reduce(
      (result, item) =>
        result +
        Number(item.orderDetails.discount) *
          Number(item.orderDetails[invoiceQuantity]),
      0
    );

    let totalAmountCalculated = this.state.editing
      ? parseFloat(totalMrp) - parseFloat(totalDiscount)
      : parseFloat(data.amount) - parseFloat(data.discount);

    let paidAmount = this.props.data.payment.reduce(
      (result, payment) =>
        result + (payment.status === "COMPLETED" ? Number(payment.amount) : 0),
      0
    );

    let overlayClass = "hidden";
    if (this.state.saving) {
      overlayClass = "";
    } else if (this.state.error) {
      overlayClass = "error-message";
    }

    let totalTax = 0;
    const taxSlab = {};
    const taxBreakdown = {};

    return (
      <div className="order-items-view">
        <Dialog
          show={Number.isFinite(this.state.itemToRemove)}
          title={getMessage("order.details.removeItem.title")}
          information={getMessage("order.details.removeItem.message")}
          onOk={() => {
            this.removeProductFromOrder(this.state.itemToRemove);
          }}
          close={() => {
            this.setState({ itemToRemove: null });
          }}
          closeText={getMessage("order.details.removeItem.cancelText")}
          okText={getMessage("order.details.removeItem.confirmText")}
        />
        <div className="flex-around section-title">
          <h3>
            {getMessage("order.details.itemsTable.title")}
            <span className="text-muted"> ({productsInOrder.length})</span>
          </h3>
          {this.state.editing ? (
            <div className="product-search">
              <ProductSearch
                label="Product"
                includeInventory
                name="product"
                onChange={this.addProductToOrder}
                storeId={this.props.storeId}
              />
            </div>
          ) : this.props.editable && !this.state.saving && !this.state.error ? (
            <img
              src={editIcon}
              alt="Edit"
              className="edit-icon"
              onClick={this.startEditing}
            />
          ) : null}
        </div>
        <Table>
          {this.state.error || this.state.saving ? (
            <div className={`message-overlay ${overlayClass}`}>
              {this.state.saving ? (
                <Loader />
              ) : this.state.error ? (
                <div className="retry-message" onClick={this.submitChanges}>
                  {getMessage("order.details.errors.onSave")}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : null}
          <Header>
            <Cell className="item-image">
              {getMessage("order.details.itemsTable.header.image")}
            </Cell>
            <Cell className="item-name">
              {getMessage("order.details.itemsTable.header.name")}
            </Cell>
            {!loadedFromInvoice && (
              <Cell>
                {getMessage("order.details.itemsTable.header.clientItemId")}
              </Cell>
            )}
            {!loadedFromInvoice && (
              <Cell
                className="item-original text-right"
                abbreviation={getMessage(
                  "order.details.itemsTable.header.quantity.abbreviated"
                )}
              >
                {getMessage("order.details.itemsTable.header.quantity")}
              </Cell>
            )}
            {loadedFromInvoice && (
              <Cell className="item-quantity text-right">Quantity</Cell>
            )}
            {!loadedFromInvoice &&
              this.props.status !== "PENDING" &&
              this.props.status !== "CANCELLED" && (
                <Cell className="item-final text-right">
                  {" "}
                  {getMessage("order.details.itemsTable.header.picked")}
                </Cell>
              )}
            <Cell className="item-mrp text-right">
              {getMessage("order.details.itemsTable.header.mrp")}
            </Cell>
            {!isEnterprise() && (
              <Cell className="item-mrp text-right">
                {getMessage("order.details.itemsTable.header.tax")}
              </Cell>
            )}
            <Cell className="item-discount text-right">
              {getMessage("order.details.itemsTable.header.discount")}
            </Cell>
            <Cell className="item-amount text-right">
              {getMessage("order.details.itemsTable.header.amount")}
            </Cell>
          </Header>
          {this.state.items &&
            this.state.items.map((item, index) => {
              const { orderDetails } = item;
              const tax = orderDetails.tax || {};

              if (
                loadedFromInvoice &&
                this.props.status === "COMPLETED" &&
                !Number(item.orderDetails.deliveredQuantity)
              ) {
                return null;
              }
              let image = item.images && item.images[0] ? item.images[0] : null;
              let mrp = Number(item.orderDetails.mrp);
              let discount = Number(item.orderDetails.discount) || 0;
              let orderedQuantity = item.orderDetails.orderedQuantity;
              let pickedQuantity = Number(item.orderDetails.deliveredQuantity);
              let chargeableQuantity =
                item.orderDetails.status === "PENDING" ||
                (item.orderDetails["deliveredQuantity"] === undefined &&
                  item.orderDetails.status === undefined)
                  ? orderedQuantity
                  : pickedQuantity;
              let isSoldByWeight = item.soldByWeight || false;
              let name = item.fullName || item.name;
              let clientItemId = item.clientItemId;
              let comment =
                item &&
                item.orderDetails &&
                item.orderDetails.metaData &&
                item.orderDetails.metaData.comment;
              let unit = (name && name.split(" ").slice(-1).pop()) || "";
              unit = unit.toUpperCase();
              let weight = name ? name.split(" ").slice(-2)[0] : 1;
              let divider = 1;
              let multiplier = 1;
              let smallUnit = "g";
              if (unit === "KG" || unit === "L") {
                smallUnit = unit === "KG" ? " g" : " ml";
                multiplier = weight * 1000;
                divider = weight * 1000;
              }
              if (unit === "G" || unit === "GM" || unit === "ML") {
                multiplier = weight;
                divider = weight;
              }
              if (orderedQuantity === 0) {
                return null;
              }

              const amount = (mrp - discount) * chargeableQuantity;
              // To calculate total tax and tax slab
              let temp = 0;
              const taxValues = Object.values(tax);
              for (let i = 0; i < taxValues.length; i += 1) {
                temp += +taxValues[i];
              }

              const priceBeforeTax = (amount * 100) / (temp + 100);
              totalTax += amount - priceBeforeTax;
              taxSlab[temp] =
                typeof taxSlab[temp] === "undefined"
                  ? amount
                  : taxSlab[temp] + amount;

              Object.keys(tax).forEach(
                (taxType) => !tax[taxType] && delete tax[taxType]
              );
              taxBreakdown[JSON.stringify(tax)] =
                typeof taxBreakdown[JSON.stringify(tax)] === "undefined"
                  ? +amount
                  : taxBreakdown[JSON.stringify(tax)] + +amount;

              return (
                <Row key={item.id || `_${index}`}>
                  <Cell className="item-image">
                    <Image bordered size="sm" src={image} />
                  </Cell>
                  <Cell className="item-name">
                    <div className="name-wrp">
                      <small className="text-muted">
                        {item.brand ? (
                          <div className="product-name">
                            {" "}
                            <span className="brand-name">
                              {item.brand.name}
                            </span>{" "}
                            {name}
                          </div>
                        ) : (
                          name
                        )}
                      </small>
                      {orderDetails.isFree && (
                        <div className="sample">
                          {getMessage("order.details.sample")}
                        </div>
                      )}
                    </div>
                    {comment && (
                      <div className="text-muted item-comment">{comment}</div>
                    )}
                    {orderDetails?.offerProducts?.map((product) => {
                      let name = product.fullName || product.name;
                      return (
                        <div className="offer-item" key={name}>
                          <div className="sample">
                            {getMessage("order.details.sample")}
                          </div>
                          <small>
                            {product.brand ? (
                              <div className="product-name">
                                {" "}
                                <span className="brand-name">
                                  {product?.brand?.name}
                                </span>{" "}
                                {name}
                              </div>
                            ) : (
                              name
                            )}
                          </small>
                        </div>
                      );
                    })}
                  </Cell>
                  {!loadedFromInvoice && (
                    <Cell className="client-item-id text-muted">
                      <div className="item-table-mobile-view">
                        {getMessage(
                          "order.details.itemsTable.header.clientItemId"
                        )}
                      </div>
                      {clientItemId}
                    </Cell>
                  )}
                  {!loadedFromInvoice && (
                    <Cell className="item-original text-right">
                      <span className="mobile-view-header">
                        {getMessage("order.details.itemsTable.header.quantity")}
                      </span>
                      <small className="text-muted">
                        {this.state.editing ? (
                          <input
                            type="number"
                            placeholder="0"
                            min={1}
                            step={1}
                            value={
                              isSoldByWeight
                                ? this.getNestedState([
                                    "items",
                                    index,
                                    "orderDetails",
                                    "orderedQuantity",
                                  ]) === ""
                                  ? this.getNestedState([
                                      "items",
                                      index,
                                      "orderDetails",
                                      "orderedQuantity",
                                    ])
                                  : parseFloat(
                                      Number(
                                        this.getNestedState([
                                          "items",
                                          index,
                                          "orderDetails",
                                          "orderedQuantity",
                                        ])
                                      )
                                    )
                                : !this.getNestedState([
                                    "items",
                                    index,
                                    "orderDetails",
                                    "orderedQuantity",
                                  ])
                                ? this.getNestedState([
                                    "items",
                                    index,
                                    "orderDetails",
                                    "orderedQuantity",
                                  ])
                                : Number(
                                    this.getNestedState([
                                      "items",
                                      index,
                                      "orderDetails",
                                      "orderedQuantity",
                                    ])
                                  ).toFixed(0)
                            }
                            onChange={(e) => {
                              if (e) {
                                let updatedValue =
                                  e.target.value === ""
                                    ? ""
                                    : Math.floor(Math.abs(e.target.value));
                                this.updateStateRecursively(
                                  [
                                    "items",
                                    index,
                                    "orderDetails",
                                    "orderedQuantity",
                                  ],
                                  updatedValue
                                );
                              }
                            }}
                            onBlur={() => {
                              let keys = [
                                "items",
                                index,
                                "orderDetails",
                                "orderedQuantity",
                              ];
                              this.updateStateRecursively(
                                keys,
                                Number(this.getNestedState(keys))
                              );
                            }}
                          />
                        ) : isSoldByWeight ? (
                          this.props.status !== "PENDING" ? (
                            (Number(orderedQuantity) / divider).toFixed(2)
                          ) : (
                            Number(orderedQuantity).toFixed() + smallUnit
                          )
                        ) : (
                          Number(orderedQuantity).toFixed(0)
                        )}
                      </small>
                    </Cell>
                  )}
                  {loadedFromInvoice && (
                    <Cell className="item-quantity text-right">
                      <span className="mobile-view-header">
                        {getMessage("order.details.itemsTable.header.quantity")}
                      </span>
                      <small className="text-muted">
                        {pickedQuantity
                          ? isSoldByWeight
                            ? (pickedQuantity / divider).toFixed(3)
                            : pickedQuantity.toFixed(0)
                          : isSoldByWeight
                          ? (Number(orderedQuantity) / divider).toFixed(3)
                          : Number(orderedQuantity).toFixed(0)}
                      </small>
                    </Cell>
                  )}
                  {!loadedFromInvoice &&
                    this.props.status !== "PENDING" &&
                    this.props.status !== "CANCELLED" && (
                      <Cell className="item-final text-right">
                        <span className="mobile-view-header">
                          {getMessage("order.details.itemsTable.header.picked")}
                        </span>

                        <small className="text-muted">
                          {pickedQuantity
                            ? isSoldByWeight
                              ? (pickedQuantity / divider).toFixed(2)
                              : pickedQuantity.toFixed(0)
                            : 0}
                        </small>
                      </Cell>
                    )}
                  <Cell className="item-mrp text-right">
                    <div className="mobile-view-header">
                      {getMessage("order.details.itemsTable.header.mrp")}
                    </div>
                    <small className="text-muted">
                      {this.props.currency.symbol}{" "}
                      {isSoldByWeight
                        ? (mrp * multiplier).toFixed(2)
                        : mrp.toFixed(2)}
                    </small>
                  </Cell>

                  {!isEnterprise() && (
                    <Cell className="item-mrp text-right">
                      {Object.keys(tax).map((key) => {
                        if (!tax[key]) {
                          return null;
                        }

                        return (
                          <div className="text-muted">
                            <span className="text-uppercase">{`${key}`}</span>
                            {`: ${tax[key]}%`}
                          </div>
                        );
                      })}
                    </Cell>
                  )}

                  <Cell className="item-discount text-right">
                    <div className="mobile-view-header">
                      {getMessage("order.details.itemsTable.header.discount")}
                    </div>
                    <small className="text-muted">
                      {this.props.currency.symbol}{" "}
                      {isSoldByWeight
                        ? (discount * multiplier).toFixed(2)
                        : discount.toFixed(2)}
                    </small>
                  </Cell>
                  <Cell className="item-amount text-right">
                    <div className="mobile-view-header">
                      {getMessage("order.details.itemsTable.header.amount")}
                    </div>
                    <div className="remove-button-container">
                      {this.props.currency.symbol} {amount.toFixed(2)}
                      {this.state.editing
                        ? !loadedFromInvoice && (
                            <div
                              className="remove-button"
                              onClick={() => {
                                this.setState({ itemToRemove: index });
                              }}
                            >
                              &times;
                            </div>
                          )
                        : null}
                    </div>
                  </Cell>
                </Row>
              );
            })}
          {productsInOrder.length > 0 && (
            <Row>
              <Cell />
              <Cell />
              {!loadedFromInvoice && <Cell />}
              {!loadedFromInvoice &&
                this.props.status !== "PENDING" &&
                this.props.status !== "CANCELLED" && <Cell />}
              <Cell className="total-amount text-right">Total</Cell>
              <Cell className="total-amount text-right">
                <small>
                  {currency.symbol}{" "}
                  {this.state.editing
                    ? totalMrp.toFixed(2)
                    : Number(data.amount)}
                </small>
              </Cell>

              {!isEnterprise() && (
                <Cell className="total-amount text-right">
                  {`${currency.symbol} ${totalTax.toFixed(2)}`}
                </Cell>
              )}

              <Cell className="total-amount text-right">
                <small>
                  {currency.symbol}{" "}
                  {this.state.editing
                    ? totalDiscount.toFixed(2)
                    : Number(data.discount).toFixed(2)}
                </small>
              </Cell>
              <Cell className="total-amount text-right">
                {currency.symbol} {truncateToTwoDecimal(totalAmountCalculated)}
              </Cell>
            </Row>
          )}
        </Table>
        {productsInOrder.length <= 0 ? (
          this.state.editing ? (
            <div className="empty-table-message">
              {getMessage("order.details.itemsTable.clearingMessage")}
            </div>
          ) : (
            <div className="empty-table-message">
              {getMessage("order.details.itemsTable.emptyMessage")}
            </div>
          )
        ) : null}
        <div className="flex-around table-footer">
          <div className="order-edit-actions">
            {this.state.editing ? (
              <button className="button primary" onClick={this.submitChanges}>
                {getMessage("order.details.itemsTable.actions.confirmText")}
              </button>
            ) : null}
            {this.state.editing ? (
              <button
                className="button button-white"
                onClick={this.cancelEditing}
              >
                {getMessage("order.details.itemsTable.actions.cancelText")}
              </button>
            ) : null}
          </div>
          {
            <InvoiceSummary
              data={{
                taxBreakdown,
                shipping: Number(data.shipping),
                discount: this.state.editing ? totalDiscount : data.discount,
                paid: paidAmount,
                invoiceAmount: invoiceAmount,
                couponDiscount: Number(data.couponDiscount),
                refundAmount: data.refundAmount,
                loadedFromInvoice: loadedFromInvoice,
                editing: this.state.editing,
                clickAndCollectCharges: Number(data.clickAndCollectCharges),
                type: data.type,
                pendingAmount: data.pendingAmount,
                surcharge: data.surcharge,
                orderDiscount: data.orderDiscount,
              }}
              currency={currency}
            />
          }
        </div>
      </div>
    );
  }
}

OrderItemsView.defaultProps = {
  data: {
    items: [],
    payment: [],
  },
  includeInvoice: true,
};

export default OrderItemsView;
