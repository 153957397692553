export const fontSizes = [
  {
    text: "42",
    value: "42px",
  },
  {
    text: "40",
    value: "40px",
  },
  {
    text: "38",
    value: "38px",
  },
  {
    text: "36",
    value: "36px",
  },
  {
    text: "34",
    value: "34px",
  },
  {
    text: "32",
    value: "32px",
  },
  {
    text: "30",
    value: "30px",
  },
  {
    text: "28",
    value: "28px",
  },
  {
    text: "26",
    value: "26px",
  },
  {
    text: "24",
    value: "24px",
  },
  {
    text: "22",
    value: "22px",
  },
  {
    text: "20",
    value: "20px",
  },
  {
    text: "18",
    value: "18px",
  },
  {
    text: "16",
    value: "16px",
  },
];
