import React from "react";
import { BaseForm, Input, Checkbox } from "../../../components/Form";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import Loader from "../../../components/Loader";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";

import "./style.css";

class PickingConfig extends BaseForm {
  componentDidMount() {
    this.setState({
      loading: true,
    });
    const api = new API({ url: "/config-service/config/inStoreProcessing" });
    api
      .get()
      .then((response) => {
        let config = response.data.inStoreProcessing;
        this.setState({
          loading: false,
          values: config,
        });
        this.isMaxOrdersCanBePickedAtOnceEnabled =
          config.multiplePickingAllowed;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(formData) {
    this.setState({
      submitting: true,
    });
    let data = Object.assign({}, this.state.values);
    if (!data.partialPickingAllowed) {
      data.partialPickingAllowed = false;
    }
    if (!data.multiplePickingAllowed) {
      data.multiplePickingAllowed = false;
      data.maxOrdersCanBePickedAtOnce = "";
    }
    const api = new API({ url: "/config-service/config" });
    api
      .post({ inStoreProcessing: data })
      .then(
        (response) => {
          this.props.history.goBack();
        },
        (error) => {
          this.setState({
            formError: error.message,
            submitting: false,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidUpdate() {
    // Reset maxOrdersCanBePickedAtOnce if multiplePickingAllowed is unset

    // TODO: Modify the follwing code once BaseForm state mutation issue is resolved.
    // This is a work around for not getting proper prevState
    const isMaxOrdersCanBePickedAtOnceEnabled =
      this.state.values && this.state.values.multiplePickingAllowed;
    if (
      this.isMaxOrdersCanBePickedAtOnceEnabled &&
      !isMaxOrdersCanBePickedAtOnceEnabled
    ) {
      this.setState((prevProps) => ({
        ...prevProps,
        values: {
          ...prevProps.values,
          maxOrdersCanBePickedAtOnce: "",
        },
      }));
    }
    this.isMaxOrdersCanBePickedAtOnceEnabled =
      isMaxOrdersCanBePickedAtOnceEnabled;
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const { values: { multiplePickingAllowed } = {} } = this.state;
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <h1>{getMessage("picking.config.heading")}</h1>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        {this.state.loading ? (
          <Loader />
        ) : (
          <Form className="picking-config-form">
            <Checkbox
              inlineLabel={getMessage("picking.config.partialPickingAllowed")}
              name="partialPickingAllowed"
              {...this.generateStateMappers({
                stateKeys: ["partialPickingAllowed"],
                loseEmphasisOnFill: true,
              })}
            />
            <Checkbox
              inlineLabel={getMessage("picking.config.multiplePickingAllowed")}
              name="multiplePickingAllowed"
              {...this.generateStateMappers({
                stateKeys: ["multiplePickingAllowed"],
                loseEmphasisOnFill: true,
              })}
            />
            <Input
              name="maxOrdersCanBePickedAtOnce"
              label={getMessage("picking.config.maxOrdersCanBePickedAtOnce")}
              placeholder={getMessage(
                "picking.config.maxOrdersCanBePickedAtOnce.placeholder"
              )}
              type="number"
              min={0}
              step={1}
              readOnly={!multiplePickingAllowed}
              {...this.generateStateMappers({
                stateKeys: ["maxOrdersCanBePickedAtOnce"],
                loseEmphasisOnFill: true,
              })}
            />
            <div className="form-action">
              <SubmitButton>{getMessage("picking.config.save")}</SubmitButton>
            </div>
          </Form>
        )}
      </AuthenticatedPage>
    );
  }
}

export default PickingConfig;
