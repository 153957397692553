import React from "react";
import { Input } from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";

export const DeliveryFeeFormulaForm = ({ value = {}, onChange }) => {
  const inputData = [
    {
      placeholder: "Additional fixed cost",
      className: "delivery-fee edit-section",
      name: "DeliveryFee",
      key: "deliveryFee",
    },
    {
      placeholder: "Lower weight limit",
      className: "lower-weight-limit edit-section",
      name: "LowerWeightLimit",
      key: "lowerWeightLimit",
    },
    {
      placeholder: "Rate per weight unit",
      className: "rate-per-weight-unit edit-section",
      name: "RatePerWeightUnit",
      key: "ratePerWeightUnit",
    },
    {
      placeholder: "Charge percentage",
      className: "charge-percentage edit-section",
      name: "ChargePercentage",
      key: "chargePercentage",
    },
  ];
  const handleChange = (val, key) => {
    onChange({ ...value, [key]: val });
  };
  return (
    <>
      <div className="delivery-formula">
        <div className="delivery-formula-heading">
          {getMessage("delivery.fee.formula.heading")}
        </div>
        <p>
          <strong>{getMessage("delivery.fee.formula.left.text")}</strong>{" "}
          {getMessage("delivery.fee.formula.right.text")}
        </p>
      </div>
      {inputData.map((item) => {
        return (
          <Input
            key={item.key}
            placeholder={item.placeholder}
            className={item.className}
            name={item.name}
            type="number"
            value={value[item.key]}
            onChange={(value) => handleChange(value, item.key)}
          />
        );
      })}
    </>
  );
};