const data = {
  "slots.store.page.title": "Store slot configuration",
  "slots.store.blocking.title": "Store slot blocking",
  "slots.store.editHeading": "Edit Slot Rule",
  "slots.store.addHeading": "Add Slot Rule",
  "slots.status": "Status",
  "slots.enabled": "Enabled",
  "slots.enable": "Enable",
  "slots.save": "Save",
  "slots.cancel": "Cancel",
  "slots.timing": "Timings",
  "slots.disabled": "Disabled",
  "slots.disable": "Disable",
  "slots.cutoff": "Cutoff time",
  "slots.cutoff.mins": "Cutoff in mins",
  "slots.cutoff.placeholder": "Enter cutoff time in mins",
  "slots.add.rule": "Add slot rule",
  "slots.store.emptyMessage":
    "There are no slot rules configured for this store",
  "slots.edit": "Edit",
  "slot.configure": "Configure",
  "slots.name.heading": "Slot",
  "slots.blocking.empty.message": "There are no slots configured for blocking",
  "slots.DELIVERY.empty": "No Delivery slots to configure",
  "slots.PICKUP.empty": "No Pickup slots to configure",
};

export default data;
