import React from "react";
import {
  BaseForm,
  Radio,
  MultiTextInput,
  ProductSearch,
  CategorySearch,
  BrandSearch,
  Searchable,
  SingleDatePicker,
  Select,
} from "../../../../components/Form";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import Table, { Row, Header, Cell } from "../../../../components/Table";
import { getMessage } from "../../../../lib/translator";
import Image from "../../../../components/Image";
import { getProductName, getProductImage } from "../../../../lib/commonlyused";

import DeleteIcon from "../../../hr/UserShifts/Form/delete.svg";
import { isExtensionEnabled } from "../../../../lib/auth";
import { getTimes } from "../../../../lib/datetime";
import API from "../../../../lib/api";
import Loader from "../../../../components/Loader";
import Loader3Dots from "../../../../components/Loader3Dots";

const SortableItem = SortableElement((props) => {
  const { product, ind, onDelete } = props;
  return (
    <Row className="pdt-row">
      <Cell>
        <Image size="sm" src={getProductImage(product)} />
      </Cell>
      <Cell>{getProductName(product)}</Cell>
      <Cell>{ind + 1}</Cell>
      <Cell>
        <button
          className="del-button"
          type="button"
          onClick={() => onDelete(ind)}
        >
          <img src={DeleteIcon} alt="delete" />
        </button>
      </Cell>
    </Row>
  );
});

const SortableList = SortableContainer((props) => {
  const products = props.products || [];
  return (
    <Table>
      {products.length > 0 && (
        <Header>
          <Cell>{getMessage("Image")}</Cell>
          <Cell>{getMessage("Name")}</Cell>
          <Cell>{getMessage("Position")}</Cell>
          <Cell />
        </Header>
      )}
      {products.map((pdt, i) => (
        <SortableItem
          key={`product-${i}`}
          product={pdt}
          index={i}
          ind={i}
          onDelete={props.onDelete}
        />
      ))}
    </Table>
  );
});

class SearchConfigForm extends BaseForm {
  constructor(props) {
    super(props);
    this.addProduct = this.addProduct.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);

    this.state.options = [
      {
        text: "Search Term",
        value: "SEARCH",
      },
    ];
    this.state.productListDetail = null;
    this.state.showLoader = false;
  }

  beforeSubmit() {
    let validations = Object.assign({}, this.state.validations);
    const values = Object.assign({}, this.state.values);
    if (values.pageType === "SEARCH") {
      delete validations.category;
      values.products = values.products.filter((_, index) => index < 5);
    } else {
      delete validations.url;
    }
    this.setState({
      validations,
    });
  }
  componentWillMount() {
    this.handleFetchProducts();
    const api = new API({ url: "/config-service/config/searchOverrider" });
    this.setState({ showLoader: true });
    api
      .get()
      .then((response) => {
        const searchOverrideArray =
          response.data.searchOverrider.entityAllowedForSearchOverride;
        let newSearchTab = [...this.state.options];
        if (searchOverrideArray.includes("CATEGORY")) {
          newSearchTab.push({ text: "Category", value: "CATEGORY" });
        }
        if (
          isExtensionEnabled("MultiBrandSupport") &&
          searchOverrideArray.includes("BRAND")
        ) {
          newSearchTab.push({ text: "Brand", value: "BRAND" });
        }

        if (
          isExtensionEnabled("ProductTagSupport") &&
          searchOverrideArray.includes("TAG")
        ) {
          newSearchTab.push({ text: "Tag", value: "TAG" });
        }
        this.setState({
          options: newSearchTab,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({
          showLoader: false,
        });
      });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageType !== this.state.pageType) {
      let validations = Object.assign({}, this.state.validations);
      const values = Object.assign({}, this.state.values);
      if (values.pageType === "SEARCH") {
        delete validations.category;
      } else {
        delete validations.url;
      }
      this.setState({
        validations,
      });
    }
  }

  componentDidMount() {
    if (!this.state.values.pageType) {
      let values = Object.assign({}, this.state.values);
      values.pageType = "SEARCH";
      this.setState({
        values,
      });
    }
  }

  addProduct(product) {
    let values = Object.assign({}, this.state.values);
    let pdts = (values.products || []).slice();
    if (pdts.filter((p) => p.id === product.id).length > 0) {
      return null;
    }
    pdts.push(product);
    values.products = pdts;
    this.setState({
      values,
    });
  }

  onSortEnd({ oldIndex, newIndex }) {
    let values = Object.assign({}, this.state.values);
    let products = values.products;
    let newProducts = arrayMove(products, oldIndex, newIndex);
    values.products = newProducts;
    this.setState({
      values,
    });
  }

  onDelete(index) {
    let values = Object.assign({}, this.state.values);
    let products = values.products;
    products.splice(index, 1);
    values.products = products;
    this.setState({
      values,
    });
  }

  removeObjectWithId = (arr, id) => {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    if (objWithIdIndex > -1) {
      // mutates the original array
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  };
  updateProductList = (_products) => {
    const { products = [] } = this.state?.values || {};

    let productsCopy = JSON.parse(JSON.stringify(products));
    productsCopy.forEach(({ id }) => {
      this.removeObjectWithId(_products, id);
    });
    this.setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        products: [...productsCopy, ..._products],
      },
    }));
  };
  getPageNumber = () => {
    const { productListDetail } = this.state;
    const { count, offset, limit } = productListDetail || {};
    if (count) {
      const currentPage = Math.floor(offset / limit + 1);
      return {
        page: currentPage + 1,
      };
    }
    return {};
  };
  handleFetchProducts = () => {
    const { id } = this.state?.values || {};
    const api = new API({
      url: `/catalogue-service/product-ranking/${id}/product`,
    });
    const params = this.getPageNumber();
    this.setState({ showViewMoreLoader: true });
    api
      .get({ ...params })
      .then((response) => {
        const { products = [], count, limit, offset } = response.data || {};
        this.setState({ productListDetail: { count, limit, offset } });
        this.updateProductList(products);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({
          showViewMoreLoader: false,
        });
      });
  };
  handleViewMoreVisibility = () => {
    const { productListDetail } = this.state;
    const { count, offset, limit } = productListDetail || {};
    if (!count) return true;

    return offset + limit < count;
  };
  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    const { pageType, products } = this.state.values;
    const searchPage = pageType === "SEARCH";
    const categoryPage = pageType === "CATEGORY";
    const brandPage = pageType === "BRAND";
    const tagPage = pageType === "TAG";
    const readonly = this.props.method !== "add";
    return this.state.showLoader ? (
      <Loader />
    ) : (
      <div className="search-config-form">
        <Form>
          {this.props.method === "add" && (
            <Radio
              label={getMessage("search-configuration.form.label.select.type")}
              name="type"
              options={this.state.options}
              readOnly={readonly}
              {...this.generateStateMappers({
                stateKeys: ["pageType"],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <div className="flex">
            <div className="flex no-margin">
              <SingleDatePicker
                name="validFromDate"
                label={getMessage("valid from")}
                placeholder={getMessage("Date")}
                enableToday
                {...this.generateStateMappers({
                  stateKeys: ["validFromDate"],
                  loseEmphasisOnFill: true,
                })}
              />
              <Select
                name="validFromTime"
                label={getMessage("time")}
                placeholder={getMessage("Select time")}
                options={getTimes()}
                {...this.generateStateMappers({
                  stateKeys: ["validFromTime"],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <div className="flex no-margin">
              <SingleDatePicker
                name="validToDate"
                enableToday
                label={getMessage("valid to")}
                placeholder={getMessage("Date")}
                {...this.generateStateMappers({
                  stateKeys: ["validToDate"],
                  loseEmphasisOnFill: true,
                })}
              />
              <Select
                name="validToTime"
                label={getMessage("time")}
                placeholder={getMessage("Select time")}
                options={getTimes()}
                {...this.generateStateMappers({
                  stateKeys: ["validToTime"],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
          </div>
          {searchPage && (
            <MultiTextInput
              name="search-urls"
              label={getMessage("search-config.searchurls")}
              placeholder={getMessage("search-config.searchurls.placeholder")}
              {...this.generateStateMappers({
                stateKeys: ["url"],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {categoryPage && (
            <CategorySearch
              label={getMessage("sc.form.category.label")}
              placeholder={getMessage("sc.form.cat.placeholder")}
              name="category"
              multiple
              {...this.generateStateMappers({
                stateKeys: ["category"],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {brandPage && (
            <BrandSearch
              name="brand"
              multiple
              label={getMessage("brand")}
              placeholder={getMessage("Enter brands")}
              {...this.generateStateMappers({
                stateKeys: ["brand"],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {tagPage && (
            <Searchable
              name="tag"
              multiple
              searchUrl="/catalogue-service/tag"
              transformResponse={(response) => response.data.tag || []}
              searchKey="name"
              valueKey="id"
              nameKey="name"
              label={getMessage("tag")}
              placeholder={getMessage("Enter tags")}
              {...this.generateStateMappers({
                stateKeys: ["tag"],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {
            <ProductSearch
              className="search-product"
              label="Product"
              getVariantData={false}
              onChange={this.addProduct}
            />
          }

          <SortableList
            products={products}
            onDelete={this.onDelete}
            onSortEnd={this.onSortEnd}
            pressDelay={200}
            helperClass="sortableSearchPageHelper"
            axis="y"
          />

          {pageType === "SEARCH" && this.handleViewMoreVisibility() ? (
            <div className="view-more" onClick={this.handleFetchProducts}>
              {this.state.showViewMoreLoader ? <Loader3Dots /> : "view more"}
            </div>
          ) : null}

          <div className="form-action actions">
            <CancelButton>
              {getMessage("search-configuration.form.cancel")}
            </CancelButton>
            <SubmitButton>{getMessage("Save")}</SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}

export default SearchConfigForm;
