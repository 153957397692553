import React from "react";

export default function Row(props) {
  let data = props.items || [];
  return (
    <div className="table-header">
      {data.map((header, index) =>
        typeof header === "string" ? (
          <div key={index} className="table-cell">
            {header}
          </div>
        ) : (
          <div
            key={index}
            className="table-cell"
            style={{ textAlign: `${header.dir}` }}
          >
            {header.name}
          </div>
        )
      )}
      {props.children}
    </div>
  );
}
