import React, { useEffect, useState } from "react";
import { Checkbox, Input } from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import API from "../../../../lib/api";
import { Dialog } from "../../../../components/Popup";

export function ProductVariantForm() {
  const [hasProductProperties, setHasProductProperties] = useState(false);
  const [properties, setProperties] = useState([]);
  const [singleProperty, setSingleProperty] = useState("");
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [formError, setFormError] = useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    getProductProperties();
  }, []);

  useEffect(() => {
    if (properties?.length > 0) {
      setHasProductProperties(true);
    } else {
      setHasProductProperties(false);
    }
  }, [properties]);

  const togglePropertyEnable = (value) => {
    if (value === false) {
      setShowWarningDialog(true);
    } else {
      setHasProductProperties(true);
    }
  };

  const getProductProperties = () => {
    const productPropertyApi = new API({
      url: "/catalogue-service/product-properties",
    });
    productPropertyApi
      .get()
      .then((response) => {
        setProperties(response.data.productProperties || []);
      })
      .catch((error) => {
        console.error(error);
        setShowErrorDialog(true);
        setFormError(error.message);
      });
  };

  const handleAddKey = () => {
    if (singleProperty) {
      const productPropertyApi = new API({
        url: "/catalogue-service/product-properties",
      });
      productPropertyApi
        .post({ name: singleProperty })
        .then((response) => {
          const newProperties = properties || [];
          setShowSuccessDialog(true);
          newProperties.push(response.data.productProperties);
          setProperties(newProperties);
          handleAddProperty("");
        })
        .catch((error) => {
          console.error(error);
          setShowErrorDialog(true);
          setFormError(error.message);
        });
    }
  };

  const handleAddProperty = (value) => {
    setSingleProperty(value);
  };

  const handleDeleteProductProperty = (id) => {
    if (id) {
      const productPropertyApi = new API({
        url: `/catalogue-service/product-properties/${id}`,
      });
      productPropertyApi
        .delete()
        .then((response) => {
          getProductProperties();
          setDeleteTarget(null);
        })
        .catch((error) => {
          setShowErrorDialog(true);
          setFormError(error.message);
          console.error(error);
        })
        .finally(() => {
          setShowWarningDialog(false);
        });
    } else {
      properties.forEach((property) => {
        if (property.id) {
          const productPropertyApi = new API({
            url: `/catalogue-service/product-properties/${property.id}`,
          });
          productPropertyApi
            .delete()
            .then((response) => {
              getProductProperties();
              setDeleteTarget(null);
            })
            .catch((error) => {
              setShowErrorDialog(true);
              setFormError(error.message);
              console.error(error);
            })
            .finally(() => {
              setShowWarningDialog(false);
            });
        }
      });
    }
  };

  const handleDeleteKey = (id) => {
    setShowWarningDialog(true);
    setDeleteTarget(id);
  };

  return (
    <div className="variant-form">
      <div className="property-toggle-wrapper">
        <Checkbox
          className="property-toggle"
          name="HasPropertyEnabled"
          controlled
          value={hasProductProperties}
          onChange={(e) => togglePropertyEnable(e)}
          label={getMessage("product.property.enable.text")}
        />
      </div>
      {hasProductProperties && (
        <>
          <Dialog
            show={showSuccessDialog}
            className="success"
            title="Success"
            information={getMessage("product.property.add.success.text")}
            close={() => setShowSuccessDialog(false)}
            closeText={getMessage("okay.text")}
          />
          <Dialog
            show={showWarningDialog}
            className="warn"
            message={getMessage("are.you.sure")}
            information={getMessage("deleteDialog.information")}
            close={() => setShowWarningDialog(false)}
            closeText={getMessage("cancel.text")}
            onOk={() => handleDeleteProductProperty(deleteTarget)}
            okText="OK"
          />
          <Dialog
            title="Failed"
            show={showErrorDialog}
            information={getMessage(formError)}
            close={() => {
              setShowErrorDialog(false);
            }}
            closeText={getMessage("okay.text")}
          />
          <div className="property-wrapper">
            {properties &&
              properties?.map((property) => (
                <div className="property-item">
                  <Input
                    key={`Property-${property.id}`}
                    type="text"
                    name={`Property-${property.name}`}
                    label={getMessage("Property Name")}
                    placeholder={getMessage("product.property.add.placeholder")}
                    value={property.name}
                    readOnly
                  />
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteKey(property.id)}
                  />
                </div>
              ))}
            <Input
              className="add-property"
              type="text"
              name={`Property-${singleProperty}`}
              label={getMessage("Property Name")}
              placeholder={getMessage("product.property.add.placeholder")}
              value={singleProperty}
              onChange={(value) => handleAddProperty(value)}
            />
          </div>
          <button className="add-button" onClick={handleAddKey}>
            {getMessage("product.property.add.text")}
          </button>
        </>
      )}
    </div>
  );
}
