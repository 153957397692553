import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import ActionButtons from "../ActionButtons";
import { Input, Select } from "../../../../../components/Form";
import { Dialog } from "../../../../../components/Popup";

import API from "../../../../../lib/api";
import { getCurrency } from "../../../../../lib/userDetails";
import { getMessage } from "../../../../../lib/translator";
import { getSingularPluralForm } from "../../../../../lib/commonlyused";
import emptyIcon from "../../KMLUpload/trip-upload.svg";
import "./style.css";
import { DeliveryFeeFormulaForm } from "..";

const getPolygonLocations = (deliveryArea) =>
  deliveryArea?.configType === "POLYGON" ? deliveryArea.area?.locations : [];

const preventDefaultBehavior = (e) => {
  e && e.preventDefault && e.preventDefault();
  e && e.stopPropagation && e.stopPropagation();
};

const PinCodeList = ({ pincodes = [], distinctColors }) =>
  pincodes?.map(({ area, name, id }, index) => (
    <div className="pincode-item" key={`pincode-${name}-${id}`}>
      <span>
        <span
          className="color-legend"
          style={{
            backgroundColor:
              distinctColors[index % distinctColors.length].value,
          }}
        />
        {name || getMessage("extensions.deliveryArea.polygon.unnamed")}
      </span>
      <span>
        {getCurrency().symbol} {area?.deliveryFee}
      </span>
    </div>
  ));

const PinCodesDisplay = (props) => {
  const { polygonGroups, distinctColors } = props;
  const pincodeDisplayRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const [isContentOverflown, setIsContentOverflown] = useState(false);
  const [containerHeight, setContainerHeight] = useState();

  useEffect(() => {
    if (pincodeDisplayRef) {
      const domEl = pincodeDisplayRef.current;
      const containerHeight = domEl.clientHeight;
      const contentHeight = domEl.querySelector(
        ".pincode-group-list-content"
      ).offsetHeight;
      if (contentHeight > containerHeight) {
        setIsContentOverflown(true);
        setContainerHeight(contentHeight + 10); // Adding 10 to match margins
      }
    }
  }, [polygonGroups]);

  const toggleExpand = (e) => {
    e && e.preventDefault && e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <div className="pincode-display-table">
      <div
        className={"pincode-group-list" + (expanded ? " expanded" : "")}
        style={expanded ? { maxHeight: containerHeight + "px" } : {}}
        ref={pincodeDisplayRef}
      >
        <div className="pincode-group-list-content">
          <div className="pincode-group-wrapper">
            <div className="pincode-group-header">
              <span>Zone</span>
              <span>{getMessage("extensions.deliveryArea.deliveryFee")}</span>
            </div>
            <div className="pincode-group">
              <PinCodeList
                pincodes={polygonGroups}
                distinctColors={distinctColors}
              />
            </div>
          </div>
        </div>
      </div>
      {isContentOverflown && (
        <button className="toggle-expand" onClick={toggleExpand}>
          Show {expanded ? "less" : "more"}
        </button>
      )}
    </div>
  );
};

const AddForm = ({
  value = {},
  onChange,
  newlyAddedLocations,
  onSave,
  locationError,
  name,
}) => {
  useEffect(() => {
    if (name) {
      handleChange({ name });
    }
    // eslint-disable-next-line
  }, []);
  const handleChange = (newValue) => {
    onChange({ ...value, ...newValue });
  };

  const handleSubmit = (e) => {
    e && e.preventDefault();
    onSave();
  };

  const isDisabled = !(newlyAddedLocations && newlyAddedLocations.length);

  return (
    <form onSubmit={handleSubmit}>
      <Input
        placeholder="Zone name"
        name="zone-name"
        type="text"
        value={value.name}
        onChange={(name) => handleChange({ name })}
      />
      <DeliveryFeeFormulaForm value={value} onChange={onChange}/>
      {locationError && (
        <small className="store-select-error">
          No new polygon found. Please draw a polygon on the map for your
          delivery area.
        </small>
      )}
      <button
        className="button primary add-submit-btn"
        type="submit"
        disabled={isDisabled}
      >
        + Add Group
      </button>
    </form>
  );
};

const EditForm = ({
  value = {},
  onChange,
  pincodeGroup,
  action,
  onDeleteZone,
  setTempLocation,
}) => {
  const [selectedGroup, setSelectedGroup] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const groups =
    pincodeGroup &&
    pincodeGroup.map(({ name, id }) => ({
      text: name || getMessage("extensions.deliveryArea.polygon.unnamed"),
      value: id,
    }));
  const isEditable = Boolean(selectedGroup);

  const handleSelectGroup = (groupId) => {
    const groupData = pincodeGroup.filter(({ id }) => id === Number(groupId));
    setSelectedGroup(groupId);
    onChange(groupData[0], true);
    setTempLocation({
      ...groupData[0],
      locations: groupData[0]?.area?.locations?.map(
        ({ latitude, longitude }) => ({ lat: latitude, lng: longitude })
      ),
    });
  };

  const handleChange = (newValue, replace) => {
    if (replace) {
      onChange({ ...newValue });
    } else {
      onChange({ ...value, ...newValue });
    }
  };

  const handleShowDeletePopup = (e) => {
    preventDefaultBehavior(e);
    setShowDeletePopup(true);
  };

  const hanldeDeleteZone = (e) => {
    preventDefaultBehavior(e);
    setShowDeletePopup(false);
    onDeleteZone();
  };

  return (
    <Fragment>
      {action === "edit" && (
        <Select
          name="group-selector"
          placeholder={getMessage("Select Group")}
          options={groups}
          value={selectedGroup}
          onChange={handleSelectGroup}
        />
      )}
      <Input
        placeholder="Zone name"
        name="zone-name"
        type="text"
        value={value.name}
        onChange={(name) => handleChange({ name })}
        readOnly={!isEditable}
      />
      <DeliveryFeeFormulaForm value={value} onChange={onChange}/>
      {isEditable && (
        <button className="delete-group-btn" onClick={handleShowDeletePopup}>
          Delete Group
        </button>
      )}
      {showDeletePopup && (
        <Dialog
          show={showDeletePopup}
          className="delivery-group-delete-modal"
          title={getMessage("extensions.deliveryArea.delete.title")}
          close={() => setShowDeletePopup(false)}
          information={getMessage("extensions.deliveryArea.delete.warning")}
          closeText="Cancel"
          okText="Delete"
          onOk={hanldeDeleteZone}
        />
      )}
    </Fragment>
  );
};
export default class PolygonFromComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
    this.handlePincodeGroupAddEdit = this.handlePincodeGroupAddEdit.bind(this);
    this.handleZoneDelete = this.handleZoneDelete.bind(this);
    this.toggleAction = this.toggleAction.bind(this);
    this.handleToggleExpand = this.handleToggleExpand.bind(this);
    this.handleDeleteZone = this.handleDeleteZone.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.toggleLocationError = this.toggleLocationError.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.setError = this.setError.bind(this);
  }

  handleZoneDelete(id) {
    const { action } = this.state;
    if (action === "add") {
      this.handleDataChange({});
    }
    this.props.handleZoneDelete(id);
  }

  toggleLocationError() {
    this.setState({
      locationError: !this.state.locationError,
    });
  }

  handlePincodeGroupAddEdit() {
    const { action, data, locationError } = this.state;
    const {
      storeId,
      fetchAndUpdate,
      toggleStoreError,
      newlyAddedPolygon,
      clearTempData,
    } = this.props;

    if (!storeId) {
      toggleStoreError();
      return;
    }

    if (locationError) {
      return;
    }

    // unseting the existing error by passing empty param
    this.setError();

    const locations = getPolygonLocations(newlyAddedPolygon);
    let params, requestMethod, url;

    if (action === "add") {
      if (!(locations && locations.length)) {
        this.toggleLocationError();
        return;
      }
      url = "/logistics-service/delivery-area";
      requestMethod = "post";
      params = { ...data, locations, configType: "POLYGON" };
    } else if (action === "edit") {
      url = `/logistics-service/delivery-area/${data.id}`;
      requestMethod = "put";
      params = {
        configType: data.configType,
        name: data.name,
        locations: locations || data.area.pincodes,
        deliveryFee: data.deliveryFee,
        lowerWeightLimit: data?.lowerWeightLimit,
        ratePerWeightUnit: data?.ratePerWeightUnit,
        chargePercentage: data.chargePercentage,
      };
    }
    params.storeId = Number(storeId);

    const api = new API({ url });
    api[requestMethod](params)
      .then(() => fetchAndUpdate(storeId))
      .then(() => {
        this.setAction();
        this.handleDataChange({});
        clearTempData();
      })
      .catch((error) => {
        this.setError(error.message);
        console.error(error);
      });
  }

  toggleAction(nextAction) {
    return (e) => {
      e && e.preventDefault && e.preventDefault();
      const { action } = this.state;
      if (action === nextAction) {
        this.setAction(undefined);
      } else {
        this.setAction(nextAction);
      }
    };
  }

  setAction(action) {
    this.setState({ action });
  }

  setError(error) {
    this.setState({ error });
  }

  handleDataChange(data) {
    this.setState({ data });
  }

  handleToggleExpand() {
    this.props.handleOpenClose("polygon");
    this.setAction(undefined);
    this.setState({ data: {} });
  }

  handleDeleteZone() {
    const { action, data } = this.state;
    const { storeId, fetchAndUpdate, clearTempData } = this.props;

    if (action === "add") {
      this.setState({ data: {} });
      this.setAction(undefined);
    } else if (action === "edit") {
      const api = new API({
        url: `/logistics-service/delivery-area/${data.id}`,
      });
      api
        .delete({ id: data.id })
        .then(() => fetchAndUpdate(storeId))
        .then(() => {
          this.setAction(undefined);
          this.setState({ data: {} });
          clearTempData();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleCancel() {
    this.setError();
    this.setAction();
    this.handleDataChange({});
    this.props.clearTempData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.newlyAddedPolygon !== this.props.newlyAddedPolygon &&
      this.state.locationError
    ) {
      this.toggleLocationError();
    }
    // Bitwise XOR operator
    if (Boolean(prevState.action) ^ Boolean(this.state.action)) {
      this.props.changeEditMode();
      this.props.changeMapEditMode();

      if (this.state.action === "add") {
        this.props.setShowDrawOption(true);
      } else {
        this.props.setShowDrawOption(false);
      }
    }

    if (prevProps.active !== this.props.active && !this.props.active) {
      this.handleCancel();
    }
  }

  render() {
    const { action, data, locationError, error } = this.state;
    const {
      active,
      polygonalArea = [],
      newlyAddedPolygon,
      distinctColors,
      setTempLocation,
      editing,
    } = this.props;
    const hasPincodesSet = Boolean(polygonalArea && polygonalArea.length);

    const FormComp = action === "add" ? AddForm : EditForm;
    const newlyAddedLocations = getPolygonLocations(newlyAddedPolygon);
    const hidden = editing && !action;
    return (
      <div
        className={`pincode-form polygon-form zones-subsection ${
          active ? "" : "collapsed-section"
        } ${hidden ? "hidden" : ""} ${action ? "edit-mode" : ""}`}
      >
        <span onClick={this.handleToggleExpand} className="section-title">
          {getMessage("extensions.deliveryArea.polygon.title")}
        </span>
        {!active && (
          <span className="section-subtitle">
            {hasPincodesSet
              ? `${getSingularPluralForm(
                  polygonalArea.length,
                  "zone"
                )}  covered`
              : "No zone defined"}
          </span>
        )}
        <span onClick={this.handleToggleExpand} className="open-close-icon">
          {active ? "\u2013" : "+"}
        </span>
        {!(hasPincodesSet || action) ? (
          active && (
            <div className="zones-content empty">
              <img src={emptyIcon} alt="no-pincode" className="empty-section" />
              <button
                onClick={this.toggleAction("add")}
                className="button primary"
              >
                + Add New Zone
              </button>
            </div>
          )
        ) : (
          <div className="zones-content">
            {active && !action && (
              <div className="header-action">
                <button
                  className={
                    "header-action-btn" + (action === "edit" ? " active" : "")
                  }
                  onClick={this.toggleAction("edit")}
                >
                  Edit
                </button>
                <button
                  className={
                    "header-action-btn" + (action === "add" ? " active" : "")
                  }
                  onClick={this.toggleAction("add")}
                >
                  Add New
                </button>
              </div>
            )}
            {action && (
              <FormComp
                value={data}
                action={action}
                onChange={this.handleDataChange}
                pincodeGroup={polygonalArea}
                onDeleteZone={this.handleDeleteZone}
                newlyAddedLocations={newlyAddedLocations}
                onSave={this.handlePincodeGroupAddEdit}
                locationError={locationError}
                setTempLocation={setTempLocation}
                name={newlyAddedPolygon?.name}
              />
            )}

            {!action && active && hasPincodesSet && (
              <PinCodesDisplay
                polygonGroups={polygonalArea}
                distinctColors={distinctColors}
              />
            )}
          </div>
        )}
        {action && (
          <ActionButtons
            onSubmit={this.handlePincodeGroupAddEdit}
            onCancel={this.handleCancel}
            error={error}
            disabled={!(data.area || data.name)}
            setError={this.setError}
          />
        )}
      </div>
    );
  }
}
