import React from "react";
import { Input, Upload, Textarea, Select } from "../../../../components/Form";
import { BannerWithText } from "zopsmart-ui";
import { fontSizes } from "../constants.js";

// lower case t is used in Text because it iis dependent on a module whose name is imageWithText with an Upper case T
const BannerWithTextLayout = () => {
  return {
    preview: BannerWithText,
    fields: ({ getState, updateState }) => {
      let buttonTextInput = "";
      let buttonLinkInput = "";
      if (getState(["button"])) {
        buttonTextInput = (
          <Input
            label="Text for Button"
            placeholder="Text for Button"
            value={getState(["action"]) || ""}
            onChange={(value) => {
              updateState(["action"], value);
            }}
          />
        );
        buttonLinkInput = (
          <Input
            label="Link for Button"
            placeholder="Link for Button"
            value={getState(["link"]) || ""}
            onChange={(value) => {
              updateState(["link"], value);
            }}
          />
        );
      }
      return (
        <div className="form-sections">
          <div className="form-section">
            <Input
              label="title"
              placeholder="Please enter title here"
              value={getState(["title"]) || ""}
              onChange={(value) => {
                updateState(["title"], value);
              }}
            />
            <Select
              label="font size (in px)"
              placeholder="font size for title in px"
              options={fontSizes}
              value={getState(["fontSizeForTitle"]) || ""}
              onChange={(value) => {
                updateState(["fontSizeForTitle"], value);
              }}
            />
            <Upload
              label="image"
              value={getState(["src"]) || ""}
              className="image_bannerbutton"
              onChange={(value) => {
                updateState(["src"], value);
              }}
            />
            <Select
              label="font size (in px)"
              placeholder="font size for description in px"
              options={fontSizes}
              value={getState(["fontSizeForDescription"]) || ""}
              onChange={(value) => {
                updateState(["fontSizeForDescription"], value);
              }}
            />
            <Input
              label="link"
              placeholder="URL to navigate on clicking"
              value={getState(["link"]) || ""}
              onChange={(value) => {
                updateState(["link"], value);
              }}
            />
            {buttonTextInput}
          </div>
          <div className="form-section">
            <Input
              label="subtitle"
              placeholder="Please enter subtitle here"
              value={getState(["subtitle"]) || ""}
              onChange={(value) => {
                updateState(["subtitle"], value);
              }}
            />
            <Input
              label="text color"
              placeholder="text color for title"
              type="color"
              value={getState(["textColorForTitle"]) || ""}
              onChange={(value) => {
                updateState(["textColorForTitle"], value);
              }}
            />
            <Textarea
              label="description"
              placeholder="Please enter description"
              value={getState(["details"]) || ""}
              onChange={(value) => {
                updateState(["details"], value);
              }}
            />
            <Input
              label="text color"
              placeholder="text color for description"
              type="color"
              value={getState(["textColorForDescription"]) || ""}
              onChange={(value) => {
                updateState(["textColorForDescription"], value);
              }}
            />
            {buttonLinkInput}
          </div>
        </div>
      );
    },
  };
};
export default BannerWithTextLayout;
