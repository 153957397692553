import React from "react";
import { Row, Cell } from "../../../../../components/Table";
import { getMessage } from "../../../../../lib/translator";
import {
  DropDown,
  DropDownItem,
  ICONS,
} from "../../../../../components/DropDown";

const tableHeader = [
  getMessage("orderPackageConfig.type.text.label"),
  getMessage("orderPackageConfig.package.length"),
  getMessage("orderPackageConfig.package.breadth"),
  getMessage("orderPackageConfig.package.height"),
  getMessage("role.action"),
];
const tableProperties = (handleEditPackage, handleDeletePackage) => {
  return {
    headers: tableHeader,
    row: ({ id, type, length, breadth, height }) => (
      <Row>
        <Cell>{type}</Cell>
        <Cell>{length}</Cell>
        <Cell>{breadth}</Cell>
        <Cell>{height}</Cell>
        <Cell>
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem
              onClick={() =>
                handleEditPackage({ id, type, length, breadth, height })
              }
            >
              {getMessage("orderPackageConfig.action.edit.text")}
            </DropDownItem>
            <DropDownItem onClick={() => handleDeletePackage(id)}>
              {getMessage("orderPackageConfig.delete.text")}
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    ),
  };
};

export default tableProperties;
