import React, { Component } from "react";
import API from "../../../../lib/api";
import BankForm from "./BankForm";
import PaymentGatewayForm from "./PaymentGatewayForm/index";
import { Popup, Dialog } from "../../../../components/Popup";
import Loader from "../../../../components/Loader";
import { getMessage } from "../../../../lib/translator";

export default class DefaultPaymentConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkedBankAccounts: [],
      bankFormShown: false,
      addBankShown: false,
      showLoader: true,
      showSucessDialog: false,
      showBankDialog: false,
      editError: "",
      addError: "",
    };

    this.showBankForm = this.showBankForm.bind(this);
    this.showAddBankForm = this.showAddBankForm.bind(this);
    this.hideBankForm = this.hideBankForm.bind(this);
    this.addBankAccount = this.addBankAccount.bind(this);
    this.hideAddBankForm = this.hideAddBankForm.bind(this);
    this.editBankAccount = this.editBankAccount.bind(this);
    this.deleteBankAccount = this.deleteBankAccount.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    const bankApi = new API({ url: "/account-service/bank-account" });
    const paymentModesApi = new API({ url: "/account-service/payment-mode" });
    Promise.all([bankApi.get(), paymentModesApi.get()])
      .then(
        ([bankResponse, paymentModesResponse]) => {
          let bankDetails = bankResponse.data.bankaccount;
          let paymentModes = paymentModesResponse.data.paymentmode;
          this.setState({
            linkedBankAccounts: bankDetails,
            paymentModes: paymentModes,
            showLoader: false,
          });
        },
        (bankError, paymentModeError) => {
          this.setState({
            error: bankError.message || paymentModeError.message,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  closeDialog() {
    this.setState({
      showSucessDialog: false,
      showBankDialog: false,
    });
  }

  showAddBankForm() {
    this.setState({
      addBankShown: true,
    });
  }

  hideAddBankForm() {
    this.setState({
      addBankShown: false,
      bankDetails: "",
      editError: "",
      addError: "",
    });
  }
  showBankForm(bankid) {
    this.setState(
      {
        bankDetails: this.state.linkedBankAccounts.filter(
          (account) => account.id === bankid
        )[0],
      },
      () => {
        this.setState({
          bankFormShown: true,
        });
      }
    );
  }
  hideBankForm() {
    this.setState({
      bankFormShown: false,
      bankDetails: "",
      editError: "",
      addError: "",
    });
  }
  addBankAccount(data) {
    this.setState({
      submitting: true,
    });
    const api = new API({ url: "/account-service/bank-account" });
    api
      .post(data)
      .then(
        (response) => {
          let newState = this.state.linkedBankAccounts;
          newState.push(response.data.bankaccount);
          this.setState({
            linkedBankAccounts: newState,
            addBankShown: false,
            addError: "",
          });
        },
        (error) => {
          this.setState({
            addError: error.message,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });

    this.hideBankForm();
  }

  editBankAccount(data) {
    const api = new API({ url: "/account-service/bank-account" });
    api
      .put(data)
      .then(
        (response) => {
          let responseBankAccount = response.data.bankaccount;
          let newLinkedBankAccounts = this.state.linkedBankAccounts;
          let editedAccountIndex = newLinkedBankAccounts.findIndex(
            (account) => account.id === responseBankAccount.id
          );
          newLinkedBankAccounts[editedAccountIndex] = responseBankAccount;
          this.setState({
            linkedBankAccounts: newLinkedBankAccounts,
            bankFormShown: false,
            editError: "",
          });
        },
        (error) => {
          this.setState({
            editError: error.message,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  deleteBankAccount(data) {
    const api = new API({ url: "/account-service/bank-account" });
    data.status = "DISABLED";
    api
      .put(data)
      .then(
        (response) => {
          let responseBankAccount = response.data.bankaccount;
          let newLinkedBankAccounts = this.state.linkedBankAccounts;
          let remainingAccounts = newLinkedBankAccounts.filter(
            (account) => account.id !== responseBankAccount.id
          );
          this.setState({
            linkedBankAccounts: remainingAccounts,
            bankFormShown: false,
            editError: "",
          });
        },
        (error) => {
          this.setState({
            editError: error.message,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <div>
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <div className="default-payment-configuration">
            <h3>
              {getMessage("settings.online.payment.bank.account.details")}
            </h3>
            <div>
              <div className="linked-bank-accounts">
                {this.state.linkedBankAccounts.map(
                  ({ id, bankName, accountNumber }) => (
                    <button
                      className="linked-bank-account"
                      key={accountNumber}
                      onClick={() => this.showBankForm(id)}
                    >
                      <div className="bank-name">{bankName}</div>
                      <div className="account-number">
                        {String(accountNumber).replace(
                          /.+(?=.{4,}$)/g,
                          "xxxxxxx-"
                        )}
                      </div>
                    </button>
                  )
                )}
                <button
                  className="link-bank-button"
                  onClick={this.showAddBankForm}
                >
                  {getMessage("online.payments.link.bank.account")}
                </button>
              </div>
              <Popup show={this.state.bankFormShown} close={this.hideBankForm}>
                <BankForm
                  onSubmit={this.editBankAccount}
                  bankDetails={this.state.bankDetails}
                  error={this.state.editError}
                  onDelete={this.deleteBankAccount}
                />
              </Popup>
              <Popup
                show={this.state.addBankShown}
                close={this.hideAddBankForm}
              >
                <BankForm
                  onSubmit={this.addBankAccount}
                  error={this.state.addError}
                />
              </Popup>
              <Dialog
                show={this.state.showSucessDialog}
                className="success"
                title={getMessage("settings.online.payments.success")}
                information={getMessage(
                  "settings.online.payment.linked.successfully"
                )}
                close={this.closeDialog}
                closeText={getMessage("settings.online.payment.ok")}
              />
              <Dialog
                show={this.state.showBankDialog}
                className="success"
                title={getMessage("settings.online.payments.success")}
                information={getMessage("settings.online.payment.linked.bank")}
                close={this.closeDialog}
                closeText={getMessage("settings.online.payment.ok")}
              />
            </div>
            <h3>{getMessage("settings.online.payment.gateway")}</h3>
            <div className="default-info">
              <span>{getMessage("settings.online.payment.our.gateway")}</span>
              <button
                type="button"
                className="button primary"
                onClick={this.props.switchToCustom}
              >
                {getMessage("settings.online.payment.use.your.gateway")}
              </button>
            </div>
            {
              <PaymentGatewayForm
                id={this.props.id}
                paymentModes={this.state.paymentModes}
                addedPaymentModes={this.props.addedGateways}
                back={this.props.back}
              />
            }
          </div>
        )}
      </div>
    );
  }
}
