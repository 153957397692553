import React, { Component } from "react";
import ListingPageWithRoutes from "../../../containers/ListingPage/listingRouter";
import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { Link } from "react-router-dom";
import { Row, Cell } from "../../../components/Table";
import { getMessage } from "../../../lib/translator";
import EmptyIcon from "./campaigns-empty.svg";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import CampaignsForm from "./Form";
import { formatDate } from "../../../lib/datetime";
import {
  localTimeToUtc,
  utcToLocalTime,
  getPrintableTime,
  getStandradDateTime,
} from "../../../lib/datetime";
import moment from "moment";
import { formatDataBeforeSubmit } from "../../../components/SegmentsForm";

import "./style.css";

function AddCampaign() {
  return (
    <div className="text-center">
      <Link
        to={{
          pathname: "/marketing/campaigns/add",
        }}
        className="primary button"
      >
        <span />+{" "}
        <span className="text">{getMessage("campaign.add.text")}</span>
      </Link>
    </div>
  );
}

const emptyState = {
  icon: EmptyIcon,
  message: getMessage("campaign.helpItems.message"),
  additionalViews: [AddCampaign],
};

const tableProperties = {
  headers: [
    getMessage("campaign.table.heading.name"),
    getMessage("campaign.table.heading.status"),
    getMessage("campaign.table.heading.runDate"),
    getMessage("campaign.table.heading.actions"),
  ],
  row: ({ id, name, comment, status, scheduledTime, onAction }) => (
    <Row>
      <Cell className="campaign-name">
        <Link
          to={`/marketing/campaigns/edit/${id}`}
          className="campaign-title-link"
        >
          {name}
        </Link>
      </Cell>
      <Cell className="campaign-status">
        <div className="campaign-mobile-view">
          {getMessage("campaign.table.heading.status")}
        </div>
        {status}
      </Cell>
      <Cell className="campaign-date">
        <div className="campaign-mobile-view">
          {getMessage("campaign.table.heading.runDate")}
        </div>
        <div>
          <div className="text-muted">
            <small className="scheduled-date">
              {scheduledTime ? formatDate(scheduledTime.split(" ")[0]) : null}
            </small>
          </div>
          <div className="text-muted">
            <small className="scheduled-time">
              {scheduledTime
                ? getPrintableTime(scheduledTime).split(",")[1]
                : null}
            </small>
          </div>
        </div>
      </Cell>
      <Cell className="campaign-actions">
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`/marketing/campaigns/edit/${id}`}>
              {getMessage("campaign.action.edit")}
            </Link>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              onAction(TABLE_ACTIONS.DELETE, { id });
            }}
          >
            {getMessage("campaign.action.delete")}
          </DropDownItem>
        </DropDown>
      </Cell>
    </Row>
  ),
};

class Campaigns extends Component {
  render() {
    const { menu } = this.props;
    return (
      <ListingPageWithRoutes
        menu={menu}
        className="campaigns-page"
        title={getMessage("campaign.table.heading")}
        addHeading={getMessage("campaign.form.add.heading")}
        editHeading={getMessage("campaign.form.edit.heading")}
        emptyState={emptyState}
        headerActions={AddCampaign}
        api={{
          url: "/promo-service/campaign",
          transform: (response) => {
            const campaigns = response.data.campaign;
            campaigns.map((campaign) => {
              if (campaign.scheduledTime) {
                campaign.scheduledTime = getStandradDateTime(
                  utcToLocalTime,
                  campaign.scheduledTime
                );
              }
              return campaign;
            });
            return campaigns;
          },
        }}
        tableProperties={tableProperties}
        form={{
          component: CampaignsForm,
          transformResponse: (response) => {
            const campaign = response.data.campaign;
            let newCampaign = { ...campaign };
            campaign.campaignData.forEach((data) => {
              if (data.mediumName === "EMAIL") {
                newCampaign["emailSubject"] = data.template.subject;
                newCampaign["emailBody"] = (data.template.body || "").replace(
                  /<br \/>/g,
                  "\n"
                );
              } else if (data.mediumName === "SMS") {
                newCampaign["smsContent"] = data.template.content;
              } else if (data.mediumName === "PUSH") {
                newCampaign["pushSubject"] = data.template.title;
                newCampaign["pushBody"] = data.template.message;
                newCampaign["pushImage"] = data.template.image && [
                  data.template.image,
                ];
                newCampaign["pushUrl"] =
                  data.template.deepLinkUrl && data.template.deepLinkUrl;
              }
            });
            if (campaign.scheduledTime) {
              const temp = getStandradDateTime(
                utcToLocalTime,
                campaign.scheduledTime
              ).split(" ");
              newCampaign["date"] = temp[0];
              newCampaign["time"] = `${temp[1].split(":")[0]}:${
                temp[1].split(":")[1]
              }`;
            }
            const newDataArray = campaign?.userSet?.data?.map((userSetData) => {
              if (userSetData.date) {
                userSetData.date = getStandradDateTime(
                  utcToLocalTime,
                  userSetData.date
                ).split(" ")[0];
              }

              if (userSetData.startDate) {
                userSetData.startDate = getStandradDateTime(
                  utcToLocalTime,
                  userSetData.startDate
                ).split(" ")[0];
              }
              if (userSetData.endDate) {
                userSetData.endtDate = getStandradDateTime(
                  utcToLocalTime,
                  userSetData.endDate
                ).split(" ")[0];
              }
              return userSetData;
            });

            newCampaign["userSet"] = {
              ...campaign.userSet,
              data: newDataArray,
            };

            delete newCampaign["campaignData"];
            return newCampaign;
          },
          transformSubmit: (formData) => {
            let newFormData = {
              name: formData.name,
              comment: formData.comment || "",
            };
            let campaignData = [];
            if (formData.emailSubject) {
              campaignData.push({
                mediumId: 1,
                template: {
                  body: (formData.emailBody || "").replace(/\n/g, "<br />"),
                  subject: formData.emailSubject,
                },
              });
            }
            if (formData.smsContent) {
              campaignData.push({
                mediumId: 2,
                template: {
                  content: formData.smsContent,
                },
              });
            }
            if (formData.pushSubject) {
              campaignData.push({
                mediumId: 3,
                template: {
                  title: formData.pushSubject,
                  message: formData.pushBody,
                  image: formData.pushImage?.[0],
                  deepLinkUrl: formData.pushUrl ?? "",
                },
              });
            }
            newFormData["campaignData"] = campaignData;
            if (formData.date) {
              newFormData["scheduledTime"] = moment(
                localTimeToUtc(`${formData.date} ${formData.time}`)
              ).format("YYYY-MM-DD HH:mm");
            }

            if (formData?.userSet) {
              // date inside userSet should be sent in YYYY-MM-DD HH:mm:ss format
              const newUserSetData = formData.userSet.data.map(
                (userSetData) => {
                  if (userSetData.date && userSetData.date.length === 10) {
                    userSetData.date = getStandradDateTime(
                      localTimeToUtc,
                      `${userSetData.date} 00:00:00`
                    );
                  }
                  if (
                    userSetData.startDate &&
                    userSetData.startDate.length === 10
                  ) {
                    userSetData.startDate =
                      userSetData.startDate === moment().format("YYYY-MM-DD")
                        ? moment().format("YYYY-MM-DD HH:mm:ss")
                        : getStandradDateTime(
                            localTimeToUtc,
                            `${userSetData.startDate} 00:00:00`
                          );
                  }
                  if (
                    userSetData.endDate &&
                    userSetData.endDate.length === 10
                  ) {
                    userSetData.endDate = getStandradDateTime(
                      localTimeToUtc,
                      `${userSetData.endDate} 23:59:59`
                    );
                  }
                  return userSetData;
                }
              );

              newFormData.userSet = { data: newUserSetData };
              newFormData = formatDataBeforeSubmit(newFormData);
            }
            return newFormData;
          },
        }}
      />
    );
  }
}

export default Campaigns;
