import React from "react";
import { Input, Upload } from "../../../../components/Form";
import DualImageBanner from "../../../../components/Layout/DualImageBanner";
import { getMessage } from "../../../../lib/translator";

const DualImageBannerLayout = () => {
  return {
    preview: DualImageBanner,
    fields: ({ getState, updateState }) => (
      <div className="form-sections">
        <div className="form-section">
          <Upload
            label={getMessage("dualImageBanner.image1")}
            value={getState(["imageUrl1"]) || ""}
            onChange={(value) => {
              updateState(["imageUrl1"], value);
            }}
          />
          <Input
            label={getMessage("dualImageBanner.title")}
            placeholder={getMessage("dualImageBanner.InputTitlePlaceholder")}
            value={getState(["title"]) || ""}
            onChange={(value) => {
              updateState(["title"], value);
            }}
          />
          <Input
            label={getMessage("dualImageBanner.link")}
            placeholder={getMessage("dualImageBanner.InputLinkPlaceholder")}
            value={getState(["link"]) || ""}
            onChange={(value) => {
              updateState(["link"], value);
            }}
          />
        </div>
        <div className="form-section">
          <Upload
            label={getMessage("dualImageBanner.image2")}
            value={getState(["imageUrl2"]) || ""}
            onChange={(value) => {
              updateState(["imageUrl2"], value);
            }}
          />
          <Input
            label={getMessage("dualImageBanner.subTitle")}
            placeholder={getMessage("dualImageBanner.InputSubtitlePlaceholder")}
            value={getState(["subTitle"]) || ""}
            onChange={(value) => {
              updateState(["subTitle"], value);
            }}
          />
        </div>
      </div>
    ),
  };
};
export default DualImageBannerLayout;
