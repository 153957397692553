import React from "react";
import { getPrintableTime, formatTime } from "../../../lib/datetime";
import { getMessage } from "../../../lib/translator";
import { Link } from "react-router-dom";
import { Row, Cell } from "../../../components/Table";
import { getSession, isExtensionEnabled } from "../../../lib/auth";
import { getAsapDuration } from "../../../lib/commonlyused";

const orderStatus = {
  COMPLETED: "order.status.completed",
  CANCELLED: "order.status.cancelled",
  PENDING: "order.status.pending",
};

export function formatDate(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1; // January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return String(yyyy) + String(mm) + String(dd);
}

const tableHeader = () => {
  let headers = [
    getMessage("operations.pickingQueue.header.referenceNumber"),
    getMessage("operations.pickingQueue.header.placedTime"),
    getMessage("operations.pickingQueue.header.amount"),
    getMessage("operations.pickingQueue.header.status"),
  ];
  if (!isExtensionEnabled("DeliverySlots")) {
    return headers;
  } else {
    headers.splice(2, 0, getMessage("order.table.header.expectedTime"));
    return headers;
  }
};

export const tableProperties = (isMobileView) => {
  return {
    headers: tableHeader(),
    row: ({
      referenceNumber,
      clientId,
      store,
      pickupLocation,
      createdAt,
      invoiceAmount,
      itemCount,
      status,
      preferredDate,
      slotType,
      slotEndTime,
      slotStartTime,
    }) => (
      <Row>
        <Cell className="column-order-number">
          <div>
            <Link
              className="order-number"
              to={`/operations/orders/${referenceNumber}`}
            >
              {clientId ? (
                <React.Fragment>
                  <span className="text-muted">#</span>
                  {clientId}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span className="text-muted">Z</span>
                  {referenceNumber}
                </React.Fragment>
              )}
            </Link>
          </div>
          {store ? <small className="text-muted">{store.name}</small> : null}
          {pickupLocation && !store ? (
            <small className="text-muted">{pickupLocation.name}</small>
          ) : null}
        </Cell>
        <Cell className="column-creation-time order-place-time">
          {isMobileView ? (
            <div className="mobile-header">
              {getMessage("operations.pickingQueue.header.placedTime")}
            </div>
          ) : (
            ""
          )}
          <div>
            {createdAt ? getPrintableTime(createdAt).split(", ")[0] : null}
          </div>
          <small className="text-muted">
            {createdAt ? getPrintableTime(createdAt).split(", ")[1] : null}
          </small>
        </Cell>
        {isExtensionEnabled("DeliverySlots") ? (
          <Cell className="column-creation-time order-preffered-date">
            {isMobileView ? (
              <div className="mobile-header">
                {getMessage("order.table.header.expectedTime")}
              </div>
            ) : (
              ""
            )}
            <div>
              {preferredDate
                ? getPrintableTime(preferredDate).split(", ")[0]
                : null}
            </div>
            <small
              className={`text-muted ${!slotEndTime ? "zero-font" : ""}`.trim()}
            >
              {slotStartTime && slotEndTime && slotType !== "ASAP"
                ? formatTime(slotStartTime) + " - " + formatTime(slotEndTime)
                : getAsapDuration(slotStartTime, slotEndTime)}
            </small>
          </Cell>
        ) : null}
        <Cell className="column-order-amount">
          {isMobileView ? (
            <div className="mobile-header">
              {getMessage("operations.pickingQueue.header.amount")}
            </div>
          ) : (
            ""
          )}
          <div className="order-amount-picking-queue">
            {getSession().organization.currency.symbol + invoiceAmount}
          </div>
          <small className="text-muted">
            {getMessage("operations.pickingQueue.itemCount", {
              count: itemCount,
            })}
          </small>
        </Cell>
        <Cell className="column-order-status">
          {isMobileView ? (
            <div className="mobile-header">
              {getMessage("operations.pickingQueue.header.status")}
            </div>
          ) : (
            ""
          )}
          <div>
            {orderStatus[status] ? getMessage(orderStatus[status]) : null}
          </div>
        </Cell>
      </Row>
    ),
  };
};
