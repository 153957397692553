import React from "react";
import Loader from "../../../../components/Loader";
import { getMessage } from "../../../../lib/translator";
import { BaseForm, Input } from "../../../../components/Form";
import API from "../../../../lib/api";
import { Dialog } from "../../../../components/Popup";

class IntegrationSettingsForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.showLoader = false;
    this.fetchConfigData = this.fetchConfigData.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  componentDidMount() {
    this.fetchConfigData();
  }

  fetchConfigData() {
    this.setState({
      showLoader: true,
    });

    const websiteConfigApi = new API({ url: `/config-service/config/website` });
    const analyticsConfigApi = new API({
      url: `/config-service/config/analytics`,
    });

    Promise.all([websiteConfigApi.get(), analyticsConfigApi.get()])
      .then(([websiteConfigResponse, analyticsConfigResponse]) => {
        const values = {
          ...websiteConfigResponse?.data?.website,
          googleTagManagerId: analyticsConfigResponse?.data?.analytics?.gtmId,
        };
        this.setState({ values, showLoader: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ showLoader: false });
        if (error.code === 401) {
          throw error;
        }
      });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    const websiteConfigApi = new API({ url: `/config-service/config` });
    const websiteData = { ...data };
    delete websiteData.googleTagManagerId;
    websiteConfigApi
      .post({
        website: websiteData,
        analytics: { gtmId: data.googleTagManagerId },
      })
      .then((response) => {
        this.setState({ formError: "", submitting: false, showSuccess: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          formError: error.message,
          submitting: false,
          showSuccess: false,
        });

        if (error.code === 401) {
          throw error;
        }
      });
  }

  handleCloseDialog(e) {
    e && e.preventDefault();
    this.setState({ showSuccess: false, formError: "" });
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const { showLoader, formError, showSuccess } = this.state;
    return showLoader ? (
      <Loader />
    ) : (
      <Form className="order-management-form">
        <Input
          label={getMessage("integrationSettings.googleMapsKey.label")}
          placeholder={getMessage(
            "integrationSettings.googleMapsKey.placeholder"
          )}
          name="googleApisKey"
          type="text"
          {...this.generateStateMappers({
            stateKeys: ["googleApisKey"],
            loseEmphasisOnFill: true,
          })}
        />
        <Input
          label={getMessage("integrationSettings.googleCaptchaKey.label")}
          placeholder={getMessage(
            "integrationSettings.googleCaptchaKey.placeholder"
          )}
          name="googleCaptchaKey"
          type="text"
          {...this.generateStateMappers({
            stateKeys: ["googleCaptchaKey"],
            loseEmphasisOnFill: true,
          })}
        />
        <Input
          label={getMessage("integrationSettings.googleCaptchaSecret.label")}
          placeholder={getMessage(
            "integrationSettings.googleCaptchaSecret.placeholder"
          )}
          name="googleCaptchaSecret"
          type="text"
          {...this.generateStateMappers({
            stateKeys: ["googleCaptchaSecret"],
            loseEmphasisOnFill: true,
          })}
        />
        <Input
          label={getMessage("integrationSettings.gtm.label")}
          placeholder={getMessage("integrationSettings.gtm.placeholder")}
          name="googleTagManagerId"
          type="text"
          {...this.generateStateMappers({
            stateKeys: ["googleTagManagerId"],
            loseEmphasisOnFill: true,
          })}
        />
        <Dialog
          className={showSuccess ? "success" : ""}
          show={showSuccess || formError}
          information={
            showSuccess
              ? getMessage("integrationSettings.dialog.success.info")
              : formError
          }
          close={this.handleCloseDialog}
          closeText={getMessage("okay")}
        />
        <SubmitButton disabled={this.state.submitting}>
          {getMessage("basicInfo.submit.text")}
        </SubmitButton>
      </Form>
    );
  }
}

export default IntegrationSettingsForm;
