import React from "react";
import {
  BaseForm,
  CategorySearch,
  Input,
  MultiTextInput,
  Upload,
  Select,
  Searchable,
  SingleDatePicker,
  Radio,
  VALIDATION_TYPES,
  MultiSelect,
  BrandSearch,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import moment from "moment";
import API from "../../../../lib/api";
import Loader from "../../../../components/Loader";
import deleteIcon from "./delete-icon.svg";
import Image from "../../../../components/Image";
import SelectStoresSearch from "../../../../components/SelectStoresSearch";
import infoIcon from "./info-icon.svg";

import "./style.css";
import { isExtensionEnabled } from "../../../../lib/auth";

const getTimes = () => {
  let times = [];
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      times.push(`0${i}:00`);
      times.push(`0${i}:30`);
    } else {
      times.push(`${i}:00`);
      times.push(`${i}:30`);
    }
  }
  return times;
};

const removeSeconds = (status, time) => {
  let hourMinutes = [];
  if (status === "start") {
    hourMinutes =
      time &&
      time.startTime &&
      time.startTime.split(" ")[1] &&
      time.startTime.split(" ")[1].split(":");
  } else if (status === "end") {
    hourMinutes =
      time &&
      time.endTime &&
      time.endTime.split(" ")[1] &&
      time.endTime.split(" ")[1].split(":");
  }
  hourMinutes && hourMinutes.pop();
  hourMinutes = hourMinutes && hourMinutes.join(":");
  return hourMinutes;
};

class BannerEditForm extends BaseForm {
  constructor(props) {
    super(props);
    if (!this.props.value) {
      let value = this.state.values;
      if (this.props.method === "add") {
        value.scope = props.options.scopes.global;
        value.startTime = moment().format("YYYY-MM-DD HH:mm:ss");
      }
    }
    this.onDelete = this.onDelete.bind(this);
  }
  onDelete() {
    if (this.props.value) {
      let value = this.props.value;
      let { pageTypes } = this.props.options;
      value.linkUrl = value.linkUrl.replace(/^\//, "");
      switch (value.pageType) {
        case pageTypes.category:
          value.categoryUrl = this.state.values.categoryUrl;
          break;
        case pageTypes.brand:
          value.brandUrl = this.state.values.brandUrl;
          break;
        case pageTypes.productTag:
          value.productTagUrl = this.state.values.productTagUrl;
          break;
        case pageTypes.search:
          value.searchUrl = this.state.values.searchUrl;
          break;
        default:
          break;
      }
    }
    this.props.onDelete();
  }

  componentDidMount() {
    let { pageTypes } = this.props.options;
    if (this.props.method === "add") {
      this.setState({ loader: true });
    }
    if (this.state.values && this.props.method === "edit") {
      let pageType = this.state.values.pageType;
      let values = Object.assign({}, this.state.values);
      let url = values.url || [];
      values.linkUrl = values.linkUrl.replace(/^\//, "");
      let params = "";
      this.setState({
        values,
        loader: false,
      });
      if (url.length > 0) {
        url.forEach((slug, i, arr) => {
          params += `slug=${slug}`;
          if (arr.length - 1 > i) {
            params += `&`;
          }
        });
      }
      if (pageType === pageTypes.category && url.length > 0) {
        let categoryApi = new API({
          url: `/catalogue-service/category?${params}`,
        });
        categoryApi.get().then((response) => {
          let category = response.data.category;
          values.categoryUrl = category;
          this.setState({
            values,
            loader: true,
          });
        });
      } else if (pageType === pageTypes.brand && url.length > 0) {
        let brandApi = new API({ url: `/catalogue-service/brand?${params}` });
        brandApi
          .get()
          .then((response) => {
            let brand = response.data.brand;
            values.brandUrl = brand;
            this.setState({
              values,
              loader: true,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (pageType === pageTypes.productTag && url.length > 0) {
        let tagApi = new API({ url: `/catalogue-service/tag?${params}` });
        tagApi
          .get()
          .then((response) => {
            let tag = response.data.tag;
            values.productTagUrl = tag;
            this.setState({
              values,
              loader: true,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (pageType === pageTypes.search && url.length > 0) {
        values.searchUrl = url;
        this.setState({
          values,
          loader: true,
        });
      } else {
        this.setState({ loader: true });
      }
    }
  }
  render() {
    let { Form } = this.components;
    let { SubmitButton, CancelButton } = this.buttons;
    let values = this.state.values;
    const edit = this.props.method === "edit";
    const {
      stores,
      multiStoreEnabled,
      pages,
      productTagEnabled,
      brandsEnabled,
      pageTypes,
      scopes,
      availableLangs,
    } = this.props.options;
    let scope = !(values && values.scope !== scopes.storeSpecific);
    let pageType = values && values.pageType;
    let startTimePlaceholder = removeSeconds("start", values);
    let endTimePlaceholder = removeSeconds("end", values);
    let options = [
      {
        text: getMessage("banners.editForm.radio.option.global"),
        value: values && values.scope === scopes.global ? scopes.global : "",
      },
    ];

    if (multiStoreEnabled) {
      options.push({
        text: getMessage("banners.editForm.radio.option.storeSpecific"),
        value: scopes.storeSpecific,
      });
    }
    return (
      <Form className="banner-edit-form">
        <Upload
          label={getMessage("banners.editForm.image.label")}
          name="imageUrl"
          required
          {...this.generateStateMappers({
            stateKeys: ["imageUrl"],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        <div className="form-sections">
          <div className="form-section seachable-component">
            <Select
              label={getMessage("banners.select.page.type")}
              placeholder={getMessage("banners.page.type.placeholder")}
              name="pageType"
              required
              options={pages}
              disabled={edit}
              {...this.generateStateMappers({
                stateKeys: ["pageType"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            {pageType &&
              pageType !== pageTypes.home &&
              (this.state.loader ? (
                <div className="searched-items">
                  {pageType === pageTypes.category && (
                    <CategorySearch
                      label={getMessage("banners.form.category.heading")}
                      placeholder={getMessage(
                        "banners.form.category.placeholder"
                      )}
                      name="category"
                      multiple
                      {...this.generateStateMappers({
                        stateKeys: ["categoryUrl"],
                        loseEmphasisOnFill: true,
                      })}
                    />
                  )}
                  {pageType === pageTypes.brand && brandsEnabled && (
                    <BrandSearch
                      label={getMessage("banners.form.brand.heading")}
                      placeholder={getMessage("banners.form.brand.placeholder")}
                      name="brand"
                      multiple
                      {...this.generateStateMappers({
                        stateKeys: ["brandUrl"],
                        validationType: VALIDATION_TYPES.ONSUBMIT,
                        loseEmphasisOnFill: true,
                      })}
                    />
                  )}
                  {pageType === pageTypes.productTag && productTagEnabled && (
                    <Searchable
                      label={getMessage("banners.form.tag.heading")}
                      placeholder={getMessage("banners.form.tag.placeholder")}
                      name="tag"
                      searchUrl="/catalogue-service/tag"
                      valueKey="id"
                      responseKey="tag"
                      nameKey="name"
                      searchKey="name"
                      transformResponse={(response) => response.data.tag || []}
                      multiple
                      {...this.generateStateMappers({
                        stateKeys: ["productTagUrl"],
                        validationType: VALIDATION_TYPES.ONSUBMIT,
                        loseEmphasisOnFill: true,
                      })}
                    />
                  )}
                  {pageType === pageTypes.search && (
                    <div>
                      <MultiTextInput
                        label={getMessage("banners.form.search.term.label")}
                        placeholder={getMessage(
                          "banners.form.search.term.placeholder"
                        )}
                        name="searchTerm"
                        type="text"
                        required
                        {...this.generateStateMappers({
                          stateKeys: ["searchUrl"],
                          validationType: VALIDATION_TYPES.ONSUBMIT,
                          loseEmphasisOnFill: true,
                        })}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <Loader size="sm" />
              ))}
          </div>
          <div className="form-section seachable-component">
            <Radio
              label={getMessage("banners.editForm.radio.label")}
              name="scope"
              options={options}
              {...this.generateStateMappers({
                stateKeys: ["scope"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            {multiStoreEnabled && stores && scope && (
              <div className="searched-items">
                <SelectStoresSearch
                  label={getMessage("banners.stores")}
                  placeholder={getMessage("banners.stores.placeholder")}
                  _this={this}
                  stores={stores.filter(
                    (store) => store.hasPicking || store.hasSelfCheckout
                  )}
                  stateKey="storeId"
                  required
                />
              </div>
            )}
          </div>
          <div className="form-section date-time-section">
            <SingleDatePicker
              label={getMessage("banners.editForm.validity.from")}
              placeholder={getMessage("banners.startTime.placeholder")}
              displayFormat="YYYY-MM-DD"
              name="startTime"
              className="date-section"
              allowAllDates
              openDirection={"up"}
              {...this.generateStateMappers({
                stateKeys: ["startTime"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Select
              label={getMessage("banners.form.startTime")}
              name="bannerStartTime"
              type="text"
              className="time-section"
              placeholder={startTimePlaceholder || "00:00"}
              options={getTimes()}
              {...this.generateStateMappers({
                stateKeys: ["bannerStartTime"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className="form-section date-time-section">
            <SingleDatePicker
              label={getMessage("banners.editForm.validity.to")}
              placeholder={getMessage("banners.endTime.placeholder")}
              displayFormat="YYYY-MM-DD"
              openDirection={"up"}
              allowToday
              enableToday
              name="endTime"
              className="date-section"
              {...this.generateStateMappers({
                stateKeys: ["endTime"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Select
              label={getMessage("banners.form.endTime")}
              name="bannerEndTime"
              type="text"
              className="time-section"
              placeholder={endTimePlaceholder || "00:00"}
              options={getTimes()}
              {...this.generateStateMappers({
                stateKeys: ["bannerEndTime"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className="form-section link-section">
            <div className="cx-import-tooltip">
              <img src={infoIcon} alt="note" />
              <div className="tooltiptext">
                <div className="tooltip-title">Note:</div>
                <div className="instruction">
                  * The link should be relative to the store url eg:
                  product/Xioami or categories/Groceries
                </div>
              </div>
            </div>
            <Input
              label={getMessage("banners.editForm.link.label")}
              placeholder={getMessage("banners.editForm.link.placeholder")}
              name="linkUrl"
              required
              {...this.generateStateMappers({
                stateKeys: ["linkUrl"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>

          <div className="form-section language-section">
            {isExtensionEnabled("MultiLingualSupport") && (
              <MultiSelect
                label={getMessage("banners.languages")}
                name="language"
                placeholder={getMessage("banners.languages.placeholder")}
                className="language"
                options={(availableLangs || []).map((language) => {
                  return {
                    text: language.name,
                    value: language.code,
                  };
                })}
                {...this.generateStateMappers({
                  stateKeys: ["language"],
                  loseEmphasisOnFill: true,
                })}
              />
            )}
          </div>
        </div>
        <div className="form-actions">
          {edit && (
            <button
              type="button"
              className="delete-button"
              onClick={this.onDelete}
            >
              <Image src={deleteIcon} alt="delete" />
              <span>{getMessage("banners.editForm.button.delete")}</span>
            </button>
          )}
          <CancelButton>
            {getMessage("banners.editForm.button.cancel")}
          </CancelButton>
          <SubmitButton>
            {getMessage("banners.editForm.button.save")}
          </SubmitButton>
        </div>
      </Form>
    );
  }
}

export default BannerEditForm;
