import React, { Component } from "react";
import API from "../../../../lib/api";
// import { Select } from '../../../../components/Form/Inputs'
import ExpandingStats from "../ExpandingStats";
// import {getMessage} from '../../../../lib/translator'

/*
  TODO: Remove comments after full feature is implemented
*/

import "./style.css";

class TripStatistics extends Component {
  constructor() {
    super();
    this.state = {
      zone: "all",
      trips: null,
    };
    this.getOrderStats = this.getOrderStats.bind(this);
    this.getOpenTrips = this.getOpenTrips.bind(this);
  }

  getOrderStats() {
    let totalOrders;
    if (this.state.zone === "all") {
      totalOrders = this.props.data.reduce((accumulator, currentZone) => {
        return accumulator.concat(currentZone.orders);
      }, []);
    } else {
      totalOrders = this.props.data.filter(
        ({ name }) => name === this.state.zone
      )[0].orders;
    }
    return totalOrders;
  }

  getOpenTrips() {
    this.tripApi = new API({
      url: "/logistics-service/trip",
    });
    let params = {};
    if (this.props.storeId) {
      params.storeId = this.props.storeId;
    }
    params.status = "OPEN";
    this.tripApi
      .get(params)
      .then((response) => {
        this.setState({
          trips: response.data.trip,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  componentDidMount() {
    this.getOpenTrips();
  }
  componentDidUpdate(prevProps) {
    if (this.props.storeId !== prevProps.storeId) {
      this.getOpenTrips();
    }
  }
  componentWillUnmount() {
    this.tripApi && this.tripApi.cancel();
  }
  render() {
    // let zones = this.props.data
    // let orders = this.getOrderStats()
    if (!this.state.trips) {
      return null;
    }
    return (
      <div className="stats-section">
        {/* <button className='refresh-button button'>Refresh Statistics</button>
        <div className='order-chart'>
          <div className='order-chart-top'>
            <div className='order-chart-heading-wrapper'>
              <div className='order-heading'>Orders</div>
              <Select
                placeholder={'All Zones'}
                value={this.state.zone}
                onChange={zone => { this.setState({ zone }) }}
                options={[{text: 'All Zones', value: 'all'}, ...zones.map(({ name }) => ({
                  text: name,
                  value: name
                }))]}
              />
            </div>
            <div className='order-count'>{getMessage('tripPlanner.orderCount', { count: orders.length })}</div>
          </div>
          <div className='order-chart-bottom'>
            <div className='stat-chart'>
              <div className='heading'>
                <span>Slot</span>
                <span>Pend</span>
                <span>Store</span>
                <span>Disp</span>
                <span>Comp</span>
              </div>
            </div>
          </div>
        </div> */}
        <ExpandingStats
          heading="Vehicles"
          data={this.props.vehicles}
          currentVehicles={
            this.state.trips
              ? this.state.trips.map((trip) => {
                  return Object.assign(
                    {
                      tripId: trip.id,
                      startTime: trip.startTime,
                      vehicleStart: trip.vehicleStartTime,
                    },
                    trip.vehicle
                  );
                })
              : null
          }
          unit="Vehicle"
          className="vehicles"
        />
        <ExpandingStats
          heading="Runner Executive"
          data={this.props.re}
          currentRunners={
            this.state.trips
              ? this.state.trips.map((trip) => {
                  return Object.assign(
                    { tripId: trip.id, startTime: trip.startTime },
                    trip.deliveryAgent
                  );
                })
              : null
          }
          unit="RE"
          className="runner-executive"
        />
      </div>
    );
  }
}

export default TripStatistics;
