import React from "react";

function DisapproveIcon(props) {
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(1.25 1.25)" fill="none" fill-rule="evenodd">
        <circle fill="#FF3D19" cx="8.75" cy="8.75" r="8.75" />
        <g stroke="#FFF" stroke-linecap="round">
          <path d="m4.75 12.75 8-8M4.75 4.75l8 8" />
        </g>
      </g>
    </svg>
  );
}

export default DisapproveIcon;
