import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  BaseForm,
  VALIDATION_TYPES,
  Radio,
  Input,
  ProductSearch,
} from "../../../../../components/Form";
import API from "../../../../../lib/api";
import { getMessage } from "../../../../../lib/translator";
import {
  getSession,
  isExtensionEnabled,
  getExtensionDetails,
} from "../../../../../lib/auth";
import {
  getOrdinal,
  utcToLocalTime,
  formatIsoTime,
  localTimeToUtc,
  convertToMilitaryTime,
} from "../../../../../lib/datetime";
import {
  getDisplayAddress,
  sortSlotsAvailability,
} from "../../../../../lib/commonlyused";
import { ProductsTable } from "../ProductsTable";
import InvoiceSummary from "../InvoiceSummary";
import { Dialog } from "../../../../../components/Popup";
import Image from "../../../../../components/Image";
import AddAddressForm from "../../../Customers/Details/AddAddress";
import MetaDataForm from "../OrderMetaData";
import Popup from "../../../../../components/Popup/Popup";
import AddIcon from "../icons/add-button-icon.svg";
import "./style.css";

function formatValue(value, currency) {
  return Math.round(value * 100) / 100;
}

function formatOrderDetails(productStoreSpecificData) {
  let { mrp, discount } = productStoreSpecificData;
  mrp = formatValue(mrp);
  discount = formatValue(discount);
  const amount = mrp - discount;
  return { amount, mrp, discount };
}

function generateOptions(slots, className) {
  let slotsArr = [];
  let slotStructure = {};
  let slotDateOptions = [];
  let dateArr = [];

  Object.entries(slots).forEach(([key, data]) => {
    data.forEach((item) => {
      slotsArr.push(item);
      if (item.type === "ASAP") {
        const currentUtc = moment().utc().format("YYYY-MM-DD HH:mm:ss");
        let currentLocal = moment(utcToLocalTime(currentUtc)).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        slotStructure[currentLocal.split(" ")[0].trim()] = [];
        dateArr.push(currentLocal.split(" ")[0].trim());
      } else {
        const timearr = item.interval.split("/");
        const ans = timearr[0].split(/[TZ]/);
        const final = `${ans[0]} ${ans[1]}`;
        const date = moment(utcToLocalTime(final), "YYYY-MM-DD HH:mm:ss")
          .format("YYYY-MM-DD, HH:mm:ss")
          .split(",")[0];
        slotStructure[date] = [];
        dateArr.push(date);
      }
    });
  });
  const dateSet = new Set([...dateArr]);

  // displaying purpose
  Array.from(dateSet).forEach((date) => {
    let ob = {};
    if (moment().format("YYYY-MM-DD") === date) {
      ob.text = "Today";
      ob.value = date;
    } else if (moment().add(1, "days").format("YYYY-MM-DD") === date) {
      ob.text = "Tomorrow";
      ob.value = date;
    } else {
      let date1 = moment(date);
      ob.text = `${getOrdinal(date1.format("DD"))} ${date1.format("MMM")}`;
      ob.value = date;
    }
    slotDateOptions.push(ob);
  });

  slotsArr.forEach((item) => {
    if (item.type === "ASAP") {
      const currentUtc = moment().utc().format("YYYY-MM-DD HH:mm:ss");
      let currentLocal = moment(utcToLocalTime(currentUtc)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      slotStructure[currentLocal.split(" ")[0].trim()].push({
        text: item.text,
        value: item.id,
      });
    } else {
      const timearr = item.interval.split("/");
      const ans = timearr[0].split(/[TZ]/);
      const final = `${ans[0]} ${ans[1]}`;
      slotStructure[
        moment(utcToLocalTime(final), "YYYY-MM-DD HH:mm:ss")
          .format("YYYY-MM-DD, HH:mm:ss")
          .split(",")[0]
      ].push({
        text: `${formatIsoTime(timearr[0])} - ${formatIsoTime(timearr[1])}`,
        value: item.id,
        disabled: !item.available,
        titleText: !item.available ? "slot not available" : null,
      });
    }
  });

  if (!className) {
    return { slotDateOptions, slotStructure };
  } else if (className === "date-list") {
    return slotDateOptions;
  } else {
    return slotStructure;
  }
}

const isEntityMetaDataEnabled = isExtensionEnabled("EntityMetaData");

class OrderAddForm extends BaseForm {
  constructor(props) {
    super(props);
    const { customerDetails, phoneNumber } = props;
    this.addressNotServed = {};
    this.state.submitting = false;
    this.state.values.type = props.pickuplocations ? "PICKUP" : "DELIVERY";
    this.state.values.paymentMode = "COD";
    this.state.values.phone = phoneNumber || null;
    this.state.values.email =
      customerDetails && customerDetails.emails.length > 0
        ? customerDetails.emails[0].email
        : null;
    this.state.values.name = customerDetails ? customerDetails.name : null;
    this.state.values.selectedAddressId =
      customerDetails && customerDetails.addresses
        ? customerDetails.addresses[0].id
        : null;
    this.state.values.pickupLocationId = props.pickuplocations
      ? props.pickuplocations[0].value
      : null;
    this.state.values.products = null;
    if (isEntityMetaDataEnabled) {
      this.state.values.metaData = {};
    }
    this.state.addressSlotMap = {
      PICKUP: {},
      DELIVERY: {},
    };
    this.store = 0; // TODO: Get store index from App
    this.state.totalAmount = 0;
    this.state.totalDiscount = 0;
    this.state.cashTendered = 0;
    this.state.formError = "";
    this.state.showErrorDialog = false;
    this.state.showAddressModal = false;
    [
      "addProductToList",
      "updateQuantity",
      "removeProduct",
      "goBack",
      "closeErrorDialog",
      "showAddressModal",
      "closeAddressModal",
      "addNewAddress",
      "renderSearchableListElement",
      "updateCashTendered",
      "updateMetaData",
      "initializeMetaData",
      "getSlotDetails",
      "changeDate",
      "getAddressParams",
      "getPickupSlotDetails",
      "getPickupParams",
      "onTypeChange",
      "promisifySetState",
      "pickupLocationApi",
      "deliveryApi",
    ].forEach((fn) => {
      this[fn] = this[fn].bind(this);
    });
  }

  updateCashTendered(e) {
    this.setState({
      cashTendered: e.target.value,
    });
  }

  generateProductsFromProductVariants(product) {
    let products = product.variants.map((variant) => {
      let pdt = Object.assign({}, product);
      pdt.name = variant.fullName;
      pdt.brand = product.brand;
      pdt.item = variant.item;
      pdt.storeSpecificData = variant.storeSpecificData;
      pdt.images = variant.images;
      pdt.id = variant.item.id;
      pdt.status = variant.status;
      return pdt;
    });
    return products;
  }

  renderSearchableListElement(item, valueKey, nameKey, onSelect) {
    const { amount, mrp, discount } = item.storeSpecificData
      ? formatOrderDetails(item.storeSpecificData)
      : { amount: 0, mrp: 0, discount: 0 };
    if (!mrp) {
      return null;
    }
    const currency = getSession().organization.currency.symbol;
    return (
      <li
        className="select-option"
        key={item[valueKey]}
        onClick={(e) => onSelect(item)}
      >
        <Image
          bordered
          size="sm"
          src={
            item.product
              ? item.product.images && item.product.images.length
                ? item.product.images[0]
                : null
              : item.images && item.images.length
              ? item.images[0]
              : null
          }
        />
        <div className="searchable-product-details">
          <div className="product-name">
            {item.brand && <span className="bold">{item.brand.name} </span>}
            {item[nameKey]}
          </div>
          <div className="price-holder">
            <span className="price">
              {currency} {amount}{" "}
            </span>
            {discount > 0 && <s className="mrp text-muted">{mrp}</s>}
          </div>
        </div>
      </li>
    );
  }

  promisifySetState(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  addProductToList(newProduct) {
    if (!newProduct) {
      return;
    }
    const { amount, discount } = formatOrderDetails(
      newProduct.storeSpecificData
    );
    const values = { ...this.state.values };
    let { products } = { ...values };
    if (!products || !products[newProduct["id"]]) {
      if (!products) {
        products = {};
      }
      products[newProduct["id"]] = newProduct;
      products[newProduct["id"]]["quantity"] = 1;
    } else {
      products[newProduct["id"]]["quantity"] =
        products[newProduct["id"]]["quantity"] + 1;
    }
    values["products"] = products;
    this.setState((prevState) => {
      return {
        values,
        totalAmount: prevState.totalAmount + amount,
        totalDiscount: prevState.totalDiscount + discount,
      };
    });
  }

  removeProduct(productId) {
    const values = { ...this.state.values };
    let { products } = { ...values };
    if (products && products[productId]) {
      const { amount, discount } = formatOrderDetails(
        products[productId].storeSpecificData
      );
      const productQuantity = products[productId]["quantity"];
      delete products[productId];
      const productsLength = Object.keys(products).length;
      if (productsLength === 0) {
        products = null;
      }
      values["products"] = products;
      this.setState((prevState) => {
        return {
          values,
          totalAmount:
            productsLength > 0
              ? prevState.totalAmount - amount * productQuantity
              : 0,
          totalDiscount:
            productsLength > 0
              ? prevState.totalDiscount - discount * productQuantity
              : 0,
        };
      });
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  // quantity is -1 for decrease in quantity and +1 is for increase in quantity
  updateQuantity(productId, quantity) {
    const values = { ...this.state.values };
    let { products } = { ...values };
    if (products && products[productId]) {
      const { amount, discount } = formatOrderDetails(
        products[productId].storeSpecificData
      );
      products[productId]["quantity"] =
        products[productId]["quantity"] + quantity;
      if (products[productId]["quantity"] <= 0) {
        delete products[productId];
      }
      const productsLength = Object.keys(products).length;
      if (productsLength === 0) {
        products = null;
      }
      values["products"] = products;
      this.setState((prevState) => {
        return {
          values,
          totalAmount:
            productsLength > 0 ? prevState.totalAmount + quantity * amount : 0,
          totalDiscount:
            productsLength > 0
              ? prevState.totalDiscount + quantity * discount
              : 0,
        };
      });
    }
  }

  updateMetaData(key, value) {
    const values = Object.assign({}, this.state.values);
    let { metaData } = { ...values };
    if (metaData) {
      metaData[key] = value;
    }
    values["metaData"] = metaData;
    this.setState({
      values,
    });
  }

  initializeMetaData(metaDataToInitialize) {
    const values = { ...this.state.values };
    values.metaData = metaDataToInitialize;
    this.setState({
      values,
    });
  }

  closeErrorDialog() {
    this.setState({
      showErrorDialog: false,
      formError: "",
    });
  }

  showAddressModal() {
    this.setState({
      showAddressModal: true,
    });
  }

  closeAddressModal() {
    this.setState({
      showAddressModal: false,
    });
  }

  orderCreate(formData, params, cart) {
    params["cart"] = JSON.stringify(cart);
    if (formData.metaData && Object.keys(formData.metaData).length > 0) {
      params["metaData"] = formData.metaData;
    } else {
      delete formData.metaData;
    }
    const storeDetails = this.getCurrStoreDetails();
    if (formData["type"] === "DELIVERY") {
      params["storeId"] = this.props.storeId;
      if ([0, false].includes(storeDetails?.hasPicking)) {
        params["pickingStoreId"] = storeDetails?.pickingStoreId;
      }
      delete params["pickupLocationId"];
    } else {
      params["pickupLocationId"] =
        (this.state.values && this.state.values["pickupLocationId"]) ||
        this.props.storeId;
    }
    const api = new API({ url: "/order-service/order" });
    api
      .post(params)
      .then(
        (response) => {
          if (response.data && response.data.order) {
            const order = response.data.order;
            if (this.state.cashTendered > 0) {
              const paymentApi = new API({
                url: "/order-service/order-payment",
              });
              let paymentParams = {};
              paymentParams["amount"] = Math.min(
                this.state.cashTendered,
                this.state.totalAmount
              );
              paymentParams["orderReferenceNumber"] = order.referenceNumber;
              paymentParams["mode"] = "COD";
              paymentParams["status"] = "COMPLETED";
              paymentApi.post(paymentParams).then(
                (response) => {
                  this.props.history.push(
                    `/operations/orders/${order.referenceNumber}`
                  );
                },
                (error) => {
                  this.setState({
                    formError: error.message,
                    showErrorDialog: true,
                    submitting: false,
                  });
                }
              );
            } else {
              this.props.history.push(
                `/operations/orders/${order.referenceNumber}`
              );
            }
          }
        },
        (error) => {
          this.setState({
            formError: error.message,
            showErrorDialog: true,
            submitting: false,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(formData) {
    const customerDetails = this.props.customerDetails;
    let cart = [];
    let params = {};
    let { products } = formData;
    if (!products) {
      return false;
    }
    this.setState({
      submitting: true,
    });
    for (let key in products) {
      cart.push({ id: products[key].id, q: products[key].quantity });
    }
    if (formData.preferredDate && isExtensionEnabled("DeliverySlots")) {
      params["preferredDate"] = this.state.preferredDate;
      params["preferredSlotId"] = Number(formData.preferredSlotId);
    }
    params["type"] = formData.type;
    params["paymentMode"] = formData.paymentMode;

    if (customerDetails !== null) {
      // Existing customer. Send only customer ID
      params["customerId"] = customerDetails.id;
    } else {
      // New customer. Address and customer details need to be sent
      params["name"] = formData.name;
      params["phone"] = formData.phone;
      params["email"] = formData.email;
      params["address"] = formData.address;
      params["city"] = formData.address && formData.address.city;
      params["landmark"] = formData.address && formData.address.landmark;
      params["pincode"] = formData.address && formData.address.pincode;
    }
    if (formData.selectedAddressId && formData.type === "DELIVERY") {
      params["addressId"] = formData.selectedAddressId;
    }
    this.orderCreate(formData, params, cart);
  }

  addNewAddress(text, response) {
    this.closeAddressModal();
    let values = { ...this.state.values };
    if (response.addresses) {
      values.customerDetails = response;
      this.setState({
        values,
      });
      this.props.addAddressInCustomerDetails(response.addresses[0], response);
    } else {
      values.selectedAddressId = response.id;
      this.setState({
        values,
      });
      this.props.addAddressInCustomerDetails(response);
    }
  }

  async deliveryApi() {
    let values = JSON.parse(JSON.stringify(this.state.values));
    values["name"] = this.props.customerDetails
      ? this.props.customerDetails.name
      : null;
    values["email"] =
      this.props.customerDetails &&
      this.props.customerDetails.emails?.length > 0
        ? this.props.customerDetails.emails?.[0].email
        : null;
    values["phone"] = this.props.phoneNumber || null;

    await this.promisifySetState({ values: values });

    if (
      !values.selectedAddressId &&
      this.props.customerDetails &&
      this.props.customerDetails.addresses &&
      this.props.customerDetails.addresses.length > 0
    ) {
      values["selectedAddressId"] = this.props.customerDetails.addresses[0].id;
    }

    const customerAddress =
      this.props.customerDetails &&
      this.props.customerDetails.addresses &&
      this.props.customerDetails.addresses.length > 0
        ? {
            address: this.props.customerDetails.addresses[0].address,
            landmark: this.props.customerDetails.addresses[0].landmark,
            city: this.props.customerDetails.addresses[0].city,
            pincode: this.props.customerDetails.addresses[0].pincode,
          }
        : { address: null, landmark: null, city: null, pincode: null };
    if (
      this.props.customerDetails &&
      this.props.customerDetails.addresses &&
      this.props.customerDetails.addresses.length > 0 &&
      isExtensionEnabled("DeliverySlots")
    ) {
      const api = new API({ url: "/order-service/slot-availability" });
      const addressId =
        this.state.values.selectedAddressId ||
        this.props.customerDetails.addresses[0].id;
      let params = this.getAddressParams(
        addressId,
        this.props.customerDetails,
        this.props.storeId
      );
      await api
        .get(params)
        .then((response) => {
          let transformedResponse = sortSlotsAvailability(response.data.slots);
          let slots = {};
          transformedResponse &&
            transformedResponse.map((slot) => {
              if (!slots.hasOwnProperty(slot.date)) {
                slots[slot.date] = [];
              }
              slots[slot.date].push(slot);
              return null;
            });
          values["addressSlotMap"]["DELIVERY"][addressId] = slots;
          values["selectedAddressId"] = addressId;
          values["preferredDate"] =
            Object.keys(slots).length > 0 ? Object.keys(slots)[0] : null;
          this.addressNotServed[addressId] = false;
          if (
            !transformedResponse ||
            Object.keys(transformedResponse).length === 0
          ) {
            this.addressNotServed[addressId] = true;
          }
          values = {
            ...values,
            ...customerAddress,
          };
          this.setState({
            values,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  async pickupLocationApi() {
    if (this.props.pickuplocations && this.props.pickuplocations.length > 0) {
      this.slotApi = new API({ url: "/order-service/slot-availability" });
      let params = this.getPickupParams(this.props.pickuplocationDetails[0].id);
      await this.slotApi
        .get(params)
        .then((response) => {
          let transformedResponse = sortSlotsAvailability(response.data.slots);
          let slots = {};
          transformedResponse &&
            transformedResponse.map((slot) => {
              if (!slots.hasOwnProperty(slot.date)) {
                slots[slot.date] = [];
              }
              slots[slot.date].push(slot);
              return null;
            });

          let values = JSON.parse(JSON.stringify(this.state.values));
          values["addressSlotMap"]["PICKUP"][
            this.props.pickuplocations[0].value
          ] = slots;
          this.setState({
            values,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.phoneNumber !== this.props.phoneNumber) {
      let values = JSON.parse(JSON.stringify(this.state.values));
      values["products"] = null;
      values["type"] =
        this.props.pickuplocations && this.state.pickupSupported
          ? "PICKUP"
          : "DELIVERY";
      values["paymentMode"] = "COD";
      values["pickupLocationId"] =
        this.props.pickuplocations && this.props.pickuplocations.length > 0
          ? this.props.pickuplocations[0].value
          : null;
      values["phone"] = this.props.phoneNumber || null;
      values["name"] = this.props.customerDetails
        ? this.props.customerDetails.name
        : null;
      if (this.props.showNewCustomerForm) {
        delete values.name;
        delete values.email;
        delete values.address;
        delete values.landmark;
        delete values.pincode;
        delete values.city;
      }
      await this.promisifySetState({ values: values });
    }

    if (prevProps.storeId !== this.props.storeId) {
      let values = JSON.parse(JSON.stringify(this.state.values));
      if (isExtensionEnabled("DeliverySlots")) {
        values["addressSlotMap"]["DELIVERY"] = {};
        values["preferredSlotId"] = null;
      }
      if (values.type === "DELIVERY") {
        values.products = null;
      }
      await this.promisifySetState({ values: values });

      if (isExtensionEnabled("DeliverySlots") && this.props.customerDetails) {
        this.deliveryApi();
      }
    }

    if (
      isExtensionEnabled("DeliverySlots") &&
      this.props.customerDetails &&
      this.props.customerDetails !== prevProps.customerDetails
    ) {
      this.deliveryApi();
    }

    if (this.props.pickuplocations !== prevProps.pickuplocations) {
      let values = JSON.parse(JSON.stringify(this.state.values));
      values["pickupLocationId"] = this.props.pickuplocations
        ? this.props.pickuplocations.length > 0 &&
          this.props.pickuplocations[0].value
        : null;

      await this.promisifySetState({ values: values });

      if (
        isExtensionEnabled("DeliverySlots") &&
        this.state.visibleDaysForSlots
      ) {
        this.pickupLocationApi();
      }
    }

    if (
      this.state.visibleDaysForSlots !== prevState.visibleDaysForSlots &&
      isExtensionEnabled("DeliverySlots")
    ) {
      this.pickupLocationApi();
    }

    if (prevProps.showNewCustomerForm !== this.props.showNewCustomerForm) {
      const validations = JSON.parse(JSON.stringify(this.state.validations));
      if (!this.props.showNewCustomerForm) {
        delete validations["name"];
        this.setState({
          validations,
        });
      }
    }
    if (this.state.values.type === "PICKUP") {
      const validations = { ...this.state.validations };
      if (validations && validations["address"]) {
        delete validations["address"];
        delete validations["landmark"];
        delete validations["pincode"];
        delete validations["city"];
        this.setState({
          validations,
        });
      }
    } else {
      let prevValues = Object.assign({}, prevState.values);
      let values = Object.assign({}, this.state.values);
      if (
        prevValues.selectedAddressId !== values.selectedAddressId &&
        values.addressSlotMap &&
        values.addressSlotMap.DELIVERY[values.selectedAddressId] &&
        values.addressSlotMap.DELIVERY[values.selectedAddressId].length < 1
      ) {
        this.addressNotServed[values.selectedAddressId] = true;
      }
      if (
        prevValues.selectedAddressId !== values.selectedAddressId &&
        values.addressSlotMap &&
        values.addressSlotMap.DELIVERY[values.selectedAddressId] &&
        (values.addressSlotMap.DELIVERY[values.selectedAddressId].length > 0 ||
          Object.keys(values.addressSlotMap.DELIVERY[values.selectedAddressId])
            .length > 0)
      ) {
        this.addressNotServed[values.selectedAddressId] = false;
      }
    }
  }

  async componentDidMount() {
    this.configApi = new API({ url: "/account-service/config/order" });
    await this.configApi
      .get()
      .then((response) => {
        const orderTypes = response.data.config.order.orderTypes;
        if (orderTypes && orderTypes.includes("PICKUP")) {
          this.setState({
            pickupSupported: true,
          });
        }
        if (orderTypes && orderTypes.includes("DELIVERY")) {
          this.setState({
            deliverySupported: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const deliverySlotsEnabled = isExtensionEnabled("DeliverySlots");
    if (deliverySlotsEnabled) {
      if (this.state.validations) {
        let validations = JSON.parse(JSON.stringify(this.state.validations));
        validations.preferredSlotId = {};
        validations.preferredSlotId.valid = false;
        this.setState({ validations });
      }
      let extId = getExtensionDetails("DeliverySlots").id;
      const api = new API({ url: `/account-service/extension/${extId}` });
      await api
        .get()
        .then((response) => {
          let transformedResponse = response.data.extension.config.globalConfig;
          let values = Object.assign({}, this.state.values);
          values.addressSlotMap = {
            DELIVERY: {},
            PICKUP: {},
          };
          this.setState({
            visibleDaysForSlots: transformedResponse.visibleDaysForSlot || 0,
            values,
          });
        })
        .catch((err) => console.error(err));

      if (!this.props.customerDetails) {
        const { storeId } = this.props;
        const { visibleDaysForSlots } = this.state;
        let slotApi = new API({ url: `/order-service/slot-availability` });
        let params = {};
        if (storeId) {
          params["storeId"] = storeId;
          params["searchInterval"] =
            moment().toISOString() +
            "/" +
            moment().add(visibleDaysForSlots, "days").toISOString();
        }
        await slotApi
          .get(params)
          .then((response) => {
            let transformedResponse = sortSlotsAvailability(
              response.data.slots
            );
            let values = JSON.parse(JSON.stringify(this.state.values));
            let slots = {};
            transformedResponse &&
              transformedResponse.map((slot) => {
                if (!slots.hasOwnProperty(slot.date)) {
                  slots[slot.date] = [];
                }
                slots[slot.date].push(slot);
                return null;
              });

            values.preferredDate =
              Object.keys(slots).length > 0 ? Object.keys(slots)[0] : null;
            values.preferredSlotId = null;
            let options = {};
            if (slots) {
              options = generateOptions(slots);
            }
            this.setState({
              slots,
              values,
              options,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    if (
      isExtensionEnabled("EntityMetaData") &&
      isExtensionEnabled("DeliveryAreaSupport")
    ) {
      this.addressSequenceApi = new API({
        url: "/config-service/config/customers",
      });
      this.addressSequenceApi
        .get()
        .then((response) => {
          this.setState({
            addressSequence: response.data.customers.addressSequence,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  getAddressParams(addressId, customerDetails) {
    let params = {};
    let { visibleDaysForSlots } = this.state;
    let { storeId } = this.props;
    const today = moment().toISOString();
    const endDay = moment().add(visibleDaysForSlots, "days").toISOString();
    params["startDate"] = today;
    params["endDate"] = endDay;
    params["orderType"] = "DELIVERY";
    params["searchInterval"] = params["startDate"] + "/" + params["endDate"];
    if (storeId) {
      params["storeId"] = storeId;
    }
    params["addressId"] = addressId;
    let customer = customerDetails || this.props.customerDetails;
    let selectedAddress = customer.addresses.filter(
      (address) => address.id === Number(addressId)
    )[0];
    params["address[address]"] = selectedAddress["address"];
    params["address[city]"] = selectedAddress["city"];
    params["address[landmark]"] = selectedAddress["landmark"];
    params["address[pincode]"] = selectedAddress["pincode"];
    params["address[latitude]"] = selectedAddress["latitude"];
    params["address[longitude]"] = selectedAddress["longitude"];
    delete params["startDate"];
    delete params["endDate"];
    return params;
  }

  getPickupParams(pickupLocationId) {
    let params = {};
    let { visibleDaysForSlots } = this.state;
    const today = moment().toISOString();
    const endDay = moment().add(visibleDaysForSlots, "days").toISOString();
    params["startDate"] = today;
    params["endDate"] = endDay;
    params["storeId"] = pickupLocationId || this.props.storeId;
    params["searchInterval"] = params["startDate"] + "/" + params["endDate"];
    delete params["startDate"];
    delete params["endDate"];
    params["orderType"] = "PICKUP";
    return params;
  }

  getSlotDetails(addressId) {
    const deliverySlotsEnabled = isExtensionEnabled("DeliverySlots");
    if (deliverySlotsEnabled) {
      if (
        this.state.values.addressSlotMap &&
        !this.state.values.addressSlotMap.DELIVERY[addressId]
      ) {
        const api = new API({ url: "/order-service/slot-availability" });
        let params = this.getAddressParams(addressId);
        api
          .get(params)
          .then(
            (response) => {
              let transformedResponse = sortSlotsAvailability(
                response.data.slots
              );
              let values = JSON.parse(JSON.stringify(this.state.values));
              let slots = {};
              transformedResponse &&
                transformedResponse.map((slot) => {
                  if (!slots.hasOwnProperty(slot.date)) {
                    slots[slot.date] = [];
                  }
                  slots[slot.date].push(slot);
                  return null;
                });
              values.addressSlotMap.DELIVERY[addressId] = slots;
              this.addressNotServed[addressId] = false;
              if (
                !transformedResponse ||
                Object.keys(transformedResponse).length === 0
              ) {
                this.addressNotServed[addressId] = true;
              }

              this.setState({
                values,
              });
            },
            (error) => {
              this.setState({
                formError: error,
              });
            }
          )
          .catch((error) => {
            console.error(error);
          });
      }
    }
    let values = Object.assign({}, this.state.values);
    values.selectedAddressId = addressId;
    values.preferredSlotId = null;
    this.setState({
      values,
    });
  }

  async getPickupSlotDetails(pickupLocationId) {
    const values = Object.assign({}, this.state.values);
    values.pickupLocationId = pickupLocationId;
    values.preferredSlotId = null;
    values.products = null;

    await this.promisifySetState({ values: values });

    let deliverySlotsEnabled = isExtensionEnabled("DeliverySlots");
    if (deliverySlotsEnabled) {
      if (
        this.state.values.addressSlotMap &&
        !this.state.values.addressSlotMap.PICKUP[pickupLocationId]
      ) {
        const api = new API({ url: "/order-service/slot-availability" });
        let params = this.getPickupParams(pickupLocationId);
        api
          .get(params)
          .then(
            (response) => {
              let transformedResponse = sortSlotsAvailability(
                response.data.slots
              );
              let values = JSON.parse(JSON.stringify(this.state.values));
              let slots = {};
              transformedResponse &&
                transformedResponse.map((slot) => {
                  if (!slots.hasOwnProperty(slot.date)) {
                    slots[slot.date] = [];
                  }
                  slots[slot.date].push(slot);
                  return null;
                });
              values.addressSlotMap.PICKUP[pickupLocationId] = slots;
              let noPickupSlots = false;
              if (Object.keys(slots).length === 0) {
                noPickupSlots = true;
              }
              this.setState({
                values,
                noPickupSlots,
              });
            },
            (error) => {
              this.setState({
                formError: error,
              });
            }
          )
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  changeDate(date) {
    let values = JSON.parse(JSON.stringify(this.state.values));
    let validations = JSON.parse(JSON.stringify(this.state.validations));
    values.preferredDate = date;
    values.preferredSlotId = null;
    if (validations.preferredSlotId) validations.preferredSlotId.valid = false;
    this.setState({
      values,
      validations,
      preferredSlotIdError: false,
    });
  }

  selectSlot(slotId, slotVal, preferredDate) {
    slotVal.forEach((val) => {
      if (`${val.value}` === slotId) {
        const timeArr = val.text.split(" ");
        if (timeArr.includes("Within")) {
          let currentLocal = moment(
            localTimeToUtc(moment().local().format("YYYY-MM-DD HH:mm:ss"))
          ).format("YYYY-MM-DD HH:mm:ss");
          this.setState({
            preferredDate: currentLocal.split(" ")[0].trim(),
          });
        } else {
          this.setState({
            preferredDate: moment(
              localTimeToUtc(
                moment(
                  `${preferredDate} ${convertToMilitaryTime(
                    timeArr[0],
                    timeArr[1]
                  )}`
                ).format("YYYY-MM-DD HH:mm:ss")
              )
            )
              .format("YYYY-MM-DD, HH:mm:ss")
              .split(",")[0],
          });
        }
      }
    });
    let values = JSON.parse(JSON.stringify(this.state.values));
    let validations = JSON.parse(JSON.stringify(this.state.validations));
    if (validations.preferredSlotId) validations.preferredSlotId.valid = true;
    values.preferredSlotId = slotId;
    this.setState({
      values,
      validations,
      preferredSlotIdError: false,
    });
  }

  beforeSubmit() {
    let values = JSON.parse(JSON.stringify(this.state.values));
    if (values.preferredSlotId === null) {
      this.setState({ preferredSlotIdError: true });
    }
  }

  onTypeChange(value) {
    this.updateState(["type"], value);
    this.setState((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.preferredSlotIdError = true;
      if (newState.validations.preferredSlotId)
        newState.validations.preferredSlotId.valid = false;
      newState.values.preferredSlotId = null;
      newState.values.products = null;
      return newState;
    });
  }
  getCurrStoreDetails = () => {
    const { storeId, pickuplocationDetails } = this.props;
    const currStore = pickuplocationDetails.filter(
      ({ id }) => id?.toString() === storeId?.toString()
    );
    return currStore?.[0];
  };
  getInventoryStoreId = (deliveryType) => {
    const storeDetails = this.getCurrStoreDetails();
    if (deliveryType === "DELIVERY") {
      if ([0, false].includes(storeDetails?.hasPicking))
        return storeDetails?.pickingStoreId;
      return this.props.storeId;
    }
    return this.state.values.pickupLocationId;
  };
  render() {
    const {
      values,
      totalAmount,
      totalDiscount,
      formError,
      showErrorDialog,
      showAddressModal,
      cashTendered,
      slots,
      pickupSupported,
      deliverySupported,
    } = this.state;
    const { pickuplocations, customerDetails, showNewCustomerForm } =
      this.props;
    const {
      products,
      name,
      phone,
      type,
      metaData,
      addressSlotMap,
      selectedAddressId,
      preferredDate,
      preferredSlotId,
      pickupLocationId,
    } = values;
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const currency = getSession().organization.currency;
    const data = {
      amount: totalAmount,
      discount: totalDiscount,
      shipping: 0,
    };
    let orderTypeOptions = [];
    if (pickuplocations && pickupSupported) {
      orderTypeOptions.push({
        text: getMessage("order.form.orderType.pickup"),
        value: "PICKUP",
      });
    }
    if (deliverySupported) {
      orderTypeOptions.push({
        text: getMessage("order.form.paymentMode.delivery"),
        value: "DELIVERY",
      });
    }
    let slotError = null;
    if (
      this.state.preferredSlotIdError &&
      this.state.pressedSubmitWithCurrentData &&
      this.state.values &&
      this.state.values.preferredSlotId === null
    ) {
      slotError = (
        <span className="preffered-slot-error">
          {getMessage("order.form.slots.error.message")}
        </span>
      );
    }

    return (
      <div>
        <Dialog
          show={showErrorDialog}
          information={formError}
          close={this.closeErrorDialog}
          closeText={getMessage("order.form.save.error.closeText")}
        />
        {showAddressModal && customerDetails && (
          <Popup
            heading={getMessage("customer.details.address.addAddress")}
            show={showAddressModal}
            close={this.closeAddressModal}
          >
            <AddAddressForm
              customerId={customerDetails && customerDetails.id}
              customerDetails={this.state.values}
              onSuccess={this.addNewAddress}
              sequence={this.state.addressSequence}
            />
          </Popup>
        )}
        <Form className="order-add-form">
          {showNewCustomerForm && (
            <div>
              <div className="customer-details-wrapper">
                <div className="flex">
                  <Input
                    label={getMessage("order.form.customer.name.heading")}
                    placeholder={getMessage(
                      "order.form.customer.name.placeholder"
                    )}
                    className="customer-name"
                    name="name"
                    type="text"
                    required
                    {...this.generateStateMappers({
                      stateKeys: ["name"],
                      loseEmphasisOnFill: true,
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                  />
                  <Input
                    label={getMessage("order.form.customer.email.heading")}
                    placeholder={getMessage(
                      "order.form.customer.email.placeholder"
                    )}
                    className="customer-email"
                    name="email"
                    type="email"
                    {...this.generateStateMappers({
                      stateKeys: ["email"],
                      loseEmphasisOnFill: true,
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                  />
                </div>
              </div>
            </div>
          )}
          {name && phone && (
            <div>
              <div
                className={
                  type === "DELIVERY" ? "flex order-type-delivery" : ""
                }
              >
                <Radio
                  className="order-type"
                  label={getMessage("order.form.orderType.heading")}
                  name="type"
                  options={orderTypeOptions}
                  {...this.generateStateMappers({
                    stateKeys: ["type"],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                  onChange={this.onTypeChange}
                  value={this.getState(["type"])}
                />
                {type === "DELIVERY" &&
                  (!customerDetails ? (
                    <React.Fragment>
                      <div className="address-details-wrapper">
                        <AddAddressForm _this={this} />
                      </div>
                      {isExtensionEnabled("DeliverySlots") &&
                        Object.keys(slots).length > 0 && (
                          <div className="date-details">
                            <Radio
                              className="date-list"
                              label={getMessage("order.form.date.heading")}
                              name="preferredDate"
                              options={generateOptions(slots, "date-list")}
                              value={preferredDate}
                              onChange={(e) => this.changeDate(e)}
                            />
                            <Radio
                              label={getMessage("order.form.slot.heading")}
                              className="slot-list"
                              name="preferredSlotId-delivery"
                              options={
                                generateOptions(slots, "slot-list")[
                                  preferredDate
                                ]
                              }
                              value={preferredSlotId}
                              onChange={(e) =>
                                this.selectSlot(
                                  e,
                                  generateOptions(slots, "slot-list")[
                                    preferredDate
                                  ],
                                  preferredDate
                                )
                              }
                              required
                            />
                            {slotError}
                          </div>
                        )}
                    </React.Fragment>
                  ) : customerDetails &&
                    customerDetails.addresses &&
                    customerDetails.addresses.length > 0 ? (
                    <div>
                      <Radio
                        className="address-card-list"
                        label={getMessage("order.form.deliveryArea.heading")}
                        name="selectedAddressId"
                        options={customerDetails.addresses.map((address) => {
                          return {
                            text: `${getDisplayAddress(
                              address,
                              this.state.addressSequence
                            )}`,
                            value: address.id,
                          };
                        })}
                        value={this.state.values.selectedAddressId}
                        onChange={(e) => this.getSlotDetails(e)}
                        secondaryLabel={() => (
                          <div
                            className="new-address-button"
                            onClick={this.showAddressModal}
                          >
                            <img src={AddIcon} width="11" height="11" alt="" />
                            {getMessage("order.form.addAddress.heading")}
                          </div>
                        )}
                      />
                      {addressSlotMap &&
                        addressSlotMap.DELIVERY[selectedAddressId] &&
                        Object.keys(addressSlotMap.DELIVERY[selectedAddressId])
                          .length > 0 && (
                          <React.Fragment>
                            <Radio
                              className="date-list"
                              label={getMessage("order.form.date.heading")}
                              name="preferredDate"
                              options={generateOptions(
                                addressSlotMap.DELIVERY[selectedAddressId],
                                "date-list"
                              )}
                              value={preferredDate}
                              onChange={(e) => this.changeDate(e)}
                            />
                            {preferredDate &&
                              Array.isArray(
                                addressSlotMap.DELIVERY[selectedAddressId][
                                  preferredDate
                                ]
                              ) && (
                                <Radio
                                  label={getMessage("order.form.slot.heading")}
                                  className="slot-list"
                                  name="preferredSlotId"
                                  options={
                                    generateOptions(
                                      addressSlotMap.DELIVERY[
                                        selectedAddressId
                                      ],
                                      "slot-list"
                                    )[preferredDate] || []
                                  }
                                  value={preferredSlotId}
                                  onChange={(e) =>
                                    this.selectSlot(
                                      e,
                                      generateOptions(
                                        addressSlotMap.DELIVERY[
                                          selectedAddressId
                                        ],
                                        "slot-list"
                                      )[preferredDate],
                                      preferredDate
                                    )
                                  }
                                  required
                                />
                              )}
                            {slotError}
                          </React.Fragment>
                        )}
                    </div>
                  ) : (
                    <div className="address-details-wrapper no-address">
                      <div
                        className="new-address-button no-address"
                        onClick={this.showAddressModal}
                      >
                        <img src={AddIcon} width="11" height="11" alt="" />
                        {getMessage("order.form.addAddress.heading")}
                      </div>
                    </div>
                  ))}
              </div>
              {type === "PICKUP" &&
                pickupSupported &&
                pickuplocations &&
                pickuplocations.length > 0 && (
                  <React.Fragment>
                    <Radio
                      className="order-pickup-location"
                      label={getMessage("order.form.pickupLocation.heading")}
                      name="pickupLocations"
                      options={pickuplocations}
                      value={this.state.values.pickupLocationId}
                      onChange={(e) => {
                        this.getPickupSlotDetails(e);
                      }}
                      // {...this.generateStateMappers({
                      //   stateKeys: ['pickupLocationId'],
                      //   loseEmphasisOnFill: true
                      // })}
                    />
                    {addressSlotMap &&
                      addressSlotMap.PICKUP[pickupLocationId] &&
                      Object.keys(addressSlotMap.PICKUP[pickupLocationId])
                        .length > 0 && (
                        <React.Fragment>
                          <Radio
                            className="date-list"
                            label={getMessage("order.form.date.heading")}
                            name="preferredDate"
                            options={generateOptions(
                              addressSlotMap.PICKUP[pickupLocationId],
                              "date-list"
                            )}
                            value={preferredDate}
                            onChange={(e) => this.changeDate(e)}
                          />
                          {preferredDate &&
                            Array.isArray(
                              addressSlotMap.PICKUP[pickupLocationId][
                                preferredDate
                              ]
                            ) && (
                              <Radio
                                label={getMessage("order.form.slot.heading")}
                                className="slot-list"
                                name="preferredSlotId"
                                options={
                                  generateOptions(
                                    addressSlotMap.PICKUP[pickupLocationId],
                                    "slot-list"
                                  )[preferredDate] || []
                                }
                                value={preferredSlotId}
                                onChange={(e) =>
                                  this.selectSlot(
                                    e,
                                    generateOptions(
                                      addressSlotMap.PICKUP[pickupLocationId],
                                      "slot-list"
                                    )[preferredDate],
                                    preferredDate
                                  )
                                }
                                required
                              />
                            )}
                          {slotError}
                        </React.Fragment>
                      )}
                  </React.Fragment>
                )}
              {isExtensionEnabled("DeliverySlots") &&
                this.state.values.type === "DELIVERY" &&
                this.addressNotServed[selectedAddressId] && (
                  <div className="form-error">
                    {getMessage("order.form.addressnotserved")}
                  </div>
                )}
              <div className="product-search">
                <ProductSearch
                  label="Product"
                  name="order-search"
                  includeInventory
                  storeId={this.getInventoryStoreId(type)}
                  onChange={this.addProductToList}
                />
              </div>
              <ProductsTable
                store={this.store}
                productsList={products}
                updateQuantity={this.updateQuantity}
                removeProduct={this.removeProduct}
                currency={currency}
              />
            </div>
          )}
          {products && (
            <div className="flex payment-options">
              <div className="details-wrapper">
                <Radio
                  className="payment-mode"
                  label={getMessage("order.form.paymentMode.heading")}
                  name="paymentMode"
                  options={[
                    {
                      text: getMessage("order.form.paymentMode.online"),
                      value: "ONLINE",
                    },
                    {
                      text: getMessage("order.form.paymentMode.cash"),
                      value: "COD",
                    },
                  ]}
                  {...this.generateStateMappers({
                    stateKeys: ["paymentMode"],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                />
              </div>
              <div className="invoice-button-wrapper">
                <InvoiceSummary data={data} currency={currency} />
                <div className="cash-tendered flex-around">
                  <div className="text-muted cash-tendered-text">{`${getMessage(
                    "order.form.cash.tendered"
                  )} (${currency.symbol})`}</div>
                  <input
                    className="cash-tendered-input"
                    type="number"
                    value={cashTendered}
                    onChange={this.updateCashTendered}
                  />
                </div>
                {cashTendered > totalAmount && (
                  <div className="balance-amount-holder flex-around">
                    <div className="text-muted">
                      {getMessage("order.form.balanceAmount")}
                    </div>
                    <div>
                      <span>{currency.symbol}</span>
                      <span className="balance-amount">
                        {(cashTendered - totalAmount).toFixed(2)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="actions-with-meta-data">
            {isEntityMetaDataEnabled && name && phone && (
              <div className="entity-meta-data">
                <div className="action-with-meta-data">
                  <MetaDataForm
                    metaData={metaData}
                    onChange={this.updateMetaData}
                    initializeMetaData={this.initializeMetaData}
                  />
                </div>
              </div>
            )}
            {products && (
              <div className="button-wrapper">
                <button
                  className="button button-white"
                  type="button"
                  onClick={this.goBack}
                >
                  {getMessage("order.form.cancel.text")}
                </button>
                <SubmitButton
                  disabled={
                    this.state.submitting ||
                    (this.state.values.type === "DELIVERY" &&
                      this.addressNotServed[selectedAddressId])
                  }
                >
                  {getMessage("order.form.submit.text")}
                </SubmitButton>
              </div>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(OrderAddForm);

export { formatOrderDetails };
