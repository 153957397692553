import React from "react";
import { BaseForm, Input, VALIDATION_TYPES } from "../../../components/Form";
import { getMessage } from "../../../lib/translator";
import API from "../../../lib/api";
import { withRouter } from "react-router-dom";
import { Dialog } from "../../../components/Popup";

function validatePasswords(newPassword, confirmPassword) {
  // Password Validations
  const hasSixCharacters = newPassword && newPassword.length > 5;
  const hasLowercaseLetter = /[a-z]/.test(newPassword);
  const hasUppercaseLetter = /[A-Z]/.test(newPassword);
  const hasNumber = /.*[0-9].*/.test(newPassword);
  const invalidConfirmPassword =
    confirmPassword && newPassword && confirmPassword !== newPassword;
  const isValid =
    hasSixCharacters &&
    hasUppercaseLetter &&
    hasLowercaseLetter &&
    hasNumber &&
    !invalidConfirmPassword;
  return {
    hasSixCharacters,
    hasLowercaseLetter,
    hasUppercaseLetter,
    hasNumber,
    invalidConfirmPassword,
    isValid,
  };
}

const isValidFields = (values = {}) => {
  let flag = true;
  Object.keys(values).forEach((key) => {
    if (!values[key] || values[key].length <= 5) {
      flag = false;
    }
  });
  return flag;
};

class ChangePasswordForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.values = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
    this.state.formError = "";
    this.state.showSuccessDialog = false;
    this.handleClose = this.handleClose.bind(this);
    this.closeDialogs = this.closeDialogs.bind(this);
  }

  handleSubmit(data) {
    const api = new API({ url: "/account-service/change-password" });
    return api
      .post(data)
      .then(
        (response) => {
          this.setState({
            formError: "",
            showSuccessDialog: true,
          });
        },
        (error) => {
          this.setState({ formError: error.message });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  closeDialogs() {
    this.setState({ showSuccessDialog: false });
  }

  handleClose() {
    this.props.history.goBack();
  }

  onSubmit(data) {
    const newPassword = this.state.values.newPassword;
    const confirmPassword = this.state.values.confirmPassword;
    const validationParams = validatePasswords(newPassword, confirmPassword);
    if (validationParams.isValid) {
      this.setState({
        submitting: true,
      });
      this.handleSubmit(data).then(() => {
        this.setState({ submitting: false });
      });
    }
  }

  render() {
    const { Form } = this.components;

    const newPassword = this.state.values.newPassword;
    const confirmPassword = this.state.values.confirmPassword;
    const validationParams = validatePasswords(newPassword, confirmPassword);
    const sixCharacterClassName = validationParams.hasSixCharacters
      ? " has-six-characters"
      : "";
    const uppercaseClassName = validationParams.hasUppercaseLetter
      ? " has-uppercase"
      : "";
    const lowercaseClassName = validationParams.hasLowercaseLetter
      ? " has-lowercase"
      : "";
    const numberClassName = validationParams.hasNumber ? " has-number" : "";
    const confirmPasswordError = validationParams.invalidConfirmPassword
      ? getMessage("changePassword.different.confirmPassword")
      : "";

    let disabledSubmitButton =
      validationParams.isValid && isValidFields(this.state.values);
    return (
      <div>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("changePassword.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("changePassword.save.success.closeText")}
        />
        <div className="form-container">
          <Form>
            <Input
              label={getMessage("changePassword.oldPassword.heading")}
              placeholder={getMessage("changePassword.oldPassword.placeholder")}
              name="oldPassword"
              type="password"
              required
              {...this.generateStateMappers({
                stateKeys: ["oldPassword"],
                validationType: VALIDATION_TYPES.ONCHANGE,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            />
            <Input
              label={getMessage("changePassword.newPassword.heading")}
              placeholder={getMessage("changePassword.newPassword.placeholder")}
              name="newPassword"
              type="password"
              required
              {...this.generateStateMappers({
                stateKeys: ["newPassword"],
                validationType: VALIDATION_TYPES.ONCHANGE,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            />
            <Input
              label={getMessage("changePassword.confirmPassword.heading")}
              placeholder={getMessage(
                "changePassword.confirmPassword.placeholder"
              )}
              name="confirmPassword"
              type="password"
              required
              error={confirmPasswordError}
              {...this.generateStateMappers({
                stateKeys: ["confirmPassword"],
                validationType: VALIDATION_TYPES.ONCHANGE,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            />
            <div className="form-buttons-container">
              <button
                type="submit"
                className={`primary button ${
                  disabledSubmitButton ? "" : "change-password-disabled"
                }`}
                disabled={this.state.submitting || !disabledSubmitButton}
              >
                {getMessage("changePassword.submit.text")}
              </button>
              <button
                className="button"
                type="button"
                onClick={this.handleClose}
                disabled={this.state.submitting}
              >
                {getMessage("changePassword.cancel.text")}
              </button>
            </div>
          </Form>
          <div className="password-validation-textbox">
            <div className="password-validation-heading">
              {getMessage("changePassword.list.heading")}
            </div>
            <div
              className={`password-validation-lists${sixCharacterClassName}`}
            >
              {" "}
              {getMessage("changePassword.list.characterLimit")}
            </div>
            <div className={`password-validation-lists${uppercaseClassName}`}>
              {getMessage("changePassword.list.uppercase")}
            </div>
            <div className={`password-validation-lists${lowercaseClassName}`}>
              {getMessage("changePassword.list.lowercase")}
            </div>
            <div className={`password-validation-lists${numberClassName}`}>
              {getMessage("changePassword.list.number")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ChangePasswordForm);
