import React, { Component } from "react";
import { InfoWindow, Marker } from "../GoogleMaps";

export default class MarkerWithInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.infoOpen || false,
    };
    this.closeInfoWindow = this.closeInfoWindow.bind(this);
    this.openInfoWindow = this.openInfoWindow.bind(this);
    this.handlePincodeClick = this.handlePincodeClick.bind(this);
  }

  closeInfoWindow() {
    this.setState({
      isOpen: false,
    });
  }

  openInfoWindow() {
    this.setState({
      isOpen: true,
    });
    if (this.props.onMarkerClick && !this.props.location.label?.location) {
      this.props.onMarkerClick();
    }
  }

  handlePincodeClick(groupId) {
    this.props.handlePincodeEdit(groupId);
  }
  render() {
    const { location, MarkerIcon } = this.props;
    return (
      <Marker
        icon={MarkerIcon}
        position={location.location}
        onClick={this.openInfoWindow}
      >
        {this.state.isOpen && location.label && (
          <InfoWindow onCloseClick={this.closeInfoWindow}>
            <div>
              <strong>{location?.label?.location}</strong>
              {", "}
              <strong>{location?.label?.time}</strong>
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}
