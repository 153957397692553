import React, { Component } from "react";
import Loader from "../../../../components/Loader";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import API from "../../../../lib/api";
import { getSession } from "../../../../lib/auth";
import { getMessage } from "../../../../lib/translator";
import AddPackageView from "../Details/AddPackageView";
import CreatePackage from "./CreatePackage";
import "./style.css";
import {
  getNestedState,
  updateStateRecursively,
} from "../../../../lib/stateManagement";
import { Dialog } from "../../../../components/Popup";

export class PackOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessDialog: false,
      showErrorDialog: false,
      formError: "",
      referenceNumber: this.props.match.params.id,
      submitting: false,
      packageList: [{ packageName: "", items: [], labelName: "", weight: "" }],
    };
    this.organization = getSession().organization;
    this.currency = this.organization.currency;
    this.showPackagePopupRef = React.createRef();
    this.selectPackageRef = React.createRef();
    this.updateStateRecursively = updateStateRecursively.bind(this);
    this.getNestedState = getNestedState.bind(this);
  }

  handlePrefillForm() {
    const packingDetails = this.state.data?.packingDetails?.[0] || {};
    const { details = {}, metaData } = packingDetails;
    const detailsData = Object.entries(details);
    const packList = {
      packageName: detailsData?.[0]?.[0],
      labelName: detailsData?.[0]?.[1]?.[0],
      weight: metaData?.packages?.[detailsData?.[0]?.[1]?.[0]]?.weight,
    };

    Object.keys(packList).forEach((key) => {
      if (packList[key]) {
        this.handleUpdateState(["packageList", 0, key], packList[key]);
      }
    });
  }

  getData() {
    let referenceNumber = this.state.referenceNumber;
    let api = new API({ url: `/order-service/order/${referenceNumber}` });
    this.setState(
      {
        loading: true,
        failed: false,
      },
      () => {
        api
          .get()
          .then(
            (response) => {
              this.setState(
                {
                  failed: false,
                  data: response.data.order,
                },
                () => {
                  if (this.props.match.params?.action === "edit") {
                    this.handlePrefillForm();
                  }
                }
              );
            },
            (error) => {
              this.setState({ failed: true });
              if (error.code === 401) {
                throw error;
              }
            }
          )
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.setState((prevState) => {
              return { loading: false };
            });
          });
      }
    );
  }
  getPackageConfiguration = (packageName) => {
    const organizationId = this.organization?.id;
    let params = {};
    params.id = organizationId;
    let api = new API({ url: `/order-service/package` });
    api
      .get(params)
      .then((response) => {
        this.setState(
          {
            packageData: response.data.package,
          },
          () => {
            if (packageName) {
              this.handleSelectPackage(packageName);
            }
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  componentDidMount() {
    this.getData();
    this.getPackageConfiguration();
  }

  handleCreatePackage = () => {
    this.showPackagePopupRef.current.childMethod();
  };

  handleSelectPackage = (packageName) => {
    this.selectPackageRef.current.childMethod(packageName);
  };

  handleUpdateState = (statePath, value) => {
    this.updateStateRecursively(statePath, value);
  };

  handleGetState = (statePath) => {
    return this.getNestedState(statePath);
  };

  sendPackageConfig = () => {
    this.setState({ submitting: true });
    const { packageName, labelName, weight } = this.state.packageList[0];
    const referenceNumber = this.state.referenceNumber;
    const id = this.state.data.customer.id;
    const { items } = this.state.data;
    const data = {
      user: { id },
      details: {},
      status: "PACKED",
      packageMetaData: { packages: {} },
    };
    data.details[packageName] = [labelName];
    data.packageMetaData.packages[labelName] = {
      weight: Number(weight),
      items: {},
    };
    items.forEach((item) => {
      data.packageMetaData.packages[labelName].items[item.id] = Number(
        item.orderDetails?.orderedQuantity
      );
    });
    data.user = JSON.stringify(data.user);
    data.details = JSON.stringify(data.details);
    const api = new API({ url: `/order-service/order/${referenceNumber}` });
    api
      .put(data)
      .then((response) => {
        if (response.status === "SUCCESS") {
          this.setState({
            formError: "",
            showSuccessDialog: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          formError: error.message,
          showErrorDialog: true,
        });
        console.error(error);
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  };
  sendEditPackageConfig = () => {
    this.setState({ submitting: true });
    const { packageName, labelName, weight } = this.state.packageList[0];
    const { items } = this.state.data;
    const referenceNumber = this.state.referenceNumber;
    const orderItemId = this.state.data.items[0].orderDetails.orderItemId;
    const quantity = Number(
      this.state.data.items[0].orderDetails.deliveredQuantity
    );
    const dataPacked = {
      items: [{ orderItemId, quantity }],
      details: {},

      packageMetaData: { packages: {} },
    };
    dataPacked.details[packageName] = Array(labelName);
    dataPacked.packageMetaData.packages[labelName] = {
      weight: Number(weight),
      items: {},
    };
    items.forEach((item) => {
      dataPacked.packageMetaData.packages[labelName].items[item.id] = Number(
        item.orderDetails?.orderedQuantity
      );
    });
    const api = new API({
      url: `/order-service/order/${referenceNumber}/pack`,
    });
    api
      .put(dataPacked)
      .then((response) => {
        if (response.status === "SUCCESS") {
          this.setState({
            formError: "",
            showSuccessDialog: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          formError: error.message,
          showErrorDialog: true,
        });
        console.error(error);
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  };

  handleSubmit = () => {
    if (this.props.match.params?.action === "edit") {
      this.sendEditPackageConfig();
    } else {
      this.sendPackageConfig();
    }
  };

  closeDialogs = () => {
    this.setState(
      {
        showSuccessDialog: false,
        showErrorDialog: false,
      },
      () => {
        if (!this.state.formError) {
          if (this.props.match.params?.action === "edit") {
            const { referenceNumber } = this.state;
            this.props.history.push(`/orders/orders/${referenceNumber}`);
            return;
          }
          this.props.history.push("/operations/orders");
        }
      }
    );
  };

  render() {
    let contentLoaded =
      !this.state.loading && !this.state.failed && this.state.data;
    const { submitting, packageData, packageList } = this.state;
    return (
      <AuthenticatedPage className="pack-order-wrp" menu={this.props.menu}>
        <div>
          <h1 className="title">{getMessage("order.action.packed")}</h1>
        </div>
        {!contentLoaded ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            {packageList.map((packageListData, index) => {
              return (
                <AddPackageView
                  key={index}
                  packageData={packageData}
                  currentIndex={index}
                  currentPackage={packageListData}
                  handleUpdateState={this.handleUpdateState}
                  handleGetState={this.handleGetState}
                  handleCreatePackage={this.handleCreatePackage}
                  ref={this.selectPackageRef}
                />
              );
            })}
            {packageList && packageList[0]?.packageName && (
              <>
                <div className="actions">
                  <div className="cancel-btn">
                    <button
                      className="button"
                      onClick={() => {
                        if (this.props.match.params?.action === "edit") {
                          const { referenceNumber } = this.state;
                          this.props.history.push(
                            `/orders/orders/${referenceNumber}`
                          );
                          return;
                        }
                        this.props.history.push("/operations/orders");
                      }}
                    >
                      {getMessage("order.form.cancel.text")}
                    </button>
                  </div>
                  <div className="submit-btn">
                    <button
                      className="primary button"
                      onClick={this.handleSubmit}
                    >
                      {submitting ? "..." : getMessage("order.action.packed")}
                    </button>
                  </div>
                </div>
              </>
            )}

            <CreatePackage
              ref={this.showPackagePopupRef}
              getPackageConfiguration={this.getPackageConfiguration}
            />

            <Dialog
              show={this.state.showSuccessDialog}
              className="success"
              information={getMessage("order.pack.request.success")}
              close={this.closeDialogs}
              closeText={getMessage("okay.text")}
            />
            <Dialog
              show={this.state.showErrorDialog}
              className="warn"
              title={getMessage("themes.layout.save.title.error")}
              information={getMessage(this.state.formError)}
              close={this.closeDialogs}
              closeText={getMessage("okay.text")}
            />
          </>
        )}
      </AuthenticatedPage>
    );
  }
}

export default PackOrder;
