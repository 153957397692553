import React from "react";
import { BaseForm } from "../../../../components/Form";
import ImageWithText from "../Layouts/ImageWithText";
import { getMessage } from "../../../../lib/translator";

export default class Layout extends BaseForm {
  constructor(props) {
    super(props);
    this.preview = this.props.preview;
    this.fields = this.props.fields || (() => null);
    this.state.values = this.props.data;
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    let Preview = this.preview;
    return (
      <Form>
        {this.preview && (
          <div className="layout-preview">
            <Preview data={this.props.data} />
          </div>
        )}
        <div className="layout-form">
          {this.fields({
            getState: this.getState.bind(this),
            updateState: this.updateState.bind(this),
            parent: this,
            index: this.props.index,
            key: this.props.key,
          })}
        </div>
        <div className="form-actions">
          <SubmitButton>{getMessage("layouts.save")}</SubmitButton>
        </div>
      </Form>
    );
  }
}

const layouts = {
  ImageWithText: ImageWithText,
};

export const getLayout = (layoutName) => {
  return layouts[layoutName]();
};
