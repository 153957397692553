import React from "react";
import {
  BaseForm,
  Searchable,
  Radio,
  SingleDatePicker,
  Input,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import { get } from "../../../../lib/storage";

export default class TripFiltersForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.values.status = "";
  }
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    const storeId = get("store");
    return (
      <Form>
        <div className="form-fields">
          <Searchable
            label={getMessage("trips.filter.byVehicleNumber.heading")}
            placeholder={getMessage("trips.filter.byVehicleNumber.placeholder")}
            name="vehicle"
            transformRequest={(searchText, paramsDefault) => {
              paramsDefault["storeId"] = storeId;
              return paramsDefault;
            }}
            searchUrl={"/logistics-service/vehicle"}
            valueKey="id"
            nameKey="number"
            searchKey="number"
            transformResponse={(response) => response.data.vehicle}
            {...this.generateStateMappers({
              stateKeys: ["vehicle"],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            isOutsideRange
            label={getMessage("trips.filter.byDates.heading")}
            {...this.generateStateMappers({
              stateKeys: ["date"],
              loseEmphasisOnFill: true,
            })}
            displayFormat="YYYY-MM-DD"
            placeholder="Date"
          />
          <Input
            label={getMessage("trips.filter.byOrderNumber.heading")}
            placeholder={getMessage("trips.filter.byOrderNumber.placeholder")}
            name="order"
            {...this.generateStateMappers({
              stateKeys: ["orderNumber"],
              loseEmphasisOnFill: true,
            })}
          />
          <Searchable
            label={getMessage("trips.filter.byRunnerExecutive.heading")}
            placeholder={getMessage(
              "trips.filter.byRunnerExecutive.placeholder"
            )}
            name="runnerExecutive"
            transformRequest={(searchText, paramsDefault) => {
              paramsDefault["roleId"] = 4;
              return paramsDefault;
            }}
            searchUrl={"/account-service/employee"}
            valueKey="id"
            nameKey="name"
            searchKey="name"
            transformResponse={(response) => response.data.employee}
            {...this.generateStateMappers({
              stateKeys: ["roles"],
              loseEmphasisOnFill: true,
            })}
          />
          <Radio
            label={getMessage("trips.filter.byStatus.heading")}
            name="status"
            className="status-filter"
            options={[
              {
                text: getMessage("trips.filter.byStatus.all"),
                value: "",
              },
              {
                text: getMessage("trips.filter.byStatus.open"),
                value: "OPEN",
              },
              {
                text: getMessage("trips.filter.byStatus.close"),
                value: "CLOSE",
              },
              {
                text: getMessage("trips.filter.byStatus.completed"),
                value: "FIN_CLOSE",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage("trips.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("trips.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
