import React from "react";
import { BaseForm } from "../../../../../components/Form";
import Checkbox from "../../../../../components/Form/Inputs/Checkbox";
import {
  toggleAll,
  computeChecked,
} from "../../../../../components/Form/Inputs/MultiLevelCheckbox";
import StyledSelect from "../StyledSelect";
import API from "../../../../../lib/api";
import { getMessage } from "../../../../../lib/translator";

import "./style.css";

function handleException(error) {
  if (error.code === 401) {
    throw error;
  }
}

export default class PermissionsForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = Object.assign(this.state, {
      users: [],
      loadingUsers: false,
      loadingUserPermissions: false,
      failedLoadingData: false,
      failedLoadingUsers: false,
      failedLoadingUserPermissions: false,
      selectedUser: null, // ID of the user whose permissions are being copied
    });
    this.getUserList = this.getUserList.bind(this);
    this.computeChecked = this.computeChecked.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.getUserPermissions = this.getUserPermissions.bind(this);
    this.copyUserPermissions = this.copyUserPermissions.bind(this);
  }
  computeChecked(service, mode) {
    let value = this.state.values[service];
    return Object.keys(value)
      .map((endpoint) => value[endpoint])
      .reduce((acc, { GET = true, PUT = true, POST = true, DELETE = true }) => {
        let result;
        if (mode === "READ") {
          result = acc && GET;
        } else if (mode === "WRITE") {
          result = acc && PUT && POST && DELETE;
        }
        return result;
      }, true);
  }
  updatePermissions({ service, api }, mode, value) {
    let methods = [];
    switch (mode) {
      case "READ":
        methods = ["GET"];
        break;
      case "WRITE":
        methods = ["PUT", "POST", "DELETE"];
        break;
      default:
        break;
    }
    let apis;
    if (api) {
      apis = [api];
    } else {
      apis = Object.keys(this.state.values[service]);
    }
    apis.forEach((api) => {
      let newValue = Object.assign({}, this.state.values[service][api]);
      let allowedPermissions = this.props.allPermissions[service][api];
      let methodsToUpdate = methods.slice();
      while (methodsToUpdate.length) {
        let method = methodsToUpdate.pop();
        if (allowedPermissions[method]) {
          newValue[method] = value;
        }
      }
      this.updateState([service, api], newValue, false);
    });
    this.setState({ selectedUser: null });
  }
  toggleAll(value) {
    this.setState((prevState) => {
      let newState = Object.assign(prevState);
      newState.values = toggleAll(prevState.values, value);
      newState.selectedUser = null;
      return newState;
    });
  }
  getUserList() {
    this.setState({ loadingUsers: true, failedLoadingUsers: false });
    this.api = new API({ url: `/account-service/user` });
    this.api
      .get({ paginated: "false" })
      .then(
        (response) => {
          this.setState({ users: response.data.user });
        },
        (err) => {
          this.setState({ failedLoadingUsers: true });
          handleException(err);
        }
      )
      .then(() => {
        this.setState({ loadingUsers: false });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  componentWillUnmount() {
    this.api && this.api.cancel();
  }
  getUserPermissions(id) {
    this.setState({ loadingUserPermissions: true });
    let api = new API({ url: `/account-service/user/${id}` });
    return new Promise((resolve, reject) => {
      api
        .get()
        .then((response) => {
          resolve(response.data.user.endpointPermissions);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        })
        .then(() => {
          this.setState({ loadingUserPermissions: false });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }
  copyUserPermissions(id) {
    this.getUserPermissions(id).then((permissions) => {
      this.setState({
        selectedUser: id,
        values: this.props.parsePermissions(permissions),
      });
    });
  }
  componentDidMount() {
    this.getUserList();
  }
  render() {
    const { Form } = this.components;
    const { SubmitButton, CancelButton } = this.buttons;
    return (
      <Form className="permissions-form">
        <div
          className={`outer-container ${
            this.state.loadingUserPermissions ? "loading-section" : ""
          }`.trim()}
        >
          <div className="fields-container">
            <div
              className={
                "toggle-all-container" +
                (this.scrollRef &&
                this.scrollRef.scrollHeight > this.scrollRef.offsetHeight
                  ? " overflown"
                  : "")
              }
            >
              <div className="permission-label">
                <Checkbox
                  controlled
                  name="toggle-all"
                  value={computeChecked(this.state.values)}
                  onChange={this.toggleAll}
                  inlineLabel="Select all"
                />
              </div>
              <div className="read-toggle-container">
                {getMessage("settings.users.permissionsForm.header.read")}
              </div>
              <div className="write-toggle-container">
                {getMessage("settings.users.permissionsForm.header.write")}
              </div>
            </div>
            <div
              className="checkboxes-container"
              ref={(node) => {
                this.scrollRef = node;
              }}
            >
              {Object.keys(this.props.allPermissions)
                .sort()
                .map((service) => (
                  <div className="permissions-section" key={service}>
                    <div className="section-heading permissions-row">
                      <div className="permission-label">
                        <div>{service}</div>
                      </div>
                      <div className="read-toggle-container">
                        {Object.values(this.getState([service]) || {})
                          .filter((obj) => "GET" in obj)
                          .filter(Boolean).length ? (
                          <Checkbox
                            controlled
                            name={`toggle-${service}--read`}
                            value={this.computeChecked(service, "READ")}
                            onChange={(value) => {
                              this.updatePermissions(
                                { service },
                                "READ",
                                value
                              );
                            }}
                          />
                        ) : null}
                      </div>
                      <div className="write-toggle-container">
                        {Object.values(this.getState([service]) || {}).filter(
                          (obj) =>
                            "POST" in obj || "PUT" in obj || "DELETE" in obj
                        ).length ? (
                          <Checkbox
                            controlled
                            name={`toggle-${service}--write`}
                            value={this.computeChecked(service, "WRITE")}
                            onChange={(value) => {
                              this.updatePermissions(
                                { service },
                                "WRITE",
                                value
                              );
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                    {Object.keys(this.getState([service]) || {})
                      .sort()
                      .map((api) => (
                        <div className="permissions-row" key={api}>
                          <div className="permission-label">{api}</div>
                          <div className="read-toggle-container">
                            {"GET" in
                            this.props.allPermissions[service][api] ? (
                              <Checkbox
                                controlled
                                name={`toggle-${service}--${api}--read`}
                                value={(({ GET = false }) => {
                                  return GET;
                                })(this.getState([service, api]))}
                                onChange={(value) => {
                                  this.updatePermissions(
                                    { service, api },
                                    "READ",
                                    value
                                  );
                                }}
                              />
                            ) : null}
                          </div>
                          <div className="write-toggle-container">
                            {"PUT" in this.props.allPermissions[service][api] ||
                            "POST" in this.props.allPermissions[service][api] ||
                            "DELETE" in
                              this.props.allPermissions[service][api] ? (
                              <Checkbox
                                controlled
                                name={`toggle-${service}--${api}--write`}
                                value={(({
                                  PUT = false,
                                  POST = false,
                                  DELETE = false,
                                }) => PUT || POST || DELETE)(
                                  this.getState([service, api])
                                )}
                                onChange={(value) => {
                                  this.updatePermissions(
                                    { service, api },
                                    "WRITE",
                                    value
                                  );
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
            </div>
          </div>
          <div className="user-list-container">
            <div className={this.state.loadingUsers ? "loading-section" : ""}>
              <h4>Copy from</h4>
              <StyledSelect
                options={this.state.users
                  .filter(
                    (user) => Number(user.id) !== Number(this.props.userId)
                  )
                  .map(
                    ({
                      id,
                      name,
                      emails: [{ email }] = [{}], // Extract first email
                    }) => ({
                      content: (
                        <div>
                          {name}
                          <div>
                            <small className="text-muted">{email}</small>
                          </div>
                        </div>
                      ),
                      value: id,
                    })
                  )}
                placeholder={
                  <div>
                    {getMessage(
                      "settings.users.permissionsForm.copyPlaceholder.name"
                    )}
                    <div>
                      <small className="text-muted">
                        {getMessage(
                          "settings.users.permissionsForm.copyPlaceholder.text"
                        )}
                      </small>
                    </div>
                  </div>
                }
                value={this.state.selectedUser}
                onChange={this.copyUserPermissions}
              />
            </div>
          </div>
        </div>
        <SubmitButton disabled={this.props.disabled}>
          {getMessage("settings.users.permissionsForm.submitText")}
        </SubmitButton>
        <CancelButton onCancel={this.props.onCancel}>
          {getMessage("settings.users.permissionsForm.cancelText")}
        </CancelButton>
      </Form>
    );
  }
}
