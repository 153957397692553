import React from "react";
import moment from "moment";
import {
  BaseForm,
  Radio,
  Select,
  SingleDatePicker,
  Input,
  Upload,
  Textarea,
  MultiSelect,
  Toggle,
  MultiTextInput,
  Checkbox,
  TimePicker,
} from "../../../../components/Form";
import Tabs from "../../../../components/Tabs";
import SelectStoresSearch from "../../../../components/SelectStoresSearch";
import BANYATP from "./BANYATP";
import BMINXATP from "./BMINXATP";
import BANYGYD from "./BANYGYD";
import BXATP from "./BXATP";
import BXGYD from "./BXGYD";
import SF from "./SF";
import SFXGSD from "./SFXGSD";
import PWP from "./PWP";
import BMIN from "./BMIN";
import BFXATP from "./BFXATP";
import BMINXGFG from "./BMINXGFG";
import { withRouter } from "react-router-dom";
import "./style.css";

import { getMessage } from "../../../../lib/translator";
import API from "../../../../lib/api";
import SegmentsForm from "../../../../components/SegmentsForm";
import downloadIcon from "../../Coupons/download.svg";
import { Dialog } from "../../../../components/Popup";
import { isExtensionEnabled } from "../../../../lib/auth";
import MetaDataFormFields from "../../../../components/MetaDataFormFields";
import { getNestedValues } from "../../../../lib/commonlyused";
import checkHttpResponseCode from "../../../../utils/isApiCallSuccessfull";
import SFBGSD from "./SFBGSD";
import SFEGSD from "./SFEGSD";

const noop = () => {
  return null;
};

let orderTypeOptions = [
  {
    text: getMessage("offer.ordertype.options.bulk"),
    value: "BULK",
  },
  {
    text: getMessage("offer.ordertype.options.delivery"),
    value: "DELIVERY",
  },
  {
    text: getMessage("offer.ordertype.options.pickup"),
    value: "PICKUP",
  },
  {
    text: getMessage("offer.ordertype.options.offline"),
    value: "OFFLINE",
  },
];

let tabsList = [
  {
    text: "Promotion",
  },
  {
    text: "Restrictions",
  },
  {
    text: "Override Content",
  },
];

class OfferForm extends BaseForm {
  constructor(props) {
    super(props);
    this.isNewOffer = this.props.method === "add";
    this.offerForm = {
      BANYATP: BANYATP,
      BMINXATP: BMINXATP,
      BANYGYD: BANYGYD,
      SFXGSD: SFXGSD,
      SFXGCD: SFXGSD,
      BXATP: BXATP,
      BXGYD: BXGYD,
      SF: SF,
      PWP: PWP,
      BMIN: BMIN,
      BFXATP: BFXATP,
      BMINXGFG: BMINXGFG,
      SFBGSD: SFBGSD,
      SFEGSD: SFEGSD,
    };
    this.entityType = {
      PRODUCT: "product",
      BRAND: "brand",
      CATEGORY: "category",
    };
    this.noMaxRedemption = ["SFXGSD", "SFXGCD", "BMIN", "BFXATP", "BMINXGFG"];
    this.buyKey = this.props.options.buyKey;
    const entityType = this.state.values.entityType;
    if (!entityType) {
      this.state.values.entityType = "PRODUCT";
    }
    if (!this.state.values.itemDiscountType) {
      this.state.values.itemDiscountType = "COMBO_DISCOUNT";
    }

    if (this.props.method === "add" && this.state.values) {
      this.state.values.status = "ENABLED";
    }
    this.freeDiscoutOffers = [
      "SF",
      "BXGYD",
      "BANYGYD",
      "SFXGSD",
      "SFXGCD",
      "PWP",
      "SFBGSD",
      "SFEGSD",
    ]; // offers that offer free pdts
    this.saveData = this.saveData.bind(this);
    this.addItem = this.addItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.addItemBuy = this.addItemBuy.bind(this);
    this.deleteBuyItem = this.deleteBuyItem.bind(this);
    this.handleEntityTypeOnchange = this.handleEntityTypeOnchange.bind(this);
    this.handleDiscountChange = this.handleDiscountChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleOfferTypeOnchange = this.handleOfferTypeOnchange.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
    this.noItemsOffers = ["SFXGSD", "SFXGCD", "PWP"];
    this.handleUniquePromocodeValidations =
      this.handleUniquePromocodeValidations.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.readyForSubmission = this.readyForSubmission.bind(this);
    this.handleCloneOffer = this.handleCloneOffer.bind(this);
    this.handleToggleClonePopup = this.handleToggleClonePopup.bind(this);
    this.closeErrorDialog = this.closeErrorDialog.bind(this);
    this.handleUpdateValues = this.handleUpdateValues.bind(this);
    this.handleUpdateValidations = this.handleUpdateValidations.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.options.pwpTagId !== this.props.options.pwpTagId) {
      let values = JSON.parse(JSON.stringify(this.state.values));
      values.pwpTagId = this.props.options.pwpTagId;
      this.setState({
        values,
      });
    }
    if (
      this.state.values &&
      this.state.values.type === "BMINXGFG" &&
      this.state.values.stackable
    ) {
      let disableCheckbox = false;
      let isCombo =
        this.state.values &&
        this.state.values.itemDiscountType === "COMBO_DISCOUNT";
      if (isCombo) {
        disableCheckbox =
          this.state.values.rule &&
          this.state.values.rule.elementGroups &&
          this.state.values.rule.elementGroups.some(
            (group) => group.total && group.total.t === "FIXED"
          );
      } else {
        let items =
          (this.state.values.rule &&
            this.state.values.rule.elementGroups.reduce(
              (acc, group) => acc.concat(group.product || []),
              []
            )) ||
          [];
        items.length > 0 &&
          items.map((item) => {
            if (item.t === "FIXED") {
              disableCheckbox = true;
            }
            return null;
          });
      }

      if (disableCheckbox) {
        this.setState({
          values: { ...this.state.values, stackable: false },
        });
        disableCheckbox = false;
      }
    } else if (
      this.state.values &&
      this.state.values.type === "BMINXATP" &&
      this.state.values.stackable
    ) {
      let disableCheckbox = false;
      disableCheckbox =
        this.state.values.rule &&
        this.state.values.rule.elementGroups &&
        this.state.values.rule.elementGroups.some(
          (group) => group.total && group.total.t === "FIXED"
        );
      if (disableCheckbox) {
        this.setState({
          values: { ...this.state.values, stackable: false },
        });
      }
    } else {
      if (
        this.state.values &&
        this.state.values.itemDiscountType === "COMBO_DISCOUNT"
      ) {
        if (
          this.state.values &&
          this.state.values.rule &&
          this.state.values.rule.total &&
          this.state.values.rule.total.t &&
          this.state.values.rule.total.t === "FIXED" &&
          this.state.values.stackable
        ) {
          this.setState({
            values: { ...this.state.values, stackable: false },
          });
        }
      } else if (
        this.state.values &&
        this.state.values.itemDiscountType === "INDIVIDUAL_DISCOUNT" &&
        this.state.values.stackable
      ) {
        let items = this.state.values.product || [];
        let disableCheckbox = false;
        items.length > 0 &&
          items.map((item) => {
            if (item.t === "FIXED") {
              disableCheckbox = true;
            }
            return null;
          });

        if (disableCheckbox) {
          this.setState({
            values: { ...this.state.values, stackable: false },
          });
          disableCheckbox = false;
        }
      }
    }
  }

  componentDidMount() {
    const { state, props } = this;
    const { method } = props;
    const { values } = state;
    this.tabForms =
      method === "add" ? [false, false, true] : ["true", true, true];
    this.fetchData();

    // This is for cloning an offer. From the offer details page,
    // the values are stored in sessionStorage and user is redirected to add offers page
    // and in this page hydrate the state from sessionStorage
    if (method === "add") {
      let clonedOffer = JSON.parse(
        window.sessionStorage.getItem("clonedOffer") || "{}"
      );
      clonedOffer = { ...values, ...clonedOffer };
      this.setState({ values: clonedOffer });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.options.activeIndex !== this.props.options.activeIndex) {
      // this.fetchData()
    }
  }

  componentWillUnmount() {
    if (this.props.method === "add") {
      window.sessionStorage.removeItem("clonedOffer");
    }
  }

  readyForSubmission() {
    let activeIndex = this.props.options.activeIndex;
    for (let i = 0; i < this.tabForms.length; i++) {
      if (activeIndex === i) {
        continue;
      } else {
        if (!this.tabForms[i]) {
          return { val: false, nextTabIndex: i };
        }
      }
    }

    return { value: true, nextTabIndex: undefined };
  }

  async fetchData() {
    this.hasOfferStarted =
      moment().diff(this.state.values.offerValidFrom) > 0 &&
      this.props.method === "edit";
    let entityId =
      this.state.values.rule &&
      this.state.values.rule.entity &&
      this.state.values.rule.entity.id;

    if (isExtensionEnabled("EntityMetaData")) {
      const metaDataApi = new API({ url: "/config-service/meta-data" });
      metaDataApi
        .get()
        .then((response) => {
          let offerMetadata = response.data.config.entityMetaData.offer || {};
          this.setState({
            metadataFormTypes: offerMetadata,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (this.props.method !== "add") {
      const offerType = this.props.value.offerType;
      let cartPrice =
        this.props.value &&
        this.props.value.rule &&
        this.props.value.rule.cartPrice;
      const entityType = this.state.values.entityType.toLowerCase();
      let getParams = "";
      let multiTierGetParams = "";
      let multiTierGetPdts =
        offerType === "BMINXGFG" &&
        (this.props.value.rule.elementGroups || []).map((group) => group.get);
      if (multiTierGetPdts) {
        multiTierGetPdts.map((getPdts) => {
          (Object.keys(getPdts) || []).forEach((id) => {
            multiTierGetParams += `id=${Number(id)}&`;
          });
          return null;
        });
      }
      let getPdts = this.props.value.rule.get;
      if (getPdts) {
        if (offerType === "PWP") {
          const rule = this.props.value.rule;
          const id = rule && rule.get && rule.get.variantId;
          getParams = `id=${id}`;
        } else {
          (Object.keys(getPdts) || []).forEach((id) => {
            getParams += `id=${Number(id)}&`;
          });
        }
      }

      if (this.state.values.tagIds && this.state.values.tagIds.length > 0) {
        let queryString = "";
        let tags = [];
        this.state.values.tagIds.map((id, index) => {
          if (index > 0) {
            queryString += `&id=${id}`;
          } else {
            queryString += `id=${id}`;
          }
          return null;
        });
        const tagApi = new API({
          url: `/catalogue-service/tag/?${queryString}`,
        });
        await tagApi
          .get()
          .then((res) => {
            if (checkHttpResponseCode(res.code) && res.status === "SUCCESS") {
              tags = res.data && res.data.tag;
            }
          })
          .catch((err) => console.error(err));

        this.setState((prevState) => {
          return {
            values: { ...prevState.values, tags },
            tagsLoaded: tags.length > 0,
          };
        });
      }

      if (getParams) {
        const getProductsAPI = new API({
          url: `/catalogue-service/product?${getParams}`,
        });
        await getProductsAPI
          .get()
          .then((response) => {
            let products = response.data.product || [];
            let productsFromProp = this.props.value.rule.get;
            products = products.map((product) => {
              let newProduct = product;
              let productFromProp =
                productsFromProp && productsFromProp[product.id];
              newProduct.q = productFromProp && productFromProp.q;
              if (this.props.value.itemDiscountType === "INDIVIDUAL_DISCOUNT") {
                newProduct.t = productFromProp && productFromProp.t;
                newProduct.v = productFromProp && productFromProp.v;
              }
              return newProduct;
            });
            let values = Object.assign({}, this.state.values);
            values.product = products;
            if (cartPrice) {
              values.cartPrice = cartPrice;
            }
            if (offerType === "PWP") {
              let rule = values.rule;
              rule.total = rule.get;
              rule.total.variantId = products[0];
              values.rule = rule;
            }
            this.setState({
              values,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (multiTierGetParams) {
        const getProductsAPI = new API({
          url: `/catalogue-service/product?${multiTierGetParams}`,
        });
        await getProductsAPI
          .get()
          .then((response) => {
            let products = response.data.product || [];
            let values = Object.assign({}, this.state.values);
            let { rule } = values;
            let { elementGroups } = rule;
            let newElementGroups = elementGroups.map((group) => {
              group.product =
                group.get &&
                Object.keys(group.get)
                  .map((getPdtId) => {
                    let newProduct =
                      products.find(
                        (pdt) => Number(pdt.id) === Number(getPdtId)
                      ) || null;
                    if (newProduct) newProduct.q = group.get[getPdtId].q;
                    if (
                      newProduct &&
                      this.props.value.itemDiscountType ===
                        "INDIVIDUAL_DISCOUNT"
                    ) {
                      newProduct.t = group.get[getPdtId].t;
                      newProduct.v = group.get[getPdtId].v;
                    }
                    return newProduct;
                  })
                  .filter(Boolean);
              delete group.get;
              return group;
            });
            rule.elementGroups = newElementGroups;
            values.rule = rule;
            this.setState({
              values,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (entityType === "product") {
        let pdtIds = this.props.value.rule.variants || [];
        let buyParams = "";
        let entityParams = "";
        pdtIds.forEach((id) => {
          buyParams += `id=${id}&`;
        });
        let buyPdts = this.props.value.rule.buy;
        let entityIds = [];
        if (
          this.props.value.offerType === "BMIN" ||
          this.props.value.offerType === "BMINXGFG"
        ) {
          entityIds = this.props.value.rule.entityIds || [];
        }

        if (this.props.value.offerType === "SFEGSD") {
          entityIds = this.props.value.rule.entity.ids;
        }

        if (buyPdts) {
          Object.keys(buyPdts).forEach((id) => {
            buyParams += `id=${Number(id)}&`;
          });
        }

        if (entityIds.length > 0) {
          entityIds.map((id, index) => {
            if (index === 0) {
              entityParams += `id=${Number(id)}`;
            } else {
              entityParams += `&id=${Number(id)}`;
            }
            return null;
          });
        }

        if (buyParams) {
          const buyProductsAPI = new API({
            url: `/catalogue-service/product?${buyParams}`,
          });
          await buyProductsAPI
            .get()
            .then((response) => {
              let products = response.data.product || [];
              products = products.map((product) => {
                let newProduct = product;
                let buyProduct = buyPdts && buyPdts[product.id];
                buyProduct =
                  typeof buyProduct === "object"
                    ? buyProduct
                    : { q: buyProduct };
                if (buyProduct) {
                  newProduct.q = buyProduct.q;
                  newProduct.t = buyProduct.t;
                  newProduct.v = buyProduct.v;
                }
                return newProduct;
              });
              let values = Object.assign({}, this.state.values);
              if (
                this.props.value.offerType === "BXATP" ||
                this.props.value.offerType === "BMINXATP" ||
                this.props.value.offerType === "BANYATP" ||
                this.props.value.offerType === "PT"
              ) {
                values.product = products;
              } else {
                values[`${this.props.options.buyKey}product`] = products;
              }
              this.setState({
                values,
              });
            })
            .catch((error) => {
              console.error(error);
            });
        }
        if (entityIds.length > 0) {
          const entityProductsApi = new API({
            url: `/catalogue-service/product?${entityParams}`,
          });
          await entityProductsApi
            .get()
            .then((response) => {
              if (
                checkHttpResponseCode(response.code) &&
                response.status === "SUCCESS"
              ) {
                let values = JSON.parse(JSON.stringify(this.state.values));
                if (
                  this.props.value.offerType === "BMIN" ||
                  this.props.value.offerType === "SFEGSD"
                ) {
                  values["product"] = response.data.product;
                  this.setState({
                    values,
                  });
                }
                if (this.props.value.offerType === "BMINXGFG") {
                  let products = response.data.product || [];
                  products = products.map((product) => {
                    let newProduct = product;
                    let buyProduct = buyPdts && buyPdts[product.id];
                    buyProduct =
                      typeof buyProduct === "object"
                        ? buyProduct
                        : { q: buyProduct };
                    if (buyProduct) {
                      newProduct.q = buyProduct.q;
                      newProduct.t = buyProduct.t;
                      newProduct.v = buyProduct.v;
                    }
                    return newProduct;
                  });

                  let values = Object.assign({}, this.state.values);
                  values[`${this.props.options.buyKey}product`] = products;
                  this.setState({
                    values,
                  });
                }
              }
            })
            .catch((err) => console.error(err));
        }
      } else {
        let values = Object.assign({}, this.state.values);
        if (entityType === "category") {
          if (entityId) {
            this.api = new API({
              url: `/catalogue-service/category?id=${entityId}`,
            });
            this.api
              .get()
              .then((response) => {
                values.category =
                  (Array.isArray(response.data.category) &&
                    response.data.category[0]) ||
                  null;
                if (cartPrice) {
                  values.cartPrice = cartPrice;
                }
                this.setState({
                  values,
                });
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            let entityIds = this.props.value.rule.entityIds || [];
            if (offerType === "SFEGSD") {
              entityIds = this.props.value.rule.entity.ids || [];
            }
            let entityParams = "";
            if (entityIds.length > 0) {
              entityIds.map((id, index) => {
                if (index === 0) {
                  entityParams += `id=${Number(id)}`;
                } else {
                  entityParams += `&id=${Number(id)}`;
                }
                return null;
              });

              const entityCategoryApi = new API({
                url: `/catalogue-service/category?${entityParams}`,
              });
              entityCategoryApi
                .get()
                .then((response) => {
                  if (
                    checkHttpResponseCode(response.code) &&
                    response.status === "SUCCESS"
                  ) {
                    let values = JSON.parse(JSON.stringify(this.state.values));
                    values["category"] = response.data.category;
                    this.setState({
                      values,
                    });
                  }
                })
                .catch((err) => console.error(err));
            }
          }
        } else if (entityType === "brand") {
          if (entityId) {
            this.api = new API({
              url: `/catalogue-service/brand?id=${entityId}`,
            });
            this.api
              .get()
              .then((response) => {
                values.brand =
                  (Array.isArray(response.data.brand) &&
                    response.data.brand[0]) ||
                  null;
                if (cartPrice) {
                  values.cartPrice = cartPrice;
                }
                this.setState({
                  values,
                });
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            let entityIds = this.props.value.rule.entityIds || [];
            if (offerType === "SFEGSD") {
              entityIds = this.props.value.rule.entity.ids || [];
            }
            let entityParams = "";
            if (entityIds.length > 0) {
              entityIds.map((id, index) => {
                if (index === 0) {
                  entityParams += `id=${Number(id)}`;
                } else {
                  entityParams += `&id=${Number(id)}`;
                }
                return null;
              });

              const entityBrandsApi = new API({
                url: `/catalogue-service/brand?${entityParams}`,
              });
              entityBrandsApi
                .get()
                .then((response) => {
                  if (
                    checkHttpResponseCode(response.code) &&
                    response.status === "SUCCESS"
                  ) {
                    let values = JSON.parse(JSON.stringify(this.state.values));
                    values["brand"] = response.data.brand;
                    this.setState({
                      values,
                    });
                  }
                })
                .catch((err) => console.error(err));
            }
          }
        }
      }
    }
  }

  saveData(data) {
    let formData = { ...data, ...this.state.values };
    if (this.isFormValid()) {
      this.props.onSubmit(formData);
    } else {
    }
  }

  deleteCategoryBrandsItem(index, entityType) {
    let values = JSON.parse(JSON.stringify(this.state.values));
    let items = (values && values[entityType]) || [];
    items.splice(index, 1);
    let validations = JSON.parse(JSON.stringify(this.state.validations));
    let itemsValidation = (validations && validations[entityType]) || [];
    if (itemsValidation) {
      if (items.length > 0) {
        itemsValidation.valueMissing = true;
        itemsValidation.valid = false;
      } else {
        itemsValidation.valueMissing = undefined;
        itemsValidation.valid = true;
      }
    }
    this.setState({
      values,
      validations,
    });
  }

  addItem(newItem, nestedKeys, entityType = "product") {
    if (!newItem) {
      return;
    }
    let values = JSON.parse(JSON.stringify(this.state.values));

    let valuesRef =
      values &&
      (nestedKeys !== undefined
        ? getNestedValues(values, ["rule", ...nestedKeys])
        : values);

    if (!valuesRef[entityType]) {
      valuesRef[entityType] = [];
    }
    const index = valuesRef[entityType].filter(
      (item) => item.id === newItem.id
    ).length;
    if (index > 0) {
      return;
    }
    valuesRef[entityType].push(newItem);
    this.setState({
      values,
    });
  }

  addItemBuy(newItem) {
    if (!newItem) {
      return;
    }
    const entityType = "product";
    let values = Object.assign({}, this.state.values);
    const key = `${this.props.options.buyKey}${entityType}`;
    if (!values[key]) {
      values[key] = [];
    }
    const index = values[key].filter((item) => item.id === newItem.id).length;
    if (index > 0) {
      return;
    }
    values[key].push(newItem);
    this.setState({
      values,
    });
  }

  deleteBuyItem(index) {
    let values = Object.assign({}, this.state.values);
    const entityType = "product";
    let key = `${this.props.options.buyKey}${entityType}`;
    let items = values[key] || [];
    items.splice(index, 1);
    let validations = Object.assign({}, this.state.validations);
    let itemsValidation = (validations && validations[key]) || [];
    itemsValidation.splice(index, 1);
    this.setState({
      validations,
      values,
    });
  }

  deleteItem(index, nestedKeys) {
    let values = JSON.parse(JSON.stringify(this.state.values));
    const entityType = "product";
    let items = !nestedKeys
      ? values && values[entityType]
      : getNestedValues(values, ["rule", ...nestedKeys, entityType]);
    items = items || [];

    items.splice(index, 1);
    let validations = JSON.parse(JSON.stringify(this.state.validations));
    let itemsValidation = !nestedKeys
      ? validations && validations[entityType]
      : getNestedValues(validations, ["rule", ...nestedKeys, entityType]);
    itemsValidation = itemsValidation || [];

    itemsValidation.splice(index, 1);
    this.setState({
      values,
      validations,
    });
  }

  handleDiscountChange(type, nestedKeys) {
    if (nestedKeys === undefined) {
      this.updateState(["rule", "total", "t"]);
    } else {
      this.updateState(["rule", ...nestedKeys, "total", "t"]);
    }

    this.setState((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let ruleValue =
        newState &&
        newState.values &&
        (nestedKeys !== undefined
          ? getNestedValues(newState.values, ["rule", ...nestedKeys])
          : newState.values.rule);
      let ruleValidation =
        newState &&
        newState.validations &&
        (nestedKeys !== undefined
          ? getNestedValues(newState.validations, ["rule", ...nestedKeys])
          : newState.validations.rule);
      if (ruleValue) {
        if (
          type === "FREE" &&
          ruleValidation &&
          ruleValidation.total &&
          ruleValidation.total.v &&
          !ruleValidation.total.v.valid
        ) {
          ruleValidation.total.v.valid = true;
        } else if (
          type !== "FREE" &&
          ruleValidation &&
          ruleValidation.total &&
          ruleValidation.total.v
        ) {
          ruleValidation.total.v.valid = false;
        }
        ruleValue.total.t = type;
        ruleValue.total.v = "";
      }
      return newState;
    });
  }

  beforeSubmit() {
    let data = Object.assign({}, this.state.values);
    let offerType = data.type;
    let instance = new this.offerForm[offerType]();
    instance.validationHandler(this);
  }
  async _submitHandler(e) {
    e && e.preventDefault();
    await this.beforeSubmit();
    this.setState({
      pressedSubmitWithCurrentData: true,
    });
    let isValid = this.isFormValid();
    let activeIndex = this.props.options.activeIndex;
    if (isValid) {
      this.tabForms[activeIndex] = true;
      let result = this.readyForSubmission();
      if (result.value) {
        if (this.props.onSubmit) {
          let values = JSON.parse(JSON.stringify(this.state.values));
          this.props.onSubmit(values);
        } else {
          this.onSubmit(this.state.values);
        }
      } else {
        this.changeTab(result.nextTabIndex);
      }
    } else {
      this.tabForms[activeIndex] = false;
    }
  }
  handleEntityTypeOnchange(targetValue) {
    let { productOnlyOffers, brandOnlyOffers, offerMapping } =
      this.props.options;
    let values = JSON.parse(JSON.stringify(this.state.values));
    let validations = JSON.parse(JSON.stringify(this.state.validations));
    let offersArray = [...offerMapping];
    offersArray = offersArray.filter((el) => {
      return (
        productOnlyOffers.indexOf(el.value) < 0 &&
        brandOnlyOffers.indexOf(el.value) < 0
      );
    });
    let offerType = values.type;
    if (
      targetValue !== "PRODUCT" &&
      offersArray.findIndex((offer) => offer.value === offerType) === -1
    ) {
      values.type = null;
    }
    values.entityType = targetValue;
    if (values.entityType === "PRODUCT") {
      delete values["brand"];
      delete values["category"];
      delete validations["brand"];
      delete validations["category"];
    }

    if (values.entityType === "BRAND") {
      delete values["buyproduct"];
      delete values["product"];
      delete values["category"];
      delete validations["product"];
      delete validations["category"];
    }

    if (values.entityType === "CATEGORY") {
      delete values["buyproduct"];
      delete values["product"];
      delete values["brand"];
      delete validations["product"];
      delete validations["brand"];
    }
    this.setState({ values, validations });
  }
  handleOfferTypeOnchange(type) {
    let values = {
      type: type,
      status: this.state.values.status,
      entityType: this.state.values.entityType,
      itemDiscountType: "COMBO_DISCOUNT",
      pwpTagId: this.state.values.pwpTagId,
    };
    if (values.type === "BMIN" || values.type === "BMINXATP") {
      values.rule = { itemDiscountType: "COMBO_DISCOUNT" };
    }
    let validations = { type: this.state.validations.type };
    this.setState({ values, validations, pressedSubmitWithCurrentData: false });
  }

  handleImageClick() {
    this.setState({
      showPromoDownloadPopup: true,
    });
    this.api = new API({
      url: `/offer-service/offer/${this.props.resourceId}/promocode`,
    });
    this.api
      .get()
      .then((res) => {})
      .catch((error) => {
        console.error(error);
        if (error.code === 401 || error.code === 403) {
          throw error;
        }
      });
  }

  onClosePopup() {
    this.setState({
      showPromoDownloadPopup: false,
    });
  }

  handleUniquePromocodeValidations(val) {
    if (!val) {
      let validations = JSON.parse(JSON.stringify(this.state.validations));
      let values = JSON.parse(JSON.stringify(this.state.values));
      values["hasUniquePromocode"] = val;
      delete validations.prefix;
      delete validations.suffix;
      delete validations.numberOfPromos;
      this.setState({
        validations,
        values,
      });
    } else {
      let values = JSON.parse(JSON.stringify(this.state.values));
      values["hasUniquePromocode"] = val;
      this.setState({
        values,
      });
    }
    return val;
  }

  changeTab(val) {
    let isValid = this.isFormValid();
    let activeIndex = this.props.options.activeIndex;
    if (isValid) {
      this.tabForms[activeIndex] = true;
    } else {
      this.tabForms[activeIndex] = false;
    }
    let validations = { type: this.state.validations.type };
    this.setState({ validations }, () => this.props.options.changeTab(val));
  }

  // This method is for updating the values in state, whatever values
  // are being sent from the child component, the parent is gonna save it.
  // So, handle it with care inside child component
  handleUpdateValues(values) {
    this.setState({ values });
  }

  handleUpdateValidations(validations) {
    this.setState({ validations });
  }

  handleCloneOffer(onlyCopy = true) {
    let { state, props } = this;
    let { values } = state || {};
    if (!onlyCopy) {
      this.disableOffer(values)
        .then(async () => {
          let redemptionValues;
          redemptionValues = await this.fetchRedemptionCount(values.id);
          let totalRedemption = redemptionValues.redemptionLimit;
          let redeemedCount = Number(redemptionValues.redemptionCount || 0);

          values = { ...values, totalRedemption, redeemedCount };

          this.cloneOffer(values, onlyCopy);
          props.history.push("/marketing/offers/add");
        })
        .catch((error) => {
          this.setState({
            showClonePopup: false,
            disableError: true,
            error: error,
          });
          console.error(error);
        });
    } else {
      values = {
        ...values,
        totalRedemption: null,
        redeemedCount: null,
        customerRedemptionLimit: null,
      };
      this.cloneOffer(values, onlyCopy);
      props.history.push("/marketing/offers/add");
    }
  }

  handleToggleClonePopup() {
    this.setState({ showClonePopup: !this.state.showClonePopup });
  }

  disableOffer(values) {
    let { id } = values;
    let url = `/offer-service/offer/${id}`;
    let api = new API({ url });
    return api.put({ id, status: "DISABLED" });
  }

  cloneOffer(state = {}, onlyCopy) {
    let { id, ...newOfferState } = state;
    if (!onlyCopy) {
      newOfferState = { ...newOfferState, copyOf: id };
    }
    delete newOfferState.clientId;
    window.sessionStorage.setItem("clonedOffer", JSON.stringify(newOfferState));
  }

  closeErrorDialog() {
    this.setState({ disableError: false });
  }

  async fetchRedemptionCount(id) {
    let url = `/offer-service/offer/${id}/redemption`;
    let api = new API({ url });
    let res = await api.get();
    let { data } = res;
    let { redemptionCount, redemptionLimit } = data || {};

    return { redemptionCount, redemptionLimit };
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton, CancelButton } = this.buttons;
    const { entityIds, stores, itemDiscountTypes } = this.props.options;
    let {
      offerMapping,
      discountTypes,
      pwpTagId,
      productOnlyOffers,
      brandOnlyOffers,
    } = this.props.options;
    const { values } = this.state;
    const { type, entityType, tags, oldFormat } = values;
    const tagsLength = tags && tags.length > 0;
    const offerType = values && values.type;
    const redeemedCount = values && values.redeemedCount;
    const FormComponent = this.offerForm[offerType] || noop;
    const itemsCount =
      values &&
      entityType &&
      values[entityType.toLowerCase()] &&
      (Array.isArray(values[entityType.toLowerCase()])
        ? values[entityType.toLowerCase()].length
        : values[entityType.toLowerCase()]);
    let activeIndex = this.props.options.activeIndex || 0;
    let promomtionsTab = activeIndex === 0;
    let restrictionsTab = activeIndex === 1;
    let overrideTab = activeIndex === 2;
    if (this.isNewOffer) {
      offerMapping = offerMapping.filter((offer) => offer.value !== "SF");
    }
    let applicableOffers = offerMapping.slice();
    if (entityType !== "PRODUCT") {
      // Not all offers are applicable on category and brand entities
      applicableOffers = applicableOffers.filter((el) => {
        return productOnlyOffers.indexOf(el.value) < 0;
      });
    }
    if (entityType !== "BRAND") {
      // Not all offers are applicable on category and brand entities
      applicableOffers = applicableOffers.filter((el) => {
        return brandOnlyOffers.indexOf(el.value) < 0;
      });
    }
    let applicableDiscounts = discountTypes.slice();
    if (this.freeDiscoutOffers.indexOf(offerType) > -1) {
      applicableDiscounts.push({
        text: "Free",
        value: "FREE",
      });
    }

    let disableCheckbox = false;
    if (values && values.type === "BMINXGFG") {
      let product = (values && values.product) || [];
      product.length > 0 &&
        product.map((item) => {
          if (item.t === "FIXED") {
            disableCheckbox = true;
          }
          return null;
        });
    } else if (values && values.type !== "BMINXGFG") {
      if (values.itemDiscountType === "COMBO_DISCOUNT") {
        disableCheckbox =
          values &&
          values.rule &&
          values.rule.total &&
          values.rule.total.t &&
          values.rule.total.t === "FIXED";
      } else {
        let product = (values && values.product) || [];
        product.length > 0 &&
          product.map((item) => {
            if (item.t === "FIXED") {
              disableCheckbox = true;
            }
            return null;
          });
      }
    }

    let isDisabled = this.props.value && this.props.value.status === "DISABLED";

    let isClonedOfferWithContinuation =
      redeemedCount !== null && redeemedCount !== undefined;

    return (
      <React.Fragment>
        {this.props.method === "edit" && (
          <div className="clone-buttons">
            {!isDisabled && (
              <button className="primary" onClick={this.handleToggleClonePopup}>
                {getMessage("offer.clone.disableNClone")}
              </button>
            )}
            <button className="primary" onClick={this.handleCloneOffer}>
              {getMessage("offer.clone.copy")}
            </button>
          </div>
        )}
        <div className="tabs-offer">
          <Tabs
            items={tabsList.map((tab) => `${tab.text}`)}
            default={0}
            onClick={this.changeTab}
            active={this.props.options.activeIndex}
          />
        </div>
        <div className="offers-form offers-toggle">
          <Toggle
            name="offersStatus"
            value={this.getState(["status"]) === "ENABLED"}
            icons={false}
            onChange={() => {
              let status = !(this.getState(["status"]) === "ENABLED")
                ? "ENABLED"
                : "DISABLED";
              this.updateState(["status"], status);
            }}
          />
        </div>
        <Form className="offers-form">
          <div className="form-fields">
            {promomtionsTab && [
              <Radio
                name="entity-type"
                key={"entity-type"}
                label={getMessage("offer.entity")}
                options={entityIds}
                value={entityType}
                onChange={this.handleEntityTypeOnchange}
                readOnly={!this.isNewOffer}
              />,
              <Select
                name="offer-type"
                key="type"
                label={getMessage("offer.type")}
                placeholder={getMessage("offer.type.placeholder")}
                options={applicableOffers}
                {...this.generateStateMappers({
                  stateKeys: ["type"],
                  loseEmphasisOnFill: true,
                })}
                onChange={this.handleOfferTypeOnchange}
                disabled={!this.isNewOffer}
              />,
            ]}
            {restrictionsTab && [
              <div className="grid" key="valid-from-div">
                <SingleDatePicker
                  enableToday
                  name="vaild-from"
                  key="vaild-from"
                  required
                  label={getMessage("offer.validFrom")}
                  {...this.generateStateMappers({
                    stateKeys: ["validFrom"],
                    loseEmphasisOnFill: true,
                  })}
                />
                <TimePicker
                  name="validFromTime"
                  label={getMessage("offer.time")}
                  key="start-time"
                  required
                  placeholder={getMessage("offer.time.placeholder")}
                  {...this.generateStateMappers({
                    stateKeys: ["validFromTime"],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>,
              <div className="grid" key="valid-to-div">
                <SingleDatePicker
                  enableToday
                  name="valid-to"
                  key="valid-to"
                  label={getMessage("offer.validTill")}
                  {...this.generateStateMappers({
                    stateKeys: ["validTill"],
                    loseEmphasisOnFill: true,
                  })}
                />
                <TimePicker
                  name="validToTime"
                  key="end-time"
                  label={getMessage("offer.validto.time")}
                  placeholder={getMessage("offer.time.placeholder")}
                  {...this.generateStateMappers({
                    stateKeys: ["validTillTime"],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>,
              !this.noMaxRedemption.includes(type) && (
                <Input
                  readOnly={this.hasOfferStarted}
                  type="number"
                  name="max-redemption"
                  key="max-redemption"
                  min={0}
                  label={getMessage("offer.max.redemption")}
                  placeholder={getMessage("offer.max.redemption.placeholder")}
                  {...this.generateStateMappers({
                    stateKeys: ["rule", "limit"],
                    loseEmphasisOnFill: true,
                  })}
                />
              ),
            ]}
            {restrictionsTab && stores && (
              <>
                <SelectStoresSearch
                  label={getMessage("offer.applies.to")}
                  placeholder={getMessage("offer.applies.to.placeholder")}
                  _this={this}
                  stores={stores}
                  stateKey="storeId"
                  required
                />
                <div>
                  <span className="restiction-order-type">
                    {getMessage("offer.ordertype.label")}
                    <div className="restriction-order-type-warning">
                      Please choose the correct order type to avoid creating
                      offers applicable to all order types
                    </div>
                  </span>
                  <MultiSelect
                    name="order-types"
                    key="order-types"
                    label=" "
                    placeholder={getMessage("offer.ordertype.placeholder")}
                    options={orderTypeOptions}
                    {...this.generateStateMappers({
                      stateKeys: ["orderType"],
                      loseEmphasisOnFill: true,
                    })}
                    readOnly={this.hasOfferStarted}
                  />
                </div>
                <div
                  className={`restriction-payment-type ${
                    !this.noMaxRedemption.includes(type) ? "margin-t" : ""
                  }`}
                >
                  <MultiTextInput
                    type="text"
                    name="payment-type"
                    key="payment-type"
                    label={getMessage("offer.payment.type")}
                    placeholder={getMessage("offer.payment.type.placeholder")}
                    {...this.generateStateMappers({
                      stateKeys: ["paymentType"],
                      loseEmphasisOnFill: true,
                    })}
                    readOnly={this.hasOfferStarted}
                  />
                </div>
                <div className="grid">
                  <Checkbox
                    className="stackable"
                    label={getMessage("offer.field.stackable.label")}
                    name={`stackable`}
                    disabled={disableCheckbox || this.hasOfferStarted}
                    {...this.generateStateMappers({
                      stateKeys: ["stackable"],
                      loseEmphasisOnFill: true,
                    })}
                  />
                  <Checkbox
                    className="visibility"
                    label={getMessage("offer.field.inInvisible.label")}
                    name={`isInvisible`}
                    {...this.generateStateMappers({
                      stateKeys: ["isInvisible"],
                      loseEmphasisOnFill: true,
                    })}
                  />
                </div>
                <div className="grid">
                  <Checkbox
                    className="unique-promocode"
                    label={getMessage("offer.field.hasUniquePromocode.label")}
                    name={`unique-promocode`}
                    disabled={this.props.method !== "add"}
                    {...this.generateStateMappers({
                      stateKeys: ["hasUniquePromocode"],
                      loseEmphasisOnFill: true,
                    })}
                    onChange={this.handleUniquePromocodeValidations}
                  />
                  {this.props.method === "edit" &&
                    this.state.values &&
                    this.state.values.hasUniquePromocode && (
                      <span className="download-image">
                        <img
                          src={downloadIcon}
                          alt="Download"
                          onClick={this.handleImageClick}
                          className="download_icon"
                        />
                      </span>
                    )}

                  {this.state.showPromoDownloadPopup && (
                    <Dialog
                      show={this.state.showPromoDownloadPopup}
                      className="notification success"
                      title={getMessage("offer.dialog.notification.label")}
                      information={getMessage(
                        "offer.dialog.notification.message"
                      )}
                      close={this.onClosePopup}
                      closeText={getMessage("offer.dialog.notification.close")}
                    />
                  )}
                  {this.state.values &&
                    !this.state.values.hasUniquePromocode && (
                      <MultiTextInput
                        className="promocode"
                        label={getMessage("coupon.promocode.label")}
                        placeholder={getMessage("coupon.promocode.placeholder")}
                        name="promocode"
                        type="text"
                        {...this.generateStateMappers({
                          stateKeys: ["promoCode"],
                          loseEmphasisOnFill: true,
                        })}
                      />
                    )}
                  {this.props.method === "add" &&
                    this.state.values &&
                    this.state.values.hasUniquePromocode && (
                      <Input
                        className="coupon-code"
                        label={getMessage("Number of coupons")}
                        placeholder={getMessage("Enter number of coupons")}
                        name="number-of-promos"
                        type="number"
                        required
                        {...this.generateStateMappers({
                          stateKeys: ["numberOfPromos"],
                          loseEmphasisOnFill: true,
                        })}
                      />
                    )}
                  {this.props.method === "add" &&
                    this.state.values &&
                    this.state.values.hasUniquePromocode && (
                      <Input
                        className="coupon-code"
                        label={getMessage("prefix")}
                        placeholder={getMessage("Enter prefix")}
                        name="prefix"
                        required
                        {...this.generateStateMappers({
                          stateKeys: ["prefix"],
                          loseEmphasisOnFill: true,
                        })}
                      />
                    )}
                  {this.props.method === "add" &&
                    this.state.values &&
                    this.state.values.hasUniquePromocode && (
                      <Input
                        className="coupon-code"
                        label={getMessage("suffix")}
                        placeholder={getMessage("Enter suffix")}
                        name="suffix"
                        required
                        {...this.generateStateMappers({
                          stateKeys: ["suffix"],
                          loseEmphasisOnFill: true,
                        })}
                      />
                    )}
                </div>
                <div className="customer-level-restrictions">
                  <h3>Customer Level</h3>
                  <div className="redemptions">
                    <Input
                      type="number"
                      name="customer-redemption"
                      key="customer-redemption"
                      min={0}
                      label={getMessage("offer.customer.redemption")}
                      placeholder={getMessage(
                        "offer.customer.redemption.placeholder"
                      )}
                      {...this.generateStateMappers({
                        stateKeys: ["customerRedemptionLimit"],
                        loseEmphasisOnFill: true,
                      })}
                      readOnly={this.hasOfferStarted}
                    />
                  </div>
                </div>

                <h3 className="delivery-date">
                  {getMessage("offer.heading.deliveyDate")}
                </h3>
                <div className="grid">
                  <SingleDatePicker
                    disabled={this.hasOfferStarted}
                    enableToday
                    name="delivery-date-start"
                    key="delivery-date-start"
                    placeholder={getMessage("offer.delivery-date.start")}
                    {...this.generateStateMappers({
                      stateKeys: ["startDeliveryDate"],
                      loseEmphasisOnFill: true,
                    })}
                    openDirection="up"
                  />
                  <TimePicker
                    disabled={this.hasOfferStarted}
                    key="delivery-date-start-time"
                    placeholder={getMessage("offer.time.placeholder")}
                    name="delivery-date-start-time"
                    {...this.generateStateMappers({
                      stateKeys: ["startDeliveryDateTime"],
                      loseEmphasisOnFill: true,
                    })}
                  />
                </div>
                <div className="grid" key="valid-to-div">
                  <SingleDatePicker
                    enableToday
                    name="delivery-date-end"
                    key="delivery-date-end"
                    disabled={this.hasOfferStarted}
                    placeholder={getMessage("offer.delivery-date.end")}
                    {...this.generateStateMappers({
                      stateKeys: ["endDeliveryDate"],
                      loseEmphasisOnFill: true,
                    })}
                    openDirection="up"
                  />
                  <TimePicker
                    name="delivery-date-end-time"
                    key="delivery-date-end-time"
                    disabled={this.hasOfferStarted}
                    placeholder={getMessage("offer.time.placeholder")}
                    {...this.generateStateMappers({
                      stateKeys: ["endDeliveryDateTime"],
                      loseEmphasisOnFill: true,
                    })}
                  />
                </div>
                <Input
                  type="number"
                  name="total-redemption"
                  key="total-redemption"
                  min={0}
                  label={getMessage("offer.total.redemption")}
                  placeholder={getMessage("offer.total.redemption.placeholder")}
                  {...this.generateStateMappers({
                    stateKeys: ["totalRedemption"],
                    loseEmphasisOnFill: true,
                  })}
                  readOnly={this.hasOfferStarted}
                >
                  {isClonedOfferWithContinuation && (
                    <span className="offer-available-redemption">
                      {getMessage("offer.redeemedCount.placeholder", {
                        count: redeemedCount,
                      })}
                    </span>
                  )}
                </Input>
              </>
            )}
            {overrideTab && [
              <Textarea
                name="description"
                key="description"
                label={getMessage("offer.description")}
                placeholder={getMessage("offer.description.placeholder")}
                {...this.generateStateMappers({
                  stateKeys: ["description"],
                  loseEmphasisOnFill: true,
                })}
              />,
              <Upload
                name="offer-image"
                key="image"
                label={getMessage("offer.image")}
                {...this.generateStateMappers({
                  stateKeys: ["imageUrl"],
                  loseEmphasisOnFill: true,
                })}
              />,
            ]}
          </div>

          {overrideTab && (
            <>
              <h3>{getMessage("Custom fields")}</h3>
              <MetaDataFormFields
                metaDataWithType={this.state.metadataFormTypes}
                _this={this}
                page="offer-form"
                className="metadata-section"
              />
            </>
          )}

          {restrictionsTab && <SegmentsForm _this={this} />}

          {promomtionsTab && (
            <FormComponent
              entityType={entityType}
              discountTypes={applicableDiscounts}
              save={this.saveData}
              type={type}
              cancel={this.props.onCancel}
              entityTypeMap={this.entityType}
              itemDiscountTypes={itemDiscountTypes}
              _this={this}
              buyKey={this.props.options.buyKey}
              hasOfferStarted={this.hasOfferStarted}
              isNewOffer={this.isNewOffer}
              pwpTagId={pwpTagId}
              method={this.props.method}
              onUpdateValues={this.handleUpdateValues}
              onUpdateValidations={this.handleUpdateValidations}
              oldFormat={oldFormat}
            />
          )}
          {((itemsCount || this.noItemsOffers.includes(type) || tagsLength) &&
            this.props.method === "add") ||
          type === "BMINXGFG" ||
          this.props.method === "edit" ? (
            <div className="form-action">
              <SubmitButton
                disabled={
                  !(
                    itemsCount ||
                    this.noItemsOffers.includes(type) ||
                    tagsLength ||
                    (type === "BMINXGFG" &&
                      values.rule &&
                      values.rule.elementGroups &&
                      values.rule.elementGroups.every(
                        (grp) =>
                          Array.isArray(grp.product) && grp.product.length
                      ))
                  )
                }
              >
                {getMessage("offer.submit")}
              </SubmitButton>
              <CancelButton>{getMessage("offer.cancel")}</CancelButton>
            </div>
          ) : null}
        </Form>
        {this.state.showClonePopup && (
          <Dialog
            show={this.state.showClonePopup}
            className="notification failure"
            title={getMessage("offer.dialog.clone.label")}
            information={getMessage("offer.dialog.clone.message")}
            close={this.handleToggleClonePopup}
            closeText={getMessage("offer.dialog.clone.close")}
            onOk={() => this.handleCloneOffer(false)}
            okText={getMessage("offer.dialog.clone.proceed")}
          />
        )}
        {this.state.disableError && (
          <Dialog
            show={this.state.disableError}
            className="notification failure"
            title={getMessage("offer.dialog.disable.errorHead")}
            information={getMessage("offer.dialog.disable.errorText")}
            close={this.closeErrorDialog}
            closeText={getMessage("offer.dialog.disable.close")}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(OfferForm);
