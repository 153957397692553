import loginStrings from "./login.js";
import resetPasswordStrings from "./resetPassword.js";
import changePasswordStrings from "./changePassword.js";
import menuStrings from "./menu.js";
import signupStrings from "./signup.js";
import settingsStrings from "./settings.js";
import validationStrings from "./validations.js";
import error404Strings from "./404.js";
import catalogueStrings from "./catalogue.js";
import operationsStrings from "./operations.js";
import marketingStrings from "./marketing.js";
import errorStrings from "./errors.js";
import listingPageStrings from "./listingPage.js";
import userStrings from "./user.js";
import logisticsStrings from "./logistics";
import hrStrings from "./hr";
import storeStrings from "./stores";
import csStrings from "./customer-support";
import analytics from "./analytics";
import onboarding from "./onboarding";
import sellersString from './sellers.js'

const allStrings = Object.assign(
  {},
  loginStrings,
  resetPasswordStrings,
  validationStrings,
  signupStrings,
  error404Strings,
  settingsStrings,
  changePasswordStrings,
  menuStrings,
  catalogueStrings,
  operationsStrings,
  marketingStrings,
  listingPageStrings,
  userStrings,
  errorStrings,
  logisticsStrings,
  hrStrings,
  storeStrings,
  csStrings,
  analytics,
  onboarding,
  sellersString
);

export default allStrings;
