import React, { Component } from "react";
import "./style.css";
import API from "../../lib/api";

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploading: false,
      file: "",
      uploadError: "",
      fileUploaded: false,
    };
    this.readFile = this.readFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.cancleFileUpload = this.cancleFileUpload.bind(this);
  }

  readFile(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      let state = {
        file: file,
      };
      // file validations
      if (
        this.props.accept !== ".*" &&
        file.type &&
        !this.props.accept
          .split(",")
          .map((str) => str.trim())
          .includes(file.type)
      ) {
        state["uploadError"] =
          this.props.validationStrings.invalidFileType || "Invalid File Type";
      } else {
        state["uploadError"] = "";
      }
      this.setState(state);
    }
  }

  cancleFileUpload() {
    this.setState({
      file: "",
      uploadError: "",
    });
  }

  handleFileUpload() {
    let data = new window.FormData();
    const file = this.state.file;
    const uploadUrl = this.props.uploadUrl;
    data.append(this.props.uploadKey || "fileUpload", file);
    if (this.props?.storeId) {
      data.append("storeId", this.props.storeId);
    }
    if (this.props?.userId) {
      data.append("userId", this?.props?.userId);
    }
    if (this.props?.organizationId) {
      data.append("organizationId", this?.props?.organizationId);
    }
    data = this.props.transformSubmit ? this.props.transformSubmit(data) : data;
    this.setState(
      {
        fileUploading: true,
        fileUploaded: false,
      },
      () => {
        const api = new API({ url: uploadUrl });
        api
          .post(data)
          .then(
            (response) => {
              this.setState(
                {
                  fileUploading: false,
                  fileUploaded: true,
                  uploadError: "",
                },
                this.props.successCallback
                  ? () => this.props.successCallback(response)
                  : null
              );
            },
            (error) => {
              let { code } = error;
              if (code === 401 || code === 403) {
                throw error;
              }
              this.setState(
                {
                  file: "",
                  uploadError: error.message,
                  fileUploading: false,
                  fileUploaded: false,
                },
                this.props.errorCallback
                  ? (response) => this.props.errorCallback(error)
                  : null
              );
            }
          )
          .catch((error) => {
            console.error(error);
          });
      }
    );
  }

  handleClick() {
    this.uploadInputRef.value = null;
  }

  render() {
    const {
      name,
      required,
      placeholder,
      accept,
      uploadButtonText,
      cancelText,
      icon,
      disableUpload,
    } = this.props;
    const { file, uploadError, fileUploaded, fileUploading } = this.state;

    let title = (this.props.strings && this.props.strings.defaultMessage) || "";
    if (file.name && !uploadError && !fileUploaded) {
      title = (this.props.strings && this.props.strings.progressMessage) || "";
    } else if (fileUploaded) {
      title =
        (this.props.strings && this.props.strings.completionMessage) || "";
    }

    return (
      <span className="input FileUpload">
        <div className="title">{title}</div>
        {(!file.name || uploadError) && !fileUploaded && (
          <div className="file-container">
            <div className="file-label">
              {placeholder || "Click here to upload the file"}
            </div>
            <input
              type="file"
              id={name}
              name={name}
              required={required}
              onChange={this.readFile}
              accept={accept}
              ref={(node) => {
                this.uploadInputRef = node;
              }}
              onClick={this.handleClick}
              disabled={disableUpload}
            />
          </div>
        )}
        {uploadError ? (
          <div className="form-error">{uploadError}</div>
        ) : (
          file.name && (
            <div>
              <div className="file-name-container">
                {icon && (
                  <div className="file-icon">
                    <img src={icon} alt="" />
                  </div>
                )}
                <div className="upload-file-name">{file.name}</div>
              </div>
              {!fileUploaded && (
                <div className="upload-button-container">
                  <button
                    disabled={fileUploading}
                    type="button"
                    onClick={this.cancleFileUpload}
                  >
                    {cancelText}
                  </button>
                  <button
                    disabled={fileUploading}
                    className="primary"
                    type="button"
                    onClick={this.handleFileUpload}
                  >
                    {fileUploading ? "..." : uploadButtonText}
                  </button>
                </div>
              )}
            </div>
          )
        )}
      </span>
    );
  }
}

FileUpload.defaultProps = {
  accept: ".csv,text/csv",
};

export default FileUpload;
