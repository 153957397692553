import React from "react";
import { BaseForm, Input, Select } from "../../../../../components/Form";
import API from "../../../../../lib/api";
import { getMessage } from "../../../../../lib/translator";
// import { DEVELOPER_TOKEN } from "../../../../../../src/config/app";
import "./style.css";
import { Dialog } from "../../../../../components/Popup";
import { faro } from "@grafana/faro-web-sdk";
import { isExtensionEnabled } from "../../../../../lib/auth";

export default class ProductFiltersForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      indusrtyNames: [],
      selectedIndustry: "All",
      showErrorDialog: false,
      formError: "",
      values: {
        tagId: null,
      },
    };
    this.isMarketPlaceEntensionEnabled = isExtensionEnabled("MarketPlace");
  }
  handleGetIndustry = () => {
    return this.state.indusrtyNames.map((val) => ({
      text: val.name,
      value: val.name,
    }));
  };
  componentDidMount() {
    this.getIndustryApi = new API({
      url: "/account-service/industry",
    });
    if (!this.isMarketPlaceEntensionEnabled) {
      Promise.all([this.getIndustryApi.get()])
        .then(([industryApiResponse]) => {
          const allObject = {
            name: "All",
            id: "All",
          };
          const availableIndustryProvider = [
            allObject,
            ...industryApiResponse.data?.industry,
          ];
          this.setState({ indusrtyNames: availableIndustryProvider });
        })
        .catch((error) => {
          if (faro?.api?.pushError) {
            faro.api.pushError(error);
          }
          this.setState({ formError: error.message, showErrorDialog: true });
        });
    }
  }
  handleGetTagId = (tag) => {
    this.getTagApi = new API({
      url: "/catalogue-service/tag",
    });
    this.getTagApi
      .get(
        {
          name: tag,
        }
        // {
        //   "Developer-token": DEVELOPER_TOKEN,
        //   restrictAutherizationToken: true,
        // }
      )
      .then((tagApiResponse) => {
        const tagArr = tagApiResponse.data.tag;
        if (tagArr?.length) {
          const tagCurrent = tagArr.filter((obj) => obj.name === tag);
          const tagNumber = tagCurrent[0].id;
          this.setState((prevState) => {
            let newState = Object.assign({}, prevState);
            newState.values.tagId = tagNumber;
            return newState;
          });
        }
      })
      .catch((error) => {
        if (faro?.api?.pushError) {
          faro.api.pushError(error);
        }
        this.setState({ formError: error.message, showErrorDialog: true });
      });
  };
  handleSelectChange = (value) => {
    if (value !== "All") {
      this.handleGetTagId(value);
    }

    this.setState({ selectedIndustry: value });
  };
  closeDialogs = () => {
    this.setState({
      showErrorDialog: false,
      formError: "",
    });
  };
  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <>
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage(this.state.formError)}
          close={this.closeDialogs}
          closeText={getMessage("okay.text")}
        />
        <Form className="product-search-add-form">
          <div
            className="form-fields"
            style={{ display: this.isMarketPlaceEntensionEnabled && "initial" }}
          >
            {!this.isMarketPlaceEntensionEnabled && (
              <Select
                className="industry-select"
                name={`product-search-select`}
                options={this.handleGetIndustry()}
                value={this.state.selectedIndustry}
                onChange={this.handleSelectChange}
              />
            )}
            <Input
              placeholder={getMessage(
                "product.search.and.add.text.placeholder"
              )}
              className="product-name"
              name="name"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["q"],
              })}
            />
          </div>
          <SubmitButton>{getMessage("search.label.text")}</SubmitButton>
        </Form>
      </>
    );
  }
}
