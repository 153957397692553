import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import { Input, Select } from "../../../../../components/Form";
import ActionButtons from "../ActionButtons";
import { Dialog } from "../../../../../components/Popup";
import { getStores } from "../../../../../lib/auth";

import API from "../../../../../lib/api";
import { getCurrency } from "../../../../../lib/userDetails";
import { getMessage } from "../../../../../lib/translator";
import { getSingularPluralForm } from "../../../../../lib/commonlyused";
import deleteIcon from "../icon-dustbin.svg";
import emptyIcon from "../../KMLUpload/trip-upload.svg";
import "./style.css";
import { DeliveryFeeFormulaForm } from "..";

const getLatlngFromPincode = (pincodes = []) => {
  let geocoder = new window.google.maps.Geocoder();
  let promises = [];
  pincodes &&
    pincodes.forEach(({ pincode }) => {
      let promise = new Promise((resolve, reject) => {
        geocoder.geocode({ address: `${pincode}` }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const location = results[0].geometry.location;
            const lat = location.lat();
            const lng = location.lng();
            resolve({ lat, lng });
          }
        });
      });
      promises.push(promise);
    });

  return Promise.all(promises).then((locations) => locations);
};

const preventDefaultBehavior = (e) => {
  e && e.preventDefault && e.preventDefault();
  e && e.stopPropagation && e.stopPropagation();
};

const PinCodeList = ({
  pincodes = [],
  editable,
  onDelete,
  onAddPincode,
  onDeleteZone,
}) => {
  const [showPincodeInput, setShowPincodeInput] = useState(false);
  const [tempPincode, setTempPincode] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const createHandleDelete = (index) => (e) => {
    preventDefaultBehavior(e);
    onDelete(index);
  };

  const handleAddPincode = (e) => {
    preventDefaultBehavior(e);
    onAddPincode("");
    setShowPincodeInput(true);
  };

  const handleChange = (value) => {
    setTempPincode(value);
  };

  const handleEnter = (e) => {
    if (e.which === 13) {
      preventDefaultBehavior(e);
      handleSave();
    }
  };

  const handleSave = (e) => {
    if (e) {
      preventDefaultBehavior(e);
    }
    onAddPincode(tempPincode);
    setShowPincodeInput(false);
    setTempPincode("");
  };

  const handleCancel = (e) => {
    preventDefaultBehavior(e);
    setShowPincodeInput(false);
    onDelete(pincodes?.length - 1);
    setTempPincode("");
  };

  const handleShowDeletePopup = (e) => {
    preventDefaultBehavior(e);
    setShowDeletePopup(true);
  };

  const hanldeDeleteZone = (e) => {
    preventDefaultBehavior(e);
    setShowDeletePopup(false);
    onDeleteZone();
  };

  return (
    <Fragment>
      {pincodes?.map(({ pincode }, index, arr) => (
        <div
          className={"pincode-item" + (editable ? " edit" : "")}
          key={`pincode-${pincode}-${index}`}
        >
          {showPincodeInput && index === arr.length - 1 ? (
            <Input
              type="text"
              value={tempPincode}
              onChange={handleChange}
              onKeyDown={handleEnter}
              placeholder="Enter new zipcode"
            />
          ) : (
            <span>{pincode.toString().padStart(6, "0")}</span>
          )}
          {editable && !(showPincodeInput && index === arr.length - 1) && (
            <img
              src={deleteIcon}
              onClick={createHandleDelete(index)}
              alt="delete icon"
            />
          )}
        </div>
      ))}
      {editable &&
        (!showPincodeInput ? (
          <Fragment>
            <button className="add-btn-dotted" onClick={handleAddPincode}>
              + Add Zipcode
            </button>
            <button
              className="delete-group-btn"
              onClick={handleShowDeletePopup}
            >
              Delete Group
            </button>
          </Fragment>
        ) : (
          <Fragment>
            <button
              className="button primary add-pincode-btn"
              onClick={handleSave}
            >
              + Add
            </button>
            <button className="cancel-add-pin-btn" onClick={handleCancel}>
              Cancel
            </button>
          </Fragment>
        ))}
      {showDeletePopup && (
        <Dialog
          show={showDeletePopup}
          className="delivery-group-delete-modal"
          title={getMessage("extensions.deliveryArea.delete.title")}
          close={() => setShowDeletePopup(false)}
          information={getMessage("extensions.deliveryArea.delete.warning")}
          closeText="Cancel"
          okText="Delete"
          onOk={hanldeDeleteZone}
        />
      )}
    </Fragment>
  );
};

const PinCodesDisplay = (props) => {
  const { pincodeGroups } = props;
  const pincodeDisplayRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const [isContentOverflown, setIsContentOverflown] = useState(false);
  const [containerHeight, setContainerHeight] = useState();

  useEffect(() => {
    if (pincodeDisplayRef) {
      const domEl = pincodeDisplayRef.current;
      const containerHeight = domEl.clientHeight;
      const contentHeight = domEl.querySelector(
        ".pincode-group-list-content"
      ).offsetHeight;
      if (contentHeight > containerHeight) {
        setIsContentOverflown(true);
        setContainerHeight(contentHeight + 10); // Adding 10 to match margins
      }
    }
  }, [pincodeGroups]);

  const toggleExpand = (e) => {
    e && e.preventDefault && e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <div className="pincode-display-table">
      <div
        className={"pincode-group-list" + (expanded ? " expanded" : "")}
        style={expanded ? { maxHeight: containerHeight + "px" } : {}}
        ref={pincodeDisplayRef}
      >
        <div className="pincode-group-list-content">
          {pincodeGroups.map((group, index) => {
            return (
              <div
                className="pincode-group-wrapper"
                key={`pincode-group-${index}`}
              >
                <div className="pincode-group-header">
                  <span>
                    {group.name
                      ? group.name
                      : getMessage("extensions.deliveryArea.zone.unnamed")}
                  </span>
                  <span>{`${getCurrency().symbol} ${
                    group.area.deliveryFee
                  }`}</span>
                </div>
                <div className="pincode-group-subheader">
                  <span>
                    {getSingularPluralForm(
                      group.area.pincodes?.length,
                      "zipcode"
                    )}
                  </span>
                  <span>
                    {getMessage("extensions.deliveryArea.deliveryFee")}
                  </span>
                </div>
                <div className="pincode-group">
                  <PinCodeList pincodes={group.area.pincodes} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {isContentOverflown && (
        <button className="toggle-expand" onClick={toggleExpand}>
          Show {expanded ? "less" : "more"}
        </button>
      )}
    </div>
  );
};

const AddForm = ({ value = {}, onChange, onDeleteZone, setNewLocation }) => {
  const [continuedAddition, setContinuedAddition] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleChange = (newValue) => {
    onChange({ ...value, ...newValue });
  };

  const handleDeletePincode = (index) => {
    const newPincodes = value.pincodes?.filter((_, i) => i !== index);
    handleChange({ pincodes: newPincodes });
  };

  const handleSubmit = (e) => {
    e && e.preventDefault();
    if (!value.storeId) {
      setShowError(true);
    } else {
      setContinuedAddition(true);
    }
  };

  const handleAddPincode = (pincode) => {
    const newPincode = { pincode };
    let oldPincodes;

    if (pincode) {
      oldPincodes = value.pincodes;
      oldPincodes = oldPincodes?.slice(0, oldPincodes?.length - 1);
    } else {
      oldPincodes = value.pincodes?.filter(({ pincode }) => Boolean(pincode));
    }

    const newPincodes = oldPincodes?.concat(newPincode);

    handleChange({ pincodes: newPincodes });
  };

  useEffect(() => {
    if (continuedAddition && value.pincodes) {
      getLatlngFromPincode(value.pincodes).then((latlng) =>
        setNewLocation(latlng)
      );
    }

    if (value.pincodes && !value.pincodes?.length) {
      setContinuedAddition(false);
    }
  }, [continuedAddition, value.pincodes, setNewLocation]);

  const allStores = getStores()?.filter((store) => store.hasDeliveryHub) || [];
  const storeOptions = [
    ...allStores.map((store) => ({ text: store.name, value: store.id })),
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Select
        placeholder={"Select store"}
        name="store"
        className="del-area-store-selector"
        options={storeOptions}
        value={value.storeId}
        onChange={(storeId) => {
          setShowError(false);
          handleChange({ storeId: Number(storeId) });
        }}
      />
      {showError && (
        <small className="store-select-error">
          {getMessage("product.selectStore")}
        </small>
      )}
      <Input
        placeholder="Zone name"
        name="zone-name"
        type="text"
        value={value.name}
        onChange={(name) => handleChange({ name })}
      />
      <DeliveryFeeFormulaForm value={value} onChange={onChange} />
      {!continuedAddition ? (
        <Fragment>
          <Input
            placeholder={`Add zipcode`}
            className="delivery-fee edit-section"
            name="pincodes"
            type="text"
            onChange={(pincode) => handleChange({ pincodes: [{ pincode }] })}
            value={value.pincodes && value.pincodes[0]?.pincode}
            required
          />
          <button className="button primary add-submit-btn" type="submit">
            + Add Group
          </button>
        </Fragment>
      ) : (
        <div className="pincode-list">
          <span className="section-title">Zip codes</span>
          <PinCodeList
            pincodes={value.pincodes}
            onDelete={handleDeletePincode}
            onAddPincode={handleAddPincode}
            onDeleteZone={onDeleteZone}
            editable
          />
        </div>
      )}
    </form>
  );
};

const EditForm = ({
  value = {},
  onChange,
  pincodeGroup,
  action,
  onDeleteZone,
  setNewLocation,
}) => {
  const [selectedGroup, setSelectedGroup] = useState();
  const groups =
    pincodeGroup &&
    pincodeGroup.map(({ name, id }) => ({
      text: name || getMessage("extensions.deliveryArea.zone.unnamed"),
      value: id,
    }));
  const isEditable = action === "add" || selectedGroup;

  const handleSelectGroup = (groupId) => {
    const groupData = pincodeGroup.filter(({ id }) => id === Number(groupId));
    setSelectedGroup(groupId);
    onChange(groupData[0], true);
  };

  const handleChange = (newValue, replace) => {
    if (replace) {
      onChange({ ...newValue });
    } else {
      onChange({ ...value, ...newValue });
    }
  };

  const handleAddPincode = (pincode) => {
    const newPincode = { pincode };
    let oldPincodes;

    if (pincode) {
      oldPincodes = value.area.pincodes;
      oldPincodes = oldPincodes?.slice(0, oldPincodes?.length - 1);
    } else {
      oldPincodes = value.area.pincodes?.filter(({ pincode }) =>
        Boolean(pincode)
      );
    }

    const newPincodes = oldPincodes?.concat(newPincode);

    handleChange({
      area: {
        ...value.area,
        pincodes: newPincodes,
      },
    });
  };

  const handleDeletePincode = (index) => {
    const newPincodes = value.area.pincodes?.filter((_, i) => i !== index);
    handleChange({
      area: {
        ...value.area,
        pincodes: newPincodes,
      },
    });
  };

  useEffect(() => {
    if (value.area?.pincodes) {
      getLatlngFromPincode(value.area.pincodes).then((latlng) =>
        setNewLocation(latlng)
      );
    }
  }, [value.area, setNewLocation]);

  return (
    <Fragment>
      {action === "edit" && (
        <Select
          name="group-selector"
          placeholder={getMessage("Select Group")}
          options={groups}
          value={selectedGroup}
          onChange={handleSelectGroup}
        />
      )}
      <Input
        placeholder="Zone name"
        name="zone-name"
        type="text"
        value={value.name}
        onChange={(name) => handleChange({ name })}
        readOnly={!isEditable}
      />
      <DeliveryFeeFormulaForm value={value} onChange={onChange} />
      {isEditable && (
        <div className="pincode-list">
          <span className="section-title">Zip codes</span>
          <PinCodeList
            pincodes={value.area?.pincodes}
            onDelete={handleDeletePincode}
            editable
            onAddPincode={handleAddPincode}
            onDeleteZone={onDeleteZone}
          />
        </div>
      )}
    </Fragment>
  );
};
export default class PincodeFromComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      data: {},
    };
    this.handlePincodeGroupAddEdit = this.handlePincodeGroupAddEdit.bind(this);
    this.toggleAction = this.toggleAction.bind(this);
    this.handleToggleExpand = this.handleToggleExpand.bind(this);
    this.handleDeleteZone = this.handleDeleteZone.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.setError = this.setError.bind(this);
  }

  handlePincodeGroupAddEdit() {
    const { action, data } = this.state;
    const { storeId, fetchAndUpdate, toggleStoreError, setNewLocation } =
      this.props;

    if (!storeId) {
      toggleStoreError();
      return;
    }

    // unseting the existing error by passing empty param
    this.setError();

    let params, requestMethod, url;

    if (action === "add") {
      url = "/logistics-service/delivery-area";
      requestMethod = "post";
      params = { ...data, configType: "PINCODE" };
    } else if (action === "edit") {
      url = `/logistics-service/delivery-area/${data.id}`;
      requestMethod = "put";
      params = {
        configType: data.configType,
        name: data.name,
        pincodes: data.area.pincodes,
        deliveryFee: data?.deliveryFee,
        lowerWeightLimit: data?.lowerWeightLimit,
        ratePerWeightUnit: data?.ratePerWeightUnit,
        chargePercentage: data.chargePercentage,
      };
    }
    params.storeId = Number(storeId);

    const api = new API({ url });
    api[requestMethod](params)
      .then(() => fetchAndUpdate(storeId))
      .then(() => {
        this.setAction();
        this.handleDataChange({});
        setNewLocation([]);
      })
      .catch((error) => {
        this.setError(error.message);
        console.error(error);
      });
  }

  toggleAction(nextAction) {
    return (e) => {
      e && e.preventDefault && e.preventDefault();
      const { action } = this.state;
      if (action === nextAction) {
        this.setAction(undefined);
      } else {
        this.setAction(nextAction);
      }
    };
  }

  setAction(action) {
    this.setState({ action });
  }

  setError(error) {
    this.setState({ error });
  }

  handleDataChange(data) {
    this.setState({ data });
  }

  handleToggleExpand() {
    this.props.handleOpenClose("pincode");
    this.setAction(undefined);
    this.setState({ data: {} });
    this.props.setNewLocation([]);
  }

  handleDeleteZone() {
    const { action, data } = this.state;
    const { storeId, fetchAndUpdate, setNewLocation } = this.props;

    if (action === "add") {
      this.setState({ data: {} });
      this.setAction(undefined);
    } else if (action === "edit") {
      const api = new API({
        url: `/logistics-service/delivery-area/${data.id}`,
      });
      api
        .delete({ id: data.id })
        .then(() => fetchAndUpdate(storeId))
        .then(() => {
          this.setAction(undefined);
          this.setState({ data: {} });
          setNewLocation([]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleCancel() {
    this.setError();
    this.setAction();
    this.handleDataChange({});
    this.props.setNewLocation([]);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.active !== this.props.active && !this.props.active) {
      this.handleCancel();
    }

    if (Boolean(prevState.action) ^ Boolean(this.state.action)) {
      this.props.changeEditMode();
    }
  }

  render() {
    const { action, data, error } = this.state;
    const { active, pincodeArea = [], setNewLocation, editing } = this.props;
    const hasPincodesSet = Boolean(pincodeArea && pincodeArea.length);

    const FormComp = action === "add" ? AddForm : EditForm;
    const hidden = editing && !action;
    return (
      <div
        className={`pincode-form zones-subsection ${
          active ? "" : "collapsed-section"
        } ${hidden ? "hidden" : ""} ${action ? "edit-mode" : ""}`}
      >
        <span onClick={this.handleToggleExpand} className="section-title">
          {getMessage("extensions.deliveryArea.pincodes.heading")}
        </span>
        {!active && (
          <span className="section-subtitle">
            {hasPincodesSet
              ? `${getSingularPluralForm(pincodeArea.length, "zone")} covered`
              : "No zone defined"}
          </span>
        )}
        <span onClick={this.handleToggleExpand} className="open-close-icon">
          {active ? "\u2013" : "+"}
        </span>
        {!(hasPincodesSet || action) ? (
          active && (
            <div className="zones-content empty">
              <img src={emptyIcon} alt="no-pincode" className="empty-section" />
              <button
                onClick={this.toggleAction("add")}
                className="button primary"
              >
                + Add New Zipcode
              </button>
            </div>
          )
        ) : (
          <div className="zones-content">
            {active && !action && (
              <div className="header-action">
                <button
                  className={
                    "header-action-btn" + (action === "edit" ? " active" : "")
                  }
                  onClick={this.toggleAction("edit")}
                >
                  Edit
                </button>
                <button
                  className={
                    "header-action-btn" + (action === "add" ? " active" : "")
                  }
                  onClick={this.toggleAction("add")}
                >
                  Add New
                </button>
              </div>
            )}
            {action && (
              <FormComp
                value={data}
                action={action}
                onChange={this.handleDataChange}
                pincodeGroup={pincodeArea}
                onDeleteZone={this.handleDeleteZone}
                setNewLocation={setNewLocation}
              />
            )}

            {!action && active && pincodeArea && pincodeArea.length > 0 && (
              <PinCodesDisplay
                pincodeGroups={pincodeArea}
                handlePincodeDelete={(val) => val}
              />
            )}
          </div>
        )}
        {action && (
          <ActionButtons
            onSubmit={this.handlePincodeGroupAddEdit}
            onCancel={this.handleCancel}
            error={error}
            disabled={!(data.area || data.name)}
            setError={this.setError}
          />
        )}
      </div>
    );
  }
}
