import React from "react";
import {
  BaseForm,
  Input,
  Textarea,
  VALIDATION_TYPES,
} from "../../../../../components/Form";
import { Dialog } from "../../../../../components/Popup";
import API from "../../../../../lib/api";
import { getMessage } from "../../../../../lib/translator";

export default class AnalyticsForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.showSuccessDialog = false;
    this.state.showErrorDialog = false;
    this.state.formError = "";
    this.closeDialogs = this.closeDialogs.bind(this);
  }

  handleSubmit(data) {
    const api = new API({ url: `/config-service/config` });

    let params = {
      analytics: data.analytics,
    };

    return api
      .post(params)
      .then(
        (response) => {
          this.setState({ formError: "", showSuccessDialog: true });
        },
        (error) => {
          this.setState({ formError: error.message, showErrorDialog: true });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleSubmit(data).then(() => {
      this.setState({ submitting: false });
    });
  }

  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    });
  }
  render() {
    const { Form } = this.components;
    const { SubmitButton, CancelButton } = this.buttons;
    return (
      <div>
        <h1 className="title">{getMessage("extensions.analytics.heading")}</h1>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("extensions.analytics.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("extensions.analytics.save.success.closeText")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage("extensions.analytics.save.error.title")}
          close={this.closeDialogs}
          closeText={getMessage("extensions.analytics.save.error.closeText")}
        />
        <Form>
          <Input
            label={getMessage("extensions.analytics.gaAccount.heading")}
            placeholder={getMessage(
              "extensions.analytics.gaAccount.placeholder"
            )}
            name="gaAccount"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["analytics", "gaAccount"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              patternMismatch: getMessage("analytics.invalidTrackingId"),
            }}
          >
            {getMessage("extensions.analytics.gaAccount.description")}
          </Input>
          <Textarea
            label={getMessage("extensions.analytics.conversionTag.heading")}
            placeholder={getMessage(
              "extensions.analytics.conversionTag.placeholder"
            )}
            name="conversionTag"
            {...this.generateStateMappers({
              stateKeys: ["analytics", "conversionTag"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          >
            {getMessage("extensions.analytics.conversionTag.description")}
          </Textarea>
          <SubmitButton>Submit</SubmitButton>
          <CancelButton>Cancel</CancelButton>
        </Form>
      </div>
    );
  }
}
