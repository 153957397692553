import React from "react";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { getMessage } from "../../../lib/translator";
import VehicleForm from "./Form";
import VehicleVendorsFilter from "./Filter";
import { Link } from "react-router-dom";
import Toggle from "../../../components/Form/Inputs/Toggle";

import "./style.css";
import emptyIcon from "../Vehicles/vehicles-empty.svg";

/**
 * defines the state for the scenario where no vehicle vendor is added.
 */
const emptyState = (props) => {
  return {
    icon: emptyIcon,
    message: getMessage("vehicles.emptyState.helperText"),
    actions: ({ apiParams, onAction }) => (
      <div className="flex">
        <Link to="/logistics/vehicles">
          <button className="primary button vehicle-btn">
            + {getMessage("vehicles.heading")}
          </button>
        </Link>
        <button
          className="button"
          onClick={() => {
            onAction(TABLE_ACTIONS.ADD);
          }}
        >
          + {getMessage("vehicles.vendor.addButton")}
        </button>
      </div>
    ),
  };
};

/**
 * defines the properties with which the header and the rows
 * of the table will be rendered.
 */
const tableProperties = {
  headers: [
    getMessage("vehicles.vendor.heading.name"),
    getMessage("vehicles.vendor.heading.status"),
    getMessage("vehicles.vendor.heading.action"),
  ],
  row: ({ id, name, status, onAction }) => (
    <Row>
      <Cell>
        <div className="vehicle-vendor-name">{name}</div>
      </Cell>
      <Cell>
        <div className="vehicle-vendor-status">{status}</div>
        <Toggle
          className={`${status} vehicle-vendor-enable-mobileview`}
          name={status}
          value={status === "ENABLED"}
          icons={false}
          onChange={() => {
            onAction(
              TABLE_ACTIONS.UPDATE,
              { id },
              { status: status === "ENABLED" ? "DISABLED" : "ENABLED" }
            );
          }}
        />
      </Cell>
      <Cell>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          {status && status === "ENABLED" ? (
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.UPDATE, { id }, { status: "DISABLED" });
              }}
            >
              {getMessage("vehicles.disable.text")}
            </DropDownItem>
          ) : (
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.UPDATE, { id }, { status: "ENABLED" });
              }}
            >
              {getMessage("vehicles.enable.text")}
            </DropDownItem>
          )}
        </DropDown>
      </Cell>
    </Row>
  ),
};

const VehiclesInterface = (props) => (
  <ListingPage
    title={getMessage("vehicles.vendor.heading")}
    api={{
      url: "/logistics-service/vehicle-vendor",
      transform: (response) => response.data.vehiclevendor,
    }}
    menu={props.menu}
    addHeading={getMessage("vehicles.form.vendor.addButton")}
    className="vehicle-vendor-page"
    emptyState={emptyState(props)}
    tableProperties={tableProperties}
    headerActions={({ onAction }) => (
      <React.Fragment>
        <Link to="/logistics/vehicles">
          <button className="primary button vehicle-btn">
            + {getMessage("vehicles.heading")}
          </button>
        </Link>
        <button
          className="button"
          onClick={() => {
            onAction(TABLE_ACTIONS.ADD);
          }}
        >
          +{" "}
          <span className="text">
            {getMessage("vehicles.vendor.addButton")}
          </span>
        </button>
      </React.Fragment>
    )}
    form={{
      component: VehicleForm,
      transformSubmit: (formData) => {
        let modifiedData = Object.assign({}, formData);
        return modifiedData;
      },
    }}
    filters={{
      component: VehicleVendorsFilter,
      transformSubmit: (formData) => {
        let modifiedData = { ...formData };
        return modifiedData;
      },
    }}
  />
);

export default VehiclesInterface;
