import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Table from "./Table";

class OrderPackageConfigRouter extends Component {
  render() {
    return <Table {...this.props} url="/settings" />;
  }
}

export default withRouter(({ match, ...props }) => (
  <OrderPackageConfigRouter {...props} />
));
