import React, { Component } from "react";
import Loader from "../../../../../../components/Loader";
import { AreaChart, Area, Tooltip, XAxis } from "recharts";

export default class SimpleAreaChart extends Component {
  render() {
    if (this.props.chartData) {
      if (this.props.chartData.length > 0) {
        return (
          <div className="simple-area-chart">
            <AreaChart
              width={this.props.width}
              height={this.props.height}
              data={this.props.chartData}
              margin={{ left: 5, right: 5 }}
            >
              <XAxis dataKey="name" />
              <Tooltip />
              <Area
                x={0}
                y={50}
                type="monotone"
                dataKey={this.props.type}
                stroke={
                  this.props.chartColor ? this.props.chartColor : "#8884d8"
                }
                fill={this.props.chartColor ? this.props.chartColor : "#8884d8"}
              />
            </AreaChart>
          </div>
        );
      } else {
        return (
          <div className="nodata-warning">
            <small>No data available</small>
          </div>
        );
      }
    } else {
      return <Loader />;
    }
  }
}
