import React, { Component } from "react";
import Table, { Header, Row, Cell } from "../../../../components/Table";
import Image from "../../../../components/Image";
import { Input, Select } from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";

class ItemTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entityType: props.entityType,
    };
    this.imageKey = {
      product: "images",
      category: "image",
      brand: "logo",
    };
    this.onDiscountTypeChange = this.onDiscountTypeChange.bind(this);
  }

  onDiscountTypeChange(type, stateTypeKeys, stateValueKeys) {
    let { _this } = this.props;
    _this.updateState(stateTypeKeys, type);
    _this.updateState(stateValueKeys, "");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entityType !== this.props.entityType) {
      this.setState({
        entityType: this.props.entityType,
      });
    }
  }
  render() {
    const {
      comboDiscount,
      entityType,
      items,
      discountTypes,
      _this,
      buyAny,
      buy,
      buyKey,
      buyQty,
      hasOfferStarted,
      nestedKeys,
    } = this.props;
    let changeKey = "product";
    if (
      _this &&
      _this.state &&
      _this.state.values &&
      _this.state.values.type === "BMINXGFG" &&
      !buy &&
      this.props.changeKey &&
      this.props.nestedKeys
    ) {
      changeKey = this.props.changeKey;
    }

    let isChangeKeyArray = Array.isArray(changeKey);

    return (
      <Table>
        <Header>
          <Cell>{getMessage("offer.image")}</Cell>
          <Cell className="name">{getMessage("offer.name")}</Cell>
          {((!buyAny && !buy) || buyQty) && (
            <Cell>{getMessage("offer.quantity")}</Cell>
          )}
          {!comboDiscount && !buyAny && (
            <Cell>{getMessage("offer.discounttype")}</Cell>
          )}
          {!comboDiscount && !buyAny && (
            <Cell>{getMessage("offer.discountvalue")}</Cell>
          )}
          {!hasOfferStarted && <Cell>{getMessage("offer.delete")}</Cell>}
        </Header>
        {items &&
          items.map((item, i) => (
            <Row key={`row-${i}`}>
              <Cell>
                <Image
                  size="sm"
                  src={
                    entityType === "product"
                      ? item.images && item.images[0]
                      : item[this.imageKey[entityType]]
                  }
                />
              </Cell>
              <Cell className="name">
                {entityType === "product" ? (
                  item.brand && item.brand.name ? (
                    <span className="bold">
                      {item.brand.name} {item.fullName || item.name}
                    </span>
                  ) : (
                    item.fullName || item.name
                  )
                ) : (
                  item.fullName || item.name
                )}
              </Cell>
              {((!buyAny && !buy) || buyQty) && (
                <Cell className="qty-cell">
                  <Input
                    type="number"
                    key={`qty-${entityType}-${i}`}
                    name={`qty-${entityType}-${i}`}
                    readOnly={hasOfferStarted}
                    placeholder={getMessage("offer.quantity")}
                    min={1}
                    required
                    {..._this.generateStateMappers({
                      stateKeys: buy
                        ? [`${buyKey}${changeKey}`, i, "q"]
                        : isChangeKeyArray
                        ? [...changeKey, i, "q"]
                        : [changeKey, i, "q"],
                      loseEmphasisOnFill: true,
                    })}
                  />
                </Cell>
              )}
              {!comboDiscount && !buyAny && (
                <Cell className="qty-cell">
                  <Select
                    name={`discount-type-${entityType}-${i}`}
                    key={`discount-type-${entityType}-${i}`}
                    placeholder={getMessage("offer.select.placeholder")}
                    disabled={hasOfferStarted}
                    options={discountTypes}
                    required
                    {..._this.generateStateMappers({
                      stateKeys: buy
                        ? [`${buyKey}${changeKey}`, i, "t"]
                        : isChangeKeyArray
                        ? [...changeKey, i, "t"]
                        : [changeKey, i, "t"],
                      loseEmphasisOnFill: true,
                    })}
                    onChange={(type) =>
                      this.onDiscountTypeChange(
                        type,
                        buy
                          ? [`${buyKey}${changeKey}`, i, "t"]
                          : isChangeKeyArray
                          ? [...changeKey, i, "t"]
                          : [changeKey, i, "t"],
                        buy
                          ? [`${buyKey}${changeKey}`, i, "v"]
                          : isChangeKeyArray
                          ? [...changeKey, i, "v"]
                          : [changeKey, i, "v"]
                      )
                    }
                  />
                </Cell>
              )}
              {!comboDiscount && !buyAny && (
                <Cell className="qty-cell">
                  <Input
                    type="number"
                    readOnly={
                      hasOfferStarted ||
                      _this.getState(
                        buy
                          ? [`${buyKey}${changeKey}`, i, "t"]
                          : isChangeKeyArray
                          ? [...changeKey, i, "t"]
                          : [changeKey, i, "t"]
                      ) === "FREE"
                    }
                    name={`discount-value-${entityType}-${i}`}
                    key={`discount-value-${entityType}-${i}`}
                    step={0.01}
                    min={0}
                    required={
                      !(
                        _this.getState(
                          buy
                            ? [`${buyKey}${changeKey}`, i, "t"]
                            : isChangeKeyArray
                            ? [...changeKey, i, "t"]
                            : [changeKey, i, "t"]
                        ) === "FREE"
                      )
                    }
                    max={
                      _this.getState(
                        buy
                          ? [`${buyKey}${changeKey}`, i, "t"]
                          : isChangeKeyArray
                          ? [...changeKey, i, "t"]
                          : [changeKey, i, "t"]
                      ) === "PERCENT_OFF"
                        ? 100
                        : Number.MAX_SAFE_INTEGER
                    }
                    placeholder={getMessage("offer.discountvalue.placeholder")}
                    {..._this.generateStateMappers({
                      stateKeys: buy
                        ? [`${buyKey}${changeKey}`, i, "v"]
                        : isChangeKeyArray
                        ? [...changeKey, i, "v"]
                        : [changeKey, i, "v"],
                      loseEmphasisOnFill: true,
                    })}
                    onChange={(val) =>
                      _this.updateState(
                        buy
                          ? [`${buyKey}${changeKey}`, i, "v"]
                          : isChangeKeyArray
                          ? [...changeKey, i, "v"]
                          : [changeKey, i, "v"],
                        val === "" || val > 0 ? val : 0
                      )
                    }
                  />
                </Cell>
              )}
              {!hasOfferStarted && (
                <Cell>
                  {
                    <button
                      className="delete-button button"
                      type="button"
                      onClick={(e) =>
                        entityType === "product"
                          ? buy
                            ? _this.deleteBuyItem(i)
                            : _this.deleteItem(i, nestedKeys)
                          : (entityType === "category" ||
                              entityType === "brand") &&
                            _this.deleteCategoryBrandsItem(
                              i,
                              _this.state.values.type === "BMINXGFG" ||
                                _this.state.values.type === "BMIN" ||
                                _this.state.values.type === "SFEGSD"
                                ? entityType
                                : "product"
                            )
                      }
                    />
                  }
                </Cell>
              )}
            </Row>
          ))}
      </Table>
    );
  }
}

export default ItemTable;
