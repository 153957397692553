import React from "react";
import {
  BaseForm,
  Select,
  Input,
  Radio,
  SingleDatePicker,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import { getStores } from "../../../../lib/auth";
import {
  getDisplaySlot,
  sortSlots,
  toTitleCase,
} from "../../../../lib/commonlyused";
import deleteIcon from "../../../catalogue/Categories/delete.svg";
import { isExtensionEnabled } from "../../../../lib/auth";

function cutoffTimeOptionsGenerator() {
  let options = [];
  for (let i = 0; i <= 27; i++) {
    options.push({
      text: Number(i).toFixed(1) + " h",
      value: `${i > 9 ? i : "0" + i}:00:00`,
    });
    options.push({
      text: Number(i) + 0.5 + " h",
      value: `${i > 9 ? i : "0" + i}:30:00`,
    });
  }
  options.pop();
  return options;
}

const capacityOptions = [
  {
    text: getMessage("Day"),
    value: "DAY",
  },
  {
    text: getMessage("Date"),
    value: "DATE",
  },
];

class CapacityPlanningForm extends BaseForm {
  constructor(props) {
    super(props);
    const deliverySlots =
      props.options.deliverySlots && sortSlots(props.options.deliverySlots);
    this.deliverySlots =
      deliverySlots &&
      deliverySlots
        .filter((slot) => slot.type !== "ASAP")
        .map((slot) => {
          return {
            text: getDisplaySlot(slot),
            value: slot.id,
          };
        });
    const pickupSlots =
      props.options.pickupSlots && sortSlots(props.options.pickupSlots);
    this.pickupSlots =
      pickupSlots &&
      pickupSlots
        .filter((slot) => slot.type !== "ASAP")
        .map((slot) => {
          return {
            text: getDisplaySlot(slot),
            value: slot.id,
          };
        });
    this.zones =
      props.options.zones &&
      props.options.zones.map((zone) => {
        return {
          text: zone.name || zone.id,
          value: zone.id,
        };
      });

    // TODO: change this to pickup zones
    this.stores =
      getStores() &&
      getStores().map((store) => {
        return {
          text: store.name,
          value: store.id,
        };
      });

    if (!props.value) {
      this.state = {
        values: {},
      };
      this.state.values.capacityType = "DAY";
      this.state.values.orderType = "DELIVERY";
    }

    if (props.value && props.value.date) {
      this.state.values.capacityType = "DATE";
    } else if (props.value && props.value.day) {
      this.state.values.capacityType = "DAY";
    }
    if (props.value && props.value.day) {
      this.state.values.day = toTitleCase(props.value.day);
    }
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton, CancelButton } = this.buttons;
    const { type, daysOfWeek } = this.props.options;
    const { values } = this.state || {};
    const { orderType, capacityType } = values;
    const isPickup = orderType === "PICKUP";
    const { props } = this;
    const readOnly = props.method === "edit";
    return (
      <Form className="capacity-planning-form">
        <div className="form-fields grid">
          <Select
            name="type"
            required
            label={getMessage("type")}
            placeholder={getMessage("type.placeholder")}
            options={type}
            {...this.generateStateMappers({
              stateKeys: ["orderType"],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Radio
            name="capacityFor"
            label={getMessage("rule.for")}
            options={capacityOptions}
            {...this.generateStateMappers({
              stateKeys: ["capacityType"],
              loseEmphasisOnFill: true,
              defaultValue: "DAY",
            })}
            readOnly={readOnly}
          />
          {capacityType === "DATE" ? (
            <SingleDatePicker
              name="date"
              readOnly={readOnly}
              label={getMessage("Date")}
              placeholder={getMessage("Date")}
              enableToday
              {...this.generateStateMappers({
                stateKeys: ["date"],
                loseEmphasisOnFill: true,
              })}
            />
          ) : (
            <Select
              name="day"
              options={daysOfWeek}
              label={getMessage("Day")}
              readOnly={readOnly}
              placeholder={getMessage("day.placeholder")}
              {...this.generateStateMappers({
                stateKeys: ["day"],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {isExtensionEnabled("DeliveryAreaSupport") && (
            <Select
              name="zones"
              type="number"
              label={getMessage("zone")}
              placeholder={getMessage("zones.placeholder")}
              options={this.zones} // TODO: handle pickup zones different
              {...this.generateStateMappers({
                stateKeys: ["zoneId"],
                loseEmphasisOnFill: true,
              })}
              readOnly={readOnly}
            />
          )}
          <Select
            name="slots"
            type="number"
            required
            readOnly={readOnly}
            label={getMessage("slot")}
            placeholder={getMessage("slots.placeholder")}
            options={isPickup ? this.pickupSlots : this.deliverySlots}
            {...this.generateStateMappers({
              stateKeys: ["slotId"],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            name="cutoff-time"
            required
            readOnly={readOnly}
            label={getMessage("cutoff.time")}
            placeholder={getMessage("cutoff.time.placeholder")}
            options={cutoffTimeOptionsGenerator()}
            {...this.generateStateMappers({
              stateKeys: ["cutOffTime"],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            name="capacity"
            label={getMessage("capacity")}
            placeholder={getMessage("capacity.placeholder")}
            type="number"
            min={0}
            {...this.generateStateMappers({
              stateKeys: ["capacity"],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <div className="form-actions">
          {props.method && props.method === "edit" && (
            <button
              className="delete-button"
              type="button"
              onClick={() => this.props.onDelete()}
            >
              <img src={deleteIcon} alt="delete" />
              <span>{getMessage("category.form.deleteText")}</span>
            </button>
          )}
          <CancelButton>{getMessage("cancel")}</CancelButton>
          <SubmitButton>{getMessage("save")}</SubmitButton>
        </div>
      </Form>
    );
  }
}

export default CapacityPlanningForm;

export { cutoffTimeOptionsGenerator };
