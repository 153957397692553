import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
} from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";
import API from "../../../../../lib/api";
import HidePassword from "../../../../../components/Form/icons/HidePassword";
import ShowPassword from "../../../../../components/Form/icons/ShowPassword";
import "./style.css";

class ResetPassword extends BaseForm {
  constructor(props) {
    super(props);
    this.state.submitting = false;
    this.state.showNewPassword = false;
    this.state.showConfirmPassword = false;
  }

  onSubmit(formData) {
    this.setState({
      submitting: true,
    });
    let data = Object.assign({}, formData);
    const api = new API({
      url: `/account-service/employee/${this.props.id}/password`,
    });
    api
      .put(data)
      .then(
        (response) => this.props.onSuccess(),
        (error) => {
          this.setState({
            formError: error.message,
            submitting: false,
          });
          if (error.code === 401) {
            throw error;
          }
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  handleShowPassword = (e, key) => {
    e.preventDefault();
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const { showNewPassword, showConfirmPassword } = this.state;
    const NewPasswordIcon = showNewPassword ? ShowPassword : HidePassword;
    const ConfirmPasswordIcon = showConfirmPassword
      ? ShowPassword
      : HidePassword;

    return (
      <Form>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        <div className="password-wrp">
          <Input
            label={getMessage("shifts.newPassword")}
            placeholder={getMessage("shifts.newPassword.placeholder")}
            name="new-password"
            required
            pattern={this.getState(["confirmPassword"])}
            type={showNewPassword ? "text" : "password"}
            {...this.generateStateMappers({
              stateKeys: ["newPassword"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            validationStrings={{
              patternMismatch: getMessage("shifts.passwords.dont.match"),
            }}
          />
          <div
            className="password-view"
            onClick={(e) => this.handleShowPassword(e, "showNewPassword")}
          >
            <NewPasswordIcon />
          </div>
        </div>
        <div className="password-wrp">
          <Input
            readOnly={!this.getState(["newPassword"])}
            label={getMessage("shifts.confirmpassword")}
            placeholder={getMessage("shifts.confirmpassword.placeholder")}
            name="confirm-password"
            required
            pattern={this.getState(["newPassword"])}
            type={showConfirmPassword ? "text" : "password"}
            {...this.generateStateMappers({
              stateKeys: ["confirmPassword"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONCHANGE,
            })}
            validationStrings={{
              patternMismatch: getMessage("shifts.passwords.dont.match"),
            }}
          />
          <div
            className="password-view"
            onClick={(e) => this.handleShowPassword(e, "showConfirmPassword")}
          >
            <ConfirmPasswordIcon />
          </div>
        </div>
        <div className="form-action">
          <SubmitButton disabled={this.state.submitting}>
            {getMessage("shifts.form.submitText")}
          </SubmitButton>
          <button
            type="button"
            className="button"
            onClick={() => this.props.close()}
          >
            {getMessage("shifts.form.cancel")}
          </button>
        </div>
      </Form>
    );
  }
}

export default ResetPassword;
