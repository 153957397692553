import React, { Component } from "react";
import moment from "moment";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage/index";
import { getMessage } from "../../../../lib/translator";
import API from "../../../../lib/api";
import AnalyticDisplayWidget from "./analytic-display-widget";
import AnalyticPieChart from "./analytic-pie-chart";
import AnalyticBarChart from "./analytic-bar-chart";
import "./style.css";
import Loader from "../../../../components/Loader";
import { Dialog } from "../../../../components/Popup";
import checkHttpResponseCode from "../../../../utils/isApiCallSuccessfull";

function activeFor(startDate, expiryDate) {
  let momentExpiryDate = moment(expiryDate);
  let start = moment(startDate);
  if (!expiryDate || momentExpiryDate.isAfter(moment())) {
    let end = moment(new Date());
    return end.diff(start, "days");
  } else {
    let start = moment(startDate);
    return momentExpiryDate.diff(start, "days");
  }
}

function calDiscountToday(data) {
  let totalDiscount = 0;
  let today = new Date().toISOString().split("T")[0];
  if (data.hasOwnProperty(today)) {
    totalDiscount = data[today].totalDiscount;
  }
  return totalDiscount;
}

function calDiscountForWeek(data) {
  let totalDiscountForWeek = 0;
  for (let i = 0; i < 7; i++) {
    let date = moment(new Date());
    let dateFormat = date.subtract(i, "days").format("YYYY-MM-DD");
    if (data.hasOwnProperty(dateFormat)) {
      totalDiscountForWeek = data[dateFormat].totalDiscount;
    }
  }
  return totalDiscountForWeek;
}

function calPurchaseToday(data) {
  let totalPurchase = 0;
  let today = moment(new Date()).format("YYYY-MM-DD");
  if (data.hasOwnProperty(today)) {
    totalPurchase = data[today].totalOrderAmount;
  }

  return totalPurchase;
}

function calPurchaseForWeek(data) {
  let totalPurchaseForWeek = 0;
  for (let i = 0; i < 7; i++) {
    let date = moment(new Date());
    let dateFormat = date.subtract(i, "days").format("YYYY-MM-DD");
    if (data.hasOwnProperty(dateFormat)) {
      totalPurchaseForWeek = data[dateFormat].totalOrderAmount;
    }
  }
  return totalPurchaseForWeek;
}

export default class CouponAnalytics extends Component {
  constructor(props) {
    super(props);
    this.api = null;
    this.state = {
      data: null,
      showLoader: true,
      widgetChartData: null,
      apiError: "",
      barChartLoader: false,
      errorDialog: false,
      dateRange: {
        startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
    };
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.hideErrorDialog = this.hideErrorDialog.bind(this);
    this.showErrorDialog = this.showErrorDialog.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(dateRange) {
    this.setState({
      dateRange: dateRange,
    });
  }

  hideErrorDialog() {
    this.setState({
      errorDialog: false,
    });
  }
  showErrorDialog() {
    this.setState({
      errorDialog: true,
    });
  }

  onClickSubmit(params) {
    this.setState({
      barChartLoader: true,
    });
    this.api = new API({
      url: `/promo-service/coupon/${this.props.match.params.id}/analytics?startDate=${params.startDate}&endDate=${params.endDate}`,
    });
    this.api
      .get()
      .then(
        (response) => {
          this.setState({
            data: response.data,
            barChartLoader: false,
          });
        },
        (error) => {
          if (error.message === "cancelled") {
            return;
          }
          if (error.code === 401) {
            throw error;
          }
          if (error.code === 403) {
            throw error;
          }
          this.setState(
            {
              apiError: error.message,
              barChartLoader: false,
            },
            () => this.showErrorDialog()
          );
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidMount() {
    this.api = new API({
      url: `/promo-service/coupon/${this.props.match.params.id}/analytics`,
    });
    this.api
      .get()
      .then(
        (response) => {
          if (
            checkHttpResponseCode(response.code) &&
            response.status === "SUCCESS"
          ) {
            this.setState({
              data: response.data,
              showLoader: false,
            });
          }
        },
        (error) => {
          if (error.message === "cancelled") {
            return;
          }
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
          this.setState(
            {
              apiError: error.message,
              showLoader: false,
            },
            () => this.showErrorDialog()
          );
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
  }

  render() {
    const { menu } = this.props;
    let mainContent = <Loader />;
    if (this.state.data && this.state.data.usage && !this.state.showLoader) {
      let coupon = this.state.data.coupon;
      let usage = this.state.data.usage;
      let activeDays = activeFor(coupon.startDate, coupon.expiryDate);
      let discountToday = calDiscountToday(usage.dailyUsage);
      let discountForWeek = calDiscountForWeek(usage.dailyUsage);
      let purchaseToday = calPurchaseToday(usage.dailyUsage);
      let purchaseForWeek = calPurchaseForWeek(usage.dailyUsage);
      let distinctUsage = usage.distinctUsage;

      mainContent = (
        <div>
          <div className="display-widget">
            <AnalyticDisplayWidget
              count={usage.totalUsage}
              itemType={getMessage("coupon.table.heading")}
              bottomLeftData={distinctUsage}
              bottomLeftType={getMessage(
                "coupon.analytics.uniqueUsers.heading"
              )}
              bottomRightData={activeDays}
              bottomRightType={getMessage("coupon.analytics.activeFor.heading")}
              chartColor="#30b5fb"
              dailyusage={usage.dailyUsage}
              type="totalUsage"
            />
            <AnalyticDisplayWidget
              count={usage.totalDiscount}
              itemType={getMessage("coupon.analytics.totalDiscount.heading")}
              bottomLeftData={discountToday}
              bottomLeftType={getMessage("coupon.analytics.today.heading")}
              bottomRightData={discountForWeek}
              bottomRightType={getMessage("coupon.analytics.thisWeek.heading")}
              chartColor="#53ec94"
              dailyusage={usage.dailyUsage}
              type="totalDiscount"
            />
            <AnalyticDisplayWidget
              count={usage.totalOrderAmount}
              itemType={getMessage("coupon.analytics.totalPurchases.heading")}
              bottomLeftData={purchaseToday}
              bottomLeftType={getMessage("coupon.analytics.today.heading")}
              bottomRightData={purchaseForWeek}
              bottomRightType={getMessage("coupon.analytics.thisWeek.heading")}
              chartColor="#f89898"
              dailyusage={usage.dailyUsage}
              type="totalOrderAmount"
            />
          </div>
          <div className="charts">
            <div className="pie-charts">
              <div>
                <AnalyticPieChart
                  heading={getMessage(
                    "coupon.analytics.paymentDistribution.heading"
                  )}
                  colors={[
                    "#7bd49d",
                    "#80959d",
                    "#67cbff",
                    "#eb8181",
                    "red",
                    "yellow",
                    "violet",
                    "orange",
                    "purple",
                    "pink",
                    "blue",
                    "darkgreen",
                  ]}
                  chartData={usage.paymentDetails}
                />
              </div>
              <div>
                <AnalyticPieChart
                  heading={getMessage(
                    "coupon.analytics.deviceDistribution.heading"
                  )}
                  colors={[
                    "#7bd49d",
                    "#80959d",
                    "#67cbff",
                    "#eb8181",
                    "red",
                    "yellow",
                    "violet",
                    "orange",
                    "purple",
                    "pink",
                    "blue",
                    "darkgreen",
                  ]}
                  deviceToggle
                  chartData={usage.placedFrom}
                />{" "}
              </div>
            </div>
            <div className="bar-chart">
              <AnalyticBarChart
                heading={getMessage(
                  "coupon.analytics.dailyDistribution.heading"
                )}
                chartData={usage.dailyUsage}
                chartType="paymentDetails"
                colors={[
                  "#7bd49d",
                  "#80959d",
                  "#67cbff",
                  "#eb8181",
                  "red",
                  "yellow",
                  "violet",
                  "orange",
                  "purple",
                  "pink",
                  "blue",
                  "darkgreen",
                ]}
                onClickSubmit={this.onClickSubmit}
                barChartLoader={this.state.barChartLoader}
                index={1}
                {...this.props}
                onChange={this.onChange}
                startDate={this.state.dateRange.startDate}
                endDate={this.state.dateRange.endDate}
              />
              <AnalyticBarChart
                heading={getMessage(
                  "coupon.analytics.dailyDistribution.heading"
                )}
                chartData={usage.dailyUsage}
                chartType="placedFrom"
                colors={[
                  "#7bd49d",
                  "#80959d",
                  "#67cbff",
                  "#eb8181",
                  "red",
                  "yellow",
                  "violet",
                  "orange",
                  "purple",
                  "pink",
                  "blue",
                  "darkgreen",
                ]}
                onClickSubmit={this.onClickSubmit}
                barChartLoader={this.state.barChartLoader}
                index={2}
                {...this.props}
                onChange={this.onChange}
                startDate={this.state.dateRange.startDate}
                endDate={this.state.dateRange.endDate}
              />
            </div>
          </div>
          <Dialog
            show={this.state.errorDialog}
            information={this.state.apiError}
            close={() => {
              this.hideErrorDialog();
            }}
            closeText={getMessage("coupon.dialog.okText")}
          />
        </div>
      );
    }

    return (
      <AuthenticatedPage menu={menu} className="coupon-analytics">
        <h1 className="title">{getMessage("coupon.analytics.heading")}</h1>
        {mainContent}
      </AuthenticatedPage>
    );
  }
}
