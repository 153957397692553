const data = {
  "customerSupport.search.noCustomer": "No customer found",
  "customerSupport.search.noOrder": "No order found",
  "order.action.fileDispute": "File dispute",
  "dispute.submit": "Submit",
  "calls.action.success.title": "Success",
  "calls.close": "Okay",
  "calls.action.success.info": "Added Successfully",
  "calls.action.form.heading": "Add Reason & Summary",
  "calls.table.heading.type": "Type",
  "calls.table.heading.from": "From",
  "calls.table.heading.to": "To",
  "calls.table.heading.startTime": "Start Time",
  "calls.table.heading.reason": "Reason",
  "calls.table.heading.recording": "Recording",
  "calls.emptyState.text": "No Calls Found",
  "calls.form.reason.placeholder": "Enter Call Reason",
  "calls.form.summary.label": "Summary",
  "calls.form.summary.placeholder": "Enter call summary",
  "calls.form.submitText": "Save",
  "calls.form.cancel": "Cancel",
  "calls.filters.submitText": "Submit",
  "calls.filters.clearFiltersText": "Clear All",
  "calls.filters.phone.label": "Phone",
  "calls.filters.phone.placeholder": "Enter Phone number",
  "calls.filters.type.label": "Type",
  "calls.filters.status.label": "Status",
  "calls.filters.tag.label": "Tag",
  "calls.filters.tag.placeholder": "All",
  "calls.filters.employee.label": "Employee",
  "calls.filters.employee.placeholder": "Enter Name",
  "calls.filters.customer.label": "Customer",
  "calls.filters.customer.placeholder": "Enter Name",
  "calls.filters.type.label.all": "All",
  "calls.filters.status.label.all": "All",
  "calls.filters.type.option.inbound": "Inbound",
  "calls.filters.type.option.outbound": "Outbound",
  "calls.filters.status.option.picked": "Picked",
  "calls.filters.status.option.missed": "Missed",
  "calls.filters.tag.option.cs": "CS",
  "calls.filters.tag.option.store": "Store",
  "calls.filters.tag.option.others": "Others",
};

export default data;
