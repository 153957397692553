import React, { Component } from "react";
import { Droppable } from "react-beautiful-dnd";
import OrderContainer from "./OrderContainer";
import moment from "moment";
import clockIcon from "./clock-image.svg";
import NotReadyOrders from "../NotReadyOrders";

export default class TripContainer extends Component {
  areOrdersReady(orders) {
    return (
      orders.length &&
      orders.reduce(
        (ready, order) =>
          ready &&
          order.address.location &&
          order.address.location.lat &&
          order.address.location.long,
        true
      )
    );
  }
  render() {
    const {
      droppableId,
      orders,
      totalOrders,
      recommendedVehicle,
      startTime,
      endTime,
      zone,
    } = this.props;
    const readyOrders = orders.filter((order) => !order.ignoreForTrip);
    const { hubTrip } = zone || {};
    const isActiveHubTrip = hubTrip === true;
    return totalOrders ? (
      <Droppable droppableId={`trip-${droppableId}`}>
        {(provided, snapshot) => (
          <div
            className={`trip-container ${
              this.props.disabled ? "disabled" : ""
            }`.trim()}
          >
            <div className="trip-start-container">
              <button
                className="button primary trip-start-button"
                onClick={(e) => {
                  this.props.onStart &&
                    this.props.onStart(
                      readyOrders,
                      droppableId,
                      recommendedVehicle,
                      zone
                    );
                }}
                disabled={!this.areOrdersReady(orders)}
              >
                {isActiveHubTrip ? "Start Hub Trip" : "Start"}
              </button>
              {startTime &&
                endTime &&
                startTime !== "0001-01-01T00:00:00Z" &&
                endTime !== "0001-01-01T00:00:00Z" && (
                  <div className="recommended-time">
                    <img
                      className="recommended-clock-icon"
                      src={clockIcon}
                      alt="clock"
                    />
                    {moment(startTime).format("HH:mm") +
                      " - " +
                      moment(endTime).format("HH:mm")}
                  </div>
                )}
            </div>
            <div
              className={`trip-orders ${
                snapshot.isDraggingOver ? "dragging-over" : ""
              }`.trim()}
            >
              <div
                className="trip-orders-inner-container"
                ref={provided.innerRef}
              >
                {readyOrders.map((order, index) => (
                  <OrderContainer
                    key={order.id}
                    data={order}
                    draggableId={order.id}
                    index={index}
                    onClick={() =>
                      this.props.showDeliveryLocation(order.id, droppableId)
                    }
                  />
                ))}
                {provided.placeholder}
              </div>
            </div>
            <div>
              <NotReadyOrders
                droppableId={`${droppableId}`}
                orders={orders.filter((order) => order.ignoreForTrip === true)}
                totalOrders={orders.length}
                showDeliveryLocation={this.props.showDeliveryLocation}
              />
            </div>
          </div>
        )}
      </Droppable>
    ) : null;
  }
}
