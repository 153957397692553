import React, { Component } from "react";
import API from "../../../lib/api";
import ActivePickers from "./ActivePickers";
import IdlePickers from "./IdlePickers";
import TotalPickers from "./TotalPickers";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import Loader from "../../../components/Loader";
import { Link } from "react-router-dom";
import { getMessage } from "../../../lib/translator";
import emptyStateIcon from "./svg/picker-empty.svg";
import settingImage from "./svg/settings-inactive.svg";
import StoreSelector from "../../../containers/StoreSelector";
import { getStandradDateTime, utcToLocalTime } from "../../../lib/datetime";

import "./style.css";
import { get } from "../../../lib/storage";

const EmptyState = () => {
  return (
    <React.Fragment>
      <div className="pickers-empty-state">
        <img src={emptyStateIcon} alt="settings" />
        {getMessage("pickers.empty")}
      </div>
    </React.Fragment>
  );
};

class PickersToday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickerLoading: true,
      employeeLoading: true,
      Loading: true,
      storeId: get("store"),
      multiStores: [],
      employees: [],
      pickers: [],
    };
    this.changeStore = this.changeStore.bind(this);
  }

  changeStore(storeId) {
    this.setState({ storeId }, this.getEmployees);
  }

  updateStates(employees) {
    let pickerIds = null;
    if (employees.length) {
      pickerIds = employees.map((emp) => emp.id);
    }
    let apiURLParams = "";
    if (pickerIds) {
      apiURLParams += pickerIds.length ? "?" : "";
      let index = 0;
      for (; index < pickerIds.length - 1; index++) {
        apiURLParams += "pickerId=" + pickerIds[index] + "&";
      }
      apiURLParams += "pickerId=" + pickerIds[index];
    }
    this.pickerApi = new API({
      url: "/order-service/picker-activity" + apiURLParams,
    });

    this.pickerApi
      .get()
      .then((res) => {
        this.setState(
          {
            pickers: res.data,
            pickerLoading: false,
            multiStores: JSON.parse(window.localStorage.stores),
          },
          () => {
            this.setState({
              employees,
              employeeLoading: false,
              Loading: false,
            });
          }
        );
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getEmployees() {
    this.setState({ Loading: true });
    this.employeeApi = new API({ url: "/account-service/employee" });
    this.employeeApi
      .get({
        roleId: 1,
        paginated: "false",
        storeId: this.state.storeId,
      })
      .then((res) => {
        let employees = res.data.employee;
        if (Array.isArray(employees)) {
          employees.map((employee) => {
            if (employee?.attendance?.createdAt) {
              if (employee.attendance.createdAt.includes("T")) {
                const newDate = employee.attendance.createdAt.split(/[TZ]/);
                employee.attendance.createdAt = `${newDate[0]} ${newDate[1]}`;
              }
              employee.attendance.createdAt = getStandradDateTime(
                utcToLocalTime,
                employee.attendance.createdAt
              );
            }
            if (employee.shiftStart) {
              employee.shiftStart = getStandradDateTime(
                utcToLocalTime,
                `${new Date().toISOString().split("T")[0]} ${
                  employee.shiftStart
                }`
              ).split(" ")[1];
            }
            if (employee.shiftEnd) {
              employee.shiftEnd = getStandradDateTime(
                utcToLocalTime,
                `${new Date().toISOString().split("T")[0]} ${employee.shiftEnd}`
              ).split(" ")[1];
            }
            return employee;
          });
        } else {
          if (employees?.attendance?.createdAt) {
            if (employees.attendance.createdAt.includes("T")) {
              const newDate = employees.attendance.createdAt.split(/[TZ]/);
              employees.attendance.createdAt = `${newDate[0]} ${newDate[1]}`;
            }
            employees.attendance.createdAt = getStandradDateTime(
              utcToLocalTime,
              employees.attendance.createdAt
            );
          }
          if (employees.shiftStart) {
            employees.shiftStart = getStandradDateTime(
              utcToLocalTime,
              `${new Date().toISOString().split("T")[0]} ${
                employees.shiftStart
              }`
            ).split(" ")[1];
          }
          if (employees.shiftEnd) {
            employees.shiftEnd = getStandradDateTime(
              utcToLocalTime,
              `${new Date().toISOString().split("T")[0]} ${employees.shiftEnd}`
            ).split(" ")[1];
          }
        }
        employees = employees.filter((emp) => {
          return emp.attendance !== null;
        });
        this.updateStates(employees);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  componentWillUnmount() {
    this.employeeApi && this.employeeApi.cancel();
    this.pickerApi && this.pickerApi.cancel();
  }

  componentDidMount() {
    this.getEmployees();
  }
  render() {
    let {
      pickers,
      employees,
      pickerLoading,
      employeeLoading,
      multiStores,
      storeId,
      Loading,
    } = this.state;
    return (
      <AuthenticatedPage menu={this.props.menu} className="pickers-today">
        <h1 className="pickers-title">
          {getMessage("pickers.today.title")}
          <Link to="/operations/pickers" className="pickers-nav-btn">
            <button className="primary button vendor-btn">
              {" "}
              <img
                src={settingImage}
                className="setting-image"
                alt="settings"
              />{" "}
              {getMessage("pickers.title")}
            </button>
          </Link>
        </h1>
        {!(pickerLoading || employeeLoading) ? (
          employees.length ? (
            Loading ? (
              <Loader />
            ) : (
              <React.Fragment>
                <StoreSelector
                  value={storeId}
                  onChange={this.changeStore}
                  stores={multiStores}
                />
                <TotalPickers {...this.state} />
                {pickers.length !== 0 ? (
                  <ActivePickers {...this.state} />
                ) : null}
                <IdlePickers {...this.state} />
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              <StoreSelector
                value={storeId}
                onChange={this.changeStore}
                stores={multiStores}
              />
              {Loading ? <Loader /> : <EmptyState />}
            </React.Fragment>
          )
        ) : (
          <Loader />
        )}
      </AuthenticatedPage>
    );
  }
}

export default PickersToday;
