import React, { Component } from "react";
import moment from "moment";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import { DateRangePicker } from "../../../components/Form";
import SimpleLineCharts from "../Charts/LineChart";
import SimplePieCharts from "../Charts/PieChart";
import API from "../../../lib/api";
import "./style.css";
import { getSession } from "../../../lib/auth";

const commonEndpoint = "/analytics-service/metrics/daily/";
const apiEndPoints = [
  "sales-amount",
  "order-count",
  "medium-wise-order-count",
  "mode-wise-payment-amount",
];

function dateToNum(d) {
  // Convert date "2016-06-26" to 20160626
  return Number(d.split("-").join(""));
}

const getAccumulatedRevenue = (data) => {
  return data ? data.reduce((total, { amount }) => total + amount, 0) : 0;
};

const getAccumulatedOrders = (data) => {
  return data ? data.reduce((total, { count }) => total + count, 0) : 0;
};

const OrderRevenueHeaderTag = ({ currency, orderRevenue }) => (
  <div className="filter">
    <div className="top-data">
      <span>
        <sup>{currency}</sup> {getAccumulatedRevenue(orderRevenue)}
      </span>
      <span>Total Revenue</span>
    </div>
  </div>
);

const OrderCountHeaderTag = ({ orderCount }) => (
  <div className="filter">
    <div className="top-data">
      <span>{getAccumulatedOrders(orderCount)}</span>
      <span>Total Orders</span>
    </div>
  </div>
);

class CsAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNumber: null,
      orderSearch: false,
      dateRange: {
        startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      orderRevenue: [],
      orderCount: [],
      mediumDistribution: [],
      paymentDistribution: [],
    };
  }

  transformDataForChart = (data) => {
    return (
      data
        ?.sort(function (a, b) {
          return dateToNum(a.date) - dateToNum(b.date);
        })
        // converting date to MM/DD format
        .map((d) => ({ ...d, date: d.date.split("-").slice(1).join("/") }))
    );
  };

  fetchChartData = () => {
    const { dateRange } = this.state;
    const apis = apiEndPoints.map(
      (url) =>
        new API({
          url: `${commonEndpoint}${url}?start=${dateRange.startDate}T00%3A00%3A00Z&end=${dateRange.endDate}T00%3A00%3A00Z`,
        })
    );

    Promise.all(apis.map((api) => api.get()))
      .then(
        ([
          orderRevenueResponse,
          orderCountResponse,
          mediumDistributionResponse,
          paymentDistributionResponse,
        ]) => {
          const newStateValue = {
            orderRevenue: this.transformDataForChart(
              orderRevenueResponse.data.salesAmount
            ),
            orderCount: this.transformDataForChart(
              orderCountResponse.data.orderCount
            ),
            mediumDistribution: mediumDistributionResponse.data
              .mediumWiseOrderCount
              ? mediumDistributionResponse.data.mediumWiseOrderCount
              : [],
            paymentDistribution: paymentDistributionResponse.data
              .modeWisePaymentAmount
              ? paymentDistributionResponse.data.modeWisePaymentAmount
              : [],
          };

          this.setState(newStateValue);
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  handleDateChange = (dates) => {
    let dateRange = JSON.parse(JSON.stringify(this.state.dateRange));
    dateRange.startDate = dates && dates.startDate;
    dateRange.endDate = dates && dates.endDate;

    this.setState({
      dateRange,
    });
  };

  renderRevenueTooltip = ({ active, payload }) => {
    const currency = getSession().organization.currency.symbol;
    if (active && payload) {
      const { value } = payload[0];
      return (
        <div className="line-chart-tooltip">
          <div className="subtitle">{currency}</div>
          <div className="value">{value}</div>
        </div>
      );
    }
    return null;
  };

  renderOrdersTooltip = ({ active, payload }) => {
    if (active && payload) {
      const { value } = payload[0];
      return (
        <div className="line-chart-tooltip">
          <div className="value">{value}</div>
          <div className="subtitle">{value > 1 ? "Orders" : "Order"}</div>
        </div>
      );
    }
    return null;
  };

  renderPieDeviceTooltip = ({ active, payload }) => {
    if (active) {
      const { value } = payload[0];
      return (
        <div className="pie-chart-tooltip">
          <div className="value">{value}</div>
          <div className="subtitle">{value > 1 ? "Orders" : "Order"}</div>
        </div>
      );
    }
    return null;
  };

  renderPiePaymentTooltip = ({ active, payload }) => {
    const currency = getSession().organization.currency.symbol;
    if (active) {
      const { value } = payload[0];
      return (
        <div className="pie-chart-tooltip">
          <div className="value">{value}</div>
          <div className="subtitle">{currency}</div>
        </div>
      );
    }
    return null;
  };

  componentDidUpdate(prevProps, prevState) {
    const { dateRange: oldDateRange } = prevState;
    const dateRange = this.state.dateRange;
    if (
      (dateRange.startDate !== oldDateRange.startDate ||
        dateRange.endDate !== oldDateRange.endDate) &&
      dateRange.startDate &&
      oldDateRange.endDate
    ) {
      this.fetchChartData();
    }
  }

  componentDidMount() {
    this.fetchChartData();
  }

  render() {
    const {
      dateRange,
      mediumDistribution,
      paymentDistribution,
      orderRevenue,
      orderCount,
    } = this.state;
    const currency = getSession().organization.currency.symbol;
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        className="order-analytics-page"
      >
        <h1 className="title">Order Analytics</h1>
        <div>
          <div className="date-selector">
            <DateRangePicker
              openDirection="down"
              minimumNights={0}
              isOutsideRange={(date) => date > new Date()}
              label="Select Dates"
              onChange={this.handleDateChange}
              displayFormat={() => "MMM D YYYY"}
              value={dateRange}
            />
          </div>
          <div className="line-chart">
            <SimpleLineCharts
              data={orderRevenue}
              xKey="date"
              yKey="amount"
              heading="Order Revenue"
              headerTag={
                <OrderRevenueHeaderTag
                  currency={currency}
                  orderRevenue={orderRevenue}
                />
              }
              toolTipContent={this.renderRevenueTooltip}
            />
            <SimpleLineCharts
              data={orderCount}
              xKey="date"
              yKey="count"
              heading="Order Count"
              headerTag={<OrderCountHeaderTag orderCount={orderCount} />}
              toolTipContent={this.renderOrdersTooltip}
            />
          </div>
          <div className="pie-chart">
            <SimplePieCharts
              header="Device Distribution"
              data={mediumDistribution}
              dataKey="count"
              nameKey="medium"
              toolTipContent={this.renderPieDeviceTooltip}
            />
            <SimplePieCharts
              header="Payment Distribution"
              data={paymentDistribution}
              dataKey="amount"
              nameKey="mode"
              toolTipContent={this.renderPiePaymentTooltip}
            />
          </div>
        </div>
      </AuthenticatedPage>
    );
  }
}

export default CsAnalytics;
