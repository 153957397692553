import React from "react";
import {
  BaseForm,
  Input,
  Radio,
  Searchable,
  BrandSearch,
  CategorySearch,
} from "../../../../components/Form";
// import ProductsMetaDataSearch from './ProductsMetaDataSearch'

import { getMessage } from "../../../../lib/translator";

import "./style.css";

export default class ProductFiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    const isSeller = this.props?.options?.seller || false;
    return (
      <Form className="product-filter-form">
        <div className="form-fields">
          {isSeller && (
            <Searchable
              label={getMessage("product.filters.sellerName.heading")}
              placeholder={getMessage(
                "product.filters.permissableSeller.placeholder"
              )}
              name="seller"
              searchUrl="/account-service/seller"
              valueKey="id"
              responseKey="seller"
              nameKey="name"
              searchKey="name"
              transformResponse={(response) => response.data.seller}
              {...this.generateStateMappers({
                stateKeys: ["seller"],
              })}
            />
          )}

          <Input
            label={getMessage("product.filters.name.heading")}
            placeholder={getMessage("product.filters.name.placeholder")}
            name="name"
            type="text"
            {...this.generateStateMappers({
              stateKeys: [!isSeller ? "name" : "productName"],
            })}
          />
          <CategorySearch
            label={getMessage("product.filters.category.heading")}
            placeholder={getMessage("product.filters.category.placeholder")}
            name="category"
            {...this.generateStateMappers({
              stateKeys: ["category"],
            })}
          />
          {this.props.options && this.props.options.brands ? (
            <BrandSearch
              label={getMessage("product.filters.brand.heading")}
              placeholder={getMessage("product.filters.brand.placeholder")}
              name="brand"
              {...this.generateStateMappers({
                stateKeys: ["brand"],
              })}
            />
          ) : null}
          {this.props.options && this.props.options.tags ? (
            <Searchable
              label={getMessage("product.filters.tag.heading")}
              placeholder={getMessage("product.filters.tag.placeholder")}
              name="brand"
              searchUrl="/catalogue-service/tag"
              valueKey="id"
              nameKey="name"
              searchKey="name"
              transformResponse={(response) => response.data.tag}
              {...this.generateStateMappers({
                stateKeys: ["tag"],
              })}
            />
          ) : null}
          <Input
            label={getMessage("barcode")}
            placeholder={getMessage("Enter Barcode")}
            name="barcode"
            {...this.generateStateMappers({
              stateKeys: ["barcode"],
            })}
          />
          <Input
            label={getMessage("product.filters.clientId.heading")}
            placeholder={getMessage("product.filters.clientId.placeholder")}
            name="clientId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["clientId"],
            })}
          />
          <Radio
            className="product-status"
            label={getMessage("product.filters.status.heading")}
            name="status"
            options={[
              {
                text: getMessage("product.filters.status.label.all"),
                value: "",
              },
              {
                text: getMessage("product.filters.status.label.enabled"),
                value: "ENABLED",
              },
              {
                text: getMessage("product.filters.status.label.disabled"),
                value: "HIDDEN",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              defaultValue: "",
            })}
          />
          {/* {this.props.options && this.props.options.entityMetaData ? (<ProductsMetaDataSearch _this={this} />) : null } */}
        </div>
        <SubmitButton>{getMessage("product.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("product.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
