import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListingPageWithRoutes from "../../../containers/ListingPage/listingRouter";
import Image from "../../../components/Image";
import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { getMessage } from "../../../lib/translator";
import {
  getPrintableTime,
  utcToLocalTime,
  localTimeToUtc,
  getStandradDateTime,
} from "../../../lib/datetime";
import API from "../../../lib/api";
import {
  isExtensionEnabled,
  hasPermissions,
  getStores,
} from "../../../lib/auth";
import OfferForm from "./Form";
import BANYATP from "./Form/BANYATP";
import BMINXATP from "./Form/BMINXATP";
import BANYGYD from "./Form/BANYGYD";
import BXATP from "./Form/BXATP";
import BXGYD from "./Form/BXGYD";
import SF from "./Form/SF";
import SFXGSD from "./Form/SFXGSD";
import BMIN from "./Form/BMIN";
import PWP from "./Form/PWP";
import BFXATP from "./Form/BFXATP";
import BMINXGFG from "./Form/BMINXGFG";
import SFBGSD from "./Form/SFBGSD";
import SFEGSD from "./Form/SFEGSD";
import OfferFilter from "./Filters";
import EmptyIcon from "../Coupons/coupons-empty.svg";
import { formatDataBeforeSubmit } from "../../../components/SegmentsForm";

import "./style.css";
import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import Toggle from "../../../components/Form/Inputs/Toggle";
import moment from "moment";

const OfferTypes = {
  BANYATP:
    "Buy any `n` of the selected variants (X,Y....) and get discount on mrp", // Buy any at Price Z
  BMINXATP: "Buy n or more of X(or X, Y) and get discount on MRP", // Buy min of X at Price X
  BANYGYD:
    "Buy any `n` of the selected variants (X,Y....) and get Z at discounted price/Free", // Buy Any Get Y at Discount (Z%)
  BXATP: "Buy X Product At Price (Z)", // Buy X Product At Price (Z)
  BXGYD: "Buy X and Get Y at discounted price/Free", // Buy X and Get Y at Discount
  SF: "Shop For `n` Amount and get Y at discounted price/Free", // Shop For Rs.X and GET Y at free/D% discount/X off,
  SFXGSD: "Shop for X amount and get discount on shipping",
  SFXGCD: "Shop for X amount and get discount on cart",
  PWP: "Purchase with purchase",
  BMIN: "Buy for min amt in entity/non-entity and get discount",
  BFXATP: "Buy any of the tags at discounted price/Free",
  BMINXGFG: "Buy for min amt in entity/non-entity and get products at discount",
  SFBGSD: "Shop any from  X Brand and get discount on shipping",
  SFEGSD: "Shop any from X entity and get discount on shipping",
};

const OfferMapping = [
  {
    text: OfferTypes.BANYATP,
    value: "BANYATP",
  },
  {
    text: OfferTypes.BMINXATP,
    value: "BMINXATP",
  },
  {
    text: OfferTypes.BANYGYD,
    value: "BANYGYD",
  },
  {
    text: OfferTypes.SF,
    value: "SF",
  },
  {
    text: OfferTypes.BMIN,
    value: "BMIN",
  },
  {
    text: OfferTypes.BMINXGFG,
    value: "BMINXGFG",
  },
  {
    text: OfferTypes.SFXGSD,
    value: "SFXGSD",
  },
  {
    text: OfferTypes.SFXGCD,
    value: "SFXGCD",
  },
  {
    text: OfferTypes.BXATP,
    value: "BXATP",
  },
  {
    text: OfferTypes.BXGYD,
    value: "BXGYD",
  },
  {
    text: OfferTypes.PWP,
    value: "PWP",
  },
  {
    text: OfferTypes.BFXATP,
    value: "BFXATP",
  },
  {
    text: OfferTypes.SFBGSD,
    value: "SFBGSD",
  },
  {
    text: OfferTypes.SFEGSD,
    value: "SFEGSD",
  },
];

const ProductOnlyOffers = [
  "SFXGSD",
  "SFXGCD",
  "BXATP",
  "BXGYD",
  "PWP",
  "BFXATP",
];
const BrandOnlyOffers = ["SFBGSD"];

const EntityIds = [
  {
    text: getMessage("offer.product"),
    value: "PRODUCT",
  },
  {
    text: getMessage("offer.category"),
    value: "CATEGORY",
  },
];

if (isExtensionEnabled("MultiBrandSupport")) {
  EntityIds.push({
    text: getMessage("offer.brand"),
    value: "BRAND",
  });
}

const DiscountTypes = [
  {
    text: getMessage("offer.fixed"),
    value: "FIXED",
  },
  {
    text: getMessage("offer.percentoff"),
    value: "PERCENT_OFF",
  },
  {
    text: getMessage("offer.absoluteoff"),
    value: "ABSOLUTE_OFF",
  },
];

const StoreOptions = [
  {
    text: getMessage("offer.all.stores"),
    value: "ALL",
  },
  {
    text: getMessage("offer.selected.stores"),
    value: "SELECTED",
  },
  {
    text: getMessage("offer.all.except.selected.stores"),
    value: "ALL_EXCEPT_SELECTED",
  },
];

const ItemDiscountTypes = [
  {
    text: getMessage("offer.combo.discount"),
    value: "COMBO_DISCOUNT",
  },
  {
    text: getMessage("offer.individual.discount"),
    value: "INDIVIDUAL_DISCOUNT",
  },
];

function AddButton() {
  return (
    <Link to="/marketing/offers/add" className="primary button">
      + <span className="text">{getMessage("offer.add.text")}</span>
    </Link>
  );
}

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage("offer.emptyMessage"),
  additionalViews: [AddButton],
};

class OfferActions extends Component {
  render() {
    const { id, onAction, status } = this.props;
    return (
      <React.Fragment>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`/marketing/offers/edit/${id}`}>
              {getMessage("offer.edit")}
            </Link>
          </DropDownItem>
          {!(status === "ENABLED") ? (
            <DropDownItem
              onClick={() => {
                onAction(
                  TABLE_ACTIONS.UPDATE,
                  { id },
                  { status: "ENABLED", tableAction: true }
                );
              }}
            >
              {getMessage("offer.action.enable")}
            </DropDownItem>
          ) : (
            <DropDownItem
              onClick={() => {
                onAction(
                  TABLE_ACTIONS.UPDATE,
                  { id },
                  { status: "DISABLED", tableAction: true }
                );
              }}
            >
              {getMessage("offer.action.disable")}
            </DropDownItem>
          )}
        </DropDown>
      </React.Fragment>
    );
  }
}

const tableProperties = () => {
  return {
    headers: [
      getMessage("offer.image"),
      getMessage("offer.description"),
      getMessage("offer.type"),
      getMessage("offer.status"),
      getMessage("offer.actions"),
    ],
    row: ({
      id,
      offerType,
      rule,
      offerValidFrom,
      offerValidTill,
      status,
      imageUrl,
      description,
      shortDescription,
      alertMessage,
      onAction,
    }) => (
      <Row>
        <Cell className="offer-table-image">
          <Image size="sm" src={imageUrl} alt="offer-logo" />
        </Cell>
        <Cell className="offer-table-description">
          <div>
            <Link to={`/marketing/offers/edit/${id}`}>
              <div className="name">
                <span>{description}</span>
              </div>
            </Link>
            <small className="text-muted">
              {offerValidFrom && offerValidTill
                ? `${getPrintableTime(offerValidFrom).split(", ")[0]} - ${
                    getPrintableTime(offerValidTill).split(", ")[0]
                  }`
                : null}
            </small>
          </div>
        </Cell>
        <Cell className="offer-table-type">
          <span className="desktop-only">{OfferTypes[offerType]}</span>
          <div className="mobile-only">
            <span className="text-muted">{`${getMessage(
              "offer.type"
            )} : `}</span>
            <span>{OfferTypes[offerType]}</span>
          </div>
        </Cell>
        <Cell className="offer-table-status">
          <span className="desktop-only">{status}</span>
          <Toggle
            className={`${status} mobile-only`}
            name={status}
            value={status === "ENABLED"}
            icons={false}
            onChange={() => {
              onAction(
                TABLE_ACTIONS.UPDATE,
                { id },
                {
                  status: status === "ENABLED" ? "DISABLED" : "ENABLED",
                  tableAction: true,
                }
              );
            }}
          />
        </Cell>
        <Cell className="offer-table-actions">
          <OfferActions onAction={onAction} id={id} status={status} />
        </Cell>
      </Row>
    ),
  };
};

class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.changeTab = this.changeTab.bind(this);
  }

  changeTab(activeIndex) {
    this.setState({ activeIndex });
  }

  componentDidMount() {
    this.offerForm = {
      BANYATP: BANYATP,
      BMINXATP: BMINXATP,
      BANYGYD: BANYGYD,
      SFXGSD: SFXGSD,
      SFXGCD: SFXGSD,
      BXATP: BXATP,
      BXGYD: BXGYD,
      SF: SF,
      PWP: PWP,
      BMIN: BMIN,
      BFXATP: BFXATP,
      BMINXGFG: BMINXGFG,
      SFBGSD: SFBGSD,
      SFEGSD: SFEGSD,
    };
    this.buyKey = "buy";
    if (isExtensionEnabled("MultiStoreSupport")) {
      this.setState({
        stores: getStores() || [],
      });
    }
    if (
      isExtensionEnabled("ProductTagSupport") &&
      hasPermissions("catalogue", "tag", "get")
    ) {
      this.pwpwApi = new API({ url: "/catalogue-service/tag" });
      this.pwpwApi
        .get({ name: "pwp" })
        .then((response) => {
          const tag = response.data.tag || [];
          if (tag.length) {
            this.offerForm.PWP = PWP; // PWP is an offer that can be added
            this.setState({
              pwpTagId: tag[0].id,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
  }

  render() {
    const { props, state } = this;
    const pwpTagId = state && state.pwpTagId;
    return (
      <ListingPageWithRoutes
        className="offers-page"
        menu={props.menu}
        title={getMessage("offers.title")}
        api={{
          url: "/offer-service/offer",
          transform: (response) =>
            Array.isArray(response.data.offers)
              ? response.data.offers.filter((offer) => offer.offerType !== "PT")
              : response.data.offers,
        }}
        emptyState={EmptyState}
        addHeading={getMessage("offer.add.heading")}
        editHeading={getMessage("offer.edit.heading")}
        tableProperties={tableProperties()}
        headerActions={({ onAction }) => (
          <Link to="/marketing/offers/add" className="primary button">
            + <span className="text">{getMessage("offer.add.text")}</span>
          </Link>
        )}
        filters={{
          component: OfferFilter,
          options: {
            offerTypes: OfferTypes,
            offerMapping: OfferMapping,
            stores: this.state && this.state.stores,
            entityIds: EntityIds,
            buyKey: this.buyKey,
          },
          transformSubmit: (response) => {
            let formData = JSON.parse(JSON.stringify(response));
            if (response.type === "all") {
              formData.type = "";
            }
            if (response.entityType === "PRODUCT") {
              formData.entityType = "VARIANT";
            }
            delete formData.selectedEntity;
            return formData;
          },
        }}
        form={{
          component: OfferForm,
          options: {
            offerTypes: OfferTypes,
            offerMapping: OfferMapping,
            productOnlyOffers: ProductOnlyOffers,
            brandOnlyOffers: BrandOnlyOffers,
            stores: this.state && this.state.stores,
            multiStoreEnabled: isExtensionEnabled("MultiStoreSupport"),
            entityIds: EntityIds,
            discountTypes: DiscountTypes,
            storeOptions: StoreOptions,
            itemDiscountTypes: ItemDiscountTypes,
            buyKey: this.buyKey,
            pwpTagId: pwpTagId,
            activeIndex: this.state.activeIndex,
            changeTab: this.changeTab,
          },
          transformSubmit: (formData) => {
            let data = JSON.parse(JSON.stringify(formData));
            if (data.tableAction) {
              delete data.tableAction;
              return data;
            }
            if (data && data.userSet) {
              data = formatDataBeforeSubmit(data);
            }
            if (data && data.userSet && data.userSet.data) {
              if (data.userSet.data.length === 0) {
                data.userSet = null;
                data.method !== "edit" && delete data.userSet;
              }
            } else if (data && data.userSet === null) {
              delete data.userSet;
            }

            if (data.userSet && data.userSet.data === undefined)
              delete data.userSet;

            // Offer specific transforms
            let Offer = this.offerForm[data.type];
            let offer = new Offer();
            data = offer.submitHandler(data, this.buyKey);
            let idsString = "";
            data.storeId &&
              Array.isArray(data.storeId) &&
              data.storeId.forEach((id, i, arr) => {
                idsString += i !== arr.length - 1 ? id + "," : id;
              });
            data.storeId = idsString;
            delete data.storeType;
            if (data.validFrom && data.validFromTime) {
              data.offerValidFrom = getStandradDateTime(
                localTimeToUtc,
                `${data.validFrom} ${data.validFromTime}`
              );
              delete data.validFrom;
              delete data.validFromTime;
            }
            if (data.validTill && data.validTillTime) {
              data.offerValidTill = getStandradDateTime(
                localTimeToUtc,
                `${data.validTill} ${data.validTillTime}`
              );
            }

            if (data.offerValidTill === null) {
              delete data.offerValidTill;
            }

            delete data.validTill;
            delete data.validTillTime;

            if (data.startDeliveryDate && data.startDeliveryDateTime) {
              data.startDeliveryDate = getStandradDateTime(
                localTimeToUtc,
                `${data.startDeliveryDate} ${data.startDeliveryDateTime}`
              );
              delete data.startDeliveryDateTime;
            }
            if (data.endDeliveryDate && data.endDeliveryDateTime) {
              data.endDeliveryDate = getStandradDateTime(
                localTimeToUtc,
                `${data.endDeliveryDate} ${data.endDeliveryDateTime}`
              );
              delete data.endDeliveryDateTime;
            }
            data.offerType = data.type;
            delete data.entityType;
            if (
              data &&
              data.type &&
              data.type !== "SFBGSD" && //SFBGSD will send t as "FREE"
              data.rule &&
              data.rule.total &&
              data.rule.total.t &&
              data.type !== "SFEGSD" &&
              data.rule.total.t === "FREE"
            ) {
              data.rule.total.t = "PERCENT_OFF";
              data.rule.total.v = 100;
            }
            if (data.type === "SFXGSD") {
              data.rule = data.rule?.[0];
              data.rule.shippingDiscount = data.rule?.total;
              delete data.rule.total;
              delete data.entity;
            }
            if (data.type === "SFBGSD" || data.type === "SFEGSD") {
              data.rule.shippingDiscount = data.rule?.total;
              if (data.rule.shippingDiscount.t === "FREE") {
                data.rule.shippingDiscount = {
                  t: "FREE",
                  v: "",
                };
              }
              delete data.rule.total;
            }
            if (data.type === "SFXGCD") {
              data.rule =
                data.rule &&
                data.rule.map((rule) => ({
                  cartDiscount: rule.total,
                  timeSlots: rule.timeSlots,
                  maxDiscount:
                    rule.maxDiscount === "" ? undefined : rule.maxDiscount,
                  cartPrice: rule.cartPrice,
                }));

              delete data.entity;
            }
            if (data.type === "PWP") {
              data.rule.get = data.rule.total;
              delete data.rule.total;
              delete data.entity;
            }

            if (data.type === "BMIN") {
              delete data.entity;
              delete data.type;
            }
            if (data.type === "BFXATP") {
              let tags = JSON.parse(JSON.stringify(data.tags));
              const tagIds = tags.map((item) => Number(item.id));
              data.rule.tagIds = tagIds;
              delete data.tags;
            }

            if (data && data.rule && data.rule.buy) {
              (Object.keys(data.rule.buy) || []).forEach((productId) => {
                if (data.rule.buy[productId].t === "FREE") {
                  data.rule.buy[productId].t = "PERCENT_OFF";
                  data.rule.buy[productId].v = 100;
                }
              });
            }
            if (data && data.rule && data.rule.get) {
              (Object.keys(data.rule.get) || []).forEach((productId) => {
                if (data.rule.get[productId].t === "FREE") {
                  data.rule.get[productId].t = "PERCENT_OFF";
                  data.rule.get[productId].v = 100;
                }
              });
            }
            if (data && data.rule && !data.rule.limit) {
              data.rule.limit = 0;
            }

            if (data.type === "SFXGCD") {
              delete data.pwpTagId;
              delete data.rule.limit;
            }

            if (data.type === "SFEGSD") {
              data.rule.entity.ids = data.rule.variants;
              data.rule.q = data.rule.quantity;

              delete data.rule.entity.id;
              delete data.rule.quantity;
              delete data.rule.variants;
            }

            delete data.type;
            delete data.method;
            delete data.oldFormat;
            if (data && data.userSet) {
              // date inside userSet should be sent in YYYY-MM-DD HH:mm:ss format
              const newUserSetData = data?.userSet?.data?.map((userSetData) => {
                if (userSetData.date && userSetData.date.length === 10) {
                  userSetData.date = getStandradDateTime(
                    localTimeToUtc,
                    `${userSetData.date} 00:00:00`
                  );
                }
                if (
                  userSetData.startDate &&
                  userSetData.startDate.length === 10
                ) {
                  userSetData.startDate =
                    userSetData.startDate === moment().format("YYYY-MM-DD")
                      ? getStandradDateTime(
                          localTimeToUtc,
                          `${moment().format("YYYY-MM-DD")} 00:00:00`
                        )
                      : getStandradDateTime(
                          localTimeToUtc,
                          `${userSetData.startDate} 00:00:00`
                        );
                }
                if (userSetData.endDate && userSetData.endDate.length === 10) {
                  userSetData.endDate = getStandradDateTime(
                    localTimeToUtc,
                    `${userSetData.endDate} 23:59:59`
                  );
                }
                return userSetData;
              });

              if (data.userSet.data) {
                data.userSet.data = newUserSetData;
              }
              data = formatDataBeforeSubmit(data);
            }
            return data;
          },
          transformResponse: (response) => {
            let offer = response.data.offers;

            offer.includes = false;

            if (
              offer &&
              (offer.offerType === "PT" ||
                offer.offerType === "BMIN" ||
                offer.offerType === "BMINXGFG") &&
              offer.rule &&
              offer.rule.entity
            ) {
              offer.includes = true;
            }

            if (
              offer.offerType === "PT" &&
              offer.rule &&
              offer.rule["non-entity"]
            ) {
              offer.rule.entity = offer.rule["non-entity"];
              delete offer.rule["non-entity"];
            }

            offer.entityType =
              !offer.rule.entity || offer.rule.entity.type === "VARIANT"
                ? "PRODUCT"
                : offer.rule.entity.type;

            if (
              (offer.offerType === "BMIN" || offer.offerType === "BMINXGFG") &&
              offer.rule &&
              offer.rule["non-entity"]
            ) {
              offer.entityType =
                offer.rule["non-entity"].type === "VARIANT"
                  ? "PRODUCT"
                  : offer.rule["non-entity"].type;
            }
            offer.itemDiscountType = offer.rule.total
              ? "COMBO_DISCOUNT"
              : "INDIVIDUAL_DISCOUNT";
            if (offer.rule && offer.offerType === "BMINXATP") {
              offer.rule.itemDiscountType = offer.rule.isCombo
                ? "COMBO_DISCOUNT"
                : "INDIVIDUAL_DISCOUNT";
              if (!offer.rule.elementGroups) {
                offer.oldFormat = true;
                offer.rule.elementGroups = [
                  {
                    total: offer.rule.total,
                    minQuantity: offer.rule.minQuantity,
                    maxDiscount: offer.rule.maxDiscount,
                  },
                ];

                delete offer.rule.total;
                delete offer.rule.minQuantity;
                delete offer.rule.maxDiscount;
              }
            }
            if (offer.rule && offer.offerType === "BMINXGFG") {
              offer.itemDiscountType =
                offer.rule.elementGroups &&
                offer.rule.elementGroups[0] &&
                offer.rule.elementGroups[0].total
                  ? "COMBO_DISCOUNT"
                  : "INDIVIDUAL_DISCOUNT";
              if (!offer.rule.elementGroups) {
                offer.rule.elementGroups = [
                  {
                    get: offer.rule.get,
                    minAmount: offer.rule.minAmount,
                  },
                ];
                if (offer.rule.total) {
                  offer.rule.elementGroups[0]["total"] = offer.rule.total;
                }
                delete offer.rule.total;
                delete offer.rule.minAmount;
              }
            }
            if (offer.rule && offer.offerType === "BMIN") {
              offer.rule.itemDiscountType = offer.rule.isCombo
                ? "COMBO_DISCOUNT"
                : "INDIVIDUAL_DISCOUNT";
              if (!offer.rule.elementGroups) {
                offer.rule.elementGroups = [
                  {
                    total: offer.rule.total,
                    minAmount: offer.rule.minAmount,
                    maxDiscount: offer.rule.maxDiscount,
                  },
                ];

                delete offer.rule.total;
                delete offer.rule.minAmount;
                delete offer.rule.maxDiscount;
              }
            }
            offer.type = offer.offerType;
            let storeIdArray = [];
            offer.storeId.split(",").forEach((id) => {
              id !== "" && storeIdArray.push(Number(id));
            });
            offer.storeId = storeIdArray;
            if (offer.offerValidFrom) {
              let time = offer.offerValidFrom;
              if (time.includes("T")) {
                const newDate = offer.startDeliveryDate.split(/[TZ]/);
                time = `${newDate[0]} ${newDate[1]}`;
              }

              const offerValidFromLocal = getStandradDateTime(
                utcToLocalTime,
                time
              );

              offer.validFrom = offerValidFromLocal.split(" ")[0];
              offer.validFromTime = offerValidFromLocal.split(" ")[1];
            } else {
              offer.validFrom = null;
              offer.validFromTime = null;
            }

            if (offer.offerValidTill) {
              let time = offer.offerValidTill;
              if (time.includes("T")) {
                const newDate = offer.startDeliveryDate.split(/[TZ]/);
                time = `${newDate[0]} ${newDate[1]}`;
              }

              const offerValidToLocal = getStandradDateTime(
                utcToLocalTime,
                time
              );

              offer.validTill = offerValidToLocal.split(" ")[0];
              offer.validTillTime = offerValidToLocal.split(" ")[1];
            } else {
              offer.validTill = null;
              offer.validTillTime = null;
            }

            if (offer.startDeliveryDate) {
              let time = offer.startDeliveryDate;
              if (time.includes("T")) {
                const newDate = offer.startDeliveryDate.split(/[TZ]/);
                time = `${newDate[0]} ${newDate[1]}`;
              }
              const startDeliveryDateLocal = getStandradDateTime(
                utcToLocalTime,
                `${time}`
              );

              offer.startDeliveryDate = startDeliveryDateLocal.split(" ")[0];
              offer.startDeliveryDateTime =
                startDeliveryDateLocal.split(" ")[1];
            } else {
              offer.startDeliveryDate = null;
              offer.startDeliveryDateTime = null;
            }

            if (offer.endDeliveryDate) {
              let time = offer.endDeliveryDate;
              if (time.includes("T")) {
                const newDate = offer.endDeliveryDate.split(/[TZ]/);
                time = `${newDate[0]} ${newDate[1]}`;
              }
              const endDeliveryDateLocal = getStandradDateTime(
                utcToLocalTime,
                time
              );

              offer.endDeliveryDate = endDeliveryDateLocal.split(" ")[0];
              offer.endDeliveryDateTime = endDeliveryDateLocal.split(" ")[1];
            } else {
              offer.endDeliveryDate = null;
              offer.endDeliveryDateTime = null;
            }
            if (offer && offer.rule && offer.rule.shippingDiscount) {
              offer.rule.total = offer.rule.shippingDiscount;
              delete offer.rule.shippingDiscount;
            }
            if (offer && offer.rule && offer.rule.cartDiscount) {
              offer.rule.total = offer.rule.cartDiscount;
              delete offer.rule.cartDiscount;
            }
            if (
              offer &&
              (offer.offerType === "SFXGCD" || offer.offerType === "SFXGSD")
            ) {
              if (!Array.isArray(offer.rule)) {
                offer.rule = [offer.rule];
              }
              offer.rule =
                offer.rule &&
                offer.rule.map((rule) => ({
                  total:
                    rule.total || rule.cartDiscount || rule.shippingDiscount,
                  timeSlots: rule.timeSlots,
                  maxDiscount: rule.maxDiscount,
                  cartPrice: rule.cartPrice,
                }));
            }
            if (offer && offer.rule && offer.rule.pwpTagId) {
              offer.rule.total = offer.rule.get;
              delete offer.rule.get;
            }
            if (offer && offer.rule && offer.rule.timeSlots) {
              offer.timeSlots = offer.rule.timeSlots;
              delete offer.rule.timeSlots;
            }

            if (offer.type === "SFEGSD") {
              offer.rule.quantity = offer.rule.q;
            }

            if (offer && offer.rule && offer.rule.tagIds) {
              offer.tagIds = offer.rule.tagIds;
              delete offer.rule.tagIds;
            }
            const newDataArray = offer?.userSet?.data?.map((userSetData) => {
              if (userSetData.date) {
                userSetData.date = getStandradDateTime(
                  utcToLocalTime,
                  userSetData.date
                ).split(" ")[0];
              }
              if (userSetData.startDate) {
                userSetData.startDate = getStandradDateTime(
                  utcToLocalTime,
                  userSetData.startDate
                ).split(" ")[0];
              }
              if (userSetData.endDate) {
                userSetData.endDate = getStandradDateTime(
                  utcToLocalTime,
                  userSetData.endDate
                ).split(" ")[0];
              }
              return userSetData;
            });

            offer["userSet"] = {
              ...offer.userSet,
              data: newDataArray,
            };
            offer.method = "edit";
            return offer;
          },
        }}
      />
    );
  }
}

export default Offer;
