import React from "react";
import ProductCollection from "../../../../components/Layout/ProductCollection";
import {
  Input,
  Select,
  CheckboxGroup,
  Radio,
  CategorySearch,
  BrandSearch,
  Searchable,
} from "../../../../components/Form";
import { isExtensionEnabled } from "../../../../lib/auth";
import { getMessage } from "../../../../lib/translator";
const ProductCollectionLayout = (selectOptions, defaultOption) => {
  return {
    preview: ProductCollection,
    fields: function ({ getState, updateState, page }) {
      const showOnly = [
        {
          text: getMessage("themes.offers"),
          value: "hasOffers",
        },
        {
          text: getMessage("themes.images"),
          value: "hasImage",
        },
        {
          text: getMessage("themes.stock"),
          value: "hasStock",
        },
      ];
      let showOnlyValue = [];

      showOnly.forEach((option) => {
        if (getState([option.value]) === 1) {
          showOnlyValue.push(option.value);
        }
      });
      return (
        <div>
          <div className="form-sections">
            <div className="form-section">
              <Input
                label={getMessage("themes.title")}
                placeholder="Type the collection title"
                {...this.generateStateMappers({
                  stateKeys: ["title"],
                })}
              />
            </div>
            <div className="form-section">
              <Input
                label={getMessage("SUBTITLE")}
                placeholder="Enter the  subtitle"
                {...this.generateStateMappers({
                  stateKeys: ["subtitle"],
                })}
              />
            </div>
            {isExtensionEnabled("ProductTagSupport") && (
              <div className="form-section">
                <Searchable
                  label="tags"
                  placeholder={getMessage("themes.tag.show")}
                  name="brand"
                  searchUrl="/catalogue-service/tag"
                  valueKey="id"
                  nameKey="name"
                  searchKey="name"
                  transformResponse={(response) =>
                    (page === "tag"
                      ? [
                          {
                            id: new Date().getTime(),
                            name: "current",
                            slug: "CURRENT",
                          },
                        ]
                      : []
                    ).concat(response.data.tag)
                  }
                  {...this.generateStateMappers({
                    stateKeys: ["tag"],
                  })}
                />
              </div>
            )}
            <div className="form-section">
              <CategorySearch
                label={"categories"}
                appendElement={
                  page === "category"
                    ? [
                        {
                          id: new Date().getTime(),
                          name: "current",
                          displayName: "current",
                          slug: "CURRENT",
                        },
                      ]
                    : null
                }
                placeholder={getMessage("themes.category.show")}
                name="name"
                {...this.generateStateMappers({
                  stateKeys: ["category"],
                })}
              />
            </div>
          </div>
          <div className="form-sections">
            {isExtensionEnabled("MultiBrandSupport") && (
              <div className="form-section">
                <BrandSearch
                  label={"brands"}
                  appendElement={
                    page === "brand"
                      ? [
                          {
                            id: new Date().getTime(),
                            name: "current",
                            displayName: "current",
                            slug: "CURRENT",
                          },
                        ]
                      : null
                  }
                  placeholder={getMessage("themes.brand.show")}
                  name="brand"
                  {...this.generateStateMappers({
                    stateKeys: ["brand"],
                  })}
                />
              </div>
            )}
            <div className="form-section">
              <Select
                label="sort by"
                placeholder={getMessage("themes.sortinglogic")}
                options={[
                  {
                    text: "Popularity",
                    value: "POPULARITY",
                  },
                  {
                    text: "New First",
                    value: "NEW_FIRST",
                  },
                  {
                    text: "New Last",
                    value: "NEW_LAST",
                  },
                ]}
                {...this.generateStateMappers({
                  stateKeys: ["sorting"],
                })}
              />
            </div>
          </div>
          <div className="form-sections">
            <div className="form-section">
              <CheckboxGroup
                label={getMessage("themes.product")}
                options={showOnly}
                value={showOnlyValue}
                onChange={(values) => {
                  const irrellevant = showOnlyValue.filter(
                    (option) => values.indexOf(option) < 0
                  );
                  const yes = showOnlyValue.filter(
                    (option) => values.indexOf(option) >= 0
                  );
                  irrellevant.forEach((option) => {
                    updateState([option], "");
                  });
                  yes.forEach((option) => {
                    updateState([option], 1);
                  });
                }}
              />
            </div>
            <div className="form-section">
              <Radio
                name="layoutType"
                label={getMessage("themes.layoutType")}
                required
                options={[
                  {
                    text: getMessage("themes.grid"),
                    value: "GRID",
                  },
                  {
                    text: getMessage("themes.scroller"),
                    value: "SCROLLER",
                  },
                ]}
                {...this.generateStateMappers({
                  stateKeys: ["layoutType"],
                })}
              />
            </div>
            <div className="form-section">
              <Radio
                name="pagination"
                label={getMessage("themes.loadMoreType")}
                required
                options={[
                  {
                    text: getMessage("themes.infiniteScroll"),
                    value: "INFINITE",
                  },
                  {
                    text: getMessage("themes.seeAll"),
                    value: "SEEALL",
                  },
                ]}
                {...this.generateStateMappers({
                  stateKeys: ["loadMoreType"],
                })}
              />
            </div>
          </div>
        </div>
      );
    },
  };
};

export default ProductCollectionLayout;
