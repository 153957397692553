const data = {
  "choose.theme.placeholder": "Select theme",
  "choose.theme.device.placeholder": "Select device",
  "industry.selection.error.message":
    "Some error occurred while fetching Industries",
  "onboarding.basicInfo.heading": "Provide Store Information",
  "onboarding.basicInfo.sencondaryHeading":
    "Enter your store information below and you can",
  "onboarding.basicInfo.subDomain.text": "subdomain",
  "onboarding.basicInfo.storeurl.description":
    "Your store URL is provided free of cost at smartstore.express. If you already own a domain",
  "onboarding.basicInfo.clickHere.text": "click here",
  "onboarding.basicInfo.register.text": "register",
  "onboarding.basicInfo.newOne.text": "a new one",
  "onboarding.basicInfo.useIt.text": "to use it or",
  "onboarding.industry.heading": "What is your industry?",
  "onboarding.industry.sencondaryHeading": "Select an industry below",
  "onboarding.products.heading": "Add products to your website",
  "onboarding.products.sencondaryHeading1":
    "Use below 10 sample products to create your",
  "onboarding.products.sencondaryHeading2": "website now and you can",
  "onboarding.theme.heading": "Choose your theme",
  "onboarding.theme.sencondaryHeading1": "Here is your default theme for your",
  "onboarding.theme.sencondaryHeading2": "website and you can",
  "onboarding.continue.text": "Continue",
  "onboarding.back.text": "Back",
  "onboarding.createWebsite.text": "Create your website now",
  "onboarding.seeMoreIndustries.text": "See more industries here",
  "onboarding.changeLater.text": "change later here",
  "onboarding.basicinfo.url.subtext": "Or use",
};

export default data;
