import React from "react";
import { BaseForm, Input, VALIDATION_TYPES } from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";

export default class TagFiltersForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.values.status = "";
  }
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage("tag.filters.name.heading")}
            placeholder={getMessage("tag.filters.name.placeholder")}
            name="name"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
        </div>
        <SubmitButton>{getMessage("tag.filters.submitText")}</SubmitButton>
        <ClearButton>{getMessage("tag.filter.clear")}</ClearButton>
      </Form>
    );
  }
}
