import React from "react";
import {
  BaseForm,
  Input,
  ProductSearch,
  CategorySearch,
  Select,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import Image from "../../../../components/Image";
import { VALIDATION_TYPES } from "../../../../components/Form";

export default class ProductForm extends BaseForm {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
    this.store = 0;
  }

  delete() {
    this.props.onDelete();
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    const { props } = this;
    const { options, value } = props;
    const product = value && value.product;
    const category = value && value.category;
    const entity = options && options.entityName;
    return (
      <div className="BrandForm stockForm">
        <Form>
          {props.method === "add" ? (
            entity === "product" ? (
              <ProductSearch
                name="name"
                required
                {...this.generateStateMappers({
                  stateKeys: ["product"],
                  loseEmphasisOnFill: true,
                })}
              />
            ) : (
              <CategorySearch
                label={options.entityName}
                placeholder={`Search a ${options.entityName} to add`}
                name="name"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ["category"],
                  loseEmphasisOnFill: true,
                })}
              />
            )
          ) : null}
          <div className="flex">
            {product ? (
              <Image size="md" src={product.images && product.images[0]} />
            ) : category ? (
              <Image size="md" src={category.image} />
            ) : null}
            {product ? (
              <h4>
                {product.brand ? (
                  <React.Fragment>
                    <span className="bold">{product.brand.name}</span>{" "}
                    {product.name}
                  </React.Fragment>
                ) : (
                  product.name
                )}
              </h4>
            ) : category ? (
              <h4>{category.name}</h4>
            ) : null}
          </div>
          <div className="form-fields">
            <Input
              label={getMessage("store.min.stock.count")}
              placeholder="00"
              name="stockBuffer"
              type="number"
              min={1}
              {...this.generateStateMappers({
                stateKeys: ["stockBuffer"],
                loseEmphasisOnFill: true,
              })}
              transformValidationHook={(DomNode, validationObj) => {
                let value = DomNode.value;
                if (value === "") {
                  Object.keys(validationObj).map((key) => {
                    validationObj[key] = key === "valid";
                    return null;
                  });
                }
                return validationObj;
              }}
            />
            <Input
              label={getMessage("store.min.purchaseable.qty")}
              placeholder="00"
              min={1}
              name="minPurchasableStock"
              type="number"
              {...this.generateStateMappers({
                stateKeys: ["minPurchasableStock"],
                loseEmphasisOnFill: true,
              })}
            />
            <Input
              label={getMessage("store.max.purchaseable.qty")}
              placeholder="00"
              min={1}
              name="maxPurchasableStock"
              type="number"
              {...this.generateStateMappers({
                stateKeys: ["maxPurchasableStock"],
                loseEmphasisOnFill: true,
              })}
            />
            <Select
              label={getMessage("store.stock.orderType")}
              placeholder={getMessage("store.stock.orderType.placeholder")}
              name="orderType"
              options={[
                {
                  text: getMessage("order.type.pickup"),
                  value: "PICKUP",
                },
                {
                  text: getMessage("order.type.delivery"),
                  value: "DELIVERY",
                },
              ]}
              required
              {...this.generateStateMappers({
                stateKeys: ["orderType"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            />
          </div>
          <div className={`form-actions ${props.method}`}>
            <SubmitButton>{getMessage("brand.form.submitText")}</SubmitButton>
            <CancelButton>{getMessage("brand.form.cancelText")}</CancelButton>
          </div>
        </Form>
      </div>
    );
  }
}
