import React from "react";

function SourceCodeIcon(props) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="workfile-2-hm(ignore)"
        stroke="none"
        fill="none"
        fillRule="evenodd"
        strokeWidth="1"
      >
        <g id="Group-118">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            transform="translate(12, 12) scale(-1, -1) rotate(90) translate(-12, -12)"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
          <g
            id="Group-117"
            transform="translate(3, 7)"
            stroke="#000000"
            strokeLinecap="round"
            strokeWidth="1.5"
          >
            <g
              id="Up-2"
              transform="translate(2, 5) scale(-1, -1) rotate(90) translate(-2, -5)translate(-3, 3)"
            >
              <path
                d="M0,4 L1.74731,2.2369975 C3.1408,0.8310975 3.8375,0.1280975 4.6867,0.0198975 C4.8947,-0.0066325 5.1053,-0.0066325 5.3133,0.0198975 C6.1625,0.1280975 6.8592,0.8310975 8.2527,2.2369975 L10,4"
                id="Path"
              ></path>
            </g>
            <g
              id="Up-2-Copy"
              transform="translate(17, 5) scale(1, -1) rotate(90) translate(-17, -5)translate(12, 3)"
            >
              <path
                d="M0,4 L1.74731,2.2369975 C3.1408,0.8310975 3.8375,0.1280975 4.6867,0.0198975 C4.8947,-0.0066325 5.1053,-0.0066325 5.3133,0.0198975 C6.1625,0.1280975 6.8592,0.8310975 8.2527,2.2369975 L10,4"
                id="Path"
              ></path>
            </g>
            <line
              x1="10.5"
              y1="0.5"
              x2="8.16524056"
              y2="10"
              id="Path-71"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SourceCodeIcon;
