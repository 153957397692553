import React, { Component } from "react";
import Coupons from "../Coupons";
import { localTimeToUtc, getStandradDateTime } from "../../../lib/datetime";

import { getMessage } from "../../../lib/translator";

class BulkCoupons extends Component {
  render() {
    return (
      <div className="bulk-coupons">
        <Coupons
          title={getMessage("Bulk Coupons")}
          addHeading={getMessage("Add unique coupons")}
          editHeading={getMessage("Edit unique coupons")}
          api={{
            url: "/promo-service/bulk-coupon",
            transform: (response) => response.data.bulkCoupon,
          }}
          transformSubmit={(formData) => {
            const {
              validOnStores,
              comment,
              maxDiscountAmount,
              discountType,
              discount,
              type,
              validOnUser,
              validOnPayment,
              dateRange,
              validOnDays,
              minOrderAmount,
              userSet,
              timeRange,
              ...others
            } = formData;
            let response = Object.assign({}, others);
            response.coupon = {};
            response.coupon = {
              validOnStores,
              discount,
              discountType,
              type,
              validOnUser,
              validOnPayment,
              minOrderAmount,
              maxDiscountAmount,
              comment,
              userSet,
            };
            if (formData["validOnDays"]) {
              delete response["validOnDays"];
              let validOnDays = {};
              formData["validOnDays"].forEach(function (key) {
                validOnDays[key] = "1";
              });
              response.coupon.validOnDays = validOnDays;
            }
            if (formData.dateRange && formData.timeRange) {
              response.coupon.startDate = getStandradDateTime(
                localTimeToUtc,
                `${formData.dateRange.startDate} ${formData.timeRange.startTime}`
              );
              response.coupon.expiryDate = getStandradDateTime(
                localTimeToUtc,
                `${formData.dateRange.endDate} ${formData.timeRange.endTime}`
              );
              delete response["dateRange"];
              delete response["timeRange"];
            }
            if (formData.userSet) {
              response.coupon.userSet.type = "SEGMENTS";
            }
            return response;
          }}
          menu={this.props.menu}
          bulk
        />
      </div>
    );
  }
}

export default BulkCoupons;
