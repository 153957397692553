import React from "react";

const LeftArrow = ({ ...props }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>left arrow</title>
    <g
      id="left-arrow"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group" fill="#000000" fillRule="nonzero">
        <g
          id="utility/navigation/caret-right"
          transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
        >
          <path
            d="M9.4365,19.2378011 C9.08765897,19.2375117 8.77331413,19.0271905 8.63995255,18.7048478 C8.50659097,18.382505 8.58045562,18.0115715 8.827125,17.764905 L14.141625,12.450405 C14.3891879,12.2011327 14.3891879,11.7987923 14.141625,11.54952 L8.827125,6.23502 C8.49017057,5.89847148 8.4898415,5.35248946 8.82639002,5.01553502 C9.16293853,4.67858057 9.70892055,4.6782515 10.045875,5.0148 L15.360375,10.3293 C16.281761,11.2525118 16.281761,12.7473982 15.360375,13.67061 L10.045875,18.98511 C9.88455194,19.1471983 9.66518674,19.2381627 9.4365,19.2378011 L9.4365,19.2378011 Z"
            id="Color"
          />
          <rect
            id="caret-right"
            fillOpacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LeftArrow;
