import React from "react";

function ApproveIcon(props) {
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(1.25 1.25)" fill="none" fill-rule="evenodd">
        <circle fill="#06D6A0" cx="8.75" cy="8.75" r="8.75" />
        <path
          d="M13.92 5.469 7.358 12.03 4.375 9.048"
          stroke="#FFF"
          stroke-linejoin="round"
          stroke-width="1.22"
        />
      </g>
    </svg>
  );
}

export default ApproveIcon;
