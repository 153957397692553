import React, { Suspense, lazy } from "react";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader";

export const LazyComponent = (dynamicImport) => {
  const Component = lazy(dynamicImport);
  Component.preLoad = dynamicImport;
  return Component;
};

const LazyComponentLoader = ({ Component, ...props }) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};

export default withRouter(LazyComponentLoader);
