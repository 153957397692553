import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Menu, { NavigationDrawer } from "../../components/Menu";
import { getMessage } from "../../lib/translator";
import ErrorBoundary, { notifyBugsnag } from "../../components/ErrorBoundary";
import {
  isExtensionEnabled,
  getStores,
  setStores,
  getSession,
} from "../../lib/auth";
import { set, get } from "../../lib/storage";
import API from "../../lib/api";
// import VerifyEmailWarning from "../../components/VerifyEmailWarning";

const httpStatusMessages = {
  403: getMessage("error.server.403"),
};
export default class AuthenticatedPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      httpStatus: 200,
      frontendUrl: "",
    };
    this.title = props.title;
    this.handleError = this.handleError.bind(this);
  }
  componentWillMount() {
    window.addEventListener("unhandledrejection", this.handleError);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.handleError);
  }

  componentDidMount() {
    if (isExtensionEnabled("MultiStoreSupport")) {
      if (!getStores()) {
        const api = new API({ url: "/account-service/store" });
        api
          .get({ paginate: "false" })
          .then((response) => {
            let stores = response.data.store.reverse();
            let user = getSession() && getSession().user;
            if (user && user.stores && user.stores.length > 0) {
              stores = user.stores.reverse();
              setStores(stores);
              set("store", stores[0].id);
            }
            setStores(stores);
            set("store", stores[0].id);
            this.setState({
              stores,
              storeId: stores[0].id,
            });
            this.props.setApiParam && this.props.setApiParam(stores[0].id);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.setState({
          stores: getStores(),
        });
      }
    } else {
      if (!get("store")) {
        const orgId = getSession().organization && getSession().organization.id;
        if (orgId) {
          const api = new API({
            url: `/account-service/organization/${orgId}`,
          });
          api
            .get()
            .then((response) => {
              const storeId =
                response.data.organization.defaultStore &&
                response.data.organization.defaultStore.id;
              const stores = [response.data.organization.defaultStore];
              set("store", storeId);
              setStores(stores);
              this.setState({
                storeId: storeId,
                stores,
              });
            })
            .catch((error) => {
              console.error(error);
            });
        }
      } else {
        this.setState({
          stores: getStores(),
          storeId: get("store"),
        });
      }
    }
    this.basicConfigApi = new API({ url: "/config-service/config/basic" });
    this.basicConfigApi
      .get()
      .then((response) => {
        this.setState({
          frontendUrl: response?.data?.basic?.frontendUrl,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleError(e) {
    e.preventDefault();
    const newState = Object.assign({}, this.state);
    if (e.reason.code === 401) {
      newState.redirectToLogin = true;
    } else if (e.reason.message === "cancelled") {
      // do nothing
    } else {
      newState.httpStatus = e.reason.code;
      newState.error = e.reason.message;
      if (e.reason.code !== 401 && e.reason.code !== 403) {
        notifyBugsnag(e.reason.message || e, e.reason);
      }
    }
    console.error(e.reason.message);
    this.setState(newState);
  }

  updateStore(storeId) {
    this.setState(
      {
        storeId: storeId,
      },
      () => this.props.onChange && this.props.onChange()
    );
  }

  render() {
    const { props } = this;
    let menuProps = {
      items: props.menu,
    };
    if (props.storeDependent && isExtensionEnabled("MultiStoreSupport")) {
      menuProps.stores = this.state.stores;
      menuProps.storeId = this.state.storeId;
    }
    if (props.showLanguageDropDown) {
      menuProps.showLanguageDropDown = true;
    }
    // const userData = JSON.parse(get("user")) || {};
    // const isUserVerified =
    //   userData?.isOwner &&
    //   (userData?.verified || userData?.emails?.[0].verified);

    return this.state.redirectToLogin ? (
      <Redirect to="/user/logout" />
    ) : (
      <div id="app">
        {/* {userData?.id && !isUserVerified && <VerifyEmailWarning />} */}
        <div
          className={[
            "app-main-wrp",
            // userData?.id && !isUserVerified ? "verify-warning" : "",
          ].join(" ")}
        >
          {props.menu ? (
            <Menu
              {...menuProps}
              frontendUrl={this.state.frontendUrl}
              onChange={(e) => this.updateStore(e)}
            />
          ) : null}
          <main className={props.className}>
            {props.menu ? <NavigationDrawer /> : null}
            <ErrorBoundary title={this.title} showHeader={false}>
              {this.state.error ? (
                <div>
                  <h1>{this.title}</h1>
                  {httpStatusMessages[this.state.httpStatus] ||
                    getMessage("error.server")}
                </div>
              ) : (
                props.children
              )}
            </ErrorBoundary>
          </main>
        </div>
      </div>
    );
  }
}
