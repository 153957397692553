import React from "react";
import Table, { Row, Cell } from "../../../components/Table";
import NameValueCard from "../../../components/NameValueCard";
import IncrementIcon from "./increase-icon.svg";
import DecrementIcon from "./decrease-icon.svg";
import { getMessage } from "../../../lib/translator";

// Formats Date object in YYYY-MM-DD format
function formatDate(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1; // January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return yyyy + "-" + mm + "-" + dd;
}

// calculates percentage change in old value and new Value
// Returns an element with change Icon and value if oldValue is not 0
function getPercentageChange(todayStats, previousStats, key) {
  const newValue = todayStats;
  const oldValue = previousStats;
  if (Number(oldValue) && oldValue > 0 && Number(newValue)) {
    const value = ((newValue - oldValue) / oldValue) * 100;
    let attr = { className: "green", icon: IncrementIcon };
    if (value < 0) {
      attr = { className: "red", icon: DecrementIcon };
    }
    return (
      <div className={attr.className}>
        <img src={attr.icon} alt="" />
        {`${Math.abs(Math.round(value))}%`}
      </div>
    );
  }
  return <span className="text-muted">-</span>;
}

// calculates last week average data for all sales and customer parameters
function getLastWeekAverage(salesStats, customerStats) {
  const today = formatDate(new Date());
  let totalDays = 0;
  let avgData = {
    amount: 0,
    customersAcquired: 0,
    transactedCustomers: 0,
    count: 0,
  };
  for (let key in customerStats) {
    if (key !== today) {
      avgData.customersAcquired += customerStats[key];
      avgData.amount += salesStats[key] && salesStats[key].amount;
      avgData.transactedCustomers +=
        salesStats[key] && salesStats[key].transactedCustomers;
      avgData.count += salesStats[key] && salesStats[key].count;
      totalDays++;
    }
  }
  if (totalDays > 0) {
    for (let key in avgData) {
      avgData[key] = avgData[key] / totalDays;
    }
  }
  return avgData;
}

function getStatsTableData(salesStats, customerStats, currency) {
  let oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  let yesterdayDate = new Date();
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const lastWeekDate = formatDate(oneWeekAgo);
  const today = formatDate(new Date());
  const yesterday = formatDate(yesterdayDate);

  const stats = {
    today: salesStats[today] || {},
    yesterday: salesStats[yesterday] || {},
    lastWeek: salesStats[lastWeekDate] || {},
    lastWeekAverage: getLastWeekAverage(salesStats, customerStats),
  };
  return [
    {
      name: getMessage("operations.dashboard.sales"),
      color: "green",
      value: `${currency} ${
        isNaN(stats.today.amount) ? 0 : stats.today.amount
      }`,
      yesterdayPer: getPercentageChange(
        stats.today.amount,
        stats.yesterday.amount
      ),
      weeklyPer: getPercentageChange(
        stats.today.amount,
        stats.lastWeekAverage.amount
      ),
      lastWeekPer: getPercentageChange(
        stats.today.amount,
        stats.lastWeek.amount
      ),
    },
    {
      name: getMessage("operations.dashboard.customerAcquired"),
      color: "gray",
      value: isNaN(customerStats[today]) ? 0 : customerStats[today],
      yesterdayPer: getPercentageChange(
        customerStats[today],
        customerStats[yesterday]
      ),
      weeklyPer: getPercentageChange(
        customerStats[today],
        stats.lastWeekAverage.customersAcquired
      ),
      lastWeekPer: getPercentageChange(
        customerStats[today],
        customerStats[lastWeekDate]
      ),
    },
    {
      name: getMessage("operations.dashboard.customersTransacted"),
      color: "red",
      value: isNaN(stats.today.transactedCustomers)
        ? 0
        : stats.today.transactedCustomers,
      yesterdayPer: getPercentageChange(
        stats.today.transactedCustomers,
        stats.yesterday.transactedCustomers
      ),
      weeklyPer: getPercentageChange(
        stats.today.transactedCustomers,
        stats.lastWeekAverage.transactedCustomers
      ),
      lastWeekPer: getPercentageChange(
        stats.today.transactedCustomers,
        stats.lastWeek.transactedCustomers
      ),
    },
    {
      name: getMessage("operations.dashboard.orders"),
      color: "blue",
      value: isNaN(stats.today.count) ? 0 : stats.today.count,
      yesterdayPer: getPercentageChange(
        stats.today.count,
        stats.yesterday.count
      ),
      weeklyPer: getPercentageChange(
        stats.today.count,
        stats.lastWeekAverage.count
      ),
      lastWeekPer: getPercentageChange(stats.today.count, stats.lastWeek.count),
    },
  ];
}

export default function StatsTable(props) {
  const { customersStats, salesStats, currency } = props;
  const data = getStatsTableData(salesStats, customersStats, currency);
  return (
    <div className="stats-table">
      {data.map((column, index) => (
        <Table key={index}>
          <div className="table-header">
            {index === 0 && <div className="table-cell" />}
            <div className="table-cell">
              <NameValueCard
                name={column.name}
                value={column.value}
                color={column.color}
              />
            </div>
          </div>
          <div className="table-caption">
            <NameValueCard
              name={column.name}
              value={column.value}
              color={column.color}
            />
          </div>
          <Row className="first-row">
            <Cell className="first-column">
              <small>{getMessage("operations.dashboard.yesterday")}</small>
            </Cell>
            <Cell>{column.yesterdayPer}</Cell>
          </Row>
          <Row>
            <Cell className="first-column">
              <small>{getMessage("operations.dashboard.weeklyAvg")}</small>
            </Cell>
            <Cell>{column.weeklyPer}</Cell>
          </Row>
          <Row>
            <Cell className="first-column">
              <small>{getMessage("operations.dashboard.lastWeek")}</small>
            </Cell>
            <Cell>{column.lastWeekPer}</Cell>
          </Row>
        </Table>
      ))}
    </div>
  );
}
