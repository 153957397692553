import React from "react";
import ProductCollection from "../../../../components/Layout/ProductCollection";
import { Input, Radio, Searchable } from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";

const getChain = (blog) => {
  let chain = [];
  let reference = blog;
  while (reference) {
    chain.push(reference.title);
    reference = reference.url;
  }
  return chain.reverse().join(" > ");
};

function renderListElement(suggestion, valueKey, nameKey, onSelect) {
  return (
    <li
      className="select-option"
      key={suggestion[valueKey]}
      onClick={(e) => onSelect(suggestion)}
    >
      {suggestion[nameKey]}
    </li>
  );
}

const BlogCollectionLayout = () => {
  return {
    preview: ProductCollection,
    fields: ({ getState, updateState, parent, page }) => {
      return (
        <div className="blog-collection">
          <div className="form-sections">
            <div className="form-section">
              <Input
                label={getMessage("themes.bc.name")}
                placeholder={getMessage("themes.bc.name.placeholder")}
                value={getState(["title"]) || ""}
                onChange={(e) => {
                  updateState(["title"], e);
                }}
              />
            </div>
            <div className="form-section">
              <Input
                label={getMessage("subtitle")}
                placeholder={getMessage("Enter subtitle ")}
                value={getState(["subtitle"]) || ""}
                onChange={(e) => {
                  updateState(["subtitle"], e);
                }}
              />
            </div>
            <div className="form-section">
              <Radio
                label={getMessage("themes.layoutType")}
                name="layoutType"
                options={[
                  {
                    text: getMessage("themes.scroller"),
                    value: "SCROLLER",
                  },
                  {
                    text: getMessage("themes.grid"),
                    value: "GRID",
                  },
                ]}
                value={getState(["layoutType"])}
                onChange={(e) => {
                  updateState(["layoutType"], e);
                }}
              />
            </div>
          </div>
          <div className={`form-sections blog-tag-search ${page}-page`}>
            {
              <Searchable
                label={getMessage("menu.item.blog")}
                placeholder={getMessage("themes.bc.blog.placeholder")}
                name="blogCollection"
                className={`blog-search blog-container`}
                multiple
                searchUrl="/blog-service/blog"
                valueKey="id"
                draggable
                responseKey="blog"
                nameKey="displayName"
                searchKey="title"
                displaySelectedValue={(blog) => {
                  return getChain(blog) ? blog.title : `${getChain(blog)}`;
                }}
                renderListElement={renderListElement}
                transformRequest={(searchText, paramsDefault) => {
                  let params = Object.assign({}, paramsDefault);
                  params.title = `%${params.title}`;
                  return params;
                }}
                transformResponse={(response) => {
                  let blogs = response.data.blog;
                  blogs = (blogs || []).map((blog) => {
                    blog.displayName = `${blog.title}`;
                    blog.id = `${blog.id}`;
                    return blog;
                  });
                  return blogs;
                }}
                value={getState(["blog"])}
                onChange={(e) => {
                  updateState(["blog"], e);
                }}
              />
            }
          </div>
        </div>
      );
    },
  };
};

export default BlogCollectionLayout;