import React from "react";
import ProductCollection from "../../../../components/Layout/ProductCollection";
import {
  Input,
  Radio,
  Checkbox,
  Searchable,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import { isExtensionEnabled } from "../../../../lib/auth";

const getChain = (category) => {
  let chain = [];
  let reference = category;
  while (reference) {
    chain.push(reference.name);
    reference = reference.parentCategory;
  }
  return chain.reverse().join(" > ");
};

function renderListElement(suggestion, valueKey, nameKey, onSelect) {
  return (
    <li
      className="select-option"
      key={suggestion[valueKey]}
      onClick={(e) => onSelect(suggestion)}
    >
      {suggestion[nameKey] &&
      (suggestion[nameKey].split("")[0] === "C" ||
        suggestion[nameKey].split("")[0] === "T")
        ? suggestion[nameKey]
            .split("")
            .splice(2, suggestion[nameKey].length)
            .join("")
        : suggestion[nameKey]}
    </li>
  );
}

const CategoryCollectionLayout = () => {
  return {
    preview: ProductCollection,
    fields: ({ getState, updateState, parent, page }) => {
      return (
        <div className="category-collection">
          <div className="form-sections">
            <div className="form-section">
              <Input
                label={getMessage("themes.cc.name")}
                placeholder={getMessage("themes.cc.name.placeholder")}
                value={getState(["title"]) || ""}
                onChange={(e) => {
                  updateState(["title"], e);
                }}
              />
            </div>
            <div className="form-section">
              <Input
                label={getMessage("subtitle")}
                placeholder={getMessage("Enter subtitle ")}
                value={getState(["subtitle"]) || ""}
                onChange={(e) => {
                  updateState(["subtitle"], e);
                }}
              />
            </div>
            <div className="form-section">
              <Radio
                label={getMessage("themes.cc.layoutType")}
                name="layoutType"
                options={[
                  {
                    text: getMessage("themes.cc.scroller"),
                    value: "SCROLLER",
                  },
                  {
                    text: getMessage("themes.cc.grid"),
                    value: "GRID",
                  },
                ]}
                value={getState(["layoutType"])}
                onChange={(e) => {
                  updateState(["layoutType"], e);
                }}
              />
            </div>
          </div>
          <div className={`form-sections category-tag-search ${page}-page`}>
            {
              <Searchable
                label={getMessage("themes.cc.categories")}
                placeholder={getMessage("themes.cc.categories.placeholder")}
                name="categoryCollection"
                className={`category-search  ${
                  !isExtensionEnabled("ProductTagSupport")
                    ? "category-container"
                    : ""
                }`}
                multiple
                searchUrl="/catalogue-service/category"
                valueKey="id"
                draggable
                responseKey="category"
                nameKey="displayName"
                searchKey="name"
                displaySelectedValue={(category) => {
                  return getChain(category)
                    ? category.type
                      ? category.name
                      : `C ${getChain(category)}`
                    : "";
                }}
                renderListElement={renderListElement}
                transformRequest={(searchText, paramsDefault) => {
                  let params = Object.assign({}, paramsDefault);
                  params.name = `%${params.name}`;
                  return params;
                }}
                transformResponse={(response) => {
                  let categories = response.data.category;
                  categories = (categories || []).map((category) => {
                    category.displayName = `C ${getChain(category)}`;
                    // category.name = `C ${category.name}`
                    category.id = `C${category.id}`;
                    return category;
                  });
                  return categories;
                }}
                value={getState(["category"])}
                onChange={(e) => {
                  updateState(["category"], e);
                }}
              />
            }
            {isExtensionEnabled("ProductTagSupport") && (
              <Searchable
                label={getMessage("themes.cc.tags")}
                placeholder={getMessage("themes.cc.tags")}
                name="tag"
                className="tag-search"
                searchUrl="/catalogue-service/tag"
                draggable
                valueKey="id"
                responseKey="tag"
                nameKey="name"
                searchKey="name"
                transformResponse={(response) => {
                  let tags = response.data.tag || [];
                  tags = (tags || []).map((tag) => {
                    tag.name = `T ${tag.name}`;
                    tag.id = `T${tag.id}`;
                    return tag;
                  });
                  return tags;
                }}
                multiple
                displaySelectedValue={(category) => {
                  return getChain(category)
                    ? category.type
                      ? category.name
                      : `C ${getChain(category)}`
                    : "";
                }}
                renderListElement={renderListElement}
                value={getState(["category"])}
                onChange={(e) => {
                  updateState(["category"], e);
                }}
              />
            )}
            {/* {page === 'category' && <div className='form-section no-label no-margin'>
              <Checkbox
                name='subCategories'
                label='hello'
                inlineLabel={getMessage('themes.cc.subCategories')}
                value={getState(['subcategories'])}
                onChange={e => {
                  updateState(['subcategories'], e)
                }}
              />
            </div>} */}
          </div>
          <div className="form-sections">
            <div className="form-section no-label config">
              <p>{getMessage("themes.cc.config")}</p>
              {/* <Checkbox
                label='Hello'
                name='subCategory'
                inlineLabel={getMessage('themes.cc.showSubCategory.inline')}
                value={getState(['showSubCategoryLinks'])}
                onChange={e => {
                  updateState(['showSubCategoryLinks'], e)
                }}
              /> */}
              <Checkbox
                label="hello"
                name="pdtCount"
                inlineLabel={getMessage("themes.cc.pdtCount.inline")}
                value={getState(["showProductCount"])}
                onChange={(e) => {
                  updateState(["showProductCount"], e);
                }}
              />
              <Checkbox
                label={getMessage("themes.cc.config.image")}
                name="bannerImage"
                inlineLabel={getMessage("themes.cc.config.image")}
                value={getState(["fullImage"])}
                onChange={(e) => {
                  updateState(["fullImage"], e);
                }}
              />
            </div>
          </div>
        </div>
      );
    },
  };
};

export default CategoryCollectionLayout;
