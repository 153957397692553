import React from "react";
import ReactDOM from "react-dom";
import { Dialog } from "../../../../../components/Popup";

import { getMessage } from "../../../../../lib/translator";

import "./style.css";

const ActionButtons = ({ onSubmit, onCancel, error, disabled, setError }) => {
  return ReactDOM.createPortal(
    <div className="action-btn-container">
      <Dialog
        show={!!error}
        message={getMessage("deliveryArea.dialog.error.title")}
        information={error}
        closeText={getMessage("basicInfo.save.error.closeText")}
        close={() => setError()}
      />
      <div className="action-buttons">
        <button onClick={onCancel} className="button">
          {getMessage("extensions.deliveryArea.cancel")}
        </button>
        <button
          onClick={onSubmit}
          className="button primary"
          disabled={disabled}
        >
          {getMessage("extensions.deliveryArea.save")}
        </button>
      </div>
    </div>,
    document.getElementById("delivery-area-form-action-btn")
  );
};

export default ActionButtons;
