import React, { useState } from "react";
import ListingPage from "../../../../containers/ListingPage";
import { getMessage } from "../../../../lib/translator";
import emptyIcon from "./noOrder.svg";
import tabularView from "./Views/tabularView";
import CreateNewPackageForm from "./CreateNewPackageForm.js";
import { Dialog, Popup } from "../../../../components/Popup";
import API from "../../../../lib/api";
import { getSession } from "../../../../lib/auth";
import "./style.css";

const CreateNewPackage = (props) => {
  return (
    <div className="text-center">
      <button className="primary button" onClick={props.handleClickShowPackage}>
        +{" "}
        <span className="text">
          {getMessage("orderPackageConfig.createNew.heading")}
        </span>
      </button>
    </div>
  );
};

const emptyState = (handleClickShowPackage) => ({
  icon: emptyIcon,
  message: getMessage("orderPackageConfig.package.emptyMsg"),
  actions: () => (
    <CreateNewPackage handleClickShowPackage={handleClickShowPackage} />
  ),
});

const PackagesTable = (props) => {
  const organizationId = getSession()?.organization?.id?.toString?.();

  const [showPackagePopup, setShowPackagePopup] = useState(false);
  const [createPackageError, setCreatePackageError] = useState(false);
  const [createPackageSuccess, setCreatePackageSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [editPackage, setEditPackage] = useState(null);
  const [deletePackage, setDeletePackage] = useState(null);
  const [listingKey, setListingKey] = useState("");
  const [isPackageEditInfo, setIsPackageEditInfo] = useState(false);

  const handleCloseCreatePackage = () => {
    setShowPackagePopup(false);
    setEditPackage(null);
  };
  const handleClickShowPackage = () => {
    setShowPackagePopup(true);
  };

  const handleSubmitAddPackage = (formData) => {
    setSubmitting(true);
    const createPackageAPI = new API({ url: "/order-service/package" });
    createPackageAPI
      .post({ organizationId, ...formData })
      .then((response) => {
        setSubmitting(false);
        setIsPackageEditInfo(false);
        handleCloseCreatePackage();
        setCreatePackageSuccess(true);
        setListingKey(JSON.stringify(response));
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        setIsPackageEditInfo(false);
        handleCloseCreatePackage();
        setCreatePackageError(true);
      });
  };
  const handleSubmitEditPackage = (formData) => {
    setSubmitting(true);
    const editPackageAPI = new API({
      url: `/order-service/package/${editPackage?.id}`,
    });
    editPackageAPI
      .put({ organizationId, ...formData })
      .then((response) => {
        setSubmitting(false);
        setIsPackageEditInfo(true);
        setCreatePackageSuccess(true);
        handleCloseCreatePackage();
        setListingKey(JSON.stringify(response));
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        setIsPackageEditInfo(true);
        handleCloseCreatePackage();
        setCreatePackageError(true);
      });
  };
  const handleSubmitDeletePackage = () => {
    const deletePackageAPI = new API({
      url: `/order-service/package/${deletePackage}`,
    });
    deletePackageAPI
      .delete({ organizationId })
      .then((response) => {
        setListingKey(JSON.stringify(response) + deletePackage);
        setDeletePackage(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const dismissPackageError = () => {
    setCreatePackageError(false);
  };
  const dismissPackageSuccess = () => {
    setCreatePackageSuccess(false);
  };

  const handleEditPackage = (orderPackage) => {
    setEditPackage({ ...orderPackage });
  };
  const handleDeletePackage = (orderPackageId) => {
    setDeletePackage(orderPackageId);
  };
  const dismissDeletePackage = () => {
    setDeletePackage(null);
  };

  const errorDialogTitle = isPackageEditInfo
    ? getMessage("orderPackageConfig.editPackage.errorMsg")
    : getMessage("orderPackageConfig.createPackage.errorMsg");
  const errorDialogInfo = isPackageEditInfo
    ? getMessage("orderPackageConfig.editPackage.errorMsg.dsc")
    : getMessage("orderPackageConfig.createPackage.errorMsg.dsc");

  const successDialogTitle = isPackageEditInfo
    ? getMessage("orderPackageConfig.editPackage.successMsg")
    : getMessage("orderPackageConfig.createPackage.successMsg");
  const successDialogInfo = isPackageEditInfo
    ? getMessage("orderPackageConfig.editPackage.successMsg.dsc")
    : getMessage("orderPackageConfig.createPackage.successMsg.dsc");

  return (
    <>
      <ListingPage
        helmetTitle="Zopping - Packaging"
        key={listingKey}
        className="packaging-page"
        menu={props.menu}
        title={getMessage("menu.item.order-package-config")}
        api={{
          url: "/order-service/package",
          params: { organizationId },
          updateApiParams: props.updateApiParams ? props.updateApiParams : null,
          transform: (response) => response.data.package,
        }}
        headerActions={() =>
          props.url.includes("settings") && (
            <CreateNewPackage handleClickShowPackage={handleClickShowPackage} />
          )
        }
        emptyState={emptyState(handleClickShowPackage)}
        tableProperties={tabularView(handleEditPackage, handleDeletePackage)}
      />

      <Popup
        show={showPackagePopup || editPackage}
        close={handleCloseCreatePackage}
        heading={
          editPackage
            ? getMessage("orderPackageConfig.editPackage.heading")
            : getMessage("orderPackageConfig.createNew.heading")
        }
      >
        <CreateNewPackageForm
          onSubmit={
            editPackage ? handleSubmitEditPackage : handleSubmitAddPackage
          }
          submitting={submitting}
          editPackage={editPackage}
        />
      </Popup>
      <Dialog
        title={errorDialogTitle}
        information={errorDialogInfo}
        show={createPackageError}
        close={dismissPackageError}
        closeText={getMessage("ok.text")}
      />
      <Dialog
        className="success"
        title={successDialogTitle}
        information={successDialogInfo}
        show={createPackageSuccess}
        close={dismissPackageSuccess}
        closeText={getMessage("ok.text")}
      />

      <Dialog
        title={getMessage("orderPackageConfig.deletePackage.heading")}
        information={getMessage("orderPackageConfig.deletePackage.subHeading")}
        show={deletePackage}
        close={dismissDeletePackage}
        closeText={getMessage("logistics.config.no")}
        onOk={handleSubmitDeletePackage}
        okText={getMessage("orderPackageConfig.delete.text")}
      />
    </>
  );
};

export default PackagesTable;
