import React from "react";
import { BaseForm, Input, Checkbox, Select } from "../../../../components/Form";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import Loader from "../../../../components/Loader";
import API from "../../../../lib/api";
import { getMessage } from "../../../../lib/translator";
import { cloneMutables } from "../../../../lib/stateManagement";
import Table, { Row, Cell, Header } from "../../../../components/Table";

import "./style.css";

class FileDispute extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      values: {},
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    const referenceNumber = this.props.match.params.id;
    const api = new API({ url: `/order-service/order/${referenceNumber}` });
    const disputeReasonsApi = new API({ url: "/order-service/dispute-reason" });
    api
      .get()
      .then((response) => {
        let order = response.data.order;
        this.setState({
          values: order,
          loading: false,
          referenceNumber,
        });
      })
      .catch((error) => {
        console.error(error);
      });
    disputeReasonsApi.get().then((response) => {
      let disputeReasons = response.data.disputereason;
      let reasonResMap = {};
      let reasons = [];
      disputeReasons.forEach((reason) => {
        reasons.push({
          text: reason.name,
          value: reason.id,
        });
        let resolutions = [];
        reason.resolutions.forEach((resolution) => {
          // if (resolution.substituteResolutions) {
          //   resolutions.push({
          //     text: resolution.substituteResolutions,
          //     value: resolution.id
          //   })
          // }
          resolutions.push({
            text: resolution.name,
            value: resolution.id,
          });
        });
        reasonResMap[reason.id] = resolutions;
      });
      this.setState({
        reasonResMap,
        reasons,
      });
    });
  }

  onSubmit(formData) {
    let data = cloneMutables(formData);
    let disputedItems = data.items.filter((item) => item.dispute);
    let params = {};
    let items = [];
    disputedItems.forEach((item) => {
      items.push({
        orderItemId: item.orderDetails.orderItemId,
        quantity: item.quantity,
        reasonId: item.reasonId,
        resolutionId: item.resolutionId,
      });
    });
    params.items = items;

    const api = new API({
      url: `/order-service/order/${this.state.referenceNumber}/dispute`,
    });
    api
      .post(params)
      .then(
        (response) => {
          this.props.history.goBack();
        },
        (error) => {
          this.setState({
            formError: error.message,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const { loading, referenceNumber, values, formError } = this.state;
    const items = values && values.items;
    const maxvalue =
      values.status === "COMPLETED" ? "deliveredQuantity" : "orderedQuantity";
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <h1 className="title heading">
          {getMessage("order.dispute.heading")}
          {" #"}
          <strong className="reference-number">{referenceNumber}</strong>
        </h1>
        <div className="file-dispute-form">
          {loading ? (
            <Loader />
          ) : (
            <Form>
              {formError && <div className="form-error">{formError}</div>}
              <Table>
                <Header>
                  <Cell>{getMessage("dispute.name")}</Cell>
                  <Cell>{getMessage("dispute.quantity")}</Cell>
                  <Cell>{getMessage("dispute.reason")}</Cell>
                  <Cell>{getMessage("dispute.resolution")}</Cell>
                </Header>
                {items &&
                  items.map((item, index) => {
                    let name =
                      item[item.entityType.toLowerCase()].fullName ||
                      item[item.entityType.toLowerCase()].name;
                    let orderItemId = item.orderDetails.orderItemId;
                    return (
                      <Row key={`item-${index}`} className="return-item">
                        <Cell>
                          <Checkbox
                            inlineLabel={name}
                            key={orderItemId}
                            name={`dispute-${index}`}
                            value={this.getState(["items", index, "dispute"])}
                            onChange={(e) =>
                              this.updateState(["items", index, "dispute"], e)
                            }
                            checked={this.getState(["items", index, "dispute"])}
                          />
                        </Cell>
                        <Cell>
                          <Input
                            key={`quantity-${index}`}
                            name={`quantity-${index}`}
                            type="number"
                            step={item.product.isSoldByWeight ? "0.01" : "1"}
                            max={item.orderDetails[maxvalue]}
                            min={0}
                            siblings={{
                              after: [
                                <div
                                  key={`total-${index}`}
                                  className="return-qty"
                                >{`/${Number(
                                  item.orderDetails[maxvalue]
                                ).toFixed(2)}`}</div>,
                              ],
                            }}
                            value={
                              item.product.isSoldByWeight
                                ? Number(
                                    this.getState(["items", index, "quantity"])
                                  ).toFixed(2)
                                : Number(
                                    this.getState(["items", index, "quantity"])
                                  ).toFixed(0)
                            }
                            {...this.generateStateMappers({
                              stateKeys: ["items", index, "quantity"],
                            })}
                          />
                        </Cell>
                        <Cell>
                          <Select
                            key={`reason-${index}`}
                            options={this.state.reasons}
                            placeholder={getMessage(
                              "dispute.reason.placeholder"
                            )}
                            {...this.generateStateMappers({
                              stateKeys: ["items", index, "reasonId"],
                              loseEmphasisOnFill: true,
                            })}
                          />
                        </Cell>
                        <Cell>
                          <Select
                            key={`resolution-${index}`}
                            placeholder={getMessage(
                              "dispute.resolution.placeholder"
                            )}
                            options={
                              this.state.reasonResMap[
                                this.getState(["items", index, "reasonId"])
                              ]
                            }
                            {...this.generateStateMappers({
                              stateKeys: ["items", index, "resolutionId"],
                              loseEmphasisOnFill: true,
                            })}
                          />
                        </Cell>
                      </Row>
                    );
                  })}
              </Table>
              <div className="form-action">
                <SubmitButton>{getMessage("dispute.submit")}</SubmitButton>
                <button
                  type="button"
                  className="button"
                  onClick={() => this.props.history.goBack()}
                >
                  {getMessage("dispute.cancel")}
                </button>
              </div>
            </Form>
          )}
        </div>
      </AuthenticatedPage>
    );
  }
}

export default FileDispute;
