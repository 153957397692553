import React, { Component } from "react";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import { getMessage } from "../../../lib/translator";
import OrderManagementForm from "./Form";

class OrderManagement extends Component {
  render() {
    return (
      <AuthenticatedPage
        className="Order-management"
        menu={this.props.menu}
        title={getMessage("orderConfig.heading")}
      >
        <h1 className="title">{getMessage("orderConfig.heading")}</h1>
        <OrderManagementForm />
      </AuthenticatedPage>
    );
  }
}

export default OrderManagement;
