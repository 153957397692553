import React from "react";
import { BaseForm, Input, VALIDATION_TYPES } from "../../../../components/Form";
import Loader from "../../../../components/Loader";
import { getMessage } from "../../../../lib/translator";
import "./style.css";

export default class EndTripForm extends BaseForm {
  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    const { loading, endKm } = this.props;

    return loading ? (
      <Loader />
    ) : (
      <Form className="end-trip-form">
        <Input
          label={getMessage("trips.endTrip.label")}
          placeholder={getMessage("trips.endTrip.placeholder")}
          type="text"
          name="endTripKm"
          required
          {...this.generateStateMappers({
            stateKeys: ["endTripKm"],
            defaultValue: endKm,
            validationType: VALIDATION_TYPES.ONSUBMIT,
          })}
        />
        <div className={`form-actions`}>
          <SubmitButton>
            {getMessage("trips.endTrip.submitButton")}
          </SubmitButton>
          <CancelButton>
            {getMessage("trips.dialog.closeTrip.cancelButton")}
          </CancelButton>
        </div>
      </Form>
    );
  }
}
