const data = {
  "analytics.reports.heading": "Reports",
  "analytics.report.modal.title": "Download Report",
  "analytics.report.send.email": "Send to Email",
  "analytics.report.processing": "Processing",
  "analytics.report.recent": "Recent Reports",
  "analytics.report.dateRange": "Select Date Range",
};

export default data;
