import React from "react";
import "./style.css";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import SocialMediaForm from "./form";
import { getMessage } from "../../../lib/translator";

export default function SocialMedia(props) {
  return (
    <AuthenticatedPage
      className="SocialMedia"
      menu={props.menu}
      title={getMessage("contactDetails.heading")}
    >
      <h1 className="title">{getMessage("contactDetails.heading")}</h1>
      <SocialMediaForm />
    </AuthenticatedPage>
  );
}
