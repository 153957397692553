import React, { Component } from "react";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import Loader from "../../../components/Loader";
import API from "../../../lib/api";
import DefaultPaymentConfiguration from "./Default";
import CustomPaymentConfiguration from "./Custom";
import { getMessage } from "../../../lib/translator";

import "./style.css";

const PaymentChoices = (props) => (
  <div className="gateway-choices">
    {props.options.map(({ title, description }, index) => (
      <div className="gateway-choice" key={index}>
        {index > 0 && <span className="border-text">OR</span>}
        <h4>{title}</h4>
        <p className="description">{description}</p>
        <div className="button-container">
          <button
            type="button"
            className="button primary choice-selection-button"
            onClick={() => {
              props.onSelect && props.onSelect(index);
            }}
          >
            Choose
          </button>
        </div>
      </div>
    ))}
  </div>
);

export default class PaymentConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gatewayChoice: -1,
      addedGateways: [],
      showLoader: false,
    };
    this.getAccountStatus = this.getAccountStatus.bind(this);
    this.switchToCustom = this.switchToCustom.bind(this);
    this.switchToDefault = this.switchToDefault.bind(this);
  }

  componentDidMount() {
    this.getAccountStatus();
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
  }

  getAccountStatus() {
    this.setState({
      showLoader: true,
    });
    this.api = new API({ url: "/account-service/payment-account" });
    this.api
      .get()
      .then((response) => {
        let paymentAccount = response.data.paymentaccount;
        if (Array.isArray(paymentAccount)) {
          paymentAccount = paymentAccount[0] || {};
        }
        let accountType = paymentAccount.type;

        if (!accountType) {
          // this.back = () => this.setState({ gatewayChoice: -1 })
          this.back = this.props.history.goBack;
          this.setState({
            gatewayChoice: 0, // -1 after our payment gateway (zoppay) integration is enabled
          });
        } else {
          this.back = this.props.history.goBack;
          if (accountType === "DEFAULT") {
            this.setState({
              // addedGateways: paymentAccount.paymentModes, Use this for zoppay
              addedGateways: paymentAccount.paymentGateways || [],
              gatewayChoice: 0, // 1 after zoppay is enabled
            });
          } else {
            this.setState({
              addedGateways: paymentAccount.paymentGateways || [],
              gatewayChoice: 0,
            });
          }
        }
        this.setState({
          showLoader: false,
          id: paymentAccount.id,
          paymentAccount: paymentAccount,
          accountType: accountType,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  switchToCustom() {
    let addedGateways = [];
    let paymentGateways = this.state.paymentAccount.paymentGateways;
    if (paymentGateways) {
      addedGateways = paymentGateways;
    }
    this.setState({
      gatewayChoice: 0,
      addedGateways: addedGateways,
    });
  }

  switchToDefault() {
    let addedGateways = [];
    let paymentModes = this.state.paymentAccount.paymentModes;

    if (paymentModes) {
      addedGateways = paymentModes;
    }
    this.setState({
      gatewayChoice: 1,
      addedGateways: addedGateways,
    });
  }

  render() {
    const gatewayChoices = [
      CustomPaymentConfiguration,
      DefaultPaymentConfiguration,
    ];
    const GatewayConfiguration =
      this.state.gatewayChoice > -1
        ? gatewayChoices[this.state.gatewayChoice]
        : null;
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        className="payment-configuration"
        title={getMessage("settings.online.payment.onlinepayment")}
      >
        <h1 className="title">
          {getMessage("settings.online.payment.onlinepayment")}
        </h1>
        {this.state.showLoader ? (
          <Loader />
        ) : this.state.gatewayChoice === -1 ? (
          <PaymentChoices
            onSelect={(gatewayChoice) => {
              this.setState({ gatewayChoice });
            }}
            options={[
              {
                title: getMessage("settings.online.payment.ihaveagateway"),
                description: getMessage(
                  "settings.online.payment.ihaveagatewaydescription"
                ),
              },
              {
                title: getMessage("settings.online.payment.idonthaveagateway"),
                description: getMessage(
                  "settings.online.payment.idonthaveagatewaydescription"
                ),
              },
            ]}
          />
        ) : (
          <GatewayConfiguration
            addedGateways={this.state.addedGateways}
            id={this.state.id}
            back={this.back}
            switchToCustom={this.switchToCustom}
            switchToDefault={this.switchToDefault}
          />
        )}
      </AuthenticatedPage>
    );
  }
}
