import React, { Component } from "react";
import { tableProperties } from "../../operations/Pickers";
import PickerForm from "../../operations/Pickers/Form";
import PickerFilter from "../../operations/Pickers/Filters";
import ListingPage from "../../../containers/ListingPage";
import { getMessage } from "../../../lib/translator";
import "../../operations/Pickers/style.css";
import emptyStateIcon from "./runner_executives.svg";

import {
  getStandradDateTime,
  utcToLocalTime,
  localTimeToUtc,
} from "../../../lib/datetime";
const emptyState = {
  icon: emptyStateIcon,
  message: getMessage("manage-runner-executives-emptyState.emptyMessage"),
};

class ManageRE extends Component {
  render() {
    const { props } = this;
    return (
      <ListingPage
        className="pickers-page" // Here we are using css of pickers-page
        menu={props.menu}
        title={getMessage("manage-runner-executives.title")}
        api={{
          url: "/account-service/employee",
          params: {
            roleId: 4,
          },
          transform: (response) => {
            const employees = response.data.employee;
            if (Array.isArray(employees)) {
              employees.map((employee) => {
                if (employee?.attendance?.createdAt) {
                  if (employee.attendance.createdAt.includes("T")) {
                    const newDate = employee.attendance.createdAt.split(/[TZ]/);
                    employee.attendance.createdAt = `${newDate[0]} ${newDate[1]}`;
                  }
                  employee.attendance.createdAt = getStandradDateTime(
                    utcToLocalTime,
                    employee.attendance.createdAt
                  );
                }
                if (employee.shiftStart) {
                  employee.shiftStart = getStandradDateTime(
                    utcToLocalTime,
                    `${new Date().toISOString().split("T")[0]} ${
                      employee.shiftStart
                    }`
                  ).split(" ")[1];
                }
                if (employee.shiftEnd) {
                  employee.shiftEnd = getStandradDateTime(
                    utcToLocalTime,
                    `${new Date().toISOString().split("T")[0]} ${
                      employee.shiftEnd
                    }`
                  ).split(" ")[1];
                }
                return employee;
              });
            } else {
              if (employees?.attendance?.createdAt) {
                if (employees.attendance.createdAt.includes("T")) {
                  const newDate = employees.attendance.createdAt.split(/[TZ]/);
                  employees.attendance.createdAt = `${newDate[0]} ${newDate[1]}`;
                }
                employees.attendance.createdAt = getStandradDateTime(
                  utcToLocalTime,
                  employees.attendance.createdAt
                );
              }
              if (employees.shiftStart) {
                employees.shiftStart = getStandradDateTime(
                  utcToLocalTime,
                  `${new Date().toISOString().split("T")[0]} ${
                    employees.shiftStart
                  }`
                ).split(" ")[1];
              }
              if (employees.shiftEnd) {
                employees.shiftEnd = getStandradDateTime(
                  utcToLocalTime,
                  `${new Date().toISOString().split("T")[0]} ${
                    employees.shiftEnd
                  }`
                ).split(" ")[1];
              }
            }
            return employees;
          },
        }}
        editHeading={getMessage("manage-runner-executives.editHeading")}
        modalClassName="picker-form"
        storeDependent
        emptyState={emptyState}
        tableProperties={tableProperties}
        form={{
          component: PickerForm,
          transformSubmit: (formData) => {
            let runnerExecutive = Object.assign({}, formData);
            if (runnerExecutive.shiftStart) {
              runnerExecutive.shiftStart = getStandradDateTime(
                localTimeToUtc,
                `${new Date().toISOString().split("T")[0]} ${
                  runnerExecutive.shiftStart
                }:00`
              ).split(" ")[1];
            }
            if (runnerExecutive.shiftEnd) {
              runnerExecutive.shiftEnd = getStandradDateTime(
                localTimeToUtc,
                `${new Date().toISOString().split("T")[0]} ${
                  runnerExecutive.shiftEnd
                }:00`
              ).split(" ")[1];
            }
            return runnerExecutive;
          },
        }}
        filters={{
          component: PickerFilter,
        }}
      />
    );
  }
}

export default ManageRE;
