import React from "react";
import { getMessage } from "../../../../lib/translator";
import "../style.css";
import {
  BaseForm,
  VALIDATION_TYPES,
  Input,
  Radio,
} from "../../../../components/Form";
import Textarea from "../../../../components/Form/Inputs/Textarea";
import { hasDuplicates } from "../../../../lib/commonlyused";

class AddRackForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      rackNumber: "",
      submitting: false,
      formError: "",
      newZone: "",
      zoneType: "",
    };
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }
  _submitHandler(e) {
    e && e.preventDefault();
    this.beforeSubmit();
    this.setState({
      pressedSubmitWithCurrentData: true,
    });
    let isValid = this.isFormValid();
    if (isValid) {
      this.validateAndSubmit();
    }
  }
  validateAndSubmit() {
    let values = JSON.parse(JSON.stringify(this.state.values));
    let rackArray = values.rack
      .trim()
      .split(",")
      .map((el) => el.trim());
    if (this.state.values.newZone?.trim()) {
      this.props.onSubmit(this.state.values);
    } else if (
      rackArray.some((entry) => (this.props.items || []).includes(entry)) ||
      hasDuplicates(rackArray)
    ) {
      this.setState({ formError: "Duplicate entries are not allowed" });
    } else {
      this.props.onSubmit(this.state.values);
    }
  }
  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div className="addRackForm">
        <Form>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <Textarea
            label={getMessage("rackManagement.rack.add")}
            placeholder={getMessage("rackManagement.rack.add.placeholder")}
            type="text"
            name="rack"
            required
            {...this.generateStateMappers({
              stateKeys: ["rack"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <Radio
            className="rack-zone-radio"
            name="rack-zone-type"
            options={[
              {
                text: getMessage("rackManagement.rack.zones.type.existing"),
                value: "addInExistingZone",
              },
              {
                text: getMessage("rackManagement.rack.zones.type.new"),
                value: "addNewZone",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["zoneType"],
              defaultValue: "addInExistingZone",
            })}
          />
          {this.state.values?.zoneType === "addNewZone" && (
            <Input
              label={getMessage("rackManagement.rack.add.zone")}
              placeholder={getMessage(
                "rackManagement.rack.add.zone.placeholder"
              )}
              type="text"
              name="newZone"
              {...this.generateStateMappers({
                stateKeys: ["newZone"],
              })}
            />
          )}
          <div className="form-buttons-container">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage("rackManagement.submitText.submit")}
            </SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}

export default AddRackForm;
