import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListingPageWithRoutes from "../../../containers/ListingPage/listingRouter";
import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import BlogForm from "./Form";
import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { Dialog } from "../../../components/Popup";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";
import { getPrintableTime } from "../../../lib/datetime";
import moment from "moment";
import { getSiteURL } from "../../../lib/commonlyused";

import "./style.css";
import emptyIcon from "./blogs-empty.svg";

class BlogActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusAction: null,
    };
    this.showStatusActionDialog = this.showStatusActionDialog.bind(this);
    this.hideStatusActionDialog = this.hideStatusActionDialog.bind(this);
  }
  showStatusActionDialog(statusAction) {
    this.setState({ statusAction });
  }
  hideStatusActionDialog() {
    this.setState({ statusAction: null });
  }
  componentWillReceiveProps() {
    this.setState({ statusAction: false });
  }
  setPublishStatus(isDraft) {
    let id = this.props.id;
    let api = new API({ url: `/blog-service/blog/${id}` });
    let publishTime = isDraft
      ? null
      : moment().utc().format("YYYY-MM-DD HH:mm:ss");
    return api
      .put({ isDraft, publishTime })
      .then((response) => {
        this.props.onAction(
          TABLE_ACTIONS.REFRESH,
          { id },
          { isDraft, publishTime }
        );
      }, console.error)
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    let { id, isDraft, url } = this.props;
    const siteUrl = getSiteURL();
    let blogLink = `${siteUrl}/blog/${url}`;
    return (
      <div>
        <Dialog
          show={this.state.statusAction}
          title={
            isDraft ? getMessage("are.you.sure") : getMessage("are.you.sure")
          }
          information={
            isDraft
              ? getMessage("marketing.blog.dialogs.publish.message")
              : getMessage("marketing.blog.dialogs.unpublish.message")
          }
          onOk={() => {
            this.props.isDraft
              ? this.setPublishStatus(false)
              : this.setPublishStatus(true);
          }}
          close={this.hideStatusActionDialog}
          closeText={getMessage("cancel.text")}
          okText={
            isDraft
              ? getMessage("marketing.blog.form.submitText")
              : getMessage("marketing.blog.table.action.unpublish")
          }
        />
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`/marketing/blog/edit/${id}`}>
              {getMessage("marketing.action.edit.text")}
            </Link>
          </DropDownItem>
          <DropDownItem>
            <div
              onClick={() => {
                this.showStatusActionDialog(id);
              }}
            >
              {isDraft
                ? getMessage("marketing.blog.form.submitText")
                : getMessage("marketing.blog.table.action.unpublish")}
            </div>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              this.props.onAction(TABLE_ACTIONS.DELETE, { id });
            }}
          >
            {getMessage("delete.text")}
          </DropDownItem>
          <DropDownItem>
            <a href={blogLink} target="_blank" rel="noopener noreferrer">
              {getMessage("View in website")}
            </a>
          </DropDownItem>
        </DropDown>
      </div>
    );
  }
}

function AddBlog() {
  return (
    <div className="text-center">
      <Link to="/marketing/blog/add" className="primary button">
        <span />+{" "}
        <span className="text">{getMessage("marketing.blog.add.text")}</span>
      </Link>
    </div>
  );
}

const emptyState = {
  icon: emptyIcon,
  message: getMessage("marketing.blog.helpItems.message"),
  additionalViews: [AddBlog],
};

const tableProperties = {
  headers: [
    getMessage("marketing.title.heading.text"),
    getMessage("marketing.table.heading.publishTime"),
    getMessage("marketing.table.heading.author"),
    // getMessage('marketing.table.heading.interaction'),
    getMessage("marketing.actions.text"),
  ],
  row: ({ id, title, publishTime, author, isDraft, onAction, url }) => (
    <Row>
      <Cell>
        <Link to={`/marketing/blog/edit/${id}`} className="blog-title-link">
          {title}
        </Link>
      </Cell>
      <Cell>
        {isDraft ? (
          <small className="status-draft">
            {getMessage("marketing.blog.table.status.draft")}
          </small>
        ) : (
          <div>
            <div className="text-muted">
              <small className="publish-date">
                {publishTime
                  ? getPrintableTime(publishTime).split(",")[0]
                  : null}
              </small>
            </div>
            <div className="text-muted">
              <small className="publish-time">
                {publishTime
                  ? getPrintableTime(publishTime).split(",")[1]
                  : null}
              </small>
            </div>
          </div>
        )}
      </Cell>
      <Cell>{author ? author.name : null}</Cell>
      <Cell>
        <BlogActions onAction={onAction} id={id} isDraft={isDraft} url={url} />
      </Cell>
    </Row>
  ),
};

export default function Blog(props) {
  return (
    <ListingPageWithRoutes
      helmetTitle="Zopping - Blog"
      menu={props.menu}
      className="blogs-page"
      title={getMessage("menu.item.blog")}
      addHeading={getMessage("marketing.form.add.heading")}
      editHeading={getMessage("marketing.form.edit.heading")}
      emptyState={emptyState}
      headerActions={AddBlog}
      api={{
        url: "/blog-service/blog",
        transform: (response) => response.data.blog,
      }}
      form={{
        component: BlogForm,
        transformSubmit: (formData) => {
          let {
            title,
            url,
            description,
            imageUrl,
            alternateTextForImage,
            tags,
          } = formData;

          let tagId = tags?.map((tag) => {
            return String(tag.id);
          });

          return {
            title,
            url,
            description,
            imageUrl,
            alternateTextForImage,
            tagIds: tagId,
            content: [],
          };
        },
        transformResponse: (response) => {
          return response?.data?.blog || {};
        },
      }}
      tableProperties={tableProperties}
    />
  );
}
