import React from "react";

function PendingIcon(props) {
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fill-rule="evenodd">
        <circle fill="#E9C60C" cx="9.75" cy="9.75" r="8.75" />
        <path
          d="M9 6v5a1 1 0 0 0 1 1h4.472"
          stroke="#FFF"
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
}

export default PendingIcon;
