import React from "react";
import API from "../../lib/api";
import GoogleMaps, {
  Polyline,
  Marker,
  MarkerWithLabel,
  // DirectionsRenderer,
} from "../GoogleMaps";
import Loader from "../Loader";
import {
  getPrintableTime,
  utcToLocalTime,
  getStandradDateTime,
} from "../../lib/datetime";
import MarkerIcon from "./map-marker.svg";
import StoreMarker from "./store-marker.svg";
import VehicleMarker from "./vehicle-marker.svg";
import { GOOGLE_MAP_DEFAULT_KEY } from "../../config/app";
import { get } from "../../lib/storage";
import MarkerWithInfo from "./MarkerWithInfo";
import "./style.css";

const lineSymbol = {
  path: "M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z",
  fillColor: "#0000FF",
  fillOpacity: 0.6,
  strokeWeight: 1,
  scale: 1,
};

const markerLabelStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#000",
  backgroundColor: "#F8D168",
  padding: "5px",
  borderRadius: "4px",
};

class MapWithPath extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: [],
      // vehicleRoute: null,
      addresses: this.getAddressString(),
    };
    this.mapMounted = this.mapMounted.bind(this);
    this.getCenter = this.getCenter.bind(this);
    this.getAddressString = this.getAddressString.bind(this);
    this.getMarkers = this.getMarkers.bind(this);
    this.getPath = this.getPath.bind(this);
  }

  getLocationParams() {
    let { vehiclePath } = this.props;
    let deliveryLocations = vehiclePath
      ?.map(
        (location) =>
          location.latitude &&
          location.longitude &&
          `&point=${location.latitude},${location.longitude}`
      )
      .filter(Boolean);
    return deliveryLocations;
  }

  getAddressString() {
    let { coordinates, vehiclePath } = this.props;
    let deliveryLocations = !vehiclePath
      ? coordinates &&
        coordinates
          .map(
            (location) =>
              location.latitude &&
              location.longitude &&
              `&point=${location.latitude},${location.longitude}`
          )
          .filter(Boolean)
      : vehiclePath
          .map(
            (location) =>
              location.latitude &&
              location.longitude &&
              `&point=${location.latitude},${location.longitude}`
          )
          .filter(Boolean);
    return deliveryLocations;
  }

  getMarkers() {
    let { coordinates } = this.props;
    let locationMarkers =
      coordinates.length &&
      coordinates.map((location) => {
        if (!location.latitude || !location.longitude) {
          return null;
        }
        return {
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
        };
      });
    return locationMarkers ? locationMarkers.filter(Boolean) : [];
  }

  mapMounted(ref) {
    this.setState({ map: ref });
  }

  getCenter() {
    if (this.state.markers) {
      let locations = JSON.parse(JSON.stringify(this.state.markers));
      if (this.props.showStore) {
        locations.push({
          lat:
            this.props.showStore.latitude &&
            parseFloat(this.props.showStore.latitude),
          lng:
            this.props.showStore.longitude &&
            parseFloat(this.props.showStore.longitude),
        });
      }
      if (this.props.showVehicle) {
        locations.push({
          lat:
            this.props.showVehicle.latitude &&
            parseFloat(this.props.showVehicle.latitude),
          lng:
            this.props.showVehicle.longitude &&
            parseFloat(this.props.showVehicle.longitude),
        });
      }
      if (this.state.points) {
        locations = locations?.concat(this.state.points);
      }
      const points = locations;
      if (points && points.length > 0 && window.google) {
        let bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < points.length; i++) {
          bounds.extend(points[i]);
        }
        this.state.map && this.state.map.fitBounds(bounds);
        return bounds.getCenter();
      }
    }
    if ("geolocation" in window.navigator) {
      window.navigator.geolocation.getCurrentPosition(({ coords }) => {
        return { lat: coords.latitude, lng: coords.longitude };
      });
    }
  }

  getPath() {
    this.getCommonMarkers(this.getMarkers());
    this.setState({ markers: this.getMarkers() });
    const api = new API({
      url:
        `${this.props.zopsmartMapUrls}/route/?points_encoded=false` +
        this.state.addresses +
        "&locale=en-US&vehicle=car&weighting=fastest&elevation=false&layer=OpenStreetMap",
    });
    if (this.state.addresses.length > 1 && this.props.callMaps !== false) {
      this.setState({ dataLoading: true });
      api
        .get()
        .then(
          (response) => {
            let apiPoints = response.paths[0].points.coordinates;
            let points = apiPoints.map((point) => {
              return {
                lat: point[1],
                lng: point[0],
              };
            });
            this.setState({
              points: points,
              dataLoading: false,
            });
          },
          (error) => {
            this.setState({
              error: error,
              dataLoading: false,
            });
          }
        )
        .catch((error) => {
          console.error(error);
        });
    } else if (!this.props.callMaps) {
      let { vehiclePath } = this.props;

      let points =
        vehiclePath &&
        vehiclePath?.map((point) => ({
          lat: parseFloat(point.latitude),
          lng: parseFloat(point.longitude),
        }));

      let vehiclePoint = null;
      let vehicleLastInfo = null;
      if (points && points.length > 0) {
        let point = points.slice(-1)[0];
        vehicleLastInfo = vehiclePath?.slice(-1)[0];
        vehiclePoint = {
          latitude: point.lat,
          longitude: point.lng,
        };
        this.getExactPathRoute(points);
      }
      this.setState({
        points: points,
        dataLoading: false,
        vehiclePoint,
        vehicleLastInfo,
      });
    }
  }

  compareArrays(prevProps, currentProps) {
    if (prevProps.length !== currentProps.length) {
      return false;
    } else {
      for (let i = 0; i < prevProps.length; i++) {
        if (prevProps[i] !== currentProps[i]) {
          return false;
        }
      }
    }
    return true;
  }

  getCommonMarkers = (markers) => {
    const commonMarkers = [];
    markers.forEach((marker, i) => {
      if (commonMarkers.length <= 0) {
        commonMarkers.push({
          lat: marker.lat,
          lng: marker.lng,
          labels: [i + 1],
        });
      } else {
        let hasCommonArea = false;
        commonMarkers.forEach((commonMarker) => {
          if (
            commonMarker.lat === marker.lat &&
            commonMarker.lng === marker.lng
          ) {
            commonMarker["labels"].push(i + 1);
            hasCommonArea = true;
          }
        });
        if (!hasCommonArea)
          commonMarkers.push({
            lat: marker.lat,
            lng: marker.lng,
            labels: [i + 1],
          });
      }
    });
    this.setState({ commonMarkers: [...commonMarkers] });
  };

  componentDidUpdate(prevProps, prevState) {
    // If props are changed, fetch the route again
    if (
      !this.compareArrays(
        this.state.addresses,
        this.getAddressString(this.props.coordinates)
      )
    ) {
      this.setState(
        { addresses: this.getAddressString(this.props.coordinates) },
        () => {
          this.getPath();
        }
      );
    }
  }

  fetchLatLngLocation = (vehicleLastInfo) => {
    const lat = vehicleLastInfo?.latitude;
    const lng = vehicleLastInfo?.longitude;
    const vehicleTime = vehicleLastInfo.time
      ? getPrintableTime(
          getStandradDateTime(utcToLocalTime, vehicleLastInfo.time)
        )
      : "";

    let vehicleLocation = `Latitude: ${lat} & Longitude: ${lng} `;
    const fetchCompleteLocation = new API({
      url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${
        get("googleMapAPIkey") || GOOGLE_MAP_DEFAULT_KEY
      }`,
    });
    fetchCompleteLocation
      .get()
      .then((res) => {
        if (res.results?.[0]?.formatted_address)
          vehicleLocation = res.results[0]?.formatted_address;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({
          vehicleLastLocation: vehicleLocation,
          vehicleTime,
        });
      });
  };

  componentDidMount() {
    this.getPath();
  }

  getRealTimePolyline = (locations, resolve, reject) => {
    const api = new API({
      url:
        `${this.props.zopsmartMapUrls}/route/?points_encoded=false&` +
        `${locations}&locale=en-US&vehicle=car&weighting=fastest&elevation=false&layer=OpenStreetMap`,
    });

    api
      .get()
      .then((response) => {
        let apiPoints = response.paths?.[0].points.coordinates;
        let points = apiPoints?.map((point) => {
          return {
            lat: point[1],
            lng: point[0],
          };
        });
        resolve(points);
      })
      .catch((error) => {
        reject(`Directions service failed due to ${error}`);
        console.error(error);
      });
  };

  getLocations = (vehiclePath) => {
    let deliveryLocations = vehiclePath
      ?.map(
        (location) =>
          location.latitude &&
          location.longitude &&
          `&point=${location.latitude},${location.longitude}`
      )
      .filter(Boolean);
    return deliveryLocations;
  };

  getExactPathRoute = () => {
    let { vehiclePath } = this.props;
    const arrayChunk = (vehicleLocations = [], chunkSize) => {
      const chunkedArray = [];
      for (let i = 0; i < vehicleLocations?.length; i += chunkSize) {
        chunkedArray.push(vehicleLocations?.slice(i, i + chunkSize));
      }
      return chunkedArray;
    };
    const batchSize = 250; // Map service supports max 250 points only
    const locationsBatches = arrayChunk(vehiclePath, batchSize);

    const getRoutesForPoints = (paths) => {
      const locations = this.getLocations(paths);
      return new Promise((resolve, reject) => {
        this.getRealTimePolyline(locations, resolve, reject);
      });
    };

    const batchPromises = () => {
      const routes = [];
      locationsBatches.forEach((batch) => {
        routes.push(getRoutesForPoints(batch));
      });
      return routes;
    };

    Promise.all(batchPromises())
      .then((routes) => {
        this.setState({
          vehicleRoute: routes,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    let vehiclePoint = this.props.showVehicle || this.state.vehiclePoint;
    const { vehicleTime, vehicleLastLocation, vehicleLastInfo } = this.state;
    const vehicleRoute = this.state.vehicleRoute;
    const { showRealRoutes } = this.props;
    const polylineOptions = showRealRoutes
      ? {
          fillColor: "#42b3f5",
          strokeWeight: 4,
          strokeColor: "#42b3f5",
          geodesic: true, // Make the Polyline follow the curvature of the Earth
        }
      : {
          fillColor: "#ff0000",
          strokeWeight: 2,
          strokeColor: "#ff0000",
        };
    return (
      <div className="map-with-path">
        <GoogleMaps
          onMapMounted={this.mapMounted}
          zoom={15}
          containerClassName="address-map"
          getCenter={this.getCenter}
        >
          {this.state.dataLoading && <Loader />}
          {this.state.commonMarkers &&
            window.google &&
            this.state.commonMarkers.map((marker) => (
              <MarkerWithLabel
                key={`marker-${marker.labels}`}
                labelAnchor={new window.google.maps.Point(-6, 53)}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={MarkerIcon}
                labelStyle={markerLabelStyle}
              >
                <div className="marker-count">{marker.labels.join(", ")}</div>
              </MarkerWithLabel>
            ))}
          {this.props.showStore && (
            <Marker
              position={{
                lat:
                  this.props.showStore.latitude &&
                  parseFloat(this.props.showStore.latitude),
                lng:
                  this.props.showStore.longitude &&
                  parseFloat(this.props.showStore.longitude),
              }}
              icon={StoreMarker}
            />
          )}
          {vehiclePoint && (
            <MarkerWithInfo
              location={{
                location: {
                  lat:
                    vehiclePoint.latitude && parseFloat(vehiclePoint.latitude),
                  lng:
                    vehiclePoint.longitude &&
                    parseFloat(vehiclePoint.longitude),
                },
                label: {
                  time: vehicleTime,
                  location: vehicleLastLocation,
                },
              }}
              MarkerIcon={VehicleMarker}
              key={`vehicle-time-marker`}
              onMarkerClick={() => this.fetchLatLngLocation(vehicleLastInfo)}
            />
          )}
          {showRealRoutes ? (
            <>
              {vehicleRoute &&
                vehicleRoute.map((route, index) => {
                  return (
                    <Polyline
                      key={index}
                      defaultPath={this.state.points}
                      path={route}
                      options={{
                        fillOpacity: 0.4,
                        strokeOpacity: 0.8,
                        editable: false,
                        ...polylineOptions,
                      }}
                      icons={[
                        {
                          icon: lineSymbol,
                          repeat: "50px",
                        },
                      ]}
                    />
                  );
                })}
            </>
          ) : (
            <>
              {this.state.points && (
                <Polyline
                  defaultPath={this.state.points}
                  path={this.state.points}
                  options={{
                    fillOpacity: 0.4,
                    strokeOpacity: 0.8,
                    editable: false,
                    ...polylineOptions,
                  }}
                  icons={[
                    {
                      icon: lineSymbol,
                      repeat: "50px",
                    },
                  ]}
                />
              )}
            </>
          )}
        </GoogleMaps>
      </div>
    );
  }
}

export default MapWithPath;
