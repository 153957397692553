import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  SelectSearch,
  Checkbox,
  Phone,
} from "../../../../components/Form";
import Toggle from "../../../../components/Form/Inputs/Toggle";
import { cloneMutables } from "../../../../lib/stateManagement/";
import MetaDataFormFields from "../../../../components/MetaDataFormFields";
import GoogleMaps, {
  Marker,
  SearchBox,
} from "../../../../components/GoogleMaps";
import { getMessage } from "../../../../lib/translator";
import { isExtensionEnabled } from "../../../../lib/auth";
import { storeSelection } from "../../../../lib/commonlyused";
import API from "../../../../lib/api";
import Table, { Header, Row, Cell } from "../../../../components/Table";
import LocationIcon from "../location";
import "./style.css";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Dialog } from "../../../../components/Popup";

let daysOfWeak = {
  Monday: { o: "", c: "" },
  Tuesday: { o: "", c: "" },
  Wednesday: { o: "", c: "" },
  Thursday: { o: "", c: "" },
  Friday: { o: "", c: "" },
  Saturday: { o: "", c: "" },
  Sunday: { o: "", c: "" },
};

const getBusinessHoursObject = (value) => {
  let values = cloneMutables(value);
  let newValues = values;
  Object.keys(daysOfWeak).map((day) => {
    newValues.businessHours[day] = newValues.businessHours[day] || {
      o: "",
      c: "",
    };
    return null;
  });
  return newValues;
};
export default class StoreForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.mapMounted = false;
    this.onMapMounted = this.onMapMounted.bind(this);
    this.findLocation = this.findLocation.bind(this);
    this.tempHoursObject = daysOfWeak;
    this.state.values = props.value
      ? props.value.businessHours
        ? getBusinessHoursObject(props.value)
        : { ...props.value, businessHours: daysOfWeak }
      : { businessHours: daysOfWeak };
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.state.availablePayments = [
      "ONLINE",
      "WALLET",
      "CREDIT-AMEX",
      "CREDIT-VISA",
      "CREDIT-MASTER",
      "DEBIT-MADA",
      "GIFTCARD",
      "APPLE_PAY",
      "COD",
    ];
    this.state.showPaymentError = false;
  }

  handleStatusChange(val) {
    let values = JSON.parse(JSON.stringify(this.state.values || {}));
    values.status = val ? "ENABLED" : "DISABLED";
    this.setState({ values });
  }

  onMapMounted(node) {
    this.mapRef = node;
    this.setState({
      mapMounted: true,
    });
    if (
      (!this.state.values.latitude || !this.state.values.latitude) &&
      "geolocation" in window.navigator
    ) {
      /* geolocation is available */
      window.navigator.geolocation.getCurrentPosition(({ coords }) => {
        this.setLocation(coords);
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    let values = nextProps.value
      ? nextProps.value.businessHours
        ? getBusinessHoursObject(nextProps.value)
        : { ...nextProps.value, businessHours: daysOfWeak }
      : { businessHours: daysOfWeak };
    this.setState({ values });
  }
  setLocation({ latitude, longitude }) {
    this.updateState(["latitude"], latitude);
    this.updateState(["longitude"], longitude);
  }
  findLocation() {
    if (this.searchBoxRef) {
      const places = this.searchBoxRef.getPlaces();
      const bounds = new window.google.maps.LatLngBounds();
      if (places.length) {
        const place = places[0];
        this.setLocation({
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
        const addressComponents = place.address_components;
        const city = addressComponents?.filter(({ types }) =>
          types?.includes("locality")
        )[0]?.short_name;
        const state = addressComponents?.filter(({ types }) =>
          types?.includes("administrative_area_level_1")
        )[0]?.long_name;
        const pincode = addressComponents?.filter(({ types }) =>
          types?.includes("postal_code")
        )[0]?.short_name;
        this.updateState(["city"], city);
        this.updateState(["state"], state);
        this.updateState(["pincode"], pincode);
      }
      // Fit location area into the map
      places.forEach((place) => {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.mapRef.fitBounds(bounds);
    }
  }

  getAllPayments = () => {
    return new Promise((resolve, reject) => {
      const configApi = new API({
        url: `/config-service/config/order`,
      });
      configApi
        .get()
        .then((res) => {
          const availablePayments = res.data.order;
          const paymentMethods = availablePayments.paymentMethods;
          this.setState((prevState) => {
            const newValues = {
              ...prevState.values,
              configuredPaymentsPickup: paymentMethods.PICKUP || [],
              configuredPaymentsDelivery: paymentMethods.DELIVERY || [],
            };
            return {
              orgPaymentModes: paymentMethods,
              values: newValues,
            };
          });
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  getPaymentModes = () => {
    const api = new API({
      url: `/config-service/config/order?storeId=${this.props.resourceId}`,
    });
    api
      .get()
      .then((response) => {
        const orderPayments = response.data.order;
        if (Object.keys(orderPayments.paymentMethods).length === 0) {
          this.setState((prevState) => {
            const newValues = {
              ...prevState.values,
              storeLevelPaymentSetup: true,
            };
            return {
              values: newValues,
            };
          });
        } else {
          let paymentMethods = {};
          if (orderPayments?.paymentMethods?.PICKUP) {
            paymentMethods.configuredPaymentsPickup =
              orderPayments.paymentMethods?.PICKUP;
          }
          if (orderPayments?.paymentMethods?.DELIVERY) {
            paymentMethods.configuredPaymentsDelivery =
              orderPayments.paymentMethods?.DELIVERY;
          }
          this.setState((prevState) => {
            const newValues = {
              ...prevState.values,
              ...paymentMethods,
              storeLevelPaymentSetup: true,
            };
            return {
              values: newValues,
            };
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onPaymentChange = (value, type, key) => {
    if (type === "PICKUP") {
      const paymentMethods = this.state.values.configuredPaymentsPickup || [];
      if (value) {
        paymentMethods.push(key);
      } else {
        const index = paymentMethods.indexOf(key);
        paymentMethods.splice(index, 1);
      }

      this.setState((prevState) => {
        return {
          values: {
            ...prevState.values,
            configuredPaymentsPickup: paymentMethods,
          },
        };
      });
    } else {
      const paymentMethods = this.state.values.configuredPaymentsDelivery || [];
      if (value) {
        paymentMethods.push(key);
      } else {
        const index = paymentMethods.indexOf(key);
        paymentMethods.splice(index, 1);
      }
      this.setState((prevState) => {
        return {
          values: {
            ...prevState.values,
            configuredPaymentsDelivery: paymentMethods,
          },
        };
      });
    }
  };

  getOrderTypes = () => {
    const accountServiceApi = new API({ url: `/account-service/config/order` });

    accountServiceApi
      .get()
      .then((res) => {
        const accountServiceRes = res.data?.config?.order;
        this.setState((prevState) => {
          const newValues = {
            ...prevState.values,
            orderTypes: accountServiceRes.orderTypes,
          };
          return {
            values: newValues,
          };
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async getEveryPaymentMode() {
    await this.getAllPayments();
    this.getPaymentModes();
    this.getOrderTypes();
  }

  componentDidMount() {
    this.getEveryPaymentMode();
    const api = new API({ url: `/config-service/meta-data` });
    api
      .get()
      .then((response) => {
        let storeMetaData =
          (response.data &&
            response.data.config &&
            response.data.config.entityMetaData &&
            response.data.config.entityMetaData.store) ||
          {};
        this.storeMetaDataWithType = storeMetaData;
        let values = Object.assign({}, this.state.values);
        let metaData = values.metaData;
        if (!metaData) {
          metaData = {};
          storeMetaData &&
            Object.keys(storeMetaData).forEach((mt) => {
              metaData[mt] = "";
            });
          values.metaData = metaData;
        } else {
          let newMetaData = {};
          storeMetaData &&
            Object.keys(storeMetaData).forEach((mt) => {
              newMetaData[mt] = metaData[mt] || "";
            });
          metaData = newMetaData;
          values.metaData = metaData;
        }
        this.setState({
          values,
          loadingProductMetaData: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidUpdate(prevprops, prevState) {
    if (!prevState.values.hasPicking && this.state.values.hasPicking) {
      let validations = Object.assign({}, this.state.validations);
      delete validations.pickingStoreId;
      this.setState({
        validations,
      });
    }
  }

  handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latlng = await getLatLng(results[0]);
    this.setLocation({
      latitude: latlng.lat,
      longitude: latlng.lng,
    });
    const addressComponents = results[0].address_components;
    const city = addressComponents?.filter(({ types }) =>
      types?.includes("locality")
    )[0]?.short_name;
    const state = addressComponents?.filter(({ types }) =>
      types?.includes("administrative_area_level_1")
    )[0]?.long_name;
    const pincode = addressComponents?.filter(({ types }) =>
      types?.includes("postal_code")
    )[0]?.short_name;
    this.updateState(["address"], results[0].formatted_address);
    this.updateState(["city"], city);
    this.updateState(["state"], state);
    this.updateState(["pincode"], pincode);
  };

  useMyLocation = async () => {
    const success = ({ coords: { latitude, longitude } }) => {
      const latlng = new window.google.maps.LatLng(latitude, longitude);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
          console.error(
            "Some error occurred while fetching address details for the location from Google API"
          );
        }
        // If reverse geocoding of location coords is Successful
        if (status === window.google.maps.GeocoderStatus.OK) {
          this.handleSelect(results[0].formatted_address);
        }
      });
    };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        /* call success if able to get location coords (Latitude, Longitude) */
        success,
        /* Else If browser unable to get location coords (Latitude, Longitude) */
        /* e.g. user denies location permission */
        function error(err) {
          console.error(`ERROR(${err.code}): ${err.message}`);
        }
      );
    } else {
      console.error("Geolocation not supported by your browser");
    }
  };

  handlePaymentModesSave = () => {
    const {
      configuredPaymentsPickup,
      configuredPaymentsDelivery,
      // storeLevelPaymentSetup,
    } = this.state.values;
    // const payload = storeLevelPaymentSetup
    //   ? {
    //       PICKUP: configuredPaymentsPickup,
    //       DELIVERY: configuredPaymentsDelivery,
    //     }
    //   : {};
    let payload = {};
    if (this.state.values?.orderTypes?.includes("PICKUP")) {
      payload["PICKUP"] = configuredPaymentsPickup;
    }
    if (this.state.values?.orderTypes?.includes("DELIVERY")) {
      payload["DELIVERY"] = configuredPaymentsDelivery;
    }

    const api = new API({
      url: `/config-service/config`,
    });
    api
      .post({
        storeId: Number(this.props.resourceId),
        order: {
          paymentMethods: payload,
        },
      })
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  beforeSubmit() {
    this.handlePaymentModesSave();
  }

  validatePaymentModes = () => {
    const data = this.state.values;
    const hasPickup = data?.orderTypes?.includes("PICKUP");
    const hasDelivery = data?.orderTypes?.includes("DELIVERY");

    switch (true) {
      case hasPickup && hasDelivery:
        return (
          data.configuredPaymentsPickup.length > 0 &&
          data.configuredPaymentsDelivery.length > 0
        );
      case hasPickup:
        return data.configuredPaymentsPickup.length > 0;
      case hasDelivery:
        return data.configuredPaymentsDelivery.length > 0;
      default:
        return false;
    }
  };

  closeDialogs = () => {
    this.setState({
      showPaymentError: false,
    });
  };

  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    const { values, availablePayments = [] } = this.state;
    const hasPicking = values && values.hasPicking;
    const usernamePattern =
      '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
    const status =
      this.props.method === "add" &&
      !(this.state.values && this.state.values.status)
        ? "ENABLED"
        : this.state.values && this.state.values.status;
    const storeLevelPaymentSetup = values?.storeLevelPaymentSetup;
    const { configuredPaymentsPickup, configuredPaymentsDelivery } = values;
    const isDeliverySupported =
      values?.orderTypes?.includes("DELIVERY") && values?.hasDeliveryHub;
    const isPickupSupported =
      values?.orderTypes?.includes("PICKUP") && values?.hasPicking;

    return (
      <div>
        <Dialog
          show={this.state.showPaymentError}
          information={getMessage("orderConfig.form.payment.validate.error")}
          close={this.closeDialogs}
          closeText={getMessage("notification.okay.text")}
        />
        <Form className="store-form">
          <div className="store-form-address-name-wrapper">
            <div className="store-form-address-name">
              <Input
                className="store-name"
                label={getMessage("operations.store.form.name.heading")}
                placeholder={getMessage(
                  "operations.store.form.name.placeholder"
                )}
                name="name"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ["name"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                }}
                maxLength="45"
              />
              <Input
                label={getMessage(
                  "order.form.customer.address.line.one.heading"
                )}
                placeholder={getMessage(
                  "order.form.customer.address.line.one.heading"
                )}
                name="addressLine1"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ["addressLine1"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                }}
              />
              <Input
                label={getMessage(
                  "order.form.customer.address.line.two.heading"
                )}
                placeholder={getMessage(
                  "order.form.customer.address.line.two.heading"
                )}
                name="addressLine2"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ["addressLine2"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                }}
              />
              <Input
                label={getMessage("order.form.customer.city.heading")}
                placeholder={getMessage("order.form.customer.city.placeholder")}
                name="city"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ["city"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage("order.form.customer.state.heading")}
                placeholder={getMessage("order.form.customer.state.enterState")}
                name="state"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ["state"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage("order.form.customer.pincode.heading")}
                placeholder={getMessage(
                  "order.form.customer.pincode.placeholder"
                )}
                name="pincode"
                required
                type="text"
                {...this.generateStateMappers({
                  stateKeys: ["pincode"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage("customer.details.email.email")}
                placeholder={getMessage("shifts.form.email.placeholder")}
                className="email-field"
                name="email"
                pattern={usernamePattern}
                required
                type="text"
                {...this.generateStateMappers({
                  stateKeys: ["email"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  patternMismatch: getMessage("input.invalidEmailFormat"),
                }}
              >
                {getMessage("store.add.email.description")}
              </Input>
              <div className="field-wrp">
                <Phone
                  label={getMessage("customer.details.phone.phoneNumber")}
                  placeholder={getMessage("calls.filters.phone.placeholder")}
                  name="phone"
                  className="phone"
                  required
                  {...this.generateStateMappers({
                    stateKeys: ["phone"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
                <div className="description">
                  {getMessage("store.add.email.description")}
                </div>
              </div>
            </div>
            <div className="location-container">
              <div className="location-header">
                <span className="address-title">
                  {getMessage("operations.store.form.location.heading") + " *"}
                </span>
                <div className="use-location" onClick={this.useMyLocation}>
                  <LocationIcon />
                  <div>{getMessage("use.current.location")}</div>
                </div>
              </div>
              <Input
                className="coordinates-input"
                label={getMessage("operations.store.form.location.heading")}
                name="coordinates"
                type="text"
                required
                value={
                  this.getState(["latitude"]) && this.getState(["longitude"])
                    ? "filled"
                    : ""
                }
                onValidation={(error) => {
                  this.registerValidation(["coordinates"], error);
                }}
                emphasize={
                  !this.getState(["latitude"]) || !this.getState(["longitude"])
                }
                showErrors={this.validationScenarios.validateOnSubmit()}
                siblings={{
                  after: (
                    <GoogleMaps
                      onMapMounted={this.onMapMounted}
                      zoom={15}
                      center={{
                        lat: this.getState(["latitude"]) || 12.9178614,
                        lng: this.getState(["longitude"]) || 77.6449406,
                      }}
                      containerClassName="map-element"
                    >
                      {this.state.mapMounted ? (
                        <SearchBox
                          ref={(node) => {
                            this.searchBoxRef = node;
                          }}
                          controlPosition={
                            window.google.maps.ControlPosition.TOP_LEFT
                          }
                          onPlacesChanged={this.findLocation}
                        >
                          <input
                            type="text"
                            placeholder={getMessage(
                              "operations.store.form.location.placeholder"
                            )}
                            className="location-search-box"
                          />
                        </SearchBox>
                      ) : null}
                      <Marker
                        position={{
                          lat: this.getState(["latitude"]) || 12.9178614,
                          lng: this.getState(["longitude"]) || 77.6449406,
                        }}
                        draggable
                        onDragEnd={(e) => {
                          this.setLocation({
                            latitude: e.latLng.lat(),
                            longitude: e.latLng.lng(),
                          });
                        }}
                      />
                    </GoogleMaps>
                  ),
                }}
                validationStrings={{
                  valueMissing: getMessage(
                    "operations.store.form.location.requiredMessage"
                  ),
                }}
              />
            </div>
          </div>
          <div className="grid">
            <Checkbox
              className="delivey-hub"
              name="hasPicking"
              label={getMessage("operations.store.form.hasPicking.label")}
              {...this.generateStateMappers({
                stateKeys: ["hasPicking"],
                loseEmphasisOnFill: true,
              })}
            />
            <Checkbox
              className="delivey-hub"
              name="hasDeliveryHub"
              label={getMessage("operations.store.form.hasDeliveryHub.label")}
              {...this.generateStateMappers({
                stateKeys: ["hasDeliveryHub"],
                loseEmphasisOnFill: true,
              })}
            />
            <Toggle
              className={status}
              title="enable/disable the store"
              name={status}
              value={status === "ENABLED"}
              icons={false}
              onChange={this.handleStatusChange}
            />
          </div>
          <div className="grid">
            <Checkbox
              className="delivey-hub"
              name="isCnC"
              label={getMessage("operations.store.form.hasClickCollect.label")}
              {...this.generateStateMappers({
                stateKeys: ["hasClickCollect"],
                loseEmphasisOnFill: true,
              })}
            />
            <Checkbox
              className="delivey-hub"
              name="hasSelfCheckout"
              label={getMessage("operations.store.form.hasSelfCheckout.label")}
              {...this.generateStateMappers({
                stateKeys: ["hasSelfCheckout"],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          {/* <div className="payment-modes">
            <div className="order-by-payment-rules">
              <div className="payment-order-label">
                {getMessage("operations.store.choose.payment.mode.for.store")}
              </div>
              <table className="payment-mode-table">
                <thead>
                  <tr>
                    <th className="table-head">
                      {getMessage("operations.store.storeName.label")}
                    </th>
                    {availablePayments.map((item) => {
                      return (
                        <th className="table-head" key={item}>
                          {getMessage(`operations.store.form.payment.${item}`)}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr key={this.props.value?.name}>
                    <td className="order-type">{this.props.value?.name}</td>
                    {availablePayments.map((item) => {
                      const hasConfigured =
                        values?.configuredPayments?.includes(item);
                      return (
                        <td className="payments-list" key={item}>
                          {isBoolean(hasConfigured) && (
                            <Checkbox
                              className="payment-mode"
                              name={item}
                              value={hasConfigured}
                              onChange={(e) => this.onPaymentChange(e, item)}
                            />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}

          {/* {isBoolean(storeLevelPaymentSetup) && (
            <Checkbox
              className="store-level-payment"
              name="store-level-payment"
              label={getMessage("operations.store.form.store.level.payment")}
              {...this.generateStateMappers({
                stateKeys: ["storeLevelPaymentSetup"],
                loseEmphasisOnFill: true,
              })}
            />
          )} */}

          {storeLevelPaymentSetup &&
            (isPickupSupported || isDeliverySupported) && (
              <div className="payment-modes">
                <div className="order-by-payment-rules">
                  <div className="payment-order-label">
                    {getMessage("choose.payment.mode.for.order.type")}
                  </div>
                  <table className="payment-mode-table">
                    <thead>
                      <tr>
                        <th className="table-head">
                          {getMessage("order.by.label.for.payment")}
                        </th>
                        {availablePayments.map((item) => {
                          return (
                            <th className="table-head" key={item}>
                              {getMessage(
                                `operations.store.form.payment.${item}`
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {isDeliverySupported && (
                        <tr key={"DELIVERY"}>
                          <td className="order-type">
                            {getMessage(
                              "order.details.location.option.delivery"
                            )}
                          </td>
                          {availablePayments.map((item) => {
                            const hasConfigured =
                              configuredPaymentsDelivery?.includes(item);
                            return (
                              <td
                                className="payments-list"
                                key={`${"DELIVERY"}-${item}`}
                              >
                                {
                                  <Checkbox
                                    className="payment-mode"
                                    name={`${"DELIVERY"}-${item}`}
                                    value={hasConfigured}
                                    onChange={(e) =>
                                      this.onPaymentChange(e, "DELIVERY", item)
                                    }
                                  />
                                }
                              </td>
                            );
                          })}
                        </tr>
                      )}
                      {isPickupSupported && (
                        <tr key={"PICKUP"}>
                          <td className="order-type">
                            {getMessage("order.details.location.option.pickup")}
                          </td>
                          {availablePayments.map((item) => {
                            const hasConfigured =
                              configuredPaymentsPickup?.includes(item);
                            return (
                              <td
                                className="payments-list"
                                key={`${"PICKUP"}-${item}`}
                              >
                                {
                                  <Checkbox
                                    className="payment-mode"
                                    name={`${"PICKUP"}-${item}`}
                                    value={hasConfigured}
                                    onChange={(e) =>
                                      this.onPaymentChange(e, "PICKUP", item)
                                    }
                                  />
                                }
                              </td>
                            );
                          })}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          <div className="grid">
            {!hasPicking && (
              <SelectSearch
                name="picking-store"
                required
                label={getMessage("Picking store")}
                placeholder={getMessage("Select store")}
                options={storeSelection()}
                nameKey="text"
                valueKey="value"
                searchByName
                {...this.generateStateMappers({
                  stateKeys: ["pickingStoreId"],
                  loseEmphasisOnFill: true,
                })}
              />
            )}
          </div>
          {(this.props.method === "add" || this.getState(["clientId"])) && (
            <Input
              label={getMessage("operations.store.form.clientId.heading")}
              placeholder={getMessage(
                "operations.store.form.clientId.placeholder"
              )}
              name="clientId"
              type="text"
              className="client-id"
              {...this.generateStateMappers({
                stateKeys: ["clientId"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              readOnly={this.props.method !== "add"}
            />
          )}
          <div className="businessTime">
            <h2>{getMessage("operations.store.form.businessHours.title")}</h2>
            <Table>
              {
                <Header>
                  <Cell>
                    {getMessage("operations.store.form.businessHours.day")}
                  </Cell>
                  <Cell>
                    {getMessage(
                      "operations.store.form.businessHours.alwaysOpen"
                    )}
                  </Cell>
                  <Cell>
                    {getMessage(
                      "operations.store.form.businessHours.openingTime"
                    )}
                  </Cell>
                  <Cell>
                    {getMessage(
                      "operations.store.form.businessHours.closingTime"
                    )}
                  </Cell>
                </Header>
              }
              {Object.keys(daysOfWeak).map((day, index) => (
                <Row className="business-time-row" key={day}>
                  <Cell>{day}</Cell>
                  <Cell>
                    <Checkbox
                      value={
                        this.state.values.businessHours[day]
                          ? this.state.values.businessHours[day]["o"] ===
                              "00:00:00" &&
                            this.state.values.businessHours[day]["c"] ===
                              "23:59:59"
                          : false
                      }
                      name={`always-open-${day}`}
                      onChange={(value) => {
                        let values = JSON.parse(
                          JSON.stringify(this.state.values)
                        );
                        if (value) {
                          this.tempHoursObject[day]["o"] =
                            (values.businessHours[day] &&
                              values.businessHours[day]["o"]) ||
                            "";
                          this.tempHoursObject[day]["c"] =
                            (values.businessHours[day] &&
                              values.businessHours[day]["c"]) ||
                            "";
                          values.businessHours[day]["o"] = "00:00:00";
                          values.businessHours[day]["c"] = "23:59:59";
                          this.setState({ values });
                        } else {
                          if (!values.businessHours[day]) {
                            values.businessHours[day] = {};
                          }
                          values.businessHours[day]["o"] =
                            (this.tempHoursObject[day] &&
                              this.tempHoursObject[day]["o"]) ||
                            "";
                          values.businessHours[day]["c"] =
                            (this.tempHoursObject[day] &&
                              this.tempHoursObject[day]["c"]) ||
                            "";
                          this.setState({ values });
                        }
                      }}
                    />
                  </Cell>
                  <Cell>
                    <Input
                      type="text"
                      pattern={"^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$"}
                      placeholder={"HH:MM:SS"}
                      // required={this.state.values.businessHours[day]['c']}
                      {...this.generateStateMappers({
                        stateKeys: ["businessHours", day, "o"],
                        loseEmphasisOnFill: true,
                      })}
                    />
                  </Cell>
                  <Cell>
                    <Input
                      type="text"
                      pattern={"^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$"}
                      placeholder={"HH:MM:SS"}
                      // required={this.state.values.businessHours[day]['o']}
                      {...this.generateStateMappers({
                        stateKeys: ["businessHours", day, "c"],
                        loseEmphasisOnFill: true,
                      })}
                    />
                  </Cell>
                </Row>
              ))}
            </Table>
          </div>
          {isExtensionEnabled("EntityMetaData") &&
            this.storeMetaDataWithType &&
            Object.keys(this.storeMetaDataWithType).length > 0 && (
              <div className="metadata-section">
                <h2>{getMessage("operations.store.form.metaData.heading")}</h2>
                <div className="metadata-section-wrapper">
                  <MetaDataFormFields
                    metaDataWithType={this.storeMetaDataWithType}
                    noContainer
                    page="stores-form"
                    _this={this}
                  />
                </div>
              </div>
            )}
          <div
            className="form-buttons"
            onClick={(e) => {
              if (!this.validatePaymentModes()) {
                this.setState({
                  showPaymentError: true,
                });
                e.preventDefault();
              }
              return false;
            }}
          >
            <SubmitButton>
              {getMessage("operations.store.form.submitText")}
            </SubmitButton>
            <CancelButton>
              {getMessage("operations.store.form.cancelText")}
            </CancelButton>
          </div>
        </Form>
      </div>
    );
  }
}
