import React, { Component } from "react";
import ListingPage, { TABLE_ACTIONS } from "../../../../containers/ListingPage";
import { Row, Cell } from "../../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../../components/DropDown";
import { Dialog } from "../../../../components/Popup";
import TeamLeaves from "../../LeaveManagement";
import DetailsCard from "../../../../components/DetailsCard";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import Tabs from "../../../../components/Tabs";

import { getMessage } from "../../../../lib/translator";
import { formatDate, getPrintableTime } from "../../../../lib/datetime";

import EmptyIcon from "../teamLeave.svg";

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage("leaves.team.empty.message"),
};

const tableProperties = {
  headers: [
    getMessage("leaves.team.employee"),
    getMessage("leaves.team.startson"),
    getMessage("leaves.team.endson"),
    getMessage("leaves.team.appliedon"),
    getMessage("leaves.team.reason"),
    getMessage("leaves.team.type"),
    getMessage("leaves.team.status"),
    getMessage("leaves.team.actions"),
  ],
  row: ({
    id,
    fromDate,
    toDate,
    appliedOn,
    reason,
    type,
    status,
    user,
    onAction,
  }) => (
    <Row>
      <Cell>{user.name}</Cell>
      <Cell>{formatDate(fromDate)}</Cell>
      <Cell>{formatDate(toDate)}</Cell>
      <Cell>{getPrintableTime(appliedOn)}</Cell>
      <Cell>{reason}</Cell>
      <Cell>{type}</Cell>
      <Cell>{status}</Cell>
      <Cell>
        {<LeaveActions onAction={onAction} id={id} status={status} />}
      </Cell>
    </Row>
  ),
};

const gridView = {
  row: ({
    id,
    fromDate,
    toDate,
    appliedOn,
    reason,
    type,
    status,
    user,
    onAction,
  }) => (
    <div className="spacer">
      <DetailsCard
        leftTitle={type}
        leftValue={reason}
        rightTitle={getMessage("leaves.header.status")}
        rightValue={status}
        bottomValue={formatDate(fromDate) + " - " + formatDate(toDate)}
      >
        {<LeaveActions id={id} onAction={onAction} status={status} />}
      </DetailsCard>
    </div>
  ),
};

class LeaveActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
    };
    this.showDialog = this.showDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.cancelLeave = this.cancelLeave.bind(this);
  }

  showDialog() {
    this.setState({
      showDialog: true,
    });
  }

  hideDialog() {
    this.setState({
      showDialog: false,
    });
  }

  cancelLeave(id) {
    let { onAction } = this.props;
    onAction(TABLE_ACTIONS.UPDATE, { id }, { status: "CANCELLED" });
  }

  render() {
    const { id, status } = this.props;
    return (
      <div>
        {status === "APPROVED_CANCELLABLE" && (
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem onClick={this.showDialog}>
              {getMessage("leaves.team.cancel")}
            </DropDownItem>
          </DropDown>
        )}
        <Dialog
          show={this.state.showDialog}
          title={getMessage("leaves.team.are.you.sure")}
          information={getMessage("leaves.team.cancel.leave")}
          onOk={() => this.cancelLeave(id)}
          close={this.hideDialog}
          okText={getMessage("leaves.team.okay")}
          closeText={getMessage("leaves.team.cancel")}
        />
      </div>
    );
  }
}

export default class TeamCancellableleaves extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowSize: window.innerWidth,
      page: 0,
    };
    this.pages = ["cancellable Leaves", "Approvable Leaves"];
    this.registerResize = this.registerResize.bind(this);
    this.changePage = this.changePage.bind(this);
    this.fetchPageData = this.fetchPageData.bind(this);
  }

  changePage(index, tableProps) {
    this.setState({
      page: index,
      tableProps: tableProps,
    });
  }

  fetchPageData() {
    let index = this.state.page;
    let tableProps = this.state.tableProps || tableProperties;
    if (index === 0) {
      return (
        <ListingPage
          className="team-leaves-page"
          api={{
            url: "/account-service/user-leave",
            params: { leaveType: "APPROVED_CANCELLABLE" },
            transform: (response) => response.data.userleave,
          }}
          emptyState={EmptyState}
          tableProperties={tableProps || []}
        />
      );
    } else {
      return <TeamLeaves />;
    }
  }

  registerResize(e) {
    this.setState({
      windowSize: e.target.innerWidth,
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.registerResize, true);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.registerResize, true);
  }

  render() {
    let tableProps = tableProperties;
    if (this.state.windowSize <= 768) {
      tableProps = gridView;
    }
    return (
      <AuthenticatedPage menu={this.props.menu} className="team-leaves-page">
        <h1>{getMessage("leaves.management.header")}</h1>
        <div className="tabsLeaves">
          <Tabs
            items={this.pages.map((page) => `${page}`)}
            default={this.state.page}
            onClick={(index) => {
              this.changePage(index, tableProps);
            }}
          />
        </div>
        {this.fetchPageData()}
      </AuthenticatedPage>
    );
  }
}
