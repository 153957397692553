import React from "react";
import { Input, Select } from "../../../components/Form";
import { Actions } from "./form";
import { getMessage } from "../../../lib/translator";

const splitAtCapitalLetter = (str) => str.split(/(?=[A-Z])/).join(" ");

const ENTER_TEXT = getMessage("communication.enter.text");

export default function ProviderConfiguration(props) {
  const { parent, allowKey, selectedProviderConfig } = props;
  const { fields, image } = selectedProviderConfig;
  let _this = parent;
  const readOnly = _this.getState([allowKey]) === false;
  return (
    <React.Fragment>
      <Actions parent={_this} icon={image} allowKey={allowKey} />
      <div className="form-fields">
        {fields.map(({ name, required, allowedValues, type }) => {
          const splittedNameAtCapitalLetter = splitAtCapitalLetter(name);
          const label = splittedNameAtCapitalLetter.toUpperCase();
          const placeholderText = `${ENTER_TEXT} ${splittedNameAtCapitalLetter.toLowerCase()}`;
          return allowedValues ? (
            <Select
              key={name}
              label={label}
              placeholder={placeholderText}
              options={allowedValues}
              name={name}
              {..._this.generateStateMappers({
                stateKeys: [name],
                loseEmphasisOnFill: true,
              })}
              disabled={readOnly}
              required={required}
            />
          ) : (
            <Input
              key={name}
              name={name}
              label={label}
              type={type === "string" ? "text" : type}
              placeholder={placeholderText}
              {..._this.generateStateMappers({
                stateKeys: [name],
                loseEmphasisOnFill: true,
              })}
              readOnly={readOnly}
              required={required}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}
