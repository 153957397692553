/*
  This file exists in src/ folder due to limitations set by create-react-app
*/

let GO_HOST = `${window.location.origin}/api`;
if (window.location.host.includes("localhost")) {
  GO_HOST = process.env.REACT_APP_API_HOST || "https://staging.zopping.com/api";
}

let IS_STAGING = GO_HOST.includes("staging");
const ZOPSMART_URL = "https://zopsmart.com";
const GOOGLE_MAP_DEFAULT_KEY = "AIzaSyA6YlEVc6ic84ZWAh2RtlOVsZh8S3fA2pY";
let GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places,drawing&key=`;
const WEBSOCKET_URL =
  process.env.REACT_APP_EVENTS_URL || "wss://events.zopsmart.com/ws/store";
const WEBSOCKET_BASE_URL =
  process.env.REACT_APP_INVOICE_EVENT_URL ||
  (IS_STAGING
    ? "wss://events.staging.shop.tamimimarkets.com/ws/order-invoices"
    : "wss://events.shop.tamimimarkets.com/ws/order-invoices");
const BUGSNAG_KEY = process.env.REACT_APP_BUGSNAG_KEY || "";
const ZOPSMART_MAPS_URL =
  process.env.REACT_APP_MAPS_URL || "https://maps.zopping.com";
const GIT_HASH = process.env.REACT_APP_GIT_HASH;
const GIT_TAG = process.env.REACT_APP_GIT_TAG;
const FARO_APP_NAME = IS_STAGING
  ? process.env.REACT_APP_FARO_APP_NAME || "zopping-enterprise-admin-ui-stage"
  : process.env.REACT_APP_FARO_APP_NAME || "zopping-enterprise-admin-ui";
const FARO_APP_URL =
  process.env.REACT_APP_FARO_APP_URL ||
  "https://grafana-agent.observability-prod.gcp.zopsmart.com/collect";

export {
  GO_HOST,
  ZOPSMART_URL,
  GOOGLE_MAPS_URL,
  BUGSNAG_KEY,
  WEBSOCKET_URL,
  ZOPSMART_MAPS_URL,
  GIT_HASH,
  GIT_TAG,
  IS_STAGING,
  GOOGLE_MAP_DEFAULT_KEY,
  FARO_APP_NAME,
  FARO_APP_URL,
  WEBSOCKET_BASE_URL,
};
