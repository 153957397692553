import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";
// import {getMessage} from '../../../../../lib/translator'
import { Link } from "react-router-dom";
import InActiveAddressIcon from "./address-inactive.svg";
import ActiveAddressIcon from "./address-active.svg";
import toTitleCase from "../../../../../utils/toTitleCase";
import "./style.css";
import {
  getPrintableTime,
  getStandradDateTime,
  utcToLocalTime,
} from "../../../../../lib/datetime";
import { isBoolean } from "lodash";

const ISO_RE =
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
export default class OrderContainer extends Component {
  getModifiedMetadata = (metaData = {}) => {
    Object.keys(metaData).forEach((orderMetaKey) => {
      if (!isBoolean(metaData[orderMetaKey])) {
        if (!metaData[orderMetaKey]) delete metaData[orderMetaKey];
      }
    });
    return metaData;
  };
  render() {
    const { id, status, address, clientId, metaData } = this.props.data;
    const { draggableId, index } = this.props;
    const modifiedMetadata = this.getModifiedMetadata(metaData);
    return (
      <Draggable draggableId={`order-${draggableId}`} index={index}>
        {(provided, snapshot) => (
          <div>
            <div
              className="order-box-spacer"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div
                className={`order-box ${
                  snapshot.isDragging ? "dragging" : ""
                }`.trim()}
              >
                <div
                  className={`reference-number ${
                    metaData.isDelayed ? "delayed-reference-number" : ""
                  }`}
                >
                  <span className="text-muted">{clientId ? "#" : "Z"}</span>
                  <Link to={`/operations/orders/${id}`} className="order-id">
                    {clientId ? `${clientId}` : `${id}`}
                  </Link>
                  <div className="order-tooltip-text">
                    {Object.keys(metaData).map((orderMetaKey) => (
                      <div className="order-meta-container" key={orderMetaKey}>
                        <div className="order-meta-title">
                          {toTitleCase(
                            orderMetaKey.split(/(?=[A-Z])/).join(" ")
                          )}
                        </div>
                        <div className="order-meta-text">
                          {ISO_RE.test(modifiedMetadata[orderMetaKey])
                            ? getPrintableTime(
                                getStandradDateTime(
                                  utcToLocalTime,
                                  modifiedMetadata[orderMetaKey]
                                )
                              )
                            : isBoolean(modifiedMetadata[orderMetaKey])
                            ? modifiedMetadata[orderMetaKey]
                              ? "Yes"
                              : "No"
                            : modifiedMetadata[orderMetaKey]}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="status">{status}</div>
                <div className="status">{`${
                  this.props.data.slot &&
                  getPrintableTime(
                    getStandradDateTime(
                      utcToLocalTime,
                      `${
                        new Date().toISOString().split("T")[0]
                      } ${this.props.data.slot.split("-")[0].trim()}`
                    )
                  ).split(",")[1]
                } 
                  -
                   ${
                     getPrintableTime(
                       getStandradDateTime(
                         utcToLocalTime,
                         `${
                           new Date().toISOString().split("T")[0]
                         } ${this.props.data.slot.split("-")[1].trim()}`
                       )
                     ).split(",")[1]
                   }`}</div>
                <img
                  className="order-address-icon"
                  src={
                    address.location.lat && address.location.long
                      ? ActiveAddressIcon
                      : InActiveAddressIcon
                  }
                  alt="address-icon"
                  onClick={this.props.onClick}
                />
                <div className="status pincode">
                  {address &&
                    address.pincode &&
                    address.pincode !== "0" &&
                    address.pincode.toString().padStart(6, "0")}
                </div>
                {/* {
                  packing
                    ? <div className='order-box-packing-details'>
                      <div>
                        {
                          Object.keys(packing[0].details).map((type, index) => (
                            <div className='packing-detail-elm' key={`trip-order-packing-details-${index}`}>
                              <div className='packing-details-type'>{getMessage(`order.packing.${type}`)} :</div>
                              <div className='packing-details-value'>{packing[0].details[type].join(', ')}</div>
                            </div>
                          ))
                        }
                      </div>
                    </div> : null
                } */}
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    );
  }
}
