import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import StoreSelector, {
  getDefaultStore,
} from "../../../containers/StoreSelector";
import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { Popup } from "../../../components/Popup";
import { getMessage } from "../../../lib/translator";
import { isExtensionEnabled, hasPermissions } from "../../../lib/auth";
import TripFilters from "./Filters";
import TripPaymentsForm from "./TripPaymentsForm";
import {
  getPrintableTime,
  getStandradDateTime,
  utcToLocalTime,
} from "../../../lib/datetime";
import API from "../../../lib/api";
import EndTripForm from "./EndTripForm";

import emptyStateIcon from "./no-trips.svg";

import "./style.css";

const PaymentModeContext = React.createContext("paymentModes");

/**
 * defines the state for the scenario where no trips are planned
 */
const emptyState = (props) => {
  return {
    icon: emptyStateIcon,
    message: getMessage("trips.emptyState.helperText", {
      multipleStores: Number(isExtensionEnabled("MultiStoreSupport")),
    }),
    actions: ({ apiParams, onAction }) =>
      isExtensionEnabled("MultiStoreSupport") && (
        <StoreSelector
          value={apiParams.storeId}
          stores={props.stores}
          onChange={(storeId) => {
            onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId });
          }}
        />
      ),
  };
};

class TableActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEndTripForm: false,
      endTripFormLoading: false,
      endKm: undefined,
      paymentsForm: false,
      tripDetails: null,
      showTripDetails: false,
    };
    this.showEndTripForm = this.showEndTripForm.bind(this);
    this.hideEndTripForm = this.hideEndTripForm.bind(this);
    this.showPaymentsForm = this.showPaymentsForm.bind(this);
    this.hidePaymentsForm = this.hidePaymentsForm.bind(this);
    this.financiallyCloseTrip = this.financiallyCloseTrip.bind(this);
    this.submitEndTripForm = this.submitEndTripForm.bind(this);
  }

  showEndTripForm() {
    this.setState({ endTripFormLoading: true, showEndTripForm: true });

    this.getTripDetails().then((response) => {
      this.setState({
        endKm: response.data?.trip?.endKm,
        endTripFormLoading: false,
      });
    });
  }

  hideEndTripForm() {
    this.setState({ showEndTripForm: false });
  }
  getTripDetails(storeId = null) {
    this.tripDetailsApi = new API({
      url: `/logistics-service/trip/${this.props.id}`,
    });
    let params = {};
    params["storeId"] = storeId;
    return this.tripDetailsApi.get(params);
  }
  showPaymentsForm() {
    this.getTripDetails()
      .then((response) => {
        let orders = response.data.trip.orders;
        let ordersWithPendingPayments =
          orders && orders.filter(({ pendingAmount }) => Number(pendingAmount));
        if (ordersWithPendingPayments && ordersWithPendingPayments.length) {
          this.setState({
            tripDetails: response.data.trip,
          });
          this.props.history.push(
            `/logistics/trips/trip-payments/${this.props.id}`
          );
        } else {
          this.financiallyCloseTrip();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  hidePaymentsForm() {
    this.setState({ paymentsForm: false });
  }
  financiallyCloseTrip(paymentsObj = {}) {
    let { id, onAction } = this.props;
    let params = {};
    let orders = this.state.tripDetails && this.state.tripDetails.orders;
    let ordersWithPendingPayments =
      orders && orders.filter(({ pendingAmount }) => Number(pendingAmount));
    if (Object.keys(paymentsObj).length > 0 || ordersWithPendingPayments) {
      orders.forEach((order) => {
        let orderPayments = {};
        if (order.paymentsByRE && order.paymentsByRE.length) {
          order.paymentsByRE.forEach((payment) => {
            let mode = Object.keys(payment)[0];
            let amount =
              paymentsObj[order.referenceNumber] &&
              paymentsObj[order.referenceNumber][mode];
            orderPayments[mode] = {
              amount: amount > -1 ? amount : Number(payment[mode].amount),
              transactionId: payment[mode].transaction_id,
            };
          });
        } else {
          order.payment.forEach((payment) => {
            let amount =
              paymentsObj[order.referenceNumber] &&
              paymentsObj[order.referenceNumber][payment.mode];
            orderPayments[payment.mode] = {
              amount: amount > -1 ? amount : Number(payment.amount),
              transactionId: payment.transactionId,
            };
          });
        }
        params[order.referenceNumber] = orderPayments;
      });
    }
    let referenceNumberArray =
      orders && orders.map((order) => order.referenceNumber);
    this.hidePaymentsForm();
    onAction(
      TABLE_ACTIONS.UPDATE,
      { id },
      {
        payments: params,
        status: "FIN_CLOSE",
        referenceNumber: referenceNumberArray || [],
      }
    );
  }

  submitEndTripForm(values) {
    const { id, onAction } = this.props;
    onAction(
      TABLE_ACTIONS.UPDATE,
      { id },
      { status: "CLOSE", endKm: values.endTripKm }
    );
    this.hideEndTripForm();
  }

  componentWillUnmount() {
    this.tripDetailsApi && this.tripDetailsApi.cancel();
  }

  render() {
    let { status } = this.props;
    const { endKm, endTripFormLoading } = this.state;

    const hasEditPermissions = hasPermissions("logistics", "trip", "put");
    return (
      <div>
        <Popup
          show={this.state.showEndTripForm}
          heading={getMessage("trips.action.close")}
          close={this.hideEndTripForm}
        >
          <EndTripForm
            loading={endTripFormLoading}
            endKm={endKm}
            onSubmit={this.submitEndTripForm}
            onCancel={this.hideEndTripForm}
          />
        </Popup>
        <Popup
          show={this.state.paymentsForm}
          className="trip-finclose-form"
          heading={getMessage(`trips.action.fin_close`)}
          close={this.hidePaymentsForm}
        >
          {this.state.paymentsForm && (
            <PaymentModeContext.Consumer>
              {(value) => (
                <TripPaymentsForm
                  orders={this.state.tripDetails.orders}
                  onSubmit={this.financiallyCloseTrip}
                  paymentMode={value.paymentMethods}
                />
              )}
            </PaymentModeContext.Consumer>
          )}
        </Popup>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          {hasEditPermissions && status === "OPEN" && (
            <DropDownItem onClick={this.showEndTripForm}>
              {getMessage(`trips.action.close`)}
            </DropDownItem>
          )}
          {hasEditPermissions && status === "CLOSE" && (
            <DropDownItem onClick={this.showPaymentsForm}>
              {getMessage(`trips.action.fin_close`)}
            </DropDownItem>
          )}
          <DropDownItem>
            <Link to={`/logistics/trips/${this.props.id}`}>
              {getMessage("trips.action.viewDetails")}
            </Link>
          </DropDownItem>
        </DropDown>
      </div>
    );
  }
}

const tableProperties = () => {
  return {
    headers: [
      getMessage("trips.header.id"),
      getMessage("trips.header.deliveryAgent"),
      getMessage("trips.header.type"),
      getMessage("trips.header.startTime"),
      getMessage("trips.header.endTime"),
      getMessage("trips.header.status"),
      getMessage("trips.header.actions"),
    ],
    row: (props) => {
      const {
        id,
        apiParams,
        deliveryAgent = {},
        startTime,
        endTime,
        status,
        vehicle,
        onAction,
        hubId,
      } = props;
      return (
        <Row>
          <Cell className="trip-id">
            <div className="trip-details">
              <Link to={`/logistics/trips/${id}`}>{`${id}`}</Link>
            </div>
          </Cell>
          <Cell>
            <div className="trip-details trips-agent-name">
              {deliveryAgent?.name}
            </div>
            <div className="trip-details">
              <div className={`vehicle-number type-${vehicle?.type}`.trim()}>
                <span className="trips-vehicle-number">{vehicle?.number}</span>
              </div>
            </div>
          </Cell>
          <Cell className="trip-type-hub">
            {hubId ? (
              <>{getMessage("trips.body.type.hub")}</>
            ) : (
              <>{getMessage("trips.body.type.store")}</>
            )}
          </Cell>
          <Cell className="trip-start-time">
            <div>
              <div className="trip-header-starttime">{`${getMessage(
                "trips.header.startTime"
              )}: `}</div>
              <div>
                {startTime
                  ? `${getPrintableTime(startTime).split(", ")[0]} `
                  : null}
                <span className="trip-header-time">
                  {startTime
                    ? getPrintableTime(startTime).split(", ")[1]
                    : null}
                </span>
              </div>
            </div>
          </Cell>
          <Cell className="trip-end-time">
            <div>
              <div className="trip-header-endtime">{`${getMessage(
                "trips.header.endTime"
              )}: `}</div>
              <div>
                {endTime
                  ? `${getPrintableTime(endTime).split(", ")[0]} `
                  : null}
                <span className="trip-header-time">
                  {endTime ? getPrintableTime(endTime).split(", ")[1] : null}
                </span>
              </div>
            </div>
          </Cell>
          <Cell className="trip-status">
            <small className="text-muted">
              {["OPEN", "CLOSE", "FIN_CLOSE"].indexOf(status) > -1
                ? getMessage(`trips.status.${status.toLowerCase()}`)
                : status}
            </small>
          </Cell>
          <Cell className="trip-actions">
            <PaymentModeContext.Consumer>
              {(value) => (
                <TableActions
                  id={id}
                  status={status}
                  onAction={onAction}
                  storeId={apiParams.storeId}
                  history={value.history}
                />
              )}
            </PaymentModeContext.Consumer>
          </Cell>
        </Row>
      );
    },
  };
};

class InterfaceContainer extends Component {
  componentDidMount() {
    this.orderConfigApi = new API({ url: "/config-service/config/order" });
    this.orderConfigApi
      .get()
      .then((response) => {
        this.setState({
          paymentMethods:
            response.data.order.paymentMethods?.["DELIVERY"] || [],
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  componentWillUnmount() {
    this.orderConfigApi && this.orderConfigApi.cancel();
  }
  getParams = () => {
    const { stores } = this.props;
    const storeId = getDefaultStore(stores)?.storeId;
    return {
      hubId: storeId,
      storeId,
    };
  };
  render() {
    const { props } = this;
    let context = {
      paymentMethods: this.state && this.state.paymentMethods,
      history: this.props.history,
    };
    return (
      <PaymentModeContext.Provider value={context}>
        <ListingPage
          menu={props.menu}
          className="trips-page"
          title={getMessage("trips.heading")}
          api={{
            url: "/logistics-service/trip",
            params: this.getParams(),
            transform: (response) => {
              const trips = response.data.trip;
              if (Array.isArray(trips)) {
                trips.map((trip) => {
                  if (trip.startTime) {
                    trip.startTime = getStandradDateTime(
                      utcToLocalTime,
                      trip.startTime
                    );
                  }
                  if (trip.endTime) {
                    trip.endTime = getStandradDateTime(
                      utcToLocalTime,
                      trip.endTime
                    );
                  }
                  return trip;
                });
              } else {
                if (trips.startTime) {
                  trips.startTime = getStandradDateTime(
                    utcToLocalTime,
                    trips.startTime
                  );
                }
                if (trips.endTime) {
                  trips.endTime = getStandradDateTime(
                    utcToLocalTime,
                    trips.endTime
                  );
                }
              }
              return trips;
            },
          }}
          sethubId={true}
          emptyState={emptyState(props)}
          tableProperties={tableProperties()}
          storeDependent
          filters={{
            component: TripFilters,
            transformSubmit: ({ vehicle, roles, ...formData }) => {
              let filters = Object.assign({}, formData);
              if (vehicle) {
                filters.vehicleId = vehicle.id;
              }
              if (roles) {
                filters.deliveryAgentId = roles.id;
                delete filters.roles;
              }
              return filters;
            },
          }}
        />
      </PaymentModeContext.Provider>
    );
  }
}

export default InterfaceContainer;

export { TableActions };
