import React, { Component } from "react";
import { getMessage } from "../../../../../lib/translator";
import { getPrintableTime } from "../../../../../lib/datetime";
import preoder from "./preorder.svg";
import "./style.css";

class PlacementDetails extends Component {
  render() {
    let orderStatusText = null;
    let status = this.props.data.status;
    switch (this.props.data.status) {
      case "PENDING":
      case "COMPLETED":
        orderStatusText = (
          <span className={`order-status-${status.toLowerCase()}`}>
            {getMessage(`order.status.${status.toLowerCase()}`)}
          </span>
        );
        break;
      default:
        if (status) {
          orderStatusText = (
            <span>
              {status.slice(0, 1).toUpperCase() + status.slice(1).toLowerCase()}
            </span>
          );
        }
        break;
    }
    let deviceParameters =
      (this.props.data.device && this.props.data.device.split("_")) || [];
    let device =
      deviceParameters[0] === "DESKTOP"
        ? deviceParameters[0]
        : deviceParameters[1];
    switch (device) {
      case "DESKTOP":
      case "ANDROID":
      case "IOS":
        device = getMessage(`order.details.device.${device.toLowerCase()}`);
        break;
      default:
        device = getMessage(`order.details.device.${"DESKTOP".toLowerCase()}`);
        break;
    }
    return (
      <div className="order-placement-details">
        <div className="section-pre-order">
          <h3 className="section-title">Status: {orderStatusText}</h3>
          {this.props.data.preorder && (
            <div className="pre-order">
              <img src={preoder} alt="pre-order-icon" />
              <span>{getMessage("order.details.preorder")}</span>
            </div>
          )}
        </div>
        <div className="order-placement-info-item">
          <span className="order-placement-info-icon icon-clock" />
          <small className="text-muted">
            Placed Time:{" "}
            <span className="creation-time">
              {getPrintableTime(this.props.data.creationTime)}
            </span>
          </small>
        </div>
        {this.props.data.completionTime ? (
          <div className="order-placement-info-item">
            <span className="order-placement-info-icon icon-clock" />
            <small className="text-muted">
              Completion Time:{" "}
              <span className="completion-time">
                {getPrintableTime(this.props.data.completionTime)}
              </span>
            </small>
          </div>
        ) : null}
        <div className="order-placement-info-item">
          <span className="order-placement-info-icon icon-mobile" />
          <small className="text-muted">
            Placed From: <span className="device-name">{device}</span>
          </small>
        </div>
      </div>
    );
  }
}

export default PlacementDetails;
