import React from "react";
import { withRouter } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { getMessage } from "../../../../lib/translator";
import {
  BaseForm,
  Input,
  Checkbox,
  CheckboxGroup,
} from "../../../../components/Form";
import API from "../../../../lib/api";
import { Dialog } from "../../../../components/Popup";
import "./style.css";
import { GO_HOST } from "../../../../config/app";

const COD = "COD";
const PICKUP = "PICKUP";
const DELIVERY = "DELIVERY";

const showConfig = () => {
  if (GO_HOST.includes("tamimimarkets.com")) return false;
  return true;
};

class OrderManagementForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.showLoader = false;
    this.handleClose = this.handleClose.bind(this);
    this.closeDialogs = this.closeDialogs.bind(this);
    this.state.hasConfigAccess = showConfig();
    this.state.showPaymentError = false;
  }
  onPaymentChange = (value, type, key) => {
    if (type === "PICKUP") {
      const paymentMethods = this.state.values.configuredPaymentsPickup || [];
      if (value) {
        paymentMethods.push(key);
      } else {
        const index = paymentMethods.indexOf(key);
        paymentMethods.splice(index, 1);
      }

      this.setState((prevState) => {
        return {
          values: {
            ...prevState.values,
            configuredPaymentsPickup: paymentMethods,
          },
        };
      });
    } else {
      const paymentMethods = this.state.values.configuredPaymentsDelivery || [];
      if (value) {
        paymentMethods.push(key);
      } else {
        const index = paymentMethods.indexOf(key);
        paymentMethods.splice(index, 1);
      }
      this.setState((prevState) => {
        return {
          values: {
            ...prevState.values,
            configuredPaymentsDelivery: paymentMethods,
          },
        };
      });
    }
  };
  componentDidMount() {
    this.setState({
      showLoader: true,
    });

    const logisticApi = new API({ url: `/config-service/config/logistics` });
    const orderApi = new API({ url: `/account-service/config/order` });
    const orderConfigApi = new API({ url: "/config-service/config/order" });
    Promise.all([orderApi.get(), logisticApi.get(), orderConfigApi.get()])
      .then(([orderApiResponse, logisticApiResponse, orderConfigResponse]) => {
        let values = JSON.parse(JSON.stringify(this.state.values));
        const orderPayments = orderConfigResponse.data.order;
        const paymentMethods = [
          "ONLINE",
          "WALLET",
          "CREDIT-AMEX",
          "CREDIT-VISA",
          "CREDIT-MASTER",
          "DEBIT-MADA",
          "GIFTCARD",
          "APPLE_PAY",
          "COD",
        ];

        values.configuredPaymentsPickup =
          orderPayments?.paymentMethods?.PICKUP || [];
        values.configuredPaymentsDelivery =
          orderPayments?.paymentMethods?.DELIVERY || [];

        values.minimumOrderValue =
          (orderApiResponse &&
            orderApiResponse.data &&
            orderApiResponse.data.config &&
            orderApiResponse.data.config.order &&
            orderApiResponse.data.config.order.minimumOrderValue) ||
          0;
        values.deliveryFee =
          (logisticApiResponse &&
            logisticApiResponse.data &&
            logisticApiResponse.data.logistics &&
            logisticApiResponse.data.logistics.deliveryFee) ||
          0;
        // values.paymentMethods =
        //   orderConfigResponse &&
        //   orderConfigResponse.data &&
        //   orderConfigResponse.data.order &&
        //   orderConfigResponse.data.order.paymentMethods;

        // values.isCODEnabled =
        //   orderConfigResponse &&
        //   orderConfigResponse.data &&
        //   orderConfigResponse.data.order &&
        //   orderConfigResponse.data.order.paymentMethods &&
        //   orderConfigResponse.data.order.paymentMethods?.includes(COD);
        values.generateRandomReferenceNumbers = Boolean(
          orderConfigResponse &&
            orderConfigResponse.data &&
            orderConfigResponse.data.order &&
            orderConfigResponse.data.order.generateRandomReferenceNumbers
        );
        values.orderTypes = orderApiResponse?.data?.config?.order?.orderTypes;
        values.minimumOrderValueForFreeDelivery =
          orderConfigResponse?.data?.order?.minimumOrderValueForFreeDelivery;
        values.minimumOrderValueForFreePickup =
          orderConfigResponse?.data?.order?.minimumOrderValueForFreePickUp;
        values.minimumOrderValueForDelivery =
          orderConfigResponse?.data?.order?.minimumOrderValueForDelivery;
        values.minimumOrderValueForPickup =
          orderConfigResponse?.data?.order?.minimumOrderValueForPickup;

        this.setState({
          values,
          showLoader: false,
          availablePayments: paymentMethods,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ showLoader: false });
        if (error.code === 401) {
          throw error;
        }
      });
  }
  handleSubmit(data = {}) {
    const { configuredPaymentsPickup, configuredPaymentsDelivery } =
      this.state.values;
    let payload = {};
    if (this.state.values?.orderTypes?.includes(PICKUP)) {
      payload[PICKUP] = configuredPaymentsPickup;
    }
    if (this.state.values?.orderTypes?.includes(DELIVERY)) {
      payload[DELIVERY] = configuredPaymentsDelivery;
    }

    let order = {};
    if (data && data.hasOwnProperty("minimumOrderValue")) {
      order.minimumOrderValue = data["minimumOrderValue"];
    } else {
      order.minimumOrderValue = 0;
    }

    if (data.orderTypes) {
      order.orderTypes = data.orderTypes;
    }

    const logistics = {},
      orderConfig = {};
    logistics.deliveryFee = data["deliveryFee"];
    orderConfig.generateRandomReferenceNumbers = Boolean(
      data["generateRandomReferenceNumbers"]
    );
    orderConfig.minimumOrderValueForDelivery =
      data.minimumOrderValueForDelivery;
    orderConfig.minimumOrderValueForPickup = data.minimumOrderValueForPickup;
    orderConfig.minimumOrderValueForFreeDelivery =
      data.minimumOrderValueForFreeDelivery;

    if (data?.minimumOrderValueForFreePickup) {
      orderConfig.minimumOrderValueForFreePickUp =
        data.minimumOrderValueForFreePickup;
    }
    if (data.isCODEnabled) {
      if (data.paymentMethods.indexOf(COD) > -1) {
        // Removing duplicate entries. This is for cleaning already affected data
        data.paymentMethods = data.paymentMethods.filter(
          (paymentMeyhod, index, array) =>
            array.indexOf(paymentMeyhod) === index
        );
      } else {
        data.paymentMethods = [...data.paymentMethods, COD];
      }
      orderConfig.paymentMethods = payload;
    } else {
      orderConfig.paymentMethods = payload;
    }
    // orderConfig.orderTypesToPaymentMethods = payload;
    const logisticAndOrderConfigApi = new API({
      url: `/config-service/config`,
    });
    const api = new API({ url: `/account-service/config/order` });

    return Promise.all([
      api.put({ order }),
      logisticAndOrderConfigApi.post({ logistics, order: orderConfig }),
    ])
      .then(([orderResponse, logisticApiResponse]) => {
        this.setState({ formError: "", showSuccessDialog: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ formError: error.message, showErrorDialog: true });

        if (error.code === 401) {
          throw error;
        }
      });
  }

  validatePaymentModes = (data) => {
    const hasPickup = data.orderTypes?.includes(PICKUP);
    const hasDelivery = data.orderTypes?.includes(DELIVERY);

    switch (true) {
      case hasPickup && hasDelivery:
        return (
          data.configuredPaymentsPickup.length > 0 &&
          data.configuredPaymentsDelivery.length > 0
        );
      case hasPickup:
        return data.configuredPaymentsPickup.length > 0;
      case hasDelivery:
        return data.configuredPaymentsDelivery.length > 0;
      default:
        return false;
    }
  };

  onSubmit(data) {
    if (this.validatePaymentModes(data)) {
      this.setState({
        submitting: true,
        showPaymentError: false,
      });
      this.handleSubmit(data).then(() => {
        this.setState({ submitting: false });
      });
    } else {
      this.setState({
        showPaymentError: true,
      });
    }
  }
  handleClose() {
    this.props.history.goBack();
  }
  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
      showPaymentError: false,
    });
  }
  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const { hasConfigAccess } = this.state;
    const { availablePayments = [] } = this.state;
    return (
      <div>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("basicInfo.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("basicInfo.save.success.closeText")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage("basicInfo.save.error.title")}
          close={this.closeDialogs}
          closeText={getMessage("basicInfo.save.error.closeText")}
        />
        <Dialog
          show={this.state.showPaymentError}
          information={getMessage("orderConfig.form.payment.validate.error")}
          close={this.closeDialogs}
          closeText={getMessage("notification.okay.text")}
        />
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <Form className="order-management-form">
            {hasConfigAccess && (
              <Input
                label={getMessage("orderConfig.form.minimumOrderValue.heading")}
                placeholder={getMessage(
                  "orderConfig.form.minimumOrderValue.placeholder"
                )}
                name="minimumOrderValue"
                type="number"
                min={0}
                {...this.generateStateMappers({
                  stateKeys: ["minimumOrderValue"],
                  loseEmphasisOnFill: true,
                })}
              />
            )}
            {this.state.values?.orderTypes?.includes(DELIVERY) &&
              hasConfigAccess && (
                <Input
                  label={getMessage(
                    "orderConfig.form.minimumOrderValueFreeDelivery.heading"
                  )}
                  placeholder={getMessage(
                    "orderConfig.form.minimumOrderValueFreeDelivery.placeholder"
                  )}
                  name="minimumOrderValueForFreeDelivery"
                  type="number"
                  min={0}
                  {...this.generateStateMappers({
                    stateKeys: ["minimumOrderValueForFreeDelivery"],
                    loseEmphasisOnFill: true,
                  })}
                />
              )}
            {this.state.values?.orderTypes?.includes(PICKUP) &&
              hasConfigAccess && (
                <Input
                  label={getMessage(
                    "orderConfig.form.minimumOrderValueFreePickup.heading"
                  )}
                  placeholder={getMessage(
                    "orderConfig.form.minimumOrderValueFreePickup.placeholder"
                  )}
                  name="minimumOrderValueForFreePickup"
                  type="number"
                  min={0}
                  {...this.generateStateMappers({
                    stateKeys: ["minimumOrderValueForFreePickup"],
                    loseEmphasisOnFill: true,
                  })}
                />
              )}
            {this.state.values?.orderTypes?.includes(DELIVERY) && (
              <Input
                label={getMessage(
                  "orderConfig.form.minimumOrderValueForDelivery.heading"
                )}
                placeholder={getMessage(
                  "orderConfig.form.minimumOrderValueForDelivery.placeholder"
                )}
                name="minimumOrderValueForDelivery"
                type="number"
                min={0}
                {...this.generateStateMappers({
                  stateKeys: ["minimumOrderValueForDelivery"],
                  loseEmphasisOnFill: true,
                })}
              />
            )}
            {this.state.values?.orderTypes?.includes(PICKUP) &&
              hasConfigAccess && (
                <Input
                  label={getMessage(
                    "orderConfig.form.minimumOrderValueForPickup.heading"
                  )}
                  placeholder={getMessage(
                    "orderConfig.form.minimumOrderValueForPickup.placeholder"
                  )}
                  name="minimumOrderValueForPickup"
                  type="number"
                  min={0}
                  {...this.generateStateMappers({
                    stateKeys: ["minimumOrderValueForPickup"],
                    loseEmphasisOnFill: true,
                  })}
                />
              )}
            <Input
              label={getMessage("orderConfig.form.deliveryFees.heading")}
              placeholder={getMessage(
                "orderConfig.form.deliveryFees.placeholder"
              )}
              name="deliveryFee"
              type="number"
              min={0}
              {...this.generateStateMappers({
                stateKeys: ["deliveryFee"],
                loseEmphasisOnFill: true,
              })}
            />
            {/* {hasConfigAccess && (
              <div className="is-cod-enabled">
                <Checkbox
                  label={getMessage("")}
                  inlineLabel={getMessage("Enable COD")}
                  name={`isCODEnabled`}
                  {...this.generateStateMappers({
                    stateKeys: ["isCODEnabled"],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            )} */}
            {hasConfigAccess && (
              <Checkbox
                label={getMessage("order.config.referenceNumber.heading")}
                inlineLabel={getMessage(
                  "order.config.generateRandomReferenceNumbers"
                )}
                name={`generateRandomReferenceNumbers`}
                className="field reference-number"
                {...this.generateStateMappers({
                  stateKeys: ["generateRandomReferenceNumbers"],
                  loseEmphasisOnFill: true,
                })}
              />
            )}
            <CheckboxGroup
              label={getMessage("order.config.orderTypes")}
              name="orderTypes"
              options={[
                {
                  text: getMessage("offer.ordertype.options.delivery"),
                  value: "DELIVERY",
                },
                {
                  text: getMessage("offer.ordertype.options.pickup"),
                  value: "PICKUP",
                },
              ]}
              {...this.generateStateMappers({
                stateKeys: ["orderTypes"],
                loseEmphasisOnFill: true,
              })}
              required
            />
            <div className="payment-modes">
              <div className="order-by-payment-rules">
                <div className="payment-order-label">
                  {getMessage("choose.payment.mode.for.order.type")}
                </div>
                <table className="payment-mode-table">
                  <thead>
                    <tr>
                      <th className="description">
                        {getMessage("order.by.label.for.payment")}
                      </th>
                      {Array.isArray(availablePayments) &&
                        availablePayments.map((item) => (
                          <th className="description" key={item}>
                            {getMessage(
                              `operations.store.form.payment.${item}`
                            )}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.values?.orderTypes?.includes(DELIVERY) && (
                      <tr key={"DELIVERY"}>
                        <td className="order-type">
                          {getMessage("order.details.location.option.delivery")}
                        </td>
                        {Array.isArray(availablePayments) &&
                          availablePayments.map((item) => {
                            const hasConfigured =
                              this.state.values?.configuredPaymentsDelivery?.includes(
                                item
                              );
                            return (
                              <td
                                className="payments-list"
                                key={`${"DELIVERY"}-${item}`}
                              >
                                <Checkbox
                                  className="payment-mode"
                                  name={`${"DELIVERY"}-${item}`}
                                  value={hasConfigured}
                                  onChange={(e) =>
                                    this.onPaymentChange(e, "DELIVERY", item)
                                  }
                                />
                              </td>
                            );
                          })}
                      </tr>
                    )}
                    {this.state.values?.orderTypes?.includes(PICKUP) && (
                      <tr key={"PICKUP"}>
                        <td className="order-type">
                          {getMessage("order.details.location.option.pickup")}
                        </td>
                        {Array.isArray(availablePayments) &&
                          availablePayments.map((item) => {
                            const hasConfigured =
                              this.state.values?.configuredPaymentsPickup?.includes(
                                item
                              );
                            return (
                              <td
                                className="payments-list"
                                key={`${"PICKUP"}-${item}`}
                              >
                                <Checkbox
                                  className="payment-mode"
                                  name={`${"PICKUP"}-${item}`}
                                  value={hasConfigured}
                                  onChange={(e) =>
                                    this.onPaymentChange(e, "PICKUP", item)
                                  }
                                />
                              </td>
                            );
                          })}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="form-buttons-container">
              <SubmitButton disabled={this.state.submitting}>
                {getMessage("basicInfo.submit.text")}
              </SubmitButton>
              <button
                className="button"
                type="button"
                onClick={this.handleClose}
                disabled={this.state.submitting}
              >
                {getMessage("basicInfo.cancel.text")}
              </button>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

export default withRouter(OrderManagementForm);
