import React from "react";
import { BaseForm, Input, VALIDATION_TYPES } from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";

class AddEmail extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      formError: "",
    };
  }
  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div className="addEmailForm">
        <Form>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <Input
            label={getMessage("notifications.email")}
            type="email"
            name="email"
            required
            placeholder={getMessage("notifications.enter.email")}
            {...this.generateStateMappers({
              stateKeys: ["email"],
              validationType: VALIDATION_TYPES.ONCHANGE,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
              typeMismatch: getMessage("input.invalidEmailFormat"),
            }}
          />
          <div className="form-buttons-container">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage("notifications.submitText")}
            </SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}
export default AddEmail;
