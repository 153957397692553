import React from "react";
import Image from "../../Image";
import "./style.css";
import LayoutGreenCard from "./LayoutGreenCard";
import LayoutRedCard from "./LayoutRedCard";

export default function DualImageGrid(props) {
  const { data, textPosition = "" } = props;
  const { imageUrl1, imageUrl2, subtitle1, subtitle2, title1, title2 } = data;
  const className = `${textPosition} DualImageGrid`;
  return (
    <div className={className}>
      <figure>
        {imageUrl1 ? (
          <Image
            className="dual-image"
            src={imageUrl1}
            alt="uploaded image 1"
          />
        ) : (
          <LayoutGreenCard className="dual-image dual-image-svg" />
        )}
        <figcaption className="fig-details">
          <div className="title">{title1}</div>
          <div className="subtitle">{subtitle1}</div>
        </figcaption>
      </figure>
      <figure>
        {imageUrl2 ? (
          <Image
            className="dual-image"
            src={imageUrl2}
            alt="uploaded image 2"
          />
        ) : (
          <LayoutRedCard className="dual-image dual-image-svg" />
        )}
        <figcaption className="fig-details">
          <div className="title">{title2}</div>
          <div className="subtitle">{subtitle2}</div>
        </figcaption>
      </figure>
    </div>
  );
}
