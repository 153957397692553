import React, { Component } from "react";
import { ZOPSMART_URL } from "../../config/app.js";
import { Link } from "react-router-dom";
import logo from "./smart-store-logo-easy.svg";
import enterpriseLogo from "./enterprise-logo.svg";
import "./style.css";

export default class EazyHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick);
  }

  handleClick(e) {
    if (this.toggleRef && this.toggleRef.contains(e.target)) {
      this.toggle();
    } else {
      this.hide();
    }
  }

  toggle() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  hide() {
    this.setState({
      expanded: false,
    });
  }

  render() {
    let { current, links, hiddenHeader, enterprise } = this.props;
    const { expanded } = this.state;
    return (
      <header className="eazy-header">
        <div>
          <div className="wrap">
            <div>
              <a
                href={
                  enterprise
                    ? `${ZOPSMART_URL}/enterprise`
                    : `${ZOPSMART_URL}/eazy`
                }
                className="logo-link"
              >
                <img
                  className="logo"
                  src={enterprise ? enterpriseLogo : logo}
                  alt="ZopSmart Logo"
                />
              </a>
            </div>
            {!enterprise && (
              <div className="menu-button-container">
                <div
                  className={`hamburger ${expanded ? "close" : ""}`.trim()}
                  ref={(node) => {
                    this.toggleRef = node;
                  }}
                >
                  <span />
                  <span />
                  <span />
                </div>
                <nav
                  className={`site-nav ${expanded ? "expanded" : ""} ${
                    hiddenHeader ? "hidden-header" : ""
                  }`.trim()}
                >
                  <ul>
                    <li>
                      <a
                        href={ZOPSMART_URL + "/eazy"}
                        className={`${
                          current === "home" ? "current" : ""
                        }`.trim()}
                      >
                        HOME
                      </a>
                    </li>
                    <li>
                      <a
                        href={ZOPSMART_URL + "/eazy/features"}
                        className={`${
                          current === "features" ? "current" : ""
                        }`.trim()}
                      >
                        FEATURES
                      </a>
                    </li>
                    <li>
                      <a
                        href={ZOPSMART_URL + "/eazy/pricing"}
                        className={`${
                          current === "pricing" ? "current" : ""
                        }`.trim()}
                      >
                        PRICING
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="header-links">
                  {links &&
                    links.map((a, index) => (
                      <Link
                        to={a.href}
                        className={a.className}
                        key={`link-${index}`}
                      >
                        {a.label}
                      </Link>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}
