import React, { Component } from "react";
import "./style.css";

export default class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.default || props.active || 0,
    };
    this.createClickHandler = this.createClickHandler.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (
      Number.isInteger(newProps.active) &&
      newProps.active !== this.state.activeTab
    ) {
      this.setState({
        activeTab: newProps.active,
      });
    }
  }

  createClickHandler(currentTab) {
    return (e) => {
      e && e.preventDefault();
      if (this.state.activeTab !== currentTab) {
        this.setState({
          activeTab: currentTab,
        });
        this.props.onClick && this.props.onClick(currentTab);
      }
    };
  }

  render() {
    const props = this.props;
    const { nameKey, valueKey } = props;
    const state = this.state;
    const align = props.align ? props.align : "";
    const component =
      nameKey && valueKey ? (
        <div className={"tabs " + align}>
          {props.items.map((item) => {
            return (
              <button
                key={item[valueKey]}
                className={
                  (state.activeTab === item[valueKey] ? "active " : "") +
                  (state.activeTab - 1 === item[valueKey] ? "previous " : "") +
                  "tab"
                }
                onClick={this.createClickHandler(item[valueKey])}
              >
                {item[nameKey]}
              </button>
            );
          })}
        </div>
      ) : (
        <div className={"tabs " + align}>
          {props.items.map((item, index) => {
            return (
              <button
                key={index}
                className={
                  (state.activeTab === index ? "active " : "") +
                  (state.activeTab - 1 === index ? "previous " : "") +
                  "tab"
                }
                onClick={this.createClickHandler(index)}
              >
                {item}
              </button>
            );
          })}
        </div>
      );
    return component;
  }
}
