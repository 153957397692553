import React from "react";
import {
  Input,
  Select,
  BaseForm,
  Phone,
  MultiSelect,
  VALIDATION_TYPES,
  Checkbox,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import deleteIcon from "./delete.svg";
import SelectStoresSearch from "../../../../components/SelectStoresSearch";

import "./style.css";

const getTimes = () => {
  let times = [];
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      times.push(`0${i}:00`);
      times.push(`0${i}:30`);
    } else {
      times.push(`${i}:00`);
      times.push(`${i}:30`);
    }
  }
  return times;
};

const daysOfWeek = [
  {
    text: "Mon",
    value: "Monday",
  },
  {
    text: "Tue",
    value: "Tuesday",
  },
  {
    text: "Wed",
    value: "Wednesday",
  },
  {
    text: "Thur",
    value: "Thursday",
  },
  {
    text: "Fri",
    value: "Friday",
  },
  {
    text: "Sat",
    value: "Saturday",
  },
  {
    text: "Sun",
    value: "Sunday",
  },
];

export default class UserShiftForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.allSelected = false;
    this.delete = this.delete.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
  }

  handleSelectAll(value) {
    let currState = JSON.parse(JSON.stringify(this.state));
    let { values } = currState;
    if (value) {
      values.storeIds = (this.props.options.stores || []).map(
        (store) => store.id
      );
      this.props.options.stores && this.setState({ values, allSelected: true });
    } else {
      values.storeIds = [];
      this.setState({ values, allSelected: false });
    }
  }

  componentDidMount() {
    let { state, props } = this;
    let shiftStart, shiftEnd;
    let { values } = state;
    let newState = Object.assign({}, this.state.values);
    if (this.props && this.props.value) {
      shiftStart = this.props.value.shiftStart;
      shiftEnd = this.props.value.shiftEnd;
    }
    if (shiftStart && shiftEnd) {
      shiftStart = shiftStart.split(":").splice(0, 2).join(":");
      shiftEnd = shiftEnd.split(":").splice(0, 2).join(":");
      newState.shiftStart = shiftStart;
      newState.shiftEnd = shiftEnd;
    }
    if (values.stores && values.stores.length > 0) {
      let stores = values.stores;
      let storeIds = stores.map((store) => {
        return store.id;
      });
      newState.storeIds = storeIds;
    }
    if (values.designation && values.designation) {
      newState.designationId = values.designation.id;
    }
    this.setState(
      {
        values: newState,
      },
      () => {
        if (props.method === "edit") {
          let currState = JSON.parse(JSON.stringify(this.state));
          let { values } = currState;
          let { storeIds } = values;
          if (
            props.options.stores &&
            props.options.stores.every((store) =>
              (storeIds || []).find((id) => id === store.id)
            )
          ) {
            this.setState({ allSelected: true });
          }
        }
      }
    );
    this.stores =
      this.props.options.multiStoreEnabled && (this.props.options.stores || []);

    this.designationOptions =
      this.props.options.designations &&
      this.props.options.designations.map((designation) => ({
        text: designation.name,
        value: designation.id,
      }));
    // if (this.props.method === 'edit' && this.state.values.stores && this.state.values.stores.length > 4) {
    //   this.updateState(['storeIds'], this.state.values && this.state.values.stores.map(store => (
    //     {
    //       text: store.name,
    //       value: store.id
    //     }
    //   )))
    // }
  }

  delete() {
    this.props.onDelete();
  }
  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const designationId = this.state.values.designationId;
    const { Form } = this.components;
    const { isoCode, stores, designations } = this.props.options;
    const selectedDes =
      designations &&
      designations.filter((des) => Number(des.id) === Number(designationId))[0];
    const timingType =
      designationId &&
      designations &&
      selectedDes &&
      selectedDes.timingType === "FIXED";
    return (
      <div className={"user-shifts-form"}>
        <Form>
          <div className="flex">
            <Input
              label={getMessage("shifts.form.name")}
              placeholder={getMessage("shifts.form.name.placeholder")}
              name="name"
              required
              {...this.generateStateMappers({
                stateKeys: ["name"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Phone
              label={getMessage("shifts.form.phone")}
              placeholder={getMessage("shifts.phone.placeholder")}
              country={isoCode}
              name="phone"
              // Hack. Modify the Phone component for solving this issue
              {...this.generateStateMappers({
                stateKeys: ["phones", 0, "phone"],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className="flex">
            <Input
              label={getMessage("shifts.form.email")}
              placeholder={getMessage("shifts.form.email.placeholder")}
              required
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["emails", 0, "email"],
                loseEmphasisOnFill: true,
              })}
            />
            <Select
              label={getMessage("shifts.designation")}
              placeholder={getMessage("shifts.designation.placeholder")}
              name="designation"
              options={this.designationOptions}
              {...this.generateStateMappers({
                stateKeys: ["designationId"],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          {
            <React.Fragment>
              {timingType ? (
                <div className="shift-details">
                  <Select
                    label={getMessage("shifts.form.startTime")}
                    name="shiftStart"
                    type="text"
                    options={getTimes()}
                    {...this.generateStateMappers({
                      stateKeys: ["shiftStart"],
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                  />
                  <Select
                    label={getMessage("shifts.form.endTime")}
                    name="shiftStart"
                    type="text"
                    options={getTimes()}
                    {...this.generateStateMappers({
                      stateKeys: ["shiftEnd"],
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                  />
                </div>
              ) : null}
            </React.Fragment>
          }
          <MultiSelect
            name="weeklyOff"
            label={getMessage("shifts.form.weekly.off")}
            placeholder={getMessage("shifts.form.weekly.off.placeholder")}
            options={daysOfWeek}
            {...this.generateStateMappers({
              stateKeys: ["weeklyOff"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          {this.stores && (
            <SelectStoresSearch
              label={getMessage("shifts.stores")}
              placeholder={getMessage("shifts.stores.placeholder")}
              _this={this}
              stores={stores}
              required
              stateKey="storeIds"
              readOnly={this.state.allSelected}
              dontDisplaySelected={this.state.allSelected}
              secondaryLabel={() => (
                <Checkbox
                  inlineLabel={getMessage("select all")}
                  name="selectAllStores"
                  value={this.state.allSelected}
                  controlled
                  onChange={(value) => {
                    this.handleSelectAll(value);
                  }}
                  className="checkLabel"
                />
              )}
            />
          )}
          <div className={`form-actions ${this.props.method}`}>
            {this.props.method && this.props.method === "edit" && (
              <button
                className="delete-button"
                type="button"
                onClick={this.delete}
              >
                <img src={deleteIcon} alt="delete" />
                <span>{getMessage("brand.form.deleteText")}</span>
              </button>
            )}
            <CancelButton>{getMessage("shifts.form.cancel")}</CancelButton>
            <SubmitButton>{getMessage("shifts.form.submitText")}</SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}
