import React from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";
import EmptyChartImg from "./analytics_empty.png";

const COLORS = [
  "#eb8181",
  "#7bd49d",
  "#67cbff",
  "#80959d",
  "#9a1f40",
  "#74d4c0",
  "#f3c623",
  "#127681",
  "#10375c",
  "#726a95",
];

const EmptyState = () => (
  <div className="empty-chart">
    <img src={EmptyChartImg} alt="empty-chart-img" />
    <div>Looks like there is no data yet</div>
  </div>
);

const SimplePieChart = ({
  header,
  data,
  dataKey = "count",
  nameKey = "medium",
  toolTipContent,
}) => (
  <section className="analytic-pie-chart">
    <h5>{header}</h5>
    <div className="pie-chart-display">
      {data.length > 0 ? (
        <ResponsiveContainer width={"100%"} height={420}>
          <PieChart>
            <Pie
              data={data}
              cx={"50%"}
              cy={"50%"}
              innerRadius={80}
              outerRadius={150}
              fill="#8884d8"
              dataKey={dataKey}
              nameKey={nameKey}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index]} />
              ))}
            </Pie>
            <Tooltip
              wrapperStyle={{ width: "5rem", height: "5rem" }}
              content={toolTipContent}
            />
            <Legend iconType="circle" width="5" iconSize={6} />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <EmptyState />
      )}
    </div>
  </section>
);

export default SimplePieChart;
