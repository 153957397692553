import React, { Component } from "react";
import AuthenticatedPage from "../../../containers/AuthenticatedPage/index";
import StatsTable from "./statsTable";
// import TopProductsTable from './topProductsTable'
// import CategoriesStats from './categoriesStats'
import SlotOrderTable from "./slotOrdertable";
import Loader from "../../../components/Loader";
import StoreSelector, {
  makestoreDependentComponent,
  getDefaultStore,
} from "../../../containers/StoreSelector";
import API from "../../../lib/api";
import { getCurrency } from "../../../lib/userDetails";
import { getMessage } from "../../../lib/translator";
import { isExtensionEnabled } from "../../../lib/auth";
import { get } from "../../../lib/storage";

import "./style.css";
import { sortOrderStatus } from "../../../lib/commonlyused";
import MarketPlaceProdStats from "./marketPlaceProdStats";

// Formats Date object in YYYY-MM-DD format
function formatDate(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1; // January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return yyyy + "-" + mm + "-" + dd;
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      // showSmallLoader: true,
      showSlotLoader: true,
      storeId: get("store") || getDefaultStore(this.props.stores).storeId,
      salesStatsData: {},
      customerStatsData: {},
      // topProductsData: [],
      // categoriesSalesData: [],
      showStore: false,
      selectedDate: formatDate(new Date()),
    };
    this.showLoader = this.showLoader.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.changeStore = this.changeStore.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  hideLoader() {
    this.setState({
      showLoader: false,
    });
  }

  handleDateChange(date) {
    this.setState({ selectedDate: date }, () => this.updateContent());
  }

  showLoader() {
    this.setState({
      showLoader: true,
    });
  }

  changeStore(storeId) {
    this.setState(
      {
        storeId: storeId,
      },
      this.updateContent
    );
  }

  componentDidMount() {
    this.updateContent();
  }

  updateContent() {
    this.setState({
      showLoader: true,
      showSlotLoader: true,
      // showSmallLoader: true,
      showStore: true,
    });
    let storeId =
      this.state.storeId ||
      get("store") ||
      getDefaultStore(this.props.stores).storeId;
    let org = JSON.parse(get("organization"));
    storeId = storeId || org.defaultStoreId;
    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const lastWeekDate = formatDate(oneWeekAgo);
    // const today = formatDate(new Date());
    this.orderStatsApi = new API({ url: "/order-service/stats" });
    this.customerStatsApi = new API({ url: "/customer-service/stats" });
    // this.catalogueSalesApi = new API({ url: '/catalogue-service/sales' })
    this.slotOrdersApi = new API({ url: "/order-service/order-count" });

    // storeId && this.catalogueSalesApi.get({ date: today, storeId: storeId }).then((catalogueResponse) => {
    //   const catalogueSales = catalogueResponse.data.sales
    //   this.setState({
    //     showSmallLoader: false,
    //     topProductsData: catalogueSales.topProducts,
    //     categoriesSalesData: catalogueSales.categorySales,
    //     showSlotLoader: false
    //   })
    // })

    let apis = storeId
      ? [
          this.orderStatsApi.get({ from: lastWeekDate, storeId: storeId }),
          this.customerStatsApi.get({
            startDate: lastWeekDate,
            storeId: storeId,
          }),
        ]
      : [];
    if (isExtensionEnabled("DeliverySlots")) {
      storeId &&
        apis.push(
          this.slotOrdersApi.get({
            preferredDate: this.state.selectedDate,
            storeId: storeId,
          })
        );
    } else {
      storeId &&
        apis.push(
          this.slotOrdersApi.get({
            createdAt: this.state.selectedDate,
            storeId: storeId,
          })
        );
    }

    Promise.all(apis)
      .then(([ordersResponse, customersResponse, slotOrdersResponse]) => {
        const salesStats = ordersResponse
          ? ordersResponse.data.stats.sales
          : {};
        const customerStats = customersResponse
          ? customersResponse.data.stats
          : {};
        const slotOrderStats =
          (slotOrdersResponse && slotOrdersResponse.data) || [];
        let statuses = [];
        let totalOrders = 0;
        slotOrderStats.forEach((slot) => {
          let keys = (slot.count && Object.keys(slot.count)) || [];
          keys.forEach((key) => {
            if (statuses.indexOf(key) === -1) {
              statuses.push(key);
            }
            totalOrders += slot.count[key];
          });
        });
        let fixedSlots = null;
        let asapSlots = null;
        let noSlots = null;
        let count = null;
        if (isExtensionEnabled("DeliverySlots")) {
          fixedSlots =
            slotOrderStats &&
            slotOrderStats
              .filter(
                (slot) =>
                  slot.slot && slot.slot && slot.slot.type === "STANDARD"
              )
              .sort((slot1, slot2) => {
                if (slot1.slot.startTime < slot2.slot.startTime) {
                  return -1;
                } else {
                  return 1;
                }
              });
          asapSlots =
            slotOrderStats &&
            slotOrderStats
              .filter((slot) => slot.slot && slot.slot.type === "ASAP")
              .sort((slot1, slot2) => {
                if (slot1.slot.endTime < slot2.slot.endTime) {
                  return -1;
                } else {
                  return 1;
                }
              });
        } else {
          count = {};
          slotOrderStats &&
            slotOrderStats.forEach((slot) => {
              let keys = Object.keys(slot.count) || [];
              keys.forEach((key) => {
                if (count[key]) {
                  count[key] += slot.count[key];
                } else {
                  count[key] = slot.count[key];
                }
              });
            });
          noSlots = { count: count };
        }

        this.setState({
          showLoader: false,
          customerStatsData: customerStats,
          salesStatsData: salesStats,
          statuses: sortOrderStatus(statuses),
          totalOrders,
          asapSlots,
          fixedSlots,
          noSlots,
          showSlotLoader: false,
          storeId: storeId,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  componentWillUnmount() {
    // this.catalogueSalesApi.cancel();
    this.customerStatsApi.cancel();
    this.slotOrdersApi && this.slotOrdersApi.cancel();
    this.orderStatsApi.cancel();
  }

  render() {
    const { props } = this;
    const {
      showLoader,
      customerStatsData,
      salesStatsData,
      showSlotLoader,
      statuses,
      asapSlots,
      totalOrders,
      fixedSlots,
      noSlots,
      showStore,
    } = this.state;
    const currency = getCurrency() && getCurrency().symbol;
    return (
      <div className="operations-dashboard">
        {showStore && isExtensionEnabled("MultiStoreSupport") && (
          <StoreSelector
            value={this.state.storeId}
            onChange={this.changeStore}
            stores={props.stores}
          />
        )}
        <h1 className="title">{getMessage("operations.dashboard.heading")}</h1>
        <div>
          {showLoader ? (
            <Loader />
          ) : (
            <StatsTable
              currency={currency}
              customersStats={customerStatsData}
              salesStats={salesStatsData}
            />
          )}
          {/* <div className='table-chart-container'>
            {(showSmallLoader) ? <Loader /> : (Object.keys(topProductsData).length > 0) && <TopProductsTable currency={currency} data={topProductsData} />}
            {(showSmallLoader) ? <Loader /> : (Object.keys(categoriesSalesData).length > 0) && <CategoriesStats data={categoriesSalesData} />}
          </div> */}
          {showSlotLoader ? (
            <Loader />
          ) : (
            <SlotOrderTable
              data={{ totalOrders, asapSlots, fixedSlots, noSlots, statuses }}
              handleDateChange={this.handleDateChange}
              selectedDate={this.state.selectedDate}
              areOrdersScheduled={isExtensionEnabled("DeliverySlots")}
            />
          )}
          {isExtensionEnabled("MarketPlace") && (
            <div className="prod-stats">
              <div className="heading">
                {getMessage("operations.prodStats.heading")}
              </div>
              <div className="stats-tables">
                <div className="table-wrp">
                  <p className="title">
                    {getMessage("operations.prodStats.byQty.title")}
                  </p>
                  <MarketPlaceProdStats
                    currency={currency}
                    dateRange={this.state.dateRange}
                    sortBy="Quantity"
                  />
                </div>
                <div className="table-wrp">
                  <p className="title">
                    {getMessage("operations.prodStats.byAmt.title")}
                  </p>
                  <MarketPlaceProdStats
                    currency={currency}
                    dateRange={this.state.dateRange}
                    sortBy="Amount"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const DashboardWithStores = makestoreDependentComponent(Dashboard);

export default class OperationsDashboard extends Component {
  render() {
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <DashboardWithStores {...this.props} />
      </AuthenticatedPage>
    );
  }
}
