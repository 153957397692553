import React, { Component, useState } from "react";
import ListingPage, { TABLE_ACTIONS } from "../../../../containers/ListingPage";
import StoreSelector from "../../../../containers/StoreSelector";
import { getMessage } from "../../../../lib/translator";
import OrderFilters from "./Filters";
import { tabularView } from "./Views";
import { Link } from "react-router-dom";
import API from "../../../../lib/api";
import { isExtensionEnabled } from "../../../../lib/auth";
import emptyIcon from "./no-orders.svg";
import rcmIcon from "./rcm.svg";
import seoIcon from "./seo.svg";
import newCustomerIcon from "./new-customer.svg";
import { DropDown, DropDownItem } from "../../../../components/DropDown";
import { Popup } from "../../../../components/Popup";
import "./style.css";
import { utcToLocalTime, getStandradDateTime } from "../../../../lib/datetime";
import checkHttpResponseCode from "../../../../utils/isApiCallSuccessfull";
import OrderUpload from "../OrderUpload/index";
import { getSession, getPermissionObject } from "../../../../lib/auth";

const bulkOrderFeatureDomains = ["dashboard", "enterprise", "localhost"];

const DEFAULT_ORDER_PERMISSIONS = [
  "CREATE_ORDER",
  "EDIT_ORDER",
  "COMPLETE_ORDER",
  "CANCEL_ORDER",
  "RETURN_ORDER",
  "EDIT_CUSTOM_FIELDS",
];

export const getOrderPermissions = () => {
  const userObject = getSession().user;
  if (!userObject?.isOwner) {
    const orderPermissions = getPermissionObject("order-service", "order");
    const permissions = orderPermissions.metaData?.permissions;
    let modifiedPermissions = [];
    if (permissions?.canCreate) {
      modifiedPermissions.push("CREATE_ORDER");
    }
    if (permissions?.canEdit) {
      modifiedPermissions.push("EDIT_ORDER");
    }
    if (permissions?.canComplete) {
      modifiedPermissions.push("COMPLETE_ORDER");
    }
    if (permissions?.canCancel) {
      modifiedPermissions.push("CANCEL_ORDER");
    }
    if (permissions?.canRefund) {
      modifiedPermissions.push("RETURN_ORDER");
    }
    if (permissions?.canEditCustomFields) {
      modifiedPermissions.push("EDIT_CUSTOM_FIELDS");
    }
    return modifiedPermissions;
  }
  return DEFAULT_ORDER_PERMISSIONS;
};

function CreateNewOrder() {
  const [showPopup, setShowPopup] = useState(false);
  const showModal = () => {
    setShowPopup(!showPopup);
  };

  let subDomain = window.location.hostname.split(".")[0];
  const isbulkOrderFeatureEnabled = bulkOrderFeatureDomains.includes(subDomain);

  return (
    <div className="text-center">
      <Popup
        heading={getMessage("order.create.bulk.text")}
        show={showPopup}
        close={showModal}
        className="bulk-upload"
      >
        <OrderUpload />
      </Popup>

      {isbulkOrderFeatureEnabled ? (
        <DropDown
          className="bulk-upload-dropdown"
          icon={
            <button className="button primary create-order-dropdown-button">
              + <span className="text">{getMessage("order.add.text")}</span>
            </button>
          }
        >
          <DropDownItem>
            <Link to="/operations/orders/add">
              {getMessage("order.add.text")}
            </Link>
          </DropDownItem>
          <DropDownItem onClick={showModal}>
            {getMessage("order.add.bulk.text")}
          </DropDownItem>
        </DropDown>
      ) : (
        <Link to="/operations/orders/add" className="primary button">
          + <span className="text">{getMessage("order.add.text")}</span>
        </Link>
      )}
    </div>
  );
}

const emptyState = (props) => {
  return {
    icon: emptyIcon,
    message: getMessage("order.emptyList.message"),
    actions: ({ onAction, apiParams }) => (
      <React.Fragment>
        <CreateNewOrder />
        {isExtensionEnabled("MultiStoreSupport") && (
          <StoreSelector
            value={apiParams.storeId}
            stores={props.stores}
            onChange={(storeId) => {
              onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId });
            }}
          />
        )}
      </React.Fragment>
    ),
  };
};

const helpItems = {
  title: getMessage("order.helpItems.title"),
  instructions: [
    {
      icon: seoIcon,
      title: getMessage("order.helpItems.seo.title"),
      description: getMessage("order.helpItems.seo.description"),
    },
    {
      icon: rcmIcon,
      title: getMessage("order.helpItems.rcm.title"),
      description: getMessage("order.helpItems.rcm.description"),
    },
    {
      icon: newCustomerIcon,
      title: getMessage("order.helpItems.nca.title"),
      description: getMessage("order.helpItems.nca.description"),
    },
  ],
};

const { Provider, Consumer } = React.createContext("commConfig");

const OrdersContainer = class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderActions: getOrderPermissions(),
      courierParams: isExtensionEnabled("ShipRocket")
        ? { "includes[1]": "courierDetails" }
        : {},
    };
  }
  getOrderConfig = () => {
    const orderConfigApi = new API({ url: "/config-service/config/order" });
    orderConfigApi
      .get()
      .then((response) => {
        this.setState({ orderConfiguration: response.data?.order || {} });
      })
      .catch((error) => console.error(error));
  };

  componentDidMount() {
    this.getOrderConfig();
    this.communicationApi = new API({
      url: `/account-service/config/communication`,
    });
    this.communicationApi
      .get()
      .then((response) => {
        this.setState({ communicationConfig: response.data.config });
      })
      .catch((error) => {
        throw error;
      });

    if (isExtensionEnabled("InStoreProcessing")) {
      this.api = new API({
        url: `/config-service/config/inStoreProcessing.packedOrderEditAllowed`,
      });
      this.api
        .get()
        .then((res) => {
          if (checkHttpResponseCode(res.code) && res.status === "SUCCESS") {
            this.setState({
              packedOrderEdit:
                res &&
                res.data &&
                res.data.inStoreProcessing &&
                res.data.inStoreProcessing.packedOrderEditAllowed,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.code === 401 || err.code === 403) {
            throw err;
          }
        });
    }
  }
  componentWillUnmount() {
    this.communicationApi && this.communicationApi.cancel();
  }
  render() {
    const { props } = this;
    const { courierParams } = this.state;
    // If url is present in the props, then we need to show customer support related interfaces
    return (
      <Provider value={this.state && this.state.communicationConfig}>
        <ListingPage
          menu={props.menu}
          className={`orders-page ${this.props.className || "ops"}`}
          title={getMessage("operations.dashboard.orders")}
          api={{
            url: "/order-service/order",
            params: { ...props.params, ...courierParams },
            updateApiParams: props.updateApiParams
              ? props.updateApiParams
              : null,
            transform: (response) => {
              const orders = response.data.order;
              if (Array.isArray(orders)) {
                orders.map((order) => {
                  if (order.createdAt) {
                    order.createdAt = getStandradDateTime(
                      utcToLocalTime,
                      order.createdAt
                    );
                  }
                  if (order.completedAt) {
                    order.completedAt = getStandradDateTime(
                      utcToLocalTime,
                      order.completedAt
                    );
                  }
                  if (order.updatedAt) {
                    order.updatedAt = getStandradDateTime(
                      utcToLocalTime,
                      order.updatedAt
                    );
                  }
                  if (order.preferredDate && order.slotStartTime) {
                    const tempDate = getStandradDateTime(
                      utcToLocalTime,
                      `${order.preferredDate} ${order.slotStartTime}`
                    ).split(" ");
                    order.preferredDate = tempDate[0];
                    order.slotStartTime = tempDate[1];
                  }
                  if (order.preferredDate && order.slotEndTime) {
                    const tempDate = getStandradDateTime(
                      utcToLocalTime,
                      `${order.preferredDate} ${order.slotEndTime}`
                    ).split(" ");
                    order.slotEndTime = tempDate[1];
                  }
                  return order;
                });
              }
              return orders;
            },
          }}
          primaryKey="referenceNumber"
          emptyState={props.emptyState ? props.emptyState() : emptyState(props)}
          helpItems={helpItems}
          filters={{
            component: OrderFilters,
            transformFilter: (formData) => {
              let result = Object.assign({}, formData);
              if (result.customer) {
                result.customerId = result.customer.id;
                delete result.customer;
              }
              if (result.slot) {
                let slotDetails = JSON.parse(result.slot);
                if (slotDetails.startTime) {
                  result.slotStartTime = slotDetails.startTime;
                }
                result.slotEndTime = slotDetails.endTime;
                result.type = slotDetails.type;
                delete result.slot;
              }
              return result;
            },
            transformSubmit: (formData) => {
              let searchParams = "";
              Object.keys(formData)
                .filter(
                  (key) =>
                    key !== "filters" && (formData[key] === 0 || formData[key])
                )
                .forEach((key, index) => {
                  if (index === 0) {
                    searchParams += `?${key}=${formData[key]}`;
                  } else {
                    searchParams += `&${key}=${formData[key]}`;
                  }
                });
              if (searchParams !== "") {
                this.props.history.push({ search: searchParams });
              }
              let result = Object.assign({}, formData);
              if (result.customer) {
                result.customerId = result.customer.id;
                delete result.customer;
              }
              if (result.slot) {
                let slotDetails = JSON.parse(result.slot);
                if (slotDetails.startTime) {
                  result.slotStartTime = slotDetails.startTime;
                }
                result.slotEndTime = slotDetails.endTime;
                result.type = slotDetails.type;
                delete result.slot;
              }
              return result;
            },
          }}
          onClear={() => this.props.history.push({ search: "filters=all" })}
          headerActions={({ apiParams, onAction }) =>
            this.props.url.includes("operations") &&
            this.state.orderActions?.includes("CREATE_ORDER") ? (
              <React.Fragment>
                <CreateNewOrder />
              </React.Fragment>
            ) : null
          }
          tableProperties={tabularView(
            this.props.url,
            this.state && this.state.packedOrderEdit,
            this.state?.orderConfiguration
          )}
          tableDynamic
          storeDependent={this.props.url === "/operations"}
        />
      </Provider>
    );
  }
};

export default OrdersContainer;

export { Provider, Consumer };
