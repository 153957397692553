import React from "react";
import { Input, Select } from "../Form";

import { getMessage } from "../../lib/translator";

const splitAtCapitalLetter = (str) => str.split(/(?=[A-Z])/).join(" ");
const ENTER_TEXT = getMessage("product.form.enter");
const DESCRIPTION_FIELDS_ALLOWED = {
  DUNZO: ["clientId", "clientSecret"],
  SHIPROCKET: ["email", "password"],
};
const descriptionApplicable = (name, id) => {
  if (
    DESCRIPTION_FIELDS_ALLOWED[id] &&
    DESCRIPTION_FIELDS_ALLOWED[id].includes(name)
  ) {
    return getMessage(`courier.support.${id}.${name}`);
  }

  return null;
};
export default function ProviderConfiguration(props) {
  const { parent, selectedProviderConfig } = props;
  const { fields, id } = selectedProviderConfig;
  const _this = parent;

  return (
    <React.Fragment>
      <div className="form-field">
        {fields.map(({ name, required, allowedValues, type }) => {
          const splittedNameAtCapitalLetter = splitAtCapitalLetter(name);
          const label = splittedNameAtCapitalLetter.toUpperCase();
          const placeholderText = `${ENTER_TEXT} ${splittedNameAtCapitalLetter.toLowerCase()}`;
          return allowedValues ? (
            <Select
              key={name}
              label={label}
              placeholder={placeholderText}
              options={allowedValues}
              name={name}
              {..._this.generateStateMappers({
                stateKeys: [name],
                loseEmphasisOnFill: true,
              })}
              required={required}
            />
          ) : (
            <Input
              key={name}
              name={name}
              label={label}
              type={type === "string" ? "text" : type}
              placeholder={placeholderText}
              {..._this.generateStateMappers({
                stateKeys: [name],
                loseEmphasisOnFill: true,
              })}
              required={required}
            >
              {descriptionApplicable(name, id)}
            </Input>
          );
        })}
      </div>
    </React.Fragment>
  );
}
