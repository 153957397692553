import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { Row, Cell } from "../../../components/Table";
import StoreSelector, {
  getDefaultStore,
} from "../../../containers/StoreSelector";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { Popup } from "../../../components/Popup";

import DisputeForm from "./Form";
import DisputesFilter from "./Filters";

import { getMessage } from "../../../lib/translator";
import { getPrintableTime } from "../../../lib/datetime";

import EmptyIcon from "./dispute-empty.svg";
import "./style.css";

const emptyState = (props) => {
  return {
    icon: EmptyIcon,
    message: getMessage("dispute.emptyMessage"),
    actions: ({ apiParams, onAction }) => (
      <StoreSelector
        value={apiParams.storeId}
        stores={props.stores}
        onChange={(storeId) => {
          onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId });
        }}
      />
    ),
  };
};

class TableActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDisputePopup: false,
    };
  }
  render() {
    const { props } = this;
    return (
      <React.Fragment>
        <Popup
          show={this.state.showDisputePopup}
          heading={`${getMessage("dispute.view.heading")} #${
            props.referenceNumber
          }`}
          close={() => this.setState({ showDisputePopup: false })}
          className="disputes-popup"
        >
          <DisputeForm
            referenceNumber={props.referenceNumber}
            onAction={props.onAction}
            closeDisputePopup={() => this.setState({ showDisputePopup: false })}
          />
        </Popup>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem
            onClick={() => this.setState({ showDisputePopup: true })}
          >
            {getMessage("dispute.view")}
          </DropDownItem>
        </DropDown>
      </React.Fragment>
    );
  }
}

const tableProperties = {
  headers: [
    getMessage("dispute.referenceNumber"),
    getMessage("dispute.orderPlacedOn"),
    getMessage("dispute.actions"),
  ],
  row: ({ referenceNumber, apiParams, createdAt, onAction }) => (
    <Row>
      <Cell>
        <span className="ref-num">
          <Link to={`/operations/orders/${referenceNumber}`}>
            {referenceNumber}
          </Link>
        </span>
      </Cell>
      <Cell>
        <div>
          {createdAt ? getPrintableTime(createdAt).split(", ")[0] : null}
        </div>
        <small className="text-muted">
          {createdAt && getPrintableTime(createdAt).split(", ")[1]}
        </small>
      </Cell>
      <Cell>
        <TableActions referenceNumber={referenceNumber} onAction={onAction} />
      </Cell>
    </Row>
  ),
};

const DisputesContainer = (props) => {
  let params = {};
  params.storeId = getDefaultStore(props.stores).storeId;
  params.disputeStatus = "PENDING";
  return (
    <ListingPage
      menu={props.menu}
      title={getMessage("dispute.heading")}
      className="disputes-page"
      primaryKey="referenceNumber"
      api={{
        url: "/order-service/order",
        params: params,
        transform: (response) => response.data.order,
      }}
      emptyState={emptyState(props)}
      tableProperties={tableProperties}
      headerActions={({ apiParams, onAction }) => (
        <StoreSelector
          value={apiParams.storeId}
          stores={props.stores}
          onChange={(storeId) => {
            onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId });
          }}
        />
      )}
      storeDependent
      filters={{
        component: DisputesFilter,
      }}
    />
  );
};

export default DisputesContainer;
