const data = {
  "coupon.add.text": "Add Coupons",
  "coupon.helpItems.message": "There are no coupons shown here",
  "coupon.heading": "Coupons",
  "coupon.table.heading": "Coupons",
  "coupon.header.coupon": "Coupon",
  "coupon.header.discount": "Discount",
  "coupon.header.limit": "Limit",
  "coupon.header.validity": "Validity",
  "coupon.header.status": "Status",
  "coupon.header.actions": "Actions",
  "coupon.action.edit": "Edit",
  "coupon.action.disable": "Disable",
  "coupon.action.delete": "Delete",
  "coupon.action.details": "Details",
  "coupon.action.usageAnalytic": "Usages Analytic",
  "coupon.form.add.heading": "Coupons",
  "coupon.form.edit.heading": "Edit Coupons",
  "coupon.form.name.heading": "Coupon Code",
  "coupon.form.name.placeholder": "Coupon code",
  "coupon.header.validity.time.start": "Starts At",
  "coupon.header.validity.time.end": "Ends At",
  "coupon.form.discount": "Discount",
  "coupon.form.cashback": "Cashback",
  "coupon.form.comment.heading": "Comment",
  "coupon.form.maxSingleRedemption.heading": "Max. Redemption By Single User",
  "coupon.form.maxRedemption.heading": "Max. Redemption",
  "coupon.form.minOrderAmount.heading": "Min. Order Amount",
  "coupon.form.validOnUser.heading": "users",
  "coupon.form.allUsers": "All",
  "coupon.form.newUsers": "New",
  "coupon.form.validOnPayment.heading": "Payment Mode",
  "coupon.form.allPayments": "All",
  "coupon.form.onlinePayments": "Online",
  "coupon.form.codPayments": "COD",
  "coupon.form.comment.description": "(for internal reference only)",
  "coupon.form.submit.text": "Submit",
  "coupon.form.cancel.text": "Cancel",
  "coupon.form.discount.heading": "Coupon Gives",
  "coupon.form.limits.heading": "Limits",
  "coupon.form.conditions.heading": "Conditions",
  "coupon.form.days.heading": "Days",
  "coupon.form.dateRange.heading": "Validity",
  "coupon.form.discountType.heading": "Discount Type",
  "coupon.form.startDate.placeholder": "Start Date",
  "coupon.form.endDate.placeholder": "Expiry Date",
  "coupon.form.maxDiscountAmount.heading": "Max. Discount Amount",
  "coupon.form.stores.heading": "Applies To",
  "coupon.form.stores.placeholder": "Select Stores",
  "coupon.dialog.cancelText": "Cancel",
  "coupon.dialog.okText": "Ok",
  "coupon.dialog.title": "Are You sure",
  "coupon.disableDialog.information": "You want to disable this coupon",
  "coupon.enableDialog.information": "You want to enable this coupon",
  "coupon.filters.couponCode.heading": "Coupon",
  "coupon.filters.status.heading": "Status",
  "coupon.filters.status.label.all": "All",
  "coupon.filters.status.label.enabled": "Enabled",
  "coupon.filters.status.label.disabled": "Disabled",
  "coupon.filters.couponCode.placeholder": "Search by Coupon Code",
  "coupon.filters.submitText": "Search",
  "coupon.filters.clearFiltersText": "Clear All",
  "coupon.action.enable": "Enable",
  "coupon.download.form.error":
    "Could not find coupons for given prefix and suffix",
  "coupon.promocode.label": "Promocode",
  "coupon.promocode.placeholder": "Enter Promocode",

  "marketing.blog.add.text": "Write a new post",
  "marketing.blog.helpItems.message": "There are no blogs shown here",
  "marketing.blog.heading": "Blog",
  "marketing.form.add.heading": "Add blog post",
  "marketing.form.edit.heading": "Edit blog post",
  "marketing.blog.form.title.heading": "Post Title",
  "marketing.blog.form.title.placeholder": "Enter your title here",
  "marketing.blog.form.title.requiredMessage": "Your blog must have a title",
  "marketing.blog.form.content.placeholder": "Type here",
  "marketing.blog.form.submitText": "Publish",
  "marketing.blog.form.cancelText": "Cancel",
  "marketing.table.heading.title": "Title",
  "marketing.table.heading.publishTime": "Published at",
  "marketing.table.heading.author": "Written by",
  "marketing.table.heading.interaction": "Interaction",
  "marketing.table.heading.actions": "Actions",
  "marketing.table.heading.status": "Status",
  "marketing.blog.table.status.draft": "Draft",
  "marketing.blog.table.action.edit": "Edit",
  "marketing.blog.table.action.publish": "Publish",
  "marketing.blog.table.action.unpublish": "Unpublish",
  "marketing.blog.dialogs.publish.title": "Are you sure?",
  "marketing.blog.dialogs.unpublish.title": "Are you sure?",
  "marketing.blog.dialogs.publish.message":
    "This will publish the blog on the website",
  "marketing.blog.dialogs.unpublish.message":
    "This will remove the blog on the website and add it to drafts",
  "marketing.blog.dialogs.publish.cancelText": "Cancel",
  "marketing.blog.dialogs.publish.okText": "Publish",
  "marketing.blog.dialogs.unpublish.okText": "Unpublish",
  "marketing.blog.table.action.delete": "Delete",
  "marketing.blog.dialogs.delete.title": "Are you sure?",
  "marketing.blog.dialogs.delete.message":
    "This will publish the blog on the website",
  "marketing.blog.dialogs.delete.cancelText": "Cancel",
  "marketing.blog.dialogs.delete.okText": "Publish",
  "marketing.actions.text": "Actions",
  "cancel.text": "Cancel",
  "marketing.action.edit.text": "Edit",
  "marketing.title.heading.text": "Title",
  "marketing.blog.form.meta.description.title.heading": "Meta Description",

  "review.individual.product.table.header.reviews": "Reviews",
  "marketing.action.REJECTED.status": "Disapprove",
  "marketing.action.APPROVED.status": "Approve",

  "marketing.blog.form.url.placeholder": "URL of the blog",
  "marketing.blog.form.more.description.title.heading": "More Description",
  "marketing.blog.form.more.description.title.placeholder":
    "Write a brief summary of your post",
  "marketing.blog.form.featured.image.title.heading": "Featured Image",
  "marketing.blog.form.assign.tag.placeholder": "Search or create a new tag",
  "marketing.blog.form.assign.tag.title.heading": "Assign Tag",
  "marketing.blog.form.alt.text.title.heading": "Alt Text",
  "marketing.blog.form.alt.text.title.placeholder":
    "Enter the alt text for the above featured image",
  "marketing.blog.form.meta.description.title.placeholder":
    "Write a brief summary of your post",

  "marketing.table.heading.recipe.description": "Recipe Description",
  "marketing.recipe.add.text": "Write a new Recipe",
  "marketing.recipe.helpItems.message": "There are no recipe's to show",
  "marketing.recipe.form.title.placeholder": "Enter your recipe name",
  "marketing.recipe.form.title.heading": "Recipe Name",
  "marketing.recipe.form.edit.heading": "Edit Recipe",
  "marketing.recipe.form.add.heading": "Add Recipe",
  "marketing.recipe.form.title.requiredMessage": "Your recipe must have a name",
  "marketing.recipe.form.description.title.heading": "Recipe description",
  "marketing.recipe.form.description.title.placeholder":
    "Short description of your recipe",
  "marketing.recipe.form.product.title.heading": "Add Products",
  "marketing.recipe.form.product.add.placeholder": "Search & add products",
  "marketing.recipe.form.submitText": "Save",
  "marketing.recipe.form.featured.image.title.heading": "Featured Image",
  "marketing.recipe.form.ingredients.placeholder":
    "List the delicious ingredients that make your recipe special! Use the text editing features to enhance the list",
  "marketing.recipe.form.ingredients.heading": "Ingredients",
  "marketing.recipe.form.directions.heading": "Directions",
  "marketing.recipe.form.metadata.heading": "Metadata",
  "marketing.recipe.form.metadata.placeholder":
    "Extra details /steps of your recipe",
  "marketing.recipe.form.directions.placeholder":
    "Share the step-by-step instructions to create your mouthwatering dish!",
  "marketing.recipe.dialogs.publish.message":
    "This will publish the recipe on the website",
  "marketing.recipe.dialogs.unpublish.message":
    "This will remove the recipe on the website and add it to drafts",
  "marketing.recipe.table.status.draft": "DRAFT",
  "marketing.recipe.table.status.published": "PUBLISHED",
  "marketing.recipe.table.action.publish": "Publish",
  "marketing.recipe.table.action.unpublish": "Unpublish",

  "websitePage.table.heading": "Static Pages",
  "websitePage.add.text": "Add page",
  "websitePage.emptyList.message": "There are no static pages for your website",
  "websitePage.emptyList.submessage": "",
  "websitePage.status.enabled": "Enabled",
  "websitePage.status.disabled": "Disabled",
  "websitePage.table.title": "Title",
  "websitePage.table.url": "URL",
  "websitePage.table.status": "Status",
  "websitePage.table.actions": "Actions",
  "websitePage.table.action.edit": "Edit",
  "websitePage.table.action.disable": "Disable",
  "websitePage.table.action.enable": "Enable",
  "websitePage.dialogs.disable.title": "Are you sure?",
  "websitePage.dialogs.enable.title": "Are you sure?",
  "websitePage.dialogs.disable.message": "This action will disable this page",
  "websitePage.dialogs.enable.message": "This action will enable this page",
  "websitePage.dialogs.disable.cancelText": "Cancel",
  "websitePage.dialogs.disable.okText": "Confirm",
  "websitePage.form.add.heading": "Add static page",
  "websitePage.form.edit.heading": "Edit static page",
  "websitePage.form.title.heading": "Page Title",
  "websitePage.form.title.placeholder": "Please enter the title of your page",
  "websitePage.form.url.heading": "URL",
  "websitePage.form.url.placeholder": "URL of the page",
  "websitePage.form.title.requiredMessage": "Your page must have a title",
  "websitePage.form.content.placeholder":
    "What you type here will be shown as the content of this page on your website",
  "websitePage.form.submitText": "Publish",
  "websitePage.form.cancelText": "Cancel",
  "websitePage.delete": "Delete",
  "websitepage.edit": "Edit",
  "websitepage.form.addLayout": "Add Layout",
  "websitePage.dialog.title": "Are you sure?",
  "websitePage.dialog.information": "This layout will be deleted",
  "websitePage.add": "Add",
  "websitePage.popup.add": "Add layout",
  "websitePage.popup.imagewithtext.heading": "Edit image with text",
  "imagewithtext.title": "Title",
  "imagewithtext.placeholder": "Enter title",
  "imagewithtext.image": "Image",
  "layouts.save": "Save",
  "websitePage.popup.heading.image": "Edit Image",
  "image.title": "Image",

  "campaign.table.heading": "Campaigns",
  "campaign.form.add.heading": "New Campaign",
  "campaign.form.edit.heading": "Edit Campaign",
  "campaign.add.text": "Add Campaign",
  "campaign.helpItems.message": "There are no campaigns",
  "campaign.table.heading.name": "Name",
  "campaign.table.heading.status": "Status",
  "campaign.table.heading.runDate": "Run Date",
  "campaign.table.heading.actions": "Actions",
  "campaign.action.edit": "Edit",
  "campaign.form.name.heading": "Name",
  "campaign.form.name.placeholder": "Name",
  "campaign.form.submit.text": "Submit",
  "campaign.form.cancel.text": "Cancel",
  "campaign.form.emailSubject.heading": "Subject",
  "campaign.form.emailSubject.placeholder": "Subject",
  "campaign.form.emailBody.heading": "Body",
  "campaign.form.emailBody.placeholder": "Body",
  "campaign.form.sms.heading": "SMS",
  "campaign.form.sms.placeholder": "SMS",
  "campaign.form.pushSubject.heading": "NOTIFICATION TITLE",
  "campaign.form.pushSubject.placeholder": "Enter title here",
  "campaign.form.pushText.heading": "NOTIFICATION TEXT",
  "campaign.form.pushText.placeholder": "Enter text here",
  "campaign.form.pushUrl.placeholder": "Enter url here",
  "campaign.form.pushImage.heading": "ADD NOTIFICATION IMAGE",
  "campaign.form.pushImage.placeholder": "Add an image",
  "campaign.form.comment.heading": "Comment",
  "campaign.form.comment.placeholder": "Comment",
  "campaign.form.date.heading": "Date",
  "campaign.form.time.heading": "Time",
  "campaign.form.time.am": "AM",
  "campaign.form.time.pm": "PM",
  "campaign.action.delete": "Delete",
  "campaign.form.preview.heading": "Preview",
  "campaign.form.maxcharacters.description": "Max. 150 characters",
  "campaign.form.vairableList.heading": "List of available variables",
  "campaign.form.invalidDateTime":
    "Datetime should be greater than current datetime",

  "offer.all": "All",
  "offer.product": "Product",
  "offer.category": "Category",
  "offer.brand": "Brand",
  "offer.product.placeholder": "Select products",
  "offer.category.placeholder": "Select category",
  "offer.categories.placeholder": "Select Categories",
  "offer.brand.placeholder": "Select brand",
  "offer.brands.placeholder": "Select brands",
  "offer.entity": "Entity Type",
  "offer.edit": "Edit",
  "offer.type": "Type",
  "offer.entity.id": "Entity ID",
  "offer.entityid.placeholder": "Enter Entity ID",
  "offer.description": "Description",
  "offer.validFrom": "Valid From",
  "offer.validTill": "Valid Till",
  "offer.actions": "Actions",
  "offers.title": "Offers",
  "offer.add.text": "Add Offer",
  "offer.type.placeholder": "Select offer type",
  "offer.submit": "Submit",
  "offer.clear": "Clear",
  "offer.applicable.to.stores": "Applicable to store",
  "offer.applicable.to.store.placeholder": "Select store",
  "offer.enabled": "Enabled",
  "offer.disabled": "Disabled",
  "offer.disable": "Disable",
  "offer.enable": "Enable",
  "offer.status": "Status",
  "offer.add.heading": "Add offer",
  "offer.edit.heading": "Edit offer",
  "offer.cancel": "Cancel",
  "offer.alert.message": "Alert Message",
  "offer.alert.message.placeholder": "Enter alert message",
  "offer.max.redemption": "order level redemption",
  "offer.max.redemption.placeholder": "Enter order level redemption",
  "offer.customer.redemption": "Customer Redemption",
  "offer.customer.redemption.placeholder": "Enter customer redemption",
  "offer.total.redemption": "Total Redemption",
  "offer.total.redemption.placeholder": "Enter total redemption",
  "offer.delivery-date.start": "Start Date",
  "offer.delivery-date.end": "End Date",
  "offer.ordertype.label": "Order Type",
  "offer.ordertype.placeholder": "Select order type",
  "offer.ordertype.options.bulk": "Bulk",
  "offer.ordertype.options.delivery": "Delivery",
  "offer.ordertype.options.pickup": "Pickup",
  "offer.ordertype.options.offline": "Offline",
  "offer.image": "Image",
  "offer.description.placeholder": "Enter description",
  "offer.fixed": "Fixed",
  "offer.percentoff": "Percent off",
  "offer.absoluteoff": "Absolute off",
  "offer.all.stores": "All stores",
  "offer.selected.stores": "Selected stores",
  "offer.all.except.selected.stores": "All except selected stores",
  "offer.stores": "Stores",
  "offer.stores.placeholder": "Select stores",
  "offer.applies.to": "Applies to",
  "offer.applies.to.placeholder": "Select Stores",
  "offer.quantity": "Quantity",
  "offer.quantity.placeholder": "Enter quantity",
  "offer.discounttype": "Discount type",
  "offer.discounttype.placeholder": "Select discount type",
  "offer.discountvalue": "Discount value",
  "offer.maxdiscountvalue": "Max Discount",
  "offer.discountvalue.placeholder": "00",
  "offer.min.quantity": "Min quantity",
  "offer.min.quantity.placeholder": "Enter min quantity",
  "offer.time": "Valid from time",
  "offer.time.placeholder": "Select time",
  "offer.validto.time": "Valid till time",
  "offer.name": "Name",
  "offer.combo.discount": "Combo",
  "offer.individual.discount": "Individual",
  "offer.get": "Get",
  "offer.main.discount.type": "Type of discount",
  "offer.cartprice": "Cart Price",
  "offer.cartprice.placeholder": "Enter cart price",
  "offer.delete": "Delete",
  "offer.emptyMessage": "There are no offers",
  "offer.includeText": "to include",
  "offer.excludeText": "to exclude",
  "offer.min.amount.label": "Min Amount",
  "offer.min.amount.placeholder": "Enter min amount",
  "offer.select.placeholder": "Select",
  "offer.includes": "includes",
  "offer.min.amount": "Minimum Amount",
  "offer.payment.type": "Payment type",
  "offer.payment.type.placeholder": "Enter payment type",
  "offer.buy": "Buy",
  "offer.filter.clientId": "Client Id",
  "offer.filter.clientId.placeholder": "Search by Client Id",
  "offer.heading.deliveyDate": "Delivery Date",
  "offer.action.disable": "Disable",
  "offer.action.enable": "Enable",
  "offer.dialog.notification.label": "Notification",
  "offer.dialog.notification.message":
    "Promocodes has been sent to your email address",
  "offer.dialog.notification.close": "Close",
  "offer.field.hasUniquePromocode.label": "Has Unique Promocode",
  "offer.field.stackable.label": "Stackable",
  "offer.field.inInvisible.label": "Hide Offer",
  "offer.filter.searchbytag.placeholder": "Search by Tag",
  "offer.filter.searchbycategory.placeholder": "Search by Category",
  "offer.filter.searchbybrand.placeholder": "Search by Brand",
  "offer.filter.category.label": "Category",
  "offer.filter.brand.label": "Brand",
  "offer.filter.tag.label": "Tag",
  "offer.filter.product.label": "Product",
  "offer.dialog.clone.label": "Warning",
  "offer.dialog.clone.message":
    "This offer will be disabled and a new offer will be created with the remaining redemption",
  "offer.dialog.clone.proceed": "Continue",
  "offer.dialog.clone.close": "Cancel",
  "offer.dialog.disable.errorHead": "Error",
  "offer.dialog.disable.errorText":
    "Error occurred while disabling the offer. Please try agin.",
  "offer.dialog.disable.close": "Okay",
  "offer.clone.disableNClone": "Disable and create new",
  "offer.clone.copy": "Create similar",
  "offer.redeemedCount.placeholder":
    "Redeemed count of original offer: {count}",

  "banners.header": "Manage Banners",
  "banners.select.page.type": "Page Type",
  "banners.select.page.type.placeholder": "Select page type",
  "banners.select.search.term": "search term",
  "banners.select.search.term.placeholder": "Enter search term",
  "banners.select.category.heading": "category",
  "banners.select.category.placeholder": "Enter category name",
  "banners.select.brand.heading": "brand",
  "banners.select.brand.placeholder": "Enter brand name",
  "banners.select.tag.heading": "tag",
  "banners.select.tag.placeholder": "Enter tag name",
  "banners.radio.label.banners": "Banners",
  "banners.radio.option.active": "Active banners",
  "banners.radio.option.expired": "Expired banners",
  "banners.view.header": "Manage Banners",
  "banners.view.drag.text": "Drag to change the order",
  "banners.view.add.button": "Add Banner",
  "banners.editForm.header": "Edit Banner",
  "banners.addForm.header": "Add Banner",
  "banners.editForm.button.save": "Save",
  "banners.editForm.button.cancel": "Cancel",
  "banners.editForm.button.delete": "Delete",
  "banners.editForm.image.label": "Image",
  "banners.editForm.link.label": "Link URL",
  "banners.editForm.radio.label": "Scope",
  "banners.editForm.radio.option.global": "Global",
  "banners.editForm.radio.option.storeSpecific": "Store specific",
  "banners.editForm.validity.from": "Validity from",
  "banners.editForm.validity.to": "Validity to",
  "banners.editForm.link.placeholder": "URL to navigate on clicking",
  "banners.popup.banner.selection.heading": "Choose Banner",
  "banners.action.edit": "Edit",
  "banners.action.delete": "Delete",
  "banners.page.type.placeholder": "Select the page type",
  "banners.startTime.placeholder": "Start date",
  "banners.endTime.placeholder": "End date",
  "banners.header.image": "Image",
  "banners.header.actions": "Actions",
  "banners.message.invalidDate": "End date must be after the start date",
  "banners.empty.banner": "Currently there are no home page banners available",
  "banners.stores": "Store",
  "banners.stores.placeholder": "Select the store",
  "banners.form.category.heading": "category",
  "banners.form.brand.heading": "brand",
  "banners.form.tag.heading": "tag",
  "banners.form.category.placeholder": "Enter category name",
  "banners.form.brand.placeholder": "Enter brand name",
  "banners.form.tag.placeholder": "Enter tag name",
  "banners.form.sequence.placeholder": "Enter the sequence",
  "banners.form.sequence.label": "Sequence",
  "banners.table.view.on.web.url": "View on web",
  "banners.table.scope": "Scope:",
  "banners.table.validity": "Validity:",
  "banners.table.na": " N/A",
  "banners.form.search.term.label": "Search Term",
  "banners.form.search.term.placeholder": "Enter the search term",
  "banners.form.startTime": "Start Time",
  "banners.form.endTime": "End Time",
  "banners.form.startTime.placeholder": "Enter Start Time",
  "banners.form.endTime.placeholder": "Enter End Time",
  "banners.success.dialog.message": "Saved",
  "banners.default.page": "Home",
  "banners.pageType.home": "Home",
  "banners.pageType.category": "Category",
  "banners.pageType.brand": "Brand",
  "banners.pageType.productTag": "Product_tag",
  "banners.pageType.search": "Search",
  "banners.scope.global": "GLOBAL",
  "banners.scope.store": "STORE",
  "banners.languages": "Language",
  "banners.languages.placeholder": "Select the language",

  "coupon.action.viewAnalytics": "Analytics",
  "coupon.analytics.heading": "Coupon Analytics",
  "coupon.analytics.analyticsFor": "Analytics For",
  "coupon.analytics.uniqueUsers.heading": "Unique Users",
  "coupon.analytics.activeFor.heading": "Active For(Days)",
  "coupon.analytics.totalDiscount.heading": "Total Coupon Discount",
  "coupon.analytics.totalPurchases.heading": "Total Sales",
  "coupon.analytics.today.heading": "Today",
  "coupon.analytics.thisWeek.heading": "This Week",
  "coupon.analytics.paymentDistribution.heading": "Payment Distribution",
  "coupon.analytics.deviceDistribution.heading": "Device Distribution",
  "coupon.analytics.dailyDistribution.heading": "Daily Distribution",
  "coupon.analytics.selectDates.heading": "Select Dates",
  "coupon.analytics.status.heading": "Status",
  "campaign.analytics.heading": "Campaign Analytics",
  "campaign.analytics.subHeading": "User Engagement",
  "campaign.header.campaign": "Campaign",
  "campaign.analytics.tab.email": "Email",
  "campaign.analytics.tab.sms": "Sms",
  "campaign.action.viewAnalytics": "View Analytics",
  "campaign.analytics.zopLinkActivity.heading": "ZopLink Activity",
  "campaign.analytics.zopNowMissyou.message": "[ZopNow] We Miss You !",
  "campaign.analytics.table.heading": "Last 10 Opened",
  "campaign.analytics.emailTab.table.header.name": "Name",
  "campaign.analytics.emailTab.table.header.email": "Email",
  "campaign.analytics.emailTab.table.header.dateTime": "Date & Time",
  "campaign.analytics.emailTab.table.header.promotional": "Promotional",
  "campaign.analytics.smsTab.table.header.url": "Url",
  "campaign.analytics.smsTab.table.header.lastVisit": "Last visit",
  "campaign.analytics.smsTab.table.header.clicks": "Clicks (unique)",
  "coupon.analytics.emailTab.basedOnEmailsDelivered":
    "Based on emails delivered",
  "coupon.analytics.emailTab.basedOnEmailsOpened": "Based on emails opened",
  "coupon.analytics.emailTab.opened": "Opened",
  "coupon.analytics.emailTab.clickedThrough": "Clicked through",
  "coupon.analytics.smsTab.clicked": "Clicked",
  "coupon.analytics.smsTab.basedOnDelivered": "Based on delivered",
  "coupon.analytics.pieChart.DESKTOP_NA_WEB": "Desktop",
  "coupon.analytics.pieChart.MOBILE_ANDROID_WEB": "Android",
  "coupon.analytics.pieChart.MOBILE_IOS_WEB": "IOS",
};

export default data;
