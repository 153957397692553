import React from "react";
import {
  BaseForm,
  Input,
  Radio,
  VALIDATION_TYPES,
} from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";

export default class CouponFiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    return (
      <Form>
        <div className="form-fields">
          {
            <Input
              label={getMessage("coupon.filters.couponCode.heading")}
              placeholder={getMessage("coupon.filters.couponCode.placeholder")}
              name="couponCode"
              className="coupon-code-filter"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["couponCode"],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            />
          }
          <Radio
            label={getMessage("coupon.filters.status.heading")}
            name="status"
            options={[
              {
                text: getMessage("coupon.filters.status.label.all"),
                value: "",
              },
              {
                text: getMessage("coupon.filters.status.label.enabled"),
                value: "ENABLED",
              },
              {
                text: getMessage("coupon.filters.status.label.disabled"),
                value: "DISABLED",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              loseEmphasisOnFill: true,
              defaultValue: "",
            })}
          />
        </div>
        <SubmitButton>{getMessage("coupon.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("coupon.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
