import React from "react";
import {
  BaseForm,
  Input,
  Radio,
  Searchable,
  VALIDATION_TYPES,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";

/**
 * *`VehiclesFiltersForm`*
 * : adds filtering to **`Vehicles`** component
 */
export default class VehiclesFiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage("vehicles.filters.byNumber.title")}
            placeholder={getMessage("vehicles.filters.byNumber.placeholder")}
            name="number"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["number"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <Searchable
            type="text"
            name="vendor"
            label={getMessage("vehicles.filters.byVendor.title")}
            placeholder={getMessage("vehicles.filters.byVendor.placeholder")}
            valueKey="id"
            nameKey="name"
            searchKey="name"
            searchUrl="/logistics-service/vehicle-vendor"
            transformResponse={(response) => response.data.vehiclevendor}
            {...this.generateStateMappers({
              stateKeys: ["vendor"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <Radio
            label={getMessage("vehicles.filters.byStatus.title")}
            name="status"
            options={[
              {
                text: getMessage("vehicles.filters.byStatus.label.all"),
                value: "",
              },
              {
                text: getMessage("vehicles.filters.byStatus.label.enabled"),
                value: "ENABLED",
              },
              {
                text: getMessage("vehicles.filters.byStatus.label.disabled"),
                value: "DISABLED",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              defaultValue: "",
            })}
          />
        </div>
        <SubmitButton>{getMessage("vehicles.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("vehicles.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
