import React from "react";
import { BaseForm, Radio } from "../Form";
import Loader from "../Loader";
import API from "../../lib/api";
import { getMessage } from "../../lib/translator";
import AuthenticatedPage from "../../containers/AuthenticatedPage";
import { Dialog } from "../Popup";
import "./style.css";
import { Link } from "react-router-dom";
import { getSession } from "../../lib/auth";
import { OtherCourierForm } from "./OtherCourierForm";
import DropdownIcon from "./dropdown.svg";
import { VendorsTable } from "./Table";
import { DunzoDetails } from "./DunzoDetails";

export default class CourierDispatch extends BaseForm {
  constructor(props) {
    super(props);
    this.state.showSuccessDialog = false;
    this.state.showErrorDialog = false;
    this.state.formError = "";
    this.state.logisticsServiceProviders = [];
    this.state.courierVendors = [];
    this.state.showShipRocket = false;
    this.state.showFedEx = false;
    this.otherLogisticsProvider = [
      {
        id: "OTHER",
        name: "Other Courier",
      },
      {
        id: "SELF",
        name: "Self",
      },
    ];
  }

  componentDidMount() {
    this.setState({ providersLoading: true });
    this.allProvidersApi = new API({
      url: "/logistics-service/logistics-provider",
    });
    this.websiteConfigApi = new API({
      url: "/config-service/config/logistics",
    });
    this.courierEligibilityApi = new API({
      url: "/logistics-service/courier-eligibility",
    });
    const id = this.props.match.params.id;
    const params = { orderId: id };
    Promise.all([
      this.allProvidersApi.get(),
      this.websiteConfigApi.get(),
      this.courierEligibilityApi.get(params),
    ])
      .then(
        ([
          allProvidersResponse,
          websiteConfigResponse,
          courierEligibilityResponse,
        ]) => {
          const vendors = courierEligibilityResponse.data?.courierVendor;

          const availableLogisticsProvider =
            websiteConfigResponse.data?.logistics?.logisticsServiceProviders;
          const logisticsProvider =
            allProvidersResponse.data?.logisticsProvider || [];
          let useableLogisticsProvider = [];
          if (
            logisticsProvider?.length > 0 &&
            availableLogisticsProvider?.length > 0
          ) {
            useableLogisticsProvider = logisticsProvider.filter((obj) =>
              availableLogisticsProvider.includes(obj.id)
            );
          }

          this.setState({
            providersList: allProvidersResponse.data,
            logisticsServiceProviders: useableLogisticsProvider,
            courierVendors: vendors,
            formError: "",
          });
        }
      )
      .catch((error) => {
        this.setState({
          formError: error.message,
          showErrorDialog: true,
        });
        console.error(error);
      })
      .finally(() => {
        this.setState({ providersLoading: false });
      });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.values?.vendor !== "OTHER" &&
      this.state.validations?.courierName
    ) {
      const validations = Object.assign({}, this.state.validations);
      delete validations.courierName;
      delete validations.courierCharges;
      delete validations.courierTrackingId;

      this.setState({
        validations: validations,
      });
    }
  }

  onSubmit = (data) => {
    if (!data.vendor) {
      this.setState({ dispatchError: true });
      return;
    }
    if (data.vendor === "OTHER" && !data.courierName) {
      return;
    }
    this.setState({
      submitting: true,
    });
    const id = this.props.match.params.id;
    let OtherCourierFormData;
    const isVendorSelf = data.vendor === "SELF";
    if (data.vendor === "OTHER" || data.vendor === "SELF") {
      OtherCourierFormData = {
        metaData: {
          courierName: isVendorSelf ? "SELF" : data.courierName,
          ...(!isVendorSelf && {
            courierCharge: data.courierCharges,
            trackURL: data.courierTrackingId,
          }),
        },
      };
    }

    let shipRocketVendorData;
    const shipRocketVendor = this.state.courierVendors?.find(
      (obj) => obj.name === data.vendor && obj.provider === "SHIPROCKET"
    );
    if (shipRocketVendor) {
      shipRocketVendorData = {
        vendor: "SHIPROCKET",
        courierVendorId: shipRocketVendor.courierVendorId,
      };
    }
    let fedExVendorData;
    const fedExVendor = this.state.courierVendors?.find(
      (obj) => obj.name === data.vendor && obj.provider === "FEDEX"
    );
    if (fedExVendor) {
      fedExVendorData = {
        vendor: "FEDEX",
        courierVendorId: fedExVendor.courierVendorId,
      };
    }
    const organizationId = getSession()?.organization?.id?.toString();
    const courierApi = new API({ url: "/logistics-service/courier" });
    const vendorName = isVendorSelf ? "OTHER" : data.vendor;
    const params = {
      organizationId,
      orderId: id.toString(),
      vendor: vendorName,
      ...OtherCourierFormData,
      ...shipRocketVendorData,
      ...fedExVendorData,
    };
    courierApi
      .post(params)
      .then((response) => {
        this.setState({
          formError: "",
          showSuccessDialog: true,
        });
      })
      .catch((error) => {
        this.setState({
          formError: error.message,
          showErrorDialog: true,
        });
        console.error(error);
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  };

  closeDialogs = () => {
    this.setState(
      {
        showSuccessDialog: false,
        showErrorDialog: false,
      },
      () => {
        if (!this.state.formError)
          this.props.history.push("/operations/orders");
      }
    );
  };
  getOptionList = () => {
    // remove SHIPROCKET from option list
    let useableLogisticsProvider = this.state.logisticsServiceProviders.filter(
      (obj) => obj.id !== "SHIPROCKET" && obj.id !== "FEDEX"
    );

    // check availabilit of DUNZO from courierEligibilityApi
    let vendorDunzo = this.state.courierVendors?.filter(
      (obj) => obj.provider === "DUNZO"
    );
    if (!vendorDunzo || vendorDunzo?.length === 0) {
      useableLogisticsProvider = [];
    }

    useableLogisticsProvider = [
      ...useableLogisticsProvider,
      ...this.otherLogisticsProvider,
    ];
    return useableLogisticsProvider.map((data) => {
      const { name, id, image } = data;

      return {
        text: (
          <div className="HeaderContainer">
            <div
              className={id === "DUNZO" ? "header dunzo" : "header"}
              key={id}
            >
              <div>
                {image && <img src={image} className="CourierLogo" alt="" />}
                <span className="courierName">{name}</span>
              </div>
            </div>
            {this.state?.values?.vendor &&
              this.state?.values?.vendor === "OTHER" &&
              id === "OTHER" && <OtherCourierForm parent={this} />}

            {this.state?.values?.vendor &&
              this.state?.values?.vendor === "DUNZO" &&
              id === "DUNZO" && <DunzoDetails data={vendorDunzo[0]} />}
          </div>
        ),
        value: id,
      };
    });
  };
  onToggleChange = (value) => {
    if (value === "shiprocket")
      this.setState({ showShipRocket: !this.state.showShipRocket });
    if (value === "fedex") this.setState({ showFedEx: !this.state.showFedEx });
  };

  render() {
    const { Form } = this.components;
    const { SubmitButton, CancelButton } = this.buttons;
    let optionList = this.getOptionList();
    const shipRocketData = this.state.logisticsServiceProviders.find(
      (obj) => obj.id === "SHIPROCKET"
    );
    const fedExData = this.state.logisticsServiceProviders.find(
      (obj) => obj.id === "FEDEX"
    );
    const { courierVendors } = this.state;
    const shipRocketVendors = courierVendors?.filter(
      (obj) => obj.provider === "SHIPROCKET"
    );
    const fedExVendors = courierVendors?.filter(
      (obj) => obj.provider === "FEDEX"
    );
    return (
      <AuthenticatedPage className="CourierSelect" menu={this.props.menu}>
        <div>
          <h1 className="title">
            {getMessage("order.courier.select.heading")}
          </h1>
          {this.state.providersLoading ? (
            <Loader />
          ) : (
            <Form>
              <Dialog
                show={this.state.showSuccessDialog}
                className="success"
                information={getMessage("order.courier.dispatch.success")}
                close={this.closeDialogs}
                closeText={getMessage("okay.text")}
              />
              <Dialog
                show={this.state.showErrorDialog}
                information={getMessage(this.state.formError)}
                close={this.closeDialogs}
                closeText={getMessage("okay.text")}
              />

              <div className="providerContainer">
                {shipRocketData && Boolean(shipRocketVendors?.length) && (
                  <>
                    <div className="HeadContainer">
                      <div
                        className="header"
                        onClick={() => this.onToggleChange("shiprocket")}
                      >
                        <img
                          className={`dropDown ${
                            this.state.showShipRocket
                              ? "selectedDropdown"
                              : "notselectedDropdown"
                          }`}
                          src={DropdownIcon}
                          alt=""
                        />
                        <img
                          src={shipRocketData.image}
                          className="CourierLogo"
                          alt=""
                        />
                        <span className="courierName">
                          {shipRocketData.name}
                        </span>
                      </div>
                      {this.state.showShipRocket && (
                        <>
                          {shipRocketVendors && shipRocketVendors.length ? (
                            <VendorsTable
                              vendorsList={shipRocketVendors}
                              parent={this}
                            />
                          ) : (
                            <>
                              <div className="no-vendor-found">
                                {getMessage(
                                  "order.courier.dispatch.no.vendor.found"
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
                {fedExData && Boolean(fedExVendors?.length) && (
                  <>
                    <div className="HeadContainer">
                      <div
                        className="header"
                        onClick={() => this.onToggleChange("fedex")}
                      >
                        <img
                          className={`dropDown ${
                            this.state.showFedEx
                              ? "selectedDropdown"
                              : "notselectedDropdown"
                          }`}
                          src={DropdownIcon}
                          alt=""
                        />
                        <img
                          src={fedExData.image}
                          className="CourierLogo"
                          alt=""
                        />
                        <span className="courierName">{fedExData.name}</span>
                      </div>
                      {this.state.showFedEx && (
                        <>
                          {fedExVendors && fedExVendors.length ? (
                            <VendorsTable
                              vendorsList={fedExVendors}
                              parent={this}
                            />
                          ) : (
                            <>
                              <div className="no-vendor-found">
                                {getMessage(
                                  "order.courier.dispatch.no.vendor.found"
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
                <Radio
                  className="vehicle-type"
                  name="vendor"
                  options={optionList}
                  {...this.generateStateMappers({
                    stateKeys: ["vendor"],
                  })}
                />
                {!this.state.values?.vendor && this.state.dispatchError && (
                  <>
                    <div className="input-error-message">
                      {getMessage("input.value.missing")}
                    </div>
                  </>
                )}
                <div className="form-action">
                  <SubmitButton disabled={this.state.submitting}>
                    {getMessage("order.action.dispatch")}
                  </SubmitButton>
                  <Link to="/operations/orders">
                    <CancelButton>
                      {getMessage("order.details.packed.cancelText")}
                    </CancelButton>
                  </Link>
                </div>
              </div>
            </Form>
          )}
        </div>
      </AuthenticatedPage>
    );
  }
}
