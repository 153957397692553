import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
} from "../../../../../components/Form";
import { Dialog } from "../../../../../components/Popup";
import API from "../../../../../lib/api";
import { getMessage } from "../../../../../lib/translator";
import "./style.css";

export default class TawkChatForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.showSuccessDialog = false;
    this.state.showErrorDialog = false;
    this.state.formError = "";
    this.closeDialogs = this.closeDialogs.bind(this);
  }

  closeDialogs() {
    this.setState({
      showErrorDialog: false,
      showSuccessDialog: false,
    });
  }

  handleSubmit(data) {
    const id = this.props.data.id;
    const api = new API({ url: `/account-service/extension/${id}` });

    let params = { status: this.props.data.status };
    params["config"] = {};
    for (let field in data) {
      params["config"][field] = data[field];
    }

    return api
      .put(params)
      .then(
        (response) => {
          this.setState({ formError: "", showSuccessDialog: true });
        },
        (error) => {
          this.setState({
            formError: error.message,
            showErrorDialog: true,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleSubmit(data).then(() => {
      this.setState({ submitting: false });
    });
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton, CancelButton } = this.buttons;

    return (
      <div className="tawk-form">
        <h1>{getMessage("extensions.tawk.live.chat")}</h1>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("extensions.tawk.save.success")}
          close={this.closeDialogs}
          closeText={getMessage("extensions.tawk.save.closetext")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage("extensions.tawk.save.error")}
          close={this.closeDialogs}
          closeText={getMessage("extensions.tawk.error.closetext")}
        />
        <Form>
          <Input
            label={getMessage("extensions.tawk.site.id")}
            placeholder={getMessage("extensions.tawk.site.id.placeholder")}
            name="siteId"
            required
            {...this.generateStateMappers({
              stateKeys: ["globalConfig", "tawkToId"],
              validationTypes: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          >
            {getMessage("extensions.tawk.site.id.description")}
            <code> Admin &rarr; Property Settings</code>
          </Input>
          <Input
            label={getMessage("extensions.tawk.widget.id")}
            placeholder={getMessage("extensions.tawk.widget.id.placeholder")}
            name="widgetId"
            required
            {...this.generateStateMappers({
              stateKeys: ["globalConfig", "widgetId"],
              validationTypes: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          >
            {getMessage("extensions.tawk.widget.id.description")}
            <code> Admin &rarr; Chat Widget &rarr; Direct Chat Link</code>
          </Input>
          <SubmitButton>{getMessage("extensions.tawk.save")}</SubmitButton>
          <CancelButton>{getMessage("extensions.tawk.cancel")}</CancelButton>
        </Form>
      </div>
    );
  }
}
