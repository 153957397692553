import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListingPage, { TABLE_ACTIONS } from "../../../../containers/ListingPage";
import InviteForm from "./InviteForm";
import { Row, Cell } from "../../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../../components/DropDown";
import { Popup } from "../../../../components/Popup";
import API from "../../../../lib/api";
import { getSession } from "../../../../lib/auth";
import { getMessage } from "../../../../lib/translator";

import "./style.css";

function handleException(error) {
  if (error.code === 401) {
    throw error;
  }
}

class InviteUserButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShown: false,
      inviteError: null,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const api = new API({ url: "/account-service/country" });
    api
      .get()
      .then((response) => {
        this.countryIsoCode = response.data.country.isoCode;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  showModal() {
    this.setState({ modalShown: true, inviteError: null });
  }
  hideModal() {
    this.setState({ modalShown: false, inviteError: null });
  }
  handleSubmit(formData) {
    let api = new API({ url: "/account-service/invite" });
    api
      .post(formData)
      .then(this.hideModal, (error) => {
        handleException(error);
        this.setState({
          inviteError: getMessage(error.message),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    return (
      <div>
        <Popup
          show={this.state.modalShown}
          close={this.hideModal}
          onClick={() => {
            this.setState({ inviteError: null });
          }}
        >
          <div className="error-message">{this.state.inviteError}</div>
          <InviteForm
            onSubmit={this.handleSubmit}
            onCancel={this.hideModal}
            isoCode={this.countryIsoCode}
          />
        </Popup>
        <button onClick={this.showModal} className="primary button">
          + {getMessage("settings.users.invite.text")}
        </button>
      </div>
    );
  }
}

const tableProperties = {
  headers: [
    getMessage("settings.users.header.name"),
    getMessage("settings.users.header.phone"),
    getMessage("settings.users.header.email"),
    getMessage("settings.users.header.actions"),
  ],
  row: ({ id, name, verified, isOwner, phones, emails, onAction }) => (
    <Row>
      <Cell>
        {isOwner ? (
          name
        ) : (
          <Link
            to={`/settings/users/edit-permissions/${id}`}
            className="user-name"
          >
            {name}
          </Link>
        )}
      </Cell>
      <Cell>{phones && phones[0] && phones[0].phone}</Cell>
      <Cell>{emails && emails[0] && emails[0].email}</Cell>
      <Cell>
        {isOwner || Number(getSession().user.id) === Number(id) ? null : (
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.DELETE, { id });
              }}
            >
              {getMessage("settings.users.action.revoke")}
            </DropDownItem>
            <DropDownItem>
              <Link to={`/settings/users/edit-permissions/${id}`}>
                {getMessage("settings.users.action.editPermissions")}
              </Link>
            </DropDownItem>
          </DropDown>
        )}
      </Cell>
    </Row>
  ),
};

export default class Users extends Component {
  render() {
    const { props } = this;
    return (
      <ListingPage
        menu={props.menu}
        className="users-page"
        title={getMessage("settings.users.heading")}
        api={{
          url: "/account-service/user",
          transform: (response) => response.data.user,
        }}
        headerActions={InviteUserButton}
        tableProperties={tableProperties}
      />
    );
  }
}
