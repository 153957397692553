import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  Textarea,
  SingleDatePicker,
  Select,
  ImageUpload,
  RichTextEditorQuill,
} from "../../../../components/Form";
import Loader from "../../../../components/Loader";
import SegmentsForm from "../../../../components/SegmentsForm";
import { getMessage } from "../../../../lib/translator";
import Tabs from "../../../../components/Tabs";
import API from "../../../../lib/api";
import { PreviewElement } from "./utils";
import debounce from "../../../../lib/debounce";
import moment from "moment";
import { getTimes } from "../../../../lib/commonlyused";

import "./style.css";

const checkIfValidDateTime = (date, time) => {
  let dateTime = date + " " + moment(time, "hh:mm").format("HH:mm:00");
  return moment(dateTime) >= moment();
};

export default class CouponForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.values.time = (props.value && props.value.time) || "";
    this.state.variables = [];
    this.state.mediums = [];
    this.state.showLoader = false;
    this.state.activeMediumId = null;
    this.state.lastFocussedElementId = null;
    this.state.lastCaretPosition = null;
    [
      "updateMediumId",
      "updateLastFocussedElement",
      "variableClicked",
      "updateLastCaretPosition",
    ].forEach((fn) => {
      this[fn] = this[fn].bind(this);
    });
    this.debouncedCaretPositionUpdate = debounce(
      this.debouncedCaretPositionUpdate.bind(this),
      100
    );
  }

  variableClicked(e) {
    const variableName = e.target.getAttribute("data-variable");
    const { lastFocussedElementId, lastCaretPosition } = this.state;
    if (
      lastFocussedElementId &&
      (lastFocussedElementId === "emailBody" ||
        lastFocussedElementId === "emailSubject" ||
        lastFocussedElementId === "smsContent") &&
      document.getElementById(lastFocussedElementId)
    ) {
      let values = { ...this.state.values };
      let prevValue = values[lastFocussedElementId];
      if (!prevValue) {
        prevValue = `{{${variableName}}}`;
      } else {
        if (lastCaretPosition == null) {
          prevValue += `{{${variableName}}}`;
        } else {
          prevValue =
            prevValue.slice(0, lastCaretPosition) +
            `{{${variableName}}}` +
            prevValue.slice(lastCaretPosition);
        }
      }
      values[lastFocussedElementId] = prevValue;
      this.setState({
        values,
      });
    }
  }

  debouncedCaretPositionUpdate(selectionStart) {
    this.setState({
      lastCaretPosition: selectionStart,
    });
  }

  updateLastCaretPosition(e) {
    this.debouncedCaretPositionUpdate(e.target.selectionStart);
  }

  updateLastFocussedElement({ e, id }) {
    this.setState({
      lastFocussedElementId: e ? e.target.id : id,
    });
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    const mediumApi = new API({ url: "/promo-service/medium" });
    const variableApi = new API({ url: "/promo-service/campaignVariable" });
    Promise.all([mediumApi.get(), variableApi.get()])
      .then(
        ([mediumApiResponse, variableApiResponse]) => {
          const mediumsData = mediumApiResponse.data.medium;
          const activeMediumId =
            mediumApiResponse.data.medium.reverse()[0]["id"];
          const variables = variableApiResponse.data;
          this.setState({
            mediums: mediumsData,
            activeMediumId,
            showLoader: false,
            variables,
          });
        },
        () => {
          this.setState({ showLoader: false });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  updateMediumId(mediumId) {
    this.setState({
      activeMediumId: mediumId,
    });
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    const { variables, values, activeMediumId, mediums } = this.state;
    const {
      emailSubject,
      emailBody,
      smsContent,
      date,
      time,
      pushImage,
      pushBody,
      pushSubject,
      pushUrl,
      status,
    } = values;
    let isDateTimeValid = true;
    if (date) {
      isDateTimeValid = checkIfValidDateTime(date, time);
    }
    const isDisabled = status === "COMPLETED";
    const EmailComponent = (
      <div className="email-container">
        <Input
          className="subject"
          label={getMessage("campaign.form.emailSubject.heading")}
          placeholder={getMessage("campaign.form.emailSubject.placeholder")}
          name="emailSubject"
          type="text"
          onFocus={(e) => this.updateLastFocussedElement({ e })}
          onMouseUp={this.updateLastCaretPosition}
          onKeyPress={this.updateLastCaretPosition}
          {...this.generateStateMappers({
            stateKeys: ["emailSubject"],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
          readOnly={isDisabled}
        />
        <RichTextEditorQuill
          className="body"
          name={"emailBody"}
          placeholder={getMessage("campaign.form.emailBody.placeholder")}
          readOnly={isDisabled}
          label={getMessage("campaign.form.emailBody.heading")}
          onFocus={this.updateLastFocussedElement}
          {...this.generateStateMappers({
            stateKeys: ["emailBody"],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    );

    const SMSComponent = (
      <div className="sms-container">
        <Textarea
          className="sms"
          label={getMessage("campaign.form.sms.heading")}
          placeholder={getMessage("campaign.form.sms.placeholder")}
          name="smsContent"
          type="text"
          onFocus={(e) => this.updateLastFocussedElement({ e })}
          onMouseUp={this.updateLastCaretPosition}
          onKeyPress={this.updateLastCaretPosition}
          {...this.generateStateMappers({
            stateKeys: ["smsContent"],
            loseEmphasisOnFill: true,
          })}
          readOnly={isDisabled}
        />
        <div className="text-right text-muted max-characters-block">
          <small>{getMessage("campaign.form.maxcharacters.description")}</small>
        </div>
      </div>
    );

    const PushNotificationComponent = (
      <div className="email-container">
        <Input
          className="subject"
          label={getMessage("campaign.form.pushSubject.heading")}
          placeholder={getMessage("campaign.form.pushSubject.placeholder")}
          name="pushSubject"
          type="text"
          onFocus={(e) => this.updateLastFocussedElement({ e })}
          onMouseUp={this.updateLastCaretPosition}
          onKeyPress={this.updateLastCaretPosition}
          readOnly={isDisabled}
          {...this.generateStateMappers({
            stateKeys: ["pushSubject"],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        <Input
          className="subject"
          label={getMessage("campaign.form.pushText.heading")}
          placeholder={getMessage("campaign.form.pushText.placeholder")}
          name="pushBody"
          type="text"
          onFocus={(e) => this.updateLastFocussedElement({ e })}
          onMouseUp={this.updateLastCaretPosition}
          onKeyPress={this.updateLastCaretPosition}
          readOnly={isDisabled}
          {...this.generateStateMappers({
            stateKeys: ["pushBody"],
            loseEmphasisOnFill: true,
          })}
        />
        <Input
          className="subject"
          label={getMessage("campaign.analytics.smsTab.table.header.url")}
          placeholder={getMessage("campaign.form.pushUrl.placeholder")}
          name="pushUrl"
          type="url"
          onFocus={(e) => this.updateLastFocussedElement({ e })}
          onMouseUp={this.updateLastCaretPosition}
          onKeyPress={this.updateLastCaretPosition}
          readOnly={isDisabled}
          {...this.generateStateMappers({
            stateKeys: ["pushUrl"],
            loseEmphasisOnFill: true,
          })}
        />
        <ImageUpload
          className="notification-image"
          label={getMessage("campaign.form.pushImage.heading")}
          placeholder={getMessage("campaign.form.pushImage.placeholder")}
          singleImage // being passed to allow only one image upload
          disabled={isDisabled}
          name="pushImage"
          {...this.generateStateMappers({
            stateKeys: ["pushImage"],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
          validationStrings={{
            valueMissing: getMessage("input.requiredMessage"),
            isUploading: getMessage("input.isUploading"),
          }}
        >
          {getMessage("product.form.variant.image.description")}
        </ImageUpload>
      </div>
    );

    return (
      <div>
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <Form className="campaign-form">
            <div className="campaign-form-container">
              <Input
                className="name"
                label={getMessage("campaign.form.name.heading")}
                placeholder={getMessage("campaign.form.name.placeholder")}
                name="name"
                type="text"
                onFocus={(e) => this.updateLastFocussedElement({ e })}
                required
                readOnly={isDisabled}
                {...this.generateStateMappers({
                  stateKeys: ["name"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                }}
              />
              <div className="flex date-time-wrapper">
                <SingleDatePicker
                  className="date-picker"
                  label={getMessage("campaign.form.date.heading")}
                  {...this.generateStateMappers({
                    stateKeys: ["date"],
                    loseEmphasisOnFill: true,
                  })}
                  enableToday
                  displayFormat="YYYY-MM-DD"
                  placeholder="Date"
                  disabled={isDisabled}
                  readOnly={isDisabled}
                  required
                />
                <div className="flex time-wrapper">
                  <Select
                    label={getMessage("campaign.form.time.heading")}
                    name="time"
                    className="campaign-time"
                    type="text"
                    placeholder="Time"
                    required
                    options={getTimes()}
                    {...this.generateStateMappers({
                      stateKeys: ["time"],
                      loseEmphasisOnFill: true,
                    })}
                    readOnly={isDisabled}
                  />
                </div>
              </div>
            </div>
            {!isDateTimeValid && !isDisabled && (
              <div className="invalid-date-time input-error-message">
                {getMessage("campaign.form.invalidDateTime")}
              </div>
            )}
            <Textarea
              className="comment-box"
              label={getMessage("campaign.form.comment.heading")}
              placeholder={getMessage("campaign.form.comment.placeholder")}
              name="comment"
              onFocus={(e) => this.updateLastFocussedElement({ e })}
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["comment"],
                loseEmphasisOnFill: true,
              })}
              readOnly={isDisabled}
            />
            {variables && variables.length > 0 && (
              <div>
                <h3>{getMessage("campaign.form.vairableList.heading")}</h3>
                {variables.map((variable) => (
                  <div
                    className="variables"
                    key={variable}
                    data-variable={variable}
                    onClick={this.variableClicked}
                  >
                    {variable}
                  </div>
                ))}
              </div>
            )}
            {activeMediumId && mediums && mediums.length > 0 && (
              <div className="tabs-preview-wrapper flex">
                <div className="tab-container">
                  <Tabs
                    items={mediums}
                    nameKey="name"
                    valueKey="id"
                    default={activeMediumId}
                    onClick={this.updateMediumId}
                  />
                  {activeMediumId === 1 && EmailComponent}
                  {activeMediumId === 2 && SMSComponent}
                  {activeMediumId === 3 && PushNotificationComponent}
                </div>
                <div className="preview-wrapper">
                  <h2 className="preveiw-heading">
                    {getMessage("campaign.form.preview.heading")}
                  </h2>
                  <div className="previewer">
                    {activeMediumId === 1 && (
                      <PreviewElement
                        templateType="EMAIL"
                        subject={emailSubject}
                        body={emailBody}
                      />
                    )}
                    {activeMediumId === 2 && (
                      <PreviewElement templateType="SMS" sms={smsContent} />
                    )}
                    {activeMediumId === 3 && (
                      <PreviewElement
                        templateType="PUSH"
                        img={pushImage?.[0]}
                        body={pushBody}
                        subject={pushSubject}
                        url={pushUrl}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            <SegmentsForm _this={this} isDisabled={isDisabled} />
            {!isDisabled && (
              <div className="form-buttons-container">
                <SubmitButton disabled={!isDateTimeValid}>
                  {getMessage("campaign.form.submit.text")}
                </SubmitButton>
                <CancelButton>
                  {getMessage("campaign.form.cancel.text")}
                </CancelButton>
              </div>
            )}
          </Form>
        )}
      </div>
    );
  }
}
