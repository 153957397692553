import React from "react";
import { getMessage } from "../../../../lib/translator";

import "./style.css";

export default function UploadKML({ handleKmlUpload }) {
  return (
    <div className="add-delivery-area">
      <div className="button primary" onClick={handleKmlUpload}>
        <span>{getMessage("Upload KML file")}</span>
      </div>
    </div>
  );
}
