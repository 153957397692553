import moment from "moment";

export const TYPES = {
  DATE: "Date",
  STORE: "Store",
  START_DATE: "startDate",
  END_DATE: "endDate",
};

const handleValueWithComma = (val) =>
  val && val.includes(",") ? `"${val}"` : val;

export const downloadCSV = (data = [], name) => {
  let csv = data.reduce((acc, curr) => {
    let line = curr.map(handleValueWithComma).join(",");
    line += "\n";
    acc += line;
    return acc;
  }, "");

  var hiddenElement = document.createElement("a");
  const csvData = new Blob([csv], { type: "text/csv" });
  const href = URL.createObjectURL(csvData);
  hiddenElement.href = href;
  hiddenElement.target = "_blank";
  hiddenElement.download = name ? `${name}.csv` : `report.csv`;
  hiddenElement.click();
};

export const getRenderParams = (params) => {
  const storeConfig = params?.filter(({ type }) => type === TYPES.STORE)[0];
  const dateConfig = params?.filter(({ type }) => type === TYPES.DATE);

  const startDateConfig = dateConfig?.filter(
    ({ key }) => key === TYPES.START_DATE
  )[0];
  const endDateConfig = dateConfig?.filter(
    ({ key }) => key === TYPES.END_DATE
  )[0];
  const otherDateConfigs = dateConfig?.filter(
    ({ key }) => ![TYPES.START_DATE, TYPES.END_DATE].includes(key)
  );

  return { storeConfig, startDateConfig, endDateConfig, otherDateConfigs };
};

export const isValid = (data, params) => {
  let valid = true;
  params &&
    params.forEach(({ key }) => {
      valid = valid && Boolean(data[key]);
    });

  if (valid && data.startDate && data.endDate) {
    valid = valid && moment(data.endDate).isAfter(data.startDate);
  }

  return valid;
};

export const createThirtyDaysTimeSpanHandler = (startDate) => (day) => {
  const diff = moment(startDate).diff(day, "day");
  return !(diff < 0 && diff >= -30);
};
