import React from "react";
import {
  BaseForm,
  Input,
  Checkbox,
  Textarea,
  Phone,
  VALIDATION_TYPES,
} from "../../../components/Form";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";
import Loader from "../../../components/Loader";
import { withRouter } from "react-router-dom";
import { Dialog } from "../../../components/Popup";
import Table, { Row } from "../../../components/Table";
import addIcon from "./add-button.svg";
import deleteIcon from "./delete.svg";

const AccountList = (props) => {
  const { accounts, toggleAccountAction } = props;
  return (
    <Table>
      {(accounts || []).map((account, index) => {
        let actionIcon = account.isAdd ? (
          <img src={addIcon} alt="add" />
        ) : (
          <img src={deleteIcon} alt="delete" />
        );
        return (
          <Row key={"account" + index}>
            <div className="flex">
              <span className={`icon icon-${account.name}`}>
                <span>{getMessage(`media.select.${account.name}`)}</span>
              </span>
              <button
                type="button"
                className="select-actions"
                onClick={() => toggleAccountAction(account.name)}
              >
                {actionIcon}
              </button>
            </div>
          </Row>
        );
      })}
    </Table>
  );
};

class SocialMediaForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.values = {
      facebookHandle: "",
      twitterHandle: "",
      hasFacebookLogin: false,
      hasTwitterLogin: false,
      iosAppLink: "",
      androidAppLink: "",
    };
    this.state.formError = "";
    this.state.showLoader = false;
    this.state.showSuccessDialog = false;
    this.state.showErrorDialog = false;
    this.state.showAccountList = false;

    this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closeDialogs = this.closeDialogs.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleAccountAction = this.toggleAccountAction.bind(this);
    this.handleWhatsappToggle = this.handleWhatsappToggle.bind(this);
  }

  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    });
  }

  handleClose() {
    this.props.history.goBack();
  }

  handleClickOutside(event) {
    if (!["select", "select-store"].includes(event.target.id)) {
      this.setState({ showAccountList: false, showStoreList: false });
    }
  }

  toggleAccountAction(name) {
    let accounts = this.state.accounts;
    let accountsArray =
      accounts &&
      accounts.map((account) => {
        if (account.name === name) {
          account.isAdd = !account.isAdd;
        }
        return account;
      });
    let appLinks = this.state.appLinks;
    let appLinksArray =
      appLinks &&
      appLinks.map((appStore) => {
        if (appStore.name === name) {
          appStore.isAdd = !appStore.isAdd;
        }
        return appStore;
      });
    this.setState({
      accounts: accountsArray,
      appLinks: appLinksArray,
    });
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    this.socialApi = new API({ url: "/account-service/config/social" });
    this.basicInfoApi = new API({ url: "/account-service/config/basic" });
    this.appStoreApi = new API({ url: "/config-service/config/basic" });

    Promise.all([
      this.socialApi.get(),
      this.basicInfoApi.get(),
      this.appStoreApi.get(),
    ])
      .then(([socialResponse, basicInfoResponse, appStoreResponse]) => {
        if (
          socialResponse.status === "SUCCESS" &&
          basicInfoResponse.status === "SUCCESS" &&
          appStoreResponse.status === "SUCCESS"
        ) {
          const social = socialResponse.data.config.social;
          const basic = basicInfoResponse.data.config.basic;
          const appStore = appStoreResponse.data.basic;
          const values = {
            facebookHandle: social.facebookHandle,
            twitterHandle: social.twitterHandle,
            hasFacebookLogin: social.hasFacebookLogin
              ? social.hasFacebookLogin
              : false,
            hasTwitterLogin: social.hasTwitterLogin
              ? social.hasTwitterLogin
              : false,
            supportEmail: basic.supportEmail,
            supportPhone: basic.supportPhone && basic.supportPhone.toString(),
            iosAppLink: appStore.iosAppLink,
            androidAppLink: appStore.androidAppLink,
            whatsappSupportEnabled: appStore.whatsappSupportEnabled,
            whatsappSupportPhone: appStore.whatsappSupportPhone
              ? `+${appStore.whatsappSupportPhone}`
              : "",
            whatsappSupportEnquiryText: appStore.whatsappSupportEnquiryText,
          };
          this.setState({
            values,
            showLoader: false,
            appLinks: this.getAppLinks(values),
            accounts: this.getSocialMediaAccounts(values),
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
    document.addEventListener("click", this.handleClickOutside, false);
  }

  componentWillUnmount() {
    this.socialApi && this.socialApi.cancel();
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  handleWhatsappToggle(value) {
    this.updateState(["whatsappSupportEnabled"], value);
    const validations = { ...this.state.validations };
    if (value && !this.state.values.whatsappSupportPhone) {
      validations.whatsappSupportPhone = {
        valid: false,
        valueMissing: getMessage("input.requiredMessage"),
      };
    } else {
      validations.whatsappSupportPhone = { valid: true, valueMissing: false };
    }
    this.setState({ validations });
  }

  handleSubmit(data) {
    let socialApi = new API({ url: "/account-service/config/social" });
    let basicInfoApi = new API({ url: "/account-service/config/basic" });
    let appLinkApi = new API({ url: "/config-service/config" });

    let socialParams = {};
    let basicParams = {};
    let appLinkParams = {};

    const basicParamsArray = ["supportEmail", "supportPhone"];
    const appLinksKeys = [
      "iosAppLink",
      "androidAppLink",
      "whatsappSupportEnabled",
      "whatsappSupportPhone",
      "whatsappSupportEnquiryText",
    ];
    socialParams["social"] = {};
    basicParams["basic"] = {};
    appLinkParams["basic"] = {};

    for (let field in data) {
      if (!basicParamsArray.includes(field)) {
        if (!appLinksKeys.includes(field)) {
          socialParams["social"][field] = data[field];
          // Removed deleted keys from payload
          this.state.accounts.forEach((account) => {
            if (field.toLowerCase().includes(account.name) && account.isAdd) {
              if (typeof data[field] === "boolean") {
                socialParams["social"][field] = false;
              } else {
                socialParams["social"][field] = "";
              }
            }
          });
        } else {
          appLinkParams["basic"][field] = data[field];
          if (field === "whatsappSupportPhone") {
            appLinkParams["basic"][field] = data[field]
              ? data[field].slice(1)
              : "";
          }
          // Removed deleted keys from payload
          this.state.appLinks.forEach((account) => {
            if (field === account.name && account.isAdd) {
              appLinkParams["basic"][field] = "";
            }
          });
        }
      } else {
        basicParams["basic"][field] = data[field];
      }
    }
    return Promise.all([
      socialApi.put(socialParams),
      basicInfoApi.put(basicParams),
      appLinkApi.post(appLinkParams),
    ])
      .then(
        ([socialResponse, basicInfoResponse, storeInfoResponse]) => {
          this.setState({ formError: "", showSuccessDialog: true });
        },
        (error) => {
          this.setState({ formError: error.message, showErrorDialog: true });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(data) {
    if (
      this.state.values.whatsappSupportEnabled &&
      !this.state.values.whatsappSupportPhone
    ) {
      return;
    }
    this.setState({
      submitting: true,
    });
    this.handleSubmit(data).then(() => {
      this.setState({ submitting: false });
    });
  }

  toggleCheckboxChange(e) {
    const stateName = e.target.value;
    var values = { ...this.state.values };
    values[stateName] = !values[stateName];
    this.setState({ values });
  }

  getSocialMediaAccounts(values) {
    let facebook = {
      name: "facebook",
      isAdd: !values.facebookHandle,
    };
    let twitter = {
      name: "twitter",
      isAdd: !values.twitterHandle,
    };

    return [facebook, twitter];
  }

  getAppLinks(values) {
    let ios = { name: "iosAppLink", isAdd: !values.iosAppLink };
    let android = { name: "androidAppLink", isAdd: !values.androidAppLink };

    return [ios, android];
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const { accounts, showAccountList, appLinks, showStoreList, values } =
      this.state;
    const isWhatsappphoneRequired = Boolean(
      values.whatsappSupportEnabled && !values.whatsappSupportPhone
    );
    return (
      <div>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("socialMedia.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("socialMedia.save.success.closeText")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage("socialMedia.save.error.title")}
          close={this.closeDialogs}
          closeText={getMessage("socialMedia.save.error.closeText")}
        />
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <Form>
            <Input
              label={getMessage("basicInfo.storename.support.email")}
              placeholder={getMessage(
                "basicInfo.storename.support.email.placeholder"
              )}
              name="supportEmail"
              type="email"
              {...this.generateStateMappers({
                stateKeys: ["supportEmail"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            />

            <Phone
              label={getMessage("basicInfo.storename.support.phone")}
              placeholder={getMessage(
                "basicInfo.storename.support.phone.placeholder"
              )}
              name="supportPhone"
              value={this.state.values.supportPhone}
              {...this.generateStateMappers({
                stateKeys: ["supportPhone"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Phone
              label={getMessage("socialMedia.whatsappSupportPhone")}
              placeholder={getMessage(
                "socialMedia.whatsappSupportPhone.placeholder"
              )}
              name="whatsappSupportPhone"
              value={this.state.values.whatsappSupportPhone}
              {...this.generateStateMappers({
                stateKeys: ["whatsappSupportPhone"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              error={
                isWhatsappphoneRequired
                  ? getMessage("input.requiredMessage")
                  : ""
              }
              required={isWhatsappphoneRequired}
              secondaryLabel={() => (
                <Checkbox
                  inlineLabel={getMessage("socialMedia.whatsappSupportEnabled")}
                  name="whatsappSupportEnabled"
                  value={this.getState(["whatsappSupportEnabled"])}
                  onChange={this.handleWhatsappToggle}
                  className="checkLabel"
                />
              )}
            />
            <Textarea
              label={getMessage("socialMedia.whatsappSupportEnquiryText")}
              placeholder={getMessage(
                "socialMedia.whatsappSupportEnquiryText.placeholder"
              )}
              name="whatsappSupportEnquiryText"
              {...this.generateStateMappers({
                stateKeys: ["whatsappSupportEnquiryText"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            />
            <label className="select-media-header">
              {getMessage("socialMedia.accounts.label")}
            </label>
            <div
              className="select-social-media"
              id="select"
              onClick={(event) => {
                this.setState({ showAccountList: true });
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              {getMessage("socialMedia.accounts.placeholder")}
              {showAccountList && (
                <AccountList
                  accounts={this.state.accounts}
                  toggleAccountAction={this.toggleAccountAction}
                />
              )}
            </div>
            {accounts && !accounts.find((o) => o.name === "facebook").isAdd && (
              <Input
                className="facebook-field"
                label={getMessage("socialMedia.facebook.heading")}
                name="facebook"
                type="text"
                placeholder={getMessage("socialMedia.facebook.placeholder")}
                prefix="https://www.facebook.com/"
                icon="facebook"
                secondaryLabel={() => (
                  <Checkbox
                    inlineLabel={getMessage("socialMedia.facebook.allowlogin")}
                    name="hasFacebookLogin"
                    value={this.getState(["hasFacebookLogin"])}
                    onChange={(value, error) => {
                      this.updateState(["hasFacebookLogin"], value);
                    }}
                    className="checkLabel"
                  />
                )}
                value={this.getState(["facebookHandle"])}
                onChange={(value, error) => {
                  this.updateState(["facebookHandle"], value);
                }}
              >
                {getMessage("socialMedia.facebook.description")}
              </Input>
            )}
            {accounts && !accounts.find((o) => o.name === "twitter").isAdd && (
              <Input
                className="twitter-field"
                label={getMessage("socialMedia.twitter.heading")}
                name="twitter"
                placeholder={getMessage("socialMedia.twitter.placeholder")}
                prefix="https://www.twitter.com/"
                icon="twitter"
                secondaryLabel={() => (
                  <Checkbox
                    inlineLabel={getMessage("socialMedia.twitter.allowlogin")}
                    name="hasTwitterLogin"
                    value={this.getState(["hasTwitterLogin"])}
                    onChange={(value, error) => {
                      this.updateState(["hasTwitterLogin"], value);
                    }}
                    className="checkLabel"
                  />
                )}
                value={this.getState(["twitterHandle"])}
                onChange={(value, error) => {
                  this.updateState(["twitterHandle"], value);
                }}
              >
                {getMessage("socialMedia.twitter.description")}
              </Input>
            )}
            <label className="select-media-header">
              {getMessage("store.accounts.label")}
            </label>
            <div
              className="select-social-media"
              id="select-store"
              onClick={(event) => {
                this.setState({ showStoreList: !this.state.showStoreList });
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              {getMessage("store.accounts.placeholder")}
              {showStoreList && (
                <AccountList
                  accounts={appLinks}
                  toggleAccountAction={this.toggleAccountAction}
                />
              )}
            </div>
            {appLinks &&
              !appLinks.find((o) => o.name === "iosAppLink").isAdd && (
                <Input
                  className="ios-field"
                  label={getMessage("store.ios.heading")}
                  name="ios"
                  placeholder={getMessage("store.ios.placeholder")}
                  prefix="https://apps.apple.com/"
                  icon="iosAppLink"
                  value={this.getState(["iosAppLink"])}
                  onChange={(value, error) => {
                    this.updateState(["iosAppLink"], value);
                  }}
                >
                  {getMessage("store.ios.description")}
                </Input>
              )}
            {appLinks &&
              !appLinks.find((o) => o.name === "androidAppLink").isAdd && (
                <Input
                  className="android-field"
                  label={getMessage("store.android.heading")}
                  name="android"
                  placeholder={getMessage("store.android.placeholder")}
                  prefix="https://play.google.com/"
                  icon="androidAppLink"
                  value={this.getState(["androidAppLink"])}
                  onChange={(value, error) => {
                    this.updateState(["androidAppLink"], value);
                  }}
                >
                  {getMessage("store.android.description")}
                </Input>
              )}
            <div className="form-buttons-container">
              <SubmitButton disabled={this.state.submitting}>
                {getMessage("socialMedia.submit.text")}
              </SubmitButton>
              <button
                className="button"
                type="button"
                onClick={this.handleClose}
                disabled={this.state.submitting}
              >
                {getMessage("socialMedia.cancel.text")}
              </button>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

export default withRouter(SocialMediaForm);
