import React, { Component } from "react";
import Tabs from "../../../components/Tabs";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import { getMessage } from "../../../lib/translator";
import ListingComponent from "./ListingComponent";

import "./style.css";
import { isExtensionEnabled } from "../../../lib/auth";
import API from "../../../lib/api";

class SearchConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.state.tabs = [{ name: "Search Term", pageType: "SEARCH" }];
    this.changeTab = this.changeTab.bind(this);
  }

  changeTab(activeIndex) {
    this.setState({ activeIndex });
  }
  componentWillMount() {
    const api = new API({ url: "/config-service/config/searchOverrider" });
    api
      .get()
      .then((response) => {
        const searchOverrideArray =
          response.data.searchOverrider.entityAllowedForSearchOverride;
        let newSearchTab = [...this.state.tabs];
        if (searchOverrideArray.includes("CATEGORY")) {
          newSearchTab.push({ name: "Category", pageType: "CATEGORY" });
        }
        if (
          isExtensionEnabled("MultiBrandSupport") &&
          searchOverrideArray.includes("BRAND")
        ) {
          newSearchTab.push({ name: "Brand", pageType: "BRAND" });
        }

        if (
          isExtensionEnabled("ProductTagSupport") &&
          searchOverrideArray.includes("TAG")
        ) {
          newSearchTab.push({ name: "Tag", pageType: "TAG" });
        }
        this.setState({
          tabs: newSearchTab,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    let { menu } = this.props;
    let { state } = this;
    let { activeIndex } = state;

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className="search-config"
      >
        <h1>{getMessage("search-configuration.header")}</h1>
        <Tabs
          items={state?.tabs?.map((tab) => `${tab.name}`)}
          default={this.state.activeIndex}
          onClick={(index) => {
            this.changeTab(index);
          }}
        />
        <ListingComponent pageType={this.state.tabs[activeIndex]["pageType"]} />
      </AuthenticatedPage>
    );
  }
}

export default SearchConfiguration;
