import React from "react";
import Header from "../../components/ZopSmartHeader";
import SmartStoreHeader from "../../components/SmartstoreHeader";
import { Provider } from "../Context";

import vehicle from "./vehicle.svg";
import "./style.css";

export default function PublicPage(props) {
  let enterprise = true;
  let subDomain = window.location.hostname.split(".")[0];
  if (subDomain === "dashboard" || subDomain === "localhost") {
    enterprise = false;
  }
  return (
    <Provider value={enterprise}>
      <div className={"fullWidthPage eazy-page " + props.className}>
        {props.showHeader && (
          <header className="global-header">
            <Header eazy />
            <SmartStoreHeader
              links={[
                {
                  href: "/login",
                  className: "smartstore-login",
                  label: "login",
                },
                {
                  href: "/signup",
                  className: "smartstore-signup",
                  label: "sign up",
                },
              ]}
              enterprise={enterprise}
            />
          </header>
        )}
        <main>{props.children}</main>
        <footer className="page-footer">
          <img className="vehicle" src={vehicle} alt="zopsmart-vehicle" />
        </footer>
      </div>
    </Provider>
  );
}
PublicPage.defaultProps = {
  showHeader: true,
};
