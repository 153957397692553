/* eslint-disable react/jsx-no-literals */
import React from "react";

function NoRating(props) {
  return (
    <svg
      width="227.287"
      height="179.296"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <ellipse
          fill="#FFF"
          transform="rotate(11 147.191 76.305)"
          cx="147.191"
          cy="76.305"
          rx="69.096"
          ry="64.303"
        />
        <ellipse
          fill="#FFF"
          transform="rotate(11 46.816 134.695)"
          cx="46.816"
          cy="134.695"
          rx="40.387"
          ry="37.585"
        />
        <g transform="translate(36.37 26.012)">
          <rect
            stroke="#00BE00"
            fill="#FFF"
            x="26.524"
            y="26.47"
            width="79.128"
            height="99.966"
            rx="8"
          />
          <path
            stroke="#00BE00"
            d="m44.241 56-4.84 2.546.924-5.39-3.916-3.818 5.412-.787 2.42-4.904 2.42 4.904 5.413.787-3.916 3.817.924 5.39z"
          />
          <path
            stroke="#005A75"
            d="m66.487 56-4.84 2.546.924-5.39-3.916-3.818 5.412-.787 2.42-4.904 2.42 4.904 5.413.787-3.916 3.817.924 5.39z"
          />
          <path
            stroke="#00BE00"
            d="m88.733 56-4.84 2.546.924-5.39-3.917-3.818 5.413-.787 2.42-4.904 2.42 4.904 5.413.787-3.917 3.817.925 5.39z"
          />
          <path
            d="M45.39 26.47h41.114v4.448a4 4 0 0 1-4 4H49.39a4 4 0 0 1-4-4V26.47z"
            stroke="#00BE00"
            fill="#FFF"
          />
          <circle stroke="#00BE00" cx="90.727" cy="15.206" r="2.253" />
          <circle stroke="#00BE00" cx="132.403" cy="53.221" r="2.253" />
          <circle stroke="#00BE00" cx="60.034" cy="2.253" r="2.253" />
          <path
            d="m13.538 30.969.021.03c.12.134.335.185.552.188a1.48 1.48 0 0 0 .69-.158l.014-.009-.014.033c-.05.149-.006.33.076.5l.037.071c.115.207.28.387.485.52l.012.007-.034.005c-.155.03-.29.158-.396.314l-.044.069a1.474 1.474 0 0 0-.207.679l-.001.015-.02-.028c-.105-.118-.283-.172-.47-.186l-.082-.003a1.474 1.474 0 0 0-.692.16l-.015.007.016-.032c.05-.15.007-.33-.075-.5l-.037-.072a1.474 1.474 0 0 0-.485-.52l-.013-.008.035-.003c.155-.032.29-.16.396-.315l.044-.068c.121-.203.194-.436.207-.68v-.016zM25.422 6.787l.008.034a1.192 1.192 0 0 0 .252.451l.047.048-.022.023a1.19 1.19 0 0 0-.084.1l-.073.107a1.186 1.186 0 0 0-.109.237l-.018.065-.03-.008a1.19 1.19 0 0 0-.128-.023l-.13-.01a1.186 1.186 0 0 0-.26.025l-.066.017-.007-.03a1.19 1.19 0 0 0-.044-.123l-.057-.117a1.186 1.186 0 0 0-.15-.213l-.05-.049.024-.021a1.19 1.19 0 0 0 .085-.1l.073-.107c.044-.075.08-.154.108-.237l.018-.067.01.002c.04.009.08.016.118.022l.11.011c.1.006.196 0 .288-.016l.087-.021zM71.828 7.034l.021.029c.12.135.335.186.552.189a1.48 1.48 0 0 0 .69-.158l.013-.008-.013.032c-.05.148-.006.33.076.499l.037.072c.115.207.28.386.484.52l.014.007-.035.004c-.155.031-.29.159-.396.315l-.044.068a1.474 1.474 0 0 0-.207.68l-.001.015-.02-.029c-.105-.118-.283-.171-.47-.185l-.082-.004a1.474 1.474 0 0 0-.692.16l-.014.008.015-.032c.05-.15.007-.33-.075-.5l-.037-.072a1.474 1.474 0 0 0-.485-.52l-.013-.008.035-.004c.155-.031.29-.159.396-.314l.044-.069c.121-.203.194-.435.207-.68v-.015zM122.515 36.603l.021.027c.12.135.335.186.551.19a1.48 1.48 0 0 0 .691-.16l.013-.007-.013.032c-.05.149-.007.33.075.5l.038.071c.115.207.28.387.484.52l.014.007-.036.004c-.154.032-.29.16-.395.315l-.044.068a1.474 1.474 0 0 0-.208.68v.015l-.02-.028c-.105-.118-.283-.172-.471-.186l-.081-.003a1.474 1.474 0 0 0-.692.16l-.014.007.015-.032c.05-.15.007-.33-.075-.5l-.037-.072a1.474 1.474 0 0 0-.485-.52l-.014-.008.036-.003c.154-.032.29-.16.396-.315l.043-.068c.122-.204.195-.436.208-.68v-.014z"
            stroke="#005A75"
          />
          <circle stroke="#00BE00" cx="118.324" cy="8.166" r="1.971" />
          <circle stroke="#00BE00" cx="24.553" cy="21.401" r="1.971" />
          <circle stroke="#00BE00" cx="3.152" cy="45.618" r="1.971" />
          <rect
            fill="#005A75"
            x="11.6"
            y="125.872"
            width="108.695"
            height="1.181"
            rx=".59"
          />
          <rect
            fill="#005A75"
            x="48.207"
            y="132.912"
            width="35.481"
            height="1.181"
            rx=".59"
          />
          <rect
            fill="#005A75"
            x="36.662"
            y="132.912"
            width="9.574"
            height="1.181"
            rx=".59"
          />
          <rect
            fill="#005A75"
            x="85.659"
            y="132.912"
            width="9.574"
            height="1.181"
            rx=".59"
          />
          <rect
            fill="#005A75"
            x="123.111"
            y="125.872"
            width="7.04"
            height="1.181"
            rx=".59"
          />
          <rect
            fill="#005A75"
            x="2.589"
            y="125.872"
            width="7.04"
            height="1.181"
            rx=".59"
          />
          <rect
            fill="#005A75"
            x="131.531"
            y="125.91"
            width="1.226"
            height="1.226"
            rx=".613"
          />
          <rect
            fill="#005A75"
            y="125.91"
            width="1.226"
            height="1.226"
            rx=".613"
          />
          <rect
            fill="#E6F6FF"
            x="34.69"
            y="69.835"
            width="59.202"
            height="1.107"
            rx=".554"
          />
          <rect
            fill="#E6F6FF"
            x="34.69"
            y="92.19"
            width="59.202"
            height="1.107"
            rx=".554"
          />
          <rect
            fill="#E6F6FF"
            x="34.69"
            y="74.885"
            width="53.821"
            height="1.107"
            rx=".554"
          />
          <rect
            fill="#E6F6FF"
            x="34.69"
            y="97.24"
            width="53.821"
            height="1.107"
            rx=".554"
          />
          <rect
            fill="#E6F6FF"
            x="34.69"
            y="79.934"
            width="51.13"
            height="1.107"
            rx=".554"
          />
          <rect
            fill="#E6F6FF"
            x="34.69"
            y="102.289"
            width="51.13"
            height="1.107"
            rx=".554"
          />
        </g>
      </g>
    </svg>
  );
}

export default NoRating;
