import React from "react";
import {
  Radio,
  DateRangePicker,
  BaseForm,
} from "../../../../../components/Form";
import SimpleBarChart from "./simple-bar-chart";
import { getMessage } from "../../../../../lib/translator";
import "./style.css";

function sortDateArray(data, type) {
  let dates = Object.keys(data);
  let sortedDates = dates.sort(function (a, b) {
    return new Date(b) - new Date(a);
  });
  let reqArr = sortedDates.map(function (eachDate) {
    let newob = {};
    for (var property in data[eachDate][type]) {
      if (data[eachDate][type].hasOwnProperty(property)) {
        let newProperty = "";
        if (property === "DESKTOP_NA_WEB") {
          newProperty = getMessage("coupon.analytics.pieChart.DESKTOP_NA_WEB");
        } else if (property === "MOBILE_ANDROID_WEB") {
          newProperty = getMessage(
            "coupon.analytics.pieChart.MOBILE_ANDROID_WEB"
          );
        } else if (property === "MOBILE_IOS_WEB") {
          newProperty = getMessage("coupon.analytics.pieChart.MOBILE_IOS_WEB");
        } else {
          let splitString = property.split("_");
          newProperty =
            splitString.length > 1 ? splitString.join(" ") : property;
        }
        newob[newProperty] = data[eachDate][type][property];
      }
    }

    let ob = { Name: eachDate, ...newob };
    return ob;
  });

  return reqArr;
}

function fetchingDataKeys(data) {
  let arr = data.map((element, index) => {
    return Object.keys(element);
  });

  let dataKeys = [];
  arr.map((element) => {
    return element.map(function (elem) {
      if (elem !== "Name" && !dataKeys.includes(elem)) {
        dataKeys.push(elem);
      }
      return dataKeys;
    });
  });
  return dataKeys;
}

function generatingRadioOptions(data) {
  let radioOptions = [{ text: "All", value: "" }];
  data.map((val) => {
    let value = val.toLowerCase();
    return radioOptions.push({
      text: value.charAt(0).toUpperCase() + value.slice(1),
      value: val,
    });
  });

  return radioOptions;
}

export default class AnalyticBarChart extends BaseForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        dateRange: {
          startDate: this.props.startDate,
          endDate: this.props.endDate,
        },
      },
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(dates) {
    let values = JSON.parse(JSON.stringify(this.state.values));
    values.dateRange.startDate = dates && dates.startDate;
    values.dateRange.endDate = dates && dates.endDate;

    this.setState({
      values,
    });
    this.props.onChange(values.dateRange);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      let values = JSON.parse(JSON.stringify(this.state.values));
      values.dateRange.startDate = this.props.startDate;
      values.dateRange.endDate = this.props.endDate;
      this.setState({
        values,
      });
    }
  }

  render() {
    let reqArr = sortDateArray(this.props.chartData, this.props.chartType);
    let dataKeys = fetchingDataKeys(reqArr);
    let radioOptions = generatingRadioOptions(dataKeys);
    let gadgetSelected =
      this.state && this.state.values && this.state.values["options-select"]
        ? this.state.values["options-select"]
        : null;

    let filteredData = [];
    if (gadgetSelected && gadgetSelected !== "") {
      reqArr.map((item, index) => {
        let ob = {};
        ob.Name = item.Name;
        ob[gadgetSelected] = item[gadgetSelected];
        filteredData[index] = ob;
        return filteredData;
      });
    } else {
      filteredData = reqArr;
    }

    return (
      <section className="analytic-bar-chart">
        <header>
          <h5>{this.props.heading}</h5>
        </header>
        <div className="filter">
          <div className="date-selector">
            <DateRangePicker
              openDirection="up"
              minimumNights={0}
              isOutsideRange={(date) => date > new Date()}
              label={getMessage("coupon.analytics.selectDates.heading")}
              {...this.generateStateMappers({
                stateKeys: ["dateRange"],
              })}
              onChange={this.onChange}
            />
            <input
              className="primary"
              type="submit"
              value="Submit"
              onClick={() =>
                this.props.onClickSubmit({
                  startDate: this.state.values.dateRange
                    ? this.state.values.dateRange.startDate
                    : null,
                  endDate: this.state.values.dateRange
                    ? this.state.values.dateRange.endDate
                    : null,
                })
              }
            />
          </div>
          <div className="options-selector">
            <Radio
              label={getMessage("coupon.analytics.status.heading")}
              options={radioOptions}
              name="options-select"
              {...this.generateStateMappers({
                stateKeys: ["options-select"],
              })}
            />
          </div>
        </div>
        <SimpleBarChart
          data={filteredData}
          showLoader={this.props.barChartLoader}
          dataKeys={dataKeys}
          colors={this.props.colors}
        />
      </section>
    );
  }
}
