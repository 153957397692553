import React from "react";
import {
  BaseForm,
  VALIDATION_TYPES,
  SelectSearch,
} from "../../../../../components/Form";
import { Dialog } from "../../../../../components/Popup";
import API from "../../../../../lib/api";
import { getLanguages } from "../../../../../lib/commonlyused";
import { getMessage } from "../../../../../lib/translator";

export default class LanguageSelect extends BaseForm {
  constructor(props) {
    super(props);
    this.state.showSuccessDialog = false;
    this.state.showErrorDialog = false;
    this.state.formError = "";
    if (this.state.values?.globalConfig?.languages?.length) {
      // format languages from ['en'] to [{ name: 'Engligh', code: 'en' }]
      this.state.values.globalConfig.languages = getLanguages().filter((lang) =>
        this.state.values.globalConfig.languages.includes(lang.code)
      );
    }
    this.closeDialogs = this.closeDialogs.bind(this);
  }

  handleSubmit(data) {
    const id = this.props.data.id;
    const api = new API({ url: `/account-service/extension/${id}` });

    let params = { status: this.props.data.status };
    params["config"] = JSON.parse(JSON.stringify(data));
    // format languages from [{ name: 'Engligh', code: 'en' }] to ['en']
    params.config.globalConfig.languages =
      params.config.globalConfig.languages.map((lang) => lang.code);

    return api
      .put(params)
      .then(
        (response) => {
          this.setState({ formError: "", showSuccessDialog: true });
        },
        (error) => {
          this.setState({ formError: error.message, showErrorDialog: true });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleSubmit(data).then(() => {
      this.setState({ submitting: false });
    });
  }

  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    });
  }
  render() {
    const { Form } = this.components;
    const { SubmitButton, CancelButton } = this.buttons;
    return (
      <div>
        <h1 className="title">
          {getMessage("extensions.multiLingual.heading")}
        </h1>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("extensions.analytics.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("extensions.analytics.save.success.closeText")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage("extensions.analytics.save.error.title")}
          close={this.closeDialogs}
          closeText={getMessage("extensions.analytics.save.error.closeText")}
        />
        <Form>
          <div className="coupon-section valid-stores">
            <SelectSearch
              label=" "
              name="Language"
              placeholder={getMessage("extensions.multiLingual.placeholder")}
              required
              options={getLanguages().sort((a, b) => {
                let x = a.name.toLowerCase();
                let y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
              })}
              nameKey="name"
              valueKey="code"
              searchByName
              multiple
              autoComplete="off"
              {...this.generateStateMappers({
                stateKeys: ["globalConfig", "languages"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <SubmitButton>Submit</SubmitButton>
          <CancelButton>Cancel</CancelButton>
        </Form>
      </div>
    );
  }
}
