import React from "react";
import { getMessage } from "../../lib/translator";
import { Input } from "../Form";

export const OtherCourierForm = (props) => {
  const { parent } = props;
  const _this = parent;
  const courierName = "courierName";
  const courierCharges = "courierCharges";
  const courierTrackingId = "courierTrackingId";
  return (
    <React.Fragment>
      <div className="other-courier-form-wrp">
        <Input
          key={courierName}
          name={courierName}
          label={getMessage("order.dispatch.label.courier.name")}
          type="text"
          placeholder={getMessage("order.dispatch.placeholder.courier.name")}
          {..._this.generateStateMappers({
            stateKeys: [courierName],
            loseEmphasisOnFill: true,
          })}
          required={true}
        />
        <Input
          key={courierCharges}
          name={courierCharges}
          label={getMessage("order.dispatch.label.courier.charges")}
          type="text"
          placeholder={getMessage("order.dispatch.placeholder.courier.charges")}
          {..._this.generateStateMappers({
            stateKeys: [courierCharges],
            loseEmphasisOnFill: true,
          })}
        />
        <Input
          key={courierTrackingId}
          name={courierTrackingId}
          label={getMessage("order.dispatch.label.courier.tracking.id")}
          type="text"
          placeholder={getMessage(
            "order.dispatch.placeholder.courier.tracking.id"
          )}
          {..._this.generateStateMappers({
            stateKeys: [courierTrackingId],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    </React.Fragment>
  );
};
