import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getPrintableTime,
  utcToLocalTime,
  getStandradDateTime,
} from "../../../../../lib/datetime";

class TripSequenceOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: this.props.orders,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  onDragStart(data) {}
  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let { orders } = this.reorder(
      this.state.orders,
      result.source,
      result.destination
    );
    this.props.updateTripSequence(orders);
  }
  reorder(orders, source, destination) {
    let itemToMove = orders.splice(source.index, 1)[0];
    orders.splice(destination.index, 0, itemToMove);
    return { orders };
  }
  render() {
    let { orders } = this.state;
    return (
      <div>
        <h3>Orders</h3>
        <div className="trip-sequence-order-list">
          <div className="order-count-list">
            {orders.map((order, index) => (
              <span key={"order-count-elm" + index}>{index + 1}</span>
            ))}
          </div>
          <DragDropContext
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}
          >
            <Droppable droppableId={`trip-sequence-1`}>
              {(provided, snapshot) => (
                <div
                  className={`trip-orders ${
                    snapshot.isDraggingOver ? "dragging-over-2" : ""
                  }`.trim()}
                >
                  <div
                    className="trip-orders-inner-container"
                    ref={provided.innerRef}
                  >
                    {orders.map((order, index) => (
                      <Draggable
                        draggableId={`order-${order.id}`}
                        index={index}
                        key={order.id}
                      >
                        {(provided, snapshot) => (
                          <div>
                            <div
                              className="order-box-spacer"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                className={`order-box ${
                                  snapshot.isDragging ? "dragging" : ""
                                }`.trim()}
                              >
                                <div className="order-box-reference-status">
                                  <div className="reference-number">
                                    {order.clientId
                                      ? `#${order.clientId}`
                                      : `Z${order.id}`}
                                  </div>
                                  <div className="status">{order.status}</div>
                                </div>
                                <div className="slot-info">
                                  {`${
                                    order.slot &&
                                    getPrintableTime(
                                      getStandradDateTime(
                                        utcToLocalTime,
                                        `${
                                          new Date().toISOString().split("T")[0]
                                        } ${order.slot.split("-")[0].trim()}`
                                      )
                                    ).split(",")[1]
                                  } - ${
                                    getPrintableTime(
                                      getStandradDateTime(
                                        utcToLocalTime,
                                        `${
                                          new Date().toISOString().split("T")[0]
                                        } ${order.slot.split("-")[1].trim()}`
                                      )
                                    ).split(",")[1]
                                  }`}
                                </div>
                              </div>
                            </div>
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  }
}

export default TripSequenceOrders;
