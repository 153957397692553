import React, { Component } from "react";
import Table, { Header, Row, Cell } from "../../../../../components/Table";
import API from "../../../../../lib/api";
import { getMessage } from "../../../../../lib/translator";
import editIcon from "../edit-icon.svg";
import { isExtensionEnabled } from "../../../../../lib/auth";
class PackingDetails extends Component {
  constructor() {
    super();
    this.state = { present: false };
  }
  componentDidMount() {
    const api = new API({ url: "/config-service/config/inStoreProcessing" });
    api
      .get()
      .then((response) => {
        const isInStoreProcessingPresent =
          response.data.inStoreProcessing.packedOrderEditAllowed;
        if (isInStoreProcessingPresent) {
          this.setState({
            present: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const details = this.props.packingDetails;
    const metaData = this.props.packageMetaData;
    const referenceNumber = this.props.referenceNumber;
    return (
      <div className="packing-details">
        <div className="packing-details-header">
          <h3>{getMessage("order.details.packing.heading")}</h3>
          {this.props.status === "PACKED" &&
            (this.props?.courierStatus === "CANCELLED" ||
              this.props?.courierStatus === undefined) &&
            this.state.present &&
            !isExtensionEnabled("InStoreProcessing") && (
              <img
                src={editIcon}
                alt="Edit"
                className="edit-icon"
                onClick={() =>
                  this.props?.history.push(
                    `/orders/pack-order/${referenceNumber}/edit`
                  )
                }
              />
            )}
        </div>
        {details ? (
          <Table>
            <Header>
              <Cell>{getMessage("type.text.label")}</Cell>
              <Cell>{getMessage("order.packing.labels")}</Cell>
              {metaData && <Cell>{getMessage("order.packing.weight")}</Cell>}
            </Header>

            {Object.keys(details).map((type, index) => {
              let packageWeight = 0;
              Array.isArray(details[type])
                ? details[type].forEach((label) => {
                    packageWeight += metaData?.packages[label]?.weight || 0;
                  })
                : (packageWeight +=
                    metaData?.packages[details[type]]?.weight || 0);
              return (
                <Row key={`packing-item-${index}`}>
                  <Cell className="order-package-type">{type}</Cell>
                  <Cell>
                    {(Array.isArray(details[type]) &&
                      details[type].join(", ")) ||
                      details[type]}
                  </Cell>
                  {metaData && <Cell>{packageWeight}</Cell>}
                </Row>
              );
            })}
          </Table>
        ) : (
          <Table>
            <Header>
              <Cell>{getMessage("type.text.label")}</Cell>
              <Cell>{getMessage("order.packing.labels")}</Cell>
              <Cell>{getMessage("order.packing.weight")}</Cell>
            </Header>
            <Row>
              <Cell className="order-package-type">{getMessage("NA")}</Cell>
              <Cell>{getMessage("NA")}</Cell>
              <Cell>{getMessage("NA")}</Cell>
            </Row>
          </Table>
        )}
      </div>
    );
  }
}

export default PackingDetails;
