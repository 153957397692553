import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  ProductSearch,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";

import "./style.css";
import Table, { Header, Row, Cell } from "../../../../components/Table";
import { getProductImage, getProductName } from "../../../../lib/commonlyused";
import Image from "../../../../components/Image";
import DeleteIcon from "./delete.svg";

const ProductList = (props) => {
  const { products, onDelete } = props;
  return (
    <Table>
      {(products || []).length > 0 && (
        <Header>
          <Cell>{getMessage("Image")}</Cell>
          <Cell>{getMessage("Name")}</Cell>
          <Cell />
        </Header>
      )}
      {(products || []).map((product, index) => (
        <Row className="pdt-row" key={product.id}>
          <Cell>
            <Image size="sm" src={getProductImage(product)} />
          </Cell>
          <Cell>{getProductName(product)}</Cell>
          <Cell>
            <button
              className="del-button"
              type="button"
              onClick={() => onDelete(index)}
            >
              <img src={DeleteIcon} alt="delete" />
            </button>
          </Cell>
        </Row>
      ))}
    </Table>
  );
};

export default class SubstitutionGroupForm extends BaseForm {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  delete() {
    this.props.onDelete();
  }

  changeStatus(status) {
    const { value } = this.props;
    if (value && value.id) {
      this.props.onSubmit({ id: value.id, status: status });
    }
  }

  addProduct(product) {
    let values = JSON.parse(JSON.stringify(this.state.values));
    let pdts = (values.products || []).slice();
    if (pdts.filter((p) => p.id === product[0].id).length > 0) {
      return null;
    }
    pdts.push(product[0]);
    values.products = pdts;
    this.setState({
      values,
    });
  }

  onDelete(index) {
    let values = JSON.parse(JSON.stringify(this.state.values));
    let products = values.products;
    products.splice(index, 1);
    values.products = products;
    this.setState({
      values,
    });
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    const { props } = this;
    const { products } = this.state.values;
    return (
      <div className="substitution-groups-form">
        <Form>
          <Input
            label={getMessage("substitutionGroup.form.name.heading")}
            placeholder={getMessage("substitutionGroup.form.name.placeholder")}
            name="name"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
              rangeOverflow: getMessage("input.rangeOverflow"),
              rangeUnderflow: getMessage("input.rangeUnderflow"),
            }}
          />
          <Input
            label={getMessage(
              "substitutionGroup.form.percentageDeviation.heading"
            )}
            placeholder={getMessage(
              "substitutionGroup.form.percentageDeviation.placeholder"
            )}
            name="percentage-deviation"
            type="number"
            required
            min={0}
            max={100}
            {...this.generateStateMappers({
              stateKeys: ["percentageDeviation"],
              formatChange: Number,
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <ProductSearch
            label={"Product"}
            getVariantData={false}
            multiple
            name="products"
            onChange={this.addProduct}
          />
          <ProductList products={products} onDelete={this.onDelete} />
          <div className={`form-actions ${props.method}`}>
            <div className="form-buttons">
              <SubmitButton>
                {getMessage("substitutionGroup.form.submitText")}
              </SubmitButton>
              <CancelButton>
                {getMessage("substitutionGroup.form.cancelText")}
              </CancelButton>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
