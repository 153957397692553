import React from "react";
import { BaseForm, Input, VALIDATION_TYPES } from "../../../components/Form";
import { withRouter } from "react-router-dom";

import { getMessage } from "../../../lib/translator";

import { GO_HOST } from "../../../config/app";

class ResetPasswordForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.values = {
      username: "",
      submitting: false,
    };
    this.state.formError = "";
    this.handleReset = this.handleReset.bind(this);
  }

  handleReset(formData) {
    window
      .fetch(GO_HOST + "/account-service/reset-password", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(formData),
      })
      .then((response) => {
        response.json().then((response) => {
          if (response.status === "SUCCESS") {
            this.setState({ submitting: false });
            this.props.onSuccess && this.props.onSuccess();
          } else {
            this.setState({
              submitting: false,
              formError: response.message.split(":")[1],
            });
          }
        });
      })
      .catch((error) => {
        this.setState({
          submitting: false,
          formError: getMessage("error.server"),
        });
        console.error(error)
      });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleReset(data);
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        <Form>
          <Input
            label={getMessage("login.email.heading")}
            placeholder={getMessage("login.email.placeholder")}
            name="username"
            type="email"
            required
            {...this.generateStateMappers({
              stateKeys: ["username"],
              validationType: VALIDATION_TYPES.ONBLUR,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
              typeMismatch: getMessage("input.invalidEmailFormat"),
            }}
          />
          <div className="form-buttons-container">
            <SubmitButton
              disabled={this.state.submitting || !this.state.values.username}
            >
              {getMessage("resetPassword.buttonText")}
            </SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(ResetPasswordForm);
