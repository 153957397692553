import React, { Component } from "react";
import ListingPage, { TABLE_ACTIONS } from "../../../../containers/ListingPage";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import Tabs from "../../../../components/Tabs";
import { Row, Cell } from "../../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../../components/DropDown";
import SlotForm from "./Form";
import Slots from "../../../settings/Extensions/ExtensionDetailsForms/Slots";
// import StoreFilter from './StoreFilter'
import moment from 'moment'
import {
  getPrintableTime,
  getStandradDateTime,
  localTimeToUtc,
  utcToLocalTime,
} from "../../../../lib/datetime";
import { getMessage } from "../../../../lib/translator";
import { sortSlots } from "../../../../lib/commonlyused";

import EmptyIcon from "./empty.svg"; // TODO: Change icon

// Converts 01:00 PM to 13:00:00. Military format
const convertToMilitaryTime = (time, meridian) => {
  if (!time || !meridian) {
    return null;
  }
  return moment(`${time} ${meridian}`, ["h:mm A"]).format("HH:mm:ss");
}


const emptyState = {
  icon: EmptyIcon,
  message: getMessage("slots.emptymessage"),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD);
      }}
    >
      + {getMessage("slot.add.text")}
    </button>
  ),
};

const tableProperties = {
  row: ({ id, startTime, endTime, onAction }) => (
    <Row>
      <Cell className="slot-time">
        {startTime
          ? getPrintableTime(
              getStandradDateTime(
                utcToLocalTime,
                `${new Date().toISOString().split("T")[0]} ${startTime}`
              )
            ).split(",")[1] +
            " -" +
            getPrintableTime(
              getStandradDateTime(
                utcToLocalTime,
                `${new Date().toISOString().split("T")[0]} ${endTime}`
              )
            ).split(",")[1]
          : getMessage("slots.asap") +
            " " +
            getMinutes(endTime) +
            " " +
            getMessage("slots.asap.minute")}
      </Cell>
      <Cell>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem
            onClick={() => {
              onAction(TABLE_ACTIONS.DELETE, { id });
            }}
          >
            {getMessage("slots.delete")}
          </DropDownItem>
        </DropDown>
      </Cell>
    </Row>
  ),
};

function getMinutes(his) {
  var a = his.split(":");
  return +a[0] * 60 + +a[1];
}

class SlotConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeId: props.storeId,
    };
  }

  componentWillReceiveProps(newProps) {
    let storeId = newProps.storeId;
    if (this.state.storeId !== storeId) {
      this.setState({
        storeId,
      });
    }
  }

  render() {
    const props = this.props;
    let params = {};
    params.orderType = props.entity && props.entity.toUpperCase();
    if (this.state.storeId) {
      params.storeId = Number(this.state.storeId);
    }
    return (
      <ListingPage
        addHeading={getMessage("slots.addHeading")}
        api={{
          url: "/order-service/slot",
          transform: (response) => sortSlots(response.data.slot),
          params: params,
          updateApiParams: (prevParams, newParams) => {
            if (
              prevParams.orderType !== newParams.orderType ||
              prevParams.storeId !== newParams.storeId
            ) {
              let storeId = newParams.storeId;
              if (!Number(newParams.storeId)) {
                storeId = null;
              }
              return {
                shouldUpdate: true,
                params: {
                  orderType: newParams.orderType,
                  storeId: storeId,
                },
              };
            }
          },
        }}
        modalClassName="slot-rules-page"
        className="slot-rules-page"
        emptyState={emptyState}
        tableProperties={tableProperties}
        form={{
          component: SlotForm,
          transformSubmit: (formData) => {
            let slots = Object.assign({}, formData);
            slots.startTime = getStandradDateTime(
              localTimeToUtc,
              `${new Date().toISOString().split("T")[0]} ${slots.startTime}`
            ).split(" ")[1];
            slots.endTime = getStandradDateTime(
              localTimeToUtc,
              `${new Date().toISOString().split("T")[0]} ${slots.endTime}`
            ).split(" ")[1];
            if (slots.type === "ASAP") {
              delete slots.startTime;
              let h = Math.floor(slots.asap / 60);
              let m = slots.asap % 60;
              h = h < 10 ? "0" + h : h;
              m = m < 10 ? "0" + m : m;
              slots.endTime = `${h}:${m}:00`;
            }
            delete slots.asap;
            return slots;
          },
        }}
        additionalViewsBottom={[Slots]}
        addToTable={({ onAction }) => (
          <button
            className="primary button"
            onClick={() => {
              onAction(TABLE_ACTIONS.ADD);
            }}
          >
            + <span className="text">{getMessage("slot.add.text")}</span>
          </button>
        )}
        addNewItemToLast
      />
    );
  }
}

const delivery = [
  {
    name: "Delivery",
    component: SlotConfiguration,
  },
];

const pickup = [
  {
    name: "Pickup",
    component: SlotConfiguration,
  },
];

const entities = delivery.concat(pickup);

class InterfaceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      storeId: null,
      entity: "delivery",
    };
    this.mapping = ["delivery", "pickup"];
    this.changeTab = this.changeTab.bind(this);
    this.updateStoreId = this.updateStoreId.bind(this);
  }

  changeTab(index) {
    this.setState({
      index,
      entity: this.mapping[index],
    });
  }

  updateStoreId(storeId) {
    this.setState({
      storeId,
    });
  }

  render() {
    const { index, entity, storeId } = this.state;
    let ListingComponent = entities[index].component;
    return (
      <div className="slot-page">
        <h1>{getMessage("slots.heading")}</h1>
        {/* <StoreFilter updateStoreId={this.updateStoreId} /> */}
        <div className="tabs-container">
          <Tabs
            items={entities.map((entity) => `${entity.name}`)}
            default={index}
            onClick={(index) => {
              this.changeTab(index);
            }}
          />
          <div className="tab-contents">
            <ListingComponent entity={entity} storeId={storeId} />
          </div>
        </div>
      </div>
    );
  }
}

class SlotsContainer extends Component {
  render() {
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <InterfaceContainer />
      </AuthenticatedPage>
    );
  }
}

export default SlotsContainer;
export { convertToMilitaryTime, getMinutes };
