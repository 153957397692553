import React from "react";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import { getMessage } from "../../../../lib/translator";
import { Helmet } from "react-helmet";
import { ProductVariantForm } from "./form";
import "./styles.css";

export default function ProductVariantSupport(props) {
  return (
    <AuthenticatedPage
      className="product-variant-support"
      menu={props.menu}
      notShowHelmet={false}
      title={getMessage("menu.item.product-variant-support")}
    >
      <Helmet title="Zopping - Product Variant Support" />
      <h1 className="title">
        {getMessage("menu.item.product-variant-support")}
      </h1>
      <ProductVariantForm />
    </AuthenticatedPage>
  );
}
