import React, { Component } from "react";
import { Row, Cell } from "../../../components/Table";
import { getMessage } from "../../../lib/translator";
import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import API from "../../../lib/api";
import {
  isExtensionEnabled,
  getSession,
  getExtensionDetails,
} from "../../../lib/auth";
import BannerEditForm from "./Forms";
import BannerFilters from "./Filters";
import bannersEmpty from "./images/banners_empty.svg";
import imagePlaceholder from "./images/image-placeholder.svg";
import moment from "moment";

import "./style.css";
import DraggableListingPage from "../DraggableListingPage";
import {
  formatDate,
  formatTime,
  localTimeToUtc,
  utcToLocalTime,
  getStandradDateTime,
} from "../../../lib/datetime";
import { getLanguages } from "../../../lib/commonlyused";
import { get } from "../../../lib/storage";
import { IS_STAGING } from "../../../config/app";
const eng = { name: "English", code: "en" };

const emptyState = {
  icon: bannersEmpty,
  message: getMessage("banners.empty.banner"),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD);
      }}
    >
      + {getMessage("banners.view.add.button")}
    </button>
  ),
};

const tableProperties = {
  headers: [
    getMessage("banners.header.image"),
    getMessage("banners.header.actions"),
  ],
  row: ({
    imageUrl,
    id,
    onAction,
    startTime,
    endTime,
    scope,
    linkUrl,
    storeId,
  }) => (
    <Row>
      <Cell className="banner-image">
        <img
          className="image-size"
          src={imageUrl || imagePlaceholder}
          alt="banner_image"
        />
        {linkUrl && (
          <small className="view-on-web">
            <a
              href={`${IS_STAGING ? "http://" : "https://"}${
                getSession().organization.domain
              }${linkUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${getMessage("banners.table.view.on.web.url")}`}
            </a>
          </small>
        )}
        <div className="banner-image-fields">
          <div>
            <span>{getMessage("banners.table.validity")}</span>
            {startTime && endTime ? (
              <small>
                {" "}
                {`${formatDate(startTime.split(" ")[0])}, ${formatTime(
                  startTime.split(" ")[1]
                )} - ${formatDate(endTime.split(" ")[0])}, ${formatTime(
                  endTime.split(" ")[1]
                )}`}
              </small>
            ) : (
              getMessage("banners.table.na")
            )}
          </div>
          {scope && (
            <div className="scope">
              {scope && <span>{getMessage("banners.table.scope")}</span>}
              {scope === "STORE"
                ? window.localStorage.stores &&
                  JSON.parse(window.localStorage.stores)
                    .map((store) => {
                      if (storeId.includes(store.id)) {
                        return store.name;
                      }
                      return null;
                    })
                    .filter((name) => Boolean(name))
                    .join(", ")
                : "Global"}
            </div>
          )}
        </div>
      </Cell>
      <Cell className="table-action">
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem
            onClick={() => {
              onAction(TABLE_ACTIONS.EDIT, { id });
            }}
          >
            {getMessage("banners.action.edit")}
          </DropDownItem>
          <DropDownItem onClick={() => onAction(TABLE_ACTIONS.DELETE, { id })}>
            {getMessage("banners.action.delete")}
          </DropDownItem>
        </DropDown>
      </Cell>
    </Row>
  ),
};

class ManageBanners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [
        { text: getMessage("banners.pageType.home"), value: "HOME" },
        { text: getMessage("banners.pageType.category"), value: "CATEGORY" },
      ],
      pageTypes: {
        home: "HOME",
        category: "CATEGORY",
        brand: "BRAND",
        productTag: "PRODUCT_TAG",
        search: "SEARCH",
      },
      scopes: {
        global: "GLOBAL",
        storeSpecific: "STORE",
      },
    };
  }

  componentDidMount() {
    const productTagEnabled = isExtensionEnabled("ProductTagSupport");
    const brandsEnabled = isExtensionEnabled("MultiBrandSupport");
    const multiStoreEnabled = isExtensionEnabled("MultiStoreSupport");

    this.setState({ productTagEnabled, brandsEnabled, multiStoreEnabled });

    if (multiStoreEnabled) {
      this.storesApi = new API({ url: "/account-service/store" });
      this.setState({
        stores: JSON.parse(get("stores")),
      });
    }
    if (productTagEnabled) {
      let { pages } = this.state;
      pages.push({
        text: getMessage("banners.pageType.productTag"),
        value: "PRODUCT_TAG",
      });
      this.setState({ pages });
    }
    if (brandsEnabled) {
      let { pages } = this.state;
      pages.push({
        text: getMessage("banners.pageType.brand"),
        value: "BRAND",
      });
      this.setState({ pages });
    }
    let { pages } = this.state;
    pages.push({
      text: getMessage("banners.pageType.search"),
      value: "SEARCH",
    });
    this.setState({ pages });
    const extension = getExtensionDetails("MultiLingualSupport");
    const id = extension && extension.id;
    let availableLangs = [eng];
    if (id) {
      const api = new API({ url: `/account-service/extension/${id}` });
      api
        .get()
        .then((response) => {
          let savedLangs =
            response.data.extension.config.globalConfig.languages;
          savedLangs &&
            savedLangs.map((lng, i) => {
              let language = getLanguages().find((lang) => lang.code === lng);
              if (language && language.name !== "english") {
                availableLangs.push({ ...language });
              }
              return null;
            });
          this.setState({ availableLangs });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  render() {
    let { menu } = this.props;
    let {
      stores,
      pages,
      multiStoreEnabled,
      productTagEnabled,
      brandsEnabled,
      pageTypes,
      scopes,
      availableLangs,
    } = this.state;
    return (
      <div>
        <DraggableListingPage
          menu={menu}
          className="manage-banners draggable-listing-page"
          title={getMessage("banners.header")}
          api={{
            url: "/promo-service/banner",
            params: { pageType: pageTypes.home },
            transform: (response) => {
              let bannerData = response.data.banner;
              if (Array.isArray(bannerData)) {
                bannerData = bannerData.map((banner) => {
                  let url = [...banner.url];
                  switch (banner.pageType) {
                    case pageTypes.category:
                      banner.categoryUrl = [...url];
                      break;
                    case pageTypes.brand:
                      banner.brandUrl = [...url];
                      break;
                    case pageTypes.productTag:
                      banner.productTagUrl = [...url];
                      break;
                    case pageTypes.search:
                      banner.searchUrl = [...url];
                      break;
                    default:
                      break;
                  }
                  if (banner.startTime) {
                    banner.startTime = getStandradDateTime(
                      utcToLocalTime,
                      banner.startTime
                    );
                  }

                  if (banner.endTime) {
                    banner.endTime = getStandradDateTime(
                      utcToLocalTime,
                      banner.endTime
                    );
                  }
                  return banner;
                });
              }
              if (bannerData.startTime) {
                bannerData.startTime = getStandradDateTime(
                  utcToLocalTime,
                  bannerData.startTime
                );
              }

              if (bannerData.endTime) {
                bannerData.endTime = getStandradDateTime(
                  utcToLocalTime,
                  bannerData.endTime
                );
              }
              return bannerData;
            },
          }}
          editHeading={getMessage("banners.editForm.header")}
          addHeading={getMessage("banners.addForm.header")}
          tableProperties={tableProperties}
          modalClassName={"manage-banners-popup"}
          form={{
            component: BannerEditForm,
            overwriteWithApiParams: false,
            allowDelete: true,
            options: {
              multiStoreEnabled: multiStoreEnabled,
              stores: stores,
              pages: pages,
              pageTypes: pageTypes,
              scopes: scopes,
              productTagEnabled: productTagEnabled,
              brandsEnabled: brandsEnabled,
              availableLangs: availableLangs,
            },
            filterBeforeAdding: (response, _this) => {
              if (
                _this &&
                _this.state &&
                _this.state.data &&
                Object.keys(_this.state.data.filters).length === 0
              ) {
                return response;
              }
              if (
                _this &&
                _this.state &&
                _this.state.data &&
                _this.state.data.filters &&
                _this.state.data.filters.pageType === response.pageType
              ) {
                return response;
              }
              if (
                _this &&
                _this.state &&
                _this.state.data &&
                _this.state.data.filters.storeId
              ) {
                return response;
              }
              return null;
            },
            transformSubmit: (formData) => {
              let params = Object.assign({}, formData);
              let url = [];
              params.url = url;
              params.linkUrl = "/" + params.linkUrl;
              if (params.pageType && params.pageType === pageTypes.category) {
                url =
                  params.categoryUrl &&
                  params.categoryUrl.map((obj) => {
                    let value = obj.slug || obj;
                    return value;
                  });
                delete params.categoryUrl;
              } else if (
                params.pageType &&
                params.pageType === pageTypes.brand
              ) {
                url =
                  params.brandUrl &&
                  params.brandUrl.map((obj) => {
                    let value = obj.slug || obj;
                    return value;
                  });
                delete params.brandUrl;
              } else if (
                params.pageType &&
                params.pageType === pageTypes.productTag
              ) {
                url =
                  params.productTagUrl &&
                  params.productTagUrl.map((obj) => {
                    let value = obj.slug || obj;
                    return value;
                  });
                delete params.productTagUrl;
              } else if (
                params.pageType &&
                params.pageType === pageTypes.search
              ) {
                url =
                  params.searchUrl &&
                  params.searchUrl.map((value) => {
                    return value;
                  });
                delete params.searchUrl;
              }
              params.url = (url && [...url]) || [];

              if (
                params.pageType &&
                params.pageType === pageTypes.search &&
                params.searchTerm &&
                params.url
              ) {
                url = [...params.url];
                params.url.push(...url);
              }

              if (
                params.pageType === pageTypes.home ||
                params.pageType === undefined
              ) {
                delete params.url;
              }

              if (moment(params.startTime).isSame(Date.now(), "day")) {
                if (params.startTime) {
                  let startDate =
                    params.startTime.split(" ") || params.startTime;
                  if (startDate[1]) {
                    params.startTime = startDate[0] + " ";
                    params.startTime += params.bannerStartTime
                      ? params.bannerStartTime + ":00"
                      : startDate[1];
                  } else {
                    params.startTime = params.startTime + " ";
                    params.startTime += params.bannerStartTime
                      ? params.bannerStartTime + ":00"
                      : "00:00:00";
                  }
                }
                let isBefore = new Date() > new Date(params.startTime);
                if (isBefore) {
                  params.startTime =
                    params.startTime || moment().format("YYYY-MM-DD HH:mm:ss");
                }
                params.startTime = getStandradDateTime(
                  localTimeToUtc,
                  params.startTime
                );
              } else {
                if (params.startTime) {
                  let startDate =
                    params.startTime.split(" ") || params.startTime;
                  if (startDate[1]) {
                    params.startTime = startDate[0] + " ";
                    params.startTime += params.bannerStartTime
                      ? params.bannerStartTime + ":00"
                      : startDate[1];
                  } else {
                    params.startTime = params.startTime + " ";
                    params.startTime += params.bannerStartTime
                      ? params.bannerStartTime + ":00"
                      : "00:00:00";
                  }
                  params.startTime = getStandradDateTime(
                    localTimeToUtc,
                    params.startTime
                  );
                }
              }

              if (params.endTime) {
                let endDate = params.endTime.split(" ") || params.endTime;
                if (endDate[1]) {
                  params.endTime = endDate[0] + " ";
                  params.endTime += params.bannerEndTime
                    ? params.bannerEndTime + ":00"
                    : endDate[1];
                } else {
                  params.endTime = params.endTime + " ";
                  params.endTime += params.bannerEndTime
                    ? params.bannerEndTime + ":00"
                    : "00:00:00";
                }
                params.endTime = getStandradDateTime(
                  localTimeToUtc,
                  params.endTime
                );
              }

              if (params.bannerStartTime) {
                delete params.bannerStartTime;
              }

              if (params.bannerEndTime) {
                delete params.bannerEndTime;
              }

              if (params.startTime === null) {
                delete params.startTime;
              }

              if (params.endTime === null) {
                delete params.endTime;
              }

              params.sequence = params.sequence ? params.sequence : 1;
              params.scope = params.scope ? params.scope : scopes.global;
              if (params.scope === scopes.global) {
                params.storeId = [];
              }
              return params;
            },
          }}
          emptyState={emptyState}
          filters={{
            component: BannerFilters,
            forceShow: true,
            options: {
              multiStoreEnabled: multiStoreEnabled,
              stores: stores,
              pages: pages,
              availableLangs: availableLangs,
            },
            transformSubmit: (formData) => {
              let data = Object.assign({}, formData);
              let { pageType } = data;
              data.url = data[pageType] && data[pageType].slug;
              if (pageType === "SEARCH") data.url = data["SEARCH_TERM"];
              delete data["CATEGORY"];
              delete data["BRAND"];
              delete data["PRODUCT_TAG"];
              delete data["SEARCH_TERM"];
              return data;
            },
          }}
          headerActions={({ onAction }) => (
            <button
              className="primary button"
              onClick={() => {
                onAction(TABLE_ACTIONS.ADD);
              }}
            >
              + <span>{getMessage("banners.view.add.button")}</span>
            </button>
          )}
        />
      </div>
    );
  }
}

export default ManageBanners;
