import React, { useState, Component } from "react";
import API from "../../../../lib/api";
import { getMessage } from "../../../../lib/translator";
// import { getMessage } from "../../../lib/translator";
import { Dialog } from "../../../../components/Popup";
import "./style.css";

const requiredKeyArray = [
  "type",
  "token_uri",
  "project_id",
  "private_key_id",
  "private_key",
  "client_x509_cert_url",
  "client_id",
  "client_email",
  "auth_uri",
  "auth_provider_x509_cert_url",
];

class JsonFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: false,
      showSuccessDialog: false,
      showErrorDialog: false,
    };
  }

  hasAllKeys = (obj, keys) => {
    for (let i = 0; i < keys.length; i++) {
      if (!obj.hasOwnProperty(keys[i])) {
        return false;
      }
    }
    return true;
  };

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = JSON.parse(e.target.result);
      const dataValidation = this.hasAllKeys(data, requiredKeyArray);
      if (dataValidation) {
        this.props.setFileData(data);
        const api = new API({ url: `/config-service/config` });
        return api
          .post({
            googleMerchantCenter: {
              contentAPICredentials: data,
            },
          })
          .then(() => {
            this.setState({
              file: true,
              showSuccessDialog: true,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.setState({
          showErrorDialog: true,
        });
      }
    };
    reader.readAsText(file);
  };

  closeDialogs = () => {
    this.setState({
      showErrorDialog: false,
      showSuccessDialog: false,
    });
  };

  render() {
    const { title, label } = this.props;
    return (
      <span>
        <span className="title">{title}</span>
        <input
          type="file"
          accept=".json"
          id="only_json"
          name="only_json"
          onChange={(e) => this.handleFileUpload(e)}
        />
        <label for="only_json">{label}</label>
        {(this.props.showFileName || this.state.file) && (
          <span className="file-name">content-api-key.json</span>
        )}
        <Dialog
          show={this.state.showErrorDialog}
          title={getMessage("google.merchant.center.error.title")}
          information={getMessage("google.merchant.center.error.msg")}
          close={this.closeDialogs}
          closeText={getMessage("okay.text")}
        />
        <Dialog
          show={this.state.showSuccessDialog}
          title={getMessage("google.merchant.center.success.title")}
          className="success"
          information={getMessage("google.merchant.center.success.msg")}
          close={this.closeDialogs}
          closeText={getMessage("okay.text")}
        />
      </span>
    );
  }
}

export default function GoogleMerchantForm(props) {
  const [fileData, setFileData] = useState(null);
  const { showSuccess } = props;

  return (
    <div className="google-merchant-center-main">
      {(showSuccess || fileData) && (
        <p className="success-msg">
          {getMessage("google.merchant.center.success.description")}
        </p>
      )}
      <JsonFileUpload
        title={
          showSuccess
            ? getMessage("google.merchant.center.upload.change.label")
            : getMessage("google.merchant.center.upload.label")
        }
        label="Upload"
        setFileData={setFileData}
        showFileName={showSuccess}
      />
      <p className="description">
        {getMessage("google.merchant.center.description")}
      </p>
    </div>
  );
}
