import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  SingleDatePicker,
  Radio,
} from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";

export default class CustomerFilters extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage("customer.enterCustomerEmail")}
            placeholder={getMessage("customer.searchByEmail")}
            name="email"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["email"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisonFill: true,
            })}
          />
          <Input
            label={getMessage("customer.enterCustomerPhone")}
            placeholder={getMessage("customer.searchByPhone")}
            name="phone"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["phone"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisonFill: true,
            })}
          />
          <Input
            label={getMessage("customer.enterClientId")}
            placeholder={getMessage("customer.searchByClientId")}
            name="clientId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["clientId"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisonFill: true,
            })}
          />
          <SingleDatePicker
            label={getMessage("customer.joinedOn")}
            placeholder={getMessage("cutomer.joinedOn.placeholder")}
            name="joined-on"
            {...this.generateStateMappers({
              stateKeys: ["joinedOn"],
              loseEmphasisonFill: true,
            })}
            isOutsideRange
          />
          <SingleDatePicker
            label={getMessage("customer.updatedAt")}
            name="updated-at"
            placeholder={getMessage("customer.updatedAt")}
            {...this.generateStateMappers({
              stateKeys: ["updatedAt"],
              loseEmphasisonFill: true,
            })}
            isOutsideRange
          />
          <Radio
            className="customer-status"
            label={getMessage("status")}
            name="status"
            options={[
              {
                text: getMessage("all.label.text"),
                value: "",
              },
              {
                text: getMessage("status.label.enabled"),
                value: "ENABLED",
              },
              {
                text: getMessage("disabled.text"),
                value: "DISABLED",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              defaultValue: "",
            })}
          />
          <Radio
            className="customer-status"
            label="Verification Status"
            name="hasLoggedIn"
            options={[
              {
                text: getMessage("all.label.text"),
                value: "",
              },
              {
                text: getMessage("verified.label.text"),
                value: "true",
              },
              {
                text: getMessage("not.verified.label.text"),
                value: "false",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["hasLoggedIn"],
              defaultValue: "",
            })}
          />
        </div>
        <SubmitButton>{getMessage("customer.filters.submittext")}</SubmitButton>
        <ClearButton>
          {getMessage("customer.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
