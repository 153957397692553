import React from "react";
import {
  BaseForm,
  Input,
  Radio,
  VALIDATION_TYPES,
  Textarea,
  CheckboxGroup,
  DateRangePicker,
  Select,
} from "../../../../components/Form";
import SelectStoresSearch from "../../../../components/SelectStoresSearch";
import { getMessage } from "../../../../lib/translator";
import { getCurrency } from "../../../../lib/userDetails";
import SegmentsForm from "../../../../components/SegmentsForm";
import { slotTimes } from "../../../../lib/datetime";
import "./style.css";
import moment from "moment";

function checkForNegativeValue(value) {
  if (value && value < 0) {
    return getMessage("input.invalidNumber");
  }
  return "";
}

export default class CouponForm extends BaseForm {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state.values.type = (value && value.type) || "PERCENT";
    this.state.values.discountType =
      (value && value.discountType) || "DIRECT_DISCOUNT";
    this.state.values.validOnDays = (value && value.validOnDays) || [
      "MON",
      "TUE",
      "WED",
      "THU",
      "FRI",
      "SAT",
      "SUN",
    ];
    this.state.values.userSet = (value && value.userSet) || { data: [] };
    this.state.tagsLoaded = true;
  }

  timeRangeDiable = (value) => {
    if (this.state?.values?.dateRange?.startDate) {
      if (
        `${this.state.values.dateRange.startDate} ${value}` <
        `${moment().format("YYYY-MM-DD HH:mm:ss")}`
      ) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    const { options } = this.props;
    const multiStores = options && options.multiStores;
    const bulk = options && options.bulk;
    const {
      discount,
      maxSingleRedemption,
      maxRedemption,
      minOrderAmount,
      maxDiscountAmount,
    } = this.state.values;
    const negDiscountErr = checkForNegativeValue(discount);
    const negSingleRedemptionErr = checkForNegativeValue(maxSingleRedemption);
    const negRedemptionErr = checkForNegativeValue(maxRedemption);
    const negOrderAmountErr = checkForNegativeValue(minOrderAmount);
    const negMaxDiscountErr = checkForNegativeValue(maxDiscountAmount);
    return (
      <div className="coupon-form-container">
        <Form>
          <div className="coupon-form">
            <div className="coupon-section">
              {bulk ? (
                <Input
                  className="coupon-code"
                  label={getMessage("Number of coupons")}
                  placeholder={getMessage("Enter number of coupons")}
                  name="number-of-coupons"
                  type="number"
                  required
                  {...this.generateStateMappers({
                    stateKeys: ["numberOfCoupons"],
                    loseEmphasisOnFill: true,
                  })}
                />
              ) : (
                <Input
                  className="coupon-code"
                  label={getMessage("coupon.form.name.heading")}
                  placeholder={getMessage("coupon.form.name.placeholder")}
                  name="couponCode"
                  type="text"
                  required
                  maxLength={15}
                  {...this.generateStateMappers({
                    stateKeys: ["couponCode"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  validationStrings={{
                    valueMissing: getMessage("input.requiredMessage"),
                  }}
                />
              )}
            </div>
            <div className="coupon-section">
              <DateRangePicker
                label={getMessage("coupon.form.dateRange.heading")}
                {...this.generateStateMappers({
                  stateKeys: ["dateRange"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                displayFormat="YYYY-MM-DD"
                required
                startDatePlaceholder={getMessage(
                  "coupon.form.startDate.placeholder"
                )}
                endDatePlaceholder={getMessage(
                  "coupon.form.endDate.placeholder"
                )}
              />
            </div>
            <div className="coupon-section">
              <Select
                label={getMessage("coupon.header.validity.time.start")}
                name="couponStartTime"
                className="coupon-code"
                placeholder={getMessage("coupon.header.validity.time.start")}
                options={slotTimes()}
                handleOptionDisable={this.timeRangeDiable}
                {...this.generateStateMappers({
                  stateKeys: ["timeRange", "startTime"],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <div className="coupon-section">
              <Select
                className="coupon-code"
                label={getMessage("coupon.header.validity.time.end")}
                name="couponEndTime"
                placeholder={getMessage("coupon.header.validity.time.end")}
                options={slotTimes()}
                {...this.generateStateMappers({
                  stateKeys: ["timeRange", "endTime"],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            {bulk && (
              <div className="coupon-section">
                <Input
                  className="coupon-code"
                  label={getMessage("prefix")}
                  placeholder={getMessage("Enter prefix")}
                  name="prefix"
                  required
                  {...this.generateStateMappers({
                    stateKeys: ["prefix"],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            )}
            {bulk && (
              <div className="coupon-section">
                <Input
                  className="coupon-code"
                  label={getMessage("suffix")}
                  placeholder={getMessage("Enter suffix")}
                  name="suffix"
                  required
                  {...this.generateStateMappers({
                    stateKeys: ["suffix"],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            )}
            <div className="coupon-section discount">
              <Input
                label={getMessage("coupon.form.discount.heading")}
                className="coupon-discount"
                placeholder="0"
                name="discount"
                type="number"
                error={negDiscountErr}
                siblings={{
                  after: [
                    <div key="discount-type" className="discount-select">
                      <Select
                        name="type"
                        options={[
                          {
                            text: "%",
                            value: "PERCENT",
                          },
                          {
                            text: getCurrency().symbol,
                            value: "CASH",
                          },
                        ]}
                        {...this.generateStateMappers({
                          stateKeys: ["type"],
                        })}
                      />
                    </div>,
                  ],
                }}
                required
                {...this.generateStateMappers({
                  stateKeys: ["discount"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <div className="coupon-section">
              <Radio
                label={getMessage("Coupon applicable for")}
                name="couponType"
                className="couponType"
                required
                options={[
                  {
                    text: getMessage("Order"),
                    value: "ORDER",
                  },
                  {
                    text: getMessage("Shipping"),
                    value: "SHIPPING",
                  },
                ]}
                {...this.generateStateMappers({
                  stateKeys: ["couponType"],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>

            <div className="coupon-section comment">
              <Textarea
                label={getMessage("coupon.form.comment.heading")}
                className="coupon-comment"
                name="comment"
                {...this.generateStateMappers({
                  stateKeys: ["comment"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              >
                {getMessage("coupon.form.comment.description")}
              </Textarea>
            </div>
            {/* Limit section */}
            <div className="coupon-section single-user-redeption">
              <div className="subheading">
                {getMessage("coupon.form.limits.heading")}
              </div>
              {!bulk && (
                <Input
                  label={getMessage("coupon.form.maxSingleRedemption.heading")}
                  placeholder="0"
                  className="single-max-redemption"
                  name="maxSingleRedemption"
                  type="number"
                  error={negSingleRedemptionErr}
                  {...this.generateStateMappers({
                    stateKeys: ["maxSingleRedemption"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              )}
            </div>
            {!bulk && (
              <div className="coupon-section max-redeption">
                <Input
                  label={getMessage("coupon.form.maxRedemption.heading")}
                  placeholder="0"
                  name="maxRedemption"
                  type="number"
                  error={negRedemptionErr}
                  {...this.generateStateMappers({
                    stateKeys: ["maxRedemption"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            )}
            <div className="coupon-section dicount-amount">
              <Input
                label={getMessage("coupon.form.maxDiscountAmount.heading")}
                placeholder="0"
                name="maxDiscountAmount"
                type="number"
                error={negMaxDiscountErr}
                {...this.generateStateMappers({
                  stateKeys: ["maxDiscountAmount"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <div className="coupon-section condition">
              {/* Conditions section */}
              <div className="subheading">
                {getMessage("coupon.form.conditions.heading")}
              </div>
              <div>
                <Input
                  label={getMessage("coupon.form.minOrderAmount.heading")}
                  placeholder="0"
                  className="coupon-minorder-amount"
                  name="minOrderAmount"
                  type="number"
                  error={negOrderAmountErr}
                  required
                  {...this.generateStateMappers({
                    stateKeys: ["minOrderAmount"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
              <div className="coupon-section payment-modes">
                <Radio
                  label={getMessage("coupon.form.validOnPayment.heading")}
                  name="validOnPayment"
                  className="payment-modes"
                  options={[
                    {
                      text: getMessage("coupon.form.allPayments"),
                      value: "ALL",
                    },
                    {
                      text: getMessage("coupon.form.onlinePayments"),
                      value: "ONLINE",
                    },
                    {
                      text: getMessage("coupon.form.codPayments"),
                      value: "COD",
                    },
                  ]}
                  {...this.generateStateMappers({
                    stateKeys: ["validOnPayment"],
                  })}
                />
              </div>
              <div className="coupon-section">
                <CheckboxGroup
                  label={getMessage("coupon.form.days.heading")}
                  name="validOnDays"
                  options={[
                    {
                      text: "Mon",
                      value: "MON",
                    },
                    {
                      text: "Tue",
                      value: "TUE",
                    },
                    {
                      text: "Wed",
                      value: "WED",
                    },
                    {
                      text: "Thu",
                      value: "THU",
                    },
                    {
                      text: "Fri",
                      value: "FRI",
                    },
                    {
                      text: "Sat",
                      value: "SAT",
                    },
                    {
                      text: "Sun",
                      value: "SUN",
                    },
                  ]}
                  {...this.generateStateMappers({
                    stateKeys: ["validOnDays"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                />
              </div>
            </div>
            {/* only if multi store extension is enabled and store has multiple store */}
            {multiStores &&
              multiStores.isEnabled &&
              multiStores.data.length > 1 && (
                <div className="coupon-section valid-stores">
                  <SelectStoresSearch
                    label={getMessage("coupon.form.stores.heading")}
                    placeholder={getMessage("coupon.form.stores.placeholder")}
                    _this={this}
                    stores={multiStores.data}
                    stateKey="validOnStores"
                    required
                  />
                </div>
              )}
            <SegmentsForm _this={this} />
          </div>
          <div className="form-buttons-container">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage("coupon.form.submit.text")}
            </SubmitButton>
            <CancelButton disabled={this.state.submitting}>
              {getMessage("coupon.form.cancel.text")}
            </CancelButton>
          </div>
        </Form>
      </div>
    );
  }
}
