const data = {
  "deleteDialog.title": "Are you sure?",
  "deleteDialog.information": "This action cannot be reverted",

  "dialog.okText": "Ok",
  "dialog.cancelText": "Cancel",

  "errorDialog.title": "Oops!",
  "errorDialog.delete.error.title": "Can not delete",

  "pagination.text": "Showing",
  "pagination.helperText": "of",

  "successDialog.title": "Success",
  "successDialog.information": "Changes successfully saved",
};

export default data;
