import React from "react";
import { BaseForm, Input } from "../../../../components/Form";
import Dialog from "../../../../components/Popup/Dialog";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import Loader from "../../../../components/Loader";
import Table, { Row, Cell, Header } from "../../../../components/Table";
import { Popup } from "../../../../components/Popup";
import API from "../../../../lib/api";
import { getMessage } from "../../../../lib/translator";
import { getSession } from "../../../../lib/auth";
import "./style.css";
import { get } from "../../../../lib/storage";

class RegisterOrderPayment extends BaseForm {
  componentDidMount() {
    this.setState({
      loading: true,
      showErrorDialog: false,
      showCompletePopup: false,
      packingDetails: [],
    });
    let searchParams = new URL(window.location).searchParams;
    const pendingAmount = searchParams.get("pendingAmount");
    const clientId = searchParams.get("clientId");
    const orderType = searchParams.get("type");
    this.setState({
      pendingAmount,
      clientId,
    });
    const storeId = get("store");
    const configApi = new API({
      url: `/config-service/config/order?storeId=${storeId}`,
    });
    configApi.get().then((response) => {
      let disabledModes = response.data.order.finCloseDisabledPaymentModes;
      disabledModes = disabledModes || [];
      const paymentModes = response.data.order.paymentMethods?.[
        orderType
      ].filter((mode) => disabledModes.indexOf(mode) === -1);
      if (paymentModes && !paymentModes.length) {
        const api = new API({
          url: `/order-service/order/${this.props.match.params.id}`,
        });
        api
          .put({ status: "COMPLETED" })
          .then((response) => {
            this.setState(
              {
                submitting: false,
              },
              () => this.props.history.goBack()
            );
          })
          .catch((error) => {
            console.error(error);
            if (error.code === 401) {
              throw error;
            } else {
              this.setState({
                submitting: false,
                loading: false,
                errorMessage:
                  (error && error.message) || "Please try again later",
                showErrorDialog: true,
              });
            }
          });
      } else {
        this.setState({
          loading: false,
          paymentModes,
        });
      }
    });
    if (!pendingAmount) {
      const orderApi = new API({
        url: `/order-service/order/${this.props.match.params.id}`,
      });
      orderApi
        .get()
        .then((response) => {
          const order = response.data.order;
          this.setState({
            pendingAmount: order.pendingAmount,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const api = new API({
      url: `/order-service/order/${this.props.match.params.id}`,
    });
    api
      .get({ "includes[0]": "offerProducts" })
      .then((response) => {
        let order = response.data.order;
        if (order.packingDetails) {
          this.setState({
            packingDetails: order.packingDetails,
            sampleItems: this.getSampleItems(order?.items),
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(formData) {
    formData = this.state.values;
    this.setState({
      submitting: true,
      loading: true,
      showCompletePopup: false,
    });
    let data = Object.assign({}, formData);
    let newData = Object.keys(data)
      .map((mode) => {
        if (data[mode].amount) {
          return {
            mode: mode,
            amount: data[mode].amount,
            transactionId: data[mode].transactionId,
          };
        }
        return null;
      })
      .filter(Boolean);

    let paramsArray = newData.map((data) => {
      let params = {};
      params.amount = data.amount;
      params.mode = data.mode;
      params.orderReferenceNumber = this.props.match.params.id;
      if (data.transactionId) {
        params.metaData = { transaction_id: data.transactionId };
      }
      params.status = "COMPLETED";
      return params;
    });
    const apiPayment = new API({ url: "/order-service/order-payment" });
    let apiCallArray = [];
    apiCallArray = paramsArray.map((param) => {
      return function () {
        return apiPayment.post(param);
      };
    });
    apiCallArray
      .reduce((prev, curr) => {
        return prev.then(curr);
      }, Promise.resolve())
      .then((response) => {
        const api = new API({
          url: `/order-service/order/${this.props.match.params.id}`,
        });
        api.put({ status: "COMPLETED" }).then((response) => {
          this.setState(
            {
              submitting: false,
              loading: false,
            },
            () => this.props.history.goBack()
          );
        });
      })
      .catch((error) => {
        console.error(error);
        if (error.code === 401) {
          throw error;
        } else {
          this.setState({
            submitting: false,
            loading: false,
            errorMessage: (error && error.message) || "Please try again later",
            showErrorDialog: true,
          });
        }
      });
  }

  getSampleItems = (orderItems = []) => {
    let samples = [];
    orderItems.forEach((item) => {
      if (item.orderDetails?.isFree) samples.push(item);
    });
    return samples;
  };

  render() {
    const { Form } = this.components;
    const referenceNumber = this.props.match.params.id;
    const {
      paymentModes,
      order,
      values,
      pendingAmount,
      submitting,
      errorMessage,
      sampleItems,
    } = this.state;
    const clientId =
      (this.state && this.state.clientId && this.state.clientId !== "null") ||
      (order && order.clientId);
    let remainingAmount = (
      pendingAmount -
      (values &&
        Object.keys(values) &&
        Object.keys(values).reduce(
          (acc, val) => Number(values[val].amount) + acc,
          0
        ))
    ).toFixed(2);
    let isButtonDisabled =
      paymentModes &&
      paymentModes.length > 0 &&
      (submitting || !!Number(!!remainingAmount && Number(remainingAmount)));
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <h1>
          {getMessage("order.register.payments.for")}{" "}
          <span className="text-muted">{clientId ? "#" : "Z"}</span>
          {clientId || referenceNumber}{" "}
        </h1>
        {this.state.showErrorDialog && (
          <Dialog
            show={this.state.showErrorDialog}
            title={"Error"}
            information={errorMessage}
            close={() => this.props.history.goBack()}
            closeText={getMessage("dialog.okText")}
          />
        )}
        {this.state.loading ? (
          <Loader />
        ) : (
          <Form className="order-payments-form">
            <Popup
              show={this.state.showCompletePopup}
              close={() => this.setState({ showCompletePopup: false })}
              className="pickup-pending-form"
              heading={getMessage("order.details.popup.heading")}
            >
              <div className="reg-payments">
                <div className="text-center">
                  {getMessage("order.details.completion.title")}
                </div>
                <div className="text-center text-muted">
                  {getMessage("order.details.completion.message")}
                </div>
                <div className="packing-details-wrp">
                  <div className="packing-info">
                    {getMessage("order.details.packing.title")}
                  </div>
                  <div className="details-container">
                    {this.state?.packingDetails &&
                      this.state?.packingDetails.map((item, index) => {
                        return (
                          <div className="details-item" key={index}>
                            <div className="package-type">
                              <span className="package-type-text">
                                {getMessage("order.details.packing.type")}
                              </span>
                              {Object.keys(item?.details)[0]}
                            </div>
                            <div className="package-name-wrp">
                              {item.details?.[
                                Object.keys(item?.details)[0]
                              ].map((data) => {
                                return (
                                  <div className="package-name" key={data}>
                                    {data}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="submit-button">
                    <button
                      className="primary button"
                      onClick={() => this.onSubmit()}
                    >
                      {getMessage("order.payment.submit")}
                    </button>
                  </div>
                </div>
                {sampleItems?.length !== 0 && (
                  <div className="sample-items">
                    <div className="heading">
                      {getMessage("order.reg.payments.sample.heading")}
                    </div>
                    {sampleItems?.map((item) => {
                      let name = item.fullName || item.name;
                      let pickedQuantity = Number(
                        item.orderDetails.deliveredQuantity
                      );
                      let isSoldByWeight = item.soldByWeight || false;
                      let divider = 1;
                      let unit =
                        (name && name.split(" ").slice(-1).pop()) || "";
                      let weight = name ? name.split(" ").slice(-2)[0] : 1;
                      if (unit === "KG" || unit === "L") {
                        divider = weight * 1000;
                      }
                      if (unit === "G" || unit === "GM" || unit === "ML") {
                        divider = weight;
                      }
                      let orderedQuantity = item.orderDetails.orderedQuantity;
                      return (
                        <div className="sample" key={name}>
                          {item.brand ? (
                            <div className="product-name">
                              {" "}
                              <span className="brand-name">
                                {item?.brand?.name}
                              </span>{" "}
                              {name}
                            </div>
                          ) : (
                            name
                          )}
                          <small className="text-muted">
                            {" : "}
                            {pickedQuantity
                              ? isSoldByWeight
                                ? (pickedQuantity / divider).toFixed(3)
                                : pickedQuantity.toFixed(0)
                              : isSoldByWeight
                              ? (Number(orderedQuantity) / divider).toFixed(3)
                              : Number(orderedQuantity).toFixed(0)}
                            {"Qty"}
                          </small>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </Popup>
            <Table>
              <Header>
                <Cell>{getMessage("order.payment.paymentmode")}</Cell>
                <Cell className="align-right">
                  {getMessage("order.payment.amount")} (
                  {getSession() &&
                    getSession().organization &&
                    getSession().organization.currency.symbol}
                  )
                </Cell>
                <Cell>{getMessage("order.payment.transactionid")}</Cell>
              </Header>
              {paymentModes &&
                paymentModes.map((mode, i) => (
                  <Row key={`${mode}-${i}-row`}>
                    <Cell key={`${mode}-${i}`}>{mode}</Cell>
                    <Cell className="amount">
                      <Input
                        name={`${mode}-${i}-amount`}
                        type="number"
                        min={0}
                        step={0.01}
                        {...this.generateStateMappers({
                          stateKeys: [mode, "amount"],
                          loseEmphasisOnFill: true,
                        })}
                      />
                    </Cell>
                    <Cell className="transactionid">
                      {mode !== "COD" && (
                        <Input
                          name={`${mode}-${i}-transactionId`}
                          type="text"
                          {...this.generateStateMappers({
                            stateKeys: [mode, "transactionId"],
                            loseEmphasisOnFill: true,
                          })}
                          required={this.getState(mode, "amount")}
                        />
                      )}
                    </Cell>
                  </Row>
                ))}
              <Row>
                <Cell>{getMessage("order.payment.pendingamount")}</Cell>
                <Cell className="align-right">{remainingAmount}</Cell>
                <Cell />
              </Row>
            </Table>
            <div className="form-action">
              <button
                className={`primary button ${isButtonDisabled && "disabled"}`}
                onClick={(e) => {
                  e.preventDefault();
                  !isButtonDisabled &&
                    this.setState({ showCompletePopup: true });
                }}
              >
                {getMessage("order.payment.submit")}
              </button>
            </div>
          </Form>
        )}
      </AuthenticatedPage>
    );
  }
}

export default RegisterOrderPayment;
