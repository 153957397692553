import React from "react";
import {
  BaseForm,
  Input,
  Upload,
  VALIDATION_TYPES,
} from "../../../components/Form";
import { IS_STAGING } from "../../../config/app";
import { getMessage } from "../../../lib/translator";
import API from "../../../lib/api";
import Loader from "../../../components/Loader";
import { Dialog } from "../../../components/Popup";
import { withRouter } from "react-router-dom";
import GoogleMaps, { Marker, SearchBox } from "../../../components/GoogleMaps";
import { getSession, saveSession, isExtensionEnabled } from "../../../lib/auth";
import { get } from "../../../lib/storage";

class BasicInformationForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.values = {
      organizationName: "",
      siteUrl: "",
      logo: "",
      latitude: "",
      longitude: "",
      address: "",
    };
    this.state.formError = "";
    this.state.showLoader = false;
    this.state.showSuccessDialog = false;
    this.state.showErrorDialog = false;
    this.isCustomUrl = false;
    this.state.mapMounted = false;
    this.onMapMounted = this.onMapMounted.bind(this);
    this.findLocation = this.findLocation.bind(this);
    this.changeUrlElement = this.changeUrlElement.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closeDialogs = this.closeDialogs.bind(this);
  }

  onMapMounted(node) {
    this.mapRef = node;
    this.setState({
      mapMounted: true,
    });
    if (
      (!this.state.values.latitude || !this.state.values.longitude) &&
      "geolocation" in window.navigator
    ) {
      /* geolocation is available */
      window.navigator.geolocation.getCurrentPosition(({ coords }) => {
        this.setLocation(coords);
      });
    }
  }

  setLocation({ latitude, longitude }) {
    this.updateState(["latitude"], latitude);
    this.updateState(["longitude"], longitude);
  }

  findLocation() {
    if (this.searchBoxRef) {
      const places = this.searchBoxRef.getPlaces();
      const bounds = new window.google.maps.LatLngBounds();
      if (places.length) {
        const place = places[0];
        this.setLocation({
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
        this.updateState(["address"], place.formatted_address);
      }
      // Fit location area into the map
      places.forEach((place) => {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.mapRef.fitBounds(bounds);
    }
  }

  handleClose() {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    this.api = new API({ url: "/account-service/config/basic" });
    this.api
      .get()
      .then((basicResponse) => {
        const basic = basicResponse.data.config.basic;
        let siteUrl = basic.siteUrl;
        siteUrl =
          siteUrl &&
          !IS_STAGING &&
          siteUrl.indexOf(".staging.zopsmart.com") !== -1
            ? siteUrl.replace(".staging.", ".")
            : siteUrl;
        if (
          siteUrl &&
          siteUrl.indexOf(".staging.zopsmart.com") === -1 &&
          siteUrl.indexOf(".zopsmart.com") !== -1 &&
          IS_STAGING
        ) {
          let index = siteUrl.indexOf(".zopsmart.com");
          siteUrl = siteUrl.substr(0, index);
          siteUrl += ".staging.zopsmart.com";
        }
        if (siteUrl) {
          let index = IS_STAGING
            ? siteUrl.indexOf(".staging.zopsmart.com")
            : siteUrl.indexOf(".zopsmart.com");
          if (index !== -1) {
            siteUrl = siteUrl.substr(0, index);
          } else {
            this.isCustomUrl = true;
          }
        }
        const logo = basic.logo ? basic.logo : "";
        let values = {
          organizationName: basic.organizationName,
          logo: logo,
          siteUrl: siteUrl,
          id: basic.id,
          supportEmail: basic.supportEmail,
          supportPhone: basic.supportPhone && basic.supportPhone.toString(),
          address: basic.address,
          latitude: Number(basic.latitude),
          longitude: Number(basic.longitude),
        };
        this.setState({
          values,
        });
      })
      .then(() => {
        this.configApi = new API({ url: "/config-service/config/basic" });
        this.configApi
          .get()
          .then((response) => {
            let basic = response.data.basic;
            let values = JSON.parse(JSON.stringify(this.state.values));
            values.favicon = basic.favicon;
            this.setState({
              values,
              showLoader: false,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
  }

  handleSubmit(data) {
    const basicParams = [
      "logo",
      "organizationName",
      "siteUrl",
      "address",
      "latitude",
      "longitude",
      "supportEmail",
      "supportPhone",
    ];
    const basicInfo = new API({ url: "/account-service/config/basic" });
    if (!data.logo) {
      delete data["logo"];
    }
    let params = {};
    params["basic"] = {};
    for (let field in data) {
      if (basicParams.indexOf(field) >= 0) {
        params["basic"][field] = data[field] || "";
        if (field === "siteUrl" && !this.isCustomUrl) {
          params["basic"][field] =
            data[field] +
            (IS_STAGING ? ".staging.zopsmart.com" : ".zopsmart.com");
        }
      }
    }

    return basicInfo
      .put(params)
      .then(
        (basicResponse) => {
          const orgSession = Object.assign(getSession().organization, {
            name: basicResponse.data.config.basic.organizationName,
            domain: basicResponse.data.config.basic.siteUrl,
          });
          saveSession({
            organization: orgSession,
          });
          this.setState({ formError: "", showSuccessDialog: true });
        },
        (error) => {
          this.setState({ formError: error.message, showErrorDialog: true });
          if (error.code === 401) {
            throw error;
          }
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  changeUrlElement(isCustomUrl) {
    return (e) => {
      e && e.preventDefault();
      this.isCustomUrl = isCustomUrl;
      this.forceUpdate();
    };
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleSubmit(data).then(() => {
      let configApi = new API({ url: "/config-service/config" });
      let params = {};
      params["basic"] = {};
      params["basic"]["favicon"] = data.favicon;
      configApi
        .post(params)
        .then((response) => {})
        .catch((error) => {
          console.error(error);
        });
      this.setState({ submitting: false });
    });
  }

  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    });
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const MultiStoreEnabled = isExtensionEnabled("MultiStoreSupport");
    let isHttpsEnabled = JSON.parse(get("organization")).httpsEnabled;
    return (
      <div>
        {this.state.formError && (
          <div className="form-error">
            {getMessage(`error.${this.state.formError}`)}
          </div>
        )}
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("basicInfo.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("basicInfo.save.success.closeText")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage("basicInfo.save.error.title")}
          close={this.closeDialogs}
          closeText={getMessage("basicInfo.save.error.closeText")}
        />
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <Form className="basicInfo-form">
            <Input
              label={getMessage("basicInfo.storename.heading")}
              placeholder={getMessage("basicInfo.storename.placeholder")}
              name="organizationName"
              type="text"
              required
              {...this.generateStateMappers({
                stateKeys: ["organizationName"],
                validationType: VALIDATION_TYPES.ONCHANGE,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            >
              {getMessage("basicInfo.storename.description")}
            </Input>
            <Input
              className="site-url"
              label={getMessage("basicInfo.storeurl.heading")}
              placeholder={getMessage("basicInfo.storeurl.placeholder")}
              name="siteUrl"
              type="text"
              prefix={isHttpsEnabled ? "https://" : "http://"}
              suffix={
                this.isCustomUrl
                  ? null
                  : IS_STAGING
                  ? ".staging.zopsmart.com"
                  : ".zopsmart.com"
              }
              required
              {...this.generateStateMappers({
                stateKeys: ["siteUrl"],
                validationType: VALIDATION_TYPES.ONCHANGE,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
                patternMismatch: getMessage("basicInfo.invalidDomain"),
              }}
            >
              {this.isCustomUrl /* TODO: Translate */ ? (
                [
                  <span key="description">
                    {getMessage("basicInfo.customDomain.description", {
                      domain: "stores.zopsmart.com",
                    })}
                  </span>,
                  <div key="link">
                    {" "}
                    Or use{" "}
                    <button
                      className="primary-link"
                      onClick={this.changeUrlElement(false)}
                    >
                      {IS_STAGING ? `staging.zopsmart.com` : `zopsmart.com`}
                    </button>{" "}
                    subdomain
                  </div>,
                ]
              ) : (
                <span>
                  Your store URL is provided free of cost at smartstore.express.
                  If you already own a domain,{" "}
                  <button
                    className="primary-link"
                    onClick={this.changeUrlElement(true)}
                  >
                    use custom domain
                  </button>
                </span>
              )}
            </Input>

            <Upload
              label={getMessage("basicInfo.uploadImage.heading")}
              placeholder={getMessage("basicInfo.uploadImage.placeholder")}
              name="logo"
              {...this.generateStateMappers({
                stateKeys: ["logo"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              required
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
                fileSizeExeeded: getMessage("input.fileSizeExeeded"),
                invalidFileType: getMessage("input.invalidFileType"),
              }}
            >
              {getMessage("basicInfo.uploadImage.description")}
            </Upload>
            <Upload
              label={getMessage("basicInfo.uploadFavicon.heading")}
              placeholder={getMessage("basicInfo.uploadFavicon.placeholder")}
              name="favicon"
              className="favicon"
              resize={false}
              accept={"image/jpeg,image/jpg,image/png,image/vnd.microsoft.icon"}
              {...this.generateStateMappers({
                stateKeys: ["favicon"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
                fileSizeExeeded: getMessage("input.fileSizeExeeded"),
                invalidFileType: getMessage("input.invalidFileType"),
              }}
            >
              {getMessage("basicInfo.uploadFavicon.description")}
            </Upload>
            {!MultiStoreEnabled && (
              <div>
                <Input
                  className="coordinates-input"
                  label={getMessage("basicInfo.form.location.heading")}
                  name="coordinates"
                  type="text"
                  required
                  value={
                    this.getState(["latitude"]) && this.getState(["longitude"])
                      ? "filled"
                      : ""
                  }
                  onValidation={(error) => {
                    this.registerValidation(["coordinates"], error);
                  }}
                  emphasize={
                    !this.getState(["latitude"]) ||
                    !this.getState(["longitude"])
                  }
                  showErrors={this.validationScenarios.validateOnSubmit()}
                  siblings={{
                    after: (
                      <GoogleMaps
                        onMapMounted={this.onMapMounted}
                        zoom={15}
                        center={{
                          lat: this.getState(["latitude"]) || 12.9178614,
                          lng: this.getState(["longitude"]) || 77.6449406,
                        }}
                        containerClassName="map-element"
                      >
                        {this.state.mapMounted ? (
                          <SearchBox
                            ref={(node) => {
                              this.searchBoxRef = node;
                            }}
                            controlPosition={
                              window.google.maps.ControlPosition.TOP_LEFT
                            }
                            onPlacesChanged={this.findLocation}
                          >
                            <input
                              type="text"
                              placeholder={getMessage(
                                "basicInfo.form.location.placeholder"
                              )}
                              className="location-search-box"
                            />
                          </SearchBox>
                        ) : null}
                        <Marker
                          position={{
                            lat: this.getState(["latitude"]) || 12.9178614,
                            lng: this.getState(["longitude"]) || 77.6449406,
                          }}
                          draggable
                          onDragEnd={(e) => {
                            this.setLocation({
                              latitude: e.latLng.lat(),
                              longitude: e.latLng.lng(),
                            });
                          }}
                        />
                      </GoogleMaps>
                    ),
                  }}
                  validationStrings={{
                    valueMissing: getMessage(
                      "basicInfo.form.location.requiredMessage"
                    ),
                  }}
                />
              </div>
            )}
            <div className="form-buttons-container">
              <SubmitButton disabled={this.state.submitting}>
                {getMessage("basicInfo.submit.text")}
              </SubmitButton>
              <button
                className="button"
                type="button"
                onClick={this.handleClose}
                disabled={this.state.submitting}
              >
                {getMessage("basicInfo.cancel.text")}
              </button>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

export default withRouter(BasicInformationForm);
