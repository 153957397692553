import React from "react";
import {
  BaseForm,
  Input,
  Textarea,
  VALIDATION_TYPES,
} from "../../../components/Form";
import { getMessage } from "../../../lib/translator";
import API from "../../../lib/api";
import Loader from "../../../components/Loader";

class AnalyticsForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.values = {
      gaAccount: "",
      conversionTag: "",
    };
    this.state.formError = "";
    this.state.showLoader = false;
    this.hideLoader = this.hideLoader.bind(this);
    this.showLoader = this.showLoader.bind(this);
  }

  showLoader() {
    this.setState({
      showLoader: true,
    });
  }

  hideLoader() {
    this.setState({
      showLoader: false,
    });
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    const api = new API({ url: "/account-service/config/analytics" });
    api
      .get()
      .then(
        (response) => {
          if (response.status === "SUCCESS") {
            const analytics = response.data.config.analytics;
            const values = {
              gaAccount: analytics.gaAccount,
              conversionTag: analytics.conversionTag,
            };
            this.setState({ values, showLoader: false });
          }
        },
        (error) => {
          this.setState({ formError: error.message, showLoader: false });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  handleSubmit(data) {
    const api = new API({ url: "/account-service/config/analytics" });
    let params = {};
    params["analytics"] = {};
    for (let field in data) {
      params["analytics"][field] = data[field];
    }
    return api
      .put(params)
      .then(
        (response) => {},
        (error) => {
          this.setState({ formError: error.message });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleSubmit(data)
      .then(() => {
        this.setState({ submitting: false });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <Form>
            <Input
              label={getMessage("analytics.tracking.heading")}
              placeholder={getMessage("analytics.tracking.placeholder")}
              name="tracking_id"
              type="text"
              required
              {...this.generateStateMappers({
                stateKeys: ["gaAccount"],
                validationType: VALIDATION_TYPES.ONCHANGE,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
                patternMismatch: getMessage("analytics.invalidTrackingId"),
              }}
            >
              {getMessage("analytics.tracking.description")}
            </Input>

            <Textarea
              label={getMessage("analytics.conversionPixel.heading")}
              placeholder="<script>&#10;//code for conversion pixel &#10;</script>"
              name="pixel"
              value={this.getState(["pixel"])}
              onChange={(value, error) => {
                this.updateState(["conversionTag"], value);
              }}
              onValidation={(error) => {
                this.registerValidation(["conversionTag"], error);
              }}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            >
              {getMessage("analytics.conversionPixel.description")}
            </Textarea>
            <div className="form-buttons-container">
              <SubmitButton disabled={this.state.submitting}>
                {getMessage("analytics.submit.text")}
              </SubmitButton>
              <CancelButton disabled={this.state.submitting}>
                {getMessage("analytics.cancel.text")}
              </CancelButton>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

export default AnalyticsForm;
