import React, { Component } from "react";

import FilledStar from "../FilledStar";
import EmptyStar from "../EmptyStar";

function findMaxNumber(one, two, three, four, five) {
  return Math.max(one, two, three, four, five);
}

const DataFormater = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toString() + "M";
  } else if (number > 1000) {
    return (number / 1000).toString() + "K";
  } else {
    return number;
  }
};

class RatingAndReviews extends Component {
  render() {
    let { totalRating, avgRating, noOfReviews } = this.props;
    const totalWidth = findMaxNumber(
      noOfReviews?.one,
      noOfReviews?.two,
      noOfReviews?.three,
      noOfReviews?.four,
      noOfReviews?.five
    );
    let one = (noOfReviews?.one * 100) / totalWidth;
    let two = (noOfReviews?.two * 100) / totalWidth;
    let three = (noOfReviews?.three * 100) / totalWidth;
    let four = (noOfReviews?.four * 100) / totalWidth;
    let five = (noOfReviews?.five * 100) / totalWidth;
    const totalNumberOne = DataFormater(noOfReviews?.one);
    const totalNumberTwo = DataFormater(noOfReviews?.two);
    const totalNumberThree = DataFormater(noOfReviews?.three);
    const totalNumberFour = DataFormater(noOfReviews?.four);
    const totalNumberFive = DataFormater(noOfReviews?.five);
    totalRating = DataFormater(totalRating);
    return (
      <>
        {totalRating > 0 && (
          <div className="orders-rating">
            <div className="total-average-rating">
              <div className="total-reviews">
                <h3>Total Ratings</h3> <span>{totalRating}</span>
              </div>
              <div className="average-rating">
                <div>
                  <h3>Average Rating</h3>
                  <div className="average-rating-content">
                    <span className="rate">{avgRating.toFixed(1)}</span>
                    <div>
                      <FilledStar />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rating-bar">
              <div>
                <EmptyStar />
                <span style={{ marginLeft: "4px" }}>5</span>
                <div
                  className="four-rating"
                  style={{
                    background: "#4AB819",
                    width: `${five}%`,
                    height: "25%",
                    margin: "0 7px",
                  }}
                ></div>
                {five > 0 && <span>{totalNumberFive}</span>}
              </div>
              <div>
                <EmptyStar />
                <span style={{ marginLeft: "4px" }}>4</span>
                <div
                  className="four-rating"
                  style={{
                    background: "#239CAE",
                    width: `${four}%`,
                    height: "25%",
                    margin: "0 7px",
                  }}
                ></div>
                {four > 0 && <span>{totalNumberFour}</span>}
              </div>
              <div>
                <EmptyStar />
                <span style={{ marginLeft: "4px" }}>3</span>
                <div
                  className="four-rating"
                  style={{
                    background: "#005A75",
                    width: `${three}%`,
                    height: "25%",
                    margin: "0 7px",
                  }}
                ></div>
                {three > 0 && <span>{totalNumberThree}</span>}
              </div>
              <div>
                <EmptyStar />
                <span style={{ marginLeft: "4px" }}>2</span>
                <div
                  className="four-rating"
                  style={{
                    background: "#F1CB59",
                    width: `${two}%`,
                    height: "25%",
                    margin: "0 7px",
                  }}
                ></div>
                {two > 0 && <span>{totalNumberTwo}</span>}
              </div>
              <div>
                <EmptyStar />
                <span style={{ marginLeft: "4px" }}>1</span>
                <div
                  className="four-rating"
                  style={{
                    background: "#EB8181",
                    width: `${one}%`,
                    height: "25%",
                    margin: "0 7px",
                  }}
                ></div>
                {one > 0 && <span>{totalNumberOne}</span>}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default RatingAndReviews;
