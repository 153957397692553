import React, { Component } from "react";
import DateRangePickerWrapper from "../../../DateRangePickerWrapper";
import "./style.css";

function getValidationObj(props, dateRange) {
  let valueMissing =
    props.required &&
    (!dateRange || !dateRange.startDate || !dateRange.endDate);
  let result = {
    valueMissing,
    valid: !valueMissing,
  };
  return result;
}

export default class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.runValidation = this.runValidation.bind(this);
  }

  runValidation(dateRange) {
    this.props.onValidation &&
      this.props.onValidation(getValidationObj(this.props, dateRange));
  }

  handleChange(startDate, endDate) {
    const dateRange = { startDate: startDate, endDate: endDate };
    this.props.onChange(dateRange);
    this.runValidation(dateRange);
  }

  componentDidMount() {
    this.runValidation(this.props.value);
  }

  render() {
    let {
      value,
      displayFormat,
      required,
      startDatePlaceholder,
      endDatePlaceholder,
      minimumNights,
      isOutsideRange,
      openDirection,
      readOnly,
    } = this.props;
    return (
      <DateRangePickerWrapper
        startDate={value && value.startDate}
        endDate={value && value.endDate}
        displayFormat={displayFormat}
        required={required}
        onDatesChange={this.handleChange}
        startDatePlaceholder={startDatePlaceholder}
        endDatePlaceholder={endDatePlaceholder}
        minimumNights={minimumNights}
        isOutsideRange={isOutsideRange}
        openDirection={openDirection}
        readOnly={readOnly}
      />
    );
  }
}
