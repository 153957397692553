import React from "react";
import { BaseForm, Input } from "../../../components/Form";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import Loader from "../../../components/Loader";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";

const HEADING_TEXT = getMessage("wallet.config.heading");
const SAVE_TEXT = getMessage("picking.config.save");

export default class WalletConfiguration extends BaseForm {
  componentDidMount() {
    //using this.configsText to store config texts, this prevents calling getMessage on every render
    this.configsText = {};
    this.setState({
      loading: true,
    });
    const api = new API({ url: "/config-service/config/wallet" });
    api
      .get()
      .then((response) => {
        let config = response.data.wallet;
        for (const configKey in config) {
          const configKeyPrefix = `wallet.config.${configKey}`;
          this.configsText[configKey] = getMessage(configKeyPrefix);
          this.configsText[`${configKey}Placeholder`] = getMessage(
            `${configKeyPrefix}.placeholder`
          );
        }
        this.setState({
          loading: false,
          values: config,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  onSubmit(formData) {
    this.setState({
      submitting: true,
    });
    let data = Object.assign({}, this.state.values);
    const api = new API({ url: "/config-service/config" });
    api
      .post({ wallet: data })
      .then(
        (response) => {
          this.props.history.goBack();
        },
        (error) => {
          this.setState({
            formError: error.message,
            submitting: false,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const configKeys = Object.keys(this.state.values);
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <h1>{HEADING_TEXT}</h1>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        {this.state.loading ? (
          <Loader />
        ) : (
          <Form>
            {configKeys.map((configKey) => (
              <Input
                key={configKey}
                name={configKey}
                label={this.configsText[configKey]}
                placeholder={this.configsText[`${configKey}Placeholder`]}
                type="number"
                min={0}
                max={configKey === "cashbackPercentage" ? 100 : null}
                step={1}
                required={true}
                {...this.generateStateMappers({
                  stateKeys: [configKey],
                  loseEmphasisOnFill: true,
                })}
              />
            ))}
            <div>
              <SubmitButton>{SAVE_TEXT}</SubmitButton>
            </div>
          </Form>
        )}
      </AuthenticatedPage>
    );
  }
}
