import React, { Component } from "react";
import "./style.css";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import OrderAddForm from "./Form";
import PhoneNumberForm from "./PhoneNumberForm";
import { getMessage } from "../../../../lib/translator";
import StoreSelector, {
  makestoreDependentComponent,
  getDefaultStore,
} from "../../../../containers/StoreSelector";
import { get } from "../../../../lib/storage";
import { isExtensionEnabled, getStores } from "../../../../lib/auth";

function CustomerCard(props) {
  const { customerDetails } = props;
  let styles = {};
  if (customerDetails.image) {
    styles = {
      backgroundImage: `url(${customerDetails.image})`,
      backgroundSize: "32px",
    };
  }

  return (
    <div className="flex customer-details-card">
      {customerDetails.image && <div style={styles} className="user-image" />}
      <div>
        <div className="customer-details-name">{customerDetails.name} </div>
        <div className="detail">
          {customerDetails.emails?.length > 0 && (
            <div>{customerDetails.emails?.[0].email}</div>
          )}
        </div>
      </div>
    </div>
  );
}

class CreateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerDetails: null,
      showNewCustomerForm: false,
      pickuplocations: null,
      phoneNumber: null,
      storeId: get("store") || getDefaultStore(props.stores).storeId,
      showStoreSelctor: false,
    };
    this.setCustomerDetails = this.setCustomerDetails.bind(this);
    this.addAddressInCustomerDetails =
      this.addAddressInCustomerDetails.bind(this);
    this.updateStoreId = this.updateStoreId.bind(this);
  }

  setCustomerDetails(customerDetails, phoneNumber) {
    this.setState({
      customerDetails,
      showNewCustomerForm: !customerDetails,
      phoneNumber,
    });
  }

  addAddressInCustomerDetails(address) {
    let newCustomerDetails = JSON.parse(
      JSON.stringify(this.state.customerDetails)
    ); // TODO : Use Immutable here
    if (
      !newCustomerDetails.addresses ||
      newCustomerDetails.addresses.length === 0
    ) {
      newCustomerDetails.addresses = [];
    }
    newCustomerDetails.addresses.push(address);
    this.setState({
      customerDetails: newCustomerDetails,
    });
  }

  componentDidMount() {
    this.setState({
      showStoreSelctor: true,
    });
    const locations = getStores();
    if (locations?.length > 0) {
      const pickuplocations = locations
        .filter((store) => store.hasClickCollect)
        .reduce((acc, location) => {
          acc.push({ value: location.id, text: location.name });
          return acc;
        }, []);
      this.setState({
        pickuplocations,
        pickuplocationDetails: locations,
      });
    }
  }

  updateStoreId(storeId) {
    this.setState({
      storeId,
    });
  }

  render() {
    const {
      customerDetails,
      showNewCustomerForm,
      pickuplocations,
      phoneNumber,
      storeId,
      showStoreSelctor,
    } = this.state;
    const { stores } = this.props;
    return (
      <div>
        <div className="header-container flex">
          <h1 className="title">{getMessage("order.form.add.heading")}</h1>
          <div className="pos-store-selector">
            {showStoreSelctor && isExtensionEnabled("MultiStoreSupport") && (
              <StoreSelector
                value={storeId}
                stores={stores}
                onChange={this.updateStoreId}
              />
            )}
          </div>
        </div>
        <div className="phone-customer-card">
          <PhoneNumberForm setCustomerDetails={this.setCustomerDetails} />
          {customerDetails && (
            <CustomerCard customerDetails={customerDetails} />
          )}
        </div>
        <OrderAddForm
          addAddressInCustomerDetails={this.addAddressInCustomerDetails}
          phoneNumber={phoneNumber}
          pickuplocations={pickuplocations}
          customerDetails={customerDetails}
          showNewCustomerForm={showNewCustomerForm}
          storeId={storeId}
          pickuplocationDetails={this.state.pickuplocationDetails}
        />
      </div>
    );
  }
}

const StoreDependentOrders = makestoreDependentComponent(CreateOrder);

const CreateOrderWrapper = (props) => {
  return (
    <AuthenticatedPage className="create-order" menu={props.menu}>
      <StoreDependentOrders {...props} />
    </AuthenticatedPage>
  );
};

export default CreateOrderWrapper;
