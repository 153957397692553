import React, { Component } from "react";
import { Link } from "react-router-dom";
import Tree, {
  removeNodeAtPath,
  addNodeUnderParent,
  changeNodeAtPath,
} from "../../../../components/Tree";
import { DropDown, DropDownItem, ICONS } from "../../../../components/DropDown";
import CategoryForm from "../Form";
import API from "../../../../lib/api";
import Loader from "../../../../components/Loader";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import "./style.css";
import { Popup, Dialog } from "../../../../components/Popup";
import { getMessage } from "../../../../lib/translator";
import Image from "../../../../components/Image";
import ImagePlaceHolder from "../../../../components/Image/image-placeholder.svg";
import tableInctive from "./table-inactive.svg";
import treeViewActive from "./tree-view-active.svg";
import emptyIcon from "../categories-empty.svg";
import EmptyState from "../../../../components/EmptyState";

function generateCategoryTree(data, parent = null) {
  data.map((item) => {
    if (item.subCategories === null) {
      item.title = item.name;
      item.children = null;
      item.parentCategory = parent && { name: parent.name, id: parent.id };
    } else {
      item.title = item.name;
      item.children = generateCategoryTree([...item.subCategories], item);
      item.parentCategory = parent && { name: parent.name, id: parent.id };
    }
    return item;
  });
  return data;
}

function filterHiddenCategories(data) {
  var result = (data || []).filter(function filterCategory(category) {
    if (category.status !== "HIDDEN") {
      if (category.children) {
        return (category.children = category.children.filter(filterCategory));
      }
      return true;
    } else {
      return false;
    }
  });
  return result;
}

class CategoryTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      treeData: [],
      getNodeKey: ({ node }) => node.id,
      done: true,
    };
    this.changeParent = this.changeParent.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.editCategory = this.editCategory.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  changeParent(node, parentNode) {
    this.setState({
      done: false,
    });
    this.api = new API({ url: `/catalogue-service/category/${node.id}` });
    let params = {};
    if (parentNode) {
      params.parentCategoryId = parentNode.id;
    } else {
      params.parentCategoryId = "";
    }
    this.api
      .put(params)
      .then(
        (response) => {
          this.treeData = null;
          this.setState({
            done: true,
            error: null,
          });
        },
        (error) => {
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
          this.setState(
            {
              error: error.message,
              done: true,
              treeData: this.treeData,
            },
            () => {
              this.treeData = null;
            }
          );
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({
      loading: true,
    });

    this.api = new API({ url: "/catalogue-service/category" });
    this.api
      .get({ paginate: "false", hierarchy: true })
      .then((response) => {
        let categories = response.data.category || [];
        let generateTree = [];
        let empty = false;
        if (categories.length > 0) {
          generateTree = generateCategoryTree(categories);
        } else {
          empty = true;
        }
        this.setState({
          loading: false,
          treeData: generateTree,
          empty,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  editCategory(formData) {
    this.setState({
      submitting: true,
    });
    let params = Object.assign({}, formData);
    this.api = new API({ url: `/catalogue-service/category/${params.id}` });
    if (params.parentCategory) {
      params.parentCategoryId = params.parentCategory.id;
    } else if (params.parentCategory === "") {
      params.parentCategoryId = "";
    } else {
      delete params.parentCategoryId;
    }
    delete params.parentCategory;
    this.api
      .put(params)
      .then(
        (response) => {
          const category = response.data.category;
          this.setState(
            (state) => ({
              treeData: changeNodeAtPath({
                treeData: state.treeData,
                path: state.path,
                getNodeKey: state.getNodeKey,
                newNode: { ...category, title: category.name },
              }),
              submitting: false,
              value: null,
              showForm: false,
              error: null,
              done: true,
              path: null,
            }),
            () => this.fetchData()
          );
        },
        (error) => {
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
          this.setState({
            submitting: false,
            error: error.message,
            done: true,
            path: null,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  addCategory(formData) {
    this.setState({
      submitting: true,
    });
    this.api = new API({ url: "/catalogue-service/category" });
    let params = Object.assign({}, formData);
    const path = this.state.path;
    if (path) {
      params.parentCategoryId = path[path.length - 1];
    } else {
      params.parentCategoryId = null;
    }
    if (formData.parentCategory) {
      params.parentCategoryId = formData.parentCategory.id;
    }
    this.api
      .post(params)
      .then((response) => {
        let category = response.data.category;
        let parentCategoryId =
          category.parentCategory && category.parentCategory.id;
        let newNode = { ...category, title: category.name };
        this.setState((state) => ({
          showForm: false,
          value: null,
          submitting: false,
          path: null,
          done: true,
          treeData: addNodeUnderParent({
            treeData: state.treeData,
            parentKey: parentCategoryId,
            expandParent: true,
            getNodeKey: state.getNodeKey,
            newNode: newNode,
            addAsFirstChild: true,
          }).treeData,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  }
  deleteCategory() {
    this.setState({
      done: false,
    });
    const path = this.state.path;
    const id = path[path.length - 1];
    this.api = new API({ url: `/catalogue-service/category/${id}` });
    this.api
      .delete()
      .then(
        (response) => {
          this.setState((prevState) => ({
            treeData: removeNodeAtPath({
              treeData: prevState.treeData,
              path: prevState.path,
              getNodeKey: prevState.getNodeKey,
            }),
            path: null,
            done: true,
            showDelete: false,
            error: null,
          }));
        },
        (error) => {
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
          this.setState({
            error: error.message,
            path: null,
            done: true,
            showDelete: false,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    const { loading, done, error, empty } = this.state;
    let { treeData } = this.state;
    // treeData = filterHiddenCategories(treeData)
    return (
      <AuthenticatedPage menu={this.props.menu} className="category-tree">
        <div className="heading">
          <h1 className="title">{getMessage("category.tree.title")}</h1>
          <div className="heading-links">
            <Link to="/catalogue/categories">
              <img
                src={tableInctive}
                alt="table view"
                title={getMessage("category.icons.table-title")}
              />
            </Link>
            <img
              src={treeViewActive}
              alt="tree view"
              title={getMessage("category.icons.tree-title")}
            />
            <button
              className="button primary"
              onClick={() =>
                this.setState({
                  showForm: true,
                  addFromTree: false,
                  path: null,
                })
              }
            >
              + <span className="text">{getMessage("category.add.text")}</span>
            </button>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {error && <div className="form-error">{error}</div>}
            <Popup
              className="add-category-tree-popup"
              title={getMessage("category.add")}
              show={this.state.showForm}
              close={() => this.setState({ showForm: false, value: null })}
            >
              <CategoryForm
                onCancel={() => this.setState({ showForm: false })}
                onSubmit={(formData) =>
                  this.state.value
                    ? this.editCategory(formData)
                    : this.addCategory(formData)
                }
                value={this.state.value}
                addFromTree={this.state.addFromTree}
                method={this.state.value ? "edit" : "add"}
              />
            </Popup>
            <Dialog
              show={this.state.showDelete}
              title={getMessage("category.delete")}
              close={() => this.setState({ showDelete: false, path: null })}
              information={getMessage("category.delete.confirm")}
              closeText={getMessage("category.delete.close")}
              okText={getMessage("category.delete.okay")}
              onOk={this.deleteCategory}
            />
            {!empty ? (
              <Tree
                data={treeData}
                className="category-tree"
                canDrag={done}
                onDragStateChanged={({ isDragging }) => {
                  if (isDragging) {
                    this.treeData = this.state.treeData;
                  }
                }}
                getNodeKey={({ node }) => node.id}
                onMoveNode={({ node, nextParentNode }) =>
                  this.changeParent(node, nextParentNode)
                }
                onChange={(e) => this.setState({ treeData: e })}
                generateNodeProps={({ node, path }) => ({
                  buttons: [
                    <div className="node-div">
                      <Image
                        src={
                          node.image && typeof node.image === "string"
                            ? node.image
                            : ImagePlaceHolder
                        }
                        alt=""
                        bordered
                      />
                    </div>,
                    <div className="node-div">{node.status}</div>,
                    <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
                      <DropDownItem
                        onClick={() => {
                          this.setState({
                            showForm: true,
                            path,
                            addFromTree: true,
                          });
                        }}
                      >
                        {getMessage("category.add")}
                      </DropDownItem>
                      <DropDownItem
                        onClick={() => {
                          this.setState({
                            value: node,
                            showForm: true,
                            addFromTree: true,
                            path,
                          });
                        }}
                      >
                        {getMessage("category.action.edit")}
                      </DropDownItem>
                      <DropDownItem>
                        <Link to={`/catalogue/products?categoryId=${node.id}`}>
                          {" "}
                          {getMessage("category.action.seeProducts")}
                        </Link>
                      </DropDownItem>
                    </DropDown>,
                    <button
                      className="remove-button"
                      onClick={() => {
                        this.setState({
                          showDelete: true,
                          path,
                        });
                      }}
                    >
                      Delete Category
                    </button>,
                  ],
                })}
              />
            ) : (
              <EmptyState
                icon={emptyIcon}
                message={getMessage("category.helpItems.message")}
                submessage={getMessage("category.helpItems.submessage")}
              />
            )}
          </React.Fragment>
        )}
      </AuthenticatedPage>
    );
  }
}

export default CategoryTree;

export { generateCategoryTree, filterHiddenCategories };
