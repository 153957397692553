import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Table from "./Table";
import Details from "./Details";

class OrdersRouter extends Component {
  render() {
    let { router, ...props } = this.props;
    let details = { ...props };
    details.url = "/operations";
    if (router.match.params.id) {
      let details = { ...this.props };
      details.url = "/operations";
      return <Details {...details} />;
    }
    return <Table {...details} />;
  }
}

export default withRouter(({ match, ...props }) => (
  <OrdersRouter router={{ match }} {...props} />
));
