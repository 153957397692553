import React from "react";
import { Link } from "react-router-dom";
import PublicPage from "../../../containers/PublicPage/index";
import { Consumer } from "../../../containers/Context";
import { getMessage } from "../../../lib/translator";
import LoginForm from "../Login/form";
import "../Verify/verify.css";

export default function AccountCreated() {
  return (
    <PublicPage className="eazy-page">
      <div className="verify">
        <header className="header">
          <h1 className="heading">{getMessage("account.created")}</h1>
          <h2 className="span subheading">
            {getMessage("verification.email.sent")}
          </h2>
        </header>
      </div>
      <Consumer>
        {(isEnterprise) => (
          <div className="account-created-wrp">
            <div className="password-sent-mobile">
              {getMessage("use.password.sent.to.registered.mobile")}
            </div>
            <LoginForm enterprise={isEnterprise} />
            {!isEnterprise && (
              <p className="alt-action">
                {getMessage("login.newUser.message")}{" "}
                <Link to="/signUp">{getMessage("signup.buttonText")}</Link>
              </p>
            )}
          </div>
        )}
      </Consumer>
    </PublicPage>
  );
}
