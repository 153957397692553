import React, { Component } from "react";
import { Link } from "react-router-dom";
import Image from "../../../components/Image";
import MapWithPath from "../../../components/MapWithPath";
import Table, { Header, Row, Cell } from "../../../components/Table";
import { TableActions } from "../Trips";
import API from "../../../lib/api";
import { getDefaultStore } from "../../../containers/StoreSelector";
import { Address as CustomerMapWidget } from "../../../containers/CustomerWidgets";
import { getMessage } from "../../../lib/translator";
import { get } from "../../../lib/storage";
import { getPrintableTime, formatTime } from "../../../lib/datetime";
import { getAsapDuration } from "../../../lib/commonlyused";
import { Dialog, Popup } from "../../../components/Popup";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import TripPaymentsForm from "../Trips/TripPaymentsForm";
import { getCurrency } from "../../../lib/userDetails";
import { hasPermissions, getSession } from "../../../lib/auth";
import moment from "moment";
import { parse, format } from "libphonenumber-js";
import TripclosedRibbon from "./ribbon.svg";
import PhoneIcon from "./call-inactive.svg";
import VehicleIcon from "./vehicle-icon.svg";
import CashIcon from "./cash-icon.svg";
import SignatureIcon from "./signature.svg";
import ClockIcon from "./clock-icon.svg";
import ShopIcon from "./shop-icon.svg";
import ImagePlaceHolder from "../../../components/Menu/images/customer-image-placeholder.svg";
import "./style.css";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import Loader from "../../../components/Loader";
import EndTripForm from "../Trips/EndTripForm";
import { getStandradDateTime, utcToLocalTime } from "../../../lib/datetime";
import { ZOPSMART_MAPS_URL } from "../../../config/app";

const TripIdContext = React.createContext("TripId");

// Converting completedAt dateTime format to ISO 8601 Notation
function appendTimezoneFormat(eta, completedAt) {
  completedAt = completedAt.split(" ").join("T");
  completedAt = completedAt + eta.slice(eta.length - 6, eta.length);
  return completedAt;
}

function timediff(eta, completedAt, offset = false) {
  if (offset === true) {
    completedAt = appendTimezoneFormat(eta, completedAt);
  }
  let etaMoment = moment(eta);
  let completedAtMoment = moment(completedAt);
  let duration = moment.duration(etaMoment.diff(completedAtMoment));
  let hours = parseInt(duration.asHours(), 10);
  let minutes = parseInt(duration.asMinutes(), 10) % 60;
  let seconds = (parseInt(duration.asSeconds(), 10) % 60) % 60;
  if (hours < 0 || minutes < 0 || seconds < 0) {
    return (
      <React.Fragment>
        (
        <span className="negativeTime">
          {"- " +
            Math.abs(hours).toString().padStart(2, "0") +
            ":" +
            Math.abs(minutes).toString().padStart(2, "0") +
            ":" +
            Math.abs(seconds).toString().padStart(2, "0")}
        </span>
        )
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        (
        <span className="positiveTime">
          {"+ " +
            hours.toString().padStart(2, "0") +
            ":" +
            minutes.toString().padStart(2, "0") +
            ":" +
            Math.abs(seconds).toString().padStart(2, "0")}
        </span>
        )
      </React.Fragment>
    );
  }
}

class TripTableActions extends TableActions {
  constructor(props) {
    super(props);
    this.showOdometerImage = this.showOdometerImage.bind(this);
    this.showPaymentsForm = this.showPaymentsForm.bind(this);
    this.submitEndTripForm = this.submitEndTripForm.bind(this);
  }

  showOdometerImage() {
    this.setState({ showOdometerImage: true });
  }

  showPaymentsForm() {
    this.props.history.push(`/logistics/trips/trip-payments/${this.props.id}`);
  }

  submitEndTripForm(values) {
    this.props.updatingTripData(values).then(this.hideEndTripForm);
  }

  render() {
    let {
      showOdometerImage,
      status,
      vehicleStartOdometerImage,
      vehicleEndOdometerImage,
      startKm,
      endKm,
    } = this.props;
    return (
      <div>
        <Popup
          show={this.state.showEndTripForm}
          heading={getMessage("trips.action.close")}
          close={this.hideEndTripForm}
        >
          <EndTripForm
            endKm={endKm}
            onSubmit={this.submitEndTripForm}
            onCancel={this.hideEndTripForm}
          />
        </Popup>
        <Popup
          show={this.state.paymentsForm}
          className="trip-finclose-form"
          heading={getMessage(`trips.action.fin_close`)}
          close={this.hidePaymentsForm}
        >
          {this.state.paymentsForm && (
            <TripIdContext.Consumer>
              {(value) => (
                <TripPaymentsForm
                  orders={this.state.tripDetails.orders}
                  onSubmit={this.financiallyCloseTrip}
                  paymentMode={value.paymentMethods}
                />
              )}
            </TripIdContext.Consumer>
          )}
        </Popup>
        <Popup
          show={this.state.showOdometerImage}
          className="odometer-popup"
          heading={getMessage("trip.details.odometerImage")}
          close={() => this.setState({ showOdometerImage: false })}
        >
          <div className="odometer-popup-text">
            <div>
              <p>{getMessage("trips.startOdometer")}</p>
              <p>{startKm && `${startKm} ${getMessage("trip.details.km")}`}</p>
            </div>
            <div>
              <p>{getMessage("trip.endOdometer")}</p>
              <p>{endKm && `${endKm} ${getMessage("trip.details.km")}`}</p>
            </div>
          </div>
          <div className="odometer-popup-img">
            <Image
              src={vehicleStartOdometerImage}
              alt="vehicle-start-odometer"
            />
            <Image src={vehicleEndOdometerImage} alt="vehicle-end-odometer" />
          </div>
        </Popup>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          {hasPermissions("logistics", "trip", "put") && status === "OPEN" && (
            <DropDownItem onClick={this.showEndTripForm}>
              {getMessage(`trips.action.close`)}
            </DropDownItem>
          )}
          {hasPermissions("logistics", "trip", "put") && status === "CLOSE" && (
            <DropDownItem onClick={this.showPaymentsForm}>
              {getMessage(`trips.action.fin_close`)}
            </DropDownItem>
          )}
          {showOdometerImage &&
            (vehicleStartOdometerImage || vehicleEndOdometerImage) && (
              <DropDownItem onClick={this.showOdometerImage}>
                {getMessage(`trips.action.vehicleOdometer`)}
              </DropDownItem>
            )}
        </DropDown>
      </div>
    );
  }
}

function storeLocation(stores, storeId) {
  let storesArr = JSON.parse(stores);
  var found = storesArr.find((store) => {
    return store.id === storeId;
  });
  return found;
}

function calcTotalCollectableAmount(orders = []) {
  let amountArr =
    orders && orders.map((order) => parseFloat(order.invoiceAmount));
  if (!amountArr) {
    return 0;
  }
  return amountArr.reduce((total, val) => total + val, 0).toFixed(2);
}

class DeliveryguyDetails extends Component {
  render() {
    let data = this.props.data;
    let agentMobileNumber =
      data.deliveryAgent.phones &&
      data.deliveryAgent.phones.length &&
      data.deliveryAgent.phones[0].phone
        ? Object.keys(parse(data.deliveryAgent.phones[0].phone)).length > 0
          ? format(parse(data.deliveryAgent.phones[0].phone), "International")
          : data.deliveryAgent.phones[0].phone
        : null;
    return (
      <div className="agent-info-container">
        <div className="agent-information">
          <div className="agent-pic">
            <img src={ImagePlaceHolder} alt="agent" />
          </div>
          <div className="agent-info">
            <span>Delivery Agent</span>
            <span>{data.deliveryAgent.name}</span>
            <span className="agent-number">
              <a href={`tel:${agentMobileNumber}`}>
                <img src={PhoneIcon} alt="phone-icon" /> {agentMobileNumber}
              </a>
            </span>
          </div>
          {data.endTime && (
            <div className="trip-closed">
              {" "}
              <img src={TripclosedRibbon} alt="Trip-closed-banner" />
              <span>CLOSED</span>
            </div>
          )}
        </div>
        <div className="vehicle-data">
          <div className={`vehicle-number type-${data.vehicle.type}`.trim()}>
            <span>
              <img src={VehicleIcon} alt="vehicle-icon" />
              {data.vehicle.number}
            </span>
          </div>
          <div className="total-amount">
            <span>
              <img src={CashIcon} alt="cash-icon" />
              {getSession().organization.currency.symbol}
              {calcTotalCollectableAmount(data.orders)}{" "}
            </span>
          </div>
        </div>
        <div className="trip-timing-details">
          <div className="clock-asset">
            {data.startTime && <img src={ClockIcon} alt="clock-icon" />}
          </div>
          <div className="distance-time-info">
            {data.startKm ? (
              <span>Trip Started at {data.startKm} Km</span>
            ) : (
              <span>Trip Started</span>
            )}
            {data.startTime && (
              <span>
                {data.startTime
                  ? getPrintableTime(data.startTime).split(", ")[1]
                  : null}
                ,{" "}
                {data.startTime
                  ? getPrintableTime(data.startTime).split(", ")[0]
                  : null}
              </span>
            )}
          </div>
          {data.vehicleStartTime && (
            <React.Fragment>
              <div className="clock-asset">
                <img src={ClockIcon} alt="clock-icon" />
              </div>
              <div className="distance-time-info">
                <span>Vehicle Started</span>
                {data.vehicleStartTime && (
                  <span>
                    {data.vehicleStartTime
                      ? getPrintableTime(data.vehicleStartTime).split(", ")[1]
                      : null}
                    ,{" "}
                    {data.vehicleStartTime
                      ? getPrintableTime(data.vehicleStartTime).split(", ")[0]
                      : null}
                  </span>
                )}
              </div>
            </React.Fragment>
          )}
          {data.endTime && (
            <div className="clock-asset">
              <img src={ClockIcon} alt="clock-icon" />
            </div>
          )}
          {data.endTime && (
            <div className="distance-time-info">
              {data.endKm ? (
                <span>Trip Ended at {data.endKm} Km</span>
              ) : (
                <span>Trip Ended</span>
              )}
              <span>
                {data.endTime
                  ? getPrintableTime(data.endTime).split(", ")[1]
                  : null}
                ,{" "}
                {data.endTime
                  ? getPrintableTime(data.endTime).split(", ")[0]
                  : null}
              </span>
            </div>
          )}
        </div>
        <div className="store-info">
          <div className="shop-icon-asset">
            <img src={ShopIcon} alt="shop-icon" />
          </div>
          {storeLocation(get("stores"), data.storeId) && (
            <div className="shop-location">
              <span>
                {storeLocation(get("stores"), data.storeId).address &&
                  storeLocation(get("stores"), data.storeId).address.split(
                    ","
                  )[0]}
                {storeLocation(get("stores"), data.storeId).address.split(
                  ","
                )[1] && <span>,</span>}
              </span>
              <span>
                {storeLocation(get("stores"), data.storeId).address &&
                  storeLocation(get("stores"), data.storeId).address.split(
                    ","
                  )[1]}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

class CustomerSignature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSignature: false,
    };
  }
  render() {
    const { showSignature } = this.state;
    const { signature } = this.props;
    return (
      <React.Fragment>
        {showSignature && (
          <Popup
            show={showSignature}
            heading={getMessage("trips.details.customerImage")}
            close={() => this.setState({ showSignature: false })}
          >
            <Image src={signature} />
          </Popup>
        )}
        <div
          className="signature"
          onClick={() => this.setState({ showSignature: true })}
        >
          <img src={SignatureIcon} alt="customer-signature" />
        </div>
      </React.Fragment>
    );
  }
}

const orderListTableProperties = (isMobileView) => {
  return {
    headers: [
      "",
      getMessage("trips.details.orderList.serialNumber"),
      getMessage("trips.details.orderList.referenceNumber"),
      getMessage("trips.header.status"),
      getMessage("trips.details.orderList.orderTime"),
      getMessage("trips.details.orderList.preferredSlot"),
      getMessage("trips.details.orderList.location"),
    ],
    row: ({
      id,
      completedAt,
      eta,
      referenceNumber,
      createdAt,
      invoiceAmount,
      status,
      customerSignatureImage,
      clientId,
      preferredDate,
      slotStartTime,
      slotEndTime,
      slotType,
      address,
    }) => (
      <Row>
        <Cell className="trip-time">
          <small className={"completed-at text-muted"}>
            {completedAt ? (
              getPrintableTime(completedAt).split(", ")[1]
            ) : (
              <span>&nbsp;</span>
            )}
          </small>
          <span className={`asset ${status}`} />
        </Cell>
        <Cell className="trip-order-sno">
          {isMobileView ? (
            <div className="text-muted1">
              {getMessage("trips.details.orderList.serialNumber")}
            </div>
          ) : (
            ""
          )}
          <div className="order-list-elm-sn">{id}</div>
        </Cell>
        <Cell className="trip-reference-number">
          {isMobileView ? (
            <div className="text-muted1">
              {getMessage("trips.details.orderList.referenceNumber")}
            </div>
          ) : (
            ""
          )}
          <React.Fragment>
            <div className="order-list-elm-refNo">
              <span>{clientId ? "#" : "Z"}</span>
              <Link to={`/operations/orders/${referenceNumber}`}>
                {clientId ? `${clientId}` : `${referenceNumber}`}
              </Link>
            </div>
            <small className="text-muted order-list-elm-amount">
              <span>
                {getCurrency().symbol}{" "}
                {invoiceAmount && Number(invoiceAmount).toFixed(2)}
              </span>
            </small>
          </React.Fragment>
        </Cell>
        <Cell className="trip-order-status">
          {isMobileView ? (
            <div className="text-muted1">
              {getMessage("trips.header.status")}
            </div>
          ) : (
            ""
          )}
          <div className="order-status">
            {["OPEN", "CLOSE", "FIN_CLOSE"].indexOf(status) > -1
              ? getMessage(`trips.status.${status.toLowerCase()}`)
              : (status || "").toLowerCase()}
          </div>
          <div className={`eta ${status} text-muted`}>
            {completedAt ? (
              moment(completedAt).format("hh:mm A")
            ) : eta ? (
              "ETA: " + moment(eta).format("hh:mm A")
            ) : (
              <span>&nbsp;</span>
            )}{" "}
            {eta && completedAt ? null : eta && timediff(eta, moment().utc())}
          </div>
        </Cell>
        <Cell className="trip-order-time">
          {isMobileView ? (
            <div className="text-muted1">
              {getMessage("trips.details.orderList.orderTime")}
            </div>
          ) : (
            ""
          )}
          <div>
            {createdAt ? getPrintableTime(createdAt).split(", ")[1] : null}
          </div>
          <small className="text-muted">
            {createdAt ? getPrintableTime(createdAt).split(", ")[0] : null}
          </small>
        </Cell>
        <Cell className="trip-preffered-slot">
          {isMobileView ? (
            <div className="text-muted1">
              {getMessage("trips.details.orderList.preferredSlot")}
            </div>
          ) : (
            ""
          )}
          <div className={`${!slotEndTime ? "zero-font" : ""}`.trim()}>
            {slotStartTime && slotEndTime && slotType !== "ASAP"
              ? formatTime(slotStartTime) + " - " + formatTime(slotEndTime)
              : getAsapDuration(slotStartTime, slotEndTime)}
          </div>
          <small className="text-muted">
            {preferredDate
              ? getPrintableTime(preferredDate).split(", ")[0]
              : null}
          </small>
        </Cell>
        <Cell className="trip-location">
          {isMobileView ? (
            <div className="text-muted1">
              {getMessage("trips.details.orderList.view.on.map")}
            </div>
          ) : (
            ""
          )}
          <React.Fragment>
            <div className="order-list-elm-location">
              {address && (
                <CustomerMapWidget
                  address={
                    address.address +
                    ", " +
                    address.city +
                    ", " +
                    address.pincode
                  }
                />
              )}
            </div>
            <div>
              {customerSignatureImage ? (
                <CustomerSignature signature={customerSignatureImage} />
              ) : (
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
              )}
            </div>
          </React.Fragment>
        </Cell>
      </Row>
    ),
  };
};

const AdditionalViewsWrapper = (props) => (
  <TripIdContext.Consumer>
    {(value) => (
      <AdditionalViews
        id={value.id}
        {...props}
        storeLocation={value.storeLocation}
        showOdometerImage={value.showOdometerImage}
        storeId={value.storeId}
      />
    )}
  </TripIdContext.Consumer>
);

function getMobileView() {
  return window.screen.width <= 480;
}

class AdditionalViews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicleCooordinate: null,
      showLoading: true,
      isMobileView: getMobileView(),
      zopsmartMapUrls: ZOPSMART_MAPS_URL,
    };
    this.getDetails = this.getDetails.bind(this);
    this.getOrderETAs = this.getOrderETAs.bind(this);
    this.mobileView = this.mobileView.bind(this);
  }
  mobileView() {
    this.setState({ isMobileView: getMobileView() });
  }
  getOrderCoordinates(data) {
    if (data?.length === 0) {
      return [];
    }
    let orderLatLng =
      data.orders &&
      data.orders.map(({ address }) => ({
        latitude: address && address.latitude,
        longitude: address && address.longitude,
      }));
    return orderLatLng;
  }

  getVehicleCooordinates(data) {
    if (data?.length === 0) {
      return [];
    }
    return data?.sort(
      (a, b) =>
        new Date(getPrintableTime(a.time)) - new Date(getPrintableTime(b.time))
    );
  }

  getStoreLocation(data) {
    let storeLatlng =
      data && data.orders && data.orders[0] && data.orders[0].store;
    return (
      storeLatlng && [
        {
          latitude: storeLatlng.latitude,
          longitude: storeLatlng.longitude,
        },
      ]
    );
  }

  getMapUrls = async () => {
    try {
      let configApi = new API({ url: "/config-service/config/backOffice" });
      const res = await configApi.get();
      if (res?.data?.backOffice?.mapsUrl) {
        this.setState({
          zopsmartMapUrls: res.data.backOffice.mapsUrl,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  async componentDidMount() {
    await this.getMapUrls();
    this.getDetails();
    this.getOrderETAs();
    window.addEventListener("resize", this.mobileView, false);
  }

  componentWillUnmount() {
    this.orderEtaApi && this.orderEtaApi.cancel();
    this.tripLocationApi && this.tripLocationApi.cancel();
    window.removeEventListener("resize", this.mobileView, false);
  }

  getOrderETAs() {
    const id = this.props.id;
    this.orderEtaApi = new API({
      url: `/logistics-service/trip-eta?storeId=${this.props.storeId}&tripId=${id}`,
    });
    this.orderEtaApi
      .get()
      .then((response) => {
        this.setState({
          orderETAs: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
        if (error.message === "cancelled") {
          return;
        }
        if (error.code === 401 || error.code === 403) {
          throw error;
        }
      });
  }

  getDetails() {
    const id = this.props.id;
    this.setState({ showLoading: true });
    this.tripLocationApi = new API({
      url: "/logistics-service/tripLocations",
    });
    let param = {
      tripId: id,
    };
    this.tripLocationApi
      .get(param)
      .then((response) => {
        this.setState({
          vehicleCooordinate: this.getVehicleCooordinates(response.data),
          showLoading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        if (error.message === "cancelled") {
          return;
        }
        if (error.code === 401 || error.code === 403) {
          throw error;
        }
        this.setState({
          showLoading: false,
          error,
        });
      });
  }
  render() {
    let { props } = this;
    let OrderListRow = orderListTableProperties(this.state.isMobileView).row;
    let orderCoordinates = props.values
      ? this.getOrderCoordinates(props.values)
      : null;
    let storeLocation = props.values
      ? this.getStoreLocation(props.values)
      : null;
    let details = props.values ? props.values : null;
    let ordersCount = 0;
    let orderHeading = getMessage("trip.details.orderList.heading");
    if (details && details.orders) {
      ordersCount = details.orders.length;
      if (ordersCount === 1) {
        orderHeading = getMessage("trip.details.orderList.singleOrder.heading");
      }
    }

    return (
      <React.Fragment>
        <div className="main-content">
          <DeliveryguyDetails data={props.values} />
          {orderCoordinates && (
            <MapWithPath
              coordinates={orderCoordinates}
              showStore={storeLocation ? storeLocation[0] : props.storeLocation}
              callMaps={false}
              showVehicle={
                this.state.vehicleCooordinate &&
                this.state.vehicleCooordinate.length
                  ? this.state.vehicleCooordinate[
                      this.state.vehicleCooordinate.length - 1
                    ]
                  : false
              }
              vehiclePath={this.state.vehicleCooordinate}
              showRealRoutes
              zopsmartMapUrls={this.state.zopsmartMapUrls}
            />
          )}
        </div>
        {details && details.orders && (
          <div className="trip-details-order-list">
            <h3>
              {" "}
              {`${ordersCount} ${orderHeading} ${getMessage(
                "trip.details.orderList.withthistrip"
              )}`}
            </h3>
            <div className="table-container">
              <Table>
                {details.orders.length && orderListTableProperties().headers ? (
                  <Header items={orderListTableProperties().headers} />
                ) : null}
                {details.orders.map((order, index) => {
                  let orderETAObj =
                    this.state &&
                    this.state.orderETAs &&
                    this.state.orderETAs[0] &&
                    this.state.orderETAs[0].orders &&
                    this.state.orderETAs[0].orders.find((obj) => {
                      return (
                        Number(obj.referenceNumber) ===
                        Number(order.referenceNumber)
                      );
                    });
                  return (
                    <OrderListRow
                      {...order}
                      id={index + 1}
                      eta={orderETAObj && orderETAObj["ETA"]}
                      key={"order-list-elm" + index}
                    />
                  );
                })}
              </Table>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

class TripDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      showLoading: true,
      apiError: null,
      showErrorDialog: false,
    };

    this.preventLoading = this.preventLoading.bind(this);
    this.fetchingInitialData = this.fetchingInitialData.bind(this);
    this.updatingTripData = this.updatingTripData.bind(this);
    this.hideErrorDialog = this.hideErrorDialog.bind(this);
  }

  hideErrorDialog() {
    this.setState({
      showErrorDialog: false,
    });
  }

  preventLoading() {
    this.setState({
      showLoading: false,
    });
  }

  updatingTripData(values) {
    let storeId = getDefaultStore(get("stores")).storeId;
    let id = this.props.match.params.id;
    let status = "CLOSE";
    const params = {
      storeId,
      status,
      id,
      endKm: values.endTripKm,
    };
    this.tripApi = new API({
      url: `/logistics-service/trip/${id}`,
    });

    return this.tripApi
      .put(params)
      .then((response) => {
        if (response.status === "SUCCESS") {
          let newState = response.data.trip;
          this.setState({
            data: newState,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message === "cancelled") {
          return;
        }
        if (error.code === 401 || error.code === 403) {
          throw error;
        }
        this.setState({
          apiError: error.message,
          showLoading: false,
          showErrorDialog: true,
        });
      });
  }

  fetchingInitialData() {
    let id = this.props.match.params.id;
    this.tripApi = new API({
      url: `/logistics-service/trip/${id}`,
    });
    this.tripApi
      .get()
      .then((response) => {
        if (response.status === "SUCCESS") {
          const trips = response.data.trip;
          if (trips.startTime) {
            trips.startTime = getStandradDateTime(
              utcToLocalTime,
              trips.startTime
            );
          }
          if (trips.endTime) {
            trips.endTime = getStandradDateTime(utcToLocalTime, trips.endTime);
          }
          if (trips.orders) {
            trips.orders.map((order) => {
              if (order.createdAt) {
                order.createdAt = getStandradDateTime(
                  utcToLocalTime,
                  order.createdAt
                );
              }
              if (order.completedAt) {
                order.completedAt = getStandradDateTime(
                  utcToLocalTime,
                  order.completedAt
                );
              }
              if (order.preferredDate && order.slotStartTime) {
                const tempDate = getStandradDateTime(
                  utcToLocalTime,
                  `${order.preferredDate} ${order.slotStartTime}`
                ).split(" ");
                order.preferredDate = tempDate[0];
                order.slotStartTime = tempDate[1];
              }
              if (order.preferredDate && order.slotEndTime) {
                const tempDate = getStandradDateTime(
                  utcToLocalTime,
                  `${order.preferredDate} ${order.slotEndTime}`
                ).split(" ");
                order.slotEndTime = tempDate[1];
              }
              return order;
            });
          }
          if (trips.vehicleStartTime) {
            trips.vehicleStartTime = getStandradDateTime(
              utcToLocalTime,
              trips.vehicleStartTime
            );
          }
          this.setState({
            data: trips,
            showLoading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message === "cancelled") {
          return;
        }
        if (error.code === 401 || error.code === 403) {
          throw error;
        }
        this.setState({
          showLoading: false,
          apiError: error.message,
        });
      });
  }

  componentDidMount() {
    this.orderConfigApi = new API({ url: "/config-service/config/order" });
    this.accountConfigApi = new API({ url: "/account-service/config" });
    Promise.all([this.orderConfigApi.get(), this.accountConfigApi.get()])
      .then(([orderConfigApiResponse, accountConfigApiResponse]) => {
        this.setState({
          paymentMethods:
            orderConfigApiResponse.data.order.paymentMethods?.["DELIVERY"] ||
            [],
          showOdometerImage:
            accountConfigApiResponse.data.config.logistics
              .shouldCaptureOdometer,
          storeLocation: {
            latitude: accountConfigApiResponse.data.config.basic.latitude,
            longitude: accountConfigApiResponse.data.config.basic.longitude,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });

    this.fetchingInitialData();
  }

  componentWillUnmount() {
    this.orderConfigApi && this.orderConfigApi.cancel();
    this.accountConfigApi && this.accountConfigApi.cancel();
    this.tripApi && this.tripApi.cancel();
  }

  render() {
    let props = this.props;
    let context = {
      id: props.match.params.id,
      storeId: getDefaultStore(props.stores).storeId,
      paymentMethods: this.state && this.state.paymentMethods,
      storeLocation: this.state && this.state.storeLocation,
      history: this.props.history,
      showOdometerImage: this.state && this.state.showOdometerImage,
    };

    let mainContent = (
      <TripIdContext.Provider value={context}>
        <div className="top-heading">
          <h1>{`${getMessage("trips.details.heading", {
            id: props.match.params.id,
          })}`}</h1>
          <span className="dropdown-actions">
            {
              <TripIdContext.Consumer>
                {(value) => {
                  return ["OPEN", "CLOSE"].indexOf(this.state.data.status) >
                    -1 ||
                    (value.showOdometerImage &&
                      (this.state.data.vehicleEndOdometerImage ||
                        this.state.data.vehicleStartOdometerImage)) ? (
                    <TripTableActions
                      id={this.state.data.id}
                      status={this.state.data.status}
                      vehicleStartOdometerImage={
                        this.state.data.vehicleStartOdometerImage
                      }
                      vehicleEndOdometerImage={
                        this.state.data.vehicleEndOdometerImage
                      }
                      startKm={this.state.data.startKm}
                      endKm={this.state.data.endKm}
                      showOdometerImage={value.showOdometerImage}
                      history={value.history}
                      updatingTripData={this.updatingTripData}
                    />
                  ) : (
                    <div>&nbsp;</div>
                  );
                }}
              </TripIdContext.Consumer>
            }{" "}
          </span>
        </div>
        <Dialog
          show={this.state.showErrorDialog}
          information={this.state.apiError}
          close={this.hideErrorDialog}
          closeText={getMessage("vehiclePlanner.error.dialog.okay")}
        />
        <AdditionalViewsWrapper values={this.state.data} />
      </TripIdContext.Provider>
    );
    if (this.state.showLoading) {
      mainContent = (
        <React.Fragment>
          <div className="top-heading">
            <h1>{`${getMessage("trips.details.heading", {
              id: props.match.params.id,
            })}`}</h1>
          </div>
          <Loader />
        </React.Fragment>
      );
    }

    return (
      <AuthenticatedPage
        className="trips-details-page"
        menu={props.menu}
        storeDependent
      >
        {mainContent}
      </AuthenticatedPage>
    );
  }
}

export default TripDetails;
