import React, { Component } from "react";
import "./style.css";
import API from "../../../../lib/api";
import { parse, format } from "libphonenumber-js";
import {
  getSession,
  isExtensionEnabled,
  getEntityMetaData,
  getExtensionDetails,
  saveEntityMetaData,
  hasPermissions,
} from "../../../../lib/auth";
import { getDisplayAddress } from "../../../../lib/commonlyused";
import InfoCard from "../../../../components/InfoCard";
import Tabs from "../../../../components/Tabs/index";
import Loader from "../../../../components/Loader";
import CustomerDetailsCard from "../../../../components/CustomerDetailsCard";
import PhoneAndEmailDisplay from "../../../../components/PhoneAndEmailCard";
import AddressCard from "./AddressCard";
import Popup from "../../../../components/Popup/Popup";
import Dialog from "../../../../components/Popup/Dialog";
import {
  getStandradDateTime,
  utcToLocalTime,
} from "../../../../lib/datetime/index";
import CallForm from "./CallForm";
import MailForm from "./MailForm";
import SmsForm from "./SmsForm";
import AddEmailForm from "./AddEmail";
import AddPhoneForm from "./AddPhoneNumber";
import AddAddressForm from "./AddAddress";
import MetaDataForm from "./MetaDataForm";
import OrdersImage from "./total-orders.svg";
import PurchasedImage from "./purchase-worth.svg";
import JoinedImage from "./joined-on.svg";
import plusIcon from "./plus-green.svg";
import LastPurchaseImage from "./last-purchase.svg";
import { getMessage } from "../../../../lib/translator/index";
import { getPrintableTime } from "../../../../lib/datetime/index";
import ListingPage from "../../../../containers/ListingPage";
import { tabularView } from "../../Orders/Table/Views";
import { Provider } from "../../Orders/Table/index";
import { set } from "../../../../lib/storage";
import purchaseHistory from "./purchase-history.svg";
import { Select } from "../../../../components/Form/Inputs";

const emptyState = {
  icon: purchaseHistory,
  message: getMessage("customer.details.purchase.emptyText"),
};
export default class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      showLoader: true,
      showCallPopup: false,
      showPopup: false,
      showDialog: false,
      showSmsPopup: false,
      showAddEmailPopup: false,
      showAddPhonePopup: false,
      showAddAddressPopup: false,
      showPhoneDeletePrompt: false,
      showEmailDeletePrompt: false,
      phoneIdToDelete: null,
      emailIdToDelete: null,
    };
    this.pages = [
      getMessage("customer.details.pages.basicinfo"),
      getMessage("customer.details.pages.address"),
      getMessage("customer.details.pages.phoneemail"),
    ];

    set("customer-details-orders-page", "1");

    this.message = "";
    this.currency = getSession().organization.currency.symbol;
    this.hideLoader = this.hideLoader.bind(this);
    this.showLoader = this.showLoader.bind(this);
    this.mailCallback = this.mailCallback.bind(this);
    this.smsCallback = this.smsCallback.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSmsClose = this.handleSmsClose.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.renderDialog = this.renderDialog.bind(this);
    this.addEmailCallback = this.addEmailCallback.bind(this);
    this.handleAddEmailClose = this.handleAddEmailClose.bind(this);
    this.addPhoneNumberCallback = this.addPhoneNumberCallback.bind(this);
    this.handleAddPhoneClose = this.handleAddPhoneClose.bind(this);
    this.deletePhoneNumberCallback = this.deletePhoneNumberCallback.bind(this);
    this.deleteEmailCallback = this.deleteEmailCallback.bind(this);
    this.addAddressCallback = this.addAddressCallback.bind(this);
    this.handleAddAddressClose = this.handleAddAddressClose.bind(this);
    this.deleteAddressCallback = this.deleteAddressCallback.bind(this);
    this.callCallback = this.callCallback.bind(this);
    this.handleCallClose = this.handleCallClose.bind(this);
    this.handlePhoneDeleteCloseDialog =
      this.handlePhoneDeleteCloseDialog.bind(this);
    this.handleEmailDeleteCloseDialog =
      this.handleEmailDeleteCloseDialog.bind(this);
    this.handlePhoneDeleteShowDialog =
      this.handlePhoneDeleteShowDialog.bind(this);
    this.handleEmailDeleteShowDialog =
      this.handleEmailDeleteShowDialog.bind(this);
    this.setDefaultPhoneOrEmail = this.setDefaultPhoneOrEmail.bind(this);
  }

  showLoader() {
    this.setState({
      showLoader: true,
    });
  }

  hideLoader() {
    this.setState({
      showLoader: false,
    });
  }

  componentDidMount() {
    if (isExtensionEnabled("CustomerTags")) {
      this.pages.push(getMessage("customer.details.pages.otherdetails"));
    } else {
      if (isExtensionEnabled("EntityMetaData")) {
        let customerKeys = getEntityMetaData() && getEntityMetaData().customer;
        if (!customerKeys) {
          let extentionDetails = getExtensionDetails("EntityMetaData");
          if (extentionDetails) {
            let id = extentionDetails.id;
            const api = new API({ url: `/account-service/extension/${id}` });
            api
              .get()
              .then((response) => {
                let details = response.data.extension.config.globalConfig;
                saveEntityMetaData(details);
                customerKeys =
                  getEntityMetaData() && getEntityMetaData().customer;
                this.addressKeys = details.address;
                if (customerKeys && Object.keys(customerKeys).length > 0) {
                  this.pages.push(
                    getMessage("customer.details.pages.otherdetails")
                  );
                }
              })
              .catch((error) => {
                console.error(error);
              });
          }
        } else {
          if (customerKeys && Object.keys(customerKeys).length > 0) {
            this.pages.push(getMessage("customer.details.pages.otherdetails"));
          }
        }
      }
    }
    this.api = new API({
      url: `/customer-service/customer/${this.props.resourceId}`,
    });
    this.api
      .get("", { "X-API-VERSION": 2 })
      .then(
        (response) => {
          this.setState({
            customer: response.data.customer,
            showLoader: false,
          });
        },
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
        console.error(error);
      });
    this.countryApi = new API({ url: "/account-service/country" });
    this.countryApi
      .get()
      .then((response) => {
        let isoCode = response.data.country.isoCode;
        this.isoCode = isoCode;
      })
      .catch((error) => {
        console.error(error);
      });
    this.communicationApi = new API({
      url: `/account-service/config/communication`,
    });
    this.communicationApi
      .get()
      .then((response) => {
        this.setState({ communicationConfig: response.data.config });
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
    if (isExtensionEnabled("EntityMetaData")) {
      this.configApi = new API({ url: "/config-service/config/customers" });
      this.configApi
        .get()
        .then((response) => {
          this.addressSequence = response.data.customers.addressSequence;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
    this.countryApi && this.countryApi.cancel();
    this.communicationApi && this.communicationApi.cancel();
    this.configApi && this.configApi.cancel();
  }

  fetchTabData() {
    let page = this.pages[this.state.page];
    if (page === getMessage("customer.details.pages.basicinfo")) {
      return (
        <div className="tab-details">
          <div className="tab-row">
            <InfoCard
              className="total-orders"
              src={OrdersImage}
              title={getMessage("customer.details.pages.basicinfo.totalorders")}
              description={this.state.customer.totalOrders}
            />
            <InfoCard
              className="total-amount"
              src={PurchasedImage}
              title={getMessage(
                "customer.details.pages.basicinfo.purchasesworth"
              )}
              description={
                this.state.customer.orderSummary &&
                this.currency + this.state.customer.orderSummary.totalAmount
              }
            />
          </div>
          <div className="tab-row">
            <InfoCard
              className="joined-on"
              src={JoinedImage}
              title={getMessage("customer.details.pages.basicinfo.joined")}
              description={
                this.state.customer.joinedTime &&
                getPrintableTime(
                  getStandradDateTime(
                    utcToLocalTime,
                    this.state.customer.joinedTime
                  )
                ).split(",")[0]
              }
            />
            <InfoCard
              className="last-purchase"
              src={LastPurchaseImage}
              title={getMessage(
                "customer.details.pages.basicinfo.lastPurchase"
              )}
              description={
                this.state.customer.lastOrderedOn
                  ? getPrintableTime(
                      getStandradDateTime(
                        utcToLocalTime,
                        this.state.customer.lastOrderedOn
                      )
                    ).split(",")[0]
                  : "-"
              }
            />
          </div>
        </div>
      );
    }
    if (page === getMessage("customer.details.pages.address")) {
      return (
        <div className="tab-details">
          {this.getAddresses()}
          {hasPermissions("customer", "address", "post") && (
            <div className="addAddress" onClick={this.addAddressCallback}>
              <div className="addIcon" />
              <div className="addTitle">
                {getMessage("customer.details.address.addAddress")}
              </div>
            </div>
          )}
        </div>
      );
    }
    if (page === getMessage("customer.details.pages.phoneemail")) {
      return (
        <div className="tab-details">
          <div className="phone-email">
            <div className="phone">
              {this.getPhoneNumbers()}
              {hasPermissions("customer", "phone", "post") && (
                <button
                  className="add-button"
                  onClick={this.addPhoneNumberCallback}
                >
                  <span className="plus-sign">
                    <img src={plusIcon} alt="plus" />
                  </span>
                  {getMessage(
                    "customer.details.pages.phonemail.addPhoneNumber"
                  )}
                </button>
              )}
            </div>
            <div className="email">
              {this.getEmails()}
              {hasPermissions("customer", "email", "post") && (
                <button className="add-button" onClick={this.addEmailCallback}>
                  <span className="plus-sign">
                    <img src={plusIcon} alt="plus" />
                  </span>
                  {getMessage("customer.details.pages.phonemail.addEmailId")}{" "}
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }
    if (page === getMessage("customer.details.pages.otherdetails")) {
      return (
        <React.Fragment>
          <MetaDataForm customer={this.state.customer} />
        </React.Fragment>
      );
    }
  }
  changePage(page) {
    this.setState(
      {
        page: page,
      },
      this.fetchPageData
    );
  }

  getAddresses() {
    let _this = this;
    let showDeleteOption = false;
    const customerId = this.state.customer.id;
    const customerAddresses = this.state.customer.addresses;
    let defaultAddress = this.state.customer.defaultAddress;
    if (customerAddresses && customerAddresses.length > 1) {
      showDeleteOption = true;
    }
    let address =
      customerAddresses &&
      customerAddresses.map(function (address, i) {
        return (
          <AddressCard
            key={address.id}
            customerId={customerId}
            coordinates={{ lat: address.latitude, lng: address.longitude }}
            id={address.id}
            showDeleteOption={
              showDeleteOption && defaultAddress.id !== address.id
            }
            title={`${getMessage("customer.details.pages.address")} ${i + 1}`}
            value={`${getDisplayAddress(address, _this.addressSequence)}`}
            viewOnMap={getMessage("customer.details.pages.address.viewOnMap")}
            deleteMessage={getMessage("customer.details.delete")}
            onDelete={_this.deleteAddressCallback}
          />
        );
      });
    return address;
  }

  getPhoneNumbers() {
    let _this = this;
    let showDeleteOption = false;
    const customerPhones = this.state.customer.phones;
    if (customerPhones && customerPhones.length > 1) {
      showDeleteOption = true;
    }
    let defaultPhoneId =
      (this.state.customer && this.state.customer.defaultPhoneId) ||
      (this.state.customer.defaultPhone && this.state.customer.defaultPhone.id);
    let phones =
      customerPhones &&
      customerPhones.map(function (phone, i) {
        return (
          <PhoneAndEmailDisplay
            key={phone.id}
            status={phone.status}
            id={phone.id}
            defaultId={defaultPhoneId}
            value={
              phone && phone.phone
                ? Object.keys(parse(phone.phone)).length > 0
                  ? format(parse(phone.phone), "International")
                  : phone.phone
                : null
            }
            type="phone"
            onDelete={_this.handlePhoneDeleteShowDialog}
            setDefaultPhoneOrEmail={_this.setDefaultPhoneOrEmail}
            showDeleteOption={showDeleteOption}
          />
        );
      });
    return phones;
  }

  setDefaultPhoneOrEmail(type, id) {
    if (!type || !id) {
      return;
    }
    const customerId = this.state.customer.id;
    const api = new API({ url: `/customer-service/customer/${customerId}` });
    let params = {};
    if (type === "phone") {
      params.defaultPhoneId = id;
    } else if (type === "email") {
      params.defaultEmailId = id;
    }
    api
      .put(params, { "X-API-VERSION": 2 })
      .then((response) => {
        let customer = response.data.customer;
        this.setState({
          customer,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteEmailCallback(id) {
    let customerId = this.state.customer.id;
    const api = new API({ url: `/customer-service/email/${id}` });
    let params = {};
    params["customerId"] = customerId;
    api
      .delete(params)
      .then(
        (response) => {
          this.message = getMessage("customer.details.email.deleted");
          let newCustomer = this.state.customer;
          let modifiedEmails = (this.state.customer.emails || []).filter(
            (email) => email.id !== id
          );
          newCustomer.emails = modifiedEmails;
          this.setState({
            showEmailDeletePrompt: false,
            showDialog: true,
            customer: newCustomer,
          });
        },
        (error) => {
          this.message = error.message;
          this.setState({
            showEmailDeletePrompt: false,
            showDialog: true,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  deleteAddressCallback(id) {
    let customerId = this.state.customer.id;
    const api = new API({ url: `/customer-service/address/${id}` });
    let params = {};
    params["customerId"] = customerId;
    api
      .delete(params)
      .then(
        (response) => {
          this.message = getMessage("customer.details.address.deleted");
          let newDetails = this.state.customer;
          let modifiedAddresses = this.state.customer.addresses.filter(
            (address) => address.id !== id
          );
          newDetails.addresses = modifiedAddresses;
          this.setState({
            showDialog: true,
            customer: newDetails,
          });
        },
        (error) => {
          this.message = error.message;
          this.setState({
            showDialog: true,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  deletePhoneNumberCallback(id) {
    let customerId = this.state.customer.id;
    const api = new API({ url: `/customer-service/phone/${id}` });
    let params = {};
    params["customerId"] = customerId;
    api
      .delete(params)
      .then(
        (response) => {
          this.message = getMessage("customer.details.phone.deleted");
          let newDetails = this.state.customer;
          let modifiedPhones = (this.state.customer.phones || []).filter(
            (phone) => phone.id !== id
          );
          newDetails.phones = modifiedPhones;
          this.setState({
            showPhoneDeletePrompt: false,
            showDialog: true,
            customer: newDetails,
          });
        },
        (error) => {
          this.message = error.message;
          this.setState({
            showPhoneDeletePrompt: false,
            showDialog: true,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  getEmails() {
    let _this = this;
    let showDeleteOption = false;
    const customerEmails = this.state.customer.emails;
    if (customerEmails && customerEmails.length > 1) {
      showDeleteOption = true;
    }

    let defaultEmailId =
      (this.state.customer && this.state.customer.defaultEmailId) ||
      (this.state.customer.defaultEmail && this.state.customer.defaultEmail.id);
    let emails =
      customerEmails &&
      customerEmails.map(function (email, i) {
        if (!email) return null;
        return (
          <PhoneAndEmailDisplay
            key={email.id}
            status={email.status}
            id={email.id}
            value={email.email}
            defaultId={defaultEmailId}
            type="email"
            setDefaultPhoneOrEmail={_this.setDefaultPhoneOrEmail}
            onDelete={_this.handleEmailDeleteShowDialog}
            showDeleteOption={showDeleteOption}
          />
        );
      });
    return emails;
  }

  getColor(status) {
    // TODO: Find color for pending and refund status
    let color = "";
    switch (status) {
      case "COMPLETED":
        color = "green";
        break;
      case "PENDING":
        color = "";
        break;
      case "REFUND":
        color = "";
        break;
      default:
        color = "";
    }
    return color;
  }

  callCallback() {
    this.setState({
      showCallPopup: true,
    });
  }

  mailCallback() {
    this.setState({
      showPopup: true,
    });
  }

  smsCallback() {
    this.setState({
      showSmsPopup: true,
    });
  }

  addEmailCallback() {
    this.setState({
      showAddEmailPopup: true,
    });
  }

  addPhoneNumberCallback() {
    this.setState({
      showAddPhonePopup: true,
    });
  }

  addAddressCallback() {
    this.setState({
      showAddAddressPopup: true,
    });
  }

  handleClose() {
    this.setState({
      showPopup: false,
    });
  }

  handleCallClose() {
    this.setState({
      showCallPopup: false,
    });
  }

  handleSmsClose() {
    this.setState({
      showSmsPopup: false,
    });
  }

  handleAddEmailClose() {
    this.setState({
      showAddEmailPopup: false,
    });
  }

  handleAddPhoneClose() {
    this.setState({
      showAddPhonePopup: false,
    });
  }

  handleAddAddressClose() {
    this.setState({
      showAddAddressPopup: false,
    });
  }

  handleCloseDialog() {
    this.setState({
      showDialog: false,
    });
  }

  handlePhoneDeleteCloseDialog() {
    this.setState({
      showPhoneDeletePrompt: false,
    });
  }

  handleEmailDeleteCloseDialog() {
    this.setState({
      showEmailDeletePrompt: false,
    });
  }

  handlePhoneDeleteShowDialog(id) {
    this.setState({
      showPhoneDeletePrompt: true,
      phoneIdToDelete: id,
    });
  }

  handleEmailDeleteShowDialog(id) {
    this.setState({
      showEmailDeletePrompt: true,
      emailIdToDelete: id,
    });
  }

  renderDialog(data, response = {}) {
    if (data === "render" && !this.state.showLoader && this.state.showPopup) {
      this.message = getMessage("customer.details.email.send");
      this.setState({
        showPopup: false,
        showDialog: true,
      });
    } else if (
      data === "call" &&
      !this.state.showLoader &&
      this.state.showCallPopup
    ) {
      this.message = getMessage("customer.details.call.call");
      this.setState({
        showCallPopup: false,
        showDialog: true,
      });
    } else if (
      data === "sms" &&
      !this.state.showLoader &&
      this.state.showSmsPopup
    ) {
      this.message = getMessage("customer.details.sms.send");
      this.setState({
        showSmsPopup: false,
        showDialog: true,
      });
    } else if (
      data === "addedEmail" &&
      !this.state.showLoader &&
      this.state.showAddEmailPopup &&
      response
    ) {
      this.message = getMessage("customer.details.email.added");
      let newCustomer = this.state.customer;
      newCustomer.emails = newCustomer.emails || [];
      newCustomer.emails.push(response);
      this.setState({
        showAddEmailPopup: false,
        showDialog: true,
        customer: newCustomer,
      });
    } else if (
      data === "addedAddress" &&
      !this.state.showLoader &&
      this.state.showAddAddressPopup &&
      response
    ) {
      this.message = getMessage("customer.details.address.added");
      let newCustomer = this.state.customer;
      newCustomer.addresses = newCustomer.addresses || [];
      newCustomer.addresses.push(response);
      this.setState({
        showAddAddressPopup: false,
        showDialog: true,
        customer: newCustomer,
      });
    } else if (
      data === "addedPhone" &&
      !this.state.showLoader &&
      this.state.showAddPhonePopup &&
      response
    ) {
      this.message = getMessage("customer.details.phone.added");
      let newCustomer = this.state.customer;
      newCustomer.phones = newCustomer.phones || [];
      newCustomer.phones.push(response);
      this.setState({
        showAddPhonePopup: false,
        showDialog: true,
        customer: newCustomer,
      });
    }
    if (this.state.showDialog) {
      return (
        <Dialog
          className="success"
          show={this.state.showDialog}
          close={this.handleCloseDialog}
          information={this.message}
          closeText="OK"
        />
      );
    }
  }

  renderCallPopup() {
    return (
      <Popup
        heading={getMessage("customer.details.call")}
        show={this.state.showCallPopup}
        close={this.handleCallClose}
      >
        <CallForm
          number={
            this.state.customer.phones &&
            this.state.customer.phones.length > 0 &&
            this.state.customer.phones?.[0]?.phone
          }
          onSuccess={this.renderDialog}
        />
      </Popup>
    );
  }

  renderMailPopup() {
    if (!this.state.customer?.emails?.[0]) return null;
    return (
      <Popup
        heading={getMessage("customer.details.sendEmail")}
        show={this.state.showPopup}
        close={this.handleClose}
      >
        <MailForm
          to={
            this.state.customer.emails &&
            this.state.customer.emails.length > 0 &&
            this.state.customer.emails?.[0]?.email
          }
          onSuccess={this.renderDialog}
        />
      </Popup>
    );
  }

  renderSmsPopup() {
    return (
      <Popup
        heading={getMessage("customer.details.sendMessage")}
        show={this.state.showSmsPopup}
        close={this.handleSmsClose}
      >
        <SmsForm
          to={
            this.state.customer.phones &&
            this.state.customer.phones.length > 0 &&
            this.state.customer.phones?.[0]?.phone
          }
          onSuccess={this.renderDialog}
        />
      </Popup>
    );
  }

  renderAddEmailPopup() {
    return (
      <Popup
        heading={getMessage("customer.details.pages.phonemail.addEmailId")}
        show={this.state.showAddEmailPopup}
        close={this.handleAddEmailClose}
      >
        <AddEmailForm
          customerId={this.state.customer.id}
          onSuccess={this.renderDialog}
        />
      </Popup>
    );
  }

  renderAddPhonePopup() {
    return (
      <Popup
        heading={getMessage("customer.details.pages.phonemail.addPhoneNumber")}
        show={this.state.showAddPhonePopup}
        close={this.handleAddPhoneClose}
      >
        <AddPhoneForm
          customerId={this.state.customer.id}
          onSuccess={this.renderDialog}
          countryIso={this.isoCode}
        />
      </Popup>
    );
  }

  renderAddAddressPopup() {
    return (
      <Popup
        heading={getMessage("customer.details.address.addAddress")}
        show={this.state.showAddAddressPopup}
        close={this.handleAddAddressClose}
      >
        <AddAddressForm
          customerId={this.state.customer.id}
          onSuccess={this.renderDialog}
          sequence={this.addressSequence}
        />
      </Popup>
    );
  }

  renderPhoneDeleteDialog() {
    return (
      <Dialog
        show={this.state.showPhoneDeletePrompt}
        close={this.handlePhoneDeleteCloseDialog}
        title={getMessage("customer.phone.delete.prompt.title")}
        information={getMessage("customer.phone.delete.prompt.message")}
        closeText={getMessage("customer.cancel.message")}
        onOk={() => {
          this.deletePhoneNumberCallback(this.state.phoneIdToDelete);
        }}
        okText={getMessage("customer.confirm.message")}
      />
    );
  }

  renderEmailDeleteDialog() {
    return (
      <Dialog
        show={this.state.showEmailDeletePrompt}
        close={this.handleEmailDeleteCloseDialog}
        title={getMessage("customer.email.delete.prompt.title")}
        information={getMessage("customer.email.delete.prompt.message")}
        closeText={getMessage("customer.cancel.message")}
        onOk={() => {
          this.deleteEmailCallback(this.state.emailIdToDelete);
        }}
        okText={getMessage("customer.confirm.message")}
      />
    );
  }

  render() {
    return (
      <div className="customerDetails">
        <h1 className="heading">{this.state?.customer?.name}</h1>
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <div>
            {this.state.customer && (
              <div>
                {this.renderCallPopup()}
                {this.renderMailPopup()}
                {this.renderDialog()}
                {this.renderSmsPopup()}
                {this.renderAddEmailPopup()}
                {this.renderAddPhonePopup()}
                {this.renderAddAddressPopup()}
                {this.renderPhoneDeleteDialog()}
                {this.renderEmailDeleteDialog()}
                <div className="tabWithCustomerCard">
                  <div className="tab-container">
                    {window.screen.width >= 480 ? (
                      <Tabs
                        items={this.pages.map((page) => `${page}`)}
                        default={this.state.page}
                        onClick={(index) => {
                          this.changePage(index);
                        }}
                      />
                    ) : (
                      <Select
                        options={this.pages.map((page, index) => {
                          return { text: page, value: index };
                        })}
                        value={`${this.state.page}`}
                        onChange={(index) =>
                          this.changePage(Number(index) ? index : "0")
                        }
                      />
                    )}
                    {this.fetchTabData()}
                  </div>
                  <CustomerDetailsCard
                    communicationConfig={this.state.communicationConfig}
                    image={this.state.customer && this.state.customer.image}
                    emails={this.state.customer && this.state.customer.emails}
                    phones={this.state.customer && this.state.customer.phones}
                    defaultPhone={
                      this.state.customer && this.state.customer.defaultPhone
                    }
                    defaultEmail={
                      this.state.customer && this.state.customer.defaultEmail
                    }
                    defaultEmailId={
                      this.state.customer && this.state.customer.defaultEmailId
                    }
                    defaultPhoneId={
                      this.state.customer && this.state.customer.defaultPhoneId
                    }
                  />
                </div>
              </div>
            )}
            <h3>{getMessage("customer.details.order.purchase.history")}</h3>
            <Provider value={this.state && this.state.communicationConfig}>
              <div className="orders-page">
                <ListingPage
                  className="customer-details-orders-page"
                  api={{
                    url: `/order-service/order`,
                    params: {
                      perPage: 5,
                      customerId: this.props.resourceId,
                    },
                    transform: (response) => {
                      const orders = response.data.order;
                      if (Array.isArray(orders)) {
                        orders.map((order) => {
                          if (order.createdAt) {
                            order.createdAt = getStandradDateTime(
                              utcToLocalTime,
                              order.createdAt
                            );
                          }
                          if (order.completedAt) {
                            order.completedAt = getStandradDateTime(
                              utcToLocalTime,
                              order.completedAt
                            );
                          }
                          if (order.updatedAt) {
                            order.updatedAt = getStandradDateTime(
                              utcToLocalTime,
                              order.updatedAt
                            );
                          }
                          if (order.preferredDate && order.slotStartTime) {
                            const tempDate = getStandradDateTime(
                              utcToLocalTime,
                              `${order.preferredDate} ${order.slotStartTime}`
                            ).split(" ");
                            order.preferredDate = tempDate[0];
                            order.slotStartTime = tempDate[1];
                          }
                          if (order.preferredDate && order.slotEndTime) {
                            const tempDate = getStandradDateTime(
                              utcToLocalTime,
                              `${order.preferredDate} ${order.slotEndTime}`
                            ).split(" ");
                            order.slotEndTime = tempDate[1];
                          }
                          return order;
                        });
                      }
                      return orders;
                    },
                  }}
                  emptyState={emptyState}
                  primaryKey="referenceNumber"
                  tableProperties={tabularView(
                    `/${this.props.options && this.props.options.page}`
                  )}
                  tableDynamic
                />
              </div>
            </Provider>
          </div>
        )}
      </div>
    );
  }
}
