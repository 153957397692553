import React, { Component } from "react";
import SimpleAreaChart from "./area-chart";
import "./style.css";

export default class AnalyticDisplayWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: null,
    };
    this.generateChartData = this.generateChartData.bind(this);
  }

  generateChartData(dailyusage, type) {
    let keys = Object.keys(dailyusage);
    let splitString = "total";
    let propertyType = type;
    if (type.indexOf(splitString) !== -1) {
      type =
        "Total " +
        type.slice(1, type.indexOf(splitString)) +
        type.slice(type.indexOf(splitString) + splitString.length, type.length);
    }
    let chartData = [];
    chartData = keys.map((key) => {
      let ob = {};
      ob.name = key;
      ob[type] = dailyusage[key][propertyType];
      return ob;
    });
    return chartData;
  }

  componentDidMount() {
    let chartData = this.generateChartData(
      this.props.dailyusage,
      this.props.type
    );
    this.setState({
      chartData: chartData,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.dailyusage !== this.props.dailyusage ||
      prevProps.type !== this.props.type
    ) {
      let chartData = this.generateChartData(
        this.props.dailyusage,
        this.props.type
      );
      this.setState({
        chartData: chartData,
      });
    }
  }

  render() {
    let type = this.props.type;
    let splitString = "total";
    if (this.props.type && type.indexOf(splitString) !== -1) {
      type =
        "Total " +
        type.slice(1, type.indexOf(splitString)) +
        type.slice(type.indexOf(splitString) + splitString.length, type.length);
    }
    return (
      <section className="analytic-display-widget">
        {(this.props.count === 0 || this.props.count) && (
          <div className="top-data">
            <span>{this.props.count.toLocaleString()}</span>
            <span>{this.props.itemType}</span>
          </div>
        )}
        <SimpleAreaChart
          height={120}
          width={300}
          chartColor={this.props.chartColor}
          chartData={this.state.chartData}
          type={type}
        />
        <div className="details">
          <div>
            <span>
              {(this.props.bottomLeftData === 0 || this.props.bottomLeftData) &&
                this.props.bottomLeftData}
            </span>
            <span>{this.props.bottomLeftType}</span>
          </div>
          <div>
            <span>
              {(this.props.bottomRightData === 0 ||
                this.props.bottomRightData) &&
                this.props.bottomRightData}
            </span>
            <span>{this.props.bottomRightType}</span>
          </div>
        </div>
      </section>
    );
  }
}
