const data = {
  "onboarding.basicInfo.heading": "Provide Store Information",
  "onboarding.basicInfo.sencondaryHeading":
    "Enter your store information below and",
  "onboarding.basicInfo.subDomain.text": "subdomain",
  "onboarding.basicInfo.storeurl.description":
    "Your store URL is provided free of cost at smartstore.express. If you already own a domain",
  "onboarding.basicInfo.clickHere.text": "click here",
  "onboarding.basicInfo.register.text": "register",
  "onboarding.basicInfo.newOne.text": "a new one",
  "onboarding.basicInfo.useIt.text": "to use it or",
  "onboarding.industry.heading": "What is your industry",
  "onboarding.industry.sencondaryHeading": "Select an industry below",
  "onboarding.products.heading": "Add products to your website",
  "onboarding.products.sencondaryHeading":
    "Use below 10 sample products to create your Grocery website now and",
  "onboarding.theme.heading": "Choose your theme",
  "onboarding.theme.sencondaryHeading":
    "Here is your default theme for your Grocery website and",
  "onboarding.continue.text": "Continue",
  "onboarding.back.text": "Back",
  "onboarding.createWebsite.text": "Create your website now",
  "onboarding.seeMoreIndustries.text": "See more industries here",
  "onboarding.changeLater.text": "you can change later here",

  "analytics.heading": "Analytics",
  "analytics.tracking.heading": "GOOGLE ANALYTICS TRACKING ID",
  "analytics.tracking.placeholder": "UA-XXXXXXXX-X",
  "analytics.conversionPixel.heading": "CONVERSION PIXEL",
  "analytics.tracking.description":
    "You can find your tracking ID by going to Google Analytics Admin > Property > Tracking Info > Tracking Code. Tracking ID will be of form UA-XXXXXXXX-X.",
  "analytics.conversionPixel.description":
    "Google analytics conversion will be automatically tracked, if tracking ID has been provided. Any additional conversion pixel code can be added here. The codes will be fired when a new order comes.",
  "analytics.submit.text": "Save",
  "analytics.cancel.text": "Cancel",
  "analytics.invalidTrackingId": "Enter a valid Tracking ID",

  "basicInfo.heading": "Basic Information",
  "basicInfo.storename.heading": "Store Name",
  "basicInfo.storename.support.phone": "Support Phone",
  "basicInfo.storename.support.phone.placeholder":
    "Enter a support phone number",
  "basicInfo.form.minimumOrderValue.placeholder":
    "Enter the minimum order value",
  "basicInfo.storename.support.email": "Support Email",
  "basicInfo.storename.support.email.placeholder":
    "Enter a support email address",
  "basicInfo.storename.description":
    "Store name is used in customer communications. It can also be used by themes to display your name in the website and mobile applications",
  "basicInfo.storename.placeholder": "Your store",
  "basicInfo.storeurl.heading": "Store Url",
  "basicInfo.storeurl.placeholder": "Enter your store name",
  "basicInfo.supportWWW": "SUPPORT WWW",
  "basicInfo.uploadImage.heading": "Upload Your Logo",
  "basicInfo.uploadImage.placeholder": "Click here to upload or drag your logo",
  "basicInfo.uploadImage.description":
    "Logo should be of at least 180x180 px and maximum of 2MB. Logo is used on your website, mobile applications and customer emails.",
  "basicInfo.customDomain.description":
    "Create a new CNAME record pointing to {domain}",
  "basicInfo.submit.text": "Save",
  "basicInfo.cancel.text": "Cancel",
  "basicInfo.invalidDomain": "Enter a valid domain name",
  "basicInfo.save.success.title": "Settings have been successfully saved",
  "basicInfo.save.success.closeText": "Okay",
  "basicInfo.save.error.title":
    "Something went wrong while saving. Please try again later",
  "basicInfo.save.error.closeText": "Okay",
  "basicInfo.form.location.heading": "Location",
  "basicInfo.form.location.placeholder": "Search for your location",
  "basicInfo.form.location.requiredMessage": "Please set store's location",
  "basicInfo.form.address.heading": "Address",
  "basicInfo.form.address.placeholder": "Enter your store's complete address",
  "basicInfo.uploadFavicon.heading": "Upload Your Favicon",
  "basicInfo.uploadFavicon.placeholder":
    "Click here to upload or drag your Favicon",
  "basicInfo.uploadFavicon.description":
    "Upload fav icon in 32x32 px (recommended) with transparent background.",

  "socialMedia.heading": "Social Media",
  "socialMedia.whatsappSupportEnabled": "Enable WhatsApp Support",
  "socialMedia.whatsappSupportPhone": "WhatsApp Support Phone",
  "socialMedia.whatsappSupportPhone.placeholder":
    "Enter WhatsApp Support Phone Number",
  "socialMedia.whatsappSupportEnquiryText": "Whatsapp Support Enquiry Text",
  "socialMedia.whatsappSupportEnquiryText.placeholder":
    "Enter Whatsapp Support Enquiry Text",
  "socialMedia.facebook.heading": "Official Facebook Page",
  "socialMedia.twitter.heading": "Official Twitter Page",
  "socialMedia.google.heading": "Official Google+ Page",
  "store.android.heading": "Official playstore Page",
  "store.ios.heading": "Official ios Page",
  "socialMedia.facebook.allowlogin": "Allow Facebook Login",
  "socialMedia.twitter.allowlogin": "Allow twitter Login",
  "socialMedia.google.allowlogin": "Allow google Login",
  "socialMedia.facebook.description":
    "If you have a facebook page for your online store, you can provide details here. You can also allow your customers to use their facebook account to login on your Store.",
  "socialMedia.twitter.description":
    "Themes can use the twitter handle to show your details on your online store. Platform can also automatically display tweets related to your handle.",
  "socialMedia.google.description":
    "Google page link can be displayed on the website and mobile app. You can also allow your customers to login using google account.",
  "store.android.description":
    "This will help you to download app from android playstore, So customer can get direct link for app and visit there",
  "store.ios.description":
    "This will help you to download app from ios store, So customer can get direct link for app and visit there",
  "socialMedia.facebook.placeholder": "username",
  "socialMedia.twitter.placeholder": "handle",
  "store.android.placeholder": "handle",
  "store.ios.placeholder": "handle",
  "socialMedia.google.placeholder": "account ID",
  "socialMedia.submit.text": "Save",
  "socialMedia.cancel.text": "Cancel",
  "socialMedia.save.success.title": "Settings have been successfully saved",
  "socialMedia.save.success.closeText": "Okay",
  "socialMedia.save.error.title":
    "Something went wrong while saving. Please try again later",
  "socialMedia.save.error.closeText": "Okay",
  "media.select.facebook": "Facebook",
  "media.select.twitter": "Twitter",
  "media.select.androidAppLink": "android",
  "media.select.iosAppLink": "ios",
  "socialMedia.accounts.placeholder": "Select social media",
  "store.accounts.placeholder": "Select app store",
  "contactDetails.heading": "Contact Details",
  "socialMedia.accounts.label": "Add social media",
  "store.accounts.label": "Add app links",
  "orderConfig.heading": "Order Config",
  "orderConfig.form.minimumOrderValue.heading": "Minimum order value",
  "orderConfig.form.payment.validate.error":
    "At least one payment option must be selected",
  "orderConfig.form.minimumOrderValueForDelivery.heading":
    "Minimum order value for delivery",

  "orderConfig.form.minimumOrderValueFreeDelivery.heading":
    "Minimum order value for free delivery",
  "orderConfig.form.minimumOrderValueFreePickup.heading":
    "Minimum order value for free pickup",
  "orderConfig.form.deliveryFees.heading": "Delivery Fees",
  "orderConfig.form.minimumOrderValue.placeholder":
    "Enter the minimum order value",
  "orderConfig.form.minimumOrderValueForDelivery.placeholder":
    "Enter the minimum order value for delivery",
  "orderConfig.form.minimumOrderValueForPickup.heading":
    "Minimum order value for pickup",
  "orderConfig.form.minimumOrderValueForPickup.placeholder":
    "Enter the minimum order value for pickup",
  "orderConfig.form.minimumOrderValueFreeDelivery.placeholder":
    "Enter the minimum order value for free delivery",
  "orderConfig.form.minimumOrderValueFreePickup.placeholder":
    "Enter the minimum order value for free pickup",
  "orderConfig.form.deliveryFees.placeholder": "Enter the delivery fees",
  "order.by.label.for.payment": "Order By",
  "choose.payment.mode.for.store": "choose the payment modes for your stores",
  "choose.payment.mode.for.order.type":
    "Choose the payment modes for order types",
  "order.management.order.type.cod": "COD",
  "order.management.order.type.online": "ONLINE",
  "operations.store.storeName.label": "Store Name",
  "operations.store.choose.payment.mode.for.store":
    "Choose the payment modes for your stores",

  "productConfig.heading": "Product Config",
  "product.config.checkoutAllowed": "Display Add to cart button on the website",
  "product.config.showPriceToggle": "Display price on the website",
  "product.config.yes": "Yes",
  "product.config.no": "No",
  "product.save": "Save",
  "product.cancel": "Cancel",

  "themes.heading": "Themes",
  "themes.subheading": "You are using {name} theme",
  "themes.customize.subheading": "You are customizing {name} theme",
  "themes.add.text": "Click here to add a layout",
  "themes.submit.text": "Save",
  "themes.cancel.text": "Cancel",
  "themes.change.text": "Change",
  "themes.customize.text": "Customize",
  "themes.change.heading": "Change Theme",
  "themes.selectTheme.text": "Use this theme",
  "themes.selection.heading": "Choose a layout",
  "themes.selection.add": "Add",
  "themes.layout.save.success": "Layouts saved!",
  "themes.error.Validation Exception: Missing required layouts - ProductCollection":
    "This layout cannot be deleted",
  "themes.layout.delete.warning": "This layout will be deleted",
  "themes.layout.save.title.success": "Success",
  "themes.layout.save.title.error": "Oops",
  "themes.dialog.okText": "Ok",
  "themes.dialog.closeText": "Cancel",
  "themes.dialog.promt.title": "Are you sure?",
  "themes.offers": "Offers",
  "themes.images": "Images",
  "themes.stock": "Stock",
  "themes.title": "Title",
  "themes.category.show": "Categories to show",
  "themes.category.show.layout": "Categories",
  "themes.brand.show": "Brands to show",
  "themes.brand.show.layout": "Brands",
  "themes.tag.show": "Tags to show",
  "themes.tag.show.layout": "Tags",
  "themes.searchable.label": "Override layout for",
  "themes.tag.filter.label": "Filter Should Appear",
  "themes.product": "products should have",
  "themes.sortinglogic": "Select sorting logic",
  "themes.cc.name": "Collection name",
  "themes.cc.name.placeholder": "Enter collection name",
  "themes.cc.categories": "Categories",
  "themes.cc.categories.placeholder": "Enter categories to show",
  "themes.cc.layoutType": "Layout type",
  "themes.cc.showSubCategory.inline": "Show subcategory links",
  "themes.cc.scroller": "Scroller",
  "themes.cc.grid": "Grid",
  "themes.cc.config": "Configs",
  "themes.cc.pdtCount.inline": "Show product count",
  "themes.bc.brands": "Brands",
  "themes.bc.categories.placeholder": "Enter brands to show",
  "themes.layoutType": "Layout Type",
  "themes.grid": "Grid",
  "themes.scroller": "Scroller",
  "themes.cc.subCategories": "Show subcategories collection",
  "themes.customize.nav": "Navigation",
  "themes.cc.select.category.text": "Category",
  "themes.cc.select.tag.text": "Tag",
  "themes.cc.tags": "Tags",
  "themes.cc.config.image": "Show as cover image",
  "themes.layout.timeline.heading": "Layout Timeline",
  "themes.layout.timeline.startDate.heading": "Start Date",
  "themes.layout.timeline.startTime.heading": "Start Time",
  "themes.layout.timeline.endDate.heading": "End Date",
  "themes.layout.timeline.endTime.heading": "End Time",
  "themes.layout.timeline.startTime.placeholder": "Select start time",
  "themes.layout.timeline.endTime.placeholder": "Select end time",
  "themes.loadMoreType": "Load more products using",
  "themes.infiniteScroll": "Infinite scroller",
  "themes.seeAll": "See all button",
  "themes.customize.announcementBar.text": "Have Announcement bar?",
  "themes.customize.announcementBar.placeHolder":
    "What you type here will be shown in the announcement bar of your website",
  "themes.customize.preview": "Preview:",
  "success.text.title": "Success",
  "themes.customize.save.success": "Successfully saved!",
  "themes.customize.error.msg": "Some error occurred while saving",
  "themes.customize.error.msg.title": "Failed",

  "imageSlideShow.addSlide": "Add Slide",

  "dualImageBanner.linkText": "Link Text",
  "dualImageBanner.image1": "image 1",
  "dualImageBanner.title": "title",
  "dualImageBanner.link": "link",
  "dualImageBanner.image2": "image 2",
  "dualImageBanner.subTitle": "sub title",
  "dualImageBanner.InputTitlePlaceholder": "Please enter title here",
  "dualImageBanner.InputLinkPlaceholder": "Please enter link here",
  "dualImageBanner.InputSubtitlePlaceholder": "Please enter sub title here",
  "dualImageGrid.linkText": "Link Text",
  "dualImageGrid.image1": "image 1",
  "dualImageGrid.title": "title",
  "dualImageGrid.link": "link",
  "dualImageGrid.image2": "image 2",
  "dualImageGrid.subTitle": "sub title",
  "dualImageGrid.enterTitle": "Please enter title here",
  "dualImageGrid.enterSubtitle": "Please enter subtitle here",
  "dualImageGrid.navigateUrl": "URL to navigate on clicking",

  "videoCarousel.addSlide": "Add Slide",
  "videoCarousel.videoUrl.title": "video URL",
  "videoCarousel.videoUrl.placeholder": "Enter embed Video URL",
  "videoCarousel.description.title": "Description",
  "videoCarousel.description.placeholder": "Enter the Description here",
  "videoCarousel.textColor.label": "text color",
  "videoCarousel.textColor.placeholder": "text color for description",
  "videoCarousel.fontSize.label": "font size (in px)",
  "videoCarousel.fontSize.placeholder": "font size for description in px",

  "extensions.icon": "Icon",
  "extensions.name": "Name",
  "extensions.addedOn": "Added On",
  "extensions.monthlyBillingEstimate": "Monthly Billing Estimate",
  "extensions.actions": "Actions",
  "extensions.install": "Install",
  "extensions.uninstall": "Uninstall",
  "extensions.installExtension": "Install Extension",
  "extensions.uninstallExtension": "Uninstall Extension",
  "extensions.monthlyEstimate": "Monthly Estimate",
  "extensions.version": "Version",
  "extensions.lastUpdated": "Last Updated",
  "extensions.activeInstallations": "Active Installations",
  "extensions.policy": "I have read and agreed with the privacy policy",
  "extensions.cancel": "Cancel",
  "extensions.installedSuccessfully": "Installed Successfully",
  "extensions.uninstalledSuccessfully": "Uninstalled Successfully",
  "extensions.enterExtensionName": "Enter Extension Name",
  "extensions.filters.submittext": "Submit",
  "extensions.searchByName": "Search by Name",
  "extensions.pricing": "Pricing",
  "extensions.refreshPrompt.title":
    "It is recommended that you logout and login again for changes to take full effect",
  "extensions.refreshPrompt.closeText": "Okay",
  "extension.details.notfound": "There are no settings for this extension",
  "extensions.configure": "Configure",
  "extensions.whatwillchange": "What will change?",
  "extensions.price": "Price",
  "extensions.error.dialog.okay": "Okay",

  "extensions.multiLingual.heading": "Multilingual Support",
  "extensions.multiLingual.placeholder": "Select Language(s)",

  "extensions.analytics.heading": "Analytics Extension Settings",
  "extensions.analytics.gaAccount.heading": "GA Account",
  "extensions.analytics.gaAccount.placeholder": "UA-XXXXXXXX-X",
  "extensions.analytics.gaAccount.description":
    "The Google Analytics Account registered to the customer",
  "extensions.analytics.conversionTag.heading": "Conversion Tag",
  "extensions.analytics.conversionTag.placeholder":
    "Paste code for conversion pixel here",
  "extensions.analytics.conversionTag.description":
    "JS Snippets to track conversions of users transacted on customer's website",
  "extensions.analytics.save.success.title":
    "Settings have been successfully saved",
  "extensions.analytics.save.success.closeText": "Okay",
  "extensions.analytics.save.error.title":
    "Something went wrong while saving. Please try again later",
  "extensions.analytics.save.error.closeText": "Okay",

  "extensions.deliveryArea.title": "Delivery Area",
  "extensions.deliveryArea.save": "Save",
  "extensions.deliveryArea.cancel": "Cancel",
  "extensions.deliveryArea.deliveryFee": "Delivery fee",
  "extensions.deliveryArea.store.error": "Select store",
  "extensions.deliveryArea.zones.heading": "Delivering Zones",
  "extensions.deliveryArea.pincodes.heading": "Zipcodes",
  "extensions.deliveryArea.zone.unnamed": "Unnamed Group",
  "extensions.deliveryArea.polygon.title": "Draw On Map",
  "extensions.deliveryArea.polygon.unnamed": "Unnamed Area",
  "extensions.deliveryArea.radial.title": "Distance From Store",
  "extensions.deliveryArea.radial.location.error":
    "Select a location on the map",
  "extensions.deliveryArea.apartment.heading": "Delivery by Apartment",
  "extensions.deliveryArea.delete.warning":
    "Would you like to delete this group?",
  "extensions.deliveryArea.delete.title": "Are you sure?",

  "extensions.tawk.live.chat": "Tawk.to Live Chat",
  "extensions.tawk.site.id": "Site Id",
  "extensions.tawk.site.id.placeholder": "Enter your Site Id",
  "extensions.tawk.site.id.description":
    "Your site ID can be found in the tawk website in ",
  "extensions.tawk.save": "Save",
  "extensions.tawk.cancel": "Cancel",
  "extensions.tawk.save.closetext": "Okay",
  "extensions.tawk.save.success": "Settings have been successfully saved",
  "extensions.tawk.save.error":
    "Something went wrong while saving. Please try again later",
  "extensions.tawk.error.closetext": "Okay",
  "extensions.tawk.widget.id": "Widget Id",
  "extensions.tawk.widget.id.placeholder": "Enter your Widget Id",
  "extensions.tawk.widget.id.description":
    "Your widget ID can be found in the tawk website in ",

  "seo.heading": "Custom SEO content",
  "extensions.seo.success": "Success",
  "extensions.seo.save.successful": "Settings successfully saved",
  "extensions.seo.title": "Title",
  "extensions.seo.title.explanation":
    "Choose a title that reads naturally and effectively communicates the topic of the page's content.",
  "extensions.seo.description": "Description",
  "extensions.seo.description.explanation":
    "Write a description that would both inform and interest users if they saw your description in a search result. It is recommended to make it long enough to be fully shown in Search, and it would contain the relevant information users would need to determine if the page is relevant to them.",
  "extensions.seo.keywords": "Keywords",
  "extensions.seo.keywords.explanation":
    "Think about the words that a user might search for to find a piece of your content. Users who know a lot about the topic might use different keywords in their search queries than someone who is new to the topic.",
  "extensions.seo.save": "Save",
  "extensions.seo.cancel": "Cancel",
  "extensions.seo.ok": "Okay",
  "extensions.seo.read.more": "Learn more",
  "extensions.seo.home": "home",
  "extensions.seo.categories": "categories",
  "extensions.seo.products": "products",
  "extensions.seo.tags": "tags",
  "extensions.seo.brands": "brands",

  "extensions.slot.heading": "Slots configuration",
  "extensions.slot.success": "Success",
  "extensions.slot.okay": "Okay",
  "extensions.slot.numOfDays": "HOW MANY DAYS TO SHOW TO CLIENT ?",
  "extensions.slot.numOfDays.placeholder": "0",
  "extensions.slot.save": "Save",
  "extensions.slot.cancel": "Cancel",
  "extensions.slot.error": "Error",
  "extension.slots.saved": "Settings have been successfully saved",
  "extensions.slot.businessTime.heading":
    "Business timing for ASAP slot availability",
  "extensions.slot.businessOpeningTime": "Opens at",
  "extensions.slot.businessClosingTime": "Closes at",

  "extensions.password-policy.heading": "Password Policy",
  "extensions.password-policy.label.minNumberOfLowercaseAlphabets":
    "minimum number of lower case alphabets",
  "extensions.password-policy.placeholder.minNumberOfLowercaseAlphabets":
    "Enter minimum number of lower case alphabets",

  "extensions.password-policy.label.minNumberOfNumericCharacters":
    "minimum Number Of Numeric Characters",
  "extensions.password-policy.placeholder.minNumberOfNumericCharacters":
    "Enter minimum number of numeric characters",

  "extensions.password-policy.label.minNumberOfSymbols":
    "minimum Number Of Symbols",
  "extensions.password-policy.placeholder.minNumberOfSymbols":
    "Enter minimum number of symbols",

  "extensions.password-policy.label.minNumberOfUppercaseAlphabets":
    "minimum number of upper case alphabets",
  "extensions.password-policy.placeholder.minNumberOfUppercaseAlphabets":
    "Enter minimum number of upper case alphabets",

  "extensions.password-policy.label.minPasswordLength":
    "minimum password length",
  "extensions.password-policy.placeholder.minPasswordLength":
    "Enter minimum password length",

  "mobileApps.heading": "Mobile Apps",
  "mobileApps.save.success.title": "Settings have been successfully saved",
  "mobileApps.save.success.closeText": "Okay",
  "mobileApps.save.error.title":
    "Something went wrong while saving. Please try again later",
  "mobileApps.save.error.closeText": "Okay",
  "mobileApps.name.heading": "Name of the App",
  "mobileApps.name.placeholder": "Enter your app's name",
  "mobileApps.shortDescription.heading": "Short description",
  "mobileApps.shortDescription.placeholder": "Enter upto 30 characters",
  "mobileApps.longDescription.heading": "Long description",
  "mobileApps.longDescription.placeholder": "Type here",
  "mobileApps.assets.heading": "Assets",
  "mobileApps.appIcon.heading": "App icon",
  "mobileApps.appIcon.placeholder": "2048 x 2048 px png transparent background",
  "mobileApps.appIcon.tooltip":
    "This image represents the app's icon on mobile devices.",
  "mobileApps.splashScreen.heading": "Splash screen",
  "mobileApps.splashScreen.placeholder":
    "2048 x 2048 px png transparent background",
  "mobileApps.splashScreen.tooltip":
    "Apps often take some time to to start up, especially when the app is first launched on a device. The splash screen is shown meanwhile, to display start up progress to the user or to indicate branding.",
  "mobileApps.theme.heading": "Select Theme",
  "mobileApps.theme.loadingText": "Loading themes",
  "mobileApps.submit.text": "Save",
  "mobileApps.cancel.text": "Cancel",

  "settings.users.heading": "Users",
  "settings.users.invite.text": "Invite User",
  "settings.users.header.name": "Name",
  "settings.users.header.phone": "Phone",
  "settings.users.header.email": "Email",
  "settings.users.header.actions": "Actions",
  "settings.users.action.revoke": "Revoke Invitation",
  "settings.users.action.editPermissions": "Edit Permissions",
  "settings.users.inviteForm.name.heading": "Name",
  "settings.users.inviteForm.name.placeholder": "Enter user's name",
  "settings.users.inviteForm.email.heading": "Email",
  "settings.users.inviteForm.email.placeholder": "Enter user's email",
  "settings.users.inviteForm.phone.heading": "Phone",
  "settings.users.inviteForm.phone.placeholder": "Enter user's phone number",
  "settings.users.inviteForm.invite.buttonText": "Invite",
  "settings.users.inviteForm.cancelText": "Cancel",
  "settings.users.permissionsForm.headingWithoutName": "Permissions",
  "settings.users.permissionsForm.heading": "Permissions for {name}",
  "settings.users.permissionsForm.header.read": "Read",
  "settings.users.permissionsForm.header.write": "Write",
  "settings.users.permissionsForm.copyPlaceholder.name": "No User",
  "settings.users.permissionsForm.copyPlaceholder.text":
    "Select a user to copy permissions",
  "settings.users.permissionsForm.submitText": "Save",
  "settings.users.permissionsForm.cancelText": "Cancel",

  "deliveryArea.table.heading": "Delivery Area",
  "deliveryArea.emptyList.message": "There are no delivery areas",
  "deliveryArea.dropdown.radial.heading": "Distance from store",
  "deliveryArea.dropdown.pincode.heading": "Zip Code / Postal Code",
  "deliveryArea.dropdown.polygon.heading": "Draw on a map",
  "deliveryArea.dialog.title": "Are You Sure?",
  "deliveryArea.disableDialog.information":
    "You want to disable this delivery area",
  "deliveryArea.enableDialog.information":
    "You want to enable this delivery area",
  "deliveryArea.dialog.cancelText": "Cancel",
  "deliveryArea.dialog.okText": "Ok",
  "deliveryArea.filters.status.heading": "Status",
  "deliveryArea.filters.status.label.all": "All",
  "deliveryArea.filters.status.label.enabled": "Enabled",
  "deliveryArea.filters.status.label.disabled": "Disabled",
  "deliveryArea.filters.configType.heading": "Type",
  "deliveryArea.filters.configType.label.all": "All",
  "deliveryArea.filters.configType.label.radial": "RADIAL",
  "deliveryArea.filters.configType.label.pincode": "ZIPCODE",
  "deliveryArea.filters.configType.label.polygon": "POLYGON",
  "deliveryArea.filters.submitText": "Search",
  "deliveryArea.slots.save": "Save",
  "deliveryArea.editSlot.heading": "Delivery area slot rules",
  "deliveryArea.slotConfigure": "Configure slots",
  "deliveryArea.dialog.error.title": "Error",
  "delivery.fee.formula.heading": "Configure Delivery Fee Formula:",
  "delivery.fee.formula.left.text": "Delivery Fee =",
  "delivery.fee.formula.right.text":
    "[Additional fixed cost] + ([Order total weight] - [Lower weight limit]) * [Rate per weight unit] + [Order subtotal] * [Charge percentage]",

  "radial.form.deliveryCharge.heading": "Delivery Charge",
  "radial.form.cancelText": "Cancel",
  "radial.form.submitText": "Save",
  "radial.form.stores.placeholder": "Select Store",
  "radial.form.stores.heading": "Served From Store",
  "radial.form.distance.description":
    "Define distance radius to cover for delivery",
  "radial.form.to": "to",
  "radial.form.map.description": "Drag and click to mark location",
  "radial.form.searchLocation.placeholder": "Search location on map",
  "radial.table.description": "from the store location",
  "radial.table.deliveryCharge": "Delivery Charge",
  "radial.selectLocation.message": "Select a Location",
  "radial.form.add.heading": "Distance from store",
  "radial.form.edit.heading": "Edit distance from store",

  "rackmanagement.heading": "Rack Management",
  "rackManagement.rack.add": "Name",
  "rackManagement.submitText.submit": "Add",
  "rackManagement.rack.add.placeholder":
    "Enter picking sequence (comma separated)",
  "rackManagement.add.more.racks": "Add Sequence",
  "rackManagement.clear.sequence": "Clear Sequence",
  "rackManagement.popup.heading": "Add Picking Sequence",
  "rackManagement.save.success.title": "Setting have been successfully saved",
  "rackManagement.save.success.closeText": "Okay",
  "rackManagement.rack.delete.title": "Are you sure ?",
  "rackManagement.rack.delete.message": "You want to delete this rack",
  "rackManagement.rack.delete.cancelText": "No, cancel",
  "rackManagement.save.text": "Save",
  "rackManagement.rack.delete.confirmText": "Yes, delete it!",
  "rackmanagement.empty.message": "Racks are not configured",
  "rackManagement.cancel.text": "Cancel",
  "rackManagement.rack.add.zone": "Add new zone",
  "rackManagement.rack.add.zone.placeholder": "Enter zone",
  "rackManagement.rack.add.zone.default": "Default zone",
  "rackManagement.rack.zones": "Zones",
  "rackManagement.rack.zones.type.existing": "Add to existing zone",
  "rackManagement.rack.zones.type.new": "Add a new zone",
  "rackManagement.rack.zone.name": "Zone name",

  "pincode.form.deliveryCharge.heading": "Delivery Charge",
  "pincode.form.cancelText": "Cancel",
  "pincode.form.submitText": "Save",
  "pincode.form.stores.placeholder": "Select Store",
  "pincode.form.stores.heading": "Served From Store",
  "pincode.form.pincodes.heading": "ZIP Codes",
  "pincode.form.pincodes.placeholder": "Type ZIP Codes and Press Enter",
  "pincode.form.add.heading": "Zip Code / Postal Code",
  "pincode.form.edit.heading": "Edit Zip Code / Postal Code",
  "pincode.table.description": "Zip Codes",
  "pincode.table.deliveryCharge": "Delivery Charge",
  "polygon.form.map.description": "Click on area to delete",

  "notifications.heading": "Notifications",
  "notifications.add.email": "Add Email",
  "notifications.add.phone": "Add phone number",
  "notifications.new.order": "When new order comes",
  "notifications.add": "Add",
  "notifications.email": "Email",
  "notifications.phone": "Phone",
  "notifications.order.title": "Order Notifications",
  "notifications.order.cancel": "When order gets cancelled",
  "notifications.order.refund": "When order gets refunded",
  "notifications.customer.title": "Customer Notifications",
  "notifications.customer.add.money": "When customer adds money to wallet",
  "notifications.customer.add.address": "When customer adds new address",
  "notifications.customer.complain": "When customer registers complain",
  "notifications.save": "Save",
  "notifications.cancel": "Cancel",
  "notifications.phone.phoneNumber": "Add phone number",
  "notifications.email.mail": "Add email address",
  "notifications.submitText": "Submit",
  "notifications.enter.phone": "Enter your phone number",
  "notifications.enter.email": "Enter your email address",
  "notification.save.success.email": "Email added successfully",
  "notification.save.success.closeText": "Okay",
  "notification.save.success.phone": "Phone added succesfully",
  "notification.email.delete.title": "Are yor sure?",
  "notification.email.delete.message": "This email will be deleted",
  "notification.email.delete.cancelText": "Cancel",
  "notification.email.delete.confirmText": "Confirm",
  "notification.phone.delete.title": "Are you sure?",
  "notification.phone.delete.message": "This phone number will be deleted",
  "notification.phone.delete.cancelText": "Cancel",
  "notification.phone.delete.confirmText": "Confirm",
  "notifications.name.phone": "SMS",
  "notifications.midtext": "to",
  "notifications.name.email": "Email",
  "notification.email.error": "Email already exists",
  "notification.phone.error": "Mobile number already exists",
  "notification.success": "Success",
  "notification.success.save": "Changes successfully saved",
  "notification.okay.text": "Okay",
  "notification.new.customer": "When new customer joins",

  "polygon.form.deliveryCharge.heading": "Delivery Charge",
  "polygon.form.submitText": "Save",
  "polygon.form.add.heading": "Draw on a map",
  "polygon.form.edit.heading": "Draw on a map",
  "polygon.table.description": "Marked location",
  "polygon.table.subdescription": "area in the Map",
  "polygon.table.deliveryCharge": "Delivery Charge",
  "polygon.form.stores.placeholder": "Select Store",
  "polygon.form.stores.heading": "Served From Store",
  "polygon.delete.text": "Click to delete",
  "polygon.selectLocation.message": "Draw area on map",

  "orderprocessing.config.heading": "Order Processing Settings",
  "orderprocessing.config.form.submit.text": "Save",
  "orderprocessing.config.form.cancel.text": "Cancel",
  "orderprocessing.config.allStores": "Default Settings",
  "orderprocessing.config.form.resetToDefault": "Reset To Default",
  "orderprocessing.qrCodeFormat.heading": "Store Printed Local Barcode Format",
  "orderprocessing.qrCodeFormat.placeholder": "93xxxxwwwwxxxxii",
  "orderprocessing.qrCodeFormat.description":
    "Use `w` to indicate weight or `p` to indicate price. Only one of them will be used. Use `i` to indicate unique identifier.",
  "orderprocessing.save.success.title": "Settings have been successfully saved",
  "orderprocessing.save.success.closeText": "Okay",
  "orderprocessing.save.error.title":
    "Something went wrong while saving. Please try again later",
  "orderprocessing.save.error.closeText": "Okay",
  "orderprocessing.config.pickingMethod.placeholder":
    "Select picking sequence method",
  "orderprocessing.config.pickingMethod.heading": "Picking Sequence Method",
  "orderprocessing.pickingMethod.oldestfirst":
    "Show oldest order in picking queue first",
  "orderprocessing.pickingMethod.farthestfirst":
    "Show order farthest from the store in picking queue first",
  "orderprocessing.config.pickingView.heading":
    "How Picker Sees the Order Items?",
  "orderprocessing.pickingView.allitems": "All at once",
  "orderprocessing.pickingView.onebyone": "One by one",
  "orderprocessing.orderLabelPrinting.heading": "Order label printing",
  "orderprocessing.orderLabelPrinting.startofpicking": "At start of picking",
  "orderprocessing.orderLabelPrinting.endofpicking": "At end of picking ",
  "orderprocessing.orderLabelPrinting.startandendofpicking":
    "At start and end of picking",
  "orderprocessing.orderLabelPrinting.notneeded": "No physical print needed",
  "orderprocessing.orderLabelFormat.heading": "Order label format",
  "orderprocessing.orderLabelFormat.onlyordernumber": "Only order number",
  "orderprocessing.orderLabelFormat.ordernumberandcustomerdetails":
    "Order number with customer details in QR code",
  "orderprocessing.orderLabelFormat.ordernumbercustomeranditemdetails":
    "Order number with customer details and item in QR code",
  "orderprocessing.qualityChecking.heading": "Quality checking",
  "orderprocessing.qualityChecking.notneeded": "Not needed",
  "orderprocessing.qualityChecking.donebychecker": "Done by checker",
  "orderprocessing.packingDetails.heading": "Packing details",
  "orderprocessing.packingDetails.notneeded": "Not needed",
  "orderprocessing.packingDetails.donebypicker": "Done by picker",
  "orderprocessing.packingDetails.donebypacker": "Done by packer",
  "orderprocessing.packingDetails.donebychecker": "Done by checker",
  "orderprocessing.pickingMethod.intelligentpickingqueue":
    "Intelligent picking queue",
  "orderprocessing.orderLabelPrinting.endofpacking": "At end of packing",

  "settings.online.payment.success": "Success",
  "settings.online.payment.succesfully.saved": "Settings successfully saved",
  "settings.online.payment.ok": "Okay",
  "settings.online.payments.save": "Save",
  "settings.online.payment.gateway.saved": "Payment gateway successfully saved",
  "settings.online.payment.delete": "Delete",
  "settings.online.payment.cancel": "Cancel",
  "settings.online.payment.are.you.sure": "Are you sure?",
  "settings.online.payment.account.delete": "The account will be deleted",
  "settings.online.payment.bank.account.details": "Bank account details",
  "settings.online.payment.link.bank.account": "Link a bank account",
  "settings.online.payment.our.gateway": "You are using our Payment Gateway",
  "settings.online.payment.gateway": "Payment Gateway",
  "settings.online.payment.bank.name": "Bank name",
  "settings.online.payment.accountnumber": "Bank account number",
  "settings.online.payment.beneficiaryname": "Beneficiary name",
  "settings.online.payment.accountholdername": "Account Holder Name",
  "settings.online.payment.branchifsccode": "Branch IFSC Code",
  "settings.online.payment.ifsccodeofbankbranch":
    "IFSC Code of the Bank Branch",
  "settings.online.payment.city": "City",
  "settings.online.payment.entercity": "Enter City",
  "settings.online.payment.state": "State",
  "settings.online.payment.enterstate": "Enter State",
  "settings.online.payment.enterpincode": "Enter Pincode",
  "settings.online.payment.pincode": "Pincode",
  "settings.online.payment.removebankaccount": "Remove bank account",
  "settings.online.payment.onlinepayment": "Online Payment",
  "settings.online.payment.ihaveagateway": "I have a Payment Gateway",
  "settings.online.payment.ihaveagatewaydescription":
    "If you have your own payment gateway and would like to integrate it with your website, then this option is for you.",
  "settings.online.payment.idonthaveagateway": "I don't have a Payment Gateway",
  "settings.online.payment.idonthaveagatewaydescription":
    "If you do not have your own payment gateway, then you can choose from a list of third party payment gateways supported by us for payments on your website.",
  "settings.online.payment.payemntmethod": "Payment Method",
  "online.payments.link.bank.account": "Link Bank Account",
  "settings.online.payments.success": "Success",
  "settings.online.payment.linked.successfully":
    "Payment Gateway has been activated",
  "settings.online.payment.linked.bank":
    "Linked the bank to your payment gateway",
  "settings.online.payment.configureexistinggateways":
    "Please configure the existing gateways before adding new gateway",
  "settings.online.payment.use.your.gateway": "Use your gateway",
  "settings.online.payment.use.zoppay": "Use our default gateway",
  "settings.integrationSettings.heading": "Integration Settings",
  "integrationSettings.googleMapsKey.label": "Google Maps Api Key",
  "integrationSettings.googleMapsKey.placeholder":
    "Enter your Google map api key",
  "integrationSettings.googleCaptchaKey.label": "Google Captcha Site Key",
  "integrationSettings.googleCaptchaKey.placeholder":
    "Enter your Google captcha site key",
  "integrationSettings.googleCaptchaSecret.label": "Google Captcha Secret Key",
  "integrationSettings.googleCaptchaSecret.placeholder":
    "Enter your Google captcha secret key",
  "integrationSettings.dialog.success.info": "Settings are saved successfully",
  "integrationSettings.gtm.label": "Google Tag Manager Id",
  "integrationSettings.gtm.placeholder": "Enter your Google tag manager Id",

  "slots.emptymessage": "There are no slots configured",
  "slots.heading": "Slots",
  "slots.addHeading": "Add Slot",
  "slot.add.text": "Add Slot",
  "slots.delete": "Delete",
  "slots.startTime": "Start Time",
  "slots.endTime": "End Time",
  "slots.actions": "Actions",
  "slot.meridian": "Meridian",
  "slots.save": "Done",
  "slots.cancel": "Cancel",
  "slots.startsAt": "Starts at",
  "slots.endsAt": "Ends at",
  "slots.start.end.mismatch": "Start time should be lesser than end time",
  "slots.asap.placeholder": "0 Minute",
  "slots.asap": "Within",
  "slots.asap.minute": "Minutes",
  "slots.today": "Today",
  "slots.tomorrow": "Tomorrow",
  "slots.store": "Store",
  "slots.store.placeholder": "Select Store",

  "entityMetaData.heading": "Custom fields",
  "entityMetaData.success": "Success",
  "entityMetaData.success.information":
    "Your settings have been successfully saved",
  "entityMetaData.okay": "Okay",
  "entityMetaData.error": "Error",
  "entitiMetaData.numberLabel": "Number",
  "entityMetaData.number.placeholder": "Enter Number",
  "entityMetaData.textLabel": "Key",
  "entityMetaData.checkboxLabel": "Filter",
  "entityMetaData.searchable.checkboxLabel": "Searchable",
  "entityMetaData.private.checkboxLabel": "Private",
  "entityMetaData.checkboxMultivaluedLabel": "multivalued",
  "entityMetaData.text.placeholder": "Enter Key",
  "entitymetaData.textareaLabel": "Key",
  "entityMetaData.textarea.placeholder": "Enter Key",
  "entityMetaData.submitText": "Save",
  "entityMetaData.are.you.sure": "Are you sure?",
  "entityMetaData.this.key.will.be.deleted": "This key will be deleted",
  "entityMetaData.cancel": "Cancel",
  "entityMetaData.delete": "Delete",
  "entityMetaData.type": "Type",
  "entityMetaData.type.placeholder": "Select Type",
  "entityMetadata.add": "Add key",

  "picking.config.heading": "Picking Configuration",
  "picking.config.multiplePickingAllowed": "Allow multiple picking",
  "picking.config.partialPickingAllowed": "Allow partial picking",
  "picking.config.maxOrdersCanBePickedAtOnce":
    "Maximum number of orders can be picked at once",
  "picking.config.maxOrdersCanBePickedAtOnce.placeholder":
    "Enter the maximum number of orders can be picked at once",
  "picking.config.save": "Save",

  "wallet.config.heading": "Wallet Configuration",
  "wallet.config.cashbackPercentage": "Cashback Percentage",
  "wallet.config.cashbackPercentage.placeholder":
    "Enter the cashback percentage",
  "wallet.config.daysAfterOrderCompletionForCashback":
    "Days After Order Completion For Cashback",
  "wallet.config.daysAfterOrderCompletionForCashback.placeholder":
    "Enter the days after order completion for cashback",

  "store.min.stock.count": "Min buffer count",
  "product.restriction.days":"days",
  "product.restriction.hours":"hours",
  "store.max.purchaseable.qty": "Max Purchasable Quantity",
  "store.min.purchaseable.qty": "Min Purchasable Quantity",
  "store.actions": "Actions",
  "store.submit": "Submit",
  "store.store.label": "Store",
  "store.store.placeholder": "Select store",
  "store.clear": "Clear",
  "store.filters.name.heading": "Name",
  "store.filters.name.placeholder": "Search by Name",
  "store.filters.submitText": "Search",
  "store.configuraion.add.text": "Add configuration",
  "stores.empty.stock": "There are no configurations",
  "store.max.purchaseable.quantity": "Max Qty",
  "store.min.purchaseable.quantity": "Min Qty",
  "enitityMetaData.values": "Values",
  "enitityMetaData.values.placeholder": "Enter values",
  "entityMetaData.country": "Countries",
  "entityMetaData.country.placeholder": "Select countries",
  "search-configuration.header": "Search Overrides",
  "search-configuration.add.button.label": "Add Configuration",
  "search-configuration.header.image": "Image",
  "search-configuration.header.name": "Name",
  "search-configuration.header.mrp": "MRP",
  "search-configuration.header.position": "Position",
  "search-configuration.header.actions": "Actions",
  "search-configuration.emptystate.message":
    "Currently there are no configurations",
  "search-configuration.form.confirm": "Confirm",
  "search-configuration.form.cancel": "Cancel",
  "search-configuration.form.label.select.type": "Select Type",
  "search-configuration.form.label.select.category": "Select Category",
  "search-configuration.form.label.add.product": "Add Product",
  "search-configuration.form.label.select.position": "Position",
  "search-configuration.action.edit": "Edit",
  "search-configuration.action.delete": "Delete",
  "search-configuration.form.heading": "Add Configuration",
  "search-config.searchurls": "Add search keywords",
  "search-config.searchurls.placeholder": "Enter search keywords",
  "search-configuration.form.label.add.product.placeholder":
    "Enter product name",
  "search-configuration.form.label.select.position.placeholder":
    "Enter position",
  "sc.form.category.label": "Category",
  "sc.form.cat.placeholder": "Enter category",
  "sc.edit.heading": "Edit configuration",
  "search-configuration.search.header.name": "Search Keywords",
  "sc.header.pdtname": "Product",
  "sc.header.image": "Image",

  "communication.service.provider": "Service Provider",
  "communication.service.provider.placeholder": "Select Provider",

  "communication.enter.text": "Enter",

  "customer-service-tags.heading": "User Tags",
  "customer-service-tags.button": "Add Tag",
  "customer-service-tags.button.cancel": "Cancel",
  "customer-service-tags.form.edit.heading": "Edit Tag",
  "customer-service-tags.form.tag.name.label": "TAG NAME",
  "customer-service-tags.form.tag.name.placeholder": "Enter tag name",
  "customer-service-tags.form.tag.status.label": "Status",
  "customer-service-tags.form.tag.status.enabled.label": "Enabled",
  "customer-service-tags.form.tag.status.disabled.label": "Disabled",
  "customer-service-tags.form.description.label": "DESCRIPTION",
  "customer-service-tags.form.description.placeholder":
    "Enter description here",
  "customer-service-tags.table.name.header": "Name",
  "customer-service-tags.table.name.placeholder": "Enter table name",
  "customer-service-tags.table.status.header": "Status",
  "customer-service-tags.table.description.header": "Description",
  "customer-service-tags.table.actions.header": "Actions",
  "customer-service-tags.table.actions.edit": "Edit",
  "customer-service-tags.table.actions.delete": "Delete",
  "customer-service-tags.save": "Save",
  "customer-service-tags.form.otherdetails.placeholder": "Search tags to add",
  "customer-service-tags.form.search.tag.name.label": "Tag Name",
  "customer-service-tags.form.search.submitText.label": "Submit",
  "customer-service-tags.form.search.clearAll.label": "Clear All",
  "customer-service-tags.noTags": "No tags configured",
  "customer.service.tags.status.ENABLED": "Enabled",
  "customer.service.tags.status.DISABLED": "Disabled",

  "order.config.referenceNumber.heading": "Reference number",
  "order.config.generateRandomReferenceNumbers":
    "Generate random reference numbers",
  "order.config.orderTypes": "Order Types",
  "orderDetails.uploadFile": "Uploaded File",
  "orderPackageConfig.createNew.heading": "Create new package",
  "orderPackageConfig.length.label": "Package length (cm)",
  "orderPackageConfig.length.placeholder": "Enter package length in cm",
  "orderPackageConfig.breadth.label": "Package breadth (cm)",
  "orderPackageConfig.breadth.placeholder": "Enter package breadth in cm",
  "orderPackageConfig.height.label": "Package height (cm)",
  "orderPackageConfig.height.placeholder": "Enter package height in cm",
  "orderPackageConfig.type.label": "Package type",
  "orderPackageConfig.type.placeholder": "Enter the type of package",
  "orderPackageConfig.package.length": "Length",
  "orderPackageConfig.package.breadth": "Breadth",
  "orderPackageConfig.package.height": "Height",
  "orderPackageConfig.createPackage.errorMsg": "Cannot create package",
  "orderPackageConfig.createPackage.errorMsg.dsc":
    "Some error occurred while creating package",
  "orderPackageConfig.package.creating": "Creating package",
  "orderPackageConfig.createPackage.successMsg": "Package Created!",
  "orderPackageConfig.createPackage.successMsg.dsc":
    "Successfully created the package",
  "orderPackageConfig.deletePackage.heading": "Delete Package?",
  "orderPackageConfig.deletePackage.subHeading":
    "Are you sure, you want to delete the selected package?",
  "orderPackageConfig.editPackage.errorMsg.dsc":
    "Some error occurred while editing the selected package",
  "orderPackageConfig.editPackage.errorMsg": "Cannot edit package",
  "orderPackageConfig.editPackage.successMsg": "Package Edited!",
  "orderPackageConfig.editPackage.successMsg.dsc":
    "Successfully edited the package",
  "orderPackageConfig.editPackage.heading": "Edit package",
  "orderPackageConfig.package.editing": "Editing package",
  "orderPackageConfig.type.text.label": "Type",
  "orderPackageConfig.submit.text": "Submit",
  "orderPackageConfig.action.edit.text": "Edit",
  "orderPackageConfig.delete.text": "Delete",
  "orderPackageConfig.package.emptyMsg": "Create a new package to show here",
  "ok.text": "Ok",
  "extensions.courier.support.heading": "Courier Support",
  "courier.support.extension.toggle.text": "Use third party courier services",
  "shipping.courier.support.placeholder": "Select Courier Service",
  "delete.text": "Delete",
  "are.you.sure": "Are you sure?",
  "courier.support.SHIPROCKET.email":
    "On your Shiprocket account, navigate to Settings → API → Configure",
  "courier.support.SHIPROCKET.password":
    "On your Shiprocket account, navigate to Settings → API → Configure",
  "courier.support.DUNZO.clientId":
    "Register on Dunzo as a business or send an email to dunzoforbusiness@dunzo.in",
  "courier.support.DUNZO.clientSecret":
    "Register on Dunzo as a business or send an email to dunzoforbusiness@dunzo.in",
  "courier.support.DUNZO.webhook.url":
    "Share this over an email to the Dunzo team",
  "refferal.managemnet.referrer.heading":
    "Referrer (existing customer) Rewards",
  "refferal.managemnet.referee.heading": "Referee (new customer) Rewards",
  "referral.bonus.text": "Referral Bonus",
  "referral.maxBonus.text": "Maximum limit",
  "referral.bonus.description": `Bonus will be credited to the referrer's wallet when the referred customers completes their first order.`,
  "signup.bonus.text": "Signup Bonus",
  "signup.bonus.placeholder": "Enter the Amount",
  "signup.bonus.description": `Money will be credited upon successful sign up using the referral code.`,
  "referral.save.success.title": "Referral Successfully Configured",
  "referral.save.error.title":
    "Something unexpected occured while saving. Please try again later!",
  "referral.management.success.title": "Success",
  "referral.management.error.title": "Something Went Wrong!",
  "google.merchant.center.upload.label":
    "Upload the content-api-key.json file here :",
  "google.merchant.center.upload.change.label":
    "To change your Content API Key, Upload the new file here :",
  "google.merchant.center.description":
    "Go to Tools and Shipping > Content API > Authentication > New API Key and download the content-api-key.json",
  "google.merchant.center.error.msg": "Upload correct file",
  "google.merchant.center.error.title": "Invalid File",
  "google.merchant.center.success.description":
    "Your Google Merchant Center account is successfully linked to Zopping !",
  "google.merchant.center.success.msg":
    "SuccessFully content-api-key.json uploaded !",
  "operations.store.form.payment.COD": "COD",
  "operations.store.form.payment.WALLET": "WALLET",
  "operations.store.form.payment.ONLINE": "ONLINE",
  "operations.store.form.payment.APPLE_PAY": "APPLE PAY",
  "operations.store.form.payment.GIFTCARD": "GIFT CARD",
  "operations.store.form.payment.DEBIT-MADA": "DEBIT MADA",
  "operations.store.form.payment.CREDIT-MASTER": "CREDIT MASTER",
  "operations.store.form.payment.CREDIT-VISA": "CREDIT VISA",
  "operations.store.form.payment.CREDIT-AMEX": "CREDIT AMEX",
  "catalogue-service-product-restrictions.heading":
    "Purchase limit Restrictions",
  "product.restrictions.empty": "No Purchase limit Restrictions",
  "product.restrictions.add.text": "Add configuration",
  "error.title": "Something Went Wrong!",
  "store.stock.orderType": "Order Type",
};

export default data;
