import React, { Component } from "react";
import { Helmet } from "react-helmet";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import { getMessage } from "../../../lib/translator";
import Tabs from "../../../components/Tabs";
import { Row, Cell } from "../../../components/Table";
import {
  getStandradDateTime,
  utcToLocalTime,
  getPrintableTime,
} from "../../../lib/datetime";
import { Link } from "react-router-dom";
import ListingPage from "../../../containers/ListingPage";
import ReviewStats from "./ratingStatus";
import { Radio } from "../../../components/Form";
import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import Image from "../../../components/Image";
import "./style.css";
import API from "../../../lib/api";
import LeftArrow from "./LeftArrow";
import FilledStart from "./FilledStar";
import EmptyStar from "./EmptyStar";
import NoRating from "./NoRating";
import { Popup } from "../../../components/Popup";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import ApproveIcon from "./ApproveIcon";
import DisapproveIcon from "./DisapproveIcon";
import PendingIcon from "./PendingStatus";

function Rating(props) {
  const fullStars = Math.floor(props.rating);
  const EmptyStars = 5 - fullStars;
  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(<FilledStart />);
  }
  if (EmptyStars < 5) {
    for (let i = 0; i < EmptyStars; i++) {
      stars.push(<EmptyStar />);
    }
  }
  return <span className="">{stars}</span>;
}

const getDropDownItems = (status) => {
  const items = [];
  if (status === REVIEW_STATUS.PENDING) items.push("APPROVED", "REJECTED");
  else if (status === REVIEW_STATUS.APPROVED) items.push("REJECTED");
  else if (status === REVIEW_STATUS.REJECTED) items.push("APPROVED");
  return items;
};

const REVIEW_STATUS = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
};
class ProductStatusAction extends Component {
  modifyStatus(status) {
    let id = this.props.id;
    let api = new API({ url: `/catalogue-service/product-rating/${id}` });
    return api
      .put({ id, status })
      .then((response) => {
        this.props.onAction(
          TABLE_ACTIONS.UPDATE,
          { id },
          { id: id, status: status },
          false
        );
      }, console.error)
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    let { status, onAction, id } = this.props;

    return (
      <div>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          {getDropDownItems(status).map((action) => (
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.UPDATE, { id }, { status: action });
              }}
            >
              {getMessage(`marketing.action.${action}.status`)}
            </DropDownItem>
          ))}
        </DropDown>
      </div>
    );
  }
}

class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      entity: "orders",
      index: 0,
      totalRating: 0,
      avgRating: 0,
      noOfReviews: null,
      reviews: null,
      productType: "All",
      productId: null,
      showIndividaulInfo: false,
      showAllIndivisualProductReview: false,
      setProductId: null,
      productImage: null,
      productName: null,
      showPopup: false,
      selectedImage: null,
    };
    this.tabHeaders = ["orders", "products"];
    this.changeTab = this.changeTab.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleShowIndivisualProduct =
      this.handleShowIndivisualProduct.bind(this);
    this.handlePopup = this.handlePopup.bind(this);
  }
  handlePopup = (image) => {
    this.setState((prevState) => ({
      ...prevState,
      showPopup: true,
      selectedImage: image,
    }));
  };

  hidePopup = () => {
    this.setState((prevState) => ({
      ...prevState,
      showPopup: false,
      selectedImage: null,
    }));
  };

  tableProperties = {
    headers: [
      getMessage("review.order.table.header.orderid"),
      getMessage("review.order.table.header.rating"),
      getMessage("review.order.table.header.date"),
      getMessage("review.order.table.header.customer"),
    ],
    row: (props) => {
      const {
        referenceNumber,
        rating,
        title,
        review,
        customer,
        createdAt,
        images,
      } = props;
      return (
        <Row>
          <Cell className="orderId">
            <div className="order-mobile-header">
              <Link to={`/operations/orders/${referenceNumber}`}>
                #{referenceNumber}
              </Link>
              <div className="order-rating-mobile-view">
                <Rating rating={rating} />
              </div>
            </div>
          </Cell>
          <Cell>
            <div className="ratingStar">
              <Rating rating={rating} />
            </div>
            {title && <div className="reviewTitle">{title}</div>}
            {review && <div className="reviewDescription">{review}</div>}
            {images && images?.length > 0 && (
              <div className="reviewImages">
                {images.map((image) => {
                  return (
                    <div
                      className="reviewImage"
                      onClick={() => this.handlePopup(image)}
                    >
                      <img src={image} alt="reviewImage" />
                    </div>
                  );
                })}
              </div>
            )}
          </Cell>
          <Cell className="createdAt">
            <small className="text-muted">
              <span>
                {
                  getPrintableTime(
                    getStandradDateTime(utcToLocalTime, createdAt)
                  ).split(",")[0]
                }
                ,
                {
                  getPrintableTime(
                    getStandradDateTime(utcToLocalTime, createdAt)
                  ).split(",")[1]
                }
              </span>
            </small>
          </Cell>
          <Cell className="customerName">
            <div>
              <div className="createAt-mobile">
                <small className="text-muted">Date </small>
                <br></br>
                <span>
                  <span>
                    {
                      getPrintableTime(
                        getStandradDateTime(utcToLocalTime, createdAt)
                      ).split(",")[0]
                    }
                    ,
                    {
                      getPrintableTime(
                        getStandradDateTime(utcToLocalTime, createdAt)
                      ).split(",")[1]
                    }
                  </span>
                </span>
              </div>
            </div>
            <div className="">
              <small className="customer-mobile">Customer</small>
              <br className="customer-mobile"></br>
              <small className="text-muted">{customer?.name}</small>
            </div>
          </Cell>
        </Row>
      );
    },
  };
  tablePropertiesforAll = {
    headers: [
      getMessage("review.all.product.table.header.item"),
      getMessage("review.order.table.header.rating"),
      getMessage("review.order.table.header.date"),
      getMessage("review.order.table.header.customer"),
      getMessage("review.order.table.header.more"),
    ],
    row: (props) => {
      const {
        product,
        rating,
        title,
        review,
        customerName,
        createdAt,
        images,
        onAction,
        status,
        id,
      } = props;
      const image = product.images ? product.images[0] : undefined;
      return (
        <Row>
          <Cell className="item">
            <div className="productName-container">
              <div className="image-name">
                <Image size="sm" src={image} bordered />
                <span className="productName">{product.name}</span>
              </div>
              <div className="ratingStar-mobile">
                <Rating rating={rating} />
              </div>
            </div>
          </Cell>
          <Cell>
            <div className="ratingStar">
              <Rating rating={rating} />
            </div>
            <div className="title-and-review-status">
              {title && <div className="reviewTitle">{title}</div>}
              <div className="status-wrap">
                {status === REVIEW_STATUS.APPROVED ? (
                  <ApproveIcon />
                ) : status === REVIEW_STATUS.REJECTED ? (
                  <DisapproveIcon />
                ) : (
                  <PendingIcon />
                )}
                <span>
                  {status === REVIEW_STATUS.APPROVED
                    ? "Approved"
                    : status === REVIEW_STATUS.PENDING
                    ? "Pending"
                    : "Disapproved"}
                </span>
              </div>
            </div>
            {review && <div className="reviewDescription">{review}</div>}
            {images && images?.length > 0 && (
              <div className="reviewImages">
                {images.map((image) => {
                  return (
                    <div
                      className="reviewImage"
                      onClick={() => this.handlePopup(image)}
                    >
                      <img src={image} alt="reviewImage" />
                    </div>
                  );
                })}
              </div>
            )}
          </Cell>
          <Cell className="createdAt">
            <small className="text-muted">
              <span>
                {
                  getPrintableTime(
                    getStandradDateTime(utcToLocalTime, createdAt)
                  ).split(",")[0]
                }
                ,
                {
                  getPrintableTime(
                    getStandradDateTime(utcToLocalTime, createdAt)
                  ).split(",")[1]
                }
              </span>
            </small>
          </Cell>
          <Cell className="customerName">
            <div className="createAt-mobile">
              <small className="text-muted">Date </small>
              <br></br>
              <span>
                {
                  getPrintableTime(
                    getStandradDateTime(utcToLocalTime, createdAt)
                  ).split(",")[0]
                }
                ,
                {
                  getPrintableTime(
                    getStandradDateTime(utcToLocalTime, createdAt)
                  ).split(",")[1]
                }
              </span>
            </div>
            <div className="">
              <small className="customer-mobile">Customer</small>
              <br className="customer-mobile"></br>
              <small className="text-muted">{customerName}</small>
            </div>
          </Cell>
          <Cell className="more">
            <div className="status-wrap-mobile">
              {status === REVIEW_STATUS.APPROVED ? (
                <ApproveIcon />
              ) : status === REVIEW_STATUS.REJECTED ? (
                <DisapproveIcon />
              ) : (
                <PendingIcon />
              )}
              <span>
                {status === REVIEW_STATUS.APPROVED
                  ? "Approved"
                  : status === REVIEW_STATUS.PENDING
                  ? "Pending"
                  : "Disapproved"}
              </span>
            </div>
            <ProductStatusAction onAction={onAction} id={id} status={status} />
          </Cell>
        </Row>
      );
    },
  };

  tablePropertiesforIndividual = {
    headers: [
      getMessage("review.all.product.table.header.item"),
      getMessage("review.individual.product.table.header.average.rating"),
      getMessage("review.individual.product.table.header.reviews"),
    ],
    row: (props) => {
      const { images, name, averageRating, totalReviews, id } = props;
      return (
        <Row>
          <Cell className="item">
            <div className="productName-container">
              <div className="image-name">
                <Image size="sm" src={images && images[0]} bordered alt="img" />
                <span className="productName">{name}</span>
              </div>
              <div className="ratingStar-mobile">
                <Rating rating={averageRating} />
              </div>
            </div>
          </Cell>
          <Cell>
            <div className="ratingStar">
              <Rating rating={averageRating} />
            </div>
          </Cell>
          <Cell>
            <div
              className="totalReviews"
              onClick={() =>
                this.handleShowIndivisualProduct(id, images && images[0], name)
              }
            >
              {totalReviews} Reviews
            </div>
          </Cell>
        </Row>
      );
    },
  };

  tablePropertiesforIndividualProductDetails = {
    headers: [
      getMessage("review.all.product.table.header.item"),
      getMessage("review.order.table.header.rating"),
      getMessage("review.order.table.header.date"),
      getMessage("review.order.table.header.customer"),
    ],
    row: (props) => {
      const { createdAt, title, review, rating, customerName, index, images } =
        props;
      const lengthOfReview = this.state.reviews.length;
      const remove = index === 0 && lengthOfReview > 1;
      const className = remove ? "remove" : "first-index";
      return (
        <Row className="remove">
          <Cell remove={remove} className={className}>
            {index === 0 ? (
              <div className="productContainer">
                <div className="productImageDiv">
                  <Image
                    size="md"
                    src={
                      this.state.productImage != null && this.state.productImage
                    }
                  />
                </div>
                <span className="productName">{this.state.productName}</span>
              </div>
            ) : null}
          </Cell>
          <Cell>
            <div className="ratingStar">
              <Rating rating={rating} />
            </div>
            {title && <div className="reviewTitle">{title}</div>}
            {review && <div className="reviewDescription">{review}</div>}
            {images && images?.length > 0 && (
              <div className="reviewImages">
                {images.map((image) => {
                  return (
                    <div
                      className="reviewImage"
                      onClick={() => this.handlePopup(image)}
                    >
                      <img src={image} alt="reviewImage" />
                    </div>
                  );
                })}
              </div>
            )}
          </Cell>
          <Cell className="createdAt">
            <small className="text-muted">
              {
                getPrintableTime(
                  getStandradDateTime(utcToLocalTime, createdAt)
                ).split(",")[0]
              }
              ,
              {
                getPrintableTime(
                  getStandradDateTime(utcToLocalTime, createdAt)
                ).split(",")[1]
              }
            </small>
          </Cell>
          <Cell className="customerName">
            <div>
              <div className="createAt-mobile">
                <small className="text-muted">Date </small>
                <br></br>
                <span>
                  {
                    getPrintableTime(
                      getStandradDateTime(utcToLocalTime, createdAt)
                    ).split(",")[0]
                  }
                  ,
                  {
                    getPrintableTime(
                      getStandradDateTime(utcToLocalTime, createdAt)
                    ).split(",")[1]
                  }
                </span>
              </div>
            </div>
            <div className="">
              <small className="customer-mobile">Customer</small>
              <br></br>
              <small className="text-muted">{customerName}</small>
            </div>
          </Cell>
        </Row>
      );
    },
  };

  fetchData() {
    if (this.state.index === 0) {
      return (
        <ListingPage
          key={this.state.index}
          className="order-review-table"
          api={{
            url: "/order-service/order-rating",
            params: { includeStatsDetails: true },
            transform: (response) => {
              this.setState({
                loading: false,
                totalRating: response.data.count,
                avgRating: response.data.averageRating,
                noOfReviews: response.data.noOfReviews,
                reviews: response.data.reviews,
              });
              return response.data.reviews;
            },
          }}
          tableProperties={this.tableProperties}
          tableDynamic
          additionalViews={[
            () => (
              <>
                <ReviewStats
                  totalRating={this.state.totalRating}
                  noOfReviews={this.state.noOfReviews}
                  avgRating={this.state.avgRating}
                />
              </>
            ),
          ]}
        />
      );
    }
    if (
      this.state.index === 1 &&
      this.state.productType === "All" &&
      !this.state.showAllIndivisualProductReview
    ) {
      return (
        <ListingPage
          key={this.state.index && this.state.productType}
          className="product-all-review-table-all"
          api={{
            url: "/catalogue-service/product-rating",
            params: { includeProductDetails: true },
            transform: (response) => {
              this.setState({
                loading: false,
              });
              return response.data.reviews;
            },
          }}
          tableProperties={this.tablePropertiesforAll}
          tableDynamic
        />
      );
    }

    if (
      this.state.index === 1 &&
      this.state.productType === "Individual" &&
      !this.state.showAllIndivisualProductReview
    ) {
      return (
        <ListingPage
          key={this.state.index && this.state.productType}
          className="product-all-review-table"
          api={{
            url: "/catalogue-service/product",
            params: { paginated: false, includeAverageRating: true },

            transform: (response) => response.data.product,
          }}
          tableProperties={this.tablePropertiesforIndividual}
          tableDynamic
        />
      );
    }
    if (this.state.showAllIndivisualProductReview && this.state.index === 1) {
      return (
        <div>
          <ListingPage
            key={this.state.index}
            className="product-all-review-table-indivisual"
            api={{
              url: "/catalogue-service/product-rating",
              params: {
                includeProductDetails: true,
                paginated: false,
                productId: parseInt(this.state.setProductId),
              },
              transform: (response) => {
                this.setState({
                  loading: false,
                  totalRating: response.data.count,
                  avgRating: response.data.averageRating,
                  noOfReviews: response.data.noOfReviews,
                  reviews: response.data.reviews,
                });
                return response.data.reviews;
              },
            }}
            tableProperties={this.tablePropertiesforIndividualProductDetails}
            tableDynamic
            additionalViews={[
              () => (
                <div>
                  <ReviewStats
                    totalRating={this.state.totalRating}
                    noOfReviews={this.state.noOfReviews}
                    avgRating={this.state.avgRating}
                  />
                  <div className="productContainerMobile">
                    <div className="productImageDiv">
                      <Image
                        size="md"
                        src={
                          this.state.productImage != null &&
                          this.state.productImage
                        }
                      />
                    </div>
                    <span className="productName">
                      {this.state.productName}
                    </span>
                  </div>
                </div>
              ),
            ]}
          />
        </div>
      );
    }
  }

  fetchTabData() {
    if (this.state.index === 0) {
      return <div className="orders-content">{this.fetchData()}</div>;
    } else if (
      this.state.index === 1 &&
      this.state.showAllIndivisualProductReview
    ) {
      return (
        <>
          <div
            className="backButtonContainer"
            onClick={this.handleShowIndivisualProduct}
          >
            <LeftArrow className="arrow" />
            <h3 className="backText">Back</h3>
          </div>
          <div className="orders-content">{this.fetchData()}</div>
        </>
      );
    } else {
      return (
        <>
          {this.state.totalRating > 0 && (
            <div className="product-review-type">
              <Radio
                className="product-type"
                name="product-rating-type"
                skin="borderless-skin"
                options={[
                  { text: "All Reviews", value: "All" },
                  { text: "Individual Product Reviews", value: "Individual" },
                ]}
                value={this.state.productType}
                onChange={(value) => {
                  this.handleChange(value);
                }}
              />
            </div>
          )}
          {this.fetchData()}
        </>
      );
    }
  }

  changeTab(index) {
    this.setState({
      index,
      showAllIndivisualProductReview: false,
      entity: this.tabHeaders[index],
    });
  }
  handleChange(value) {
    this.setState({
      productType: value,
    });
  }

  handleShowIndivisualProduct = (id = null, images = null, name = null) => {
    this.setState((prevState) => ({
      productName: name,
      productImage: images,
      setProductId: id,
      showAllIndivisualProductReview: !prevState.showAllIndivisualProductReview,
    }));
  };
  render() {
    const { loading, totalRating, index } = this.state;
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <div className="reviewDetails">
          <Helmet title="Zopping - View Customer" />
          <h1 className="heading">{getMessage("menu.item.rating-reviews")}</h1>
          {loading ? (
            <>{this.fetchTabData()}</>
          ) : (
            <>
              <div className="tab-container">
                <Tabs
                  items={this.tabHeaders}
                  default={index}
                  onClick={(index) => {
                    this.changeTab(index);
                  }}
                />
              </div>
              {this.fetchTabData()}
            </>
          )}
          {totalRating === 0 && !loading && (
            <div className="no-ratings">
              <div>
                <NoRating />
                <h3 className="no-ratings-heading">No Ratings to shows</h3>
              </div>
            </div>
          )}
          {this.state.showPopup && (
            <Popup show={this.state.showPopup} close={this.hidePopup}>
              <div className="selectedImage">
                <img src={this.state.selectedImage} alt="selectedImage" />
              </div>
            </Popup>
          )}
        </div>
      </AuthenticatedPage>
    );
  }
}
export default Reviews;
