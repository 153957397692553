import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Cell } from "../../../../../components/Table";
import { Dialog, Popup } from "../../../../../components/Popup";
import {
  DropDown,
  DropDownItem,
  ICONS,
} from "../../../../../components/DropDown";
import {
  Email as CustomerEmailWidget,
  Call as CustomerCallWidget,
  Address as CustomerMapWidget,
} from "../../../../../containers/CustomerWidgets";
import { getMessage } from "../../../../../lib/translator";
import {
  getSession,
  isExtensionEnabled,
  hasPermissions,
} from "../../../../../lib/auth";
import { getPrintableTime, formatTime } from "../../../../../lib/datetime";
import { getAsapDuration } from "../../../../../lib/commonlyused";
import { TABLE_ACTIONS } from "../../../../../containers/ListingPage";
import OrderInvoice from "../../Details/OrderInvoice";
import FileForReturn from "../../../Returns/Form/FileForReturn";
import CompletePickupPendingOrder from "../../../Returns/Form/CompletePickupPending";
import { Consumer } from "../index";
import pickupImage from "./pickup.svg";
import deliveyImage from "./delivery.svg";
import offlineImage from "./offline.svg";
import B2BImage from "./b2b.svg";
import API from "../../../../../lib/api";
import { getOrderPermissions } from "../index";
import Image from "../../../../../components/Image";
import { isMarketPlaceOwner } from "../../../../../lib/marketPlace";

import { GO_HOST } from "../../../../../config/app";

const showConfig = () => {
  if (GO_HOST.includes("tamimimarkets.com")) return false;
  return true;
};

// Mapping order status with the relevant message key
const orderStatus = {
  COMPLETED: "order.status.completed",
  CANCELLED: "order.status.cancelled",
  PENDING: "order.status.pending",
  PICKED: "order.status.picked",
  CHECKED: "order.status.checked",
  PACKED: "order.status.packed",
  PICKING: "order.status.picking",
  "PARTIALLY-PICKED": "order.status.partiallypicked",
  CHECKING: "order.status.checking",
  PACKING: "order.status.packing",
  DISPATCHED: "order.status.dispatched",
  RETURNED: "order.status.returned",
};

const orderTypeImages = {
  PICKUP: pickupImage,
  DELIVERY: deliveyImage,
  B2B: B2BImage,
  OFFLINE: offlineImage,
};

// Logic to decide what payment status should be shown for the order
const PaymentStatusText = ({ status, pendingAmount, refundAmount }) => {
  let text = "";
  switch (status) {
    case "PAID":
      text = (
        <small className="text-muted">{getMessage("order.payment.paid")}</small>
      );
      break;
    case "PENDING":
      text = (
        <small className="pending-amount">
          {getMessage("order.table.pendingAmount", {
            currency: getSession().organization.currency.symbol,
            amount: Number(pendingAmount).toFixed(2),
          })}
        </small>
      );
      break;
    case "REFUND":
      text = (
        <small className="refund-amount">
          {getMessage("order.table.refundAmount", {
            currency: getSession().organization.currency.symbol,
            amount: Number(pendingAmount).toFixed(2),
          })}
        </small>
      );
      break;
    default:
      break;
  }
  return text;
};
const FillRateText = ({ fillRate = 0 }) => {
  return (
    <small className={`${fillRate < 80 ? "fill-rate" : "text-muted"}`}>
      {(fillRate === 0 || fillRate) &&
        getMessage("order.table.text.fillrate") +
          Math.round(fillRate * 100) / 100 +
          "%"}
    </small>
  );
};

const tripStatus = ["DISPATCHED", "COMPLETED", "RETURNED"];

class TableActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompleteDialog: false,
      showCancelDialog: false,
      showInvoice: false,
      printInvoice: false,
      showLog: false,
      showPickupPending: false,
      orderActions: getOrderPermissions(),
    };
    this.returnableStatuses = ["COMPLETED"];
    this.isInStoreEnabled = isExtensionEnabled("InStoreProcessing");
    this.isLogisticsEnabled = isExtensionEnabled("LogisticsSupport");
    this.returnableStatuses.push("PACKED");
    this.returnableStatuses.push("DISPATCHED");

    this.printInvoice = this.printInvoice.bind(this);
  }
  printInvoice() {
    this.setState((prevState) => {
      return {
        showInvoice: true,
        printInvoice: true,
      };
    });
  }
  handleCourierDispatch = (referenceNumber) => {
    const courierApi = new API({
      url: `/logistics-service/courier/${referenceNumber}`,
    });
    courierApi
      .put()
      .then((_) => {
        this.setState({
          dispatchFormError: "",
          showSuccessDispatchDialog: true,
        });
      })
      .catch((error) => {
        this.setState({
          dispatchFormError: error.message,
          showErrorDispatchDialog: true,
        });
        console.error(error);
      });
  };
  closeDispatchDialogs = (onAction, whichDialogue) => {
    this.setState(
      {
        showSuccessDispatchDialog: false,
        showErrorDispatchDialog: false,
      },
      () => {
        if (whichDialogue === "success") onAction(TABLE_ACTIONS.REFRESH);
      }
    );
  };

  render() {
    let {
      referenceNumber,
      status,
      onAction,
      type,
      paymentStatus,
      pendingAmount,
      clientId,
      url,
      returnsByRE,
      courierDetails,
    } = this.props;
    const productReturned = (returnsByRE && returnsByRE.length > 0) || false; // to know product returned or not
    let hasOrderEditPermissions = hasPermissions("order", "order", "PUT");
    let hasOrderPostPermissions = hasPermissions("order", "order", "post");
    let canBeCompleted =
      hasOrderEditPermissions && isExtensionEnabled("InStoreProcessing")
        ? isExtensionEnabled("LogisticsSupport")
          ? status === "DISPATCHED"
          : status === "PACKED"
        : isExtensionEnabled("LogisticsSupport")
        ? status === "DISPATCHED"
        : isExtensionEnabled("ShipRocket")
        ? status === "DISPATCHED" && courierDetails?.vendor === "OTHER"
        : !(
            !isExtensionEnabled("InStoreProcessing") &&
            isExtensionEnabled("ShipRocket")
          ) && status === "PACKED";
    let canBePendingToPacked =
      hasOrderEditPermissions &&
      !isExtensionEnabled("InStoreProcessing") &&
      status === "PENDING";
    let canBeReturned =
      hasOrderPostPermissions &&
      isExtensionEnabled("OrderReturns") &&
      this.returnableStatuses.indexOf(status) > -1;
    const canBeDisputed =
      isExtensionEnabled("OrderDisputes") && status === "COMPLETED";
    let canShowTrip =
      isExtensionEnabled("LogisticsSupport") &&
      tripStatus.indexOf(status) > -1 &&
      hasPermissions("logistics", "trip", "get") &&
      type.name === "DELIVERY";
    let canBeDispatched = Boolean(courierDetails)
      ? ["RESERVED", "CANCELLED"].includes(courierDetails?.status) &&
        isExtensionEnabled("ShipRocket") &&
        status === "PACKED" &&
        type &&
        type.name === "DELIVERY"
      : isExtensionEnabled("ShipRocket") &&
        status === "PACKED" &&
        type &&
        type.name === "DELIVERY";
    if (type && type.name === "PICKUP" && status === "PACKED") {
      canBeCompleted = true;
    }
    let canBeCancelled =
      (status === "PENDING" || status === "RETURNED") &&
      hasPermissions("order", "order", "put");
    let registerPayments =
      paymentStatus === "PENDING" &&
      (!isExtensionEnabled("LogisticsSupport") ||
        (type && type.name === "PICKUP"));
    const isStatusPacked = status === "PACKED" || false;
    const packedOrderEdit = this.props.packedOrderEdit || false;
    const fromOperations = this.props.url === "/operations";
    const hasAccessToAction = this.state.orderActions;

    const hideOptionForSeller =
      !isExtensionEnabled("MarketPlace") || isMarketPlaceOwner();
    const hasAccessToInvoice = this.props.invoiceSupportEnabled && showConfig();

    return (
      <div>
        <Dialog
          show={this.state.showSuccessDispatchDialog}
          className="success"
          information={getMessage("order.courier.dispatch.success")}
          close={() => this.closeDispatchDialogs(onAction, "success")}
          closeText={getMessage("okay.text")}
        />
        <Dialog
          show={this.state.showErrorDispatchDialog}
          information={getMessage(this.state.dispatchFormError)}
          close={() => this.closeDispatchDialogs(onAction)}
          closeText={getMessage("okay.text")}
        />
        <Dialog
          show={this.state.showCompleteDialog}
          title={getMessage("order.details.completion.title")}
          information={getMessage("order.details.completion.message")}
          onOk={() => {
            onAction(
              TABLE_ACTIONS.UPDATE,
              { referenceNumber },
              { status: "COMPLETED" }
            );
            this.setState({ showCompleteDialog: false });
          }}
          close={() => this.setState({ showCompleteDialog: false })}
          okText={getMessage("order.details.completion.confirmText")}
          closeText={getMessage("order.details.completion.cancelText")}
        />
        <Dialog
          show={this.state.showCancelDialog}
          title={getMessage("order.details.cancellation.title")}
          information={getMessage("order.details.cancellation.message")}
          onOk={() => {
            onAction(
              TABLE_ACTIONS.UPDATE,
              { referenceNumber },
              { status: "CANCELLED" }
            );
            this.setState({ showCancelDialog: false });
          }}
          close={() => this.setState({ showCancelDialog: false })}
          okText={getMessage("order.details.cancellation.confirmText")}
          closeText={getMessage("order.details.cancellation.cancelText")}
        />
        {this.state.showMoveToPendingDialog && (
          <Dialog
            show={this.state.showMoveToPendingDialog}
            title={getMessage("order.details.pending.title")}
            information={getMessage("order.details.pending.message")}
            onOk={() => {
              onAction(
                TABLE_ACTIONS.UPDATE,
                { referenceNumber },
                { status: "PENDING" }
              );
            }}
            close={() => this.setState({ moveCancelledToPending: false })}
            okText={getMessage("order.details.cancellation.confirmText")}
            closeText={getMessage("order.details.cancellation.cancelText")}
          />
        )}
        {this.state.showInvoice && (
          <OrderInvoice
            referenceNumber={referenceNumber}
            show={this.state.showInvoice}
            close={() =>
              this.setState({ showInvoice: false, printInvoice: false })
            }
            print={this.state.printInvoice}
          />
        )}
        {this.state.showReturn && (
          <Popup
            show={this.state.showReturn}
            referenceNumber={referenceNumber}
            close={() => this.setState({ showReturn: false })}
            className="file-for-return"
            heading={`${getMessage("return.file.heading")} #${referenceNumber}`}
          >
            <FileForReturn
              referenceNumber={referenceNumber}
              closeReturnFilePopup={() => this.setState({ showReturn: false })}
              onAction={onAction}
              status={status}
            />
          </Popup>
        )}
        {this.state.showPickupPending && (
          <Popup
            show={this.state.showPickupPending}
            close={() => this.setState({ showPickupPending: false })}
            className="pickup-pending-form"
          >
            <CompletePickupPendingOrder
              productReturned={productReturned}
              referenceNumber={this.props.referenceNumber}
              redirectUrl={`${url}/orders/file-for-return/${referenceNumber}`}
              close={() => this.setState({ showPickupPending: false })}
              onComplete={() => {
                onAction(
                  TABLE_ACTIONS.UPDATE,
                  { referenceNumber },
                  { status: "COMPLETED" }
                );
                this.setState({
                  showPickupPending: false,
                });
              }}
              onPickupPendingComplete={(params) => {
                onAction(TABLE_ACTIONS.UPDATE, { referenceNumber }, params);
              }}
            />
          </Popup>
        )}
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`${url}/orders/${referenceNumber}`}>
              {getMessage("order.action.viewDetails")}
            </Link>
          </DropDownItem>

          {hasAccessToInvoice && (
            <DropDownItem onClick={() => this.setState({ showInvoice: true })}>
              {getMessage("order.action.viewInvoice")}
            </DropDownItem>
          )}

          {canBeDisputed && (
            <DropDownItem>
              <Link to={`${url}/orders/dispute/${referenceNumber}`}>
                {getMessage("order.details.dispute.optionText")}
              </Link>
            </DropDownItem>
          )}

          {canShowTrip && (
            <DropDownItem>
              <Link to={`/logistics/trips?orderNumber=${referenceNumber}`}>
                {getMessage("order.actions.trips")}
              </Link>
            </DropDownItem>
          )}

          {hasAccessToInvoice && (
            <DropDownItem onClick={this.printInvoice}>
              {getMessage("order.action.printInvoice")}
            </DropDownItem>
          )}
          {hideOptionForSeller &&
            canBeCancelled &&
            hasAccessToAction?.includes("CANCEL_ORDER") && (
              <DropDownItem
                onClick={(e) => {
                  this.setState({
                    showCancelDialog: true,
                  });
                }}
              >
                {getMessage("order.action.cancel")}
              </DropDownItem>
            )}
          {hideOptionForSeller && canBePendingToPacked && (
            <DropDownItem>
              <Link to={`${url}/orders/pack-order/${referenceNumber}`}>
                {getMessage("order.action.packed")}
              </Link>
            </DropDownItem>
          )}
          {hideOptionForSeller &&
            canBeCompleted &&
            hasAccessToAction?.includes("COMPLETE_ORDER") &&
            (!registerPayments ? (
              <DropDownItem
                onClick={() => {
                  if (!isExtensionEnabled("OrderReturns")) {
                    this.setState({ showCompleteDialog: true });
                  } else {
                    this.setState({ showPickupPending: true });
                  }
                }}
              >
                {getMessage("order.action.complete")}
              </DropDownItem>
            ) : (
              <DropDownItem>
                <Link
                  to={`${url}/orders/register-payment/${referenceNumber}?pendingAmount=${pendingAmount}&clientId=${clientId}&type=${type?.name}`}
                >
                  {getMessage("order.action.complete")}
                </Link>
              </DropDownItem>
            ))}
          {hideOptionForSeller && canBeDispatched && (
            <DropDownItem
              onClick={() => {
                if (courierDetails?.status === "RESERVED") {
                  this.handleCourierDispatch(referenceNumber);
                }
              }}
            >
              {courierDetails?.status === "RESERVED" ? (
                <>{getMessage("order.action.dispatch")}</>
              ) : (
                <Link to={`${url}/orders/dispatch/${referenceNumber}`}>
                  {getMessage("order.action.dispatch")}
                </Link>
              )}
            </DropDownItem>
          )}
          {hideOptionForSeller &&
            canBeReturned &&
            hasAccessToAction?.includes("RETURN_ORDER") && (
              <DropDownItem>
                <Link to={`${url}/orders/file-for-return/${referenceNumber}`}>
                  {getMessage("order.action.return")}
                </Link>
              </DropDownItem>
            )}
          {hideOptionForSeller && (
            <DropDownItem>
              <Link to={`${url}/orders/order-log/${referenceNumber}`}>
                {getMessage("order.actions.logs")}
              </Link>
            </DropDownItem>
          )}

          {hideOptionForSeller &&
            isStatusPacked &&
            packedOrderEdit &&
            fromOperations &&
            hasAccessToAction?.includes("EDIT_ORDER") && (
              <DropDownItem>
                <Link to={`orders/edit-packed/${referenceNumber}`}>
                  {getMessage("order.action.editPackedOrder")}
                </Link>
              </DropDownItem>
            )}
        </DropDown>
      </div>
    );
  }
}

const tableHeader = (url) => {
  let headers = [
    getMessage("order.table.header.referenceNumber"),
    getMessage("order.table.header.customer"),
    getMessage("order.table.header.placedTime"),
    getMessage("order.table.header.amount"),
    getMessage("order.table.header.status"),
    getMessage("order.table.header.courierAndCharge"),
    getMessage("order.table.header.completionTime"),
    getMessage("order.table.header.actions"),
  ];
  if (!isExtensionEnabled("DeliverySlots")) {
    return headers;
  } else {
    headers.splice(3, 0, getMessage("order.table.header.expectedTime"));
    return headers;
  }
};
const tableProperties = (url, packedOrderEdit, orderConfiguration) => {
  return {
    headers: tableHeader(),
    row: ({
      items,
      slotType,
      clientId,
      referenceNumber,
      customer,
      address,
      completedAt,
      createdAt,
      invoiceAmount,
      itemCount,
      status,
      store,
      pickupLocation,
      pendingAmount,
      refundAmount,
      type,
      onAction,
      preferredDate,
      slotStartTime,
      slotEndTime,
      paymentStatus,
      fillRate,
      returnsByRE,
      courierDetails,
      shipping,
    }) => (
      <Consumer>
        {(value) => {
          const organization = (getSession() || {}).organization || {};
          let allowCall =
            value &&
            value.communication &&
            value.communication.allowCall !== false &&
            hasPermissions("communication", "call", "post");
          let allowEmail =
            value &&
            value.communication &&
            value.communication.allowEmail !== false &&
            hasPermissions("communication", "email", "post");
          const courierCancelled =
            Boolean(courierDetails) && courierDetails?.status === "CANCELLED";
          const courierName = courierDetails?.metaData?.courierName ?? "";
          const courierCharge = courierDetails?.metaData?.courierCharge ?? "";
          return (
            <Row>
              <Cell className="column-order-number">
                <div className="container-order-number">
                  <div>
                    {type.name && (
                      <div className="order-type-icons">
                        <Image src={orderTypeImages[type.name]} />
                      </div>
                    )}
                  </div>
                  <div>
                    <div>
                      <Link
                        className="order-number"
                        to={
                          url
                            ? `${url}/orders/${referenceNumber}`
                            : `/operations/orders/${referenceNumber}`
                        }
                      >
                        {clientId ? (
                          <span>
                            <span className="text-muted prefix">
                              {" "}
                              {getMessage("order.table.clientId.prefix")}{" "}
                            </span>{" "}
                            {clientId}
                          </span>
                        ) : (
                          <span>
                            {" "}
                            <span className="text-muted prefix">
                              {" "}
                              {getMessage(
                                "order.table.referenceNumber.prefix"
                              )}{" "}
                            </span>{" "}
                            {referenceNumber}
                          </span>
                        )}
                      </Link>
                    </div>
                    {type.name === "PICKUP" ? (
                      <small className="text-muted store-name">
                        {pickupLocation && pickupLocation.name}
                      </small>
                    ) : (
                      store && (
                        <small className="text-muted store-name">
                          {store.name}
                        </small>
                      )
                    )}
                  </div>
                </div>
              </Cell>
              <Cell className="column-customer-name">
                <div className="cx-name-text">
                  {customer ? (
                    <Link
                      className="customer-name"
                      to={
                        url
                          ? `${url}/customers/view/${customer.id}`
                          : `/operations/customers/view/${customer.id}`
                      }
                    >
                      {customer?.name}
                    </Link>
                  ) : null}
                </div>
                <div className="customer-actions">
                  {address && (
                    <CustomerMapWidget
                      address={
                        type.name === "DELIVERY"
                          ? address.address +
                            ", " +
                            address.city +
                            ", " +
                            address.pincode
                          : pickupLocation
                          ? pickupLocation.address
                          : ""
                      }
                    />
                  )}
                  {allowEmail &&
                    customer &&
                    customer.emails &&
                    !!customer.emails.length && (
                      <CustomerEmailWidget
                        emails={customer.emails.slice(0, 1)}
                        showOptions={false}
                      />
                    )}
                  {allowCall &&
                    customer &&
                    customer.phones &&
                    !!customer.phones.length && (
                      <CustomerCallWidget
                        phones={customer.phones.slice(0, 1)}
                        showOptions={false}
                      />
                    )}
                </div>
              </Cell>
              <Cell className="column-creation-time">
                <div>
                  {createdAt
                    ? getPrintableTime(createdAt).split(", ")[0]
                    : null}
                </div>
                <small className="text-muted">
                  {createdAt
                    ? getPrintableTime(createdAt).split(", ")[1]
                    : null}
                </small>
              </Cell>
              {isExtensionEnabled("DeliverySlots") ? (
                <Cell className="column-creation-time">
                  <div>
                    {preferredDate
                      ? getPrintableTime(preferredDate).split(", ")[0]
                      : null}
                  </div>
                  <small
                    className={`text-muted ${
                      !slotEndTime ? "zero-font" : ""
                    }`.trim()}
                  >
                    {slotStartTime && slotEndTime && slotType !== "ASAP"
                      ? formatTime(slotStartTime) +
                        " - " +
                        formatTime(slotEndTime)
                      : getAsapDuration(slotStartTime, slotEndTime)}
                  </small>
                </Cell>
              ) : null}
              <Cell className="column-order-amount">
                <div>
                  {((organization.currency && organization.currency.symbol) ||
                    "") + invoiceAmount}
                </div>
                <small className="text-muted">
                  {getMessage("order.table.itemCount", {
                    count: itemCount || (items && items.length) || 0,
                  })}
                </small>
              </Cell>
              <Cell className="column-order-status">
                <div>
                  {orderStatus[status] ? getMessage(orderStatus[status]) : null}
                </div>
                <PaymentStatusText
                  {...{ status, pendingAmount, refundAmount }}
                />
                {(type.name === "PICKUP" || type.name === "DELIVERY") &&
                  (status === "PACKED" ||
                    status === "COMPLETED" ||
                    status === "DISPATCHED") && (
                    <FillRateText {...{ fillRate }} />
                  )}
              </Cell>
              <Cell className="column-courier-and-charge">
                {!courierCancelled && (
                  <>
                    <div>{courierName ?? ""}</div>

                    <small className="text-muted">
                      {courierCharge
                        ? getSession().organization.currency.symbol +
                          Number(courierCharge).toFixed(2)
                        : null}
                    </small>
                  </>
                )}
              </Cell>
              <Cell className="column-completion-time">
                <div>
                  {completedAt
                    ? getPrintableTime(completedAt).split(", ")[0]
                    : null}
                </div>
                <small className="text-muted">
                  {completedAt
                    ? getPrintableTime(completedAt).split(", ")[1]
                    : null}
                </small>
              </Cell>
              <Cell className="column-order-actions">
                <TableActions
                  status={status}
                  clientId={clientId}
                  courierDetails={courierDetails}
                  referenceNumber={referenceNumber}
                  onAction={onAction}
                  type={type}
                  paymentStatus={paymentStatus}
                  pendingAmount={pendingAmount}
                  url={url || "/operations"}
                  packedOrderEdit={packedOrderEdit}
                  returnsByRE={returnsByRE}
                  invoiceSupportEnabled={
                    orderConfiguration?.invoiceSupportEnabled
                  }
                />
              </Cell>
            </Row>
          );
        }}
      </Consumer>
    ),
  };
};

export default tableProperties;

export { tripStatus };
