import React from "react";
import { useEffect } from "react";
import {
  Input,
  Upload,
  Radio,
  RichTextEditorQuill,
} from "../../../../components/Form";
import RichText from "../../../../components/Layout/RichText";
import { getMessage } from "../../../../lib/translator";
const COLOR_PATTERN = /^#([A-F0-9]{3}|[A-F0-9]{6})$/i;

const RichTextLayout = (props) => {
  const { getState, updateState, registerValidation } = props;
  useEffect(() => {
    if (!getState(["layoutBackground", "backgroundType"])) {
      updateState(["layoutBackground", "backgroundType"], "IMAGE");
    }
    handleThemeColorValidator(
      getState(["layoutBackground", "backgroundColor"]) || "#000000"
    );
    // eslint-disable-next-line
  }, []);
  const handleThemeColorValidator = (value) => {
    const error = {
      patternMismatch: !COLOR_PATTERN.test(value),
      valid: COLOR_PATTERN.test(value),
    };
    registerValidation(["layoutBackground", "backgroundColor"], error);
  };
  const isColorOption =
    getState(["layoutBackground", "backgroundType"]) === "COLOR";
  const colorVal = getState(["layoutBackground", "backgroundColor"]);
  const isColorError = !COLOR_PATTERN.test(colorVal);
  return (
    <div className="form-sections rich-text-layout">
      <div className="form-section">
        <Input
          label="title"
          placeholder="Enter Title here"
          value={getState(["title"]) || ""}
          onChange={(value) => {
            updateState(["title"], value);
          }}
        />
      </div>
      <div className="form-section">
        <Input
          label="sub title"
          placeholder="Enter Subtitle here"
          value={getState(["subtitle"]) || ""}
          onChange={(value) => {
            updateState(["subtitle"], value);
          }}
        />
      </div>
      <div className="form-section-full">
        <RichTextEditorQuill
          className="body"
          name={"descriptionBody"}
          toolbar={[
            [
              { color: [] },
              "bold",
              "italic",
              "underline",
              { list: "ordered" },
              { list: "bullet" },
              { align: [] },
            ],
          ]}
          placeholder={getMessage("campaign.form.emailBody.heading")}
          label={getMessage("campaign.form.emailBody.heading")}
          value={getState(["description"]) || ""}
          onChange={(value) => {
            updateState(["description"], value);
          }}
        />
        <div className="img-alignment-wrp">
          <Radio
            className="image-alignment"
            name="imgAlignment"
            skin="borderless-skin"
            options={[
              { text: "BACKGROUND IMAGE", value: "IMAGE" },
              { text: "BACKGROUND COLOR", value: "COLOR" },
            ]}
            value={getState(["layoutBackground", "backgroundType"]) || ""}
            onChange={(value) => {
              updateState(["layoutBackground", "backgroundType"], value);
            }}
          />
          {isColorOption ? (
            <div className="bg-color">
              <Input
                name="bg-color"
                type="color"
                value={getState(["layoutBackground", "backgroundColor"]) || ""}
                onChange={(value) => {
                  updateState(["layoutBackground", "backgroundColor"], value);
                  handleThemeColorValidator(value);
                }}
                siblings={{
                  after: (
                    <Input
                      name="bg-color"
                      type="text"
                      value={
                        getState(["layoutBackground", "backgroundColor"]) || ""
                      }
                      onChange={(value) => {
                        updateState(
                          ["layoutBackground", "backgroundColor"],
                          value
                        );
                        handleThemeColorValidator(value);
                      }}
                      showErrors={isColorError}
                      pattern={COLOR_PATTERN}
                      validationStrings={{
                        patternMismatch: "Please enter valid color",
                      }}
                    />
                  ),
                }}
              />
            </div>
          ) : (
            <Upload
              placeholder="Click here to upload or drag your image"
              value={getState(["layoutBackground", "backgroundImage"]) || ""}
              onChange={(value) => {
                updateState(["layoutBackground", "backgroundImage"], value);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
const RichTextLayoutWrp = () => {
  return {
    preview: RichText,
    fields: ({ getState, updateState, registerValidation }) => (
      <RichTextLayout
        getState={getState}
        updateState={updateState}
        registerValidation={registerValidation}
      />
    ),
  };
};
export default RichTextLayoutWrp;
