import React from "react";
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const SimpleLineChart = ({
  heading,
  headerTag = null,
  data,
  xKey = "date",
  yKey,
  toolTipContent,
}) => (
  <section className="analytic-line-chart">
    <header>
      <h5>{heading}</h5>
    </header>
    {headerTag}
    <ResponsiveContainer width={"100%"} height={300}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey={xKey} padding={{ left: 30 }} />
        <YAxis />
        <CartesianGrid />
        <Line
          dataKey={yKey}
          stroke="#20c05c"
          strokeWidth={2}
          activeDot={{ r: 6 }}
        />
        <Tooltip
          cursor={false}
          wrapperStyle={{
            width: "4rem",
            height: "3rem",
            top: "-4.5rem",
            left: "-2.75rem",
          }}
          content={toolTipContent}
        />
      </LineChart>
    </ResponsiveContainer>
  </section>
);

export default SimpleLineChart;
