import React, { Component } from "react";
import { Link } from "react-router-dom";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import { DragDropContext } from "react-beautiful-dnd";
import TripContainer from "./TripContainer";
import Loader from "../../../components/Loader";
import API from "../../../lib/api";
import { isExtensionEnabled } from "../../../lib/auth";
import { getMessage } from "../../../lib/translator";
import StoreSelector, {
  getDefaultStore,
  makestoreDependentComponent,
} from "../../../containers/StoreSelector";
import EmptyState from "../../../components/EmptyState";
import { Popup, Dialog } from "../../../components/Popup";
import Mapform from "../../operations/Customers/Details/AddressCard/MapForm";
import TripStatistics from "./Statistics";
import OpenTrips from "./OpenTrips";
import TripSequence from "./TripSequence";
import moment from "moment";
import { registerSocket } from "../../../registerSocket";
import { ZOPSMART_MAPS_URL } from "../../../config/app";
import emptyStateIcon from "./no-trips.svg";
import "./style.css";

class InterfaceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign(
      {
        data: null,
        submittingZoneIndexes: [],
        unassignedOrders: [],
        config: {
          interZoneDnD: true,
          sameZoneDnd: false,
          sameTripDnd: false,
        },
      },
      getDefaultStore(this.props.stores)
    );

    this.getPlannedTrips = this.getPlannedTrips.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.changeStore = this.changeStore.bind(this);
    this.showTripMap = this.showTripMap.bind(this);
    this.getStoreLocation = this.getStoreLocation.bind(this);
    this.modifyState = this.modifyState.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.getDeliveryAreas = this.getDeliveryAreas.bind(this);
    this.showDeliveryLocation = this.showDeliveryLocation.bind(this);
    this.hideMap = this.hideMap.bind(this);
    this.showSuccessDialog = this.showSuccessDialog.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.handleSuccessClose = this.handleSuccessClose.bind(this);
    this.zopsmartMapUrls = ZOPSMART_MAPS_URL;
  }
  getPlannedTrips() {
    let socket = registerSocket();
    if (socket) {
      socket.onmessage = this.updateOrder;
    }
    this.logisticsAutomationApi = new API({
      url: "/logistics-service/automation",
    });
    let params = {};
    params.refreshPlannedTrips = window.localStorage.getItem(
      "refreshPlannedTrips"
    );
    if (this.state.storeId) {
      params.storeId = this.state.storeId;
    }
    params.date = moment().format("YYYY-MM-DD");
    this.logisticsAutomationApi
      .get(params)
      .then((response) => {
        this.setState({
          data: response.data,
        });
      })
      .catch((error) => {
        // if user is unauthorized redirect to login screen
        if (error.code === 401) throw error;
        console.error(error);
      });
  }
  onDragStart(data) {}
  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    this.setState((prevState) => {
      let { zone, unassignedOrders } = this.reorder(
        prevState.data.zone,
        result.source,
        result.destination,
        prevState.unassignedOrders,
        prevState.config
      );
      let nextState = {
        data: Object.assign({}, prevState.data, {
          zone,
        }),
        unassignedOrders,
      };
      return nextState;
    });
  }
  reorder(zone, source, destination, unassignedOrders, config) {
    let sourceLocation = source.droppableId.match(/\d+/g).map(Number);
    let destinationLocation = destination.droppableId.match(/\d+/g).map(Number);
    let sourceZoneIndex = sourceLocation[0];
    let destinationZoneIndex = destinationLocation[0];
    let notReadyRegExp = new RegExp("^not-ready");
    if (
      notReadyRegExp.test(source.droppableId) ||
      notReadyRegExp.test(destination.droppableId)
    ) {
      zone = this.toggleTripOrders(
        zone,
        source,
        destination,
        unassignedOrders,
        config
      );
    } else {
      let sourceTripIndex = sourceLocation[1];
      let destinationTripIndex = destinationLocation[1];
      if (config.interZoneDnD) {
        let itemToMove = zone[sourceZoneIndex].plannedTrips[
          sourceTripIndex
        ].orders.splice(source.index, 1)[0];
        zone[destinationZoneIndex].plannedTrips[
          destinationTripIndex
        ].orders.splice(destination.index, 0, itemToMove);
      } else if (config.sameZoneDnd) {
        if (sourceZoneIndex === destinationZoneIndex) {
          let itemToMove = zone[sourceZoneIndex].plannedTrips[
            sourceTripIndex
          ].orders.splice(source.index, 1)[0];
          zone[destinationZoneIndex].plannedTrips[
            destinationTripIndex
          ].orders.splice(destination.index, 0, itemToMove);
        }
      } else if (config.sameTripDnd) {
        if (
          sourceZoneIndex === destinationZoneIndex &&
          sourceTripIndex === destinationTripIndex
        ) {
          let itemToMove = zone[sourceZoneIndex].plannedTrips[
            sourceTripIndex
          ].orders.splice(source.index, 1)[0];
          zone[destinationZoneIndex].plannedTrips[
            destinationTripIndex
          ].orders.splice(destination.index, 0, itemToMove);
        }
      }
    }
    // zone = zone.filter(this.getOrderNumbersForZone)
    return { zone, unassignedOrders };
  }
  toggleTripOrders(zone, source, destination, unassignedOrders, config) {
    let sourceLocation = source.droppableId.match(/\d+/g).map(Number);
    let destinationLocation = destination.droppableId.match(/\d+/g).map(Number);
    let sourceZoneIndex = sourceLocation[0];
    let sourceTripIndex = sourceLocation[1];
    let destinationZoneIndex = destinationLocation[0];
    let tripRegExp = new RegExp("^trip");
    let notReadyRegExp = new RegExp("^not-ready");

    let readyOrders = [];
    let notReadyOrders = [];
    zone[sourceZoneIndex].plannedTrips[sourceTripIndex].orders.forEach(
      (element) => {
        if (element.ignoreForTrip !== true) {
          readyOrders.push(element);
        } else {
          notReadyOrders.push(element);
        }
      }
    );

    if (sourceZoneIndex === destinationZoneIndex) {
      if (
        tripRegExp.test(source.droppableId) &&
        notReadyRegExp.test(destination.droppableId)
      ) {
        // Moving from tripContainer to not-ready
        let itemToMove = readyOrders.splice(source.index, 1)[0];
        itemToMove.ignoreForTrip = true;
        notReadyOrders.splice(destination.index, 0, itemToMove);
      } else if (
        notReadyRegExp.test(source.droppableId) &&
        tripRegExp.test(destination.droppableId)
      ) {
        // Moving from not-ready to tripContainer
        let itemToMove = notReadyOrders.splice(source.index, 1)[0];
        itemToMove.ignoreForTrip = false;
        readyOrders.splice(destination.index, 0, itemToMove);
      } else if (
        notReadyRegExp.test(source.droppableId) &&
        notReadyRegExp.test(destination.droppableId)
      ) {
        // Moving within not-ready
        let itemToMove = notReadyOrders.splice(source.index, 1)[0];
        notReadyOrders.splice(destination.index, 0, itemToMove);
      }
    }
    zone[sourceZoneIndex].plannedTrips[sourceTripIndex].orders =
      readyOrders.concat(notReadyOrders);
    return zone;
  }
  changeStore(storeId) {
    this.setState({ storeId }, this.getPlannedTrips);
  }
  showTripMap(orders, droppableId, recommendedVehicle, hub) {
    this.setState({
      showTripMap: true,
      tripMapData: orders,
      currentTripHub: hub,
      zoneTripDetails: droppableId,
      recommendedVehicle,
    });
  }
  onSuccess(lat, lng) {
    this.hideMap();
    this.showSuccessDialog();
    let zoneTripIndex = this.state.zoneTripDetails.match(/\d+/g).map(Number);
    let orderIndex = this.state.data.zone[zoneTripIndex[0]].plannedTrips[
      zoneTripIndex[1]
    ].orders.findIndex((order) => order.id === this.state.orderId);
    let newState = { ...this.state.data };
    let item = newState.zone[zoneTripIndex[0]].plannedTrips[
      zoneTripIndex[1]
    ].orders.splice(orderIndex, 1)[0];
    item.address.location.lat = parseFloat(lat);
    item.address.location.long = parseFloat(lng);
    newState.zone[zoneTripIndex[0]].plannedTrips[
      zoneTripIndex[1]
    ].orders.splice(orderIndex, 0, item);
    this.setState({ data: newState });
  }
  showSuccessDialog() {
    this.setState({
      showSuccessDialog: true,
    });
  }
  handleSuccessClose() {
    this.setState({
      showSuccessDialog: false,
    });
  }
  showDeliveryLocation(orderId, droppableId) {
    let zoneTripIndex = droppableId.match(/\d+/g).map(Number);
    let order = this.state.data.zone[zoneTripIndex[0]].plannedTrips[
      zoneTripIndex[1]
    ].orders.filter((order) => order.id === orderId);
    order = order[0];
    let fullAddress = [
      order.address.address,
      order.address.city,
      order.address.landmark,
      order.address.pincode,
    ]
      .filter((el) => !!el || el === 0)
      .join(", ");
    let coordinates = {
      lat: order.address.location.lat,
      lng: order.address.location.long,
    };
    let addressId = order.address.id;
    this.setState({
      showMap: true,
      orderId,
      address: fullAddress,
      coordinates,
      addressId,
      customerId: order.customerId,
      zoneTripDetails: droppableId,
    });
  }
  hideMap() {
    this.setState({
      showMap: false,
    });
  }
  getStoreLocation() {
    this.accountConfigApi = new API({
      url: "/account-service/config/basic",
    });
    this.accountConfigApi
      .get()
      .then((response) => {
        this.setState({
          storeLocation: {
            lat: response.data.config.basic.latitude,
            lng: response.data.config.basic.longitude,
          },
        });
      })
      .catch((error) => {
        // if user is unauthorized redirect to login screen
        if (error.code === 401) throw error;
        console.error(error);
      });
  }
  getOrderNumbersForZone(zone) {
    let totalOrders = zone.plannedTrips
      .map((trip) => trip.orders)
      .reduce((accOrder, currentOrders) => accOrder.concat(currentOrders), []);
    return totalOrders.length;
  }
  modifyState(orderNumbers, vehicleId, deliveryAgentId) {
    let newState = { ...this.state.data };
    newState.vehicles = newState.vehicles.filter(
      (vehicle) => vehicle.id !== parseInt(vehicleId, 10)
    );
    newState.executives = newState.executives.filter(
      (re) => re.id !== parseInt(deliveryAgentId, 10)
    );
    let zoneTripIndex = this.state.zoneTripDetails.match(/\d+/g).map(Number);
    let zoneIndex = zoneTripIndex[0];
    let tripIndex = zoneTripIndex[1];
    newState.zone[zoneIndex].plannedTrips[tripIndex].orders = newState.zone[
      zoneIndex
    ].plannedTrips[tripIndex].orders.filter(
      (order) => !orderNumbers.includes(order.id)
    );
    newState.zone = newState.zone.filter(this.getOrderNumbersForZone);
    this.setState({
      data: newState,
      showTripStarted: true,
      showTripMap: false,
    });
  }

  getDeliveryAreas() {
    if (isExtensionEnabled("DeliveryAreaSupport")) {
      const deliveryAreaApi = new API({
        url: `/logistics-service/delivery-area`,
      });
      deliveryAreaApi
        .get({ paginated: "false" })
        .then((response) => {
          this.setState({
            deliveryAreas: response.data.deliveryarea,
          });
        })
        .catch((error) => {
          // if user is unauthorized redirect to login screen
          if (error.code === 401) throw error;
          console.error(error);
        });
    }
  }

  getMapUrls = () => {
    let configApi = new API({ url: "/config-service/config/backOffice" });
    configApi
      .get()
      .then((res) => {
        this.zopsmartMapUrls = res?.data?.backOffice?.mapsUrl;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  componentDidMount() {
    this.getPlannedTrips();
    this.getMapUrls();
    if (!(this.props && this.props.stores)) {
      this.getStoreLocation();
    }
    this.getDeliveryAreas();
  }

  componentWillUnmount() {
    this.accountConfigApi && this.accountConfigApi.cancel();
    this.logisticsAutomationApi && this.logisticsAutomationApi.cancel();
  }

  updateOrder(msg) {
    if (!this) {
      return;
    }
    let data = msg.data;
    if (typeof data === "string") {
      data = JSON.parse(data);
    }
    let stateData = Object.assign({}, this.state.data);
    if (
      stateData &&
      stateData.zone &&
      stateData.zone.length > 0 &&
      Number(this.state.storeId) === Number(data.storeId)
    ) {
      let zones = Object.assign([], stateData.zone);
      zones.forEach((zone) => {
        zone.plannedTrips &&
          zone.plannedTrips.forEach((plannedTrip) => {
            plannedTrip.orders &&
              plannedTrip.orders.forEach((order) => {
                if (Number(order.id) === Number(data.referenceNumber)) {
                  order.status = data.newStatus;
                }
              });
          });
      });
      stateData.zone = zones;
      this.setState({
        data: stateData,
      });
    }
  }

  render() {
    if (!this.state.data) {
      return <Loader />;
    }
    let { zone, vehicles, executives } = this.state.data;
    let { deliveryAreas, storeId } = this.state;
    const { stores } = this.props;
    return (
      <div>
        <div className="flex-around">
          <h1>{getMessage("tripPlanner.heading")}</h1>
          {isExtensionEnabled("MultiStoreSupport") && (
            <StoreSelector
              value={this.state.storeId}
              onChange={this.changeStore}
              stores={stores}
            />
          )}
          {zone && zone.length ? (
            <Link
              className="trip-summary-header-link"
              to="/logistics/trip-planner/table-view"
            >
              {getMessage("tripPlanner.tableView")}
            </Link>
          ) : null}
        </div>
        <div className="trip-planner-page-content">
          <OpenTrips storeId={this.state.storeId} />
          {zone && !zone.length && (
            <EmptyState
              icon={emptyStateIcon}
              message={getMessage("tripPlanner.emptyState.helperText", {
                multipleStores: Number(isExtensionEnabled("MultiStoreSupport")),
              })}
            />
          )}
          {zone && zone.length ? (
            <DragDropContext
              onDragStart={this.onDragStart}
              onDragEnd={this.onDragEnd}
            >
              <div className="interface-container">
                {zone.map(
                  ({ name = "", plannedTrips = [], ...zone }, zoneIndex) => {
                    return (
                      <div
                        key={name + zoneIndex}
                        className="trip-outer-container"
                      >
                        <div
                          className="zone-title"
                          style={
                            name === "NO_ZONE"
                              ? { backgroundColor: "#eb8181" }
                              : null
                          }
                        >
                          {name === "NO_ZONE" ? "NO ZONE" : name}
                        </div>
                        {plannedTrips.map((trip, tripIndex) => (
                          <TripContainer
                            key={`${zoneIndex}-${tripIndex}`}
                            zone={zone}
                            orders={trip.orders}
                            droppableId={`Z${zoneIndex}T${tripIndex}`}
                            vehicles={vehicles}
                            executives={executives}
                            recommendedVehicle={trip.vehicle}
                            onStart={this.showTripMap}
                            // disabled={this.state.submittingZoneIndexes.indexOf(index) > -1}
                            showDeliveryLocation={this.showDeliveryLocation}
                            totalOrders={trip.orders.length}
                            startTime={trip.startTime ? trip.startTime : null}
                            endTime={trip.endTime ? trip.endTime : null}
                            onDragStart={this.onDragStart}
                            onDragEnd={this.onDragEnd}
                          />
                        ))}
                      </div>
                    );
                  }
                )}
              </div>
            </DragDropContext>
          ) : null}
          <TripStatistics
            data={zone}
            vehicles={vehicles}
            re={executives}
            storeId={this.state.storeId}
          />
        </div>
        <Popup
          className={"trip-sequence-popup"}
          show={this.state.showTripMap}
          close={() => this.setState({ showTripMap: false })}
        >
          <TripSequence
            deliveryData={this.state.tripMapData}
            onCancel={() => this.setState({ showTripMap: false })}
            currentTripHub={this.state.currentTripHub}
            storeData={stores}
            storeId={this.state.storeId}
            storeLocation={this.state.storeLocation}
            vehicle={vehicles}
            re={executives}
            modifyState={this.modifyState}
            recommendedVehicle={this.state.recommendedVehicle}
            zopsmartMapUrls={this.zopsmartMapUrls}
          />
        </Popup>
        <Popup
          className="editPopup map-address"
          heading={getMessage("customer.address.modal.heading")}
          show={this.state.showMap}
          close={this.hideMap}
        >
          <Mapform
            address={this.state.address}
            addressId={this.state.addressId}
            draggable
            onSuccess={this.onSuccess}
            coordinates={this.state.coordinates}
            tripPlanner
            orderId={this.state.orderId}
            customerId={this.state.customerId}
            isTripPlannerPage
            stores={stores}
            deliveryAreas={deliveryAreas}
            storeId={storeId}
          />
        </Popup>
        {this.state.showSuccessDialog && (
          <Dialog
            show={this.state.showSuccessDialog}
            close={this.handleSuccessClose}
            title={getMessage("customer.address.success")}
            information={getMessage("customer.address.saved")}
            closeText={getMessage("customer.address.okay")}
            className="success"
          />
        )}
        {this.state.showTripStarted && (
          <Dialog
            show={this.state.showTripStarted}
            close={() => {
              this.setState({ showTripStarted: false });
            }}
            title={getMessage("tripPlanner.trip.started")}
            closeText={getMessage("customer.address.okay")}
            className="success"
          />
        )}
      </div>
    );
  }
}

const InterfaceWithStores = makestoreDependentComponent(InterfaceContainer);

export default class TripPlanner extends Component {
  render() {
    return (
      <AuthenticatedPage menu={this.props.menu} className="trip-planner">
        <InterfaceWithStores />
      </AuthenticatedPage>
    );
  }
}
