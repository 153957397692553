/* eslint-disable react/jsx-no-literals */
import React from "react";

function LeftArrow(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m8 4 8 8-8 8" stroke="#4AB819" fill="none" />
    </svg>
  );
}

export default LeftArrow;
