import React from "react";
import { Link } from "react-router-dom";
import { BaseForm, Input } from "../../../../components/Form";
import Table, { Row, Cell, Header } from "../../../../components/Table";
import Loader from "../../../../components/Loader";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import { getMessage } from "../../../../lib/translator";
import { getCurrency } from "../../../../lib/userDetails";
import API from "../../../../lib/api";
import { get } from "../../../../lib/storage";
import { getDefaultStore } from "../../../../containers/StoreSelector";
import "./style.css";

export default class TripPaymentsForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      values: {},
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.checkSubmittable = this.checkSubmittable.bind(this);
  }

  componentDidUpdate() {
    let remainingAmounts = {};
    let totalRemaining = 0;
    let flag = false;
    Object.keys(this.state.values).forEach((order) => {
      remainingAmounts[order] =
        Math.round(
          (parseFloat(this.state.pendingAmounts[order]) -
            Object.keys(this.state.values[order])
              .map((k) =>
                this.state.values[order][k]["amount"]
                  ? this.state.values[order][k]["amount"]
                  : 0
              )
              .reduce((a, c) => parseFloat(a) + parseFloat(c))) *
            1e12
        ) / 1e12;
      totalRemaining =
        Math.round((totalRemaining + remainingAmounts[order]) * 1e12) / 1e12;
      if (this.state.remainingAmounts) {
        if (this.state.remainingAmounts[order] !== remainingAmounts[order]) {
          flag = true;
        }
      }
    });
    flag && this.setState({ remainingAmounts, totalRemaining });
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    const storeId = get("store") || getDefaultStore().storeId;
    const id = this.props.match.params.id;
    const api = new API({ url: `/logistics-service/trip/${id}` });
    const orderConfigApi = new API({
      url: `/config-service/config/order?storeId=${storeId}`,
    });
    Promise.all([api.get(), orderConfigApi.get()])
      .then(([apiResponse, configResponse]) => {
        let values = {};
        let pendingAmounts = {};
        apiResponse = apiResponse.data.trip;
        let allOrders = apiResponse.orders;
        let disabledModes =
          configResponse.data.order?.finCloseDisabledPaymentModes;
        disabledModes = disabledModes || [];
        const paymentModesResponse = configResponse.data.order?.paymentMethods;
        let paymentMethods;
        if (paymentModesResponse?.INTERNAL_APP?.["DELIVERY"]) {
          paymentMethods = paymentModesResponse.INTERNAL_APP["DELIVERY"];
        } else {
          paymentMethods = configResponse.data.order.paymentMethods?.[
            "DELIVERY"
          ]?.filter((mode) => disabledModes.indexOf(mode) === -1);
        }
        let ordersWithPendingPayments =
          apiResponse.orders &&
          apiResponse.orders.filter(({ pendingAmount }) =>
            Number(pendingAmount)
          );
        ordersWithPendingPayments &&
          ordersWithPendingPayments.forEach((order, i) => {
            pendingAmounts[order.referenceNumber] = Number(order.pendingAmount);
            if (order.paymentsByRE && order.paymentsByRE.length > 0) {
              let orderPaymentModes = {};
              paymentMethods.forEach((payment) => {
                let value = order.paymentsByRE.filter(
                  (mode) => mode[payment]
                )[0];
                if (value) {
                  orderPaymentModes[payment] = {
                    amount: Number(Number(value[payment]?.amount).toFixed(2)),
                    transactionId: value[payment].transaction_id,
                  };
                } else {
                  orderPaymentModes[payment] = {
                    amount: 0,
                    transactionId: null,
                  };
                }
              });
              values[order.referenceNumber] = orderPaymentModes;
            } else {
              let orderPaymentModes = {};
              paymentMethods.forEach((payment) => {
                let value = order.payment?.filter((mode) => mode[payment])[0];
                if (value) {
                  orderPaymentModes[payment] = {
                    amount: Number(Number(value[payment]?.amount).toFixed(2)),
                    transactionId: value[payment].transactionId,
                  };
                } else {
                  orderPaymentModes[payment] = {
                    amount: 0,
                    transactionId: null,
                  };
                }
              });
              values[order.referenceNumber] = orderPaymentModes;
            }
          });
        this.setState(
          {
            paymentModes: paymentMethods,
            loading: false,
            values: values,
            allOrders: allOrders,
            pendingAmounts: pendingAmounts,
            storeId: apiResponse.storeId,
          },
          () => {
            let remainingAmounts = {};
            let totalRemaining = 0;
            Object.keys(this.state.values).forEach((order) => {
              remainingAmounts[order] =
                Math.round(
                  (parseFloat(this.state.pendingAmounts[order]) -
                    Object.keys(this.state.values[order])
                      .map((k) =>
                        this.state.values[order][k]["amount"]
                          ? this.state.values[order][k]["amount"]
                          : 0
                      )
                      .reduce((a, c) => parseFloat(a) + parseFloat(c))) *
                    1e12
                ) / 1e12;
              totalRemaining =
                Math.round((totalRemaining + remainingAmounts[order]) * 1e12) /
                1e12;
            });
            this.setState({ remainingAmounts, totalRemaining });
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  formatPayload = (payload) => {
    const tempData = { ...payload };
    Object.entries(tempData).forEach(([key, item]) => {
      Object.keys(item).forEach((obj) => {
        if (Number(item[obj]?.amount) === 0) {
          delete item[obj];
        }
      });
    });
    return tempData;
  };

  onSubmit(formData) {
    let data = JSON.parse(JSON.stringify(formData));
    const api = new API({
      url: `/logistics-service/trip/${this.props.match.params.id}`,
    });
    let params = {};
    params.payments = this.formatPayload(data);
    params.status = "FIN_CLOSE";
    params.storeId = this.state.storeId;
    params.referenceNumber = Object.keys(data);
    api
      .put(params)
      .then(
        (response) => {
          this.props.history.goBack();
        },
        (error) => {
          this.setState({
            formError: error.message,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  handleCancel() {
    this.props.history.goBack();
  }

  checkSubmittable() {
    let allRemaining = this.state.remainingAmounts;
    let flag = true;
    Object.keys(allRemaining).forEach((order) => {
      if (allRemaining[order] !== 0) {
        flag = false;
        return false;
      }
    });
    return flag;
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const { paymentModes, loading, values, pendingAmounts, allOrders } =
      this.state;
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <h1>{getMessage("order.register.payments")}</h1>
        {loading ? (
          <Loader />
        ) : (
          <div className="trip-payments-form">
            <Form>
              {this.state.formError && (
                <div className="form-error">{this.state.formError}</div>
              )}
              <Table>
                <Header>
                  <Cell>{getMessage("order.heading")}</Cell>
                  {paymentModes.map((paymentMode, i) => (
                    <Cell key={`header-${paymentMode}`}>
                      {getMessage(`${paymentMode}`) +
                        ` (${getCurrency().symbol})`}
                    </Cell>
                  ))}
                  <Cell>
                    {getMessage(`order.paymentmode.PENDING`) +
                      `(${getCurrency().symbol})`}
                  </Cell>
                </Header>
                {Object.keys(values).map((order, i) => {
                  let foundOrder = allOrders.find((odr) => {
                    return Number(odr.referenceNumber) === Number(order);
                  });
                  return (
                    <Row key={`fragment-${order}`}>
                      <Cell key={order} className="order-number">
                        <span className="text-muted">
                          {foundOrder.clientId ? "#" : "Z"}
                        </span>
                        <Link to={`/operations/orders/${order}`}>
                          {foundOrder.clientId
                            ? `${foundOrder.clientId}`
                            : `${order}`}
                        </Link>
                        <div>
                          <small className="text-muted">
                            {pendingAmounts[order]}
                          </small>
                        </div>
                      </Cell>
                      {paymentModes.map((paymentMode, i) => (
                        <Cell
                          key={`cell-${order}-${paymentMode}`}
                          className="payment-input"
                        >
                          <Input
                            key={`${order}-${paymentMode}`}
                            name={`${order}-${paymentMode}`}
                            type="number"
                            step={0.01}
                            {...this.generateStateMappers({
                              stateKeys: [order, paymentMode, "amount"],
                            })}
                            validationStrings={{
                              stepMismatch: "2 decimal places only",
                            }}
                          />
                          <div className="trx-id">
                            <small className="text-muted">
                              {this.getState([
                                order,
                                paymentMode,
                                "transactionId",
                              ])
                                ? `${this.getState([
                                    order,
                                    paymentMode,
                                    "transactionId",
                                  ])}`
                                : " "}
                            </small>
                          </div>
                        </Cell>
                      ))}
                      <Cell className="total-amount">
                        {this.state.remainingAmounts &&
                          parseFloat(
                            this.state.remainingAmounts[order]
                          ).toFixed(2)}
                        <div className="trx-id"> &nbsp; </div>
                      </Cell>
                    </Row>
                  );
                })}
                <Row>
                  <Cell className="total-amount">Total</Cell>
                  {paymentModes.map((paymentMode, i) => (
                    <Cell
                      key={`cell-total-${paymentMode}`}
                      className="total-amount"
                    >
                      {Object.keys(values)
                        .map((order) =>
                          values[order][paymentMode]["amount"]
                            ? values[order][paymentMode]["amount"]
                            : 0
                        )
                        .reduce(
                          (a, c) =>
                            Math.round((parseFloat(a) + parseFloat(c)) * 1e12) /
                            1e12,
                          0
                        )
                        .toFixed(2)}
                    </Cell>
                  ))}
                  <Cell className="total-amount">
                    {this.state.totalRemaining !== undefined &&
                      parseFloat(this.state.totalRemaining).toFixed(2)}
                  </Cell>
                </Row>
              </Table>
              <div className="form-actions">
                <SubmitButton>
                  {getMessage("trips.paymentsForm.submitText")}
                </SubmitButton>
                <button
                  className="button cancel-button"
                  type="button"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        )}
      </AuthenticatedPage>
    );
  }
}
