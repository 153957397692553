import React from "react";
import { BaseForm } from "../../../../../components/Form";
import MetaDataFormFields from "../../../../../components/MetaDataFormFields";
import API from "../../../../../lib/api";
import { hasPermissions } from "../../../../../lib/auth";
import { getMessage } from "../../../../../lib/translator";
import { cloneMutables } from "../../../../../lib/stateManagement";
import UploadedFileView from "../UploadedFileView";

import editIcon from "../edit-icon.svg";
import "./style.css";

class OrderMetaData extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      saving: false,
      canBeEdited: false,
    };
  }

  componentDidMount() {
    this.extnsnApi = new API({ url: `/config-service/meta-data` });
    this.extnsnApi
      .get()
      .then((response) => {
        let config = response.data.config.entityMetaData.order;
        this.setState(
          {
            config,
            canBeEdited: true,
          },
          () => {
            this.setState((prevState) =>
              this.generateStateFromProps(this.props, this.state)
            );
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
    this.ignoreKeysInView = ["pickupPersonContact", "pickupPersonName"];
  }

  generateStateFromProps(props, state) {
    let newState = Object.assign({}, state);
    let config = newState.config;
    let { data } = props;
    let values = {};
    config &&
      Object.entries(config).forEach(([key, type], index) => {
        values[key] = data && data[key];
      });
    newState.values = values;
    return cloneMutables(newState);
  }

  onSubmit(formData) {
    let data = Object.assign({}, formData);
    if (this.props.onChange) {
      this.setState(
        {
          saving: true,
        },
        () => {
          this.props
            .onChange(data)
            .then(
              (response) => {
                this.setState({
                  error: false,
                });
              },
              (error) => {
                this.setState({
                  error: error,
                });
                if (error.code === 401) {
                  throw error;
                }
              }
            )
            .then(() =>
              this.setState({
                editing: false,
                saving: false,
              })
            );
        }
      );
    }
  }
  componentWillUnmount() {
    this.extnsnApi && this.extnsnApi.cancel();
  }

  render() {
    const { editing, config, saving } = this.state;
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const { data } = this.props;
    const canBeEdited = hasPermissions("order", "order", "put");
    return (
      <div className="order-metadata-details">
        {editing ? (
          <Form>
            <div className="flex-around section-title">
              <h3>Custom Fields Details</h3>
            </div>
            <MetaDataFormFields
              className="meta-data"
              readOnly={!canBeEdited}
              stateKeys={[]}
              title={<h3>Order Metadata</h3>}
              metaDataWithType={config}
              page="order-details"
              _this={this}
            />
            {canBeEdited && (
              <div className="form-action">
                <SubmitButton disabled={saving}>
                  {getMessage("order.submit")}
                </SubmitButton>
                <button
                  type="button"
                  className="button"
                  onClick={() =>
                    this.setState((prevState) =>
                      this.generateStateFromProps(
                        this.props,
                        Object.assign({}, prevState, { editing: false })
                      )
                    )
                  }
                >
                  {getMessage("order.cancel")}
                </button>
              </div>
            )}
          </Form>
        ) : (
          <div className="order-metadata-details">
            <div className="flex-around section-title">
              <h3>Custom Fields Details</h3>
              {this.props.editable ? (
                <img
                  src={editIcon}
                  alt="Edit"
                  className="edit-icon"
                  onClick={() => this.setState({ editing: true })}
                />
              ) : null}
            </div>
            {config &&
              Object.entries(config)
                .map(([key, type], index) => {
                  if (data && data[key]) {
                    if (type.type === "file" && !data[key]?.url) return false;
                    if (this.ignoreKeysInView.indexOf(key) > -1) {
                      return false;
                    }
                    return (
                      <div key={`metadata-${key}-${index}`}>
                        <small className="text-muted">
                          {key}:{" "}
                          <span className="creation-time">
                            {data[key] === true ? (
                              "Yes"
                            ) : type.type === "file" && data[key].accessUrl ? (
                              <UploadedFileView orderDetailData={data[key]} />
                            ) : typeof data[key] === "string" ? (
                              getMessage(data[key])
                            ) : (
                              JSON.stringify(data[key])
                            )}
                          </span>
                        </small>
                      </div>
                    );
                  }
                  return null;
                })
                .filter(Boolean)}
          </div>
        )}
      </div>
    );
  }
}

export default OrderMetaData;
