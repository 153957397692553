import React, { Component } from "react";
import { getMessage } from "../../../../lib/translator";
import Picker from "../svg/picker.svg";

class TotalPickers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPickers: 0,
      activePickers: 0,
      idlePickers: 0,
    };
  }

  componentDidMount() {
    let { employees, pickers } = this.props;

    this.setState({
      totalPickers: employees.length,
      activePickers: pickers.length,
      idlePickers: employees.length - pickers.length,
    });
  }

  render() {
    let { totalPickers, activePickers, idlePickers } = this.state;
    return (
      <div className="pickers-side-bar-container">
        <div className="pickers-total-pickers-container">
          <span>{totalPickers}</span>
          <br />
          {getMessage("pickers.today.total.pickers")}
          <br />
          <img src={Picker} alt="" className="pickers-icon" />
        </div>
        <div className="pickers-active-idle">
          <div className="pickers-active-pickers-container">
            <span>{activePickers}</span>
            <br />
            {getMessage("pickers.today.active.pickers")}
          </div>
          <div className="pickers-idle-pickers-container">
            <span>{idlePickers}</span>
            <br />
            {getMessage("pickers.today.idle.pickers")}
          </div>
        </div>
      </div>
    );
  }
}

export default TotalPickers;
