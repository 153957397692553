import "sanitize.css/sanitize.css";
import React, { Component } from "react";
import Router from "./router";
import { getLocale, setLocale } from "../../lib/translator";
import { isLoggedIn, saveSession } from "../../lib/auth";
import ErrorBoundary from "../../components/ErrorBoundary";
import ReactGA from "react-ga";
import "../../lib/polyfills";
import API from "../../lib/api";
import "./app.css";
import { messageHandler } from "../../lib/internalDashboard/internalDashboard";
import { FaroErrorBoundary, withFaroProfiler } from "@grafana/faro-react";

ReactGA.initialize("UA-108420673-2");
class App extends Component {
  componentDidMount() {
    if (window) {
      // for handling communication with internal-dashboard to handle certain actions
      window.onload = function () {
        window.addEventListener("message", messageHandler);
      };
    }

    // fetch permissions wnenever app loads for logged in users, so that stale permission data from localstorage is not used when the user refreshes the page
    if (window.localStorage.getItem("permissions")) {
      const api = new API({ url: "/account-service/me" });
      api
        .get()
        .then(
          (response) => {
            saveSession({
              user: response.data.user,
            });
          },
          () => {
            // ignore this error
          }
        )
        .catch((error) => {
          console.error(error);
        });
    }
    import("../../initialize").then(({ initializeFaro }) => {
      initializeFaro();
    });
  }
  componentWillUnmount() {
    if (window) {
      window.removeEventListener("message", messageHandler);
    }
  }
  render() {
    if (window.location.hostname !== "localhost") {
      ReactGA.pageview(window.location.pathname + window.location.search); // TODO: Move this to router if possible
    }
    let locale = getLocale();
    if (!locale.language) {
      locale.language = "en";
    }
    setLocale(locale);
    return (
      <FaroErrorBoundary>
        <ErrorBoundary>
          <Router isLoggedIn={isLoggedIn()} />
        </ErrorBoundary>
      </FaroErrorBoundary>
    );
  }
}
export default withFaroProfiler(App);
