import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  Checkbox,
} from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";

export default class StoresFiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    return (
      <Form className="stores-filter">
        <div className="form-fields">
          <Input
            label={getMessage("store.filters.name.heading")}
            placeholder={getMessage("store.filters.name.placeholder")}
            name="name"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <Input
            label={getMessage("operations.store.filters.clientId.heading")}
            placeholder={getMessage(
              "operations.store.filters.clientId.placeholder"
            )}
            name="clientId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["clientId"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className="flex">
          <Checkbox
            className="delivey-hub"
            name="hasPicking"
            label={getMessage("operations.store.form.hasPicking")}
            controlled
            {...this.generateStateMappers({
              stateKeys: ["hasPicking"],
              loseEmphasisOnFill: true,
            })}
          />
          <Checkbox
            className="delivey-hub"
            name="hasDeliveryHub"
            label={getMessage("operations.store.form.hasDeliveryHub")}
            controlled
            {...this.generateStateMappers({
              stateKeys: ["hasDeliveryHub"],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage("store.filters.submitText")}</SubmitButton>
        <ClearButton>{getMessage("store.clear")}</ClearButton>
      </Form>
    );
  }
}
