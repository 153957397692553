import React, { Component } from "react";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { Link } from "react-router-dom";
import CapacityPlanningForm from "./Form";
import CapacityPlanningFilter from "./Filters";
import { getMessage } from "../../../lib/translator";
import API from "../../../lib/api";
import { get } from "../../../lib/storage";
import { getDisplaySlot } from "../../../lib/commonlyused";
import { getPrintableTime, getDaysOfWeek } from "../../../lib/datetime";
import { SingleDatePicker } from "../../../components/Form";

import "./style.css";

import EmptyIcon from "./capacityplanner.svg";
import { isExtensionEnabled } from "../../../lib/auth";

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage("cp.empty"),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD);
      }}
    >
      <span className="text">{`+ ${getMessage("cp.add")}`}</span>
    </button>
  ),
};

const tableProperties = (options) => {
  return {
    headers: [
      getMessage("cp.type"),
      getMessage("cp.area"),
      getMessage("Slot"),
      getMessage("cutoff.time"),
      getMessage("Date"),
      getMessage("Capacity"),
      getMessage("Actions"),
    ],
    row: ({
      id,
      orderType,
      cutOffTime,
      day,
      date,
      capacity,
      zone,
      slot,
      slotId,
      zoneId,
      onAction,
    }) => {
      const derivedZone =
        !zone &&
        options.zones &&
        options.zones.filter((zone) => zone.id === zoneId)[0];
      const slots =
        orderType === "PICKUP" ? options.pickupSlots : options.deliverySlots;
      const derivedSlot =
        !slot && slots && slots.filter((slot) => slot.id === slotId)[0];
      let split = cutOffTime && cutOffTime.split(":");
      let displayCutOffTime = split && split[0] && Number(split[0]);
      if (split.length > 1 && split[1].trim() === "30" && displayCutOffTime) {
        displayCutOffTime = Number(displayCutOffTime) + Number(0.5);
      } else if (
        split.length > 1 &&
        split[1].trim() === "30" &&
        displayCutOffTime === 0
      ) {
        displayCutOffTime = Number(displayCutOffTime) + Number(0.5);
      } else {
        displayCutOffTime = Number(displayCutOffTime) + ".0";
      }
      return (
        <Row>
          <Cell className="capacity-order-type">
            <span onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}>
              {orderType &&
                orderType.split("")[0] +
                  orderType
                    .toLowerCase()
                    .split("")
                    .slice(1, orderType.length)
                    .join("")}
            </span>
          </Cell>
          <Cell className="capacity-zone-name">
            <span className="text-muted">
              {(zone && zone.name) || (derivedZone && derivedZone.name)}
            </span>
          </Cell>
          <Cell className="capacity-slot">
            <div>
              <span className="text-muted mobile-header-slot">
                {getMessage("Slot")}
              </span>
              <p>
                {(slot && getDisplaySlot(slot)) ||
                  (derivedSlot && getDisplaySlot(derivedSlot))}
              </p>
            </div>
          </Cell>
          <Cell className="capacity-cutoff-time">
            <div>
              <span className="text-muted mobile-header-cutoff">
                {getMessage("cutoff.time")}
              </span>
              <p>{`${displayCutOffTime} h`}</p>
            </div>
          </Cell>
          <Cell className="capitalize capacity-day">
            {(date && getPrintableTime(date).split(",")[0]) ||
              (day && day === "ALLDAYS" ? "All Days" : day.toLowerCase())}
          </Cell>
          <Cell className="text-right capacity">
            <div>
              <span className="text-muted mobile-header-capacity">
                {getMessage("Capacity")}
              </span>
              <p>{capacity}</p>
            </div>
          </Cell>
          <Cell className="capacity-actions">
            <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
              <DropDownItem
                onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}
              >
                Edit
              </DropDownItem>
              <DropDownItem
                onClick={() => onAction(TABLE_ACTIONS.DELETE, { id })}
              >
                Delete
              </DropDownItem>
            </DropDown>
          </Cell>
        </Row>
      );
    },
  };
};

const planningTypes = [
  {
    text: getMessage("delivery"),
    value: "DELIVERY",
  },
  {
    text: getMessage("pickup"),
    value: "PICKUP",
  },
];
class CapacityPlanning extends Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.getZonesAndSlots = this.getZonesAndSlots.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.state = {
      zones: [],
      slots: [],
      date: null,
    };
  }

  componentDidMount() {
    this.getZonesAndSlots();
    let daysOfWeek = getDaysOfWeek({ full: true });
    daysOfWeek.unshift({ text: "All Days", value: "ALLDAYS" });
    this.setState({ daysOfWeek });
  }
  onDateChange(date) {
    this.setState({
      date,
    });
    this.props.history.push({
      pathname: `capacity-planning/today`,
      date: date,
    });
  }

  getZonesAndSlots(storeId) {
    this.zonesApi = new API({ url: `/logistics-service/delivery-area` });
    const zonesParams = {
      paginated: "false",
      storeId: storeId || get("store"),
    };

    if (isExtensionEnabled("DeliveryAreaSupport")) {
      this.zonesApi.get(zonesParams).then((zonesResponse) => {
        this.setState({
          zones: zonesResponse.data.deliveryarea || [],
        });
      });
    }

    this.slotsApiPickup = new API({ url: `/order-service/slot` });
    const slotParams = {
      paginated: "false",
      storeId: storeId || get("store"),
      orderType: "PICKUP",
    };

    this.slotsApiDelivery = new API({ url: "/order-service/slot" });
    const slotParamsDelivery = {
      paginated: "false",
      storeId: storeId || get("store"),
      orderType: "DELIVERY",
    };

    // TODO: need to see abt pickup zones. right now using stores but need to be converted to zones

    Promise.all([
      this.slotsApiPickup.get(slotParams),
      this.slotsApiDelivery.get(slotParamsDelivery),
    ])
      .then(([slotsPickupResponse, slotsDeliveryResponse]) => {
        this.setState({
          pickupSlots: slotsPickupResponse.data.slot || [],
          deliverySlots: slotsDeliveryResponse.data.slot || [],
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentWillUnmount() {
    this.zonesApi && this.zonesApi.cancel();
    this.slotsApi && this.slotsApi.cancel();
    window.removeEventListener("resize", this.mobileView, false);
  }

  update(storeId) {
    this.getZonesAndSlots(storeId);
  }

  render() {
    const options = {
      pickupSlots: this.state.pickupSlots,
      deliverySlots: this.state.deliverySlots,
      zones: this.state.zones,
      type: planningTypes,
      daysOfWeek: this.state.daysOfWeek,
      date: this.state.date,
      onDateChange: this.onDateChange,
    };
    return (
      <ListingPage
        className="capacity-planning"
        api={{
          url: "/logistics-service/zone-capacity",
          transform: (response) => response.data.capacityPlanning,
          updateApiParams: (
            prevParams,
            newParams,
            prevStateParams,
            newStateParams
          ) => {
            if (
              prevStateParams &&
              newStateParams &&
              prevStateParams.storeId !== newStateParams.storeId
            ) {
              this.update(newStateParams.storeId);
              return {
                shouldUpdate: true,
                params: {
                  storeId: newStateParams.storeId,
                },
              };
            }
          },
        }}
        emptyState={EmptyState}
        headerActions={({ onAction }) => (
          <React.Fragment>
            <div className="datepick-wrapper">
              <SingleDatePicker
                allowAllDates
                displayFormat="YYYY-MM-DD"
                onChange={this.onDateChange}
                value={this.state.date}
              />
            </div>
            <span className="today-heading">
              <Link to="/operations/capacity-planning/today">Today</Link>
            </span>
            <button
              className="primary button"
              onClick={() => {
                onAction(TABLE_ACTIONS.ADD);
              }}
            >
              {" "}
              <span className="text">{`+ ${getMessage("cp.add")}`}</span>
            </button>
          </React.Fragment>
        )}
        editHeading={getMessage("cp.edit")}
        addHeading={getMessage("cp.add")}
        tableProperties={tableProperties(options)}
        menu={this.props.menu}
        title={getMessage("cp.title")}
        storeDependent
        modalClassName="capacity-planning-form"
        form={{
          component: CapacityPlanningForm,
          options: options,
          allowDelete: true,
          transformSubmit: (formData) => {
            let data = Object.assign({}, formData);
            if (data.capacityType === "DAY") {
              delete data.date;
            } else {
              if (data.date && !data.date.includes("T")) {
                data.date = data.date + "T00:00:00Z";
              }
              delete data.day;
            }
            if (data.orderType === "PICKUP") {
              delete data.zoneId;
            }
            delete data.capacityType;
            delete data.zone;
            delete data.slot;
            delete data.id;
            return data;
          },
        }}
        filters={{
          component: CapacityPlanningFilter,
          options: options,
          transformSubmit: (formData) => {
            return formData;
          },
        }}
      />
    );
  }
}

export default CapacityPlanning;
