import React from "react";
import AuthenticatedPage from "../../../containers/AuthenticatedPage/index";
import { getMessage } from "../../../lib/translator";

export default function NotDone(props) {
  return (
    <AuthenticatedPage menu={props.menu}>
      <h1>{getMessage("404.heading")}</h1>
      <p>
        We are still working on <strong>{props.location.pathname}</strong>
      </p>
    </AuthenticatedPage>
  );
}
