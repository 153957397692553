import React, { useEffect } from "react";
import Quill from "quill";
import imageUpload from "quill-plugin-image-upload";
import API from "../../../../lib/api";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "./RichTextEditorQuill.css";
const DirectionAttribute = Quill.import("attributors/attribute/direction");
Quill.register(DirectionAttribute, true);
const AlignClass = Quill.import("attributors/class/align");
Quill.register(AlignClass, true);
const BackgroundClass = Quill.import("attributors/class/background");
Quill.register(BackgroundClass, true);
const ColorClass = Quill.import("attributors/class/color");
Quill.register(ColorClass, true);
const DirectionClass = Quill.import("attributors/class/direction");
Quill.register(DirectionClass, true);
const FontClass = Quill.import("attributors/class/font");
Quill.register(FontClass, true);
const SizeClass = Quill.import("attributors/class/size");
Quill.register(SizeClass, true);
const AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);
const BackgroundStyle = Quill.import("attributors/style/background");
Quill.register(BackgroundStyle, true);
const ColorStyle = Quill.import("attributors/style/color");
Quill.register(ColorStyle, true);
const DirectionStyle = Quill.import("attributors/style/direction");
Quill.register(DirectionStyle, true);
const FontStyle = Quill.import("attributors/style/font");
Quill.register(FontStyle, true);
const SizeStyle = Quill.import("attributors/style/size");
Quill.register(SizeStyle, true);

// register quill-plugin-image-upload
Quill.register("modules/imageUpload", imageUpload);

function upload(file) {
  // Upload file to server
  return new Promise((resolve, reject) => {
    let data = new window.FormData();
    data.append("imageUpload", file);
    const api = new API({ url: "/media-service/image-upload" });
    api
      .post(data)
      .then((response) => {
        resolve(response.data.imageUpload.url);
      }, reject)
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}

const emailBodyImageStyle = `max-width: 100%; object-fit: cover; object-position: center`;

const quillOptions = (toolbar) => {
  return {
    modules: {
      toolbar: toolbar || ["image"],
      imageUpload: {
        // uses quill-plugin-image-upload to upload the image and returns the url
        upload: (file) => {
          return new Promise((resolve, reject) => {
            upload(file)
              .then((url) => resolve(url))
              .catch(reject);
          });
        },
      },
    },
    theme: "snow",
  };
};

export default function RichTextEditorQuill(props) {
  const {
    name,
    onFocus,
    onChange,
    placeholder,
    readOnly,
    toolbar,
    value,
    imgStyled,
  } = props;

  useEffect(() => {
    const quill = new Quill(`#${name}`, {
      ...quillOptions(toolbar),
      readOnly,
      placeholder,
    });
    if (quill.root.innerHTML === "<p><br></p>" && value) {
      quill.root.innerHTML = value;
    }
    quill.on("editor-change", () => {
      // on any change in the editor it will be fired
      const quillContents = quill.getContents(); // fetches content after every change
      if (imgStyled) {
        let appendQuillContent = ``;
        quillContents.ops.forEach((quillContent) => {
          if (quillContent.insert.hasOwnProperty("imageUpload")) {
            // if content is image appending img tag else appending text content
            appendQuillContent += `<img src="${quillContent.insert.imageUpload}" style="${emailBodyImageStyle}" />`;
          } else {
            appendQuillContent += quillContent.insert;
          }
        });
        onChange(appendQuillContent); // passing appended html content for preview
      } else {
        onChange(quill.root.innerHTML);
      }

      quill.hasFocus() && onFocus && onFocus({ id: name });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, quillOptions, onFocus]);

  return <div id={name} />;
}
