import React from "react";
import {
  BaseForm,
  Input,
  Radio,
  Checkbox,
  Searchable,
  VALIDATION_TYPES,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import twoWheelerIcon from "./2-wheeler.svg";
import fourWheelerIcon from "./4-wheeler.svg";
import droneIcon from "./drone.svg";
import "./style.css";

/** *`VehicleForm`*
 * :adds management to **`Vehicles`** component
 */
export default class VehicleForm extends BaseForm {
  /**
   * changes the status of the Vehicle
   */
  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div className="VehicleForm">
        <Form>
          {/* TODO: ADD localized formatting */}
          <Input
            label={getMessage("vehicles.form.number.heading")}
            placeholder={getMessage("vehicles.form.number.placeholder")}
            name="number"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["number"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <Searchable
            type="text"
            name="vehicleVendor"
            placeholder={getMessage("vehicles.form.vendor.placeholder")}
            valueKey="id"
            nameKey="name"
            searchKey="name"
            responseKey="vehiclevendor"
            label={getMessage("vehicles.form.vendor.heading")}
            searchUrl="/logistics-service/vehicle-vendor"
            transformResponse={(response) => response.data.vehiclevendor}
            createButton={getMessage("vehicles.form.vendor.addButton")}
            required
            {...this.generateStateMappers({
              stateKeys: ["vehicleVendor"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <div className="field-group">
            <Radio
              className="vehicle-type"
              label={getMessage("vehicles.form.type.heading")}
              name="type"
              required
              options={[
                {
                  text: (
                    <div>
                      {getMessage("vehicles.form.type.label.2w")}
                      <img
                        className="radio-label-icon"
                        src={twoWheelerIcon}
                        alt=""
                      />
                    </div>
                  ),
                  value: "2W",
                },
                {
                  text: (
                    <div>
                      {getMessage("vehicles.form.type.label.4w")}
                      <img
                        className="radio-label-icon"
                        src={fourWheelerIcon}
                        alt=""
                      />
                    </div>
                  ),
                  value: "4W",
                },
                {
                  text: (
                    <div>
                      {getMessage("vehicles.form.type.label.drone")}
                      <img
                        className="radio-label-icon"
                        src={droneIcon}
                        alt=""
                      />
                    </div>
                  ),
                  value: "DRONE",
                },
              ]}
              {...this.generateStateMappers({
                stateKeys: ["type"],
              })}
            />
            <Checkbox
              className="availability-status"
              label={getMessage("vehicles.form.status.heading")}
              inlineLabel={getMessage("vehicles.form.status.label.enable")}
              name="status"
              {...this.generateStateMappers({
                stateKeys: ["status"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              value={(this.getState(["status"]) || "ENABLED") === "ENABLED"}
              onChange={(checked) => {
                this.updateState(["status"], checked ? "ENABLED" : "DISABLED");
              }}
            />
          </div>
          <div className={`form-actions`}>
            <SubmitButton>
              {getMessage("vehicles.form.submitText")}
            </SubmitButton>
            <CancelButton>{getMessage("brand.form.cancelText")}</CancelButton>
          </div>
        </Form>
      </div>
    );
  }
}
