const data = {
  "changePassword.heading": "Change Password",
  "changePassword.different.confirmPassword":
    "Confirm password is not same as New password",
  "changePassword.oldPassword.heading": "Old Password",
  "changePassword.oldPassword.placeholder": "Enter Old Password",
  "changePassword.newPassword.heading": "New Password",
  "changePassword.newPassword.placeholder": "Enter New Password",
  "changePassword.confirmPassword.heading": "Confirm Password",
  "changePassword.confirmPassword.placeholder": "Confirm New Password",
  "changePassword.submit.text": "Change Password",
  "changePassword.cancel.text": "Cancel",
  "changePassword.list.heading": "Password Must Contain",
  "changePassword.list.characterLimit": "At least 6 characters",
  "changePassword.list.uppercase": "At least 1 upper case letter (A..Z)",
  "changePassword.list.lowercase": "At least 1 lower case letter (a..z)",
  "changePassword.list.number": "At least 1 number (0..9)",
  "changePassword.save.success.title": "Password successfully changed",
  "changePassword.save.success.closeText": "Okay",
};
export default data;
