import React from "react";
import { BaseForm, Select } from "../../../../components/Form";
import Table, { Header, Row, Cell } from "../../../../components/Table";
import Loader from "../../../../components/Loader";
import { TABLE_ACTIONS } from "../../../../containers/ListingPage";

import API from "../../../../lib/api";
import { getMessage } from "../../../../lib/translator";
import { getPrintableTime } from "../../../../lib/datetime";

// TODO: Add all resolutions translation
const resolutionOptions = (resolutions) => {
  if (!resolutions) {
    return null;
  }
  let options = [];
  resolutions.forEach((resolution) => {
    options.push({
      text: getMessage(`dispute.${resolution.name.toLowerCase()}`),
      value: resolution.name,
    });
    if (resolution.substituteResolutions) {
      options.push({
        text: getMessage(
          `dispute.${resolution.substituteResolutions.toLowerCase()}`
        ),
        value: resolution.substituteResolutions,
      });
    }
  });
  return options;
};

class DisputeForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.showLoader = false;
    this.state.values = [];
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    const api = new API({
      url: `/order-service/order/${this.props.referenceNumber}/dispute`,
    });
    api
      .get()
      .then((response) => {
        let disputes = response.data.dispute;
        let submitButtonshown = false;
        let disputeItems = disputes.map((dispute) => {
          if (dispute.status === "PENDING") {
            submitButtonshown = true;
          }
          return {
            resolution: dispute.resolution.name,
            status: dispute.status === "PENDING" ? "APPROVED" : dispute.status,
            id: dispute.id,
          };
        });
        this.setState({
          disputes,
          showLoader: false,
          submitButtonshown,
          values: {
            disputeItems,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(formData) {
    this.setState({
      submitting: true,
    });
    let values = Object.assign({}, formData);
    let disputesConfig =
      values.disputeItems &&
      values.disputeItems.filter((dispute) => dispute.disputeStatus);
    // let disputes = this.state.disputes.slice()
    disputesConfig.forEach((dispute, index) => {
      if (disputesConfig[index].status === "REJECTED") {
        delete disputesConfig[index].resolution;
      }
      // disputesConfig[index].id = dispute.id
    });
    values.disputeItems = disputesConfig;
    const { referenceNumber } = this.props;
    let deleteRow = true;
    const api = new API({
      url: `/order-service/order/${this.props.referenceNumber}/dispute`,
    });
    api
      .put(values)
      .then(
        (response) => {
          this.props.onAction(
            TABLE_ACTIONS.REFRESH,
            { referenceNumber },
            null,
            deleteRow
          );
        },
        (error) => {
          this.setState({
            formError: error.message,
            submitting: false,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { disputes, submitButtonshown, submitting } = this.state;
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    return this.state.showLoader ? (
      <Loader />
    ) : (
      <Form>
        <Table>
          <Header>
            <Cell>{getMessage("dispute.orderItem")}</Cell>
            <Cell>{getMessage("dispute.filingTime")}</Cell>
            <Cell>{getMessage("dispute.disputedQuantity")}</Cell>
            <Cell>{getMessage("dispute.reason")}</Cell>
            <Cell>{getMessage("dispute.status")}</Cell>
            <Cell>{getMessage("dispute.approveReject")}</Cell>
            <Cell>{getMessage("dispute.actions")}</Cell>
          </Header>
          {disputes &&
            disputes.map((dispute, index) => (
              <Row key={`${dispute.id}`}>
                <Cell>{dispute.orderItem.product.name}</Cell>
                <Cell>
                  <div>
                    {dispute.createdAt
                      ? getPrintableTime(dispute.createdAt).split(", ")[0]
                      : null}
                  </div>
                  <small className="text-muted">
                    {dispute.createdAt &&
                      getPrintableTime(dispute.createdAt).split(", ")[1]}
                  </small>
                </Cell>
                <Cell>{dispute.quantity}</Cell>
                <Cell>{dispute.reason.name}</Cell>
                <Cell>{dispute.status}</Cell>
                <Cell>
                  {dispute.status === "PENDING" ? (
                    <Select
                      name={`approve-reject-${index}`}
                      placeholder={getMessage("dispute.choose")}
                      required
                      {...this.generateStateMappers({
                        stateKeys: ["disputeItems", index, "disputeStatus"],
                        loseEmphasisOnFill: true,
                      })}
                      options={[
                        {
                          text: getMessage("dispute.approve"),
                          value: "APPROVED",
                        },
                        {
                          text: getMessage("dispute.reject"),
                          value: "REJECTED",
                        },
                      ]}
                    />
                  ) : (
                    dispute.status
                  )}
                </Cell>
                <Cell>
                  {dispute.status === "PENDING" ? (
                    <Select
                      name={`action-${index}`}
                      placeholder={getMessage("dispute.actions")}
                      {...this.generateStateMappers({
                        stateKeys: ["disputeItems", index, "resolution"],
                      })}
                      options={resolutionOptions(dispute.reason.resolutions)}
                    />
                  ) : (
                    getMessage(
                      `dispute.${dispute.resolution.name.toLowerCase()}`
                    )
                  )}
                </Cell>
              </Row>
            ))}
        </Table>
        {submitButtonshown && (
          <div className="form-action">
            {
              <SubmitButton disabled={submitting}>
                {getMessage("dispute.save")}
              </SubmitButton>
            }
            <button
              type="button"
              className="button"
              onClick={this.props.closeDisputePopup}
            >
              {getMessage("dispute.cancel")}
            </button>
          </div>
        )}
      </Form>
    );
  }
}

export default DisputeForm;
