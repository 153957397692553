import React from "react";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import API from "../../../../lib/api";
import Table, { Cell } from "../../../../components/Table";
import Headers from "../../../../components/Table/Header";
import Row from "../../../../components/Table/Row";

import "./style.css";
import moment from "moment";
import ArrowRight from "./Arrow-right.svg";
import ArrowLeft from "./Arrow-left.svg";
import PickupIcon from "./pickup.svg";
import VehicleIcon from "../../../logistics/TripDetails/vehicle-icon.svg";
import {
  getPrintableTime,
  getStandradDateTime,
  utcToLocalTime,
} from "../../../../lib/datetime";
import { getAsapDuration, sortSlots } from "../../../../lib/commonlyused";
import { cutoffTimeOptionsGenerator } from "../Form";
import { Select, SingleDatePicker } from "../../../../components/Form";
import EditIcon from "../../Orders/Details/edit-icon.svg";
import { get } from "../../../../lib/storage";
import { getMessage } from "../../../../lib/translator";
import { Dialog } from "../../../../components/Popup";
import Loader from "../../../../components/Loader";
import { Link } from "react-router-dom";
import checkHttpResponseCode from "../../../../utils/isApiCallSuccessfull";

function generateTabularDataSkeleton(slotsAndZones) {
  let skeleton = {};
  slotsAndZones &&
    slotsAndZones.totalSlots &&
    slotsAndZones.totalSlots.map((slot, i) => {
      skeleton[slot] = [];
      slotsAndZones &&
        slotsAndZones.totalZones &&
        slotsAndZones.totalZones.map((zone, j) => {
          let ob = JSON.parse(
            JSON.stringify({
              delivery: { capacity: null, orderCount: null },
              pickup: { capacity: null, orderCount: null },
              zone: JSON.parse(JSON.stringify(zone)),
            })
          );
          skeleton[slot].push(ob);
          return null;
        });
      return null;
    });
  return skeleton;
}

function generateTabularData(tableSkeleton, inComingData) {
  inComingData.forEach((item, index) => {
    let slot = item.startTime
      ? item.startTime + "-" + item.endTime
      : item.endTime;
    if (item.orderType === "DELIVERY") {
      tableSkeleton[slot].forEach((eachZone, i) => {
        eachZone.delivery.slotId = item.id;
        eachZone.delivery.capacity = item.zones[i].capacity;
        eachZone.delivery.orderCount = item.zones[i].orderCount;
      });
    } else {
      tableSkeleton[slot].forEach((eachZone, i) => {
        eachZone.pickup.slotId = item.id;
        eachZone.pickup.capacity = item.zones[i].capacity;
        eachZone.pickup.orderCount = item.zones[i].orderCount;
      });
    }
  });
  return tableSkeleton;
}
function getSortedSlots(slots) {
  let formattedSlots = slots.map((item) => {
    let data = item.split("-");
    let ob =
      data.length > 1
        ? { type: "STANDARD", endTime: data[1], startTime: data[0] }
        : { type: "ASAP", endTime: data[0] };
    return ob;
  });

  let sortedFormattedSlots = sortSlots(formattedSlots);
  return sortedFormattedSlots
    .filter((item) => item.type !== "ASAP")
    .map((item) => {
      let slot = item.startTime
        ? item.startTime + "-" + item.endTime
        : item.endTime;
      return slot;
    });
}

function slotsAndZones(slots) {
  let uniqueSlots = {};
  let slotMapToOrderType = {};
  slots.map((slot) => {
    uniqueSlots[
      slot.startTime ? slot.startTime + "-" + slot.endTime : slot.endTime
    ] = slot;
    if (
      slotMapToOrderType[
        slot.startTime ? slot.startTime + "-" + slot.endTime : slot.endTime
      ]
    ) {
      slotMapToOrderType[
        slot.startTime ? slot.startTime + "-" + slot.endTime : slot.endTime
      ] = "BOTH";
    } else {
      slotMapToOrderType[
        slot.startTime ? slot.startTime + "-" + slot.endTime : slot.endTime
      ] = slot.orderType;
    }
    return null;
  });
  return {
    totalSlots: (uniqueSlots && Object.keys(uniqueSlots)) || [],
    totalZones:
      (uniqueSlots &&
        Object.values(uniqueSlots).length > 0 &&
        Object.values(uniqueSlots)[0] &&
        Object.values(uniqueSlots)[0].zones) ||
      [],
    slotMapToOrderType,
  };
}

function isEquals(data, tableIndex) {
  if (
    data.deliveryTotal !== null &&
    Number(data.deliveryTotal) !== Number(tableIndex.deliveryTotal)
  ) {
    return false;
  }

  if (
    data.pickUpTotal !== null &&
    Number(data.pickUpTotal) !== Number(tableIndex.pickUpTotal)
  ) {
    return false;
  }
  return true;
}

function calcPercent(total, curr) {
  if (total === 0) {
    return 0;
  }
  return (curr / total) * 100;
}

class ZoneProgressBar extends React.Component {
  render() {
    let width = calcPercent(this.props.total, this.props.curr);
    return (
      <div className="zone-progress-bar">
        <div className="progress-line">
          <div
            className="completed-progress-line"
            style={{ width: `${width}%` }}
          />
        </div>
      </div>
    );
  }
}

class Today extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = null;
    this.table = {};

    this.state = {
      edit: [],
      sortedSlots: [],
      currentStore: get("store"),
      selectedOption: "00:00:00",
      errorMessage: null,
      showErrorDialog: false,
      date:
        (this.props.location && this.props.location.date) ||
        moment().format("YYYY-MM-DD"),
      loading: true,
    };

    this.includes = this.includes.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.arrowClickRight = this.arrowClickRight.bind(this);
    this.arrowClickLeft = this.arrowClickLeft.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.disableEdit = this.disableEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onTodayButtonClick = this.onTodayButtonClick.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if (this.tableRef && !this.tableRef.contains(event.target)) {
      this.setState({
        edit: [],
      });
    }
  }

  onStoreChange() {
    let currentStore = get("store");
    this.setState({
      currentStore,
      loading: true,
    });
  }

  onTodayButtonClick() {
    this.setState((prevState) => {
      return {
        loading: prevState.date !== moment().format("YYYY-MM-DD"),
        date: moment().format("YYYY-MM-DD"),
      };
    });
  }

  onDateChange(date) {
    this.setState((prevState) => {
      return {
        date,
        loading: prevState.date !== date,
      };
    });
  }
  closePopup() {
    this.setState({
      showErrorDialog: false,
      errorMessage: null,
    });
  }

  handleOnKeyDown(e, index, i) {
    if (e.key === "Escape") {
      this.disableEdit(index, i);
    }
  }

  handleChange(val) {
    this.setState({
      loading: true,
      selectedOption: val,
    });
  }

  includes(x, y) {
    return this.state.edit.find((item) => item.x === x && item.y === y);
  }

  handleEdit(index, i, pickupCapacity, deliveryCapacity) {
    let newob = { x: index, y: i };
    let uniqueState = index + "," + i;
    this.setState((prevState) => {
      return {
        edit: prevState.edit.concat(newob),
        indices: {
          ...prevState.indices,
          [uniqueState]: {
            pickUpTotal: pickupCapacity ? Number(pickupCapacity) : null,
            deliveryTotal: deliveryCapacity ? Number(deliveryCapacity) : null,
          },
        },
      };
    });
  }

  onChange(e, type, index, i) {
    let val = e.target.value;
    if (type === "delivery") {
      this.setState((prevState) => {
        return {
          indices: {
            ...prevState.indices,
            [index + "," + i]: {
              ...prevState.indices[index + "," + i],
              deliveryTotal: val,
            },
          },
        };
      });
    }
    if (type === "pickup") {
      this.setState((prevState) => {
        return {
          indices: {
            ...prevState.indices,
            [index + "," + i]: {
              ...prevState.indices[index + "," + i],
              pickUpTotal: val,
            },
          },
        };
      });
    }
  }

  disableEdit(index, i) {
    let newEditState = this.state.edit.filter(
      (item) => item.x !== index || item.y !== i
    );
    this.setState({
      edit: newEditState,
    });
  }

  arrowClickLeft() {
    if (!this.once) {
      this.timer = setInterval(() => {
        if (this.tableRef) {
          this.once = true;
          this.tableRef.scrollLeft -= 5;
        }
      }, 5);
    }

    setTimeout(() => {
      this.once = undefined;
      clearInterval(this.timer);
    }, 240);
  }
  arrowClickRight() {
    if (!this.once) {
      this.timer = setInterval(() => {
        if (this.tableRef) {
          this.once = true;
          this.tableRef.scrollLeft += 5;
        }
      }, 5);
    }

    setTimeout(() => {
      this.once = undefined;
      clearInterval(this.timer);
    }, 240);
  }

  onSubmit(e, index, i, slot, zoneId) {
    e.preventDefault();
    if (this.state.selectedOption) {
      Object.keys(this.state.indices).map(async (key) => {
        if (!isEquals(this.state.indices[key], this.table[key])) {
          let deliveryOb = JSON.parse(JSON.stringify(this.state.indices[key]));
          let pickupOb = JSON.parse(JSON.stringify(this.state.indices[key]));
          this.putApi = new API({ url: `/logistics-service/zone-capacity` });
          let promises = [];
          if (
            slot.delivery.slotId &&
            deliveryOb.deliveryTotal &&
            Number(deliveryOb.deliveryTotal) !==
              Number(this.table[key].deliveryTotal)
          ) {
            deliveryOb.slotId = Number(slot.delivery.slotId);
            let capacity = deliveryOb.deliveryTotal;
            deliveryOb.capacity = capacity;
            deliveryOb.orderType = "DELIVERY";
            deliveryOb.zoneId = Number(zoneId);
            deliveryOb.date = `${this.state.date}T00:00:00+00:00`;
            deliveryOb.storeId = Number(this.state.currentStore);
            deliveryOb.cutOffTime = this.state.selectedOption;
            delete deliveryOb.deliveryTotal;
            delete deliveryOb.pickUpTotal;
            promises.push(this.putApi.put(deliveryOb));
          }

          if (
            slot.pickup.slotId &&
            pickupOb.pickUpTotal &&
            Number(pickupOb.pickUpTotal) !== Number(this.table[key].pickUpTotal)
          ) {
            pickupOb.slotId = Number(slot.pickup.slotId);
            let capacity = pickupOb.pickUpTotal;
            pickupOb.capacity = capacity;
            pickupOb.orderType = "PICKUP";
            pickupOb.date = `${this.state.date}T00:00:00+00:00`;
            pickupOb.storeId = Number(this.state.currentStore);
            pickupOb.cutOffTime = this.state.selectedOption;
            delete pickupOb.deliveryTotal;
            delete pickupOb.pickUpTotal;
            promises.push(this.putApi.put(pickupOb));
          }

          Promise.all(promises)
            .then((res) => {
              let newZoneCapacity = JSON.parse(JSON.stringify(this.state.data));
              res.forEach((response) => {
                if (
                  checkHttpResponseCode(response.code) &&
                  response.status === "SUCCESS"
                ) {
                  let zoneCapacity =
                    (response.data && response.data.capacityPlanning) || {};
                  let slot = zoneCapacity.slot.startTime
                    ? zoneCapacity.slot.startTime +
                      "-" +
                      zoneCapacity.slot.endTime
                    : zoneCapacity.slot.endTime;
                  let type =
                    zoneCapacity.orderType || zoneCapacity.slot.orderType;
                  let found = newZoneCapacity[slot].find(
                    (item) => item.zone.zoneId === zoneId
                  );
                  if (type === "PICKUP") {
                    newZoneCapacity[slot] = newZoneCapacity[slot].map(
                      (item) => {
                        item.pickup.capacity = zoneCapacity.capacity;
                        return item;
                      }
                    );
                  } else {
                    if (found) {
                      found.delivery.capacity = zoneCapacity.capacity;
                    }
                  }
                }
              });
              this.setState({
                data: newZoneCapacity,
              });
            })
            .catch((error) => {
              console.error(error);
            });
        }
        return null;
      });
    } else {
      this.setState({
        errorMessage: "CutOff Time is not selected.",
        showErrorDialog: true,
      });
    }

    this.disableEdit(index, i);
    this.setState({
      indices: undefined,
    });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.api = new API({ url: "/logistics-service/slot-capacity" });
    const params = {
      date: this.state.date
        ? this.state.date + "/" + this.state.date
        : moment().utc().format("YYYY-MM-DD") +
          "/" +
          moment().utc().format("YYYY-MM-DD"),
      storeId: Number(this.state.currentStore || get("store")),
      cutOffTime: this.state.selectedOption,
    };
    this.api
      .get(params)
      .then((res) => {
        let filteredData = res.data.slotCapacity;
        let filteredTabularData =
          (filteredData &&
            filteredData.length > 0 &&
            filteredData[0].capacity) ||
          [];
        let tableProperties = slotsAndZones(filteredTabularData);
        let tabularSkeleton = generateTabularDataSkeleton(tableProperties);
        let data = generateTabularData(tabularSkeleton, filteredTabularData);
        let sortedSlots = getSortedSlots(tableProperties.totalSlots);
        this.setState({
          loading: false,
          data,
          tableProperties,
          sortedSlots,
        });
      })
      .catch((err) => {
        console.error(err);
        if (err.code === 401 || err.code === 403) {
          throw err;
        }
        this.setState({
          loading: false,
          sortedSlots: [],
        });
      });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.api && this.api.cancel();
    this.putApi && this.putApi.cancel();
  }

  componentDidUpdate(prevProps, prevState) {
    let prevDate = prevState.date;
    let currentDate = this.state.date;

    if (
      this.state.currentStore !== prevState.currentStore ||
      prevState.selectedOption !== this.state.selectedOption ||
      (currentDate && currentDate !== prevDate)
    ) {
      this.api = new API({ url: "/logistics-service/slot-capacity" });
      const params = {
        date: this.state.date
          ? this.state.date + "/" + this.state.date
          : moment().format("YYYY-MM-DD") + "/" + moment().format("YYYY-MM-DD"),
        storeId: Number(this.state.currentStore),
        cutOffTime: this.state.selectedOption,
      };
      this.api
        .get(params)
        .then((res) => {
          if (checkHttpResponseCode(res.code) && res.status === "SUCCESS") {
            let filteredData = res.data.slotCapacity;
            let filteredTabularData =
              (filteredData &&
                filteredData.length > 0 &&
                filteredData[0].capacity) ||
              [];
            let tableProperties = slotsAndZones(filteredTabularData);
            let tabularSkeleton = generateTabularDataSkeleton(tableProperties);
            let data = generateTabularData(
              tabularSkeleton,
              filteredTabularData
            );
            let sortedSlots = getSortedSlots(tableProperties.totalSlots);
            this.setState({
              loading: false,
              data,
              tableProperties,
              sortedSlots,
            });
          } else {
            this.setState({
              loading: false,
              sortSlots: [],
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.code === 400) {
            this.setState({
              loading: false,
              sortedSlots: [],
            });
            return err;
          }
          throw err;
        });
    }
  }

  render() {
    let content = <Loader />;
    if (this.state.loading) {
      content = <Loader />;
    } else {
      if (this.state.sortedSlots.length > 0) {
        content = (
          <div>
            <div className="table-wrapper">
              <div className="outer-table">
                <div>
                  <div className="slot-table">
                    <div className="slot-table-heading">
                      {getMessage("cp.today.table.slot")}
                    </div>
                    {this.state.sortedSlots.map((data, index) => {
                      let slot = data.split("-");
                      let slotHeading =
                        slot.length > 1
                          ? getPrintableTime(
                              getStandradDateTime(
                                utcToLocalTime,
                                `${new Date().toISOString().split("T")[0]} ${
                                  slot[0]
                                }`
                              )
                            ).split(",")[1] +
                            " - " +
                            getPrintableTime(
                              getStandradDateTime(
                                utcToLocalTime,
                                `${new Date().toISOString().split("T")[0]} ${
                                  slot[1]
                                }`
                              )
                            ).split(",")[1]
                          : getAsapDuration("12:00 AM", slot[0]);
                      let slotMapToOrderType =
                        this.state.tableProperties.slotMapToOrderType;
                      let orderType = slotMapToOrderType[data];
                      return (
                        <Cell key={index}>
                          <span className="slot-duration">{slotHeading}</span>
                          {orderType === "BOTH" && (
                            <span className="slot-icons">
                              <span className="pickup-icon">
                                <img src={PickupIcon} alt="PickupIcon" />
                              </span>
                              <span className="delivery-icon">
                                <img src={VehicleIcon} alt="VehicleIcon" />
                              </span>
                            </span>
                          )}
                          {orderType === "DELIVERY" && (
                            <span className="slot-icons DELIVERY">
                              <span className="delivery-icon">
                                <img src={VehicleIcon} alt="VehicleIcon" />
                              </span>
                            </span>
                          )}
                          {orderType === "PICKUP" && (
                            <span className="slot-icons PICKUP">
                              <span className="pickup-icon">
                                <img src={PickupIcon} alt="PickupIcon" />
                              </span>
                            </span>
                          )}
                        </Cell>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <Table
                    tableRef={(el) => {
                      this.tableRef = el;
                    }}
                  >
                    <Headers
                      items={this.state.tableProperties.totalZones.map(
                        (zone) => zone.name
                      )}
                    />
                    {this.state.sortedSlots.map((slot, index) => {
                      let slotMapToOrderType =
                        this.state.tableProperties.slotMapToOrderType;
                      let orderType = slotMapToOrderType[slot];
                      let zoneCapacity = this.state.data[slot];
                      return zoneCapacity.length > 0 ? (
                        <Row key={index}>
                          {zoneCapacity &&
                            zoneCapacity.map((item, i) => {
                              this.table[index + "," + i] = {
                                deliveryTotal: item.delivery.capacity || null,
                                pickUpTotal: item.pickup.capacity || null,
                              };
                              if (this.includes(index, i)) {
                                return (
                                  <Cell key={i}>
                                    <form
                                      onSubmit={(e) =>
                                        this.onSubmit(
                                          e,
                                          index,
                                          i,
                                          {
                                            delivery: {
                                              slotId: item.delivery.slotId,
                                            },
                                            pickup: {
                                              slotId: item.pickup.slotId,
                                            },
                                          },
                                          item.zone.zoneId
                                        )
                                      }
                                    >
                                      {orderType === "BOTH" && (
                                        <div className="both">
                                          <div className="pickup edit">
                                            <span className="pickup-count">
                                              {item.pickup.orderCount || 0}
                                            </span>
                                            <ZoneProgressBar
                                              curr={item.pickup.orderCount || 0}
                                              total={item.pickup.capacity || 0}
                                            />
                                            {item.pickup.slotId ? (
                                              <input
                                                name="pickUpTotal"
                                                type="number"
                                                min="0"
                                                value={
                                                  this.state.indices[
                                                    index + "," + i
                                                  ].pickUpTotal || ""
                                                }
                                                onChange={(e) =>
                                                  this.onChange(
                                                    e,
                                                    "pickup",
                                                    index,
                                                    i
                                                  )
                                                }
                                                onKeyDown={(e) =>
                                                  this.handleOnKeyDown(
                                                    e,
                                                    index,
                                                    i
                                                  )
                                                }
                                              />
                                            ) : (
                                              item.pickup.capacity || 0
                                            )}
                                          </div>
                                          <div className="delivery edit">
                                            <span className="delivery-count">
                                              {item.delivery.orderCount || 0}
                                            </span>
                                            <ZoneProgressBar
                                              curr={
                                                item.delivery.orderCount || 0
                                              }
                                              total={
                                                item.delivery.capacity || 0
                                              }
                                            />
                                            {item.delivery.slotId ? (
                                              <input
                                                name="deliveryTotal"
                                                type="number"
                                                value={
                                                  this.state.indices[
                                                    index + "," + i
                                                  ].deliveryTotal || ""
                                                }
                                                onChange={(e) =>
                                                  this.onChange(
                                                    e,
                                                    "delivery",
                                                    index,
                                                    i
                                                  )
                                                }
                                                onKeyDown={(e) =>
                                                  this.handleOnKeyDown(
                                                    e,
                                                    index,
                                                    i
                                                  )
                                                }
                                              />
                                            ) : (
                                              item.delivery.capacity || 0
                                            )}
                                            <input type="submit" />
                                          </div>
                                        </div>
                                      )}
                                      {orderType === "PICKUP" && (
                                        <div className="pickup edit">
                                          <span className="pickup-count">
                                            {item.pickup.orderCount || 0}
                                          </span>
                                          <ZoneProgressBar
                                            curr={item.pickup.orderCount || 0}
                                            total={item.pickup.capacity || 0}
                                          />
                                          {item.pickup.slotId ? (
                                            <input
                                              name="pickUpTotal"
                                              type="number"
                                              min="0"
                                              value={
                                                this.state.indices[
                                                  index + "," + i
                                                ].pickUpTotal || ""
                                              }
                                              onChange={(e) =>
                                                this.onChange(
                                                  e,
                                                  "pickup",
                                                  index,
                                                  i
                                                )
                                              }
                                              onKeyDown={(e) =>
                                                this.handleOnKeyDown(
                                                  e,
                                                  index,
                                                  i
                                                )
                                              }
                                            />
                                          ) : (
                                            item.pickup.capacity || 0
                                          )}
                                        </div>
                                      )}
                                      {orderType === "DELIVERY" && (
                                        <div className="delivery edit">
                                          <span className="delivery-count">
                                            {item.delivery.orderCount || 0}
                                          </span>
                                          <ZoneProgressBar
                                            curr={item.delivery.orderCount || 0}
                                            total={item.delivery.capacity || 0}
                                          />
                                          {item.delivery.slotId ? (
                                            <input
                                              name="deliveryTotal"
                                              type="number"
                                              value={
                                                this.state.indices[
                                                  index + "," + i
                                                ].deliveryTotal || ""
                                              }
                                              onChange={(e) =>
                                                this.onChange(
                                                  e,
                                                  "delivery",
                                                  index,
                                                  i
                                                )
                                              }
                                              onKeyDown={(e) =>
                                                this.handleOnKeyDown(
                                                  e,
                                                  index,
                                                  i
                                                )
                                              }
                                            />
                                          ) : (
                                            item.delivery.capacity || 0
                                          )}
                                          <input type="submit" />
                                        </div>
                                      )}
                                    </form>
                                  </Cell>
                                );
                              }
                              return (
                                <Cell key={i}>
                                  {orderType === "BOTH" && (
                                    <div className="both">
                                      <div className="pickup">
                                        <span className="pickup-count">
                                          {item.pickup.orderCount || 0}
                                        </span>
                                        <ZoneProgressBar
                                          curr={item.pickup.orderCount || 0}
                                          total={item.pickup.capacity || 0}
                                        />
                                        <span className="pickup-capacity">
                                          {item.pickup.capacity || 0}
                                        </span>
                                      </div>
                                      <div className="delivery">
                                        <span className="delivery-count">
                                          {item.delivery.orderCount || 0}
                                        </span>
                                        <ZoneProgressBar
                                          curr={item.delivery.orderCount || 0}
                                          total={item.delivery.capacity || 0}
                                        />
                                        <span className="delivery-capacity">
                                          {item.delivery.capacity || 0}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {orderType === "DELIVERY" && (
                                    <div>
                                      <div className="delivery">
                                        <span className="delivery-count">
                                          {item.delivery.orderCount || 0}
                                        </span>
                                        <ZoneProgressBar
                                          curr={item.delivery.orderCount || 0}
                                          total={item.delivery.capacity || 0}
                                        />
                                        <span className="delivery-capacity">
                                          {item.delivery.capacity || 0}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {orderType === "PICKUP" && (
                                    <div>
                                      <div className="pickup">
                                        <span className="pickup-count">
                                          {item.pickup.orderCount || 0}
                                        </span>
                                        <ZoneProgressBar
                                          curr={item.pickup.orderCount || 0}
                                          total={item.pickup.capacity || 0}
                                        />
                                        <span className="pickup-capacity">
                                          {item.pickup.capacity || 0}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {this.state.edit.length === 0 ? (
                                    <img
                                      src={EditIcon}
                                      alt="Edit-icon"
                                      onClick={() =>
                                        this.handleEdit(
                                          index,
                                          i,
                                          item.pickup.capacity,
                                          item.delivery.capacity
                                        )
                                      }
                                    />
                                  ) : null}
                                </Cell>
                              );
                            })}
                        </Row>
                      ) : (
                        <Row>
                          {" "}
                          <Cell>No Zones to display</Cell>{" "}
                        </Row>
                      );
                    })}
                  </Table>
                  <span className="arrow-right" onClick={this.arrowClickRight}>
                    <img src={ArrowRight} alt="arrow-right" />
                  </span>
                  <span className="arrow-left" onClick={this.arrowClickLeft}>
                    <img src={ArrowLeft} alt="arrow-right" />
                  </span>
                </div>
              </div>
            </div>
            <div className="bottom-info">
              <p>
                <span>
                  <img src={VehicleIcon} alt="VehicleIcon" />
                  {getMessage("cp.today.delivery")}
                </span>
                <span>
                  <img src={PickupIcon} alt="VehicleIcon" />
                  {getMessage("cp.today.pickup")}
                </span>
              </p>
            </div>
          </div>
        );
      } else {
        content = <p>No Data Available</p>;
      }
    }
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        className="today"
        storeDependent
        onChange={this.onStoreChange}
      >
        <header className="today-capacity-plan">
          <span className="today-heading">{getMessage("cp.today")}</span>
          <span className="header-icons">
            <SingleDatePicker
              allowAllDates
              displayFormat="YYYY-MM-DD"
              onChange={this.onDateChange}
              value={this.state.date}
            />
            <span
              onClick={this.onTodayButtonClick}
              className={`today-button ${
                this.state.date === moment().format("YYYY-MM-DD")
                  ? "green"
                  : "black"
              }`}
            >
              {getMessage("cp.today.button")}
            </span>
            <Link to="/operations/capacity-planning">
              <span className="button primary">{getMessage("cp.title")}</span>
            </Link>
          </span>
        </header>
        <div className="cut-off-time">
          <Select
            className="cut-off-time-select"
            name="cut-off-time-select"
            placeholder={getMessage("cp.today.cutofftime")}
            options={cutoffTimeOptionsGenerator()}
            value={this.state.selectedOption}
            onChange={this.handleChange}
          />
        </div>
        {content}
        {this.state.showErrorDialog && (
          <Dialog
            show={this.state.showErrorDialog}
            information={this.state.errorMessage}
            close={this.closePopup}
            closeText={getMessage("vehiclePlanner.error.dialog.okay")}
          />
        )}
      </AuthenticatedPage>
    );
  }
}

export default Today;
