import React, { Component } from "react";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import { Select } from "../../../components/Form";
import { Popup, Dialog } from "../../../components/Popup";
import { getStores } from "../../../lib/auth";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";
import PincodeFormComponent from "./FormComponents/Pincode";
import RadialFormComponent from "./FormComponents/Radial";
import PolygonFormComponent from "./FormComponents/Polygon";
import ApartmentComponent from "./FormComponents/Apartment";
import CommonMapComponent from "./CommonMapComponent";
import AddDeliveryArea from "./AddDeliveryArea";
import KMLUpload from "./KMLUpload";

import "./style.css";

const distinctColors = [
  { color: "default", value: "#7ac8ed" },
  { color: "blue", value: "#0000ff" },
  { color: "maroon", value: "#800000" },
  { color: "darkolivegreen", value: "#556b2f" },
  { color: "darkblue", value: "#00008b" },
  { color: "darkmagenta", value: "#8b008b" },
  { color: "darkvoilet", value: "#eca5b7" },
  { color: "darkorchid", value: "#9932cc" },
  { color: "darkcyan", value: "#008b8b" },
  { color: "darkgreen", value: "#006400" },
  { color: "darkorange", value: "#ff8c00" },
  { color: "seagreen", value: "#55CFAF" },
  { color: "darkmagenta", value: "#8b008b" },
  { color: "darkvoilet", value: "#eca5b7" },
];

class MapControls extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.storeSelectReadOnly = false;

    this.handleOpenClose = this.handleOpenClose.bind(this);
  }

  handleOpenClose(section) {
    this.setState((prevState) => {
      if (prevState.activeSection === section) {
        return {
          ...prevState,
          activeSection: "",
        };
      } else {
        return {
          ...prevState,
          activeSection: section,
        };
      }
    });
  }

  render() {
    const {
      pincodeArea,
      radialArea,
      polygonalArea,
      apartments,
      distinctColors,
      setNewLocation,
      fetchAndUpdate,
      storeId,
      toggleStoreError,
      tempDeliveryAreaData,
      changeEditMode,
      changeMapEditMode,
      setTempLocation,
      clearTempData,
      setShowDrawOption,
      editing,
    } = this.props;

    const { activeSection } = this.state;

    return (
      <React.Fragment>
        <PincodeFormComponent
          pincodeArea={pincodeArea}
          active={activeSection === "pincode"}
          handleOpenClose={this.handleOpenClose}
          fetchAndUpdate={fetchAndUpdate}
          storeId={storeId}
          toggleStoreError={toggleStoreError}
          setNewLocation={setNewLocation}
          changeEditMode={changeEditMode}
          editing={editing}
        />
        <RadialFormComponent
          polygonalArea={radialArea}
          active={activeSection === "radial"}
          handleOpenClose={this.handleOpenClose}
          distinctColors={distinctColors.slice(5, 10)}
          fetchAndUpdate={fetchAndUpdate}
          storeId={storeId}
          toggleStoreError={toggleStoreError}
          setNewLocation={setNewLocation}
          changeEditMode={changeEditMode}
          editing={editing}
        />
        <PolygonFormComponent
          polygonalArea={polygonalArea}
          active={activeSection === "polygon"}
          handleOpenClose={this.handleOpenClose}
          distinctColors={distinctColors.slice(0, 5)}
          fetchAndUpdate={fetchAndUpdate}
          storeId={storeId}
          toggleStoreError={toggleStoreError}
          newlyAddedPolygon={tempDeliveryAreaData}
          changeEditMode={changeEditMode}
          changeMapEditMode={changeMapEditMode}
          setTempLocation={setTempLocation}
          clearTempData={clearTempData}
          setShowDrawOption={setShowDrawOption}
          editing={editing}
        />
        <ApartmentComponent
          storeId={this.props.storeId}
          apartments={apartments}
          distinctColors={distinctColors.slice(10)}
          handleOpenClose={this.handleOpenClose}
          active={activeSection === "apartment"}
          setNewLocation={setNewLocation}
          toggleStoreError={toggleStoreError}
          fetchAndUpdate={fetchAndUpdate}
          changeEditMode={changeEditMode}
          editing={editing}
        />
      </React.Fragment>
    );
  }
}

class InterfaceContainer extends Component {
  constructor(props) {
    super(props);
    this.setStore = this.setStore.bind(this);
    this.setNewLocation = this.setNewLocation.bind(this);
    this.setNewLocations = this.setNewLocations.bind(this);
    this.setNewEditedLocations = this.setNewEditedLocations.bind(this);
    this.fetchDeliveryAreaData = this.fetchDeliveryAreaData.bind(this);
    this.changeEditMode = this.changeEditMode.bind(this);
    this.changeMapEditMode = this.changeMapEditMode.bind(this);
    this.clearTempData = this.clearTempData.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.uploadSuccessCallback = this.uploadSuccessCallback.bind(this);
    this.toggleStoreError = this.toggleStoreError.bind(this);
    this.setShowDrawOption = this.setShowDrawOption.bind(this);

    this.state = {
      deliveryArea: [],
      tempDeliveryArea: {},
      storeId: null,
      values: null,
      editing: false,
      selectedLocation: [],
      showError: false,
      apiError: null,
      pincodeEditId: null,
      radialEditId: null,
      kmlUploadPopupShown: false,
      showSelectStoreDialog: false,
    };
  }
  showModal() {
    if (!this.state.storeId) {
      this.setState({ showSelectStoreDialog: true });
      return;
    }
    this.setState({
      kmlUploadPopupShown: true,
    });
  }
  hideModal() {
    this.setState({
      kmlUploadPopupShown: false,
    });
  }

  postLocationsAfterKML = (tempDeliveryArea) => {
    const payload = {
      configType: tempDeliveryArea.configType,
      name: tempDeliveryArea.name,
      locations: tempDeliveryArea.area?.locations,
      storeId: this.state.storeId,
    };

    const deliveryAreaApi = new API({
      url: "/logistics-service/delivery-area",
    });
    deliveryAreaApi
      .post(payload)
      .then(() => {
        this.fetchDeliveryAreaData(payload.storeId);
        this.setState({
          tempDeliveryArea,
          deliveryArea: [tempDeliveryArea],
          showSuccessDialog: true,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          showErrorDialog: true,
          kmlUploadError: error.message,
        });
      });
  };

  setTempDeliveryArea = (locations = [], name, id) => {
    let tempDeliveryArea = {
      configType: "POLYGON",
      name,
      id,
      area: {
        locations,
      },
    };
    this.postLocationsAfterKML(tempDeliveryArea);
  };

  uploadSuccessCallback(response) {
    this.hideModal();
    const deliveryArea = response.data.deliveryAreas?.[0] || {};
    this.setTempDeliveryArea(deliveryArea?.locations, deliveryArea?.name);
  }
  setNewLocation(selectedLocation) {
    this.setState({
      selectedLocation,
    });
  }

  setStore(id) {
    let storeId = id === "ALL" ? null : id;
    this.setState({ storeId });
    if (!this.state.editing) {
      this.fetchDeliveryAreaData(storeId);
    }
  }
  changeMapEditMode() {
    const mapEditing = !this.state.mapEditing;
    this.setState({ mapEditing });
  }
  changeEditMode() {
    const editing = !this.state.editing;
    this.setState({ editing });
  }
  clearTempData() {
    this.setState({ tempDeliveryArea: {} });
  }

  setNewLocations({ locations, name, id }) {
    let tempDeliveryArea = {
      configType: "POLYGON",
      name,
      id,
      area: {
        locations: locations?.map(({ lat, lng }) => ({
          latitude: lat,
          longitude: lng,
        })),
      },
    };
    this.setState({
      tempDeliveryArea,
    });
  }

  setNewEditedLocations(params) {
    let { tempDeliveryArea } = this.state;
    this.setState({
      tempDeliveryArea: {
        ...tempDeliveryArea,
        area: {
          ...tempDeliveryArea.area,
          locations: params.locations?.map(({ lat, lng }) => ({
            latitude: lat,
            longitude: lng,
          })),
        },
      },
    });
  }

  fetchDeliveryAreaData(storeId) {
    let api = new API({ url: "/logistics-service/delivery-area" });
    let params = storeId
      ? { storeId, paginated: "false" }
      : { paginated: "false" };
    api
      .get(params)
      .then((response) => {
        let deliveryArea = response.data.deliveryarea || [];
        this.setState({ deliveryArea });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  toggleStoreError() {
    let { showStoreError } = this.state;
    this.setState({ showStoreError: !showStoreError });
  }
  setShowDrawOption(showDrawOption) {
    this.setState({ showDrawOption });
  }
  componentDidMount() {
    this.fetchDeliveryAreaData();
  }

  closeDialogs = () => {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    });
  };

  render() {
    const {
      deliveryArea,
      editing,
      mapEditing,
      storeId,
      showStoreError,
      tempDeliveryArea,
      showDrawOption,
    } = this.state;
    const allStores =
      getStores()?.filter((store) => store.hasDeliveryHub) || [];
    const storeOptions = [
      { text: "All stores", value: "ALL" },
      ...allStores.map((store) => ({ text: store.name, value: store.id })),
    ];
    let pincodeArea = deliveryArea.filter(
      (zone) => zone.configType === "PINCODE"
    );
    let radialArea = deliveryArea.filter(
      (zone) => zone.configType === "RADIAL"
    );
    let polygonalArea = deliveryArea.filter(
      (zone) => zone.configType === "POLYGON"
    );
    let apartments = deliveryArea.filter(
      (zone) => zone.configType === "BUILDING"
    );

    return (
      <div>
        {this.state.kmlUploadPopupShown && (
          <Popup
            heading={getMessage("deliveryArea.kml.upload.text")}
            show={this.state.kmlUploadPopupShown}
            close={this.hideModal}
          >
            <KMLUpload
              storeId={this.state.storeId}
              successCallback={this.uploadSuccessCallback}
            />
          </Popup>
        )}
        <Dialog
          show={this.state.showError}
          close={() =>
            this.setState({ showError: false }, this.fetchDeliveryAreaData)
          }
          information={this.state.apiError}
          closeText={getMessage("extensions.error.dialog.okay")}
        />
        <Dialog
          show={this.state.showSelectStoreDialog}
          close={() => this.setState({ showSelectStoreDialog: false })}
          information={"select a store before uploading kml file"}
          closeText={getMessage("extensions.error.dialog.okay")}
        />
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("delivery.area.kmlUpload.Success")}
          close={this.closeDialogs}
          closeText={getMessage("okay.text")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          title={getMessage("delivery.area.kmlUpload.error.title")}
          information={this.state.kmlUploadError}
          close={this.closeDialogs}
          closeText={getMessage("okay.text")}
        />
        <div className="flex-around">
          <h1>{getMessage("extensions.deliveryArea.title")}</h1>
          <AddDeliveryArea handleKmlUpload={this.showModal} />
        </div>
        <Select
          label="Store"
          placeholder={editing ? "Select store" : ""}
          name="store"
          className="del-area-store-selector"
          options={storeOptions}
          value={storeId ? Number(storeId) : "ALL"}
          onChange={(id) => {
            this.setState({ showStoreError: false });
            this.setStore(id);
          }}
        />
        {showStoreError && (
          <small className="store-select-error">
            {getMessage("extensions.deliveryArea.store.error")}
          </small>
        )}
        <div className="delivery-area-content-wrapper">
          <h3 className="delivering-zones-title">
            {getMessage("extensions.deliveryArea.zones.heading")}
          </h3>
          <hr className="horizontal-line" />
          <div className="delivery-area-content">
            <div className="map-controls">
              <MapControls
                storeId={this.state.storeId}
                setStore={this.setStore}
                setNewLocation={this.setNewLocation}
                setTempLocation={this.setNewLocations}
                editing={editing}
                pincodeArea={pincodeArea}
                polygonalArea={polygonalArea}
                radialArea={radialArea}
                apartments={apartments}
                changeEditMode={this.changeEditMode}
                changeMapEditMode={this.changeMapEditMode}
                clearTempData={this.clearTempData}
                distinctColors={distinctColors}
                fetchAndUpdate={this.fetchDeliveryAreaData}
                toggleStoreError={this.toggleStoreError}
                tempDeliveryAreaData={tempDeliveryArea}
                setShowDrawOption={this.setShowDrawOption}
              />
            </div>
            <div className="map-area">
              <CommonMapComponent
                deliveryArea={deliveryArea}
                pincodeArea={pincodeArea}
                polygonalArea={polygonalArea}
                radialArea={radialArea}
                apartments={apartments}
                setNewLocations={this.setNewLocations}
                tempMarkers={this.state.selectedLocation}
                setNewEditedLocations={this.setNewEditedLocations}
                editPolygon={tempDeliveryArea}
                editing={mapEditing}
                distinctColors={distinctColors}
                storeId={this.state.storeId}
                stores={allStores}
                setShowDrawOption={this.setShowDrawOption}
                showDrawOption={showDrawOption}
              />
            </div>
          </div>
          <div id="delivery-area-form-action-btn" />
        </div>
      </div>
    );
  }
}
export default class DeliveryAreas extends Component {
  render() {
    return (
      <AuthenticatedPage menu={this.props.menu} className="delivery-areas">
        <InterfaceContainer />
      </AuthenticatedPage>
    );
  }
}
