import React, { Component } from "react";
import { Input, Select, BrandSearch } from "../../../../components/Form";
import ItemTable from "./ItemTable";

import { getMessage } from "../../../../lib/translator";

class SFBGSD extends Component {
  constructor(props) {
    super(props);
    this.submitHandler = this.submitHandler.bind(this);
    this.validationHandler = this.validationHandler.bind(this);
  }

  validationHandler(parent) {
    let validations = JSON.parse(JSON.stringify(parent.state.validations));
    parent.setState({
      validations,
    });
  }

  submitHandler(formData) {
    let data = JSON.parse(JSON.stringify(formData));
    const entityType = data.entityType.toLowerCase();
    let rule = data.rule || {};
    if (rule && rule.total) {
      rule.total.v = Number(rule.total.v);
    }
    rule["entity"] = {
      type: data.entityType === "PRODUCT" ? "VARIANT" : data.entityType,
      id: data.entityType === "PRODUCT" ? null : data[entityType].id, // TODO: change this for brands and category
    };
    rule.limit = Number(rule.limit);

    data.rule = rule;
    // data.entityId = 1
    data.offerType = data.type;
    delete data.pwpTagId;
    delete data.quantity;
    delete data.minQuantity;
    delete data.product;
    delete data.category;
    delete data.brand;
    delete data.entity;
    delete data.includes;
    delete data.itemDiscountType;
    return data;
  }
  render() {
    let { entityType, discountTypes, _this, type, hasOfferStarted } =
      this.props;
    const { values } = _this.state;
    entityType = this.props.entityTypeMap[entityType];
    const items = values[entityType];
    const itemsLength = items ? items.length : 0;
    return (
      <div className={`offers-form ${type}-offer`}>
        <div className="offer-fields">
          {entityType === "brand" && (
            <BrandSearch
              name="brand-search"
              required
              readOnly={hasOfferStarted}
              label={`BRAND`}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              className="category-search"
              {..._this.generateStateMappers({
                stateKeys: [entityType],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <Select
            name="discount-type"
            label={getMessage("offer.discounttype")}
            required
            disabled={hasOfferStarted}
            placeholder={getMessage("offer.discounttype.placeholder")}
            options={discountTypes}
            className="discount-type"
            {..._this.generateStateMappers({
              stateKeys: ["rule", "total", "t"],
              loseEmphasisOnFill: true,
            })}
            onChange={_this.handleDiscountChange}
            readOnly={hasOfferStarted}
          />
          <Input
            name="discount-value"
            type="number"
            step={0.01}
            min={0}
            max={
              values &&
              values.rule &&
              values.rule.total &&
              values.rule.total.t === "PERCENT_OFF"
                ? 100
                : Number.MAX_SAFE_INTEGER
            }
            required
            label={getMessage("offer.discountvalue")}
            className="discount-value"
            placeholder={getMessage("offer.discountvalue.placeholder")}
            {..._this.generateStateMappers({
              stateKeys: ["rule", "total", "v"],
              loseEmphasisOnFill: true,
            })}
            readOnly={
              hasOfferStarted ||
              (values &&
                values.rule &&
                values.rule.total &&
                values.rule.total.t === `FREE`)
            }
          />
        </div>
        <div>
          {itemsLength ? (
            <ItemTable
              entityType={entityType}
              items={items}
              discountTypes={discountTypes}
              _this={_this}
              hasOfferStarted={hasOfferStarted}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default SFBGSD;
