const data = {
  "input.requiredMessage": "This cannot be blank",
  "input.invalidFormatMessage": "Please check the format",
  "input.invalidEmailFormat": "Please enter a valid email address",
  "input.invalidUsernameFormat": "Please enter a valid username",
  "input.invalidPhoneFormat": "Please enter a valid phone number",
  "input.fileSizeExeeded": "File size exeeded",
  "input.invalidFileType": "File type is invalid.",
  "input.rangeOverflow": "Please enter a smaller value",
  "input.rangeUnderflow": "Please enter a larger value",
  "input.isUploading": "Please wait while the file is being sent to the server",
  "input.invalidNumber": "Enter a positive value",
  "datetime.date": "Date",
  "datetime.time": "Time",
};

export default data;
