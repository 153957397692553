import React from "react";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { getDefaultStore } from "../../../containers/StoreSelector";
import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import VehicleForm from "./Form";
import VehiclesFilters from "./Filters";
import { getMessage } from "../../../lib/translator";
import { Link } from "react-router-dom";
import { hasPermissions } from "../../../lib/auth";
import Toggle from "../../../components/Form/Inputs/Toggle";

import "./style.css";
import emptyIcon from "./vehicles-empty.svg";
import twoWheelerIcon from "./Form/2-wheeler.svg";
import fourWheelerIcon from "./Form/4-wheeler.svg";
import dorneIcon from "./Form/drone.svg";

/**
 * defines the state for the scenario where no vehicles are added.
 */
const emptyState = (props) => {
  return {
    icon: emptyIcon,
    message: getMessage("vehicles.emptyState.helperText"),
    actions: ({ apiParams, onAction }) => (
      <div className="flex">
        <Link to="/logistics/vehicle-vendor">
          <button className="primary button vendor-btn">
            + {getMessage("vehicles.vendor.heading")}
          </button>
        </Link>
        {hasPermissions("logistics", "vehicle", "post") && (
          <button
            className="primary button"
            onClick={() => {
              onAction(TABLE_ACTIONS.ADD);
            }}
          >
            + {getMessage("vehicles.header.addText")}
          </button>
        )}
      </div>
    ),
  };
};

/**
 * defines the properties with which the header and the rows
 * of the table will be rendered.
 */
const tableProperties = () => {
  return {
    headers: [
      getMessage("vehicles.header.number"),
      getMessage("vehicles.header.type"),
      getMessage("vehicles.header.vendor"),
      getMessage("vehicles.header.status"),
      getMessage("vehicles.header.onAction"),
    ],
    row: ({
      id,
      number,
      type,
      status,
      vendor,
      onAction,
      vehicleVendor,
      storeId,
    }) => (
      <Row>
        <Cell>
          {hasPermissions("logistics", "vehicle", "put") ? (
            <div
              className="vehicle-number"
              onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}
            >
              {number}
            </div>
          ) : (
            <div>{number}</div>
          )}
        </Cell>
        <Cell>
          {
            <img
              src={type === "DRONE" && dorneIcon}
              alt={"vehicle-icon"}
              className="vehicle-icon"
            />
          }
          {
            <img
              src={type === "2W" && twoWheelerIcon}
              alt={"vehicle-icon"}
              className="vehicle-icon"
            />
          }
          {
            <img
              src={type === "4W" && fourWheelerIcon}
              alt={"vehicle-icon"}
              className="vehicle-icon"
            />
          }
          {["4W", "2W", "DRONE"].indexOf(type) > -1 ? (
            <span className="vehicle-type">{`${getMessage(
              `vehicles.type.${type.toLowerCase()}`
            )}`}</span>
          ) : (
            type
          )}
        </Cell>
        <Cell className="vehicle-vendor">
          <span className="text-muted">
            <span className="vehicle-header-mobile">{`${getMessage(
              "vehicles.header.vendor"
            )}: `}</span>{" "}
            <small className="vehicle-text-muted">
              {vehicleVendor ? vehicleVendor.name : "-"}
            </small>
          </span>
        </Cell>
        <Cell className="vehicle-status">
          <small className="text-muted no-mobile">
            {["ENABLED", "DISABLED"].indexOf(status) > -1
              ? getMessage(`vehicles.status.${status.toLowerCase()}`)
              : status}
          </small>
          <Toggle
            className={`${status} only-mobile`}
            name={status}
            value={status === "ENABLED"}
            icons={false}
            onChange={() => {
              onAction(
                TABLE_ACTIONS.UPDATE,
                { id },
                { status: status === "ENABLED" ? "DISABLED" : "ENABLED" }
              );
            }}
          />
        </Cell>
        <Cell className="vehicle-actions">
          {hasPermissions("logistics", "vehicle", "put") && (
            <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
              <DropDownItem
                onClick={() => {
                  onAction(TABLE_ACTIONS.EDIT, { id });
                }}
              >
                {getMessage("vehicles.action.edit")}
              </DropDownItem>
              {status && status === "ENABLED" ? (
                <DropDownItem
                  onClick={() => {
                    onAction(
                      TABLE_ACTIONS.UPDATE,
                      { id },
                      { status: "DISABLED" }
                    );
                  }}
                >
                  {getMessage("vehicles.disable.text")}
                </DropDownItem>
              ) : (
                <DropDownItem
                  onClick={() => {
                    onAction(
                      TABLE_ACTIONS.UPDATE,
                      { id },
                      { status: "ENABLED" }
                    );
                  }}
                >
                  {getMessage("vehicles.enable.text")}
                </DropDownItem>
              )}
            </DropDown>
          )}
        </Cell>
      </Row>
    ),
  };
};
export default class VehiclesInterface extends React.Component {
  render() {
    let props = this.props;
    return (
      <ListingPage
        title={getMessage("vehicles.heading")}
        addHeading={getMessage("vehicles.header.addText")}
        editHeading={getMessage("vehicles.header.editText")}
        api={{
          url: "/logistics-service/vehicle",
          params: getDefaultStore(props.stores),
          transform: (response) => response.data.vehicle,
        }}
        menu={props.menu}
        className="vehicles-page"
        emptyState={emptyState(props)}
        storeDependent
        tableProperties={tableProperties()}
        headerActions={({ onAction }) => (
          <React.Fragment>
            <Link to="/logistics/vehicle-vendor">
              <button className="primary button vendor-btn">
                + {getMessage("vehicles.vendor.heading")}
              </button>
            </Link>
            {hasPermissions("logistics", "vehicle", "post") && (
              <button
                className="button"
                onClick={() => {
                  onAction(TABLE_ACTIONS.ADD);
                }}
              >
                + {getMessage("vehicles.header.addText")}
              </button>
            )}
          </React.Fragment>
        )}
        form={{
          component: VehicleForm,
          transformSubmit: (formData) => {
            let modifiedData = Object.assign({}, formData);
            if (formData.vehicleVendor) {
              modifiedData["vendorId"] = formData.vehicleVendor.id;
              delete modifiedData.vehicleVendor;
            }
            return modifiedData;
          },
        }}
        filters={{
          component: VehiclesFilters,
          transformSubmit: (formData) => {
            let modifiedData = { ...formData };
            if (formData.vendor) {
              modifiedData["vendorId"] = formData.vendor.id;
              delete modifiedData.vendor;
            }
            if (formData.vehicle) {
              modifiedData["vehicle"] = formData.vehicle.number;
              delete modifiedData.vehicle;
            }
            return modifiedData;
          },
        }}
      />
    );
  }
}
