import React, { Component } from "react";
import { Droppable } from "react-beautiful-dnd";
import OrderContainer from "../TripContainer/OrderContainer";

export default class NotReadyOrders extends Component {
  render() {
    const { orders, droppableId } = this.props;
    return orders.length > 0 ? (
      <Droppable droppableId={`not-ready-${droppableId}`}>
        {(provided, snapshot) => (
          <div
            className={`not-ready trip-orders ${
              snapshot.isDraggingOver ? "dragging-over" : ""
            }`.trim()}
          >
            <div
              className="trip-orders-inner-container"
              ref={provided.innerRef}
            >
              {orders.map((order, index) => (
                <OrderContainer
                  key={order.id}
                  data={order}
                  draggableId={order.id}
                  index={index}
                  onClick={() =>
                    this.props.showDeliveryLocation(order.id, droppableId)
                  }
                />
              ))}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    ) : null;
  }
}
