import React from "react";
import { BaseForm, VALIDATION_TYPES, Phone } from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";

class AddPhoneNumberForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      formError: "",
    };
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div className="AddPhoneNumberForm">
        <Form>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <Phone
            country={this.props.isoCode}
            label={getMessage("notifications.phone")}
            name="phone"
            type="text"
            required
            placeholder={getMessage("notifications.enter.phone")}
            {...this.generateStateMappers({
              stateKeys: ["phone"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <div className="form-buttons-container">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage("notifications.submitText")}
            </SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}
export default AddPhoneNumberForm;
