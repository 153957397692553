import React, { Component } from "react";
import SEOForm from "./SEOSetting";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import { getMessage } from "../../../lib/translator";

export default class SEO extends Component {
  render() {
    return (
      <AuthenticatedPage
        className="seo"
        menu={this.props.menu}
        title={getMessage("seo.heading")}
      >
        <h1 className="title">{getMessage("seo.heading")}</h1>
        <SEOForm />
      </AuthenticatedPage>
    );
  }
}
