import React, { Component } from "react";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import StoreSelector, {
  getDefaultStore,
} from "../../../containers/StoreSelector";
import VehilcePlannerFilterForm from "./Filters";
import AddShiftForm from "./Form";
import { getMessage } from "../../../lib/translator";
import moment from "moment";
import { Provider } from "./Context";

import "./style.css";
import emptyIcon from "./vehicles-empty.svg";
import VehicleShift from "./VehicleShift";
import API from "../../../lib/api";
import { isExtensionEnabled } from "../../../lib/auth";
import {
  utcToLocalTime,
  localTimeToUtc,
  getStandradDateTime,
} from "../../../lib/datetime";
import { get } from "../../../lib/storage";

/**
 * defines the state for the scenario where no vehicles are added.
 */
const emptyState = (props) => {
  return {
    icon: emptyIcon,
    message: getMessage("vehiclePlanner.emptyState.message"),
    actions: ({ apiParams, onAction }) => (
      <div className="flex">
        {isExtensionEnabled("MultiStoreSupport") && (
          <StoreSelector
            value={apiParams.storeId}
            stores={props.stores}
            onChange={(storeId) => {
              onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId });
            }}
          />
        )}
        <button
          className="primary button"
          onClick={() => {
            onAction(TABLE_ACTIONS.ADD);
          }}
        >
          + {getMessage("vehiclePlanner.addShift.button")}
        </button>
      </div>
    ),
  };
};

/**
 * defines the properties with which the header and the rows
 * of the table will be rendered.
 */
const tableProperties = {
  row: (props) => <VehicleShift {...props} />,
};

class VehiclePlanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: {},
    };
    this.fetchVehicles = this.fetchVehicles.bind(this);
    this.updateVehicleOnCheckin = this.updateVehicleOnCheckin.bind(this);
    this.updateVehicleOnCheckOut = this.updateVehicleOnCheckOut.bind(this);
    this.updateVehicleOnReplace = this.updateVehicleOnReplace.bind(this);
  }
  updateVehicleOnCheckin(vehicleId, response) {
    let addedVehicleIndex = this.state.vehicles.all.findIndex(
      (vehicle) => vehicle.id === parseInt(vehicleId, 10)
    );
    let availableVehicles = JSON.parse(JSON.stringify(this.state.vehicles.all));
    let checkedInVehicles = JSON.parse(
      JSON.stringify(this.state.vehicles.checkedIn)
    );
    availableVehicles[addedVehicleIndex].isCheckedIn = true;
    checkedInVehicles.push(response);
    this.setState({
      vehicles: {
        ...this.state.vehicles,
        checkedIn: checkedInVehicles,
        all: availableVehicles,
      },
    });
  }
  updateVehicleOnCheckOut(response) {
    let removedVehicleIndex = this.state.vehicles.all.findIndex(
      (vehicle) => vehicle.id === response.vehicleId
    );
    let availableVehicles = JSON.parse(JSON.stringify(this.state.vehicles.all));
    let checkedInVehicles = JSON.parse(
      JSON.stringify(this.state.vehicles.checkedIn)
    );
    availableVehicles[removedVehicleIndex].isCheckedIn = false;
    checkedInVehicles =
      (checkedInVehicles &&
        checkedInVehicles.filter(
          (vehicle) => vehicle.vehicleId !== response.vehicleId
        )) ||
      [];
    checkedInVehicles.push(response);
    this.setState({
      vehicles: {
        ...this.state.vehicles,
        checkedIn: checkedInVehicles,
        all: availableVehicles,
      },
    });
  }
  updateVehicleOnReplace(response) {
    let removedVehicleIndex = this.state.vehicles.all.findIndex(
      (vehicle) => vehicle.id === response.vehicleId
    );
    let addedVehicleIndex = this.state.vehicles.all.findIndex(
      (vehicle) => vehicle.id === response.replacedBy
    );
    let availableVehicles = JSON.parse(JSON.stringify(this.state.vehicles.all));
    availableVehicles[removedVehicleIndex].isCheckedIn = false;
    availableVehicles[addedVehicleIndex].isCheckedIn = true;
    this.checkedInVehiclesApi = new API({
      url: "/logistics-service/vehicle-attendance",
    });
    let checkedInVehiclesParams = {
      date: moment().format("YYYY-MM-DD"),
      storeId: get("store") || getDefaultStore(this.props.stores).storeId,
      paginated: "false",
    };
    this.checkedInVehiclesApi
      .get(checkedInVehiclesParams)
      .then((response) => {
        this.setState({
          vehicles: {
            ...this.state.vehicles,
            checkedIn: response.data.vehicleattendance,
            all: availableVehicles,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ formError: error.message });
      });
  }
  fetchVehicles() {
    this.checkedInVehiclesApi = new API({
      url: "/logistics-service/vehicle-attendance",
    });
    let checkedInVehiclesParams = {
      date: moment().utc().format("YYYY-MM-DD"),
      storeId: get("store") || getDefaultStore(this.props.stores).storeId,
      paginated: "false",
    };
    this.allVehilcesApi = new API({ url: "/logistics-service/vehicle" });
    let storeId = get("store") || getDefaultStore(this.props.stores).storeId;
    let allVehilceParams = { storeId: storeId, paginated: "false" };
    Promise.all([
      this.checkedInVehiclesApi.get(checkedInVehiclesParams),
      this.allVehilcesApi.get(allVehilceParams),
    ])
      .then(([checkedInVehicleResponse, allVehicleResponse]) => {
        this.setState({
          vehicles: {
            checkedIn: checkedInVehicleResponse.data.vehicleattendance,
            all: allVehicleResponse.data.vehicle,
            updateOnCheckIn: this.updateVehicleOnCheckin,
            updateOnCheckOut: this.updateVehicleOnCheckOut,
            updateOnReplace: this.updateVehicleOnReplace,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        if (error.code === 401 || error.code === 403) {
          throw error;
        }
        if (error.message === "cancelled") {
          return;
        }
        this.setState({ formError: error.message });
      });
  }
  componentDidMount() {
    this.fetchVehicles();
  }
  componentWillUnmount() {
    this.checkedInVehiclesApi && this.checkedInVehiclesApi.cancel();
    this.allVehilcesApi && this.allVehilcesApi.cancel();
  }
  render() {
    return (
      <Provider value={this.state.vehicles}>
        <ListingPage
          title={getMessage("vehiclePlanner.heading")}
          className="vehicle-planner-page"
          api={{
            url: "/logistics-service/vehicle-planner",
            params: {
              date: moment().format("YYYY-MM-DD"),
            },
            onUpdateStore: this.fetchVehicles,
            transform: (response) => {
              if (Array.isArray(response.data.vehicleplanner)) {
                const newResponse = response.data.vehicleplanner.map(
                  (planner) => {
                    const final = getStandradDateTime(
                      utcToLocalTime,
                      `${planner.date} ${planner.shiftStart}`
                    ).split(" ");
                    planner.date = final[0];
                    planner.shiftStart = final[1];
                    planner.shiftEnd = getStandradDateTime(
                      utcToLocalTime,
                      `${planner.date} ${planner.shiftEnd}`
                    ).split(" ")[1];
                    return planner;
                  }
                );
                return newResponse;
              } else {
                const obj = response.data.vehicleplanner;
                const final = getStandradDateTime(
                  utcToLocalTime,
                  `${obj.date} ${obj.shiftStart}`
                ).split(" ");
                obj.date = final[0];
                obj.shiftStart = final[1];
                obj.shiftEnd = getStandradDateTime(
                  utcToLocalTime,
                  `${obj.date} ${obj.shiftEnd}`
                ).split(" ")[1];
                return obj;
              }
            },
          }}
          menu={this.props.menu}
          addHeading={getMessage("vehiclePlanner.addShift.heading")}
          editHeading={getMessage("vehiclePlanner.editShift.heading")}
          emptyState={emptyState(this.props)}
          headerActions={({ apiParams, onAction }) => (
            <div className="flex">
              <button
                className="primary button"
                onClick={() => {
                  onAction(TABLE_ACTIONS.ADD);
                }}
              >
                +{" "}
                <span className="text">
                  {getMessage("vehiclePlanner.addShift.button")}
                </span>
              </button>
            </div>
          )}
          storeDependent
          addNewItemToLast
          tableProperties={tableProperties}
          form={{
            component: AddShiftForm,
            transformSubmit: (formData) => {
              const final = getStandradDateTime(
                localTimeToUtc,
                `${formData.date} ${formData.shiftStart}`
              ).split(" ");
              let params = {
                date: final[0],
                shiftStart: final[1],
                shiftEnd: getStandradDateTime(
                  localTimeToUtc,
                  `${formData.date} ${formData.shiftEnd}`
                ).split(" ")[1],
                regularCount: Number(formData.regularCount),
              };
              if (formData.adhocCount && formData.adhocCount !== "") {
                params.adhocCount = Number(formData.adhocCount);
              }
              return params;
            },
            overwriteWithApiParams: false,
            filterBeforeAdding: (response, _this) => {
              if (
                _this.state.data.filters.date === response.date ||
                _this.state.apiParams.date === response.date
              ) {
                return response;
              } else {
                return null;
              }
            },
          }}
          filters={{
            component: VehilcePlannerFilterForm,
            transformSubmit: (formData) => {
              if (!formData.date) {
                formData.date = moment().format("YYYY-MM-DD");
              }
              return formData;
            },
            forceShow: true,
          }}
        />
      </Provider>
    );
  }
}

export default VehiclePlanner;
