import React, { Component } from "react";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { Row, Cell } from "../../../components/Table";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import Image from "../../../components/Image";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { getMessage } from "../../../lib/translator";
import ProductRestrictionForm from "./Form";
import "./style.css";
import EmptyIcon from "./empty.svg";
import { formatTime, getPrintableTime } from "../../../lib/datetime";
import RestrictedProductFileUpload from "./RestrictedProductUpload";
import { getStandradDateTime } from "../../../lib/datetime";
import { localTimeToUtc } from "../../../lib/datetime";
import { utcToLocalTime } from "../../../lib/datetime";

import { Popup } from "../../../components/Popup";
class FileUploadAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShown: false,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  hideModal() {
    this.setState({
      modalShown: false,
    });
  }

  render() {
    const user = JSON.parse(window.localStorage.getItem("user"));
    let organizationData = JSON.parse(
      window.localStorage.getItem("organization")
    );
    return (
      <div className="bulk-upload-button">
        <Popup
          heading={getMessage("catalogue.product.restriction.bulk.upload")}
          show={this.state.modalShown}
          close={this.hideModal}
          className="bulk-upload-popup"
        >
          <RestrictedProductFileUpload
            uploadUrl="/catalogue-service/product-restriction-file"
            id={user?.id}
            organizationId={organizationData?.id}
          />
        </Popup>
        <button
          className="secondary button"
          onClick={(e) => {
            this.setState({
              modalShown: true,
            });
          }}
        >
          <span className="text">
            {getMessage("catalogue.product.restriction.bulk.upload")}
          </span>
        </button>
      </div>
    );
  }
}

const tableProperties = {
  headers: [
    getMessage("catalogue.product.restriction.image"),
    getMessage("catalogue.product.restriction.name"),
    getMessage("catalogue.product.restriction.startDate"),
    getMessage("catalogue.product.restriction.endDate"),
    getMessage("catalogue.product.restriction.max.purchaseable.qty"),
    getMessage("catalogue.product.restriction.actions"),
  ],
  row: ({
    id,
    name,
    startDate,
    endDate,
    rule,
    product,
    onAction,
    startTime,
    endTime,
  }) => {
    return (
      <Row>
        <Cell className="store-pdt-image">
          <Image size="sm" src={product?.images?.[0]} bordered />
        </Cell>
        <Cell className="name">
          <span
            className="category-name"
            onClick={() => {
              onAction(TABLE_ACTIONS.EDIT, { id });
            }}
          >
            {name ? (
              name.name
            ) : product ? (
              product.brand ? (
                <React.Fragment>
                  <span className="bold">{product.brand.name} </span>
                  {product.name}
                </React.Fragment>
              ) : (
                product.name
              )
            ) : null}
          </span>
        </Cell>
        <Cell className="limit-start-date ">
          <span className="store-mobile-header">
            {getMessage("catalogue.product.restriction.startDate")}
          </span>
          <div>{getPrintableTime(startDate)}</div>
          <small className="text-muted coupon-validity-to">
            {formatTime(startTime)}
          </small>
        </Cell>
        <Cell className="limit-end-date">
          <span className="store-mobile-header">
            {getMessage("catalogue.product.restriction.endDate")}
          </span>
          <div>{getPrintableTime(endDate)}</div>
          <small className="text-muted coupon-validity-to">
            {formatTime(endTime)}
          </small>
        </Cell>
        <Cell className="limit-max-purchase-qty align-right">
          <span className="store-mobile-header">
            {getMessage("catalogue.product.restriction.max.purchaseable.qty")}
          </span>
          {rule?.maxPurchasableLimit}
        </Cell>
        <Cell className="store-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.EDIT, { id });
              }}
            >
              {getMessage("category.action.edit")}
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.DELETE, { id });
              }}
            >
              {getMessage("category.action.delete")}
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    );
  },
};

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage("product.restrictions.empty"),
  actions: ({ onAction }) => (
    <>
      <FileUploadAction />
      <button
        className="primary button"
        onClick={() => {
          onAction(TABLE_ACTIONS.ADD);
        }}
      >
        +{" "}
        <span className="text">
          {getMessage("product.restrictions.add.text")}
        </span>
      </button>
    </>
  ),
};

class InterfaceContainer extends Component {
  render() {
    return (
      <div className="purchase-limit-wrapper">
        <h1 className="title">
          {getMessage("catalogue-service-product-restrictions.heading")}
        </h1>
        <ListingPage
          className="restriction-list"
          api={{
            url: "/catalogue-service/product-restriction",
            transform: (response) => {
              let productRestrictions;
              let product = response.data?.product || [];
              if (Array.isArray(response.data?.productRestrictions)) {
                productRestrictions = response.data?.productRestrictions;
                productRestrictions.forEach((item) => {
                  if(item?.rule?.renewalTime){
                    item.days = parseInt(Number(item?.rule?.renewalTime) / 24);
                    item.hours = Number(item?.rule?.renewalTime) % 24;
                    delete item?.rule?.renewalTime;
                  }
                  const startDateTime = getStandradDateTime(
                    utcToLocalTime,
                    item?.startDate
                  );
                  const endDateTime = getStandradDateTime(
                    utcToLocalTime,
                    item?.endDate
                  );
                  item.startDate = startDateTime.split(" ")[0];
                  item.startTime = startDateTime.split(" ")[1];
                  item.endDate = endDateTime.split(" ")[0];
                  item.endTime = endDateTime.split(" ")[1];
                  item.product = product.filter(
                    (pdt) => pdt.id === item.entityId
                  )?.[0];
                });
              } else {
                productRestrictions = response.data?.productRestriction || [];
                productRestrictions.product = product?.[0] || [];
                let startDateTime;
                if (productRestrictions?.startDate)
                  startDateTime = getStandradDateTime(
                    utcToLocalTime,
                    productRestrictions?.startDate
                  );
                let endDateTime;
                if (productRestrictions?.endDate)
                  endDateTime = getStandradDateTime(
                    utcToLocalTime,
                    productRestrictions?.endDate
                  );
                  if(productRestrictions?.rule?.renewalTime){
                    productRestrictions.days = parseInt(Number(productRestrictions?.rule?.renewalTime)/ 24);
                    productRestrictions.hours = Number(productRestrictions?.rule?.renewalTime) % 24;
                    delete productRestrictions?.rule?.renewalTime;
                  }
                productRestrictions.startDate = startDateTime?.split(" ")[0];
                productRestrictions.startTime = startDateTime?.split(" ")[1];
                productRestrictions.endDate = endDateTime?.split(" ")[0];
                productRestrictions.endTime = endDateTime?.split(" ")[1];
              }
              return productRestrictions;
            },
          }}
          emptyState={EmptyState}
          form={{
            component: ProductRestrictionForm,
            transformSubmit: (formData) => {
              let data = Object.assign({}, formData);
              if (data.product && data.product.id) {
                data.entityId = data.product.id;
                delete data.product;
              }
              if (data.startDate && data.startTime) {
                data.startDate = getStandradDateTime(
                  localTimeToUtc,
                  `${formData.startDate} ${formData.startTime}`
                );
                data.startDate = data.startDate.replace(/ /g, "T") + "Z";
              }
              if (data.endDate && data.endTime) {
                data.endDate = getStandradDateTime(
                  localTimeToUtc,
                  `${formData.endDate} ${formData.endTime}`
                );
                data.endDate = data.endDate.replace(/ /g, "T") + "Z";
              }
              if(!data.days && !data.hours) {
                data.rule.renewalTime = 0;
              }
              if(data.days || data.hours) {
                let totalHours = 0;
                if(data.days) {
                  totalHours = parseInt(data.days * 24)
                }
                if(data.hours) {
                  totalHours += parseInt(data.hours)
                }
                data.rule.renewalTime = totalHours
              }
              delete data.days;
              delete data.hours;
              delete data["startTime"];
              delete data["endTime"];
              data.type = "PRODUCT";
              return data;
            },
          }}
          primaryKey="id"
          tableProperties={tableProperties}
          addHeading="Add Configuration"
          editHeading="Edit Configuration"
          headerActions={({ onAction }) => (
            <React.Fragment>
              <FileUploadAction />
              <button
                className="primary button"
                onClick={() => {
                  onAction(TABLE_ACTIONS.ADD);
                }}
              >
                +{" "}
                <span className="text">
                  {getMessage("product.restrictions.add.text")}
                </span>
              </button>
            </React.Fragment>
          )}
        />
      </div>
    );
  }
}

export default class ProductRestrictionConfig extends Component {
  render() {
    return (
      <AuthenticatedPage menu={this.props.menu} className="purchase-limit">
        <InterfaceContainer />
      </AuthenticatedPage>
    );
  }
}
