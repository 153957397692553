import React from "react";
import {
  BaseForm,
  Input,
  SingleDatePicker,
  Select,
} from "../../../../components/Form";
import API from "../../../../lib/api";
import { getMessage } from "../../../../lib/translator";

export default class OrderFiltersForm extends BaseForm {
  constructor(props) {
    // lastOrderType is being used to track the change in selected orderType
    // As the state is getting mutated, we are not able to get correct prevState
    super(props);
    this.lastOrderType = undefined;
  }

  componentDidMount() {
    this.configApi = new API({ url: "/account-service/config/order" });
    this.configApi
      .get()
      .then((response) => {
        let orderTypes = response.data.config.order.orderTypes;
        orderTypes = (orderTypes || []).map((orderType) => {
          return {
            text:
              orderType.split("")[0] +
              orderType
                .toLowerCase()
                .split("")
                .splice(1, orderType.length)
                .join(""),
            value: orderType,
          };
        });
        this.setState({ orderTypes });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // This componentDidUpdate method used to track the change in orderType
  // As inside BaseForm the state is getting mutated, we are not able to compare
  // prevState and current state. This is why lastOrderType variable is introduced
  // to store the last value of orderType. As it does not have any impact on the UI,
  // we are not storing it inside state, thus we can prevent unnecessary render.
  componentDidUpdate(prevProps, prevState) {
    const { values } = this.state;
    if (values && values.orderType !== this.lastOrderType) {
      this.lastOrderType = values.orderType;
    }
  }

  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    const { orderTypes } = this.state;
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage("order.filters.referenceNumber.heading")}
            placeholder={getMessage(
              "order.filters.referenceNumber.placeholder"
            )}
            name="referenceNumber"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["referenceNumber"],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage("order.filters.clientId.heading")}
            placeholder={getMessage("order.filters.clientId.placeholder")}
            name="clientId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["clientId"],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage("order.filters.orderType")}
            placeholder={getMessage("order.filters.orderType.placeholder")}
            name="orderType"
            options={orderTypes}
            {...this.generateStateMappers({
              stateKeys: ["orderType"],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            label={getMessage("order.filters.preferredDate")}
            placeholder={getMessage("order.filter.placedOn.placeholder")}
            name="preferred-date"
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ["preferredDate"],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage("order.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("order.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
