import React from "react";

const variables = {
  name: "Customer",
};

const parseTemplate = (template) => {
  Object.keys(variables).forEach((variable) => {
    let regex = new RegExp("{{s*" + variable + "s*}}", "g");
    template = template.replace(regex, variables[variable]);
  });
  return template;
};

const PreviewElement = (props) => {
  let { subject, body, sms, templateType, img, url } = props;
  subject = subject && parseTemplate(subject);
  body = body && parseTemplate(body);
  sms = sms && parseTemplate(sms);
  return (
    <div className="template-preview">
      {templateType === "EMAIL" && (
        <div>
          <h4 className="email-subject">{subject}</h4>
          <div className="email-body">
            <pre dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </div>
      )}
      {templateType === "SMS" && <div className="sms-content">{sms}</div>}
      {templateType === "PUSH" && (
        <div>
          <h4 className="push-subject">{subject}</h4>
          <div className="push-body">{body}</div>
          <a href={url} className="push-body">
            {url}
          </a>
          {img && (
            <img src={img} alt="push-notification" className="push-img" />
          )}
        </div>
      )}
    </div>
  );
};

export { PreviewElement };
