import React from "react";
import { BaseForm, Input, VALIDATION_TYPES } from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
// import './style.css'

/** *`VehicleForm`*
 * :adds management to **`Vehicles`** component
 */
export default class VehicleForm extends BaseForm {
  /**
   * changes the status of the Vehicle
   */
  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div className="VehicleForm">
        <Form>
          <Input
            label={getMessage("vehicles.form.vendor.heading")}
            placeholder={getMessage("vehicles.form.vendor.placeholder")}
            name="number"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <div className={`form-actions`}>
            <SubmitButton>
              {getMessage("vehicles.vendor.addButton")}
            </SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}
