import React, { Component } from "react";
import { Input, BaseForm } from "../../../../../components/Form";
import Image from "../../../../../components/Image";
import API from "../../../../../lib/api";
import Loader from "../../../../../components/Loader";
import Dialog from "../../../../../components/Popup/Dialog";
import { getMessage } from "../../../../../lib/translator";

const Header = (props) => {
  const header = props.header;
  if (!header) {
    return null;
  }
  let details = header.map((paymentMode) => (
    <th
      key={paymentMode.key}
      colSpan={paymentMode.colSpan}
      scope={paymentMode.colgroup}
    >
      {paymentMode.name}
    </th>
  ));
  return details;
};

const SubHeader = (props) => {
  const subHeader = props.subHeader;
  if (!subHeader) {
    return null;
  }
  let details = subHeader.map((subHeader, i) => {
    return subHeader.map((item, j) => (
      <th key={`${i}-${j}-subheader`} scope="col">
        <Image src={item.src} />
      </th>
    ));
  });
  return details;
};

class TableRow extends Component {
  componentDidMount() {
    this.setState({
      gateway: this.props.gateway,
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.gateway !== this.state.gateway) {
      this.setState({
        gateway: newProps.gateway,
      });
    }
  }
  render() {
    const { paymentModesOrder, gateway, onChange } = this.props;
    if (!gateway || !paymentModesOrder) {
      return null;
    }
    const gatewayPaymentModes = gateway.paymentModes.map((pm) => pm.id);
    let keys = Object.keys(paymentModesOrder);
    let row = keys.map((groupId) => {
      let values = paymentModesOrder[groupId].map((pm) => {
        if (gatewayPaymentModes.indexOf(pm.id) > -1) {
          let paymentModeRate = gateway.paymentModes.filter(
            (gpm) => gpm.id === pm.id
          )[0].rate;
          return (
            <td key={`${gateway.id}-${pm.id}`}>
              <Input
                type="number"
                suffix={"%"}
                min={0}
                max={100}
                name={`${groupId}-${pm.id}`}
                value={paymentModeRate}
                onChange={(e) => onChange(gateway.id, pm.id, e)}
              />
            </td>
          );
        } else {
          return <td key={`${gateway.id}-${pm.id}`} />;
        }
      });
      return values;
    });
    return row;
  }
}

export default class RateForm extends BaseForm {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      showSuccessDialog: false,
      validations: {},
    };

    this.state.values = {
      configuredGateways: this.props.addedGateways,
    };

    this.getGatewayNames = this.getGatewayNames.bind(this);
    this.getHeaderDetails = this.getHeaderDetails.bind(this);
    this.showGatewayConfig = this.showGatewayConfig.bind(this);
    this.onChange = this.onChange.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  closeDialog() {
    this.setState({
      showSuccessDialog: false,
    });
  }

  getGatewayNames() {
    let { addedGateways } = this.props;
    let names = addedGateways.map((gw) => {
      return (
        <tr key={gw.id}>
          <td>{gw.providerName}</td>
        </tr>
      );
    });
    return names;
  }

  componentWillReceiveProps(newProps) {
    let configuredGateways = this.state.values.configuredGateways;
    if (newProps.configuredGateways !== configuredGateways) {
      this.setState({
        values: {
          configuredGateways: newProps.addedGateways,
        },
      });
    }
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    const { paymentModes } = this.props;
    let pmHeaderDetails = paymentModes.reduce((result, pm) => {
      result[pm.parentPaymentModeId] = result[pm.parentPaymentModeId] || [];
      result[pm.parentPaymentModeId].push(pm);
      return result;
    }, {});

    let keys = Object.keys(pmHeaderDetails);
    let singlePm = pmHeaderDetails["null"];
    delete pmHeaderDetails["null"];
    singlePm.map((pm) => {
      if (keys.indexOf(pm.id) < 0) {
        pmHeaderDetails[pm.id] = [pm];
      }
      return null;
    });
    this.setState(
      {
        paymentModesWithChilPm: pmHeaderDetails,
      },
      () => {
        this.getHeaderDetails();
      }
    );
  }

  getHeaderDetails() {
    const { paymentModes } = this.props;
    let pmHeaderDetails = this.state.paymentModesWithChilPm;
    if (!pmHeaderDetails) {
      return null;
    }
    let keys = Object.keys(pmHeaderDetails);

    let mainDivision = keys.map((key) => {
      if (key > 0) {
        let pmName = paymentModes.filter((pm) => pm.id === key)[0].paymentMode;
        let pmId = paymentModes.filter((pm) => pm.id === key)[0].id;
        let pmCount = pmHeaderDetails[key].length;
        return {
          key: pmId,
          colSpan: pmCount,
          scope: "colgroup",
          name: pmName,
        };
      } else {
        let paymentModes = pmHeaderDetails[key].map((pm) => {
          if (keys.indexOf(pm.id) < 0) {
            return {
              key: pm.id,
              colSpan: 1,
              scope: "colgroup",
              name: pm.paymentMode,
            };
          }
          return null;
        });
        return paymentModes;
      }
    });

    let subPaymentModes = this.state.paymentModesWithChilPm;
    if (!subPaymentModes) {
      return null;
    }
    let subHeaderObject = Object.keys(subPaymentModes).map((group) => {
      return subPaymentModes[group].map((pm) => {
        return {
          scope: "col",
          src: pm.imageUrl,
        };
      });
    });
    this.setState({
      headerObject: mainDivision,
      subHeaderObject: subHeaderObject,
      showLoader: false,
    });
    return mainDivision;
  }

  showGatewayConfig() {
    if (!this.state.paymentModesWithChilPm) {
      return;
    }
    let paymentModesOrder = this.state.paymentModesWithChilPm;
    let gateways = this.props.addedGateways.map((gw) => {
      return (
        <tr key={gw.id}>
          <td className="ghost-spacer" />
          <TableRow
            paymentModesOrder={paymentModesOrder}
            gateway={gw}
            onChange={this.onChange}
          />
        </tr>
      );
    });
    return gateways;
  }

  onChange(gatewayId, paymentModeId, e) {
    let newState = this.state.values.configuredGateways;
    if (!newState) {
      return;
    }
    let paymentGateway = newState.filter((pg) => pg.id === gatewayId);
    let paymentMode = paymentGateway[0].paymentModes.filter(
      (pm) => pm.id === paymentModeId
    )[0];
    paymentMode.rate = e;
    this.setState({
      values: {
        configuredGateways: newState,
      },
    });
  }

  onSubmit(data) {
    const api = new API({ url: "/account-service/payment-account" });
    let params = {};
    params["type"] = "CUSTOM";
    params["id"] = this.props.paymentAccountId;
    params["paymentGateways"] = data.configuredGateways;
    api
      .put(params)
      .then(
        (response) => {
          this.setState({
            showSuccessDialog: true,
            formError: "",
          });
          this.props.onSuccess(response.data.paymentaccount.paymentGateways);
        },
        (error) => {
          this.setState({
            formError: error.message,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    return (
      <div className="rate-form">
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <div>
            <Dialog
              show={this.state.showSuccessDialog}
              className="success"
              close={this.closeDialog}
              closeText={getMessage("settings.online.payment.ok")}
              information={getMessage(
                "settings.online.payment.succesfully.saved"
              )}
            />
            <Form>
              {this.state.formError && (
                <div className="form-error">{this.state.formError}</div>
              )}
              <div className="merchant-revenue-section">
                {/* <div className='header'>Priority: </div> */}
                <div className="content">
                  <div className="scroll-container">
                    <table className="fixed-table" key="fixed-table">
                      <thead>
                        <tr key="diagonal">
                          <th>
                            <div className="diagonalize">
                              <div className="top-right">
                                {getMessage(
                                  "settings.online.payment.payemntmethod"
                                )}
                              </div>
                              <div className="bottom-left">
                                {getMessage("settings.online.payment.gateway")}
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{this.getGatewayNames()}</tbody>
                    </table>
                    <table
                      className="scrollable-table"
                      style={{ minWidth: `${100 * 9}px` }}
                      key="scrollable-table"
                    >
                      <colgroup span="3" />
                      <colgroup span="2" />
                      <thead>
                        <tr key="header">
                          <td rowSpan="2" />
                          <Header header={this.state.headerObject} />
                        </tr>
                        <tr key="subheader">
                          <SubHeader subHeader={this.state.subHeaderObject} />
                        </tr>
                      </thead>
                      <tbody>{this.showGatewayConfig()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="form-actions">
                <SubmitButton>
                  {getMessage("settings.online.payments.save")}
                </SubmitButton>
              </div>
            </Form>
          </div>
        )}
      </div>
    );
  }
}
