import React, { Component } from "react";
import moment from "moment";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import Loader from "../../../../components/Loader";
import Table, { Header, Row, Cell } from "../.././../../components/Table";

import { getMessage } from "../../../../lib/translator";
import API from "../../../../lib/api";
import { get } from "../../../../lib/storage";
import { isExtensionEnabled } from "../../../../lib/auth";
import {
  formatTime,
  formatIsoTime,
  formatIsoDate,
  getPrintableTime,
  getStandradDateTime,
  utcToLocalTime,
} from "../../../../lib/datetime";

function downloadCsv(data) {
  let csv = "";
  data.forEach((row) => {
    csv += row.join(",");
    csv += "\n";
  });
  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
  hiddenElement.target = "_blank";
  hiddenElement.download = `trip_${moment().format(
    "YYYY-MM-DD"
  )} - ${moment().format("HH:mm")}.csv`;
  hiddenElement.click();
}

class TripPlannerTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.formatDataForCsvReady = this.formatDataForCsvReady.bind(this);
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
    const api = new API({ url: "/logistics-service/automation" });
    let params = {};
    params.date = moment().utc().format("YYYY-MM-DD");
    params.storeId = get("store");
    params.refreshPlannedTrips = window.localStorage.getItem(
      "refreshPlannedTrips"
    );
    api
      .get(params)
      .then((response) => {
        this.setState({
          loading: false,
          plannedTrip: response.data?.zone,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  formatDataForCsvReady() {
    let zone = this.state.plannedTrip;
    let dataArray = [];
    let deliverySlotStartKey = isExtensionEnabled("DeliverySlots")
      ? getMessage("tripPlanner.slots.startTime")
      : null;
    let deliverySlotEndKey = isExtensionEnabled("DeliverySlots")
      ? getMessage("tripPlanner.slot.endTime")
      : null;
    dataArray[0] = [
      getMessage("tripPlanner.orderNumber"),
      getMessage("tripPlanner.status"),
      getMessage("tripPlanner.items"),
      getMessage("tripPlanner.orderPlacedDate"),
      getMessage("tripPlanner.orderPlacedTime"),
    ];
    deliverySlotStartKey && dataArray[0].push(deliverySlotStartKey);
    deliverySlotEndKey && dataArray[0].push(deliverySlotEndKey);
    dataArray[0] = dataArray[0].concat([
      getMessage("tripPlanner.order.lat"),
      getMessage("tripPlanner.order.lng"),
      getMessage("tripPlanner.order.distance"),
      getMessage("tripPlanner.picker.name"),
      getMessage("tripPlanner.picker.startTime"),
      getMessage("tripPlanner.picker.endTime"),
      getMessage("tripPlanner.vehicle"),
      getMessage("tripPlanner.vehicle.shiftStart"),
      getMessage("tripPlanner.vehicle.shiftEnd"),
      getMessage("tripPlanner.tripNo"),
      getMessage("tripPlanner.tripStart"),
      getMessage("tripPlanner.tripEnd"),
      getMessage("tripPlanner.orderSeq"),
      getMessage("tripPlanner.arrival"),
      getMessage("tripPlanner.waiting"),
      getMessage("tripPlanner.complete"),
    ]);
    zone &&
      zone.forEach((plannedTrip) => {
        plannedTrip &&
          plannedTrip.plannedTrips.forEach((trip, tripIndex) => {
            trip &&
              trip.orders.forEach((order, orderIndex) => {
                let arr = [];
                arr.push(order.clientId ? order.clientId : order.id);
                arr.push(order.status);
                arr.push(order.productCount);
                arr.push(formatIsoDate(order.createdAt));
                arr.push(formatIsoTime(order.createdAt));
                isExtensionEnabled("DeliverySlots") &&
                  order.slot &&
                  arr.push(formatTime(order.slot.split("-")[0]));
                isExtensionEnabled("DeliverySlots") &&
                  order.slot &&
                  arr.push(formatTime(order.slot.split("-")[1]));
                arr.push(Number(order.address.location.lat).toFixed(2));
                arr.push(Number(order.address.location.long).toFixed(2));
                arr.push(
                  trip.sequence
                    ? (
                        Number(trip.sequence[orderIndex].Distance) / 1000
                      ).toFixed(2)
                    : ""
                );
                arr.push(order.metaData.picker);
                arr.push(
                  moment
                    .utc(order.metaData.pickingStartsBy)
                    .format("YYYY-MM-DD") !== "0001-01-01"
                    ? formatIsoTime(order.metaData.pickingStartsBy)
                    : "-"
                );
                arr.push(
                  moment
                    .utc(order.metaData.pickingEndsBy)
                    .format("YYYY-MM-DD") !== "0001-01-01"
                    ? formatIsoTime(order.metaData.pickingEndsBy)
                    : "-"
                );
                arr.push(trip.vehicle.number);
                arr.push(
                  trip.vehicle &&
                    trip.vehicle.number !== "" &&
                    trip.vehicle.shiftIn
                    ? formatTime(trip.vehicle.shiftIn)
                    : ""
                );
                arr.push(
                  trip.vehicle &&
                    trip.vehicle.number !== "" &&
                    trip.vehicle.shiftOut
                    ? formatTime(trip.vehicle.shiftOut)
                    : ""
                );
                arr.push(tripIndex);
                arr.push(
                  trip.startTime &&
                    moment.utc(trip.startTime).format("YYYY-MM-DD") !==
                      "0001-01-01"
                    ? formatIsoTime(trip.startTime)
                    : "-"
                );
                arr.push(
                  trip.endTime &&
                    moment.utc(trip.endTime).format("YYYY-MM-DD") !==
                      "0001-01-01"
                    ? formatIsoTime(trip.endTime)
                    : "-"
                );
                arr.push(orderIndex);
                arr.push(
                  order.metaData &&
                    order.metaData.eta &&
                    moment.utc(order.metaData.eta).format("YYYY-MM-DD") !==
                      "0001-01-01"
                    ? formatIsoTime(order.metaData.eta)
                    : "-"
                );
                arr.push(
                  order.metaData &&
                    order.metaData.waiting &&
                    moment.utc(order.metaData.waiting).format("YYYY-MM-DD") !==
                      "0001-01-01"
                    ? order.metaData.waiting
                    : "-"
                );
                arr.push(
                  order.metaData &&
                    order.metaData.completionTime &&
                    moment
                      .utc(order.metaData.completionTime)
                      .format("YYYY-MM-DD") !== "0001-01-01"
                    ? formatIsoTime(order.metaData.completionTime)
                    : "-"
                );
                dataArray.push(arr);
              });
          });
      });
    downloadCsv(dataArray || []);
  }

  render() {
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <div className="trip-summary-header">
          <h1>{getMessage("tripPlanner.table.heading")}</h1>
          {this.state.loading ? (
            <Loader />
          ) : (
            this.state.plannedTrip &&
            !!this.state.plannedTrip.length && (
              <button
                className="button primary"
                onClick={this.formatDataForCsvReady}
              >
                {getMessage("tripPlanner.download.summary.text")}
              </button>
            )
          )}
        </div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className="trip-table">
            <Table>
              <Header>
                <Cell>{getMessage("tripPlanner.orderNumber")}</Cell>
                <Cell>{getMessage("tripPlanner.status")}</Cell>
                <Cell>{getMessage("tripPlanner.items")}</Cell>
                <Cell>{getMessage("tripPlanner.orderPlacedDate")}</Cell>
                <Cell>{getMessage("tripPlanner.orderPlacedTime")}</Cell>
                {isExtensionEnabled("DeliverySlots") && (
                  <Cell>{getMessage("tripPlanner.slots.startTime")}</Cell>
                )}
                {isExtensionEnabled("DeliverySlots") && (
                  <Cell>{getMessage("tripPlanner.slot.endTime")}</Cell>
                )}
                <Cell>{getMessage("tripPlanner.order.lat")}</Cell>
                <Cell>{getMessage("tripPlanner.order.lng")}</Cell>
                <Cell>{getMessage("tripPlanner.order.distance")}</Cell>
                <Cell>{getMessage("tripPlanner.picker.name")}</Cell>
                <Cell>{getMessage("tripPlanner.picker.startTime")}</Cell>
                <Cell>{getMessage("tripPlanner.picker.endTime")}</Cell>
                <Cell>{getMessage("tripPlanner.vehicle")}</Cell>
                <Cell>{getMessage("tripPlanner.vehicle.shiftStart")}</Cell>
                <Cell>{getMessage("tripPlanner.vehicle.shiftEnd")}</Cell>
                <Cell>{getMessage("tripPlanner.tripNo")}</Cell>
                <Cell>{getMessage("tripPlanner.tripStart")}</Cell>
                <Cell>{getMessage("tripPlanner.tripEnd")}</Cell>
                <Cell>{getMessage("tripPlanner.orderSeq")}</Cell>
                <Cell>{getMessage("tripPlanner.arrival")}</Cell>
                <Cell>{getMessage("tripPlanner.waiting")}</Cell>
                <Cell>{getMessage("tripPlanner.complete")}</Cell>
              </Header>
              {this.state.plannedTrip &&
                this.state.plannedTrip.map((plannedTrip, plannedTripIndex) => {
                  return (
                    plannedTrip &&
                    plannedTrip.plannedTrips.map(
                      (trip, tripIndex) =>
                        trip &&
                        trip.orders.map((order, orderIndex) => (
                          <Row key={`row-${tripIndex}-${orderIndex}`}>
                            <Cell>
                              {order.clientId ? (
                                <span className="text-muted">#</span>
                              ) : (
                                <span className="text-muted">Z</span>
                              )}
                              {order.clientId ? order.clientId : order.id}
                            </Cell>
                            <Cell>{order.status}</Cell>
                            <Cell className="align-right">
                              {order.productCount}
                            </Cell>
                            <Cell>
                              {
                                getPrintableTime(
                                  getStandradDateTime(
                                    utcToLocalTime,
                                    order.createdAt
                                  )
                                ).split(",")[0]
                              }
                            </Cell>
                            <Cell>
                              {
                                getPrintableTime(
                                  getStandradDateTime(
                                    utcToLocalTime,
                                    order.createdAt
                                  )
                                ).split(",")[1]
                              }
                            </Cell>
                            {isExtensionEnabled("DeliverySlots") && (
                              <Cell>
                                {order.slot &&
                                  getPrintableTime(
                                    getStandradDateTime(
                                      utcToLocalTime,
                                      `${
                                        new Date().toISOString().split("T")[0]
                                      } ${order.slot.split("-")[0].trim()}`
                                    )
                                  ).split(",")[1]}
                              </Cell>
                            )}
                            {isExtensionEnabled("DeliverySlots") && (
                              <Cell>
                                {order.slot &&
                                  getPrintableTime(
                                    getStandradDateTime(
                                      utcToLocalTime,
                                      `${
                                        new Date().toISOString().split("T")[0]
                                      } ${order.slot.split("-")[1].trim()}`
                                    )
                                  ).split(",")[1]}
                              </Cell>
                            )}
                            <Cell>
                              {Number(order.address.location.lat).toFixed(2)}
                            </Cell>
                            <Cell>
                              {Number(order.address.location.long).toFixed(2)}
                            </Cell>
                            <Cell className="align-right">
                              {trip.sequence
                                ? (
                                    Number(trip.sequence[orderIndex].Distance) /
                                    1000
                                  ).toFixed(2)
                                : ""}
                            </Cell>
                            <Cell>{order.metaData.picker}</Cell>
                            <Cell>
                              {moment
                                .utc(order.metaData.pickingStartsBy)
                                .format("YYYY-MM-DD") !== "0001-01-01"
                                ? getPrintableTime(
                                    getStandradDateTime(
                                      utcToLocalTime,
                                      order.metaData.pickingStartsBy
                                    )
                                  ).split(",")[1]
                                : "-"}
                            </Cell>
                            <Cell>
                              {moment
                                .utc(order.metaData.pickingEndsBy)
                                .format("YYYY-MM-DD") !== "0001-01-01"
                                ? getPrintableTime(
                                    getStandradDateTime(
                                      utcToLocalTime,
                                      order.metaData.pickingEndsBy
                                    )
                                  ).split(",")[1]
                                : "-"}
                            </Cell>
                            <Cell>{trip.vehicle.number}</Cell>
                            <Cell>
                              {trip.vehicle &&
                              trip.vehicle.number !== "" &&
                              trip.vehicle.shiftIn
                                ? getPrintableTime(
                                    getStandradDateTime(
                                      utcToLocalTime,
                                      `${
                                        new Date().toISOString().split("T")[0]
                                      } ${trip.vehicle.shiftIn}`
                                    )
                                  ).split(",")[1]
                                : ""}
                            </Cell>
                            <Cell>
                              {trip.vehicle &&
                              trip.vehicle.number !== "" &&
                              trip.vehicle.shiftOut
                                ? getPrintableTime(
                                    getStandradDateTime(
                                      utcToLocalTime,
                                      `${
                                        new Date().toISOString().split("T")[0]
                                      } ${trip.vehicle.shiftOut}`
                                    )
                                  ).split(",")[1]
                                : ""}
                            </Cell>
                            <Cell className="align-right">{tripIndex}</Cell>
                            <Cell>
                              {trip.startTime &&
                              moment
                                .utc(trip.startTime)
                                .format("YYYY-MM-DD") !== "0001-01-01"
                                ? getPrintableTime(
                                    getStandradDateTime(
                                      utcToLocalTime,
                                      trip.startTime
                                    )
                                  ).split(",")[1]
                                : "-"}
                            </Cell>
                            <Cell>
                              {trip.endTime &&
                              moment.utc(trip.endTime).format("YYYY-MM-DD") !==
                                "0001-01-01"
                                ? getPrintableTime(
                                    getStandradDateTime(
                                      utcToLocalTime,
                                      trip.endTime
                                    )
                                  ).split(",")[1]
                                : "-"}
                            </Cell>
                            <Cell className="align-right">{orderIndex}</Cell>
                            <Cell>
                              {order.metaData &&
                              order.metaData.eta &&
                              moment
                                .utc(order.metaData.eta)
                                .format("YYYY-MM-DD") !== "0001-01-01"
                                ? getPrintableTime(
                                    getStandradDateTime(
                                      utcToLocalTime,
                                      order.metaData.eta
                                    )
                                  ).split(",")[1]
                                : "-"}
                            </Cell>
                            <Cell>
                              {order.metaData &&
                              order.metaData.waiting &&
                              moment
                                .utc(order.metaData.waiting)
                                .format("YYYY-MM-DD") !== "0001-01-01"
                                ? order.metaData.waiting
                                : "-"}
                            </Cell>
                            <Cell>
                              {order.metaData &&
                              order.metaData.completionTime &&
                              moment
                                .utc(order.metaData.completionTime)
                                .format("YYYY-MM-DD") !== "0001-01-01"
                                ? getPrintableTime(
                                    getStandradDateTime(
                                      utcToLocalTime,
                                      order.metaData.completionTime
                                    )
                                  ).split(",")[1]
                                : "-"}
                            </Cell>
                          </Row>
                        ))
                    )
                  );
                })}
            </Table>
          </div>
        )}
      </AuthenticatedPage>
    );
  }
}

export default TripPlannerTable;
