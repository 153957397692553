import React from "react";
import "./style.css";
import AuthenticatedPage from "../../../containers/AuthenticatedPage/index";
import AnalyticsForm from "./form";
import { getMessage } from "../../../lib/translator";

export default function Analytics(props) {
  return (
    <AuthenticatedPage
      className="Analytics"
      menu={props.menu}
      title={getMessage("analytics.heading")}
    >
      <h1 className="title">{getMessage("analytics.heading")}</h1>
      <AnalyticsForm />
    </AuthenticatedPage>
  );
}
