import React from "react";
import {
  BaseForm,
  Input,
  Upload,
  VALIDATION_TYPES,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import deleteIcon from "./delete.svg";
import "./style.css";

export default class BrandForm extends BaseForm {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }

  transformResponse(response) {
    return response.data ? response.data.brand : "";
  }

  delete() {
    this.props.onDelete();
  }

  changeStatus(status) {
    const { value } = this.props;
    if (value && value.id) {
      this.props.onSubmit({ id: value.id, status: status });
    }
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    const { props } = this;
    return (
      <div className="BrandForm">
        <Form>
          <Input
            label={getMessage("brand.form.name.heading")}
            placeholder={getMessage("brand.form.name.placeholder")}
            name="name"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <Input
            label={getMessage("brand.form.tagline.heading")}
            placeholder={getMessage("brand.form.tagline.placeholder")}
            name="tagline"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["tagLine"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Upload
            label={getMessage("brand.form.image.heading")}
            placeholder={getMessage("brand.form.image.placeholder")}
            {...this.generateStateMappers({
              stateKeys: ["image"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <div className={`form-actions ${props.method}`}>
            {props.method && props.method === "edit" && (
              <button
                className="delete-button"
                type="button"
                onClick={this.delete}
              >
                <img src={deleteIcon} alt="delete" />
                <span>{getMessage("brand.form.deleteText")}</span>
              </button>
            )}
            {props.method && props.method === "edit" && (
              <div className="hide-delete-buttons flex">
                {props.value && props.value.status === "ENABLED" ? (
                  <button
                    className="button"
                    type="button"
                    onClick={() => this.changeStatus("HIDDEN")}
                  >
                    {getMessage("brand.form.hideText")}
                  </button>
                ) : (
                  <button
                    className="button"
                    type="button"
                    onClick={() => this.changeStatus("ENABLED")}
                  >
                    {getMessage("brand.form.enableText")}
                  </button>
                )}
              </div>
            )}
            {(!props.method || props.method !== "edit") && (
              <CancelButton>{getMessage("brand.form.cancelText")}</CancelButton>
            )}
            <SubmitButton>{getMessage("brand.form.submitText")}</SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}
