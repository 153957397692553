import React, { Component } from "react";
import { BUGSNAG_KEY, GIT_HASH, GIT_TAG, IS_STAGING } from "../../config/app";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import ErrorPage from "../../pages/others/ErrorPage";
import { withRouter } from "react-router-dom";
import { getSession } from "../../lib/auth";
import { getDefaultStore } from "../../containers/StoreSelector";

let organization = getSession().organization || {};
organization = {
  name: organization.name,
  enterprise: organization.isEnterprise,
  id: organization.id,
};
let user = getSession().user || {};
user = {
  name: user.name,
  id: user.id,
};

function generateConfigs(error, info) {
  let releaseStage = IS_STAGING ? "staging" : "production";
  let configurations = {
    apiKey: BUGSNAG_KEY,
    autoNotify: false,
    user: user,
    autoCaptureSessions: false,
    releaseStage: releaseStage,
    maxEvents: 5,
    metaData: {
      info: info,
      organization: organization,
      hash: GIT_HASH,
      error: error,
      storeId: getDefaultStore().storeId,
    },
    beforeSend: (report) => {
      if (
        error === "cancelled" ||
        error === "Not Authorised" ||
        report.errorMessage === "Not Authorised" ||
        report.errorMessage === "cancelled" ||
        report.errorMessage === "API call cancelling" ||
        error === "Request failed with status code 401" ||
        error === "API call cancelling" ||
        report.errorMessage === "Request failed with status code 401"
      ) {
        return false;
      }
    },
  };
  if (GIT_TAG) {
    configurations.appVersion = GIT_TAG;
  }
  return configurations;
}

function notifyBugsnag(error, info = null) {
  if (!BUGSNAG_KEY) return;
  const bugsnagClient = bugsnag(generateConfigs(error, info));
  bugsnagClient.use(bugsnagReact, React);
  bugsnagClient.notify(new Error(error));
}

class DefaultErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    const { history } = props;
    history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          title={this.props.title}
          showHeader={this.props.showHeader}
        />
      );
    }
    return this.props.children;
  }
}

const bugsnagClient = BUGSNAG_KEY ? bugsnag(generateConfigs(null, null)) : null;
bugsnagClient && bugsnagClient.use(bugsnagReact, React);
if (bugsnagClient) {
  bugsnagClient.user = user;
  bugsnagClient.metaData = {
    organization: organization,
    hash: GIT_HASH,
    storeId: getDefaultStore().storeId,
  };
}
const ErrorBoundary = bugsnagClient
  ? bugsnagClient.getPlugin("react")
  : withRouter(DefaultErrorBoundary);
const errorBoundaryWrapper = (props) => {
  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <ErrorPage title={props.title} showHeader={props.showHeader} />
      )}
      {...props}
    />
  );
};
export default errorBoundaryWrapper;
export { notifyBugsnag };
