import React, { Component, useState, useEffect, Fragment } from "react";
import moment from "moment";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import { SingleDatePicker } from "../../../components/Form";
import Select from "../../../components/Form/Inputs/Select";
import { Dialog, Popup } from "../../../components/Popup";
import Loader from "../../../components/Loader";

import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";
import {
  canAccessReports,
  getStores,
  isERCOrComplete,
} from "../../../lib/auth";

import "./style.css";
import {
  downloadCSV,
  getRenderParams,
  isValid,
  createThirtyDaysTimeSpanHandler,
} from "./helpers";

const reportEndpoint = "/report-service/reports";

const dateRangeOptions = ["Day", "Week", "Month", "Custom"];

const DownloadReportForm = ({ handleClose, selectedReport, _this }) => {
  const [data, setData] = useState({});
  const [dateRangeType, setDateRangeType] = useState("Month");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDateRangeTypeChange = (e) => {
    setDateRangeType(e.target.value);
  };

  const handleSubmit = (key) => {
    setIsProcessing(true);
    const reportId = selectedReport?.id;
    const url = `${reportEndpoint}/${reportId}`;
    const params = Object.keys(data).reduce((acc, curr) => {
      acc[curr] = encodeURIComponent(data[curr]);
      return acc;
    }, {});
    params.requestType = key;
    const api = new API({ url });
    api
      .get(params)
      .then((resp) => {
        handleClose();
        if (key === "download") {
          downloadCSV(resp.data, selectedReport?.name);
        }
        if (key === "email") {
          _this.setState({
            formError: "",
            successMessage: resp.data,
            showSuccessDialog: true,
          });
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const createDataChangeHandler = (key) => (date) => {
    const newData = { ...data, [key]: date };
    setData(newData);
  };

  /* Recalculate the date-range  whenever different timespan is selected*/
  useEffect(() => {
    const { startDateConfig, endDateConfig } = getRenderParams(
      selectedReport?.params
    );

    if (startDateConfig && endDateConfig) {
      if (dateRangeType !== "Custom") {
        let numOfDays = 30;
        if (dateRangeType === "Day") {
          numOfDays = 1;
        } else if (dateRangeType === "Week") {
          numOfDays = 7;
        }

        const newDates = {
          startDate: moment().subtract(numOfDays, "d").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        };

        setData((data) => ({ ...data, ...newDates }));
      }
    }
  }, [dateRangeType, selectedReport]);

  // Reset endDate is selected span is more than 30 days
  useEffect(() => {
    if (data.startDate && data.endDate) {
      if (moment(data.startDate).diff(data.endDate, "day") < -30) {
        setData((data) => ({ ...data, endDate: undefined }));
      }
    }
  }, [data.startDate, data.endDate]);

  const allStores = getStores()
    ?.filter((store) => store.hasPicking || store.hasClickCollect)
    .map((store) => ({ text: store.name, value: store.id }));

  const { storeConfig, startDateConfig, endDateConfig, otherDateConfigs } =
    getRenderParams(selectedReport?.params);

  const canbeSubmitted = !isProcessing && isValid(data, selectedReport?.params);

  return (
    <Popup
      className="report-form"
      heading={getMessage("analytics.report.modal.title")}
      show
      close={handleClose}
    >
      {storeConfig && (
        <div>
          <label htmlFor="store" className="report-field-label">
            {getMessage("store.store.label")}
          </label>
          <Select
            placeholder={"Select store"}
            name={storeConfig.key}
            options={allStores}
            value={data[storeConfig.key] ? Number(data[storeConfig.key]) : ""}
            onChange={createDataChangeHandler(storeConfig.key)}
          />
        </div>
      )}
      {startDateConfig && endDateConfig && (
        <div>
          <span className="report-field-label">
            {getMessage("analytics.report.dateRange")}
          </span>
          <div className="date-range-radio">
            {dateRangeOptions.map((val) => (
              <div key={`radio-${val}`}>
                <input
                  type="radio"
                  name="dateRangeType"
                  id={`radio-${val}`}
                  value={val}
                  checked={val === dateRangeType}
                  onChange={handleDateRangeTypeChange}
                />
                <label htmlFor={`radio-${val}`}>{val}</label>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="date-selector">
        {startDateConfig && (
          <SingleDatePicker
            openDirection="down"
            className="date-range-field"
            enableToday
            isOutsideRange={(day) => moment().diff(day) < 0}
            label={startDateConfig.name}
            onChange={createDataChangeHandler(startDateConfig.key)}
            displayFormat={() => "MMM D YYYY"}
            value={data[startDateConfig.key]}
            disabled={dateRangeType !== "Custom"}
          />
        )}
        {startDateConfig && endDateConfig && (
          <span className="date-range-separator">-</span>
        )}
        {endDateConfig && (
          <SingleDatePicker
            openDirection="down"
            className="date-range-field"
            isOutsideRange={createThirtyDaysTimeSpanHandler(data.startDate)}
            customIsOutsideRange
            label={endDateConfig.name}
            onChange={createDataChangeHandler(endDateConfig.key)}
            displayFormat={() => "MMM D YYYY"}
            value={data[endDateConfig.key]}
            disabled={dateRangeType !== "Custom"}
          />
        )}
        {otherDateConfigs &&
          otherDateConfigs.map((param, index) => (
            <SingleDatePicker
              openDirection="down"
              enableToday
              isOutsideRange={(day) => moment().diff(day) < 0}
              label={param.name}
              onChange={createDataChangeHandler(param.key)}
              displayFormat={() => "MMM D YYYY"}
              value={data[param.key]}
              key={"other-date-" + index}
            />
          ))}
      </div>
      <div className="button-action">
        {selectedReport?.requestTypes?.map((requestTypes) => (
          <button
            className={requestTypes?.name + "-report-btn"}
            disabled={!canbeSubmitted}
            onClick={() => handleSubmit(requestTypes?.name)}
          >
            {getMessage(
              isProcessing
                ? "analytics.report.processing"
                : requestTypes === "download"
                ? "analytics.report.modal.title"
                : "analytics.report.send.email"
            )}
          </button>
        ))}
      </div>
    </Popup>
  );
};

class AnalyticsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  fetchReports = () => {
    this.setState({ loading: true });
    const api = new API({ url: reportEndpoint });

    api
      .get()
      .then((reports) => {
        const modifiedResp = Object.keys(reports.data).reduce((acc, curr) => {
          return [...acc, { ...reports.data[curr], id: curr }];
        }, []);
        this.setState({ reports: modifiedResp, loading: false });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleShowModal = (report) => {
    this.setState({ showModal: true, selectedReport: report });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  closeDialogs = () => {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    });
  };

  componentDidMount() {
    this.fetchReports();
  }

  render() {
    const {
      reports,
      showModal,
      selectedReport,
      loading,
      showSuccessDialog,
      showErrorDialog,
      formError,
      successMessage,
    } = this.state;

    const isERCorComplete = isERCOrComplete();
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        className="analytics-report-page"
      >
        <h1 className="title">{getMessage("analytics.reports.heading")}</h1>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            {showModal && (
              <DownloadReportForm
                handleClose={this.closeModal}
                selectedReport={selectedReport}
                _this={this}
              />
            )}
            <h3 className="section-heading">
              {getMessage("analytics.report.recent")}
            </h3>
            <div className="reports-card-conatiner">
              {reports?.map((report, index) => {
                const shouldRender =
                  (isERCorComplete && canAccessReports(report?.name)) ||
                  !isERCorComplete;

                if (shouldRender) {
                  return (
                    <div
                      className="report-card"
                      key={report.name + index}
                      onClick={() => this.handleShowModal(report)}
                    >
                      <span className="placeholder" />
                      <span>{report.name}</span>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <Dialog
              show={showSuccessDialog}
              title={getMessage("success.text.title")}
              className="success"
              information={successMessage}
              close={this.closeDialogs}
              closeText={getMessage("okay.text")}
            />
            <Dialog
              show={showErrorDialog}
              title={getMessage("error.generic")}
              information={getMessage(formError)}
              close={this.closeDialogs}
              closeText={getMessage("okay.text")}
            />
          </Fragment>
        )}
      </AuthenticatedPage>
    );
  }
}

export default AnalyticsReport;
