import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  Textarea,
} from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";
import API from "../../../../../lib/api";

class MailForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.values = {
      subject: "",
      body: "",
      submitting: false,
      formError: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    const api = new API({ url: "/communication-service/email" });
    let params = {};
    params["to"] = this.props.to;
    params["transactional"] = false;
    params["type"] = "no-template";
    let dataToSend = {};
    dataToSend["subject"] = data["subject"];
    dataToSend["body"] = data["body"];
    params["data"] = dataToSend;
    return api
      .post(params)
      .then(
        (response) => {
          this.setState({ formError: "" });
        },
        (error) => {
          this.setState({ formError: error.message });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleSubmit(data)
      .then(() => {
        this.setState({ submitting: false });
        if (this.state.formError === "") {
          this.props.onSuccess("render");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div className="MailForm">
        <Form>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <Input
            label={getMessage("customer.details.mail.subject")}
            type="text"
            name="subject"
            required
            {...this.generateStateMappers({
              stateKeys: ["subject"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <Textarea
            label={getMessage("customer.details.mail.body")}
            type="text"
            name="body"
            className="mail"
            required
            {...this.generateStateMappers({
              stateKeys: ["body"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <div className="form-buttons-container">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage("customer.details.mail.submitText.send")}
            </SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}
export default MailForm;
