import React from "react";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { BaseForm, Input, VALIDATION_TYPES } from "../../../components/Form";
import Table, { Header } from "../../../components/Table";
import CustomerTableView from "../../operations/Customers/Tables";
import OrderContainer from "../../operations/Orders/Table";
import Loader from "../../../components/Loader";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";

import "./style.css";

class CsDashboard extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      orderNumber: null,
      orderSearch: false,
      isApiCalled: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    // Check if the form is empty
    let emptyForm =
      this.state.values &&
      Object.values(this.state.values).filter((value) => Boolean(value));
    emptyForm = emptyForm ? emptyForm.length === 0 : true;

    if (!emptyForm) {
      this.setState({ submitting: true, isApiCalled: false });
      let orderSearch = Boolean(this.state.values.referenceNumber);
      if (orderSearch) {
        this.setState({
          orderSearch: true,
          orderNumber: this.state.values.referenceNumber,
          customer: null,
          submitting: false,
          isApiCalled: false,
        });
        return;
      }
      const api = new API({ url: "/customer-service/customer" });
      const params = {
        email: this.state.values.email,
        phone: this.state.values.phone,
      };
      api
        .get(params, { "X-API-VERSION": 2 })
        .then((response) => {
          if (response.data) {
            if (response.data.customer) {
              this.setState({
                customer: response.data.customer,
                orderSearch: false,
                orderNumber: null,
                submitting: false,
                isApiCalled: true,
              });
            } else {
              this.setState({
                customer: null,
                orderSearch: false,
                orderNumber: null,
                submitting: false,
                isApiCalled: true,
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.setState({
        customer: null,
        orderSearch: false,
        orderNumber: null,
        submitting: false,
        isApiCalled: false,
      });
    }
  }

  _getRow = (data) => {
    let result = {
      data: null,
      index: -1,
    };
    if (!data) {
      return result;
    }
    let { customer } = this.state;
    for (let i = 0, len = customer.length; i < len; i++) {
      let item = customer[i];
      let matched = true;
      for (let key in data) {
        if (!(key in item) || item[key] !== data[key]) {
          matched = false;
          break;
        }
      }
      if (matched) {
        result.data = item;
        result.index = i;
        break;
      }
    }
    return result;
  };

  modifyResource = (params) => {
    // Make an API call to update the resource
    let api = new API({
      url: "/customer-service/customer/" + params.id,
    });
    return api
      .put(params, { "X-API-VERSION": 2 })
      .then((response) => response)
      .catch((error) => {
        console.error(error);
      });
  };

  performAction = (action, data, updates) => {
    // action: (string) Action to perform
    // data: The data needed to search a specific a row
    // updates: The data to update the row with
    if (action in TABLE_ACTIONS) {
      switch (action) {
        case TABLE_ACTIONS.UPDATE:
          let row = this._getRow(data);
          if (row.index >= 0 && updates) {
            let params = Object.assign({}, data, updates);
            return this.modifyResource(params);
          }
          break;
        default:
          break;
      }
    }
  };

  render() {
    const { rerender } = this.props;
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    let CustomerRow = CustomerTableView("customer-support", rerender).row;
    let componentProps = { ...this.props };
    componentProps.className = "cx-search-results";
    componentProps.url = "/customer-support";

    componentProps.emptyState = (props) => {
      return {
        message: "No Orders Matched",
      };
    };

    delete componentProps.menu;
    if (this.state.orderSearch) {
      componentProps.params = { referenceNumber: this.state.orderNumber };
      componentProps.updateApiParams = (prevParams, newParams) => {
        if (prevParams.referenceNumber !== newParams.referenceNumber) {
          return {
            shouldUpdate: true,
            params: {
              referenceNumber: newParams.referenceNumber,
            },
          };
        }
      };
    }
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <h1 className="title">Customer Support</h1>
        <Form className="customer-support-home">
          <div className="form-fields">
            <Input
              label={getMessage("customer.enterCustomerEmail")}
              placeholder={getMessage("customer.searchByEmail")}
              name="email"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["email"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
            <Input
              label={getMessage("customer.enterCustomerPhone")}
              placeholder={getMessage("customer.searchByPhone")}
              name="phone"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["phone"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
            <Input
              label={getMessage("order.filters.referenceNumber.heading")}
              placeholder={getMessage(
                "order.filters.referenceNumber.placeholder"
              )}
              name="referenceNumber"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["referenceNumber"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
          </div>
          <SubmitButton>
            {getMessage("customer.filters.submittext")}
          </SubmitButton>
        </Form>
        {this.state.submitting ? (
          <Loader />
        ) : (
          <div className="table-container">
            {this.state.customer ? (
              this.state.customer.length > 0 ? (
                <div className="customers-page">
                  <Table tableDynamic>
                    {CustomerTableView("customer-support").headers ? (
                      <Header
                        items={CustomerTableView("customer-support").headers}
                      />
                    ) : null}
                    {this.state.customer.map((row, index) => (
                      <CustomerRow
                        {...row}
                        onAction={this.performAction}
                        key={"cs-cumtomer" + index}
                      />
                    ))}
                  </Table>
                </div>
              ) : (
                <div className="text-muted text-center">
                  {getMessage("customerSupport.search.noCustomer")}
                </div>
              )
            ) : this.state.isApiCalled ? (
              <div className="text-muted text-center">
                {getMessage("customerSupport.search.noCustomer")}
              </div>
            ) : null}
            {this.state.orderSearch ? (
              <OrderContainer {...componentProps} />
            ) : null}
          </div>
        )}
      </AuthenticatedPage>
    );
  }
}

export default CsDashboard;
