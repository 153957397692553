import React, { Component } from "react";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import ChangePasswordForm from "./form";
import { getMessage } from "../../../lib/translator";
import "./style.css";

export default class ChangePassword extends Component {
  render() {
    return (
      <AuthenticatedPage className="ChangePassword" menu={this.props.menu}>
        <h1 className="heading">{getMessage("changePassword.heading")}</h1>
        <ChangePasswordForm />
      </AuthenticatedPage>
    );
  }
}
