import React from "react";
import { getSession } from "../../lib/auth";
import {
  getPrintableTime,
  getStandradDateTime,
  utcToLocalTime,
} from "../../lib/datetime";
import { getMessage } from "../../lib/translator";

export const DunzoDetails = (props) => {
  const { data } = props;
  const { shippingCost, deliveryEndEstimate, pickupEstimate } = data;
  const organization = (getSession() || {}).organization || {};
  return (
    <div className="dunzo-details">
      <div className="top-container">
        <p>{getMessage("order.table.courierCharge")}</p>
        <p>
          {getMessage("order.courier.dispatch.table.header.pickup.estimate")}
        </p>
        <p>
          {getMessage("order.courier.dispatch.table.header.delivery.estimate")}
        </p>
      </div>
      <div className="bottom-container">
        <p>
          {((organization.currency && organization.currency.symbol) || "") +
            shippingCost.toFixed(2)}
        </p>
        {pickupEstimate && (
          <div>
            <p>
              {getPrintableTime(
                getStandradDateTime(utcToLocalTime, pickupEstimate)
              )}
            </p>
            <p>
              {
                getPrintableTime(
                  getStandradDateTime(utcToLocalTime, pickupEstimate)
                ).split(",")[1]
              }
            </p>
          </div>
        )}
        {deliveryEndEstimate && (
          <div>
            <p>
              {getPrintableTime(
                getStandradDateTime(utcToLocalTime, deliveryEndEstimate)
              )}
            </p>
            <p>
              {
                getPrintableTime(
                  getStandradDateTime(utcToLocalTime, deliveryEndEstimate)
                )[1]
              }
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
