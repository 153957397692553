import React, { Component } from "react";
import API from "../../../../lib/api";
import Loader from "../../../../components/Loader";
import EmptyState from "../../../../components/EmptyState";
import { getMessage } from "../../../../lib/translator";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import InstoreSettings from "./InstoreSettings";
import AnalyticsForm from "./AnalyticsForm";
import TawkChatSettings from "./TawkChatSetting";
import EntityMetaData from "./EntityMetaData";
import LanguageSelect from "./LanguageConfig";
import PasswordPolicySupport from "./PasswordPolicySupport";
import { getExtensionDetails } from "../../../../lib/auth";

class MissingDetails extends Component {
  render() {
    const emptyState = {
      message: getMessage("extension.details.notfound"),
    };

    return <EmptyState {...emptyState} />;
  }
}

export const slugComponentDetail = {
  InStoreProcessing: InstoreSettings,
  Analytics: AnalyticsForm,
  TawkToLiveChat: TawkChatSettings,
  EntityMetaData: EntityMetaData,
  MultiLingualSupport: LanguageSelect,
  PasswordPolicySupport: PasswordPolicySupport,
};
export default class ExtensionDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      data: null,
    };
    this.state.showLoader = false;
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    let metaDataExtension = getExtensionDetails("EntityMetaData");

    const id = this.props.match.params.id;
    const api = new API({ url: `/account-service/extension/${id}` });
    if (
      metaDataExtension &&
      Number(metaDataExtension.id) === Number(this.props.match.params.id)
    ) {
      this.setState(
        {
          data: {
            id: Number(this.props.match.params.id),
            slug: metaDataExtension.slug,
          },
        },
        () => {
          this.setState({ showLoader: false });
        }
      );
      return;
    }
    api
      .get()
      .then(
        async (response) => {
          const data = response.data.extension;
          const slug = data && data.slug;

          // Hardcoding only for analytics extn as it is an exception
          if (slug === "Analytics") {
            const configApi = new API({
              url: "/config-service/config/analytics",
            });
            const gaData = await configApi.get();
            data.config.analytics = gaData.data.analytics;
          }

          this.setState({
            data,
            showLoader: false,
          });
        },
        () => {
          this.setState({ showLoader: false });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { data, showLoader } = this.state;
    let slug = data ? data.slug : null;
    let value = data ? data.config : null;
    let Component = slugComponentDetail[slug] || MissingDetails;
    return (
      <AuthenticatedPage className="ExtensionForms" menu={this.props.menu}>
        {showLoader ? (
          <Loader />
        ) : (
          <Component
            data={data}
            value={value}
            {...this.props}
            onCancel={this.handleClose}
          />
        )}
      </AuthenticatedPage>
    );
  }
}
