import React from "react";
import { BaseForm, Select } from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";

export default class VehicleReplaceForm extends BaseForm {
  render() {
    let { vehicles, availableVehicles } = this.props;
    let { Form } = this.components;
    let { SubmitButton } = this.buttons;
    return (
      <div className="vehicle-replace-form">
        <Form>
          <div className="form-field">
            <Select
              name="old-vehicle"
              label={getMessage(
                "vehiclePlanner.replaceVehicle.form.replaced.label"
              )}
              placeholder={getMessage(
                "vehiclePlanner.replaceVehicle.form.replaced.placeHolder"
              )}
              options={vehicles.map((vehicle) => ({
                value: vehicle.vehicleId,
                text: vehicle.number,
              }))}
              {...this.generateStateMappers({
                stateKeys: ["vehicleId"],
                loseEmphasisOnFill: true,
              })}
              required
            />
          </div>
          <div className="form-field">
            <Select
              name="new-vehicle"
              label={getMessage(
                "vehiclePlanner.replaceVehicle.form.replacedBy.label"
              )}
              placeholder={getMessage(
                "vehiclePlanner.replaceVehicle.form.replacedBy.placeHolder"
              )}
              options={availableVehicles.map((vehicle) => ({
                value: vehicle.id,
                text: vehicle.number,
              }))}
              {...this.generateStateMappers({
                stateKeys: ["replacedBy"],
                loseEmphasisOnFill: true,
              })}
              required
            />
          </div>
          <SubmitButton>Replace</SubmitButton>
        </Form>
      </div>
    );
  }
}
