import React, { Component } from "react";
import moment from "moment";
import Table, { Row, Header, Cell } from "../../../components/Table";
import { SingleDatePicker } from "../../../components/Form";
import { Link } from "react-router-dom";
import {
  getMinutes,
  formatTime,
  formatDate,
  getStandradDateTime,
  utcToLocalTime,
  getPrintableTime,
} from "../../../lib/datetime";
import { getMessage } from "../../../lib/translator";
import { get } from "../../../lib/storage";
import { isEqual } from "lodash";

class SlotOrderTable extends Component {
  constructor() {
    super();
    this.state = {
      total: {},
      fixedSlotColumn: [],
      asapSlotColumn: [],
    };
    this.generateStateFromProps = this.generateStateFromProps.bind(this);
  }

  generateStateFromProps() {
    const { asapSlots, fixedSlots, statuses } = this.props.data;
    let total = {};
    statuses &&
      statuses.map((status) => {
        total[status] = 0;
        return null;
      });

    let fixedSlotColumn =
      fixedSlots &&
      fixedSlots.map((object) => {
        let countOb = object.count;
        if (countOb) {
          Object.keys(countOb).map((status) => {
            total[status] += Number(countOb[status]);
            return null;
          });
        }
        return object.slot;
      });
    let asapSlotColumn =
      asapSlots &&
      asapSlots.map((object) => {
        let countOb = object.count;
        if (countOb) {
          Object.keys(countOb).map((status) => {
            total[status] += Number(countOb[status]);
            return null;
          });
        }
        return object.slot;
      });
    this.setState({ fixedSlotColumn, asapSlotColumn, total });
  }

  componentDidMount() {
    this.generateStateFromProps();
  }

  componentDidUpdate(prevProps, prevState) {
    let currData = this.props.data;
    let prevData = prevProps.data;
    if (
      !isEqual(currData.asapSlots, currData.asapSlots) ||
      !isEqual(prevData.asapSlots, currData.asapSlots)
    ) {
      this.generateStateFromProps();
    }
  }
  render() {
    const { asapSlots, fixedSlots, noSlots, statuses, totalOrders } =
      this.props.data;
    const { areOrdersScheduled, selectedDate, handleDateChange } = this.props;
    const { fixedSlotColumn, asapSlotColumn } = this.state;
    const today = moment().format("YYYY-MM-DD");
    return (
      <div className="order-stats">
        <div className="header-container">
          <div className="left">
            <div className="heading">
              {`Orders ${
                selectedDate === today
                  ? "Today"
                  : "On " + formatDate(selectedDate)
              }`}
              <span className="orders-count">({totalOrders})</span>
            </div>
          </div>
          <div className="right">
            <div className="date-select">
              <SingleDatePicker
                allowAllDates={areOrdersScheduled ? true : undefined}
                isOutsideRange={!areOrdersScheduled ? true : undefined}
                displayFormat="YYYY-MM-DD"
                value={selectedDate}
                openDirection="up"
                onChange={handleDateChange}
              />
            </div>
          </div>
        </div>
        {statuses && statuses.length > 0 && (
          <div className="order-table-container">
            <Table className="order-table">
              <Header>
                <Cell className="first-column-header">
                  {noSlots
                    ? getMessage("order.filters.status.heading")
                    : getMessage("operations.dashboard.slot")}
                </Cell>
                {statuses &&
                  statuses.map((status, index) => (
                    <Cell key={`status-${index}`}>
                      <Link
                        to={`/operations/orders?storeId=${get(
                          "store"
                        )}&status=${status.toUpperCase()}`}
                      >
                        {status.toUpperCase()}
                      </Link>
                    </Cell>
                  ))}
              </Header>
              {statuses && asapSlotColumn && asapSlotColumn.length > 0 && (
                <Row className="first-column">
                  {asapSlotColumn.map((slot, index) => (
                    <Row key={`asap-slot-name-${index}`}>
                      <Cell>
                        <Link
                          to={`/operations/orders?storeId=${get(
                            "store"
                          )}&slotType=ASAP&slotEndTime=${
                            slot.endTime
                          }&preferredDate=${selectedDate}`}
                        >
                          {getMessage("slots.asap") +
                            " " +
                            getMinutes(slot.endTime) +
                            " " +
                            getMessage("slots.asap.minute")}
                        </Link>
                      </Cell>
                    </Row>
                  ))}
                </Row>
              )}
              {statuses &&
                asapSlots &&
                asapSlots.map((slot, index) => (
                  <Row key={`asap-slot-${index}`}>
                    <Cell>
                      <Link
                        to={`/operations/orders?storeId=${get(
                          "store"
                        )}&slotType=ASAP&slotEndTime=${
                          slot.slot.endTime
                        }&preferredDate=${selectedDate}`}
                      >
                        {getMessage("slots.asap") +
                          " " +
                          getMinutes(slot.slot.endTime) +
                          " " +
                          getMessage("slots.asap.minute")}
                      </Link>
                    </Cell>
                    {statuses &&
                      statuses.map((status, i) => (
                        <Cell key={`asap-slot-${status}-${i}-${index}`}>
                          <Link
                            to={`/operations/orders?storeId=${get(
                              "store"
                            )}&slotType=ASAP&slotEndTime=${
                              slot.slot.endTime
                            }&status=${status.toUpperCase()}&preferredDate=${selectedDate}`}
                          >
                            {slot.count[status] || "-"}
                          </Link>
                        </Cell>
                      ))}
                  </Row>
                ))}
              {statuses && fixedSlotColumn && fixedSlotColumn.length > 0 && (
                <Row className="first-column">
                  {fixedSlotColumn.map((slot, index) => (
                    <Row key={`std-slot-name-${index}`}>
                      <Cell>
                        <Link
                          to={`/operations/orders?storeId=${get(
                            "store"
                          )}&slotType=STANDARD&slotStartTime=${
                            slot.startTime
                          }&slotEndTime=${
                            slot.endTime
                          }&preferredDate=${selectedDate}`}
                        >
                          {`${
                            getPrintableTime(
                              getStandradDateTime(
                                utcToLocalTime,
                                `${new Date().toISOString().split("T")[0]} ${
                                  slot.startTime
                                }`
                              )
                            ).split(",")[1]
                          } - ${
                            getPrintableTime(
                              getStandradDateTime(
                                utcToLocalTime,
                                `${new Date().toISOString().split("T")[0]} ${
                                  slot.endTime
                                }`
                              )
                            ).split(",")[1]
                          }`}
                        </Link>
                      </Cell>
                    </Row>
                  ))}
                </Row>
              )}
              {statuses &&
                fixedSlots &&
                fixedSlots.map((slot, index) => (
                  <Row key={`std-slot-${index}`}>
                    <Cell>
                      <Link
                        to={`/operations/orders?storeId=${get(
                          "store"
                        )}&slotType=STANDARD&slotStartTime=${
                          slot.slot.startTime
                        }&slotEndTime=${
                          slot.slot.endTime
                        }&preferredDate=${selectedDate}`}
                      >{`${formatTime(slot.slot.startTime)} - ${formatTime(
                        slot.slot.endTime
                      )}`}</Link>
                    </Cell>
                    {statuses &&
                      statuses.map((status, i) => (
                        <Cell key={`std-slot-${status}-${index}-${i}`}>
                          <Link
                            to={`/operations/orders?storeId=${get(
                              "store"
                            )}&slotType=STANDARD&slotStartTime=${
                              slot.slot.startTime
                            }&slotEndTime=${
                              slot.slot.endTime
                            }&status=${status.toUpperCase()}&preferredDate=${selectedDate}`}
                          >
                            {slot.count[status] || "-"}
                          </Link>
                        </Cell>
                      ))}
                  </Row>
                ))}
              {noSlots && (
                <Row className="first-column">
                  <Cell>{getMessage("operations.dashboard.counts")}</Cell>
                </Row>
              )}
              {noSlots && (
                <Row>
                  <Cell>{getMessage("operations.dashboard.counts")}</Cell>
                  {noSlots &&
                    statuses.map((status, index) => (
                      <Cell key={`noslots-${index}`}>
                        <Link
                          to={`/operations/orders?storeId=${get(
                            "store"
                          )}&status=${status}&createdAt=${selectedDate}`}
                        >
                          {noSlots.count[status]}
                        </Link>
                      </Cell>
                    ))}
                </Row>
              )}
              {areOrdersScheduled && statuses && (
                <Row>
                  <Cell>{getMessage("operations.dashboard.total")}</Cell>
                  {statuses.map((status, index) => {
                    return (
                      <Cell key={`status-total-${index}`}>
                        {this.state.total[status]}
                      </Cell>
                    );
                  })}
                </Row>
              )}
            </Table>
          </div>
        )}
      </div>
    );
  }
}

export default SlotOrderTable;
