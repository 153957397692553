import React, { Component } from "react";
import moment from "moment";
import "./style.css";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import { getMessage } from "../../../lib/translator";
import API from "../../../lib/api";
import Loader from "../../../components/Loader";
import { Radio } from "../../../components/Form";
import {
  getMonthStartEnd,
  getPrevMonth,
  getNextMonth,
  getDateInSQL,
} from "../../../lib/datetime";
import BigCalendar from "../../../components/BigCalendar";
import ImagePlaceHolder from "./customer-image-placeholder.svg";
import LeftArrow from "./left-arrow.svg";
import RightArrow from "./right-arrow.svg";
import SearchIcon from "./search.svg";
import { Header } from "../../../components/Table";

class MonthEvent extends Component {
  componentDidMount() {
    let eventElement = document.querySelector(".rbc-event");

    if (eventElement) {
      eventElement.style.marginLeft = "1.375rem";
    }
    let eventImage = document.querySelector(".rbc-event .custom-event img");

    if (eventImage) {
      eventImage.style.display = "inline-block";
    }
  }
  render() {
    return (
      <div className="custom-event">
        <div className="user-image-url">
          <img src={this.props.event.userImageUrl} alt="user" />
        </div>
        <div className="event-name">{this.props.event.userName}</div>
      </div>
    );
  }
}

function generateOptions(data) {
  let options = [
    {
      text: getMessage("user.leave-summary.status.All"),
      value: "ALL",
    },
  ];

  data.map((item) => {
    options.push({
      text: getMessage(
        item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
      ),
      value: item,
    });
    return options;
  });
  return options;
}

class LeaveSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      options: [],
      selectedUser: null,
      selectedLeaveType: null,
      showLoader: true,
      from: getMonthStartEnd()[0],
      to: getMonthStartEnd()[1],
      selectedStatus: "ALL",
      active: "",
    };

    // We'll be having a static copy of events and will be used during reset of filters.
    // We'll update the value during API call
    this.eventBackup = [];
    this.leaveTypes = ["EARNED", "SICK", "PATERNITY", "MATERNITY", "HALFDAY"];
    this.filterByUser = this.filterByUser.bind(this);
    this.filterByType = this.filterByType.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.getCalendar = this.getCalendar.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.onClickToday = this.onClickToday.bind(this);
    this.toggleActiveClass = this.toggleActiveClass.bind(this);
  }

  toggleActiveClass() {
    if (this.state.active === "active") {
      this.setState(
        {
          active: "",
          selectedLeaveType: null,
        },
        () => this.filterByType("ALL")
      );
    } else {
      this.setState({
        active: "active",
      });
    }
  }

  onClickToday() {
    this.setState(
      {
        from: getMonthStartEnd()[0],
        to: getMonthStartEnd()[1],
      },
      () => this.getCalendar()
    );
  }

  getCalendar() {
    const api = new API({ url: "/account-service/leave-summary" });
    const { from, to } = this.state;
    api
      .get({
        from,
        to,
        team: true,
      })
      .then((response) => {
        const events = response.data.leavesummary;
        // Have a copy of events and will be using it during reseting filters
        this.eventBackup = events;
        this.setState(
          {
            events,
            showLoader: false,
          },
          () => this.filterByType(this.state.selectedStatus)
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidMount() {
    this.getCalendar();
    let options = generateOptions(this.leaveTypes);
    if (options && options.length > 1) {
      this.setState({
        options: options,
      });
    }
  }

  filterByUser({ userId }) {
    const { selectedUser, events } = this.state;
    if (selectedUser === null) {
      this.setState({
        events: events.filter((v) => v.user.id === userId),
        selectedUser: userId,
      });
    } else {
      this.setState({
        events: this.eventBackup,
        selectedUser: null,
      });
    }
  }

  filterByType(type) {
    if (type !== "ALL") {
      this.setState({
        events: this.eventBackup.filter((v) => v.type === type),
        selectedLeaveType: type,
        selectedStatus: type,
      });
    } else {
      this.setState({
        events: this.eventBackup,
        selectedLeaveType: null,
        selectedStatus: type,
      });
    }
  }

  clearFilters() {
    this.setState({
      events: this.eventBackup,
      selectedUser: null,
      selectedLeaveType: null,
    });
  }

  handleNext() {
    const newDate = getDateInSQL(getNextMonth(new Date(this.state.from)));
    this.setState(
      {
        from: getMonthStartEnd(newDate)[0],
        to: getMonthStartEnd(newDate)[1],
      },
      () => this.getCalendar()
    );
  }

  handlePrev() {
    const newDate = getDateInSQL(getPrevMonth(new Date(this.state.from)));
    this.setState(
      {
        from: getMonthStartEnd(newDate)[0],
        to: getMonthStartEnd(newDate)[1],
      },
      () => this.getCalendar()
    );
  }

  render() {
    let displayMonth = moment(this.state.from).format("MMMM");
    let displayYear = moment(this.state.from).format("YYYY");
    let { events, showLoader, from } = this.state;
    // This we need because calendar accessor accepts only below keys
    if (events) {
      events = events.map((d) => {
        return {
          eventId: d.id,
          fromDate: d.fromDate,
          toDate: d.toDate,
          title: d.reason,
          type: d.type,
          userName: d.user.name,
          userId: d.user.id,
          userImageUrl: d.user.imageUrl || ImagePlaceHolder,
        };
      });
    }
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        className="leave-summary-page"
        title={"Leave Summary"}
      >
        <Header>
          <div className="main-heading">
            <h1 className="title">
              {getMessage("user.leave-summary.heading")}
            </h1>
            <button onClick={this.toggleActiveClass}>
              <img src={SearchIcon} alt="search-icon" />
            </button>
          </div>
        </Header>

        {showLoader ? (
          <Loader />
        ) : (
          <div>
            <Radio
              label={getMessage("user.leave-summary.status.heading")}
              name={this.state.selectedStatus}
              className={`status-selector ${this.state.active}`}
              options={this.state.options}
              onChange={(value) => this.filterByType(value)}
              value={this.state.selectedStatus}
            />
            <div className="toolbar">
              <div className="selector-wrapper">
                <ul>
                  {this.leaveTypes.map((type) => (
                    <li key={type} className={type}>
                      {type.toLocaleLowerCase()}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="caption">{displayMonth + " " + displayYear}</div>
              <div className="button-group">
                <div className="today button" onClick={this.onClickToday}>
                  Today
                </div>
                <div
                  className="primary button previous"
                  onClick={this.handlePrev}
                >
                  <img src={LeftArrow} alt="left-arrow" />
                </div>
                <div className="primary button next" onClick={this.handleNext}>
                  <img src={RightArrow} alt="right-arrow" />{" "}
                </div>
              </div>
            </div>

            <BigCalendar
              date={new Date(from)}
              events={events}
              accessors={{
                startAccessor: "fromDate",
                endAccessor: "toDate",
                titleAccessor: "userName",
              }}
              monthEvent={MonthEvent}
              onSelectEvent={this.filterByUser}
              eventPropGetter={(event) => {
                return {
                  style: {
                    backgroundColor: (() => {
                      switch (event.type) {
                        case "SICK":
                          return "#eb8181";
                        case "PATERNITY":
                          return "#f8ac59";
                        case "MATERNITY":
                          return "#80959d";
                        case "EARNED":
                          return "#1ec05c";
                        case "HALFDAY":
                          return "#45c0ff";
                        default:
                          return "#2A92CA";
                      }
                    })(),
                  },
                };
              }}
            />
          </div>
        )}
      </AuthenticatedPage>
    );
  }
}

export default LeaveSummary;
