import React from "react";
import { Popup } from "../../../../components/Popup";

const PDFViewer = ({ src, show, hideInvoice }) => {
  return (
    <Popup className="editPopup invoice-popup" show={show} close={hideInvoice}>
      <iframe
        name="printer_frame"
        id="printer_frame"
        src={src}
        title="PdfViewer"
        type="application/pdf"
        width="100%"
        height="700px"
      />
    </Popup>
  );
};

export default PDFViewer;
