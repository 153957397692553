import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./style.css";
import calenderIcon from "./calender-icon.svg";

export default class DateRangePickerWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
    };
  }

  render() {
    const { focusedInput } = this.state;
    let {
      startDate,
      endDate,
      displayFormat,
      transForm,
      required,
      onDatesChange,
      startDatePlaceholder,
      endDatePlaceholder,
      minimumNights,
      isOutsideRange,
      openDirection,
      readOnly,
    } = this.props;
    const smallDevice = window.matchMedia("(max-width: 600px)").matches;
    const orientation = smallDevice ? "vertical" : "horizontal";

    startDate = (startDate && moment(startDate, transForm)) || null;
    endDate = (endDate && moment(endDate, transForm)) || null;
    return (
      <div className="date-picker">
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDatesChange={({ startDate, endDate }) =>
            onDatesChange(
              startDate && moment(startDate).format(transForm),
              endDate && moment(endDate).format(transForm)
            )
          }
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => {
            this.setState({ focusedInput });
          }}
          startDateId="startDate"
          endDateId="endDate"
          displayFormat={displayFormat}
          required={required}
          startDatePlaceholderText={startDatePlaceholder}
          endDatePlaceholderText={endDatePlaceholder}
          customInputIcon={<img src={calenderIcon} alt="" />}
          orientation={orientation}
          minimumNights={minimumNights}
          isOutsideRange={isOutsideRange}
          openDirection={openDirection}
          readOnly={readOnly}
          disabled={readOnly}
        />
      </div>
    );
  }
}
DateRangePickerWrapper.defaultProps = {
  displayFormat: "YYYY-MM-DD",
  className: "",
  transForm: "YYYY-MM-DD",
  minimumNights: 1,
};
