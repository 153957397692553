import React, { Component } from "react";
import { EditorState, RichUtils } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import { Editor } from "react-draft-wysiwyg";
import { Popup } from "../Popup";
import SourceCodeIcon from "./SourceCodeIcon";
export default class RichEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHtmlMode: false,
      rawHtml: "",
    };

    this.interceptProps(props);
    this.onChange = this.onChange.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
    this.toggleBlockType = this.toggleBlockType.bind(this);
    this.toggleHtmlMode = this.toggleHtmlMode.bind(this);
    this.handleHtmlChange = this.handleHtmlChange.bind(this);
    this.applyHtml = this.applyHtml.bind(this);
    this.cancelHtmlEdit = this.cancelHtmlEdit.bind(this);
  }

  toggleBlockType(style) {
    this.onChange(RichUtils.toggleBlockType(this.props.value, style));
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.props.onChange(newState);
      return "handled";
    }
    return "not-handled";
  }

  toggleInlineStyle(style, e) {
    e.preventDefault();
    this.props.onChange(RichUtils.toggleInlineStyle(this.props.value, style));
  }

  interceptProps(props) {
    if (!(props.value instanceof EditorState)) {
      props.onChange(importText(props.value));
    }
  }

  componentWillReceiveProps(newProps) {
    this.interceptProps(newProps);
  }

  onChange(editorState) {
    this.props.onChange(editorState);
  }

  toggleHtmlMode(e) {
    e.preventDefault();
    this.setState((prevState) => {
      const newState = !prevState.isHtmlMode;
      if (!newState) {
        // Switching from HTML to Rich Text
        this.props.onChange(
          EditorState.createWithContent(stateFromHTML(prevState.rawHtml))
        );
      }
      return {
        isHtmlMode: newState,
        rawHtml: newState
          ? stateToHTML(this.props.value.getCurrentContent())
          : prevState.rawHtml,
      };
    });
  }

  handleHtmlChange(event) {
    this.setState({ rawHtml: event.target.value });
  }

  applyHtml(e) {
    e.preventDefault();
    this.props.onChange(
      EditorState.createWithContent(stateFromHTML(this.state.rawHtml))
    );
    this.setState({ isHtmlMode: false });
  }

  cancelHtmlEdit(e) {
    e.preventDefault();
    this.setState({ isHtmlMode: false });
  }

  render() {
    let { props } = this;
    if (!(props.value instanceof EditorState)) {
      return null;
    }

    let className = "RichEditor-editor";
    var contentState = this.props.value.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }

    return (
      <div className="RichEditor">
        {this.state.isHtmlMode ? (
          <Popup show heading={"Source Code"} className="Source-Code-Popup">
            <div>
              <textarea
                value={this.state.rawHtml}
                onChange={this.handleHtmlChange}
                className="Source-Code-textArea"
              />
              <div className="Source-Code-button-container">
                <button className="primary button" onClick={this.applyHtml}>
                  Ok
                </button>
                <button
                  className="button"
                  type="button"
                  onClick={this.cancelHtmlEdit}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Popup>
        ) : (
          <div className={className}>
            <Editor
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "colorPicker",
                  "link",
                  "embedded",
                  "image",
                  "remove",
                  "history",
                ],
              }}
              placeholder={props.placeholder}
              editorState={props.value}
              onEditorStateChange={this.onChange}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.mapKeyToEditorCommand}
              customStyleMap={{
                STRIKETHROUGH: {
                  textDecoration: "line-through",
                },
              }}
              toolbarCustomButtons={[
                <div className="Source-Code-Html-button">
                  <SourceCodeIcon onClick={this.toggleHtmlMode}>
                    Source Code
                  </SourceCodeIcon>
                </div>,
              ]}
            />
          </div>
        )}
      </div>
    );
  }
}

export function importHTML(value) {
  return stateFromHTML(value);
}

export function exportHTML(editorState) {
  return stateToHTML(editorState.getCurrentContent());
}

export function importText(value) {
  if (!value) {
    return EditorState.createEmpty();
  } else if (typeof value === "string") {
    return EditorState.createWithContent(importHTML(value));
  }
  return value;
}

export function exportText(editorState) {
  const content = editorState?.getCurrentContent();
  if (!content.hasText()) {
    return "";
  } else {
    return stateToHTML(content);
  }
}
