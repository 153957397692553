import React, { Component } from "react";
import {
  ProductSearch,
  Input,
  Select,
  BrandSearch,
  CategorySearch,
} from "../../../../components/Form";
import ItemTable from "./ItemTable";

import { getMessage } from "../../../../lib/translator";

class BANYATP extends Component {
  constructor(props) {
    super(props);
    this.submitHandler = this.submitHandler.bind(this);
    this.validationHandler = this.validationHandler.bind(this);
  }

  validationHandler(parent) {
    let validations = JSON.parse(JSON.stringify(parent.state.validations));
    parent.setState({
      validations,
    });
  }

  submitHandler(formData) {
    let data = JSON.parse(JSON.stringify(formData));
    const entityType = data.entityType.toLowerCase();
    let rule = data.rule || {};
    if (rule && rule.total) {
      rule.total.v = Number(rule.total.v);
    }
    if (rule.minAmount) {
      rule.minAmount = Number(rule.minAmount);
    }
    if (rule.minQuantity) {
      rule.minQuantity = Number(rule.minQuantity);
      delete rule.minQuantity;
    }
    rule["entity"] = {
      type: data.entityType === "PRODUCT" ? "VARIANT" : data.entityType,
      id: data.entityType === "PRODUCT" ? null : data[entityType].id, // TODO: change this for brands and category
    };
    rule.limit = Number(rule.limit);

    if (entityType === "product") {
      rule.variants = data.product.map((pdt) => pdt.id).filter(Boolean);
    }

    data.rule = rule;
    // data.entityId = 1
    data.offerType = data.type;
    delete data.pwpTagId;
    delete data.quantity;
    delete data.minQuantity;
    delete data.product;
    delete data.category;
    delete data.brand;
    delete data.entity;
    delete data.includes;
    delete data.itemDiscountType;
    return data;
  }
  render() {
    let { entityType, discountTypes, _this, type, hasOfferStarted } =
      this.props;
    const { values } = _this.state;
    const { itemDiscountType } = _this.state.values;
    entityType = this.props.entityTypeMap[entityType];
    const comboDiscount = itemDiscountType === "COMBO_DISCOUNT";
    const items = values[entityType];
    const itemsLength = items ? items.length : 0;
    return (
      <div className={`offers-form ${type}-offer`}>
        <div className="offer-fields">
          {entityType === "product" && (
            <ProductSearch
              required
              label={getMessage(`offer.${entityType}`)}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              name={`entityType-${entityType}-pdt`}
              key={`entityType-${entityType}-pdt`}
              className="product-searchable"
              onChange={_this.addItem}
              readOnly={hasOfferStarted}
            />
          )}
          {entityType === "category" && (
            <CategorySearch
              name="category-search"
              readOnly={hasOfferStarted}
              required
              label={`CATEGORY`}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              className="category-search"
              {..._this.generateStateMappers({
                stateKeys: [entityType],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {entityType === "brand" && (
            <BrandSearch
              name="brand-search"
              required
              readOnly={hasOfferStarted}
              label={`BRAND`}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              className="category-search"
              {..._this.generateStateMappers({
                stateKeys: [entityType],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <Input
            type="number"
            name="quantity"
            min={1}
            required
            readOnly={hasOfferStarted}
            label={getMessage("offer.quantity")}
            placeholder={getMessage("offer.quantity.placeholder")}
            {..._this.generateStateMappers({
              stateKeys: ["rule", "quantity"],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            name="discount-type"
            label={getMessage("offer.discounttype")}
            required
            disabled={hasOfferStarted}
            placeholder={getMessage("offer.discounttype.placeholder")}
            options={discountTypes}
            className="discount-type"
            {..._this.generateStateMappers({
              stateKeys: ["rule", "total", "t"],
              loseEmphasisOnFill: true,
            })}
            onChange={_this.handleDiscountChange}
            readOnly={hasOfferStarted}
          />
          <Input
            name="discount-value"
            type="number"
            step={0.01}
            min={0}
            max={
              values &&
              values.rule &&
              values.rule.total &&
              values.rule.total.t === "PERCENT_OFF"
                ? 100
                : Number.MAX_SAFE_INTEGER
            }
            required
            readOnly={hasOfferStarted}
            label={getMessage("offer.discountvalue")}
            className="discount-value"
            placeholder={getMessage("offer.discountvalue.placeholder")}
            {..._this.generateStateMappers({
              stateKeys: ["rule", "total", "v"],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <div>
          {itemsLength ? (
            <ItemTable
              comboDiscount={comboDiscount}
              entityType={entityType}
              items={items}
              discountTypes={discountTypes}
              _this={_this}
              buyAny
              hasOfferStarted={hasOfferStarted}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default BANYATP;
