import React, { Component } from "react";
import ListingPage from "../../../containers/ListingPage";
import { Row, Cell } from "../../../components/Table";
import { getMessage } from "../../../lib/translator";

const tableProperties = (currency) => {
  return {
    headers: [
      getMessage("operations.prodStats.statTable.name"),
      getMessage("operations.prodStats.statTable.qty"),
      getMessage("operations.prodStats.statTable.amt"),
    ],
    row: (props) => {
      const { productName, totalQuantity, totalAmount } = props;
      return (
        <Row>
          <Cell>{productName}</Cell>
          <Cell>{totalQuantity}</Cell>
          <Cell>
            <div> {currency?.currency}</div>
            {totalAmount}
          </Cell>
        </Row>
      );
    },
  };
};

export default class MarketPlaceProdStats extends Component {
  render() {
    const params = {
      sortBy: this.props.sortBy,
    };
    return (
      <ListingPage
        className="productStats-byQty"
        api={{
          url: "/order-service/product-stats",
          params: {
            perPage: 5,
            from: "2020-01-01",
            ...params,
          },
          transform: (response) => response.data?.productStats || [],
        }}
        tableProperties={tableProperties(this.props?.currency)}
      />
    );
  }
}
