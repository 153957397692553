import React from "react";

function FilledStar(props) {
  return (
    <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m6.548.367 1.376 2.994c.06.18.24.3.42.36l3.11.479c.539 0 .718.598.36.958L9.54 7.493c-.12.12-.18.36-.18.539l.539 3.234c.06.479-.42.898-.838.658l-2.812-1.557a.448.448 0 0 0-.538 0l-2.813 1.557c-.418.24-.957-.12-.837-.658l.538-3.234c.06-.18-.06-.42-.18-.539L.147 5.158c-.299-.36-.12-.958.36-1.018l3.11-.48c.18 0 .36-.179.42-.359L5.41.307c.3-.419.898-.419 1.137.06z"
        fill="#F1CB59"
      />
    </svg>
  );
}

export default FilledStar;
