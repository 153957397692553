import React from "react";
import { BaseForm, Input } from "../../../../../components/Form";
import MetadataFormFields from "../../../../../components/MetaDataFormFields";
import PlacesWithStandaloneSearchBox from "../../../../../components/StandaloneSearchBox";
import { getMessage } from "../../../../../lib/translator";
import API from "../../../../../lib/api";
import { isExtensionEnabled } from "../../../../../lib/auth";
import Loader from "../../../../../components/Loader";

class AddAddressForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      landmark: "",
      pincode: "",
      city: "",
      submitting: false,
      latitude: "",
      longitude: "",
      formError: "",
    };
    this.sequence = null;
    if (
      isExtensionEnabled("EntityMetaData") &&
      props.sequence &&
      props.sequence.length
    ) {
      this.sequence = props.sequence;
    }
    this.response = "";
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    if (isExtensionEnabled("EntityMetaData")) {
      // let id = getExtensionDetails('EntityMetaData').id
      this.api = new API({ url: `/config-service/meta-data` });
      this.sequenceApi = new API({ url: "/config-service/config/customers" });
      Promise.all([this.api.get(), !this.sequence && this.sequenceApi.get()])
        .then(([extnResponse, sequenceResponse]) => {
          const config = extnResponse.data.config.entityMetaData.address;
          this.sequence =
            (sequenceResponse &&
              sequenceResponse.data.customers.addressSequence) ||
            this.sequence;
          if (config && !config.length && this.sequence) {
            let sequenceConfig = {};
            this.sequence.forEach((key) => {
              sequenceConfig[key] = config[key];
            });
            this.customerConfig = sequenceConfig;
          } else {
            this.customerConfig = config;
          }
          this.setState({
            loading: false,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
  }

  handleSubmit(data) {
    const api = new API({ url: "/customer-service/address" });
    let params = { ...data.address };
    params["customerId"] = this.props.customerId;

    return api
      .post(params)
      .then(
        (response) => {
          this.setState({ formError: "" });
          this.response = response.data.address;
        },
        (error) => {
          this.setState({ formError: error.message });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(data) {
    this.setState(
      {
        submitting: true,
      },
      () => {
        this.handleSubmit(data).then(() => {
          this.setState({
            submitting: false,
          });
          if (this.state.formError === "") {
            this.props.onSuccess &&
              this.props.onSuccess("addedAddress", this.response);
          }
        });
      }
    );
  }

  handlePlaceSearch = async (data, form) => {
    if (data?.[0]) {
      this.setState({ formError: "" });
      const addressComponents = data[0].address_components;
      const pincode = addressComponents?.filter(({ types }) =>
        types?.includes("postal_code")
      )[0]?.short_name;
      const city = addressComponents?.filter(({ types }) =>
        types?.includes("locality")
      )[0]?.short_name;
      const landmark = data[0].formatted_address;
      const location = data[0].geometry.location;
      const latitude = location.lat();
      const longitude = location.lng();
      const serviceableAreaAPI = new API({
        url: `/logistics-service/serviceable-area`,
      });
      try {
        const params = {
          "address[latitude]": latitude,
          "address[longitude]": longitude,
          "address[pincode]": pincode ?? "",
        };
        form.updateState(["address", "landmark"], landmark);
        const serviceableAreaResponse = await serviceableAreaAPI.get(params);
        if (serviceableAreaResponse.data) {
          form.updateState(["address", "city"], city);
          form.updateState(["address", "latitude"], latitude);
          form.updateState(["address", "longitude"], longitude);
          if (pincode) {
            form.updateState(["address", "pincode"], pincode);
          }
        } else {
          this.setState({
            formError: getMessage(
              "customer.details.page.address.notServiceable.error"
            ),
          });
        }
      } catch (e) {
        this.setState({ formError: e?.message ?? getMessage("error.generic") });
        console.error(e);
      }
    }
  };

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const { loading } = this.state;
    const { _this } = this.props;
    const Wrapper = _this ? "div" : Form;
    const form = _this || this;
    return (
      <div className="addAddressForm">
        <Wrapper>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {this.customerConfig && (
                <MetadataFormFields
                  _this={_this || this}
                  page="add-address"
                  className="form-fields"
                  metaDataWithType={this.customerConfig}
                  preserveSequence
                  stateKeys={["address", "metaData"]}
                />
              )}
              <Input
                label={getMessage("customer.details.pages.address")}
                placeholder={getMessage(
                  "customer.details.page.address.placeholder"
                )}
                name="address"
                required
                {...form.generateStateMappers({
                  stateKeys: ["address", "address"],
                  loseEmphasisOnFill: true,
                })}
              />
              <PlacesWithStandaloneSearchBox
                element={
                  <Input
                    label={getMessage("customer.details.address.landmark")}
                    placeholder={getMessage(
                      "customer.details.address.landmark.placeholder"
                    )}
                    name="landmark"
                    required
                    {...form.generateStateMappers({
                      stateKeys: ["address", "landmark"],
                      loseEmphasisOnFill: true,
                    })}
                  />
                }
                onPlacesSearched={(data) => this.handlePlaceSearch(data, form)}
              />
              <Input
                label={getMessage("customer.details.address.pincode")}
                placeholder={getMessage(
                  "customer.details.address.pincode.placeholder"
                )}
                name="pincode"
                required
                {...form.generateStateMappers({
                  stateKeys: ["address", "pincode"],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage("customer.details.address.city")}
                placeholder={getMessage(
                  "customer.details.address.city.placeholder"
                )}
                name="city"
                required
                {...form.generateStateMappers({
                  stateKeys: ["address", "city"],
                  loseEmphasisOnFill: true,
                })}
              />
            </React.Fragment>
          )}
          {!_this && (
            <div className="form-buttons-container">
              <SubmitButton disabled={this.state.submitting}>
                {getMessage("customer.details.submitText.submit")}
              </SubmitButton>
            </div>
          )}
        </Wrapper>
      </div>
    );
  }
}
export default AddAddressForm;
