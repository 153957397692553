import React, { Component } from "react";
import { BaseForm } from "../../../../../components/Form";
import "./style.css";
import { PieChart, Pie, Cell, Sector, Legend } from "recharts";
import Loader from "../../../../../components/Loader";
import { getMessage } from "../../../../../lib/translator";

function validData(data) {
  if (data.length > 0) {
    let val = data.reduce((acc, curr) => acc + curr.value, 0);
    return !!val;
  }
  return false;
}

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

class TwoLevelPieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.getInitialState = this.getInitialState.bind(this);
    this.onPieEnter = this.onPieEnter.bind(this);
  }
  getInitialState() {
    return {
      activeIndex: 0,
    };
  }

  onPieEnter(data, index) {
    this.setState({
      activeIndex: index,
    });
  }

  render() {
    let COLORS = this.props.colors;
    if (this.props.showLoader) {
      return <Loader />;
    } else {
      if (!validData(this.props.data)) {
        return (
          <div className="nodata-warning">
            <small>No data available</small>
          </div>
        );
      }
      return (
        <PieChart width={600} height={400}>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={this.props.data}
            cx={300}
            cy={160}
            innerRadius={75}
            outerRadius={145}
            fill="#888d8"
            onMouseEnter={this.onPieEnter}
            dataKey="value"
          >
            {this.props.data.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend iconType="circle" />
        </PieChart>
      );
    }
  }
}

function formObject(chartData) {
  let keys = Object.keys(chartData);
  let values = Object.values(chartData);

  let reqArr = null;
  reqArr = keys.reduce((accumalator, curr, index) => {
    let ob = { value: values[index] };
    if (curr === "DESKTOP_NA_WEB") {
      ob.name = getMessage("coupon.analytics.pieChart.DESKTOP_NA_WEB");
    } else if (curr === "MOBILE_ANDROID_WEB") {
      ob.name = getMessage("coupon.analytics.pieChart.MOBILE_ANDROID_WEB");
    } else if (curr === "MOBILE_IOS_WEB") {
      ob.name = getMessage("coupon.analytics.pieChart.MOBILE_IOS_WEB");
    } else {
      let splitString = curr.split("_");
      let currval = splitString.length > 1 ? splitString.join(" ") : curr;
      ob.name = currval.charAt(0).toUpperCase() + currval.slice(1);
    }
    accumalator.push(ob);
    return accumalator;
  }, []);
  return reqArr;
}

export default class AnalyticPieChart extends BaseForm {
  render() {
    const colors = this.props.colors;
    let reqData = null;
    if (this.props.chartData) {
      reqData = formObject(this.props.chartData);
    }

    return (
      <section className="analytic-pie-chart">
        <h5>{this.props.heading}</h5>
        {reqData && (
          <div className="piechart-display">
            <TwoLevelPieChart
              data={reqData}
              colors={colors}
              showLoader={this.state.showLoader}
            />
          </div>
        )}
      </section>
    );
  }
}
