import React, { Component } from "react";
import API from "../../../../lib/api";
import Loader from "../../../../components/Loader";
import { Link } from "react-router-dom";
import agentIcon from "./agent-icon.svg";
import moment from "moment";

import "./style.css";

const OrderItemDeliveryStatus = (props) => {
  return (
    <div className="trip-order-delivery-details-elm">
      <div className="delivery-status-icon" />
      <div className="order-id">
        <span className="text-muted">{props.clientId ? "#" : "Z"}</span>
        <Link
          to={`/operations/orders/${props.referenceNumber}`}
          className="reference-number"
        >
          {props.clientId ? `${props.clientId}` : `${props.referenceNumber}`}
        </Link>
      </div>
      <div className="delivery-status">
        {props.status === "COMPLETED"
          ? "Delivered"
          : props.status.charAt(0).toUpperCase() +
            props.status.slice(1).toLowerCase()}
      </div>
    </div>
  );
};
class OpenTripListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      data: null,
    };
    this.toggleExpand = this.toggleExpand.bind(this);
    this.getOrders = this.getOrders.bind(this);
  }
  toggleExpand(e) {
    e.target.classList.toggle("active");
    this.setState({ expanded: !this.state.expanded });
  }
  getOrders() {
    let { orders } = this.props;
    if (!orders) {
      return null;
    }
    let delivered = orders.filter((order) => {
      return order.status === "COMPLETED";
    }).length;
    let total = orders.length;
    return {
      delivered,
      total,
    };
  }
  render() {
    let contentStyle = {
      maxHeight: this.state.expanded ? this.contentRef.offsetHeight + "px" : 0,
    };
    let etaResponse = Boolean(this.props.eta);
    let totalDiff =
      this.props.eta && moment.duration(moment(this.props.eta).diff(moment()));
    let eta = totalDiff && Math.ceil(totalDiff.asMinutes());
    let delayed = eta < 0;
    eta = eta > 0 ? eta : Math.abs(eta);
    let hours = Number(eta) > 60 ? Math.floor(Number(eta) / 60) : 0;
    let minutes = eta ? Math.ceil(Number(eta) % 60) : 0;
    return (
      <div className="open-trip-list-elm">
        <div className="open-trip-list-elm-header">
          <Link
            to={`/logistics/trips/${this.props.tripId}`}
            className="trip-id"
          >
            {this.props.tripId}
          </Link>
          {this.getOrders() && (
            <div className="total-delivered">{`${this.getOrders().delivered}/${
              this.getOrders().total
            } Delivered`}</div>
          )}
          <div className="trip-delivery-details">
            <img
              className="delivery-agent-icon"
              src={agentIcon}
              alt="agent-icon"
            />
            <span className="delivery-agent-name">{this.props.agent.name}</span>
          </div>
          {etaResponse && !delayed && (
            <div className="remaining-trip-time">
              Ends in
              {hours
                ? ` ${hours} Hour${hours > 1 ? "s" : ""} ${minutes} `
                : null}
              {!hours && ` ${eta} `}Minutes
            </div>
          )}
          {etaResponse && delayed && (
            <div className="remaining-trip-time">
              Delayed by
              {hours
                ? ` ${hours} Hour${hours > 1 ? "s" : ""} ${minutes} `
                : null}
              {!hours && ` ${eta} `}Minutes
            </div>
          )}
        </div>
        <div className="trip-order-delivery-details" style={contentStyle}>
          <div
            className="trip-order-delivery-details-wrapper"
            ref={(node) => {
              this.contentRef = node;
            }}
          >
            {this.props.orders &&
              this.props.orders.map((order, i) => {
                return (
                  <OrderItemDeliveryStatus
                    key={i}
                    clientId={order.clientId}
                    referenceNumber={order.referenceNumber}
                    status={order.status}
                    slotStartTime={order.slotStartTime}
                    slotEndTime={order.slotEndTime}
                  />
                );
              })}
          </div>
        </div>
        <div className="toggle-button" onClick={this.toggleExpand} />
      </div>
    );
  }
}
class OpenTrips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      trips: [],
      tripETAs: null,
    };
    this.getOpenTrips = this.getOpenTrips.bind(this);
    this.getTripsETA = this.getTripsETA.bind(this);
  }
  getOpenTrips() {
    this.tripApi = new API({
      url: "/logistics-service/trip",
    });
    let params = {};
    if (this.props.storeId) {
      params.storeId = this.props.storeId;
    }
    params.status = "OPEN";
    params.paginated = "false";
    this.tripApi
      .get(params)
      .then((response) => {
        this.setState(
          {
            data: response.data,
          },
          () => {
            if (!response.data?.trip?.length) {
              this.setState({ trips: [] });
              return;
            }
            this.getTripsETA();
            response.data.trip.forEach((el) => {
              const api = new API({ url: `/logistics-service/trip/${el.id}` });
              let params = {};
              if (this.props.storeId) {
                params.storeId = this.props.storeId;
              }
              params.id = el.id;
              api
                .get(params)
                .then((response) => {
                  this.setState({
                    trips: [...this.state.trips, response.data.trip],
                  });
                })
                .catch((error) => {
                  console.error(error);
                });
            });
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }
  getTripsETA() {
    if (!(this.state.data && this.state.data.count)) {
      return null;
    }
    let tripIds = this.state.data.trip.map((eachTrip) => {
      return eachTrip.id;
    });
    let paramStr = `storeId=${this.props.storeId}&`;
    if (tripIds.length) {
      tripIds.forEach((id, i) => {
        paramStr += `${i ? "&" : ""}tripId=${id}`;
      });
    }
    this.tripEtaApi = new API({
      url: `/logistics-service/trip-eta?${paramStr}`,
    });
    this.tripEtaApi
      .get()
      .then((response) => {
        this.setState({
          tripETAs: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
        if (error.code === 401 || error.code === 403) {
          throw error;
        }
      });
  }
  componentDidMount() {
    this.getOpenTrips();
  }
  componentDidUpdate(prevProps) {
    if (this.props.storeId !== prevProps.storeId) {
      this.getOpenTrips();
    }
  }

  componentWillUnmount() {
    this.tripApi && this.tripApi.cancel();
    this.tripEtaApi && this.tripEtaApi.cancel();
  }

  render() {
    if (!this.state.data) {
      return <Loader />;
    }
    if (
      !(
        this.state.data.count &&
        this.state.data.count === this.state.trips.length
      )
    ) {
      return null;
    }
    return (
      this.state.data &&
      this.state.data.count && (
        <div className="open-trips-wrapper">
          <div className="open-trips-heading">Open Trips</div>
          <div className="open-trip-list">
            {this.state.data &&
              this.state.data.trip.map((el, i) => {
                let obj =
                  this.state.tripETAs &&
                  this.state.tripETAs.find((ETAObj) => {
                    return ETAObj.tripId === el.id;
                  });
                let eta = obj ? obj.ETA : null;
                let trip = this.state.trips.find((trip) => {
                  return trip.id === el.id;
                });
                return (
                  <OpenTripListItem
                    key={i}
                    orders={trip ? trip.orders : null}
                    tripId={el.id}
                    eta={eta}
                    agent={el.deliveryAgent}
                    startTime={el.startTime}
                    storeId={this.props.storeId}
                  />
                );
              })}
          </div>
        </div>
      )
    );
  }
}

export default OpenTrips;
