import React from "react";
import {
  BaseForm,
  Input,
  ProductSearch,
  Select,
  SingleDatePicker,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import Image from "../../../../components/Image";
import { getTimes } from "../../../../lib/datetime";
import "./style.css";
import { timeRangeDisable } from "../../../../lib/datetime";

export default class ProductRestrictionForm extends BaseForm {
  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    const { props } = this;
    const { value } = props;
    const product = value && value.product;
    return (
      <div className="restrictionForm">
        <Form>
          {props.method === "add" ? (
            <ProductSearch
              name="name"
              required
              {...this.generateStateMappers({
                stateKeys: ["product"],
                loseEmphasisOnFill: true,
              })}
            />
          ) : null}
          <div className="flex">
            {product ? (
              <Image size="md" src={product.images && product.images?.[0]} />
            ) : null}
            {product ? (
              <h4>
                {product.brand ? (
                  <React.Fragment>
                    <span className="bold">{product.brand.name}</span>
                    {product.name}
                  </React.Fragment>
                ) : (
                  product.name
                )}
              </h4>
            ) : null}
          </div>
          <div className="form-fields">
            <Input
              label={getMessage("store.max.purchaseable.qty")}
              placeholder="00"
              min={1}
              name="maxPurchasableQuantity"
              type="number"
              required
              {...this.generateStateMappers({
                stateKeys: ["rule", "maxPurchasableLimit"],
                loseEmphasisOnFill: true,
              })}
            />

            <div className="startDateTime">
              <SingleDatePicker
                name="startDate"
                label={getMessage("catalogue.product.restriction.startDate")}
                placeholder={getMessage("Date")}
                enableToday
                required
                {...this.generateStateMappers({
                  stateKeys: ["startDate"],
                  loseEmphasisOnFill: true,
                })}
              />
              <div className="form-field">
                <Select
                  name="slot-start"
                  label={getMessage("slots.startTime")}
                  placeholder={getMessage("slots.startsAt")}
                  options={getTimes()}
                  handleOptionDisable={(val) =>
                    timeRangeDisable(this.state.values?.startDate, val)
                  }
                  required
                  {...this.generateStateMappers({
                    stateKeys: ["startTime"],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            </div>
            <div className="startDateTime">
              <SingleDatePicker
                name="endDate"
                enableToday
                label={getMessage("catalogue.product.restriction.endDate")}
                placeholder={getMessage("Date")}
                required
                {...this.generateStateMappers({
                  stateKeys: ["endDate"],
                  loseEmphasisOnFill: true,
                })}
              />

              <div className="form-field">
                <Select
                  name="slot-end"
                  label={getMessage("slots.endTime")}
                  placeholder={getMessage("slots.endsAt")}
                  options={getTimes()}
                  handleOptionDisable={(val) =>
                    timeRangeDisable(this.state.values?.startDate, val)
                  }
                  required
                  {...this.generateStateMappers({
                    stateKeys: ["endTime"],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            </div>
            <div className="days">
             <Input
               label={getMessage("product.restriction.days")}
               placeholder="0"
               min={0}
               name="days"
               type="number"
               {...this.generateStateMappers({
                 stateKeys: ["days"],
                 loseEmphasisOnFill: true,
              })}
              />
               <Input
                label={getMessage("product.restriction.hours")}
                placeholder="0"
                min={0}
                name="hours"
                type="number"
                {...this.generateStateMappers({
                  stateKeys: ["hours"],
                  loseEmphasisOnFill: true,
                })}
               />
            </div>
          </div>

          <div className={`form-actions ${props.method}`}>
            <SubmitButton>{getMessage("brand.form.submitText")}</SubmitButton>
            <CancelButton>{getMessage("brand.form.cancelText")}</CancelButton>
          </div>
        </Form>
      </div>
    );
  }
}
