import React from "react";
import {
  BaseForm,
  Input,
  Textarea,
  Upload,
  VALIDATION_TYPES,
} from "../../../components/Form";
import { getMessage } from "../../../lib/translator";
import API from "../../../lib/api";
import Loader from "../../../components/Loader";
import { Dialog } from "../../../components/Popup";
import { withRouter } from "react-router-dom";
import tooltipImg from "./tooltip.svg";

const Tooltip = (props) => (
  <div className="Tooltip">
    <img src={tooltipImg} alt="?" />
    <div className="tooltip-text">{props.children}</div>
  </div>
);

class MobileAppsForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, this.state, {
      themes: null,
      showLoader: false,
      showSuccessDialog: false,
      showErrorDialog: false,
      loadingThemes: false,
    });
    this.handleClose = this.handleClose.bind(this);
    this.closeDialogs = this.closeDialogs.bind(this);
    this.loadData = this.loadData.bind(this);
    this.loadThemes = this.loadThemes.bind(this);
  }
  loadData() {
    this.setState({ showLoader: true });
    this.api = new API({ url: "/account-service/config/mobileApp" });
    this.api
      .get()
      .then((response) => {
        this.setState((prevState) => {
          let newState = Object.assign({}, prevState, {
            values: response.data.config.mobileApp,
            loader: false,
          });
          return newState;
        });
      })
      .then(() => {
        this.setState({ showLoader: false });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  componentWillUnmount() {
    this.api && this.api.cancel();
  }
  loadThemes() {
    let api = new API({ url: "/account-service/theme" });
    api
      .get()
      .then((response) => {
        this.setState({
          themes: response.data.theme,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleClose() {
    this.props.history.goBack();
  }
  onSubmit(data) {
    const api = new API({ url: "/account-service/config/mobileApp" });
    this.setState({ submitting: true });
    api
      .put({ mobileApp: data })
      .then(
        () => {
          this.setState({ showSuccessDialog: true });
        },
        () => {
          this.setState({ showErrorDialog: true });
        }
      )
      .then(() => {
        this.setState({ submitting: false });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    });
  }
  componentDidMount() {
    this.loadData();
    // this.loadThemes()
  }
  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("mobileApps.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("mobileApps.save.success.closeText")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage("mobileApps.save.error.title")}
          close={this.closeDialogs}
          closeText={getMessage("mobileApps.save.error.closeText")}
        />
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <Form>
            <div className="form-fields">
              <Input
                className="app-name"
                label={getMessage("mobileApps.name.heading")}
                placeholder={getMessage("mobileApps.name.placeholder")}
                name="name"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ["name"],
                  validationType: VALIDATION_TYPES.ONCHANGE,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                }}
              />
              <Textarea
                label={getMessage("mobileApps.shortDescription.heading")}
                placeholder={getMessage(
                  "mobileApps.shortDescription.placeholder"
                )}
                name="shortDescription"
                maxLength={30}
                required
                {...this.generateStateMappers({
                  stateKeys: ["shortDescription"],
                  validationType: VALIDATION_TYPES.ONCHANGE,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                }}
              />
              <Textarea
                label={getMessage("mobileApps.longDescription.heading")}
                placeholder={getMessage(
                  "mobileApps.longDescription.placeholder"
                )}
                name="longDescription"
                required
                {...this.generateStateMappers({
                  stateKeys: ["longDescription"],
                  validationType: VALIDATION_TYPES.ONCHANGE,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                }}
              />
            </div>
            <h3>{getMessage("mobileApps.assets.heading")}</h3>
            <div className="form-fields">
              <Upload
                label={getMessage("mobileApps.appIcon.heading")}
                placeholder={getMessage("mobileApps.appIcon.placeholder")}
                name="appIcon"
                secondaryLabel={() => (
                  <Tooltip>{getMessage("mobileApps.appIcon.tooltip")}</Tooltip>
                )}
                {...this.generateStateMappers({
                  stateKeys: ["appIcon"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                required
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                  fileSizeExeeded: getMessage("input.fileSizeExeeded"),
                  invalidFileType: getMessage("input.invalidFileType"),
                }}
              />
              <Upload
                label={getMessage("mobileApps.splashScreen.heading")}
                placeholder={getMessage("mobileApps.splashScreen.placeholder")}
                name="appIcon"
                secondaryLabel={() => (
                  <Tooltip>
                    {getMessage("mobileApps.splashScreen.tooltip")}
                  </Tooltip>
                )}
                {...this.generateStateMappers({
                  stateKeys: ["splashScreen"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                required
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                  fileSizeExeeded: getMessage("input.fileSizeExeeded"),
                  invalidFileType: getMessage("input.invalidFileType"),
                }}
              />
            </div>
            {this.state.themes && (
              <h3>{getMessage("mobileApps.theme.heading")}</h3>
            )}
            {this.state.loadingThemes ? (
              <div className="centered">
                <Loader size="sm" />
                <div className="loader-message">
                  {getMessage("mobileApps.theme.loadingText")}
                </div>
              </div>
            ) : this.state.themes ? (
              <div className="scroller">
                {this.state.themes.map((theme) => (
                  <div
                    className={`theme-option ${
                      this.getState(["selectedTheme"]) === theme.slug
                        ? "selected"
                        : ""
                    }`.trim()}
                    key={theme.id}
                  >
                    <label>
                      <input
                        type="radio"
                        name="selectedTheme"
                        className="invisible"
                        value={theme.slug}
                        onChange={(e) => {
                          this.updateState(["selectedTheme"], e.target.value);
                        }}
                      />
                      <div className="theme-preview-container">
                        <img src={theme.image.desktop} alt={theme.name} />
                      </div>
                      <div className="theme-name">{theme.name}</div>
                    </label>
                  </div>
                ))}
              </div>
            ) : null}
            <div className="form-buttons-container">
              <SubmitButton disabled={this.state.submitting}>
                {getMessage("mobileApps.submit.text")}
              </SubmitButton>
              <button
                className="button"
                type="button"
                onClick={this.handleClose}
                disabled={this.state.submitting}
              >
                {getMessage("mobileApps.cancel.text")}
              </button>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

export default withRouter(MobileAppsForm);
