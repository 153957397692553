import React, { Component } from "react";
import { Link } from "react-router-dom";

import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import ListingPageWithRoutes from "../../../containers/ListingPage/listingRouter";
import ProductUpload from "./ProductUpload";
import SearchAndAdd from "./SearchAndAdd";
import { Row, Cell } from "../../../components/Table";
import Image from "../../../components/Image";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import ProductForm, { STOCK_STRATEGIES } from "./Form";
import ProductFilters from "./Filters";
import { Popup, Dialog } from "../../../components/Popup";
import { getMessage } from "../../../lib/translator";
import { isExtensionEnabled, getSession } from "../../../lib/auth";
import API from "../../../lib/api";
import { get, unset } from "../../../lib/storage";
import find from "lodash.find";
import Toggle from "../../../components/Form/Inputs/Toggle";
import {
  Radio,
  BaseForm,
  Textarea,
  Searchable,
} from "../../../components/Form";
import Tabs from "../../../components/Tabs";
import {
  isMarketPlaceOwner,
  isMarketPlaceSeller,
} from "../../../lib/marketPlace";
import downloadIcon from "./download.svg";
import emptyIcon from "./products-empty.svg";

import "./style.css";
import { GO_HOST } from "../../../config/app";
const emptyState = {
  icon: emptyIcon,
  message: getMessage("product.helpItems.message"),
  submessage: getMessage("product.helpItems.submessage"),
  additionalViews: [ProductUpload],
};
const showConfig = () => {
  if (GO_HOST.includes("tamimimarkets.com")) return false;
  return true;
};

const API_HEADER = {
  // "Developer-token": DEVELOPER_TOKEN,
  "Accept-Language": "en",
  restrictAutherizationToken: true,
};
// const MASTER_CATELOGUE_STORE_ID = 1;
export const getProductRequestStatus = () => {
  const requestStatusTabs = ["APPROVED", "PENDING", "REJECTED"];
  if (isMarketPlaceSeller()) {
    requestStatusTabs.push("DRAFT");
  }

  return requestStatusTabs;
};

export const isSellerSection = () => {
  const location = window.location.pathname.split("/");
  if (isExtensionEnabled("MarketPlace")) {
    if (isMarketPlaceSeller() || location.includes("seller-catalogue")) {
      return true;
    }
  }
  return false;
};

class HeaderActions extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      showDownloadOptions: false,
      showDownloadComplete: false,
      showSellerSelectionForm: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showDownloadModal = this.showDownloadModal.bind(this);
    this.hideDownloadModal = this.hideDownloadModal.bind(this);
    this.csvDownload = this.csvDownload.bind(this);
    this.toggleShowSellerSelectionForm =
      this.toggleShowSellerSelectionForm.bind(this);
    this.isMarketPlaceOwner = isMarketPlaceOwner();
    this.isMarketPlaceSeller = isMarketPlaceSeller();
    this.isSellerSection = isSellerSection();
  }

  toggleShowSellerSelectionForm() {
    this.setState((prev) => ({
      showSellerSelectionForm: !prev.showSellerSelectionForm,
    }));
  }

  handleSelectedSeller = (data) => {
    this.setState({
      selectedSellerData: data,
    });
  };

  showModal() {
    this.setState({
      openModal: true,
      values: {
        bulkUploadType: "catalogue",
      },
    });
  }

  hideModal() {
    this.setState({
      openModal: false,
    });
  }

  showDownloadModal(e) {
    e && e.preventDefault();
    this.setState({
      showDownloadOptions: true,
      values: {
        downloadType: "catalogue",
      },
    });
  }

  hideDownloadModal(message) {
    if (typeof message === "string") {
      this.setState({
        showDownloadOptions: false,
        showDownloadComplete: true,
      });
    } else {
      this.setState({
        showDownloadOptions: false,
      });
    }
  }

  csvDownload(e) {
    e.preventDefault();
    if (this.state.values && this.state.values.downloadType === "catalogue") {
      const api = new API({
        url: "/catalogue-service/product-upload?type=file",
      });
      api
        .get()
        .then((response) => {
          this.hideDownloadModal(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (
      this.state.values &&
      this.state.values.downloadType === "inventory" &&
      this.state.values.storeId
    ) {
      const { storeId } = this.state.values;
      const { email } = { ...JSON.parse(get("user")) }.emails[0];
      const api = new API({ url: "/inventory-service/inventory-file" });
      api
        .get({ storeId, email })
        .then((response) => {
          this.hideDownloadModal(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  getAddProductOptions = () => {
    const dropDown = [
      <DropDownItem key="1">
        <Link
          to={
            isMarketPlaceOwner()
              ? `/catalogue/master-catalogue/add`
              : `/catalogue/products/add`
          }
        >
          {getMessage("product.add.single.text")}
        </Link>
      </DropDownItem>,
      <DropDownItem onClick={this.showModal} key="2">
        {getMessage("product.add.bulk.text")}
      </DropDownItem>,
    ];

    if (
      isExtensionEnabled("MarketPlace") &&
      (this.isMarketPlaceSeller || this.isSellerSection)
    ) {
      dropDown.splice(
        0,
        2,
        <DropDownItem key="3">
          {this.isMarketPlaceOwner ? (
            <div onClick={this.toggleShowSellerSelectionForm}>
              {getMessage("product.search.and.add.text")}
            </div>
          ) : (
            <Link to="/catalogue/products/search-and-add">
              {getMessage("product.search.and.add.text")}
            </Link>
          )}
        </DropDownItem>
      );
    }
    return dropDown;
  };

  render() {
    const storeOptions = [...JSON.parse(get("stores"))].map((store) => {
      return { text: store.name, value: store.id };
    });
    const bulkUploadType =
      this.state.values && this.state.values["bulkUploadType"];
    const downloadType = this.state.values && this.state.values.downloadType;
    const location = window.location.pathname.split("/");
    const isSellerCatalogue = location.includes("seller-catalogue");

    return (
      <div className="flex">
        {this.state.showSellerSelectionForm && (
          <Popup
            heading={getMessage("Select Seller")}
            show={this.state.showSellerSelectionForm}
            close={this.toggleShowSellerSelectionForm}
            className="seller-selection-form"
          >
            <div>
              <Searchable
                label={getMessage("product.seller.selection.heading")}
                placeholder={getMessage(
                  "product.filters.permissableSeller.placeholder"
                )}
                name="seller"
                searchUrl="/account-service/seller"
                valueKey="id"
                responseKey="seller"
                nameKey="name"
                searchKey="name"
                transformResponse={(response) => response.data.seller}
                onChange={(val) => this.handleSelectedSeller(val)}
                value={this.state.selectedSellerData}
              />

              <Link
                to={`/catalogue/products/search-and-add${
                  this.state.selectedSellerData &&
                  "?sellerId=" + this.state.selectedSellerData.id
                }`}
                className={`button image-selection-button select-seller-button ${
                  !this.state.selectedSellerData &&
                  "select-seller-button-disabled"
                }`}
              >
                {getMessage("Continue")}
              </Link>
              <button
                className="button"
                onClick={() => this.toggleShowSellerSelectionForm()}
              >
                {getMessage("category.form.cancelText")}
              </button>
            </div>
          </Popup>
        )}
        {this.state.openModal && (
          <Popup
            heading={getMessage("product.add.bulk.text")}
            show={this.state.openModal}
            close={this.hideModal}
            className="bulk-upload"
          >
            <Radio
              className="product-type"
              label={getMessage("product.selectType")}
              name="bulkUploadType"
              options={[
                {
                  text: getMessage("product.type.catalogue"),
                  value: "catalogue",
                },
                {
                  text: getMessage("product.type.inventory"),
                  value: "inventory",
                },
              ]}
              {...this.generateStateMappers({
                stateKeys: ["bulkUploadType"],
              })}
            />
            <ProductUpload
              hideAddButton
              disableUpload={!bulkUploadType}
              bulkUploadType={bulkUploadType}
            />
          </Popup>
        )}
        {this.state.showDownloadOptions && (
          <Popup
            heading={getMessage("product.downloadOptionsTitle")}
            show={this.state.showDownloadOptions}
            close={this.hideDownloadModal}
            className="download-options"
          >
            <Radio
              className="product-type"
              label={getMessage("product.selectType")}
              name="downloadType"
              options={[
                {
                  text: getMessage("product.type.catalogue"),
                  value: "catalogue",
                },
                {
                  text: getMessage("product.type.inventory"),
                  value: "inventory",
                },
              ]}
              {...this.generateStateMappers({
                stateKeys: ["downloadType"],
              })}
            />
            {this.state.values &&
              this.state.values["downloadType"] === "inventory" && (
                <React.Fragment>
                  <div className="horizontal-line">
                    <hr />
                  </div>
                  <Radio
                    className="product-type stores"
                    label={getMessage("product.selectStore")}
                    name="storeId"
                    options={storeOptions}
                    {...this.generateStateMappers({
                      stateKeys: ["storeId"],
                    })}
                  />
                </React.Fragment>
              )}
            <div>
              <button
                type="button"
                className="button"
                onClick={this.hideDownloadModal}
              >
                {getMessage("product.cancelDownloadCsv")}
              </button>
              <input
                type="submit"
                className="primary"
                value={getMessage("product.downloadOptionsTitle")}
                onClick={this.csvDownload}
                disabled={
                  !(
                    this.state.values.downloadType === "catalogue" ||
                    (this.state.values.downloadType === "inventory" &&
                      this.state.values.storeId)
                  )
                }
              />
            </div>
          </Popup>
        )}
        <Dialog
          className="success"
          show={this.state.showDownloadComplete}
          title={getMessage("product.downloadSuccessTitle", {
            value:
              downloadType &&
              downloadType
                .charAt(0)
                .toUpperCase()
                .concat(downloadType.slice(1)),
          })}
          information={getMessage("product.downloadSuccessMessage", {
            value: downloadType,
          })}
          closeText={getMessage("product.downloadDialogClose")}
          close={() =>
            this.setState({
              showDownloadComplete: false,
            })
          }
        />
        {!isSellerSection() && (
          <button className="download-button" onClick={this.showDownloadModal}>
            <img src={downloadIcon} alt={getMessage("product.add.text")} />
          </button>
        )}
        {(!isSellerCatalogue || isMarketPlaceOwner) && (
          <DropDown
            className="bulk-upload-dropdown"
            icon={
              <button className="button primary add-product-dropdown-button">
                + <span className="text">{getMessage("product.add.text")}</span>
              </button>
            }
          >
            {this.getAddProductOptions().map((item) => item)}
          </DropDown>
        )}
      </div>
    );
  }
}

const getEditPath = (pId, id) => {
  if (isMarketPlaceOwner() && !isSellerSection()) {
    return `/catalogue/master-catalogue/edit/${pId}`;
  } else if (isSellerSection() && !isMarketPlaceSeller()) {
    return `/catalogue/seller-catalogue/edit/${pId}?requestId=${id}`;
  } else if (isSellerSection() && isMarketPlaceSeller()) {
    return `/catalogue/products/edit/${pId}?requestId=${id}`;
  } else {
    return `/catalogue/products/edit/${pId}`;
  }
};

const tableProperties = (_this) => {
  const { handleRequestApproval } = _this;
  const textAlignment = get("dataLang") === "ar" ? "right" : "";
  const sellerColumn = {
    name: getMessage("product.header.sellername"),
    dir: textAlignment,
  };
  const statusColumn = {
    name: getMessage("product.header.status"),
    dir: textAlignment,
  };
  const merchandiseColumn = {
    name: getMessage("product.merchandise.id"),
    dir: textAlignment,
  };
  const headers = [
    getMessage("product.header.image"),
    {
      name: getMessage("product.header.name"),
      dir: textAlignment,
    },
    {
      name: getMessage("product.header.primaryCategory"),
      dir: textAlignment,
    },
    getMessage("product.header.actions"),
  ];

  if (isSellerSection() && isMarketPlaceOwner()) {
    headers.splice(4, 0, sellerColumn);
  }
  if (!(isSellerSection() || isMarketPlaceSeller())) {
    headers.splice(4, 0, statusColumn);
  }
  if (!showConfig()) {
    headers.splice(2, 0, merchandiseColumn);
  }

  return {
    headers,
    row: ({
      id,
      productId,
      images,
      brand,
      name,
      slug,
      primaryCategory,
      status,
      variants,
      onAction,
      product,
      sellerName,
      sellerID,
      metaData,
    }) => {
      let image = images && images.length && images[0];
      const { merchandise = "" } = metaData || {};
      if (!image && variants && variants.length) {
        let varImage = variants.filter((variant) => variant.images)[0];
        if (varImage && varImage.images) {
          image = varImage.images[0];
        }
      }
      const showApproveBtn =
        isMarketPlaceOwner() &&
        isSellerSection() &&
        ["PENDING", "REJECTED"].includes(status);

      const showRejectBtn =
        isMarketPlaceOwner() && isSellerSection() && status === "PENDING";
      const pId = product ? productId : id;
      const sessionData = getSession();
      const organization = sessionData.organization;
      let siteUrl = organization?.domain;

      if (siteUrl) {
        let isHttpsEnabled = JSON.parse(get("organization")).httpsEnabled;
        let protocol = isHttpsEnabled ? "https://" : "http://";
        const isPrefixInSiteUrl = siteUrl?.includes("http");
        siteUrl = isPrefixInSiteUrl ? siteUrl : `${protocol}${siteUrl}`;
      }
      let productStoreLink = isSellerSection()
        ? `${siteUrl}/product/${product?.slug}/${sellerID}`
        : `${siteUrl}/product/${slug}`;
      return (
        <Row>
          <Cell>
            <Image src={image} size="sm" bordered />
          </Cell>
          <Cell align={textAlignment}>
            <Link to={getEditPath(pId, id)} className="product-name">
              <div>
                {brand ? (
                  <span className="brand-name">{brand.name} </span>
                ) : null}
                {name ?? product?.name}
              </div>
            </Link>
          </Cell>
          {!showConfig() && (
            <Cell className="primary-category" align={textAlignment}>
              <small>{merchandise}</small>
            </Cell>
          )}
          <Cell className="primary-category" align={textAlignment}>
            <small className="text-muted not-mobile">
              {(primaryCategory ? primaryCategory.name : null) ||
                product?.primaryCategory}
            </small>
            <span className="text-muted only-mobile">
              {`${getMessage("product.header.primaryCategory")}: `}
              <small className="product-text-muted">
                {(primaryCategory ? primaryCategory.name : null) ||
                  product?.primaryCategory}
              </small>
            </span>
          </Cell>
          {isSellerSection() && isMarketPlaceOwner() && (
            <Cell className="seller-name">
              <small className={`${sellerName} not-mobile`}>{sellerName}</small>
              <small className={`${sellerName} only-mobile`}>
                {sellerName}
              </small>
            </Cell>
          )}
          {!(isSellerSection() || isMarketPlaceSeller()) && (
            <Cell className="product-status" align={textAlignment}>
              <small className="text-muted product-status not-mobile">
                {status ? status.toLowerCase() : null}
              </small>
              <Toggle
                className={`${status} only-mobile`}
                name={status}
                value={status === "ENABLED"}
                icons={false}
                onChange={() => {
                  onAction(
                    TABLE_ACTIONS.UPDATE,
                    { id },
                    { status: status === "ENABLED" ? "HIDDEN" : "ENABLED" }
                  );
                }}
              />
            </Cell>
          )}
          <Cell className="product-actions">
            <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
              <DropDownItem>
                <Link to={getEditPath(pId, id)}>
                  {getMessage("product.action.edit")}
                </Link>
              </DropDownItem>
              {!isSellerSection() && (
                <DropDownItem
                  onClick={() => {
                    onAction(TABLE_ACTIONS.DELETE, { id });
                  }}
                >
                  {getMessage("product.action.delete")}
                </DropDownItem>
              )}
              {!isSellerSection() &&
                (!(status === "ENABLED") ? (
                  <DropDownItem
                    onClick={() => {
                      onAction(
                        TABLE_ACTIONS.UPDATE,
                        { id },
                        { status: "ENABLED" }
                      );
                    }}
                  >
                    {getMessage("product.action.enable")}
                  </DropDownItem>
                ) : (
                  <DropDownItem
                    onClick={() => {
                      onAction(
                        TABLE_ACTIONS.UPDATE,
                        { id },
                        { status: "HIDDEN" }
                      );
                    }}
                  >
                    {getMessage("product.action.disable")}
                  </DropDownItem>
                ))}

              <DropDownItem>
                <a
                  href={productStoreLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getMessage("View in website")}
                </a>
              </DropDownItem>

              {showApproveBtn && (
                <DropDownItem
                  onClick={() => {
                    handleRequestApproval(
                      id,
                      { status: "APPROVED", productId },
                      () => onAction(TABLE_ACTIONS.REFRESH)
                    );
                  }}
                >
                  {getMessage("Approve")}
                </DropDownItem>
              )}
              {showRejectBtn && (
                <DropDownItem
                  onClick={() => {
                    handleRequestApproval(
                      id,
                      { status: "REJECTED", productId },
                      () => {
                        onAction(TABLE_ACTIONS.REFRESH);
                        _this.setState({
                          showRejectReasonForm: false,
                        });
                      }
                    );
                  }}
                >
                  {getMessage("Reject")}
                </DropDownItem>
              )}
            </DropDown>
          </Cell>
        </Row>
      );
    },
  };
};
export default class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestTab: window.localStorage?.getItem(`productPage-tabIndex`) || 0,
      queryParams: null,
    };
    this.productRequestTabStatus = getProductRequestStatus();
  }

  fetchTableDataOnStatus = (tab) => {
    let queryParams = null;
    if (this.isSellerSection()) queryParams = { status: tab };
    return queryParams;
  };

  changeTab = (tab) => {
    window.localStorage.setItem(`productPage-tabIndex`, tab);
    unset("products-page");
    this.props.history.push({ search: "" });
    this.setState({
      requestTab: tab,
    });
  };

  getAPIUrl = () => {
    if (isExtensionEnabled("MarketPlace")) {
      const location = window.location.pathname.split("/");
      if (
        this.isSellerSection() &&
        !location.includes("add-product") &&
        !location.includes("edit")
      ) {
        return "/catalogue-service/seller-product-request";
      }
    }
    return "/catalogue-service/product";
  };

  isSellerSection = () => {
    const location = window.location.pathname.split("/");
    if (isExtensionEnabled("MarketPlace")) {
      if (isMarketPlaceSeller() || location.includes("seller-catalogue")) {
        return true;
      }
    }
    return false;
  };

  handleRequestApproval = (reqId, params, cb) => {
    const putApi = new API({
      url: `/catalogue-service/seller-product-request/${reqId}/approval`,
    });

    this.submitApprovalStatus = (newParams) => {
      putApi
        .put({ ...params, ...newParams })
        .then((response) => {
          cb();
        })
        .catch((error) => {
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
          this.setState({
            apiError: error.message,
            showErrorDialog: true,
          });
          console.error(error);
        })
        .finally(() => {
          this.setState({ providersLoading: false });
        });
    };

    if (params.status === "REJECTED" && !this.state.rejectionReason) {
      this.setState({
        showRejectReasonForm: true,
      });
      return;
    }

    this.submitApprovalStatus();
  };

  render() {
    let props = this.props;
    const variantsEnabled = isExtensionEnabled("MultiVariantSupport");
    const brandsEnabled = isExtensionEnabled("MultiBrandSupport");
    const storesEnabled = isExtensionEnabled("MultiStoreSupport");
    const stockStrategy = isExtensionEnabled("StockManagement")
      ? STOCK_STRATEGIES.NUMERIC
      : STOCK_STRATEGIES.BOOLEAN;
    const tagsEnabled = isExtensionEnabled("ProductTagSupport");
    const inStoreProcessing = isExtensionEnabled("InStoreProcessing");
    const soldByWeightEnabled = isExtensionEnabled("SoldByWeightSupport");
    const entityMetaDataEnabled = isExtensionEnabled("EntityMetaData");
    const preOrderSupportEnabled = isExtensionEnabled("PreOrderSupport");
    const bulkOrderEnabled = isExtensionEnabled("BulkOrderSupport");
    const tab = this.productRequestTabStatus[this.state.requestTab];
    const queryParams = this.fetchTableDataOnStatus(tab);
    const apiUrl = this.getAPIUrl();

    return (
      <>
        <Popup
          show={this.state.showRejectReasonForm}
          heading={getMessage("product.request.reject.form.heading")}
          close={() => {
            this.setState({ showRejectReasonForm: false, submitting: false });
          }}
          className="rejection-form"
        >
          <form
            onSubmit={(e) => {
              e && e.preventDefault();
              this.submitApprovalStatus({
                rejectionReason: this.state.rejectionReason,
              });
            }}
          >
            <Textarea
              label={getMessage("product.request.reject.form.label")}
              placeholder={getMessage(
                "product.request.reject.form.placeholder"
              )}
              value={this.state?.rejectionReason || ""}
              onChange={(value) => {
                // console.log("okok : ", value);
                this.setState({
                  rejectionReason: value,
                });
              }}
            />
            <button
              className="button image-selection-button reject-button"
              type="submit"
              disabled={!this.state.rejectionReason}
            >
              {getMessage("product.request.reject.form.heading")}
            </button>
            <button
              className="button"
              onClick={() => {
                this.setState({
                  showRejectReasonForm: false,
                  submitting: false,
                });
              }}
            >
              {getMessage("category.form.cancelText")}
            </button>
          </form>
        </Popup>
        <ListingPageWithRoutes
          menu={props.menu}
          className="products-page"
          title={getMessage("product.table.heading")}
          addHeading={getMessage("product.form.add.heading")}
          editHeading={getMessage("product.form.edit.heading")}
          api={{
            url: apiUrl,
            params: queryParams,
            transform: (response) => {
              let products = response.data.product
                ? response.data.product
                : response.data.requests || [];

              Array.isArray(products) &&
                products.forEach((item) => {
                  if (!item.images && item.hasVariants) {
                    const variantWithImages = find(
                      item.variants,
                      (v) => v.images !== null
                    );
                    item.images = variantWithImages && variantWithImages.images;
                  }
                });
              return products;
            },
          }}
          showLanguageDropDown
          emptyState={emptyState}
          headerActions={HeaderActions}
          additionalViews={[
            () => {
              return (
                this.isSellerSection() && (
                  <div className="tab-wrp">
                    <Tabs
                      items={this.productRequestTabStatus.map((tab) =>
                        getMessage(`product.request.${tab.toLowerCase()}`)
                      )}
                      default={Number(this.state.requestTab)}
                      onClick={(index) => {
                        this.changeTab(index);
                      }}
                    />
                  </div>
                )
              );
            },
          ]}
          form={{
            url: "/catalogue-service/product",
            apiParams: {},
            component: ProductForm,
            options: {
              tags: tagsEnabled,
              variants: variantsEnabled,
              brands: brandsEnabled,
              stockStrategy: stockStrategy,
              multipleStores: storesEnabled,
              inStoreProcessing: inStoreProcessing,
              soldByWeight: soldByWeightEnabled,
              entityMetaData: entityMetaDataEnabled,
              preOrderSupport: preOrderSupportEnabled,
              bulkOrderSuppport: bulkOrderEnabled,
              getStores: function () {
                const stores = JSON.parse(get("stores"));
                return new Promise((resolve) => {
                  resolve(stores);
                });
              },
              getProductMetaData: () => {
                if (isExtensionEnabled("EntityMetaData")) {
                  return new Promise((resolve, reject) => {
                    const api = new API({ url: `/config-service/meta-data` });
                    api.get().then((response) => {
                      resolve(response.data.config.entityMetaData);
                    }, reject);
                  }).catch((error) => {
                    console.error(error);
                  });
                }
                return null;
              },
              getInventoryData: (storeId, ids) => {
                return new Promise((resolve, reject) => {
                  const api = new API({
                    url: `/inventory-service/item?storeId=${storeId}&productId=${ids.join(
                      ","
                    )}`,
                  });
                  api.get().then((response) => {
                    resolve(response);
                  }, reject);
                }).catch((error) => {
                  console.error(error);
                });
              },
            },
            transformResponse: (response) => {
              let product = response.data.product;
              // For each product, assume single variant, single store (at least)
              // Depending on organization settings, transform submit data correspondingly
              let modifiedProduct = Object.assign({}, product);
              if (
                !product.variants ||
                (Array.isArray(product.variants) &&
                  product.variants.length === 0)
              ) {
                modifiedProduct.variants = [{}];
                if (product.images) {
                  modifiedProduct.variants[0].images = product.images;
                  // delete modifiedProduct.images
                }
                if (product.barcodes) {
                  modifiedProduct.variants[0].barcodes = product.barcodes;
                  // delete product.barcodes
                }
                if (product.clientItemId) {
                  modifiedProduct.variants[0].clientItemId =
                    product.clientItemId;
                  modifiedProduct.madeUp = true;
                  // delete product.clientItemId
                }

                if (isExtensionEnabled("MarketPlace")) {
                  modifiedProduct.variants[0].metaData = {};
                  modifiedProduct.variants[0].metaData[
                    "Minimum Profit Margin"
                  ] = product.metaData?.["Minimum Profit Margin"];
                  delete product.metaData?.["Minimum Profit Margin"];
                }

                modifiedProduct.variants[0].id = product.id;
              }
              // Primary category and secondary categories merged as categories
              modifiedProduct.categories =
                modifiedProduct.secondaryCategories.length > 0
                  ? [...modifiedProduct.secondaryCategories]
                  : [];
              // Primary category as the first element in categories
              modifiedProduct.categories.unshift(
                modifiedProduct.primaryCategory
              );
              delete modifiedProduct.primaryCategory;
              delete modifiedProduct.secondaryCategories;
              return modifiedProduct;
            },
          }}
          filters={{
            component: ProductFilters,
            options: {
              brands: brandsEnabled,
              tags: tagsEnabled,
              entityMetaData: entityMetaDataEnabled,
              seller: isSellerSection(),
            },
            transformSubmit: (formData) => {
              let filters = Object.assign({}, formData);
              if (formData.category) {
                filters.slugCategory = formData.category.slug;
                delete filters.category;
              }
              if (formData.brand) {
                filters.slugBrand = formData.brand.slug;
                delete filters.brand;
              }
              if (formData.tag) {
                filters.tagId = formData.tag.id;
                delete filters.tag;
              }
              if (formData.name) {
                filters.sorting = "RELEVANCE";
              }
              if (formData.seller) {
                filters.sellerID = formData.seller.id;
                delete filters.seller;
              }
              return filters;
            },
          }}
          secondaryActions={{
            component: {
              action: "search-and-add",
              Component: SearchAndAdd,
            },
            form: {
              action: "add-product",
              secondaryOptions: {
                headers: API_HEADER,
                masterCatelogue: true,
                getInventoryData: (storeId, ids) => {
                  return new Promise((resolve, reject) => {
                    const api = new API({
                      url: `/inventory-service/item?storeId=${storeId}&productId=${ids.join(
                        ","
                      )}`,
                    });
                    api.get({}, API_HEADER).then((response) => {
                      resolve(response);
                    }, reject);
                  });
                },
                getItem: (url, name) => {
                  return new Promise((resolve, reject) => {
                    const api = new API({
                      url: url,
                    });
                    const params = { name: name };
                    api.get(params).then((response) => {
                      resolve(response);
                    }, reject);
                  });
                },
                updateItem: (url, name) => {
                  return new Promise((resolve, reject) => {
                    const api = new API({
                      url: url,
                    });
                    const params = { name: name };
                    api.post(params).then((response) => {
                      resolve(response);
                    }, reject);
                  });
                },
              },
            },
          }}
          tableProperties={tableProperties(this)}
        />
      </>
    );
  }
}
