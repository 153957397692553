import React from "react";
import { BaseForm } from "../../../../../components/Form";
import MetaDataFormFields from "../../../../../components/MetaDataFormFields";
import { Dialog } from "../../../../../components/Popup";

import API from "../../../../../lib/api";
import {
  getEntityMetaData,
  isExtensionEnabled,
  getExtensionDetails,
  saveEntityMetaData,
  hasPermissions,
} from "../../../../../lib/auth";
import { getMessage } from "../../../../../lib/translator";
import UserTags from "./../UserTags";

import "./style.css";

class MetaDataForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.metaDataWithType = {};
    this.getCustomerMetaData = this.getCustomerMetaData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.showView = this.showView.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getCustomerMetaData() {
    let metaData = getEntityMetaData().customer;
    this.metaDataWithType = Object.assign({}, metaData);
    let customer = Object.assign({}, this.props.customer);
    this.metaData = customer.metaData;
    if (!customer.metaData) {
      metaData &&
        Object.entries(metaData).forEach(([key, type]) => {
          metaData[key] = "";
        });
      this.metaData = customer.metaData = metaData;
    }
    this.setState({
      values: customer,
      tempState: customer.metaData,
      prevState: customer.metaData,
    });
  }

  componentDidMount() {
    let customerKeys = getEntityMetaData() && getEntityMetaData().customer;
    if (
      (!customerKeys ||
        (Array.isArray(customerKeys) && !customerKeys.length) ||
        (customerKeys.constructor === Object &&
          !Object.keys(customerKeys).length)) &&
      isExtensionEnabled("EntityMetaData")
    ) {
      let extentionDetails = getExtensionDetails("EntityMetaData");
      if (extentionDetails) {
        this.api = new API({ url: `/config-service/meta-data` });
        this.api
          .get()
          .then((response) => {
            let details = response.data.config.entityMetaData;
            saveEntityMetaData(details);
            this.getCustomerMetaData();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else {
      if (isExtensionEnabled("EntityMetaData")) {
        this.getCustomerMetaData();
      }
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
    this.customerServiceApi && this.customerServiceApi.cancel();
  }

  handleEdit() {
    this.setState({
      editing: true,
    });
  }

  onSubmit(formDataRaw) {
    let formData = { ...formDataRaw, metaData: this.state.tempState };
    this.setState({
      submitting: true,
      editing: false,
    });
    const customerId = this.props.customer?.id;
    this.customerServiceApi = new API({
      url: `/customer-service/customer/${customerId}`,
    });
    let params = Object.assign({}, this.props.customer);
    params.metaData = formData.metaData;
    const configureableKeys = Object.keys(this.metaDataWithType);
    Object.keys(formData.metaData).forEach((key) => {
      if (configureableKeys.indexOf(key) < 0) {
        delete formData.metaData[key];
      }
    });
    this.customerServiceApi
      .put(params, { "X-API-VERSION": 2 })
      .then(
        (response) => {
          this.setState({
            submitting: false,
            formError: "",
            showSuccessDialog: true,
          });
        },
        (error) => {
          this.setState({
            submitting: false,
            formError: error.message,
            showErrorDialog: true,
            tempState: this.state.prevState,
          });
        }
      )
      .then(() => {
        if (this.state.tempState) {
          Object.keys(this.state.tempState).forEach((key) => {
            this.updateState(["metaData", key], this.state.tempState[key]);
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleChange(value, key) {
    this.setState({
      tempState: {
        ...this.state.tempState,
        [key]: value,
      },
    });
  }

  showView() {
    let temp = [];
    let count = 0;
    if (
      this.state.values &&
      this.state.values.metaData &&
      this.metaDataWithType
    ) {
      for (let key in this.metaDataWithType) {
        temp.push(
          <div key={++count} className="customer-metadata-show-view">
            <div className="customer-metadata-key">{key}</div>
            <div className="customer-metadata-value">
              {this.state.values.metaData[key]}
            </div>
          </div>
        );
      }
    }
    return (
      <div className="customer-metadta-show-view-container">{[...temp]}</div>
    );
  }

  render() {
    const editPermission = hasPermissions("customer", "customer", "put");
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    return (
      <React.Fragment>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          title={getMessage("customer.entityMetaData.successTitle")}
          information={getMessage("customer.entityMetaData.successInformation")}
          close={() => this.setState({ showSuccessDialog: false })}
          closeText={getMessage("customer.entityMetaData.ok")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          title={getMessage("customer.entityMetaData.errorTitle")}
          information={this.state.formError}
          close={() => this.setState({ showErrorDialog: false })}
          closeText={getMessage("customer.entityMetaData.ok")}
        />
        {isExtensionEnabled("CustomerTags") && <UserTags {...this.props} />}
        {isExtensionEnabled("CustomerTags") &&
          Object.keys(this.metaDataWithType).length > 0 && (
            <div className="horizontal-line">
              <hr />
            </div>
          )}
        {Object.keys(this.metaDataWithType).length > 0 && (
          <div className="customer-metadata-form">
            {
              <Form>
                <MetaDataFormFields
                  className="form-fields"
                  metaDataWithType={this.metaDataWithType}
                  page="customer-details-metadata"
                  _this={this}
                />
                <div className="form-actions">
                  <span>
                    {editPermission && (
                      <SubmitButton>
                        {getMessage("customer.entityMetaData.submit")}
                      </SubmitButton>
                    )}
                  </span>
                </div>
              </Form>
            }
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default MetaDataForm;
