import React from "react";
import {
  BaseForm,
  Input,
  Phone,
  CheckboxGroup,
  Select,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import { getDaysOfWeek } from "../../../../lib/datetime";

const getTimes = () => {
  let times = [];
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      times.push(`0${i}:00`);
      times.push(`0${i}:30`);
    } else {
      times.push(`${i}:00`);
      times.push(`${i}:30`);
    }
  }
  return times;
};

class PickerForm extends BaseForm {
  componentDidMount() {
    let values = Object.assign({}, this.state.values);
    if (values.shiftStart) {
      values.shiftStart = values.shiftStart.split(":").slice(0, 2).join(":");
    }
    if (values.shiftEnd) {
      values.shiftEnd = values.shiftEnd.split(":").slice(0, 2).join(":");
    }
    this.setState({
      values,
    });
  }
  render() {
    const { Form } = this.components;
    const { SubmitButton, CancelButton } = this.buttons;
    return (
      <Form>
        <div className="form-fields">
          <Input
            type="text"
            name="name"
            required
            label={getMessage("picker.name")}
            {...this.generateStateMappers({
              stateKeys: ["name"],
              loseEmphasisOnFill: true,
            })}
            readOnly
          />
          <Phone
            label={getMessage("picker.phone")}
            name="phone"
            {...this.generateStateMappers({
              stateKeys: ["phones", 0, "phone"],
              loseEmphasisOnFill: true,
            })}
            readOnly
          />
        </div>
        <CheckboxGroup
          label={getMessage("picker.weeklyOff")}
          options={getDaysOfWeek({})}
          {...this.generateStateMappers({
            stateKeys: ["weeklyOff"],
            loseEmphasisOnFill: true,
          })}
        />
        <div className="form-fields">
          <Select
            label={getMessage("shifts.form.startTime")}
            name="shiftStart"
            type="text"
            options={getTimes()}
            {...this.generateStateMappers({
              stateKeys: ["shiftStart"],
            })}
            required
          />
          <Select
            label={getMessage("shifts.form.endTime")}
            name="shiftStart"
            type="text"
            options={getTimes()}
            {...this.generateStateMappers({
              stateKeys: ["shiftEnd"],
            })}
            required
          />
        </div>
        <div className="form-actions">
          <SubmitButton>{getMessage("picker.submit")}</SubmitButton>
          <CancelButton>{getMessage("picker.cancel")}</CancelButton>
        </div>
      </Form>
    );
  }
}

export default PickerForm;
