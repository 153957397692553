import React from "react";
import { Upload, Input } from "../../../../components/Form";
import ImageWithText from "../../../../components/Layout/ImageWithText";
import { getMessage } from "../../../../lib/translator";

const ImageWithTextLayout = () => {
  return {
    preview: ImageWithText,
    fields: ({ getState, updateState }) => (
      <div className="form-sections">
        <div className="form-section">
          <Input
            label={getMessage("imagewithtext.title")}
            placeholder={getMessage("imagewithtext.placeholder")}
            value={getState(["title"]) || ""}
            onChange={(value) => {
              updateState(["title"], value);
            }}
          />
          <Upload
            label={getMessage("imagewithtext.image")}
            value={getState(["imageUrl"]) || ""}
            onChange={(value) => {
              updateState(["imageUrl"], value);
            }}
          />
        </div>
      </div>
    ),
  };
};

export default ImageWithTextLayout;
