import React from "react";

const LocationIcon = (props) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title></title>
    <g
      id="Add-a-store"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Navigation" transform="translate(-554.000000, -286.000000)">
        <g id="Group-21" transform="translate(252.000000, 286.000000)">
          <g id="Group-15" transform="translate(302.000000, 0.000000)">
            <rect
              id="Rectangle"
              stroke="#979797"
              strokeWidth="0.833333333"
              fill="#D8D8D8"
              opacity="0"
              x="0.416666667"
              y="0.416666667"
              width="19.1666667"
              height="19.1666667"
            ></rect>
            <g
              id="Group-12"
              transform="translate(3.333333, 3.333333)"
              stroke="#4AB819"
              strokeWidth="0.740740741"
            >
              <line
                x1="0"
                y1="6.66666667"
                x2="13.3333333"
                y2="6.66666667"
                id="Line"
                fill="#D8D8D8"
                strokeLinecap="round"
                transform="translate(6.666667, 6.666667) rotate(90.000000) translate(-6.666667, -6.666667) "
              ></line>
              <line
                x1="0"
                y1="6.2962963"
                x2="13.3333333"
                y2="6.2962963"
                id="Line"
                fill="#D8D8D8"
                strokeLinecap="round"
              ></line>
              <circle
                id="Oval"
                fill="#FFFFFF"
                cx="6.66666667"
                cy="6.66666667"
                r="5.55555556"
              ></circle>
              <circle
                id="Oval-Copy-2"
                fill="#4AB819"
                cx="6.66666667"
                cy="6.66666667"
                r="2.59259259"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LocationIcon;
