import React, { Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Loader from "../../../../../components/Loader";

export default class SimpleBarChart extends Component {
  render() {
    if (this.props.showLoader) {
      return <Loader />;
    } else {
      if (this.props.data.length > 0) {
        let bars = this.props.dataKeys.map((dataKey, index) => {
          return (
            <Bar
              barSize={6}
              key={index}
              dataKey={dataKey}
              fill={this.props.colors[index]}
            />
          );
        });

        return (
          <ResponsiveContainer height={300} width={"100%"}>
            <BarChart
              data={this.props.data}
              margin={{ top: 5, right: 30, left: -20, bottom: 5 }}
              barGap={4}
              barCategoryGap={0}
            >
              <XAxis dataKey="Name" />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend iconType="circle" />
              {bars}
            </BarChart>
          </ResponsiveContainer>
        );
      } else {
        return (
          <div className="nodata-warning">
            <small>No data available</small>
          </div>
        );
      }
    }
  }
}
