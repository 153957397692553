import React from "react";
import { Helmet } from "react-helmet";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BaseForm, Toggle, Input, Checkbox } from "../Form";
import Loader from "../Loader";
import API from "../../lib/api";
import { getMessage } from "../../lib/translator";
import AuthenticatedPage from "../../containers/AuthenticatedPage";
import ProviderConfiguration from "./ProviderConfiguration";
import { Dialog } from "../Popup";
import "./style.css";
import DropdownIcon from "./dropdown.svg";
import {
  disableExtension,
  enableExtension,
  getExtensionDetails,
  isExtensionEnabled,
} from "../../lib/auth";
import deleteIcon from "./icon-dustbin.svg";
import { Select } from "../../components/Form";

const DUNZO_WEBHOOK_CURL = `curl --location --request POST 'https://zopping.com/api/logistics-service/courier-event' \
--header 'developer-token: 28f39c97-67a5-49ea-ac12-e77363af0063' \
--header 'X-Client-Name: DUNZO' \
--header 'Content-Type: application/json' \
--data-raw '{
    "status": "completed",
}'`;

const COURIER_EVENT_URL =
  "https://zopping.com/api/logistics-service/courier-event";

export default class CourierSupport extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessDialog: false,
      showErrorDialog: false,
      formError: "",
      providerSelected: {
        providerFormVisible: "",
        providerActive: {},
      },
      validated: false,
      copied: false,
      shipRocketExtenson: false,
      courierServices: [],
      addedServices: [],
      deleteTarget: null,
    };
    this.logisticsServiceProviders = [];
    this.logisticsServiceProvidersCredential = {};
  }

  componentDidMount() {
    this.setState({ providersLoading: true });
    const shipRocketExtenson = isExtensionEnabled("ShipRocket");
    this.setState({ shipRocketExtenson: shipRocketExtenson });

    this.allProvidersApi = new API({
      url: "/logistics-service/logistics-provider",
    });
    this.websiteConfigApi = new API({
      url: "/config-service/config/logistics",
    });
    Promise.all([this.allProvidersApi.get(), this.websiteConfigApi.get()])
      .then(([allProvidersResponse, websiteConfigResponse]) => {
        this.logisticsServiceProviders =
          websiteConfigResponse.data.logistics.logisticsServiceProviders;
        this.logisticsServiceProvidersCredential = {
          ...websiteConfigResponse.data.logistics
            .logisticsServiceProvidersCredential,
        };
        this.setState({
          providersList: allProvidersResponse.data,
          courierServices: allProvidersResponse.data.logisticsProvider.map(
            (provider) => ({
              ...provider,
              text: provider.name,
              value: provider.id,
            })
          ),
          addedServices: Object.keys(
            websiteConfigResponse.data.logistics
              .logisticsServiceProvidersCredential || {}
          ),
        });
        if (
          websiteConfigResponse.data.logistics
            .logisticsServiceProvidersCredential &&
          Object.keys(
            websiteConfigResponse.data.logistics
              .logisticsServiceProvidersCredential
          ).length
        ) {
          const logisticsServiceProviders =
            websiteConfigResponse.data.logistics.logisticsServiceProviders;
          this.setState((prevState) => {
            let newState = Object.assign({}, prevState);
            logisticsServiceProviders.forEach((val) => {
              newState.providerSelected.providerActive[val] = true;
            });
            return newState;
          });
        }
      })
      .catch((error) => {
        this.setState({
          formError: error.message,
          showErrorDialog: true,
        });
        console.error(error);
      })
      .finally(() => {
        this.setState({ providersLoading: false });
      });
  }

  handleValidation = (params) => {
    const api = new API({ url: `/logistics-service/courier-health` });
    return api
      .post(params)
      .then(() => {
        this.setState({
          formError: "",
          validated: true,
        });
      })
      .catch((error) => {
        this.setState({
          formError: error.message,
          showErrorDialog: true,
        });
        console.error(error);
      });
  };

  getActiveProviders = () => {
    let providers = [];
    const providerActive = this.state.providerSelected.providerActive;
    if (Object.keys(providerActive).length) {
      Object.keys(providerActive).forEach((key) => {
        if (providerActive[key]) {
          providers.push(key);
        }
      });
    }
    return providers.filter(Boolean);
  };

  onSubmit = (data) => {
    this.setState({
      submitting: true,
    });

    const providers = [];
    const tempProvider = this.getActiveProviders();
    tempProvider.forEach((id) => {
      if (this.state.addedServices.includes(id)) {
        providers.push(id);
      }
    });

    let providersCreds = {};
    if (this.logisticsServiceProvidersCredential) {
      Object.keys(this.logisticsServiceProvidersCredential).forEach((key) => {
        if (key !== "" && this.state.addedServices.includes(key)) {
          providersCreds[key] = this.logisticsServiceProvidersCredential[key];
        }
      });
      if (this.state.providerSelected.providerFormVisible !== "") {
        providersCreds[this.state.providerSelected.providerFormVisible] = {
          ...data,
        };
      }
    } else {
      providersCreds[this.state.providerSelected.providerFormVisible] = {
        ...data,
      };
    }
    const api = new API({ url: `/config-service/config` });
    const params = {
      logistics: {
        logisticsServiceProviders: providers,
        logisticsServiceProvidersCredential: providersCreds,
      },
    };

    this.handleValidation(params).then(() => {
      if (this.state.validated) {
        api
          .post(params)
          .then(() => {
            this.logisticsServiceProviders = providers;
            this.logisticsServiceProvidersCredential = providersCreds;
            this.setState({
              touched: {},
              formError: "",
              validated: false,
              validations: {},
              showSuccessDialog: true,
            });
          })
          .catch((error) => {
            this.setState({ formError: error.message, showErrorDialog: true });
            console.error(error);
          })
          .finally(() => {
            this.setState({ submitting: false });
          });
      } else {
        this.setState({ submitting: false });
      }
    });
  };

  updateExtension = async () => {
    let extension = getExtensionDetails("ShipRocket");
    if (!extension) {
      this.setState({ providersLoading: true });
      const extensionData = new API({ url: `/account-service/extension` });
      await extensionData
        .get()
        .then((response) => {
          let slug = "ShipRocket";
          let extensionIndex = (response.data.extension || []).findIndex(
            (ext) => ext.slug === slug
          );
          extension =
            extensionIndex > -1
              ? response.data.extension[extensionIndex]
              : null;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        })
        .finally(() => {
          this.setState({ providersLoading: false });
        });
    }
    const extensionId = extension && extension.id;
    const extensionStatus = extension && extension.status;
    if (extensionId) {
      const api = new API({ url: `/account-service/extension/${extensionId}` });
      if (!this.state.shipRocketExtenson && extensionStatus === "ENABLED") {
        // make extension disable
        this.setState({ providersLoading: true });
        const params = { id: extensionId, status: "DISABLED" };
        api
          .put(params)
          .then((response) => {
            disableExtension(response.data.extension);
          })
          .catch((error) => {
            this.setState((prevState) => ({
              shipRocketExtenson: !prevState.shipRocketExtenson,
              formError: error.message,
              showErrorDialog: true,
            }));
            console.error(error);
          })
          .finally(() => {
            this.setState({ providersLoading: false });
          });
      } else if (
        this.state.shipRocketExtenson &&
        extensionStatus !== "ENABLED"
      ) {
        // make extension enable
        this.setState({ providersLoading: true });
        const params = { id: extensionId, status: "ENABLED" };
        api
          .put(params)
          .then((response) => {
            if (!getExtensionDetails("ShipRocket")) {
              enableExtension(response.data.extension);
            }
          })
          .catch((error) => {
            this.setState((prevState) => ({
              shipRocketExtenson: !prevState.shipRocketExtenson,
              formError: error.message,
              showErrorDialog: true,
            }));
          })
          .finally(() => {
            this.setState({ providersLoading: false });
          });
      }
    }
  };

  toggleExtension = () => {
    this.setState(
      (prevState) => ({
        shipRocketExtenson: !prevState.shipRocketExtenson,
      }),
      this.updateExtension
    );
  };

  updateProviderStatus = () => {
    let providers = this.getActiveProviders();
    const api = new API({ url: `/config-service/config` });
    const params = {
      logistics: {
        logisticsServiceProviders: providers,
      },
    };
    api
      .post(params)
      .then(() => {
        this.setState({
          formError: "",
        });
      })
      .catch((error) => {
        this.setState({ formError: error.message, showErrorDialog: true });
        console.error(error);
      });
  };

  toggleProviderStatus = (providerName) => {
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.providerSelected.providerActive[providerName] =
        !prevState.providerSelected.providerActive[providerName];
      return newState;
    }, this.updateProviderStatus);
  };

  closeDialogs = () => {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
      showWarningDialog: false,
      formError: "",
    });
  };

  handleCopy = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 1000);
    this.setState({ copied: true });
  };

  handleFormView = (providerName) => {
    let data = {};
    if (
      this.logisticsServiceProvidersCredential &&
      providerName in this.logisticsServiceProvidersCredential
    ) {
      data = { ...this.logisticsServiceProvidersCredential[providerName] };
    } else {
      if (providerName === "DUNZO") {
        data["processingFeePaymentMode"] = "COD";
      }
    }
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.providerSelected.providerFormVisible =
        providerName === newState.providerSelected.providerFormVisible
          ? null
          : providerName;
      newState.values = { ...data };
      newState.touched = {};
      newState.validations = {};
      return newState;
    });
  };

  onProviderChange = (courierName) => {
    if (!this.state.addedServices.includes(courierName)) {
      const newServices = [...this.state.addedServices, courierName];
      this.setState({
        addedServices: newServices,
      });
    }
  };

  handleProviderDelete = (id) => {
    const newServices = this.state.addedServices.filter(
      (service) => service !== id
    );
    this.setState({
      addedServices: newServices,
      showWarningDialog: false,
    });
  };

  handleDeleteDialog = (id) => {
    this.setState({
      showWarningDialog: true,
      deleteTarget: id,
    });
  };

  render() {
    const { Form } = this.components;
    const { SubmitButton, CancelButton } = this.buttons;
    const {
      shipRocketExtenson,
      providerSelected,
      courierServices,
      deleteTarget,
    } = this.state;

    return (
      <AuthenticatedPage
        className="CourierSupport"
        menu={this.props.menu}
        notShowHelmet={false}
      >
        <div>
          <Helmet title="Zopping - Shipments" />
          <h1 className="title">
            {getMessage("extensions.courier.support.heading")}
          </h1>

          <Form>
            <Dialog
              show={this.state.showSuccessDialog}
              className="success"
              information={getMessage("basicInfo.save.success.title")}
              close={this.closeDialogs}
              closeText={getMessage("notification.okay.text")}
            />
            <Dialog
              show={this.state.showErrorDialog}
              information={getMessage(this.state.formError)}
              close={this.closeDialogs}
              closeText={getMessage("notification.okay.text")}
            />
            <Dialog
              show={this.state.showWarningDialog}
              className="warn"
              message={getMessage("are.you.sure")}
              information={getMessage("deleteDialog.information")}
              close={this.closeDialogs}
              closeText={getMessage("notifications.cancel")}
              onOk={() => this.handleProviderDelete(deleteTarget)}
              okText="OK"
            />
            <div className="providerContainer">
              <div className="extension-toggle-wrp">
                <Checkbox
                  className="extension-toggle"
                  name="shipRocketExtension"
                  controlled
                  value={shipRocketExtenson}
                  onChange={this.toggleExtension}
                />
                <p>{getMessage("courier.support.extension.toggle.text")}</p>
              </div>
              {shipRocketExtenson && (
                <div className="selection">
                  <Select
                    name="provider"
                    label={getMessage("extensions.courier.support.heading")}
                    placeholder={getMessage(
                      "shipping.courier.support.placeholder"
                    )}
                    options={courierServices}
                    {...this.generateStateMappers({
                      stateKeys: ["provider"],
                      loseEmphasisOnFill: true,
                    })}
                    onChange={this.onProviderChange}
                  />
                </div>
              )}
              {this.state.providersLoading ? (
                <Loader />
              ) : (
                <>
                  {shipRocketExtenson &&
                    this.state?.providersList?.logisticsProvider?.map(
                      (data) => {
                        const { name, id, image } = data;
                        const submitDisable = !Boolean(
                          providerSelected.providerActive[id]
                        );
                        if (this.state.addedServices.includes(id)) {
                          return (
                            <div className="HeaderContainer" key={id}>
                              <div className="header">
                                <div>
                                  <img
                                    className={
                                      id ===
                                      this.state.providerSelected
                                        .providerFormVisible
                                        ? "selectedDropdown"
                                        : "notselectedDropdown"
                                    }
                                    src={DropdownIcon}
                                    onClick={() => this.handleFormView(id)}
                                    alt=""
                                  />
                                  <img
                                    src={image}
                                    className="CourierLogo"
                                    alt=""
                                  />
                                  <span className="courierName">{name}</span>
                                </div>
                                <div className="action-container">
                                  <div
                                    className="delete-button"
                                    onClick={() => {
                                      this.handleDeleteDialog(id);
                                    }}
                                  >
                                    <img
                                      src={deleteIcon}
                                      alt="x"
                                      className="product-delete-icon"
                                    />
                                    <span className="product-delete-button-text">
                                      {getMessage("delete.text")}
                                    </span>
                                  </div>
                                  <Toggle
                                    name="serviceProvider"
                                    className="provider-toggle"
                                    value={Boolean(
                                      this.state.providerSelected
                                        .providerActive[id]
                                    )}
                                    icons={false}
                                    onChange={() =>
                                      this.toggleProviderStatus(id)
                                    }
                                  />
                                </div>
                              </div>
                              {id ===
                                this.state.providerSelected
                                  .providerFormVisible && (
                                <div className="FormContainer">
                                  {id === "DUNZO" && (
                                    <div className="webhook-wrp">
                                      <Input
                                        value={COURIER_EVENT_URL}
                                        label={getMessage("webhook.url")}
                                        readOnly
                                      >
                                        {getMessage(
                                          "courier.support.DUNZO.webhook.url"
                                        )}
                                      </Input>
                                      <CopyToClipboard
                                        text={DUNZO_WEBHOOK_CURL}
                                        onCopy={this.handleCopy}
                                      >
                                        <span className="copy">
                                          {getMessage(
                                            this.state.copied
                                              ? "copied"
                                              : "copy"
                                          )}
                                        </span>
                                      </CopyToClipboard>
                                    </div>
                                  )}
                                  <ProviderConfiguration
                                    parent={this}
                                    selectedProviderConfig={data}
                                  />
                                  <SubmitButton
                                    disabled={
                                      this.state.submitting || submitDisable
                                    }
                                  >
                                    {getMessage("notifications.submitText")}
                                  </SubmitButton>
                                </div>
                              )}
                            </div>
                          );
                        }
                        return "";
                      }
                    )}
                </>
              )}
              {shipRocketExtenson && (
                <div>
                  <SubmitButton disabled={this.state.submitting}>
                    {getMessage("notifications.save")}
                  </SubmitButton>
                  <CancelButton>
                    {getMessage("notifications.cancel")}
                  </CancelButton>
                </div>
              )}
            </div>
          </Form>
        </div>
      </AuthenticatedPage>
    );
  }
}
