import React from "react";

export default function GreenImage({ className }) {
  return (
    <svg
      width="240px"
      height="300px"
      viewBox="0 0 240 300"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <title>Red</title>
      <g id="Red" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-10">
          <path
            d="M239.5,0.5 L239.5,299.5 L0.5,299.5 L0.5,0.5 L239.5,0.5 Z"
            id="Rectangle-4"
            stroke="#F8E6E6"
            fill="#FFEEEE"
          ></path>
          <g
            id="Group-53"
            opacity="0.200000003"
            transform="translate(45.000000, 30.000000)"
          >
            <rect
              id="Rectangle-46"
              stroke="#979797"
              fill="#D8D8D8"
              opacity="0"
              x="0.5"
              y="0.5"
              width="149"
              height="149"
            ></rect>
            <g
              id="placeholder_geniric"
              transform="translate(12.000000, 24.000000)"
              fill="#80959D"
              fillRule="nonzero"
            >
              <g id="Group-18" transform="translate(0.000000, 44.011765)">
                <path
                  d="M6.07058824,50.0823529 L74.3647059,50.0823529 L74.3647059,6.07058824 L6.07058824,6.07058824 L6.07058824,50.0823529 Z M0,0 L80.4352941,0 L80.4352941,56.1529412 L0,56.1529412 L0,0 Z"
                  id="Rectangle-13-Copy-4"
                ></path>
                <polygon
                  id="Path-18-Copy"
                  points="13.6588235 40.9764706 22.7663663 15.1764706 39.1209229 33.6543712 48.5647059 29.1127951 48.5647059 40.9764706"
                ></polygon>
                <circle
                  id="Oval-9-Copy"
                  cx="62.2235294"
                  cy="21.2470588"
                  r="6.07058824"
                ></circle>
              </g>
              <polygon
                id="Path-18"
                opacity="0.5"
                points="52.2819814 10.4855479 117.116661 47.1909973 92.947109 88.2194874 84.0278679 83.4344886 84.605409 91.5691198 96.1644632 97.7230807 127.572242 44.3903967 49.4685356 0.172831839 26.5601548 40.6225154 35.9130142 38.8778227"
              ></polygon>
              <circle
                id="Oval-9-Copy-4"
                opacity="0.5"
                cx="94.0941176"
                cy="56.1529412"
                r="6.07058824"
              ></circle>
              <polygon
                id="Path-20-Copy"
                opacity="0.5"
                points="50.0823529 37.9411765 65.2588235 37.9411765 62.5018384 25.8"
              ></polygon>
            </g>
          </g>
          <rect
            id="Rectangle-24"
            fill="#80959D"
            opacity="0.200000003"
            x="30"
            y="213"
            width="160"
            height="10"
          ></rect>
          <rect
            id="Rectangle-24"
            fill="#80959D"
            opacity="0.100000001"
            x="30"
            y="233"
            width="120"
            height="10"
          ></rect>
        </g>
      </g>
    </svg>
  );
}
