import React, { useState } from "react";
import { getMessage } from "../../../../../lib/translator";
import { Popup as Modal } from "../../../../../components/Popup";
import getFileExtensionFromUrl from "../../../../../utils/getFileExtensionFromUrl";
import "./style.css";

const UploadedFileView = (props) => {
  const { orderDetailData } = props;
  const accessUrl = orderDetailData?.accessUrl;
  const fileType = accessUrl && getFileExtensionFromUrl(accessUrl);

  const [viewUploadedFile, setViewUploadedFile] = useState(false);
  const [showViewButton, setViewButton] = useState(false);

  const handleViewUploadedFile = () => {
    setViewUploadedFile(!viewUploadedFile);
    setViewButton(false);
  };
  return (
    <>
      {viewUploadedFile && (
        <Modal
          show
          close={handleViewUploadedFile}
          heading={getMessage("orderDetails.uploadFile")}
        >
          <div className="view-uploaded-file-wrp">
            {fileType && fileType === "pdf" ? (
              <embed src={accessUrl} className="upload-file-large" />
            ) : (
              <img
                src={accessUrl}
                className="upload-file-large"
                alt="uploaded-file"
              />
            )}
          </div>
        </Modal>
      )}
      <div className="file-upload-wrp">
        {accessUrl && (
          <div className="file-body-wrp" onClick={handleViewUploadedFile}>
            <div
              className="file-pdf-wrp"
              onMouseLeave={() => setViewButton(false)}
            >
              {fileType && fileType === "pdf" ? (
                <embed
                  src={accessUrl}
                  className="upload-file"
                  onMouseEnter={() => setViewButton(true)}
                  onTouchEnd={() => setViewButton(true)}
                />
              ) : (
                <img
                  src={accessUrl}
                  className="upload-file"
                  onMouseEnter={() => setViewButton(true)}
                  alt="file"
                />
              )}
              {showViewButton && (
                <div className="file-view-pdf-btn-wrp">
                  <button type="button" className="file-view-pdf-btn">
                    {getMessage("dispute.view")}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadedFileView;
