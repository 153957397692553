import React from "react";
import { Link } from "react-router-dom";
import { getMessage } from "../../../lib/translator";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import { getSession } from "../../../lib/auth";
import Change from "./change";
import Customize from "./customize";
import CategorySequence from "../../catalogue/Categories/CategorySequencing";
import "./style.css";

function getPage(action) {
  const pages = {
    change: Change,
    customize: Customize,
    navigation: CategorySequence,
  };
  return pages[action];
}

function getThemeDetails() {
  const org = getSession().organization;
  return org.theme;
}

export default function (props) {
  const Page = getPage(props.match.params.action);
  const theme = getThemeDetails();

  return Page ? (
    <Page {...props} theme={theme} />
  ) : (
    <AuthenticatedPage
      menu={props.menu}
      className="website"
      title={getMessage("themes.heading")}
    >
      <h1 className="title">{getMessage("themes.heading")}</h1>
      <div className="box">
        <img src={theme.image.desktop} alt="theme" />
        <h2>{getMessage("themes.subheading", { name: theme.name })}</h2>
        <div className="actions">
          <Link to="/settings/themes/change" className="button">
            {getMessage("themes.change.text")}
          </Link>
          <Link to="/settings/themes/customize" className="primary button">
            {getMessage("themes.customize.text")}
          </Link>
        </div>
      </div>
    </AuthenticatedPage>
  );
}
