import React from "react";
import { BaseForm } from "../../../../../../components/Form";
import { getMessage } from "../../../../../../lib/translator";
import API from "../../../../../../lib/api";
import Map from "../../../../../../containers/CustomerWidgets/AddressMap";
import "./style.css";

class MapForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      formError: "",
      submitting: false,
    };
    let lat = props.coordinates && Number(props.coordinates.lat);
    let lng = props.coordinates && Number(props.coordinates.lng);
    if (lat && lng) {
      this.state.values = {
        location: {
          lat: lat,
          lng: lng,
        },
      };
    }
    ["setNewLocation", "showError"].forEach((fn) => {
      this[fn] = this[fn].bind(this);
    });
  }

  setNewLocation(lat, lng) {
    let values = { ...this.state.values };
    values.location = { lat, lng };
    this.setState({
      values,
      formError: null,
    });
  }

  showError(error) {
    this.setState({
      formError: error,
    });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    const api = new API({
      url: `/customer-service/address/${this.props.addressId}`,
    });
    let params = {};
    let lat = null;
    let lng = null;
    if (this.state.values && this.state.values.location) {
      lat = this.state.values.location.lat;
      lng = this.state.values.location.lng;
    }

    params["latitude"] = lat;
    params["longitude"] = lng;
    params["customerId"] = this.props.customerId; // For tripPlanner page, customerId is required
    api
      .put(params)
      .then((response) => {
        this.setState({
          submitting: false,
        });
        this.props.onSuccess(
          response.data.address.latitude,
          response.data.address.longitude
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    let { address, isTripPlannerPage, stores, deliveryAreas, storeId } =
      this.props;
    let coor =
      this.state.values && this.state.values.location
        ? this.state.values.location
        : null;
    return (
      <div className="MapForm">
        <div className="address-heading">{address}</div>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        <Map
          address={address}
          showError={this.showError}
          draggable
          setNewLocation={this.setNewLocation}
          searchBox
          coor={coor}
          isTripPlannerPage={isTripPlannerPage}
          stores={stores}
          deliveryAreas={deliveryAreas}
          storeId={storeId}
        />
        <Form>
          <div className="form-buttons-container">
            <SubmitButton
              disabled={Boolean(!this.state.values || this.state.submitting)}
            >
              {getMessage("save")}
            </SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}
export default MapForm;
