import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
} from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";

class CreateNewPackageForm extends BaseForm {
  componentDidMount() {
    const { editPackage } = this.props;
    if (editPackage) {
      this.setState({
        values: {
          ...editPackage,
        },
      });
    }
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const typePattern = "^([a-zA-Z0-9])[a-zA-Z0-9 ]*$";
    const { submitting, editPackage } = this.props;
    const {
      validations: { length = {}, breadth = {}, height = {}, type = {} } = {},
    } = this.state;

    const disableSubmit =
      !(length.valid && breadth.valid && height.valid && type.valid) ||
      submitting;

    return (
      <Form>
        <Input
          label={getMessage("orderPackageConfig.length.label")}
          placeholder={getMessage("orderPackageConfig.length.placeholder")}
          name="length"
          type="number"
          step="0.01"
          min="0.00"
          required
          {...this.generateStateMappers({
            stateKeys: ["length"],
            validationType: VALIDATION_TYPES.ONBLUR,
            loseEmphasisOnFill: true,
          })}
          validationStrings={{
            valueMissing: getMessage("input.requiredMessage"),
          }}
        />
        <Input
          label={getMessage("orderPackageConfig.breadth.label")}
          placeholder={getMessage("orderPackageConfig.breadth.placeholder")}
          name="breadth"
          type="number"
          step="0.01"
          min="0.00"
          required
          {...this.generateStateMappers({
            stateKeys: ["breadth"],
            validationType: VALIDATION_TYPES.ONBLUR,
            loseEmphasisOnFill: true,
          })}
          validationStrings={{
            valueMissing: getMessage("input.requiredMessage"),
          }}
        />
        <Input
          label={getMessage("orderPackageConfig.height.label")}
          placeholder={getMessage("orderPackageConfig.height.placeholder")}
          name="height"
          type="number"
          step="0.01"
          min="0.00"
          required
          {...this.generateStateMappers({
            stateKeys: ["height"],
            validationType: VALIDATION_TYPES.ONBLUR,
            loseEmphasisOnFill: true,
          })}
          validationStrings={{
            valueMissing: getMessage("input.requiredMessage"),
          }}
        />
        <Input
          label={getMessage("orderPackageConfig.type.label")}
          placeholder={getMessage("orderPackageConfig.type.placeholder")}
          name="type"
          type="text"
          required
          {...this.generateStateMappers({
            stateKeys: ["type"],
            validationType: VALIDATION_TYPES.ONCHANGE,
            loseEmphasisOnFill: true,
          })}
          pattern={typePattern}
          validationStrings={{
            valueMissing: getMessage("input.requiredMessage"),
            patternMismatch: getMessage("product.form.packageType.pattern"),
          }}
        />
        <SubmitButton disabled={disableSubmit}>
          {submitting
            ? `${getMessage(
                editPackage
                  ? "orderPackageConfig.package.editing"
                  : "orderPackageConfig.package.creating"
              )}...`
            : getMessage("orderPackageConfig.submit.text")}
        </SubmitButton>
      </Form>
    );
  }
}

export default CreateNewPackageForm;
