import React, { Component } from "react";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { getMessage } from "../../../lib/translator";
import { formatDate, tableProperties } from "./utils.js";
import StoreSelector, {
  getDefaultStore,
} from "../../../containers/StoreSelector";
import { utcToLocalTime, getStandradDateTime } from "../../../lib/datetime";

import emptyIcon from "./picking-queue.svg";
import "./style.css";

const today = formatDate(new Date());
const emptyState = (props) => ({
  icon: emptyIcon,
  message: getMessage("operations.pickingQueue.emptyList.message"),
  actions: ({ onAction, apiParams }) => (
    <StoreSelector
      value={apiParams.storeId}
      stores={props.stores}
      onChange={(storeId) => {
        onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId });
      }}
    />
  ),
});
function getMobileView() {
  return window.screen.width <= 480;
}

class OrderPickingQueue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: getMobileView(),
    };
    this.mobileView = this.mobileView.bind(this);
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() });
  }

  componentDidMount() {
    window.addEventListener("resize", this.mobileView, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.mobileView, false);
  }
  render() {
    let props = this.props;
    return (
      <ListingPage
        className="picking-queue-page"
        menu={props.menu}
        title={getMessage("operations.pickingQueue.heading")}
        api={{
          url: `/order-service/picking-queue/${today}`,
          params: getDefaultStore(props.stores),
          transform: (response) => {
            const queue = response.data.orderQueue;
            if (Array.isArray(queue)) {
              queue.map((obj) => {
                if (obj.createdAt) {
                  obj.createdAt = getStandradDateTime(
                    utcToLocalTime,
                    obj.createdAt
                  );
                }
                if (obj.completedAt) {
                  obj.completedAt = getStandradDateTime(
                    utcToLocalTime,
                    obj.completedAt
                  );
                }
                if (obj.preferredDate && obj.slotStartTime) {
                  const tempDate = getStandradDateTime(
                    utcToLocalTime,
                    `${obj.preferredDate} ${obj.slotStartTime}`
                  ).split(" ");
                  obj.preferredDate = tempDate[0];
                  obj.slotStartTime = tempDate[1];
                }
                if (obj.preferredDate && obj.slotEndTime) {
                  const tempDate = getStandradDateTime(
                    utcToLocalTime,
                    `${obj.preferredDate} ${obj.slotEndTime}`
                  ).split(" ");
                  obj.slotEndTime = tempDate[1];
                }
                return obj;
              });
            }
            return queue;
          },
        }}
        dontSavePagination
        emptyState={emptyState(props)}
        storeDependent
        primaryKey="referenceNumber"
        tableProperties={tableProperties(this.state.isMobileView)}
      />
    );
  }
}

export default OrderPickingQueue;
