import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Email as CustomerEmailWidget,
  Call as CustomerCallWidget,
} from "../../../../../containers/CustomerWidgets";
import { Radio, Select } from "../../../../../components/Form";
import { SKINS as RADIO_SKINS } from "../../../../../components/Form/Inputs/Radio";
import { getMessage } from "../../../../../lib/translator";
import {
  isExtensionEnabled,
  getExtensionDetails,
  getStores,
} from "../../../../../lib/auth";
import API from "../../../../../lib/api";
import {
  formatDate,
  getPrintableTime,
  formatIsoTime,
  getStandradDateTime,
  utcToLocalTime,
  formatSlotTime,
  localTimeToUtc,
} from "../../../../../lib/datetime";

import editIcon from "../edit-icon.svg";
import {
  getDisplayAddress,
  getAsapDuration,
  sortSlotsAvailability,
} from "../../../../../lib/commonlyused";

function generateDateOptions(slots) {
  if (!slots) {
    return [];
  } else {
    return Object.keys(slots).map((date) => {
      let text = formatDate(date);
      if (moment().format("YYYY-MM-DD") === date) {
        text = getMessage("order.details.today");
      } else if (moment().add(1, "days").format("YYYY-MM-DD") === date) {
        text = getMessage("order.details.tomorrow");
      }
      return { text: text, value: date };
    });
  }
}
class DeliveryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.orderTypes = [
      {
        text: getMessage("order.details.location.option.delivery"),
        value: "DELIVERY",
      },
    ];
    if (isExtensionEnabled("DeliverySlots")) {
      this.state.addressSlotMap = {
        DELIVERY: {},
        PICKUP: {},
      };
    }
    this.startEditing = this.startEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
    this.getAddressParams = this.getAddressParams.bind(this);
    this.formatSlotEndTime = this.formatSlotEndTime.bind(this);
    this.getPickupParams = this.getPickupParams.bind(this);
    this.promisifySetState = this.promisifySetState.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleChangeSelectedStore = (id) => {
    this.setState({ selectedDeliveryStore: id });
  };

  onChange(id) {
    let { visibleDaysForSlot, orderType } = this.state;

    if (isExtensionEnabled("DeliverySlots")) {
      const addressApi = new API({ url: "/order-service/slot-availability" });
      if (!this.state.addressSlotMap[orderType][id]) {
        let params = {};
        if (orderType === "DELIVERY") {
          let addressIndex = this.state.customer.addresses.findIndex(
            (address) => Number(address.id) === Number(id)
          );
          params = this.getAddressParams(id, visibleDaysForSlot, addressIndex);
        } else if (orderType === "PICKUP") {
          params = this.getPickupParams(id, visibleDaysForSlot);
        }

        addressApi
          .get(params)
          .then((response) => {
            let addressSlots = sortSlotsAvailability(response.data.slots);
            let slots = {};
            addressSlots &&
              addressSlots.map((slot) => {
                if (slot.available) {
                  if (slots.hasOwnProperty(slot.date)) {
                    slots[slot.date].push(slot);
                  } else {
                    slots[slot.date] = [];
                    slots[slot.date].push(slot);
                  }
                }
                return null;
              });
            let addressSlotMap = JSON.parse(
              JSON.stringify(this.state.addressSlotMap)
            );
            addressSlotMap[orderType][id] = slots;
            this.dateOptions[orderType][id] = generateDateOptions(slots);

            this.setState({
              addressSlotMap,
            });
          })
          .catch((error) => {
            console.error(error);
            if (error.code === 401) {
              throw error;
            } else {
              this.setState({
                error: error.message,
              });
            }
          });
      }
    }

    if (this.state.orderType === "DELIVERY") {
      let addressIndex = this.state.customer.addresses.findIndex(
        (address) => Number(address.id) === Number(id)
      );
      this.setState({
        addressIndex,
        addressId: id,
        orderType: "DELIVERY",
        preferredSlotId: undefined,
      });
    } else if (this.state.orderType === "PICKUP") {
      let pickupLocationIndex =
        this.state.pickupLocations &&
        this.state.pickupLocations?.findIndex(
          (pickupAddress) => Number(pickupAddress.id) === Number(id)
        );
      this.setState({
        pickupLocationIndex,
        pickupLocationId: id,
        orderType: "PICKUP",
        preferredSlotId: undefined,
      });
    }
  }

  promisifySetState(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  getAddressParams(addressId, visibleDaysForSlot, addressIndex) {
    let params = {};
    const { data } = this.props;
    const { customer } = this.state;
    let address =
      customer && customer.addresses && customer.addresses[addressIndex];
    params["startDate"] = moment().toISOString();
    params["endDate"] = moment().add(visibleDaysForSlot, "days").toISOString();
    params["storeId"] = data.storeId;
    params["orderType"] = "DELIVERY";
    params["searchInterval"] = params["startDate"] + "/" + params["endDate"];
    // Optional Parameters
    params["address[address]"] = address.address;
    params["address[landmark]"] = address.landmark;
    params["address[city]"] = address.city;
    params["address[pincode]"] = address.pincode?.toString();
    params["address[latitude]"] = address.latitude;
    params["address[longitude]"] = address.longitude;
    delete params["startDate"];
    delete params["endDate"];
    return params;
  }

  getPickupParams(pickupId, visibleDaysForSlot) {
    let params = {};
    let { pickupLocations } = this.state;
    if (!pickupLocations) {
      return params;
    }
    params["startDate"] = moment().toISOString();
    params["endDate"] = moment().add(visibleDaysForSlot, "days").toISOString();
    params["storeId"] = pickupId;
    params["orderType"] = "PICKUP";
    params["searchInterval"] = params["startDate"] + "/" + params["endDate"];
    delete params["startDate"];
    delete params["endDate"];
    return params;
  }

  async componentDidMount() {
    const { data } = this.props;
    let dateOptions = { DELIVERY: {}, PICKUP: {} };

    let addressSlotMap = {
      DELIVERY: {},
      PICKUP: {},
    };

    let newStates = this.generateStateFromProps(this.props, {
      editing: false,
      saving: false,
    });

    await this.promisifySetState(newStates);

    if (isExtensionEnabled("DeliverySlots")) {
      let extnId = getExtensionDetails("DeliverySlots").id;
      this.extnApi = new API({ url: `/account-service/extension/${extnId}` });
      await this.extnApi
        .get()
        .then((response) => {
          let visibleDaysForSlot =
            (response.data.extension.config.globalConfig &&
              response.data.extension.config.globalConfig.visibleDaysForSlot) ||
            null;
          // visibleDaysForSlot = 5 // TODO: Remove this
          this.setState({
            visibleDaysForSlot,
          });
        })
        .catch((err) => console.error(err));

      if (this.state.visibleDaysForSlot) {
        let pickupLocations = getStores();
        if (pickupLocations?.length) {
          await this.promisifySetState({
            stores: pickupLocations,
            selectedDeliveryStore: data?.storeId,
          });
        }

        if (
          pickupLocations &&
          pickupLocations.length > 0 &&
          this.props.orderConfig &&
          this.props.orderConfig.orderTypes &&
          this.props.orderConfig.orderTypes.includes("PICKUP")
        ) {
          this.orderTypes.push({
            text: getMessage("order.details.location.option.pickup"),
            value: "PICKUP",
          });
          let pickupLocationId = data.pickupLocation && data.pickupLocation.id;
          let pickupLocationIndex;
          if (pickupLocationId) {
            pickupLocationIndex =
              pickupLocations &&
              pickupLocations.findIndex(
                (loc) => loc && loc.id === pickupLocationId
              );
          }

          await this.promisifySetState({
            pickupLocations: pickupLocations,
            pickupLocationIndex,
            pickupLocationId,
          });
          if (this.props.data.pickupLocation) {
            this.orderSlotApi = new API({
              url: "/order-service/slot-availability",
            });
            let params = this.getPickupParams(
              this.props.data.pickupLocation.id,
              this.state.visibleDaysForSlot
            ); // TODO: remove asap param
            this.orderSlotApi
              .get(params)
              .then((response) => {
                let addressSlots = sortSlotsAvailability(response.data.slots);
                let slots = {};
                addressSlots &&
                  addressSlots.map((slot) => {
                    if (slot.available) {
                      if (slots.hasOwnProperty(slot.date)) {
                        slots[slot.date].push(slot);
                      } else {
                        slots[slot.date] = [];
                        slots[slot.date].push(slot);
                      }
                    }
                    return null;
                  });
                let today = new Date();
                let preferredDate =
                  this.state.preferredDate &&
                  moment(this.state.preferredDate).format("YYYY-MM-DD");
                if (preferredDate < today) {
                  preferredDate = moment().format("YYYY-MM-DD");
                }
                addressSlotMap.PICKUP[data.pickupLocation.id] = slots;
                dateOptions["PICKUP"][data.pickupLocation.id] =
                  generateDateOptions(slots);

                this.setState({
                  addressSlotMap,
                  preferredDate,
                });
              })
              .catch((err) => console.error(err));
          }
        }

        if (data.address && (this.state.addressId || this.state.addressIndex)) {
          this.orderSlotApi = new API({
            url: "/order-service/slot-availability",
          });
          let params = this.getAddressParams(
            data.address.id,
            this.state.visibleDaysForSlot,
            0
          );
          this.orderSlotApi
            .get(params)
            .then((response) => {
              let addressSlots = sortSlotsAvailability(response.data.slots);
              let today = new Date();
              let preferredDate =
                this.state.preferredDate &&
                moment(this.state.preferredDate).format("YYYY-MM-DD");
              if (preferredDate < today) {
                preferredDate = moment().format("YYYY-MM-DD");
              }

              let slots = {};
              addressSlots &&
                addressSlots.map((slot) => {
                  if (slot.available) {
                    if (slots.hasOwnProperty(slot.date)) {
                      slots[slot.date].push(slot);
                    } else {
                      slots[slot.date] = [];
                      slots[slot.date].push(slot);
                    }
                  }
                  return null;
                });
              addressSlotMap.DELIVERY[data.address.id] = slots;
              dateOptions["DELIVERY"][data.address.id] =
                generateDateOptions(slots);
              this.setState({
                addressSlotMap,
                preferredDate,
                error: null,
              });
            })
            .catch((error) => {
              console.error(error);
              if (error.code === 401) {
                throw error;
              }
              this.setState({
                error: error.message,
              });
            });
        }
      }
    }
    this.dateOptions = dateOptions;
  }

  startEditing() {
    this.setState({ editing: true, preferredSlotId: null });
  }
  cancelEditing() {
    this.setState({
      editing: false,
    });
  }
  formatSlotEndTime(time) {
    return (
      parseInt(time.split(":")[1], 10) + "" + parseInt(time.split(":")[2], 10)
    );
  }

  getUtcdate() {
    let utcDate = this.state.preferredDate;
    this.state.allSlots.forEach((val) => {
      if (`${val.id}` === this.state.preferredSlotId) {
        const timeArr = val.text.split(" ");
        if (timeArr.includes("Within")) {
          let currentLocal = moment(
            localTimeToUtc(moment().local().format("YYYY-MM-DD HH:mm:ss"))
          ).format("YYYY-MM-DD HH:mm:ss");
          utcDate = currentLocal.split(" ")[0].trim();
        } else {
          const finalTime = moment(
            utcToLocalTime(`${val.date} ${val.interval.split(/[TZ]/)[1]}`)
          )
            .format("YYYY-MM-DD HH:ss:mm")
            .split(" ")[1];
          const tempDate = `${this.state.preferredDate} ${finalTime}`;
          utcDate = getStandradDateTime(localTimeToUtc, tempDate).split(" ")[0];
        }
      }
    });
    return utcDate;
  }

  submitChanges() {
    let { orderType, selectedDeliveryStore } = this.state;
    const { data } = this.props;
    if (this.props.onChange) {
      // onChange method should return a promise
      this.setState(
        {
          // editing: false,
          saving: true,
        },
        () => {
          let requestParams = {};
          let storeRequestParams = {};
          let storeRequestHeaders = {};
          if (
            orderType === "DELIVERY" &&
            Number.isFinite(this.state.addressIndex)
          ) {
            requestParams.addressId =
              this.state.customer.addresses &&
              this.state.customer.addresses[this.state.addressIndex].id;
            requestParams.type = "DELIVERY";
            if (isExtensionEnabled("DeliverySlots")) {
              requestParams.preferredDate = this.getUtcdate();
              requestParams.preferredSlotId = Number(
                this.state.preferredSlotId
              );
            }
            const { org } = this.props;
            const organizationId = org.id.toString();
            storeRequestParams.storeId = parseInt(
              this.state.selectedDeliveryStore
            );
            storeRequestParams.organizationId = organizationId;
            storeRequestHeaders["X-API-VERSION"] = "2";
          } else if (
            orderType === "PICKUP" &&
            Number.isFinite(Number(this.state.pickupLocationId))
          ) {
            // TODO: Add logic to select a pickup location
            requestParams.pickupLocationId =
              this.state.pickupLocations[this.state.pickupLocationIndex].id;
            requestParams.type = "PICKUP";
            if (isExtensionEnabled("DeliverySlots")) {
              requestParams.preferredSlotId = Number(
                this.state.preferredSlotId
              );
              requestParams.preferredDate = this.getUtcdate();
            }
          }
          if (this.props.data.status !== "PENDING") {
            delete requestParams.addressId;
          }

          if (this.state.preferredSlotId) {
            this.props
              .onChange(requestParams, {})
              .then(
                () => {
                  this.setState({ error: false, saving: false });
                },
                (error) => {
                  this.setState({ error: true, saving: false });
                  if (error.code === 401) {
                    throw error;
                  }
                }
              )
              .then(() => {
                if (parseInt(selectedDeliveryStore) === data?.storeId) {
                  this.setState({ editing: false, saving: false });
                }
              });
          }

          if (parseInt(selectedDeliveryStore) !== data?.storeId) {
            this.props
              .onChange(storeRequestParams, storeRequestHeaders)
              .then(
                () => {
                  this.setState({ error: false, saving: false });
                },
                (error) => {
                  this.setState({ error: true, saving: false });
                  if (error.code === 401) {
                    throw error;
                  }
                }
              )
              .then(() => {
                this.setState({ editing: false, saving: false });
              });
          }
        }
      );
    }
  }
  generateStateFromProps(props, state) {
    let addressId = props.data.address ? props.data.address.id : null;
    let pickupId = props.data.pickupLocation
      ? props.data.pickupLocation.id
      : null;
    let addressIndex;
    let pickupLocationIndex;
    if (addressId) {
      addressIndex =
        props.data.customer &&
        props.data.customer.addresses &&
        props.data.customer.addresses.findIndex(
          (address) => address.id === props.data.address.id
        );
      if (addressIndex === -1) {
        addressIndex = undefined;
      }
    }
    if (pickupId && state.pickupLocations) {
      pickupLocationIndex =
        state.pickupLocations &&
        state.pickupLocations.findIndex(
          (loc) => loc.id === props.data.pickupLocation.id
        );
      if (pickupLocationIndex === -1) {
        pickupLocationIndex = null;
      }
    }
    let newState = JSON.parse(JSON.stringify(state));
    newState = {
      ...newState,
      addressIndex,
      addressId,
      pickupLocationIndex,
      pickupLocationId: pickupId,
      pickupLocation: props.data.pickupLocation,
      customer: props.data.customer,
      orderType: props.data.type,
    };

    if (isExtensionEnabled("DeliverySlots") && !state.preferredDate) {
      newState.preferredDate =
        props.data.preferredDate < moment().format("YYYY-MM-DD")
          ? moment().format("YYYY-MM-DD")
          : props.data.preferredDate;
      newState.preferredSlotId =
        props.data.preferredSlot && props.data.preferredSlot.id;
    }
    return newState;
  }
  componentWillReceiveProps(newProps) {
    if (newProps.data.address || newProps.data.pickupLocation) {
      this.setState((prevState) =>
        this.generateStateFromProps(newProps, prevState)
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { customer, visibleDaysForSlot, orderType } = this.state;
    if (prevState.orderType !== this.state.orderType) {
      if (orderType === "DELIVERY") {
        if (
          visibleDaysForSlot &&
          !this.state.addressSlotMap[orderType][this.state.addressId]
        ) {
          this.slotApi = new API({ url: "/order-service/slot-availability" });
          let addressId =
            customer &&
            customer.addresses &&
            customer.addresses[0] &&
            customer.addresses[0].id;
          if (addressId && this.props.data.storeId) {
            let params = this.getAddressParams(
              addressId,
              visibleDaysForSlot,
              0
            );
            this.slotApi
              .get(params)
              .then((response) => {
                let addressSlots = sortSlotsAvailability(response.data.slots);
                let slots = {};
                addressSlots &&
                  addressSlots.map((slot) => {
                    if (slot.available) {
                      if (slots.hasOwnProperty(slot.date)) {
                        slots[slot.date].push(slot);
                      } else {
                        slots[slot.date] = [];
                        slots[slot.date].push(slot);
                      }
                    }
                    return null;
                  });
                let addressSlotMap = JSON.parse(
                  JSON.stringify(this.state.addressSlotMap)
                );
                let today = new Date();
                let preferredDate =
                  this.state.preferredDate &&
                  moment(this.state.preferredDate).format("YYYY-MM-DD");
                if (!preferredDate || preferredDate < today) {
                  preferredDate = moment().format("YYYY-MM-DD");
                }
                addressSlotMap.DELIVERY[addressId] = slots;
                this.dateOptions["DELIVERY"][addressId] =
                  generateDateOptions(slots);
                let addressNotServed = addressSlots.length === 0;
                this.setState({
                  addressSlotMap,
                  preferredDate,
                  addressId,
                  addressNotServed,
                  error: null,
                  preferredSlotId: undefined,
                });
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      } else if (orderType === "PICKUP") {
        if (
          visibleDaysForSlot &&
          this.state.addressSlotMap[orderType] &&
          !this.state.addressSlotMap[orderType][this.state.pickupLocationId]
        ) {
          this.slotApi = new API({ url: "/order-service/slot-availability" });
          let pickupId =
            this.state.pickupLocations &&
            this.state.pickupLocations[0] &&
            this.state.pickupLocations[0].id;
          if (pickupId) {
            let params = this.getPickupParams(pickupId, visibleDaysForSlot);
            this.slotApi
              .get(params)
              .then((response) => {
                let addressSlotMap = JSON.parse(
                  JSON.stringify(this.state.addressSlotMap)
                );
                let addressSlots = sortSlotsAvailability(response.data.slots);
                let slots = {};
                addressSlots &&
                  addressSlots.map((slot) => {
                    if (slot.available) {
                      if (slots.hasOwnProperty(slot.date)) {
                        slots[slot.date].push(slot);
                      } else {
                        slots[slot.date] = [];
                        slots[slot.date].push(slot);
                      }
                    }
                    return null;
                  });

                let today = new Date();
                let preferredDate =
                  this.state.preferredDate &&
                  moment(this.state.preferredDate).format("YYYY-MM-DD");
                if (preferredDate < today || !preferredDate) {
                  preferredDate = moment().format("YYYY-MM-DD");
                }
                addressSlotMap.PICKUP[pickupId] = slots;
                this.dateOptions["PICKUP"][pickupId] =
                  generateDateOptions(slots);
                this.setState({
                  addressSlotMap,
                  preferredDate,
                  pickupLocationId: pickupId,
                  pickupLocationIndex: 0,
                  addressNotServed: false,
                  preferredSlotId: undefined,
                });
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      }
    }
  }
  componentWillUnmount() {
    this.extnApi && this.extnApi.cancel();
    this.slotApi && this.slotApi.cancel();
  }

  render() {
    const {
      visibleDaysForSlot,
      addressSlotMap,
      preferredDate,
      addressId,
      orderType,
      pickupLocationId,
      selectedDeliveryStore,
      stores,
    } = this.state;
    const isPickupOrder = orderType === "PICKUP";
    let { communicationConfig, data } = this.props;
    let allowCall =
      communicationConfig &&
      communicationConfig.communication &&
      communicationConfig.communication.allowCall !== false;
    let allowEmail =
      communicationConfig &&
      communicationConfig.communication &&
      communicationConfig.communication.allowEmail !== false;
    var title;
    if (this.props.data.status === "COMPLETED") {
      if (this.props.data.type === "DELIVERY") {
        title = getMessage("order.details.location.delivery.completed.title");
      } else if (this.props.data.type === "PICKUP") {
        title = getMessage("order.details.location.pickup.completed.title");
      } else if (this.props.data.type === "B2B") {
        title = getMessage("order.details.location.b2b.completed.title");
      } else if (this.props.data.type === "OFFLINE") {
        title = getMessage("order.details.location.offline.completed.title");
      }
    } else {
      if (this.props.data.type === "DELIVERY") {
        title = getMessage("order.details.location.delivery.pending.title");
      } else if (this.props.data.type === "PICKUP") {
        title = getMessage("order.details.location.pickup.pending.title");
      } else if (this.props.data.type === "B2B") {
        title = getMessage("order.details.location.b2b.pending.title");
      } else if (this.props.data.type === "OFFLINE") {
        title = getMessage("order.details.location.offline.pending.title");
      }
    }

    let isAddressSelected = Number.isFinite(this.state.addressIndex);
    let isPickupLocationSelected = Number.isFinite(
      this.state.pickupLocationIndex
    );

    let minutesToDeliver = this.props.data.preferredSlot
      ? this.props.data.preferredSlot.startTime
        ? formatSlotTime(
            this.props.data.preferredDate,
            this.props.data.preferredSlot.startTime
          ) +
          " - " +
          formatSlotTime(
            this.props.data.preferredDate,
            this.props.data.preferredSlot.endTime
          )
        : null
      : null;
    if (
      this.props.data.slotType === "ASAP" &&
      this.props.data.preferredSlot &&
      this.props.data.preferredSlot.startTime &&
      this.props.data.preferredSlot.endTime
    ) {
      minutesToDeliver = getAsapDuration(
        this.props.data.preferredSlot.startTime,
        this.props.data.preferredSlot.endTime
      );
    }

    let metaData = this.props.data.address && this.props.data.address.metaData;
    let metaDataPhones =
      metaData &&
      [{ phone: metaData.Phone }, { phone: metaData.AlternatePhone }].filter(
        Boolean
      );
    let myoptions =
      this.dateOptions &&
      this.dateOptions[orderType] &&
      this.dateOptions[orderType][
        orderType === "PICKUP" ? pickupLocationId : addressId
      ];
    let filteredOption =
      myoptions && myoptions.filter((item) => item.value === preferredDate);
    let selectedDate = null;
    if (filteredOption && filteredOption.length > 0) {
      selectedDate = filteredOption[0].value;
    }
    const clientId = this.state?.pickupLocation?.clientId;
    const billingAddress = this.props.data?.billingAddress;
    return (
      <div className="address-details">
        {!this.state.editing ? (
          <React.Fragment>
            <h3 className="customer-heading">{getMessage("Customer")}</h3>
            {this.state.customer && (
              <React.Fragment>
                <Link
                  className="customer-name"
                  to={`${this.props.data.url}/customers/view/${this.state.customer.id}`}
                >
                  {this.state?.customer?.name}
                </Link>
                <div className="customer-actions">
                  {allowEmail &&
                    this.state.customer &&
                    this.state.customer.emails &&
                    this.state.customer.emails.length > 0 && (
                      <CustomerEmailWidget
                        emails={this.state.customer.emails}
                      />
                    )}
                  {allowCall &&
                    this.state.customer &&
                    this.state.customer.phones &&
                    this.state.customer.phones.length > 0 && (
                      <CustomerCallWidget phones={this.state.customer.phones} />
                    )}
                </div>
              </React.Fragment>
            )}
            <div className="flex-around section-title title">
              <h3>{title}</h3>
              {this.props.editable ? (
                <img
                  src={editIcon}
                  alt="Edit"
                  className="edit-icon"
                  onClick={this.startEditing}
                />
              ) : null}
            </div>
          </React.Fragment>
        ) : (
          <div className="section-title">
            <div className="location-options">
              <Radio
                name="delivery-option"
                skin={RADIO_SKINS.BORDERLESS}
                value={orderType}
                readOnly={this.props.data.status !== "PENDING"}
                onChange={(value) => {
                  value === "DELIVERY"
                    ? this.setState({
                        orderType: "DELIVERY",
                        preferredSlotId: null,
                      })
                    : this.setState({
                        orderType: "PICKUP",
                        preferredSlotId: null,
                      });
                }}
                options={this.orderTypes}
              />
            </div>
          </div>
        )}
        {this.props.data.address && (
          <div className="customer-address customer-meta">
            {(({ metaData }) => {
              let name = [
                metaData && metaData.FirstName,
                metaData && metaData.LastName,
              ]
                .filter(Boolean)
                .join(" ");
              if (name) {
                return name;
              }
              return this.state?.customer?.name;
            })(this.props.data.address)}
          </div>
        )}
        {this.props.data.metaData &&
          this.props.data.pickupLocation &&
          this.props.data.metaData.pickupPersonName && (
            <React.Fragment>
              <div className="customer-address customer-meta">
                {(({ pickupPersonName }) => {
                  return pickupPersonName;
                })(this.props.data.metaData)}
              </div>
              {this.props.data.metaData.pickupPersonContact && (
                <div className="customer-address customer-meta">
                  {(({ pickupPersonContact }) => {
                    return pickupPersonContact;
                  })(this.props.data.metaData)}
                </div>
              )}
            </React.Fragment>
          )}
        <div className="customer-actions">
          {allowCall && metaDataPhones && metaDataPhones.length > 0 && (
            <CustomerCallWidget phones={metaDataPhones} />
          )}
        </div>
        {(orderType === "DELIVERY" ||
          orderType === "PICKUP" ||
          orderType === "OFFLINE") &&
        (isAddressSelected || isPickupLocationSelected) ? (
          !this.state.editing ? (
            !isPickupOrder ? (
              <React.Fragment>
                <div className="customer-address">
                  {this.props.data.address &&
                    (({ address, city, pincode, metaData }) => {
                      let addString =
                        this.props.data.address &&
                        getDisplayAddress(
                          this.props.data.address,
                          this.props.data.addressSequence
                        );
                      return addString;
                    })(this.props.data.address)}
                </div>
                <div className="billing-address-title">
                  <div className="billing-title">
                    {getMessage("order.details.location.billing.address.title")}
                  </div>
                </div>
                <div className="customer-address">
                  {billingAddress &&
                    (({ address, city, pincode, metaData }) => {
                      let addString = getDisplayAddress(
                        billingAddress,
                        this.props.data.addressSequence
                      );
                      return addString;
                    })(billingAddress)}
                </div>
              </React.Fragment>
            ) : (
              this.state.pickupLocation && (
                <div className="customer-address">
                  <div className="customer-address">
                    {clientId ? (
                      <>
                        {clientId}, {this.state.pickupLocation.address}
                      </>
                    ) : (
                      <>{this.state.pickupLocation.address}</>
                    )}
                  </div>
                </div>
              )
            )
          ) : (
            <React.Fragment>
              <div className="customer-addresses">
                {!isPickupOrder ? (
                  <Radio
                    name="delivery-address"
                    skin={RADIO_SKINS.ONE_PER_LINE}
                    readOnly={this.props.data.status !== "PENDING"}
                    value={
                      this.state.customer.addresses[this.state.addressIndex] &&
                      this.state.customer.addresses[this.state.addressIndex].id
                    }
                    onChange={this.onChange}
                    options={this.state.customer.addresses.map((address) => ({
                      value: address.id,
                      text: getDisplayAddress(
                        address,
                        this.props.data.addressSequence
                      ),
                    }))}
                  />
                ) : (
                  <Radio
                    name="pickup-addresses"
                    skin={RADIO_SKINS.ONE_PER_LINE}
                    readOnly={this.props.data.status !== "PENDING"}
                    value={
                      this.state.pickupLocations &&
                      this.state.pickupLocations[
                        this.state.pickupLocationIndex
                      ] &&
                      this.state.pickupLocations[this.state.pickupLocationIndex]
                        .id
                    } // TODO: confirm
                    onChange={this.onChange}
                    options={
                      this.state.pickupLocations &&
                      this.state.pickupLocations
                        .filter((store) => store.hasClickCollect)
                        .map((pickupLocation) => ({
                          value: pickupLocation.id,
                          text: pickupLocation.name,
                        }))
                    }
                  />
                )}
              </div>
              {stores && orderType === "DELIVERY" && (
                <div className="stores-container">
                  <div className="stores-heading">
                    {getMessage("operations.store.heading")}
                  </div>
                  <Radio
                    name="stores"
                    skin={RADIO_SKINS.ONE_PER_LINE}
                    readOnly={data.status !== "PENDING"}
                    value={selectedDeliveryStore}
                    onChange={this.handleChangeSelectedStore}
                    options={stores?.map?.((store) => ({
                      value: store.id,
                      text: store.name,
                    }))}
                  />
                </div>
              )}
              {isExtensionEnabled("DeliverySlots") &&
                orderType === "DELIVERY" &&
                visibleDaysForSlot &&
                addressSlotMap &&
                addressSlotMap[orderType] &&
                addressSlotMap[orderType][addressId] && (
                  <React.Fragment>
                    <Select
                      name="preferredDate"
                      label={getMessage("order.details.preferredDate")}
                      value={selectedDate}
                      options={
                        this.dateOptions &&
                        this.dateOptions[orderType] &&
                        this.dateOptions[orderType][addressId]
                      }
                      onChange={(e) =>
                        this.setState({
                          preferredSlotId: undefined,
                          preferredDate: e,
                        })
                      }
                    />
                    {preferredDate &&
                      Array.isArray(
                        addressSlotMap[orderType][addressId][preferredDate]
                      ) && (
                        <Select
                          name="preferredSlot"
                          label={getMessage("order.details.prefferdSlot")}
                          placeholder={getMessage("Select slot")}
                          value={this.state.preferredSlotId}
                          onChange={(e) => {
                            this.setState({
                              preferredSlotId: e,
                              allSlots:
                                addressSlotMap[orderType][addressId][
                                  preferredDate
                                ],
                            });
                          }}
                          options={addressSlotMap[orderType][addressId][
                            preferredDate
                          ].map((slot) => {
                            const timearr = slot.interval.split("/");
                            if (slot.type === "ASAP") {
                              return {
                                text: slot.text,
                                value: slot.id,
                              };
                            } else {
                              return {
                                text: `${formatIsoTime(
                                  timearr[0]
                                )} - ${formatIsoTime(timearr[1])}`,
                                value: slot.id,
                              };
                            }
                          })}
                        />
                      )}
                  </React.Fragment>
                )}
              {isExtensionEnabled("DeliverySlots") &&
                isPickupOrder &&
                addressSlotMap &&
                addressSlotMap[orderType] &&
                addressSlotMap[orderType][pickupLocationId] && (
                  <React.Fragment>
                    <Select
                      name="preferredDatePickup"
                      label={getMessage("order.details.preferredDate")}
                      value={selectedDate}
                      options={
                        this.dateOptions &&
                        this.dateOptions[orderType] &&
                        this.dateOptions[orderType][pickupLocationId]
                      }
                      onChange={(e) =>
                        this.setState({
                          preferredDate: e,
                          preferredSlotId: undefined,
                        })
                      }
                    />
                    {preferredDate &&
                      Array.isArray(
                        addressSlotMap[orderType][pickupLocationId][
                          preferredDate
                        ]
                      ) && (
                        <Select
                          name="preferredSlot"
                          label={getMessage("order.details.prefferdSlot")}
                          value={this.state.preferredSlotId}
                          onChange={(e) => {
                            this.setState({
                              preferredSlotId: e,
                              allSlots:
                                addressSlotMap[orderType][pickupLocationId][
                                  preferredDate
                                ],
                            });
                          }}
                          options={addressSlotMap[orderType][pickupLocationId][
                            preferredDate
                          ].map((slot) => {
                            const timearr = slot.interval.split("/");
                            return {
                              text: `${formatIsoTime(
                                timearr[0]
                              )} - ${formatIsoTime(timearr[1])}`,
                              value: slot.id,
                            };
                          })}
                        />
                      )}
                  </React.Fragment>
                )}
            </React.Fragment>
          )
        ) : (
          <div className="store-address">
            {this.state.pickupLocation ? (
              <React.Fragment>
                <span className="customer-address customer-meta">
                  {getMessage("order.details.pickup.address")}
                </span>{" "}
                {this.state.pickupLocation.address}
              </React.Fragment>
            ) : null}
          </div>
        )}
        {!this.state.editing &&
          isExtensionEnabled("DeliverySlots") &&
          (this.props.data.preferredDate || minutesToDeliver) && (
            <div className="slot-details">
              <div className="section-title slot-title">
                <h5>{getMessage("order.details.slot.heading")}</h5>
              </div>
              {this.props.data.preferredDate && (
                <div className="customer-address">{`${
                  this.props.data.preferredDate &&
                  getPrintableTime(
                    getStandradDateTime(
                      utcToLocalTime,
                      `${this.props.data.preferredDate} ${this.props.data.preferredSlot.startTime}`
                    )
                  ).split(",")[0]
                }`}</div>
              )}
              {minutesToDeliver && (
                <div className="customer-address">{minutesToDeliver}</div>
              )}
            </div>
          )}
        <div className="delivery-option-buttons">
          {this.state.editing ? (
            <button
              className="button primary"
              disabled={
                (isExtensionEnabled("DeliverySlots") &&
                  !this.state.preferredSlotId &&
                  parseInt(selectedDeliveryStore) === data?.storeId) ||
                this.state.saving
              }
              onClick={this.submitChanges}
            >
              {getMessage("order.details.location.actions.confirmText")}
            </button>
          ) : null}
          {this.state.editing ? (
            <button
              className="button button-white"
              onClick={this.cancelEditing}
            >
              {getMessage("order.details.location.actions.cancelText")}
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

export default DeliveryDetails;
