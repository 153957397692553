import React, { Component } from "react";
import { Helmet } from "react-helmet";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import Loader from "../../../../components/Loader";
import { getMessage } from "../../../../lib/translator";
import GoogleMerchantCenterForm from "./form";
import API from "../../../../lib/api";
import "./style.css";

export default class GoogleMerchantCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      showSuccessMsg: false,
    };
  }
  loadData = () => {
    this.setState({ showLoader: true });
    this.api = new API({ url: `/config-service/config/googleMerchantCenter` });
    this.api
      .get()
      .then((response) => {
        if (response.data.googleMerchantCenter.contentAPICredentials) {
          this.setState({
            showSuccessMsg: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      })
      .finally(() => this.setState({ showLoader: false }));
  };
  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
  }
  render() {
    return (
      <AuthenticatedPage
        className="google-merchant-center"
        menu={this.props.menu}
        title={getMessage("menu.item.google-merchant-center")}
        notShowHelmet={false}
      >
        <Helmet title="Zopping - Google Merchant Center" />
        <h1 className="title">
          {getMessage("menu.item.google-merchant-center")}
        </h1>
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <GoogleMerchantCenterForm showSuccess={this.state.showSuccessMsg} />
        )}
      </AuthenticatedPage>
    );
  }
}
