import React from "react";
import {
  BaseForm,
  Radio,
  VALIDATION_TYPES,
  CategorySearch,
} from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";

export default class CategoryFiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    return (
      <Form>
        <div className="form-fields">
          <CategorySearch
            label={getMessage("category.filters.name.heading")}
            placeholder={getMessage("category.filters.name.placeholder")}
            name="name"
            {...this.generateStateMappers({
              stateKeys: ["category"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            showChain
          />
          <Radio
            label={getMessage("category.filters.status.heading")}
            name="status"
            options={[
              {
                text: getMessage("category.filters.status.label.all"),
                value: "",
              },
              {
                text: getMessage("category.filters.status.label.enabled"),
                value: "ENABLED",
              },
              {
                text: getMessage("category.filters.status.label.disabled"),
                value: "HIDDEN",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              defaultValue: "",
            })}
          />
        </div>
        <SubmitButton>{getMessage("category.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("category.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
