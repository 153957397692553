import React, { Component } from "react";
import _Phone from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getSession } from "../../../../lib/auth";
import "./override.css";

function getValidationObj(props, value) {
  let valueMissing = props.required ? !value : false;
  return {
    valueMissing,
    valid: !valueMissing,
  };
}

class Phone extends Component {
  constructor(props) {
    super(props);
    this.org = getSession().organization || {};
    this.handleChange = this.handleChange.bind(this);
    this.runValidation = this.runValidation.bind(this);
    this.state = {
      defaultCountryCode: this.org?.countryCode || "IN",
    };
  }

  handleChange(value) {
    this.props.onChange && this.props.onChange(value);
    this.runValidation(value);
  }

  runValidation(value) {
    this.props.onValidation &&
      this.props.onValidation(getValidationObj(this.props, value));
  }

  componentDidMount() {
    this.runValidation(this.props.value);
  }

  componentDidUpdate(prevProps, prevState) {
    const updatedLocalOrgData = getSession().organization || {};
    if (prevState.defaultCountryCode !== updatedLocalOrgData?.countryCode) {
      this.setState({ defaultCountryCode: updatedLocalOrgData?.countryCode });
    }
  }

  render() {
    let {
      emphasize,
      onValidation,
      showErrors,
      onChange,
      country,
      required = false,
      label,
      placeholder,
      ...props
    } = this.props;
    let PhoneInput = _Phone;
    return (
      <PhoneInput
        {...props}
        placeholder={
          placeholder ? placeholder + ((required && !label && " *") || "") : ""
        }
        onChange={this.handleChange}
        defaultCountry={country || this.state.defaultCountryCode}
        displayInitialValueAsLocalNumber
        rules={{ required }}
      />
    );
  }
}

export default Phone;
