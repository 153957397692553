import React from "react";
import "./style.css";

const Loader3Dots = function (props) {
  return (
    <div className="loader-3-dots">
      <div class="dot-flashing"></div>
    </div>
  );
};

export default Loader3Dots;
