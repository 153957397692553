import React from "react";
import { BaseForm, Input } from "../../../../components/Form";
import Dialog from "../../../../components/Popup/Dialog";
import Loader from "../../../../components/Loader";
import Table, { Row, Cell, Header } from "../../../../components/Table";

import API from "../../../../lib/api";
import { getMessage } from "../../../../lib/translator";
import { getSession } from "../../../../lib/auth";
import "./style.css";

class RegisterOrderPayment extends BaseForm {
  componentDidMount() {
    this.setState({
      loading: true,
      showErrorDialog: false,
    });
    const pendingAmount = this.props.pendingAmount;
    const clientId = this.props.clientId;
    this.setState({
      pendingAmount,
      clientId,
    });
    const configApi = new API({ url: "/config-service/config/order" });
    configApi.get().then((response) => {
      let disabledModes = response.data.order.finCloseDisabledPaymentModes;
      disabledModes = disabledModes || [];
      const paymentModes = response.data.order.paymentMethods?.[
        "PICKUP"
      ].filter((mode) => disabledModes.indexOf(mode) === -1);
      if (paymentModes && !paymentModes.length) {
        const api = new API({
          url: `/order-service/order/${this.props.referenceNumber}`,
        });
        api
          .put({ status: "COMPLETED" })
          .then((response) => {
            this.setState(
              {
                submitting: false,
              },
              () => this.props.closePopup()
            );
          })
          .catch((error) => {
            console.error(error);
            if (error.code === 401) {
              throw error;
            } else {
              this.setState({
                submitting: false,
                loading: false,
                errorMessage:
                  (error && error.message) || "Please try again later",
                showErrorDialog: true,
              });
            }
          });
      } else {
        this.setState({
          loading: false,
          paymentModes,
        });
      }
    });
    if (!pendingAmount) {
      const orderApi = new API({
        url: `/order-service/order/${this.props.referenceNumber}`,
      });
      orderApi
        .get()
        .then((response) => {
          const order = response.data.order;
          this.setState({
            pendingAmount: order.pendingAmount,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  onSubmit(formData) {
    this.setState({
      submitting: true,
      loading: true,
    });
    let data = Object.assign({}, formData);
    let newData = Object.keys(data)
      .map((mode) => {
        if (data[mode].amount) {
          return {
            mode: mode,
            amount: data[mode].amount,
            transactionId: data[mode].transactionId,
          };
        }
        return null;
      })
      .filter(Boolean);

    const api = new API({ url: "/order-service/order-payment" });
    Promise.all(
      newData.map((data) => {
        let params = {};
        params.amount = data.amount;
        params.mode = data.mode;
        params.orderReferenceNumber = this.props.referenceNumber;
        if (data.transactionId) {
          params.metaData = { transaction_id: data.transactionId };
        }
        params.status = "COMPLETED";
        return api.post(params);
      })
    )
      .then((response) => {
        const api = new API({
          url: `/order-service/order/${this.props.referenceNumber}`,
        });
        api
          .put({ status: "COMPLETED" })
          .then((response) => {
            this.setState(
              {
                submitting: false,
                loading: false,
              },
              () => this.props.closePopup()
            );
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        if (error.code === 401) {
          throw error;
        } else {
          this.setState({
            submitting: false,
            loading: false,
            errorMessage: (error && error.message) || "Please try again later",
            showErrorDialog: true,
          });
        }
        console.error(error)
      });
  }
  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const { paymentModes, values, pendingAmount, submitting, errorMessage } =
      this.state;
    let remainingAmount = (
      pendingAmount -
      (values &&
        Object.keys(values) &&
        Object.keys(values).reduce(
          (acc, val) => Number(values[val].amount) + acc,
          0
        ))
    ).toFixed(2);
    return (
      <>
        {this.state.showErrorDialog && (
          <Dialog
            show={this.state.showErrorDialog}
            title={"Error"}
            information={errorMessage}
            close={() => this.props.closePopup()}
            closeText={getMessage("dialog.okText")}
          />
        )}
        {this.state.loading ? (
          <Loader />
        ) : (
          <Form className="order-payments-form">
            <Table>
              <Header>
                <Cell>{getMessage("order.payment.paymentmode")}</Cell>
                <Cell className="align-right">
                  {getMessage("order.payment.amount")} (
                  {getSession() &&
                    getSession().organization &&
                    getSession().organization.currency.symbol}
                  )
                </Cell>
                <Cell>{getMessage("order.payment.transactionid")}</Cell>
              </Header>
              {paymentModes &&
                paymentModes.map((mode, i) => (
                  <Row key={`${mode}-${i}-row`}>
                    <Cell key={`${mode}-${i}`}>{mode}</Cell>
                    <Cell className="amount">
                      <Input
                        name={`${mode}-${i}-amount`}
                        type="number"
                        min={0}
                        step={0.01}
                        {...this.generateStateMappers({
                          stateKeys: [mode, "amount"],
                          loseEmphasisOnFill: true,
                        })}
                      />
                    </Cell>
                    <Cell className="transactionid">
                      {mode !== "COD" && (
                        <Input
                          name={`${mode}-${i}-transactionId`}
                          type="text"
                          {...this.generateStateMappers({
                            stateKeys: [mode, "transactionId"],
                            loseEmphasisOnFill: true,
                          })}
                          required={this.getState(mode, "amount")}
                        />
                      )}
                    </Cell>
                  </Row>
                ))}
              <Row>
                <Cell>{getMessage("order.payment.pendingamount")}</Cell>
                <Cell className="align-right">{remainingAmount}</Cell>
                <Cell />
              </Row>
            </Table>
            <div className="form-action">
              <SubmitButton
                disabled={
                  paymentModes &&
                  paymentModes.length > 0 &&
                  (submitting ||
                    !!Number(!!remainingAmount && Number(remainingAmount)))
                }
              >
                {getMessage("order.payment.submit")}
              </SubmitButton>
            </div>
          </Form>
        )}
      </>
    );
  }
}

export default RegisterOrderPayment;
