import React from "react";
import { Input, Upload } from "../../../../components/Form";
import DualImageGrid from "../../../../components/Layout/DualImageGrid";
import { getMessage } from "../../../../lib/translator";

// lower case t is used in Text because it iis dependent on a module whose name is imageWithText with an Upper case T
const DualImageLayout = () => {
  return {
    preview: DualImageGrid,
    fields: ({ getState, updateState }) => (
      <div className="form-sections">
        <div className="form-section">
          <Input
            label={getMessage("dualImageGrid.title")}
            placeholder={getMessage("dualImageGrid.enterTitle")}
            value={getState(["title1"]) || ""}
            onChange={(value) => {
              updateState(["title1"], value);
            }}
          />
          <Input
            label={getMessage("dualImageGrid.subTitle")}
            placeholder={getMessage("dualImageGrid.enterSubtitle")}
            value={getState(["subtitle1"]) || ""}
            onChange={(value) => {
              updateState(["subtitle1"], value);
            }}
          />
          <Upload
            label={getMessage("dualImageGrid.image1")}
            value={getState(["imageUrl1"]) || ""}
            onChange={(value) => {
              updateState(["imageUrl1"], value);
            }}
          />
          <Input
            label={getMessage("dualImageGrid.link")}
            placeholder={getMessage("dualImageGrid.navigateUrl")}
            value={getState(["link1"]) || ""}
            onChange={(value) => {
              updateState(["link1"], value);
            }}
          />
        </div>
        <div className="form-section">
          <Input
            label={getMessage("dualImageGrid.title")}
            placeholder={getMessage("dualImageGrid.enterTitle")}
            value={getState(["title2"]) || ""}
            onChange={(value) => {
              updateState(["title2"], value);
            }}
          />
          <Input
            label={getMessage("dualImageGrid.subTitle")}
            placeholder={getMessage("dualImageGrid.enterSubtitle")}
            value={getState(["subtitle2"]) || ""}
            onChange={(value) => {
              updateState(["subtitle2"], value);
            }}
          />
          <Upload
            label={getMessage("dualImageGrid.image2")}
            value={getState(["imageUrl2"]) || ""}
            onChange={(value) => {
              updateState(["imageUrl2"], value);
            }}
          />
          <Input
            label={getMessage("dualImageGrid.link")}
            placeholder={getMessage("dualImageGrid.navigateUrl")}
            value={getState(["link2"]) || ""}
            onChange={(value) => {
              updateState(["link2"], value);
            }}
          />
        </div>
      </div>
    ),
  };
};
export default DualImageLayout;
