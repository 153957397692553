import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import EmptyState from "../../../components/EmptyState";
import Table from "../../../components/Table";
import HelpCard from "../../../components/HelpCard";
import HelpWidget from "../../../components/HelpWidget";
import Loader from "../../../components/Loader";
import Pagination from "../../../components/Pagination";
import { Popup as Modal, Dialog } from "../../../components/Popup";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";
import { get } from "../../../lib/storage";
import { getDefaultStore } from "../../../containers/StoreSelector";
import { isExtensionEnabled, isEnterprise } from "../../../lib/auth";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

import "../../../containers/ListingPage/style.css";
import "./style.css";

export const TABLE_ACTIONS = {
  ADD: "ADD",
  EDIT: "EDIT",
  VIEW: "VIEW",
  DELETE: "DELETE",
  FILTER: "FILTER",
  REFRESH: "REFRESH",
  UPDATE: "UPDATE",
  SET_API_PARAMS: "SET_API_PARAMS",
};

const NullComponent = () => null;

const SortableItem = SortableElement((props) => {
  let { row, Row, onAction, apiParams, rowIndex } = props;
  return (
    <Row {...row} apiParams={apiParams} onAction={onAction} index={rowIndex} />
  );
});

const SortableList = SortableContainer((props) => {
  return (
    <div>
      {props.viewData &&
        props.viewData.map((row, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            row={row}
            rowIndex={index}
            {...props}
          />
        ))}
    </div>
  );
});

class DraggableListingPage extends React.Component {
  constructor(props) {
    super(props);
    let searchParams = Object.assign(
      {},
      ...this.props.router.location.search
        .slice(1)
        .split("&")
        .filter(Boolean)
        .map((keystr) => {
          keystr = keystr.split("=");
          return {
            [keystr[0]]: decodeURIComponent(keystr[1]),
          };
        })
    );
    if (Object.keys(searchParams).length > 0) {
      window.localStorage.setItem(this.props.className, 1);
    }
    let apiParams = {};
    if (props.api) {
      if (props.api.params) {
        apiParams = props.api.params;
      }
      if (props.storeDependent && isExtensionEnabled("MultiStoreSupport")) {
        apiParams.storeId = get("store") || getDefaultStore().storeId;
      }
    }
    this.state = {
      data: {
        items: null,
        paging: null,
        filters: searchParams || {}, // Corresponds to the filters applied to the API
        viewItems: null,
      },
      filters: {
        // Corresponds to the state of the 'filters' view
        shown: Object.keys(searchParams).length > 0,
      },
      apiParams: apiParams,
      loaders: {
        data: false,
      },
      form: {
        shown: false,
        rowIndex: -1, // The row being edited
        data: null,
      },
      deleteDialog: {
        shown: false,
        data: {},
      },
      errorDialog: {
        shown: false,
        message: "",
        title: "",
      },
      saveBanner: false,
      submitting: false,
      firstLoadDone: false, // Indicate that we have gotten results from API at least once
    };

    this.primaryKey = this.props.primaryKey || "id";

    if (props.api && props.api.url) {
      this.api = new API({ url: props.api.url });
    }

    /* Default hooks */
    // Method to extract data from the API call made
    this._transformResponse = (response) => response;
    this._transformSubmit = (form) => form;

    /* Hook overrides */
    if (props.api && props.api.transform) {
      this._transformResponse = props.api.transform;
    }
    if (props.form && props.form.transformSubmit) {
      this._transformSubmit = props.form.transformSubmit;
    }
    if (props.updateView) {
      this.updateView = this.updateView.bind(this);
    }

    this._showDataLoader = this._showDataLoader.bind(this);
    this._hideDataLoader = this._hideDataLoader.bind(this);
    this.changePage = this.changePage.bind(this);
    this.performAction = this.performAction.bind(this);
    this._showForm = this._showForm.bind(this);
    this._hideForm = this._hideForm.bind(this);
    this._showFilters = this._showFilters.bind(this);
    this._hideFilters = this._hideFilters.bind(this);
    this._toggleFilters = this._toggleFilters.bind(this);
    this.createResource = this.createResource.bind(this);
    this.modifyResource = this.modifyResource.bind(this);
    this.getResource = this.getResource.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.throwError = this.throwError.bind(this);
    this._showDeleteDialog = this._showDeleteDialog.bind(this);
    this._hideDeleteDialog = this._hideDeleteDialog.bind(this);
    this._showErrorDialog = this._showErrorDialog.bind(this);
    this._hideErrorDialog = this._hideErrorDialog.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onDraggableSubmit = this.onDraggableSubmit.bind(this);
    this.onAddBanner = this.onAddBanner.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.closeDragDialog = this.closeDragDialog.bind(this);
  }
  // Some data lookup methods
  _getRow(data) {
    let result = {
      data: null,
      index: -1,
    };
    let { items } = this.state.data;
    for (let i = 0, len = items.length; i < len; i++) {
      let item = items[i];
      let matched = true;
      for (let key in data) {
        if (!(key in item) || item[key] !== data[key]) {
          matched = false;
          break;
        }
      }
      if (matched) {
        result.data = item;
        result.index = i;
        break;
      }
    }
    return result;
  }
  // Some utility methods to manage states
  _showDataLoader() {
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.loaders.data = true;
      return newState;
    });
  }
  _hideDataLoader() {
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.loaders.data = false;
      return newState;
    });
  }
  _showForm(rowIndex, data) {
    // If rowIndex is specified, then a particular row is being edited
    if (this.props.form && this.props.form.component) {
      this.setState((prevState) => {
        let newState = Object.assign({}, prevState);
        newState.form.shown = true;
        newState.form.rowIndex = isFinite(rowIndex) ? rowIndex : -1;
        if (data) {
          newState.form.data = data;
        }
        return newState;
      });
    }
  }
  _hideForm() {
    // Clear the form and hide it
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.form.shown = false;
      newState.form.rowIndex = -1;
      newState.form.data = null;
      return newState;
    });
  }
  _showFilters() {
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.filters.shown = true;
      return newState;
    });
  }
  _hideFilters() {
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.filters.shown = false;
      return newState;
    });
  }
  _toggleFilters() {
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.filters.shown = !prevState.filters.shown;
      return newState;
    });
  }
  _hideDeleteDialog() {
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.deleteDialog.shown = false;
      return newState;
    });
  }
  _showDeleteDialog() {
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.deleteDialog.shown = true;
      return newState;
    });
  }
  _hideErrorDialog() {
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.errorDialog.shown = false;
      newState.errorDialog.message = "";
      newState.errorDialog.title = "";
      return newState;
    });
  }
  _showErrorDialog() {
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.errorDialog.shown = true;
      return newState;
    });
  }
  // Methods to manage data via API
  /**
   * Makes API call to create the resource
   * @param {object} data - Data to be created in DB
   * @return {Oject} newState - updated state [Immutated]
   */
  createResource(data) {
    let api = new API({
      url: this.props.api.url,
    });
    let params =
      this.props.form && this.props.form.overwriteWithApiParams === false
        ? Object.assign({}, this.state.apiParams, this._transformSubmit(data))
        : Object.assign({}, this._transformSubmit(data), this.state.apiParams);
    return api
      .post(params)
      .then(
        (response) => {
          let data = this._transformResponse(response);
          this.setState({
            pageType: data && data.pageType,
            id: data && data.id,
          });
          /* Show new entry at top of the table */
          if (this.props.form && this.props.form.filterBeforeAdding) {
            let filterBeforeAdding = this.props.form.filterBeforeAdding;
            data = filterBeforeAdding(data, this);
          }
          if (data) {
            this.setState((prevState) => {
              let newState = Object.assign({}, prevState);
              let updatedItems = newState.data.items;
              this.props.addNewItemToLast
                ? updatedItems.push(data)
                : updatedItems.unshift(data);
              newState.data.items = updatedItems;
              const updatedPaging = newState.data.paging;
              updatedPaging.count = (updatedPaging.count || 0) + 1;
              newState.data.paging = updatedPaging;
              return newState;
            });
          }
          this.viewData = this.props.updateView
            ? this.state.data.viewItems && this.state.data.viewItems.length > 0
              ? this.state.data.viewItems
              : this.state.data.items
            : this.state.data.items;
          if (this.viewData) {
            this.onAddBanner();
          }
          this._hideForm();
        },
        (error) => {
          this.throwError(error);
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }
  modifyResource(data) {
    // Make an API call to update the resource
    let api = new API({
      url: this.props.api.url + "/" + data[this.primaryKey],
    });
    let params =
      this.props.form && this.props.form.overwriteWithApiParams === false
        ? Object.assign({}, this.state.apiParams, this._transformSubmit(data))
        : Object.assign({}, this._transformSubmit(data), this.state.apiParams);
    return api
      .put(params)
      .then(
        (response) => {
          /* Update the table row */
          if (this.props.form && this.props.form.filterBeforeAdding) {
            let filterBeforeAdding = this.props.form.filterBeforeAdding;
            data = filterBeforeAdding(data, this);
          }
          let updatedRow = this._transformResponse(response);
          let row = this._getRow({
            [this.primaryKey]: updatedRow[this.primaryKey],
          });
          if (data) {
            if (row.index > -1) {
              this.setState((prevState) => {
                let newState = Object.assign({}, prevState);
                let updatedItems = newState.data.items;
                updatedItems.splice(row.index, 1, updatedRow);
                newState.data.items = updatedItems;
                return newState;
              });
            }
          } else {
            if (row.index > -1) {
              this.setState((prevState) => {
                let newState = Object.assign({}, prevState);
                let updatedItems = newState.data.items;
                updatedItems.splice(row.index, 1);
                newState.data.items = updatedItems;
                const updatedPaging = newState.data.paging;
                updatedPaging.count = updatedPaging.count - 1;
                newState.data.paging = updatedPaging;
                return newState;
              });
            }
          }
          this.props.api &&
            this.props.api.afterSubmit &&
            this.props.api.afterSubmit(response);
          this.viewData = this.props.updateView
            ? this.state.data.viewItems && this.state.data.viewItems.length > 0
              ? this.state.data.viewItems
              : this.state.data.items
            : this.state.data.items;
          this._hideForm();
        },
        (error) => {
          this.throwError(error);
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Makes API call to delete the resource
   * @param {object} data - Data to be deleted from DB
   * @return {Oject} newState - updated state [Immutated]
   */
  deleteResource(data) {
    let api = new API({
      url: this.props.api.url + "/" + data[this.primaryKey],
    });
    let params = Object.assign({}, data, this.state.apiParams);
    api
      .delete(params)
      .then(
        (response) => {
          /* Update the table row */
          let row = this._getRow({
            [this.primaryKey]: data[this.primaryKey],
          });
          if (row.index > -1) {
            this.setState((prevState) => {
              let newState = Object.assign({}, prevState);
              let updatedItems = newState.data.items;
              updatedItems.splice(row.index, 1);
              newState.data.items = updatedItems;
              const updatedPaging = newState.data.paging;
              updatedPaging.count = updatedPaging.count - 1;
              newState.data.paging = updatedPaging;
              return newState;
            });
          }
          this.viewData = this.props.updateView
            ? this.state.data.viewItems && this.state.data.viewItems.length > 0
              ? this.state.data.viewItems
              : this.state.data.items
            : this.state.data.items;
        },
        (error) => {
          this.throwError(error, getMessage("errorDialog.delete.error.title"));
        }
      )
      .then(this._hideForm)
      .catch((error) => {
        console.error(error);
      });
  }

  getResource(data) {
    let api = new API({
      url: this.props.api.url + "/" + data[this.primaryKey],
    });
    let params = Object.assign(
      {},
      this._transformSubmit(data),
      this.state.apiParams
    );
    api
      .get(params)
      .then(
        (response) => {
          /* Update the table row */
          let updatedRow = this._transformResponse(response);
          let row = this._getRow({
            [this.primaryKey]: updatedRow[this.primaryKey],
          });
          if (row.index > -1) {
            this.setState((prevState) => {
              let newState = Object.assign({}, prevState);
              let updatedItems = newState.data.items;
              updatedItems.splice(row.index, 1, updatedRow);
              newState.data.items = updatedItems;
              return newState;
            });
          }
          this.props.api &&
            this.props.api.afterSubmit &&
            this.props.api.afterSubmit(response);

          this._hideForm();
        },
        (error) => {
          this.throwError(error);
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }
  fetchTableData(params = {}) {
    if (!this.api) {
      return;
    }
    this._showDataLoader();
    if (Object.keys(params).length > 0) {
      params = Object.assign({}, this.state.apiParams, params);
    } else {
      params = Object.assign({}, params, this.state.apiParams);
    }
    return this.api
      .get(params)
      .then((response) => {
        this.setState(
          (prevState) => {
            let newState = Object.assign({}, prevState);
            newState.data.items = this._transformResponse(response);
            if (this.props.updateView) {
              newState.data.viewItems = this._transformResponse(response);
            }

            newState.data.paging = (({ count, limit, offset }) => ({
              count,
              limit,
              offset,
            }))(response.data);
            newState.firstLoadDone = true;
            return newState;
          },
          () => {
            this.viewData = this.props.updateView
              ? this.state.data.viewItems &&
                this.state.data.viewItems.length > 0
                ? this.state.data.viewItems
                : this.state.data.items
              : this.state.data.items;
            this.previousData = this.viewData;
          }
        );
      })
      .then(this._hideDataLoader)
      .catch((error) => {
        if (error.code === 404) {
          this.setState(
            (prevState) => {
              let newState = Object.assign({}, prevState);
              newState.data.items = [];
              return newState;
            },
            () => {
              this._hideDataLoader();
            }
          );
        } else if (error.code === 401) {
          throw error;
        }
        console.error(error)
      });
  }
  changePage(page) {
    window.localStorage.setItem(this.props.className, page); // necessary for pagination
    if (Object.keys(this.state.data.filters).length) {
      this.applyFilters(this.state.data.filters, page);
    } else {
      this.fetchTableData({ page });
    }
  }
  applyFilters(filters, page = 1) {
    this.setState({ saveBanner: false });
    window.localStorage.setItem(this.props.className, page); // necessary for pagination
    var transformedFilters = filters;
    if (this.props.filters && this.props.filters.transformSubmit) {
      transformedFilters = this.props.filters.transformSubmit(filters);
    }
    let params = Object.assign(
      {
        offset: 0, // Reset page to 1 if applying filters
        page: page,
      },
      transformedFilters
    );
    this.setState(
      (prevState) => {
        let newState = Object.assign({}, prevState);
        newState.data.filters = filters;
        return newState;
      },
      () => {
        this.fetchTableData(params);
      }
    );
  }
  clearFilters() {
    window.localStorage.setItem(this.props.className, 1);
    this.setState(
      (prevState) => {
        let newState = Object.assign({}, prevState);
        newState.data.filters = {};
        return newState;
      },
      () => {
        this.fetchTableData();
      }
    );
  }
  confirmDelete() {
    this.deleteResource(this.state.deleteDialog.data);
    this._hideDeleteDialog();
  }
  throwError(error, title) {
    if (error.code === 400) {
      this.setState((prevState) => {
        let newState = Object.assign({}, prevState);
        newState.errorDialog.message = error.message; // if string does not have a colon
        newState.errorDialog.title = title || "";
        return newState;
      }, this._showErrorDialog());
    } else {
      throw error;
    }
  }
  performAction(action, data, updates, deleteRow = false) {
    // action: (string) Action to perform
    // data: The data needed to search a specific a row
    // updates: The data to update the row with
    // deleteRow: delete the row from view without making api call
    if (action in TABLE_ACTIONS) {
      switch (action) {
        case TABLE_ACTIONS.ADD:
          if (this.props.form) {
            this._showForm(-1, data);
          }
          break;
        case TABLE_ACTIONS.EDIT:
          if (this.props.form) {
            // Lookup row number for given data and send that row number
            let row = this._getRow(data);
            if (row.index >= 0) {
              if (updates) {
                // If there are updates, just update row instead of showing form
                this.setState((prevState) => {
                  let newState = Object.assign({}, prevState);
                  let updatedItems = newState.data.items;
                  updatedItems.splice(
                    row.index,
                    1,
                    Object.assign({}, row.data, updates)
                  );
                  newState.data.items = updatedItems;
                  return newState;
                });
              } else {
                this._showForm(row.index);
              }
            }
          }
          break;
        case TABLE_ACTIONS.UPDATE:
          let row = this._getRow(data);
          if (row.index >= 0 && updates) {
            let params = Object.assign({}, data, updates);
            return this.modifyResource(params);
          }
          break;
        case TABLE_ACTIONS.DELETE:
          this.setState((prevState) => {
            let newState = Object.assign({}, prevState);
            newState.deleteDialog.data = data;
            return newState;
          });
          this._showDeleteDialog();
          break;
        case TABLE_ACTIONS.FILTER:
          // To the user, search & filters are mutually exclusive, but internally,
          // both are implemented as filters
          this.applyFilters(data);
          break;
        case TABLE_ACTIONS.REFRESH:
          let params = {};
          if (data && deleteRow) {
            // If data and delete row are not empty, simply delete the row from the view
            let row = this._getRow(data);
            this.setState((prevState) => {
              let newState = Object.assign({}, prevState);
              let updatedItems = newState.data.items.slice();
              updatedItems.splice(row.index, 1);
              newState.data.items = updatedItems;
              return newState;
            });
            return;
          } else if (data && updates) {
            // If 'data' and 'updates' are not empty, simply update the view by pushing 'updates' into the state
            let row = this._getRow(data);
            this.setState((prevState) => {
              let newState = Object.assign({}, prevState);
              let updatedItems = newState.data.items;
              updatedItems.splice(
                row.index,
                1,
                Object.assign({}, row.data, updates)
              );
              newState.data.items = updatedItems;
              return newState;
            });
          } else if (data) {
            // TODO: If 'data' is not empty, fetch details for that row and update the data for that row
            this.getResource(data);
            return;
          } else if (this.state.data && this.state.data.filters) {
            // if filters are applied on the table then refresh the page with filters
            params = Object.assign(
              {
                offset: 0, // Reset page to 1 if applying filters
              },
              this.state.data.filters
            );
          }
          this.fetchTableData(params);
          break;
        case TABLE_ACTIONS.SET_API_PARAMS:
          this.setState((prevState) => {
            return {
              apiParams: Object.assign({}, prevState.apiParams, data),
            };
          }, this.fetchTableData);
          break;
        default:
          break;
      }
    } else if (
      this.props.tableProperties.actions &&
      action in this.props.tableProperties.actions
    ) {
      // Perform custom action
    }
  }
  componentDidMount() {
    this.fetchTableData({
      ...this.state.data.filters,
      page: window.localStorage.getItem(this.props.className),
    });
  }

  componentWillUnmount() {
    this.api && this.api.cancel();
  }

  updateView(data, key) {
    if (this.props.updateView) {
      let newView = this.props.updateView(data, key);
      let newData = Object.assign({}, this.state.data);
      newData.viewItems = newView;
      this.setState({
        data: newData,
      });
    }
  }

  closeDragDialog() {
    this.setState({ isDrag: false });
  }

  onSortEnd({ oldIndex, newIndex }) {
    let beforeSwap = JSON.parse(JSON.stringify(this.viewData));
    this.viewData =
      this.viewData && arrayMove(this.viewData, oldIndex, newIndex);
    let afterSwap = JSON.parse(JSON.stringify(this.viewData));

    beforeSwap.map((ele, index) => {
      if (ele.id !== afterSwap[index].id) {
        this.setState({ saveBanner: true });
      }
      return null;
    });

    let updatedArray = afterSwap.map((banner, index) => {
      let newSequence = Object.assign(
        { ...banner },
        { sequence: beforeSwap[index].sequence }
      );
      return newSequence;
    });

    this.viewData = updatedArray;
    this.forceUpdate();
  }

  onAddBanner() {
    let filter =
      this.state &&
      this.state.data &&
      this.state.data.filters &&
      this.state.data.filters.hasOwnProperty("pageType");
    let params = {};
    params.pageType = this.state.pageType || "HOME";
    if (
      (!filter && this.state.pageType === "HOME") ||
      (filter && this.state.data.filters.pageType === this.state.pageType)
    ) {
      let data = (this.viewData || []).filter((banner) => {
        return banner.pageType === this.state.pageType;
      });

      data.map((banner, index, arr) => {
        if (this.state.id !== banner.id) {
          let api = new API({
            url: this.props.api.url + "/" + banner.id,
          });
          api
            .put({ sequence: banner.sequence + 1 })
            .then((response) => {
              this.viewData &&
                this.viewData.map((data, index) => {
                  if (response.data.banner.id === data.id) {
                    this.viewData[index].sequence =
                      response.data.banner.sequence;
                  }
                  return null;
                });
            })
            .catch((error) => {
              console.error(error);
              if (error.code === 401) {
                throw error;
              }
            });
        }
        return null;
      });
    } else if (
      (!filter && this.state.pageType !== "HOME") ||
      (filter && this.state.data.filters.pageType !== this.state.pageType)
    ) {
      this.api &&
        this.api
          .get(params)
          .then((response) => {
            return this._transformResponse(response) || [];
          })
          .then((response) => {
            (response || []).map((banner, index, arr) => {
              if (this.state.id !== banner.id) {
                let api = new API({
                  url: this.props.api.url + "/" + banner.id,
                });
                api
                  .put({ sequence: banner.sequence + 1 })
                  .then((response) => {
                    this.viewData &&
                      this.viewData.map((data, index) => {
                        if (response.data.banner.id === data.id) {
                          this.viewData[index].sequence =
                            response.data.banner.sequence;
                        }
                        return null;
                      });
                  })
                  .catch((error) => {
                    console.error(error);
                    if (error.code === 401) {
                      throw error;
                    }
                  });
              }
              return null;
            });
          });
    }
  }

  onDraggableSubmit() {
    this.setState({ isDrag: true });

    let a = this.viewData;
    let b = this.previousData;

    if (a && b) {
      for (let index = 0; index < a.length; index++) {
        for (let index1 = 0; index1 < b.length; index1++) {
          if (a[index].id === b[index1].id) {
            if (a[index].sequence !== b[index1].sequence) {
              this.setState({ submitting: true });
              let api = new API({
                url: this.props.api.url + "/" + a[index].id,
              });
              api
                .put({ sequence: a[index].sequence })
                .then((response) => {
                  setTimeout(() => this.setState({ submitting: false }), 1000);
                })
                .catch((error) => {
                  console.error(error);
                  if (error.code === 401) {
                    throw error;
                  }
                });
            }
          }
        }
      }
    }
    this.previousData = this.viewData;
  }

  handleMove(event) {
    if (window.innerHeight - event.clientY < 50) {
      window.scrollBy(0, 10);
    } else if (event.clientY < 50) {
      window.scrollBy(0, -10);
    }
  }

  render() {
    // TODO: Add support for replacing default messages with localized strings
    let { props } = this;
    let view = null;
    let data = this.state.data;
    let filtersCount = Object.keys(data.filters).filter((key) =>
      Boolean(data.filters[key])
    ).length;
    let filtersApplied = filtersCount > 0;
    let emptyStateShown = false;
    if (
      !this.api ||
      (data.paging &&
        data.paging.count === 0 &&
        !(data.items && data.items.length > 0)) ||
      (data.items && data.items.length === 0)
    ) {
      if (filtersApplied) {
        view = <div className="text-muted text-center">No results found</div>;
      } else {
        emptyStateShown = true;
        let emptyStateProps = Object.assign({}, props.emptyState);
        let emptyState = <EmptyState {...emptyStateProps} />;
        let helpItems = null;
        if (props.helpItems && !isEnterprise()) {
          helpItems = (
            <HelpWidget title={props.helpItems.title}>
              {props.helpItems.instructions.map((instruction, index) => (
                <HelpCard
                  icon={instruction.icon}
                  title={instruction.title}
                  key={index}
                  onAction={instruction.onAction}
                >
                  {instruction.description}
                </HelpCard>
              ))}
            </HelpWidget>
          );
        }
        view = (
          <div>
            {emptyState}
            {helpItems}
          </div>
        );
      }
    } else if (data.items && props.tableProperties.row && this.viewData) {
      let Row = props.tableProperties.row;
      let { count, offset, limit } = data.paging;
      count = Number(count);
      offset = Number(offset);
      limit = Number(limit);
      let totalPages = Math.ceil(count / limit);
      const page = window.localStorage.getItem(this.props.className);
      if (
        this.state &&
        this.state.data &&
        Object.keys(this.state.data.filters).length === 0
      ) {
        this.viewData = this.viewData.filter((banner) => {
          return banner.pageType === "HOME";
        });
      }

      let emptyStateProps = Object.assign({}, props.emptyState);
      let emptyState = <EmptyState {...emptyStateProps} />;

      let helpItems = null;
      if (props.helpItems && !isEnterprise()) {
        helpItems = (
          <HelpWidget title={props.helpItems.title}>
            {props.helpItems.instructions.map((instruction, index) => (
              <HelpCard
                icon={instruction.icon}
                title={instruction.title}
                key={index}
                onAction={instruction.onAction}
              >
                {instruction.description}
              </HelpCard>
            ))}
          </HelpWidget>
        );
      }
      this.viewData.length === 0 && this.state.pageType !== "HOME"
        ? (view = (
            <div>
              {emptyState}
              {helpItems}
            </div>
          ))
        : (view = (
            <div className="table-container">
              <Table tableDynamic={props.tableDynamic || false}>
                <SortableList
                  viewData={this.viewData}
                  Row={Row}
                  pressDelay={200}
                  onSortEnd={this.onSortEnd}
                  apiParams={this.state.apiParams}
                  onAction={this.performAction}
                  lockAxis="y"
                  lockToContainerEdges
                  onSortMove={this.handleMove}
                  helperClass="manage-banners-sortable-helper"
                />

                {props.addToTable && (
                  <props.addToTable onAction={this.performAction} />
                )}
              </Table>

              <div className="pagination-container">
                <div className="pagination-count">{`${getMessage(
                  "pagination.text"
                )} ${offset + 1} - ${
                  limit > 0 && offset >= 0
                    ? Math.min(offset + limit, count)
                    : count
                } ${getMessage("pagination.helperText")} ${count}`}</div>
                {totalPages > 1 ? (
                  <Pagination
                    total={totalPages}
                    current={Number(page) || Math.floor(offset / limit + 1)}
                    onSelect={this.changePage}
                    // pageNumberNotEditable
                  />
                ) : null}
              </div>
              {this.viewData &&
                this.viewData.length > 1 &&
                this.state.saveBanner && (
                  <div className="draggable-actions">
                    <button
                      type="button"
                      onClick={this.onDraggableSubmit}
                      className="button primary"
                    >
                      {this.state.submitting ? "..." : "Save"}
                    </button>
                  </div>
                )}
            </div>
          ));
    }
    let HeaderActions = this.props.headerActions || NullComponent;
    const Form = props.form
      ? props.form.component || NullComponent
      : NullComponent;
    const Filters = props.filters
      ? props.filters.component || NullComponent
      : NullComponent;
    const allowDelete = props.form && props.form.allowDelete; // to allow delete action from inside the form
    if (emptyStateShown) {
      HeaderActions =
        props.emptyState && props.emptyState.actions
          ? props.emptyState.actions
          : NullComponent;
    }
    let WrapperComponent = this.props.menu
      ? AuthenticatedPage
      : ({ menu, title, storeDependent, ...props }) => <div {...props} />;
    return (
      <WrapperComponent
        menu={props.menu}
        className={"listing-page " + props.className}
        storeDependent={props.storeDependent}
        onChange={() => {
          this.performAction(TABLE_ACTIONS.SET_API_PARAMS, {
            storeId: get("store"),
          });
          this.props.api &&
            this.props.api.onUpdateStore &&
            this.props.api.onUpdateStore();
        }}
      >
        <div className="header-container">
          <h1 className="title">{props.title}</h1>
          {(!emptyStateShown && this.state.firstLoadDone) ||
          (this.props.filters && this.props.filters.forceShow) ? (
            <div className="header-actions-wrapper">
              {this.props.filters ? (
                <div className="search-button-wrapper">
                  <button
                    className={
                      "search-button" +
                      (this.state.filters.shown ? "" : " active")
                    }
                    onClick={this._toggleFilters}
                  />
                  {/* <span className='search-button-count'>{filtersCount || null}</span> */}
                </div>
              ) : null}
              <HeaderActions
                apiParams={this.state.apiParams}
                onAction={this.performAction}
                data={this.state.data}
              />
            </div>
          ) : (
            emptyStateShown && (
              <div className="header-actions-wrapper">
                <HeaderActions
                  apiParams={this.state.apiParams}
                  onAction={this.performAction}
                />
              </div>
            )
          )}
        </div>
        {this.props.additionalViews
          ? this.props.additionalViews.map((View, index) => (
              <View
                key={index}
                data={this.props.updateView ? this.state.data.items : null}
                updateView={this.props.updateView ? this.updateView : null}
              />
            ))
          : null}
        <div
          className={
            "filters-wrapper" + (this.state.filters.shown ? " hidden" : "")
          }
        >
          {this.state.firstLoadDone && (
            <Filters
              value={this.state.data.filters}
              onClear={this.clearFilters}
              onSubmit={this.applyFilters}
              options={props.filters ? props.filters.options : null}
            />
          )}
        </div>
        {this.state.form.shown ? (
          <Modal
            heading={
              this.state.form.rowIndex > -1
                ? this.props.editHeading ||
                  (this.props.getEditHeading &&
                    this.props.getEditHeading(
                      this.state.data.items[this.state.form.rowIndex]
                    ))
                : this.props.addHeading ||
                  (this.props.getAddHeading &&
                    this.props.getAddHeading(this.state.form.data))
            }
            className={
              this.props.modalClassName ||
              (this.props.getModalClassName &&
                ((this.state.form.data &&
                  this.props.getModalClassName(this.state.form.data)) ||
                  (this.state.form.rowIndex > -1 &&
                    this.props.getModalClassName(
                      this.state.data.items[this.state.form.rowIndex]
                    )))) ||
              ""
            }
            show={this.state.form.shown}
            close={this._hideForm}
          >
            <Form
              value={
                this.state.form.rowIndex > -1
                  ? this.state.data.items[this.state.form.rowIndex]
                  : this.state.form.data
              }
              onSubmit={
                this.state.form.rowIndex > -1
                  ? this.modifyResource
                  : this.createResource
              }
              onCancel={this._hideForm}
              method={this.state.form.rowIndex > -1 ? "edit" : "add"}
              options={this.props.form && this.props.form.options}
              onDelete={
                allowDelete
                  ? () =>
                      this.performAction(
                        "DELETE",
                        this.state.data.items[this.state.form.rowIndex]
                      )
                  : null
              }
            />
          </Modal>
        ) : null}
        {this.state.deleteDialog.shown && (
          <Dialog
            show={this.state.deleteDialog.shown}
            title={getMessage("deleteDialog.title")}
            information={getMessage("deleteDialog.information")}
            onOk={this.confirmDelete}
            close={this._hideDeleteDialog}
            closeText={getMessage("dialog.cancelText")}
            okText={getMessage("dialog.okText")}
          />
        )}
        {this.state.errorDialog.shown && (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={getMessage(this.state.errorDialog.message)}
            close={this._hideErrorDialog}
            closeText={getMessage("dialog.okText")}
          />
        )}
        {this.state.loaders.data ? <Loader /> : view}
        {!emptyStateShown && this.props.additionalViewsBottom
          ? this.props.additionalViewsBottom.map((View, index) => (
              <View key={index} values={this.state.data} />
            ))
          : null}
        <Dialog
          className="success draggable-listing-page"
          show={this.state.isDrag}
          close={this.closeDragDialog}
          message={getMessage("banners.success.dialog.message")}
          closeText="OK"
        />
      </WrapperComponent>
    );
  }
}

DraggableListingPage.propTypes = {
  title: PropTypes.string,
  api: PropTypes.shape({
    url: PropTypes.string.isRequired,
    transform: PropTypes.func,
  }),
};

export default withRouter(({ location, history, match, ...props }) => (
  <DraggableListingPage router={{ location, history, match }} {...props} />
));
