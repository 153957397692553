import React, { Component } from "react";
import moment from "moment";
import ListingPage from "../../../containers/ListingPage";
import { getDefaultStore } from "../../../containers/StoreSelector";
import { getMessage } from "../../../lib/translator";
import { isExtensionEnabled } from "../../../lib/auth";
import API from "../../../lib/api";
import { get } from "../../../lib/storage";
import OrderFilters from "./Filters";
import { tabularView } from "./Views";
import emptyIcon from "./click-collect-empty.svg";
import { getStandradDateTime, utcToLocalTime } from "../../../lib/datetime";
import checkHttpResponseCode from "../../../utils/isApiCallSuccessfull";

const emptyState = () => {
  return {
    icon: emptyIcon,
    message: getMessage("order.click.collect.empty"),
  };
};
const { Provider, Consumer } = React.createContext("commConfig");

class ClickCollectOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollectCenterWithPickup: false,
      customLoading: true,
    };
  }
  componentDidMount() {
    this.communicationApi = new API({
      url: `/account-service/config/communication`,
    });
    this.communicationApi
      .get()
      .then((response) => {
        this.setState({ communicationConfig: response.data.config });
      })
      .catch((error) => {
        throw error;
      });

    if (isExtensionEnabled("InStoreProcessing")) {
      this.api = new API({
        url: `/config-service/config/inStoreProcessing.packedOrderEditAllowed`,
      });
      this.api
        .get()
        .then((res) => {
          if (checkHttpResponseCode(res.code) && res.status === "SUCCESS") {
            this.setState({
              packedOrderEdit:
                res &&
                res.data &&
                res.data.inStoreProcessing &&
                res.data.inStoreProcessing.packedOrderEditAllowed,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.code === 401 || err.code === 403) {
            throw err;
          }
        });
    }
    const storeId = get("store") || getDefaultStore().storeId;
    this.storeApi = new API({ url: `/account-service/store/${storeId}` });
    this.orderConfigApi = new API({ url: `/account-service/config/order` });
    Promise.all([this.storeApi.get(), this.orderConfigApi.get()])
      .then(([storeApiRes, orderConfigRes]) => {
        this.setState({
          isCollectCenterWithPickup:
            storeApiRes?.data?.store?.hasClickCollect &&
            orderConfigRes?.data?.config?.order?.orderTypes?.includes("PICKUP"),
          customLoading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ customLoading: false });
        throw error;
      });
  }
  componentWillUnmount() {
    this.communicationApi && this.communicationApi.cancel();
  }
  render() {
    const { props } = this;
    // If url is present in the props, then we need to show customer support related interfaces
    return (
      <Provider value={this.state && this.state.communicationConfig}>
        <ListingPage
          menu={props.menu}
          className={`orders-page ${props.className || "ops"}`}
          title={getMessage("menu.item.click-collect-orders")}
          showEmptyState={!this.state.isCollectCenterWithPickup}
          customLoading={this.state.customLoading}
          api={{
            url: "/order-service/order",
            params: {
              status: "PACKED",
              preferredDate: moment().format("YYYY-MM-DD"),
              "include[0]": "packingInfo",
              "include[1]": "customerLocation",
              orderType: "PICKUP",
            },
            updateApiParams: props.updateApiParams
              ? props.updateApiParams
              : null,
            transform: (response) => {
              let orders = response.data.order;
              if (Array.isArray(orders)) {
                orders.map((order) => {
                  if (order.createdAt) {
                    order.createdAt = getStandradDateTime(
                      utcToLocalTime,
                      order.createdAt
                    );
                  }
                  if (order.completedAt) {
                    order.completedAt = getStandradDateTime(
                      utcToLocalTime,
                      order.completedAt
                    );
                  }
                  if (order.updatedAt) {
                    order.updatedAt = getStandradDateTime(
                      utcToLocalTime,
                      order.updatedAt
                    );
                  }
                  if (order.preferredDate && order.slotStartTime) {
                    const tempDate = getStandradDateTime(
                      utcToLocalTime,
                      `${order.preferredDate} ${order.slotStartTime}`
                    ).split(" ");
                    order.preferredDate = tempDate[0];
                    order.slotStartTime = tempDate[1];
                  }
                  if (order.preferredDate && order.slotEndTime) {
                    const tempDate = getStandradDateTime(
                      utcToLocalTime,
                      `${order.preferredDate} ${order.slotEndTime}`
                    ).split(" ");
                    order.slotEndTime = tempDate[1];
                  }
                  return order;
                });
              }
              orders = orders?.sort((a, b) => {
                if (
                  a.customerLocation?.distance > 0 &&
                  b.customerLocation?.distance > 0 &&
                  a.customerLocation?.distance > b.customerLocation?.distance
                ) {
                  return 1;
                } else return -1;
              });
              return orders;
            },
          }}
          primaryKey="referenceNumber"
          emptyState={emptyState()}
          filters={{
            component: OrderFilters,
            transformFilter: (formData) => {
              let result = Object.assign({}, formData);
              if (result.customer) {
                result.customerId = result.customer.id;
                delete result.customer;
              }
              return result;
            },
            transformSubmit: (formData) => {
              let searchParams = "";
              Object.keys(formData)
                .filter(
                  (key) =>
                    key !== "filters" && (formData[key] === 0 || formData[key])
                )
                .forEach((key, index) => {
                  if (index === 0) {
                    searchParams += `?${key}=${formData[key]}`;
                  } else {
                    searchParams += `&${key}=${formData[key]}`;
                  }
                });
              if (searchParams !== "") {
                props.history.push({ search: searchParams });
              }
              let result = Object.assign({}, formData);
              if (result.customer) {
                result.customerId = result.customer.id;
                delete result.customer;
              }
              return result;
            },
          }}
          onClear={() => props.history.push({ search: "filters=all" })}
          tableProperties={tabularView(
            `/operations`,
            this.state && this.state.packedOrderEdit
          )}
          tableDynamic
          storeDependent={true}
        />
        )
      </Provider>
    );
  }
}

export default ClickCollectOrders;
export { Provider, Consumer };
