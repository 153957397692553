import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  Upload,
  Checkbox,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import deleteIcon from "./delete.svg";

import "./style.css";

export default class TagForm extends BaseForm {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
    if (props.method === "add") {
      this.state.values.filterable = true;
    }
  }
  delete() {
    this.props.onDelete();
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div className="TagForm">
        <Form>
          <Input
            label={getMessage("tag.form.name.heading")}
            placeholder={getMessage("tag.form.name.placeholder")}
            name="name"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <Input
            label={getMessage("tag.form.tagline.heading")}
            placeholder={getMessage("tag.form.tagline.placeholder")}
            name="tagline"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["tagLine"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Upload
            label={getMessage("tag.form.image.heading")}
            placeholder={getMessage("tag.form.image.placeholder")}
            {...this.generateStateMappers({
              stateKeys: ["image"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Checkbox
            name="filterble"
            inlineLabel={getMessage("Filterable")}
            {...this.generateStateMappers({
              stateKeys: ["filterable"],
              loseEmphasisOnFill: true,
            })}
          />
          <div className={`form-actions ${this.props.method}`}>
            {this.props.method && this.props.method === "edit" && (
              <button
                className="delete-button"
                type="button"
                onClick={this.delete}
              >
                <img src={deleteIcon} alt="delete" />
                <span>{getMessage("brand.form.deleteText")}</span>
              </button>
            )}
            {(!this.props.method || this.props.method !== "edit") && (
              <CancelButton>{getMessage("brand.form.cancelText")}</CancelButton>
            )}
            <SubmitButton>{getMessage("tag.form.submitText")}</SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}
