import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";

import "./style.css";

class PaymentResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: false,
      verificationData: {},
    };
    let queryString = this.props.router.location.search.slice(1); // Slice to remove '?' character
    this.query = Object.assign(
      {},
      ...queryString.split("&").map((keyVal) => {
        keyVal = keyVal.split("=");
        return {
          [keyVal[0]]: keyVal[1],
        };
      })
    );
    this.verifyPayment = this.verifyPayment.bind(this);
  }
  verifyPayment() {
    let api = new API({ url: "/billing-service/verify-payment" });
    return api.post(this.query);
  }
  componentDidMount() {
    this.verifyPayment()
      .then((response) => {
        this.setState({
          verified: true,
          verificationData: response.data.onlinepayment,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    return (
      <AuthenticatedPage menu={false} className="payment-response">
        {!this.state.verified ? (
          <div className="payment-response-overlay">
            <div>{getMessage("user.payment.loader.text")}</div>
          </div>
        ) : (
          <div className="payment-response-overlay">
            {this.state.verificationData.status === "COMPLETED" ? (
              <h1 className="success-title">
                {getMessage("user.payment.success.title")}
              </h1>
            ) : (
              <h1 className="fail-title">
                {getMessage("user.payment.fail.title")}
              </h1>
            )}
            <Link className="redirect-link" to="/user/account-balance">
              {getMessage("user.payment.redirect.link")}
            </Link>
          </div>
        )}
      </AuthenticatedPage>
    );
  }
}

export default withRouter(({ location, history, match, ...props }) => (
  <PaymentResponse router={{ location }} {...props} />
));
