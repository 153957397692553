import React from "react";
import { BaseForm } from "../../../../components/Form";
import { Select } from "../../../../components/Form/Inputs";
import TripSequenceOrders from "./TripSequenceOrders";
import { getMessage } from "../../../../lib/translator";
import MapWithPath from "../../../../components/MapWithPath";
import API from "../../../../lib/api";
import { Dialog } from "../../../../components/Popup";

import "./style.css";

class TripSequence extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      deliveryData: props.deliveryData,
      coordinates: this.getCoordinates(props.deliveryData),
      vehicleId: props.recommendedVehicle && props.recommendedVehicle.id,
      zopsmartMapUrls: this.props.zopsmartMapUrls,
    };
    this.getCoordinates = this.getCoordinates.bind(this);
    this.updateTripSequence = this.updateTripSequence.bind(this);
    this.getDistance = this.getDistance.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getStoreLocation = this.getStoreLocation.bind(this);
    this.getMarkersCoordinates = this.getMarkersCoordinates.bind(this);
    this.getAddressString = this.getAddressString.bind(this);
    this.getTripDistance = this.getTripDistance.bind(this);
  }

  getTripDistance(addresses) {
    const api = new API({
      url:
        `${this.state.zopsmartMapUrls}/route/?points_encoded=false` +
        addresses +
        "&locale=en-US&vehicle=car&weighting=fastest&elevation=false&layer=OpenStreetMap",
    });
    api
      .get()
      .then((response) => {
        this.getDistance(response.paths[0].distance);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          showErrorDialog: true,
          formError: error.message,
        });
      });
  }

  getAddressString() {
    let coordinates = this.getMarkersCoordinates();
    let vehiclePath = this.state.coordinates;
    let deliveryLocations = !vehiclePath
      ? coordinates &&
        coordinates
          .map(
            (location) =>
              location.latitude &&
              location.longitude &&
              `&point=${location.latitude},${location.longitude}`
          )
          .filter(Boolean)
      : vehiclePath
          .map(
            (location) =>
              location.latitude &&
              location.longitude &&
              `&point=${location.latitude},${location.longitude}`
          )
          .filter(Boolean);
    return deliveryLocations;
  }

  getCoordinates(orders) {
    const { hubTrip } = this.props?.currentTripHub || {};
    let storeLocation = this.getStoreLocation();
    if (hubTrip === true) {
      const hubLocation = this.getHubLocation();
      return storeLocation.concat(hubLocation).concat(storeLocation);
    }
    let deliveryLocations = orders
      .map(
        ({ address }) =>
          address.location &&
          address.location.lat && {
            latitude: address.location.lat,
            longitude: address.location.long,
          }
      )
      .filter(Boolean);

    return storeLocation.concat(deliveryLocations).concat(storeLocation);
  }

  updateTripSequence(orders) {
    this.setState({
      deliveryData: orders,
      coordinates: this.getCoordinates(orders),
    });
    this.getTripDistance(this.getAddressString());
  }

  getDistance(distance) {
    distance = Number.parseFloat(distance / 1000).toFixed(2) + " KM";
    this.setState({ tripDistance: distance });
  }

  getMarkersCoordinates() {
    return (
      this.state.coordinates &&
      this.state.coordinates.slice(1, this.state.coordinates.length - 1)
    );
  }

  getStore() {
    let { storeId, storeData } = this.props;
    let store = storeData.filter(
      (store) => store.id === parseInt(storeId, 10)
    )[0];
    return store;
  }

  getStoreLocation() {
    let { storeId, storeData } = this.props;
    let storeLocation;
    if (!this.props.storeLocation) {
      let store = storeData.filter(
        (store) => store.id === parseInt(storeId, 10)
      );
      storeLocation = store.length
        ? [{ latitude: store[0].latitude, longitude: store[0].longitude }]
        : [];
    } else {
      storeLocation = [
        {
          latitude: this.props.storeLocation.lat,
          longitude: this.props.storeLocation.lng,
        },
      ];
    }
    return storeLocation;
  }
  getHubLocation() {
    const { hub } = this.props?.currentTripHub || {};
    if (hub) {
      return [{ latitude: hub.location.lat, longitude: hub.location.long }];
    }
    return [];
  }

  onSubmit() {
    const { hubTrip, hub } = this.props?.currentTripHub || {};
    let orderNumbers = this.state.deliveryData
      ? this.state.deliveryData.map((order) => order.id)
      : [];
    let params = {};
    params.orderNumbers = orderNumbers;
    params.storeId = this.props.storeId;
    params.vehicleId = this.state.vehicleId;
    params.deliveryAgentId = this.state.deliveryAgentId;
    if (hubTrip === true) {
      params.hubId = hub?.id;
    }

    this.startTrip(params);
  }
  startTrip(params) {
    let api = new API({ url: "/logistics-service/trip" });
    this.setState({ submitting: true });
    api
      .post(params)
      .then((response) => {
        // TODO: Response should be added to 'Open trips' state

        this.setState({
          submitting: false,
          successMessage: getMessage("tripPlanner.trip.started"),
        });
        this.props.modifyState(
          params.orderNumbers,
          params.vehicleId,
          params.deliveryAgentId
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          submitting: false,
          error: error.message,
        });
      });
  }

  componentDidMount() {
    this.getTripDistance(this.getAddressString());
  }

  closeDialogs = () => {
    this.setState({
      showErrorDialog: false,
    });
  };

  render() {
    let { Form } = this.components;
    let { SubmitButton, CancelButton } = this.buttons;
    let storeLocation = this.getStoreLocation()[0];
    const store = this.getStore();
    let statuses = ["PACKED"];

    if (store?.hasPicking === false || store?.hasPicking === 0) {
      statuses = ["AT-HUB"];
    }

    const orderReadyToBeDispatched =
      this.state.deliveryData &&
      this.state.deliveryData.reduce(
        (ready, order) => ready && statuses.includes(order.status),
        true
      );

    return !(storeLocation.latitude && storeLocation.longitude) ? (
      <div className="no-store-location">
        {getMessage("tripPlanner.trip.storeLocation.error")}
      </div>
    ) : (
      <div className="TripSequenceMap">
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage(this.state.formError)}
          close={this.closeDialogs}
          closeText={getMessage("okay.text")}
        />
        <div className="trip-sequence-details">
          <div className="trip-sequence-details-heading">{`${getMessage(
            "tripPlanner.start"
          )}`}</div>
          <div className="trip-sequence-form">
            <div className="trip-sequence-orders">
              <TripSequenceOrders
                orders={this.state.deliveryData}
                updateTripSequence={this.updateTripSequence}
              />
            </div>
            <div className="trip-distance-container">
              Total Trip Distance: {this.state.tripDistance}
            </div>
            <Form>
              <h3>{getMessage("tripPlanner.assign")}</h3>
              <div className="select-buttons">
                <Select
                  placeholder={getMessage("tripPlanner.runner.executive")}
                  options={
                    this.props.re
                      ? this.props.re.map((elm) => {
                          return {
                            text: elm.name,
                            value: elm.id,
                          };
                        })
                      : []
                  }
                  value={this.state.deliveryAgentId}
                  onChange={(e) => this.setState({ deliveryAgentId: e })}
                />
                <Select
                  placeholder={getMessage("tripPlanner.runner.vehicle")}
                  options={
                    this.props.vehicle
                      ? this.props.vehicle.map((elm) => {
                          return {
                            text: elm.number,
                            value: elm.id,
                          };
                        })
                      : []
                  }
                  onChange={(e) => this.setState({ vehicleId: e })}
                  value={this.state.vehicleId}
                />
              </div>
              {this.state.error && (
                <div className="trip-error">{this.state.error}</div>
              )}
              <div className="form-buttons">
                <CancelButton>Cancel</CancelButton>
                <SubmitButton
                  disabled={
                    this.state.submitting ||
                    !orderReadyToBeDispatched ||
                    !(
                      this.state.deliveryData &&
                      this.state.deliveryAgentId &&
                      this.state.vehicleId
                    )
                  }
                >
                  Start Trip
                </SubmitButton>
              </div>
            </Form>
          </div>
        </div>
        <MapWithPath
          coordinates={this.getMarkersCoordinates()}
          getDistance={this.getDistance}
          showStore={storeLocation}
          vehiclePath={this.state.coordinates}
          zopsmartMapUrls={this.state.zopsmartMapUrls}
        />
      </div>
    );
  }
}

export default TripSequence;
