import React, { Component } from "react";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import Form from "./form";
import "./style.css";

class CommunicationConfig extends Component {
  render() {
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        className="communication-config"
      >
        <h1 className="title">Communication Configuration</h1>
        <Form back={this.props.history.goBack} />
      </AuthenticatedPage>
    );
  }
}

export default CommunicationConfig;
