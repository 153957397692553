import React from "react";
import {
  BaseForm,
  Input,
  Phone,
  VALIDATION_TYPES,
  Searchable,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import "./style.css";

export default class UserForm extends BaseForm {
  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    const { props } = this;
    const sellerId = this.props.value?.sellerID;
    return (
      <div className="UserForm">
        <Form>
          <Input
            label={getMessage("sellers.user.form.name.heading")}
            placeholder={getMessage("sellers.user.form.name.pleceholder")}
            name="name"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />

          <Searchable
            label={getMessage("sellers.user.form.sellername.heading")}
            placeholder={getMessage("sellers.user.form.sellername.placeholder")}
            name="seller"
            type="text"
            searchUrl="/account-service/seller"
            valueKey="id"
            searchKey="name"
            transformResponse={(response) =>
              response.data?.seller?.filter((sell) => sell?.id !== sellerId)
            }
            {...this.generateStateMappers({
              stateKeys: ["seller"],
              loseEmphasisOnFill: true,
            })}
          />
          <Phone
            country={this.state.countryCode}
            label={getMessage("sellers.user.form.phone.heading")}
            placeholder={getMessage("sellers.user.form.phone.placeholder")}
            name="phone"
            className="phone-number"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["phone"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            label={getMessage("sellers.user.form.email.heading")}
            placeholder={getMessage("sellers.user.form.email.placeholder")}
            className="seller-email"
            name="email"
            type="email"
            required
            {...this.generateStateMappers({
              stateKeys: ["email"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <div className={`form-actions ${props.method}`}>
            <div className="form-buttons">
              <SubmitButton>{getMessage("users.save.text")}</SubmitButton>
              <CancelButton>{getMessage("sellers.cancel.text")}</CancelButton>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
