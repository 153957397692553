import React, { Component } from "react";
import { Popup } from "../../../../../components/Popup";
import OrderItemsView from "../OrderItemsView";
import Loader from "../../../../../components/Loader";
import { getMessage } from "../../../../../lib/translator";
import { getSession, isExtensionEnabled } from "../../../../../lib/auth";
import {
  formatDate,
  formatTime,
  getPrintableTime,
} from "../../../../../lib/datetime";
import { parse, format } from "libphonenumber-js";
import API from "../../../../../lib/api";
import {
  getAsapDuration,
  getDisplayAddress,
} from "../../../../../lib/commonlyused";
import {
  getStandradDateTime,
  utcToLocalTime,
} from "../../../../../lib/datetime";
import "./style.css";

class OrderInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.organization = getSession().organization;
    this.currency = this.organization.currency;
    this.hideInvoice = this.hideInvoice.bind(this);
  }
  hideInvoice() {
    this.props.close();
  }
  getData() {
    let referenceNumber = this.props.referenceNumber;
    let api = new API({ url: `/order-service/order/${referenceNumber}` });
    this.setState(
      {
        loading: true,
        failed: false,
      },
      () => {
        api
          .get()
          .then(
            (response) => {
              const order = response.data.order;
              if (order.createdAt) {
                order.createdAt = getStandradDateTime(
                  utcToLocalTime,
                  order.createdAt
                );
              }
              if (order.completedAt) {
                order.completedAt = getStandradDateTime(
                  utcToLocalTime,
                  order.completedAt
                );
              }
              if (order.updatedAt) {
                order.updatedAt = getStandradDateTime(
                  utcToLocalTime,
                  order.updatedAt
                );
              }
              if (order.preferredDate && order.slotStartTime) {
                const tempDate = getStandradDateTime(
                  utcToLocalTime,
                  `${order.preferredDate} ${order.slotStartTime}`
                ).split(" ");
                order.preferredDate = tempDate[0];
                order.slotStartTime = tempDate[1];
              }
              if (order.preferredDate && order.slotEndTime) {
                const tempDate = getStandradDateTime(
                  utcToLocalTime,
                  `${order.preferredDate} ${order.slotEndTime}`
                ).split(" ");
                order.slotEndTime = tempDate[1];
              }
              this.setState({
                failed: false,
                data: order,
              });
            },
            (error) => {
              this.setState({ failed: true });
              if (error.code === 401) {
                throw error;
              }
            }
          )
          .then(() => {
            this.setState(
              (prevState) => {
                return { loading: false };
              },
              () => {
                if (this.props.print) {
                  window.print();
                  this.hideInvoice();
                }
              }
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
    if (isExtensionEnabled("EntityMetaData") && !this.props.addressSequence) {
      this.api = new API({ url: "/config-service/config/customers" });
      this.api
        .get()
        .then((response) => {
          let addressSequence = response.data.customers.addressSequence;
          this.setState({
            addressSequence,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.props.data,
        loading: false,
        failed: false,
        addressSequence: this.props.addressSequence,
      });
    } else if (this.props.show) {
      this.getData();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    let { props } = this;
    let newData = props && props.data;
    let prevData = prevState && prevState.data;
    if (newData && prevData) {
      if (
        newData.amount !== prevData.amount ||
        newData.preferredDate !== prevData.preferredDate ||
        newData.slotStartTime !== prevData.slotStartTime ||
        newData.slotEndTime !== prevData.slotEndTime
      ) {
        this.setState({
          data: newData,
        });
      }
    }
  }
  render() {
    const data = this.state.data || {};
    const name =
      data.address &&
      data.address.metaData &&
      (data.address.metaData.FirstName || data.address.metaData.LastName)
        ? `${
            data.address.metaData.FirstName
              ? data.address.metaData.FirstName
              : ""
          } ${
            data.address.metaData.LastName ? data.address.metaData.LastName : ""
          }`
        : data.customer && data.customer.name;
    let contentLoaded =
      !this.state.loading && !this.state.failed && this.state.data;
    return contentLoaded ? (
      this.props.show ? (
        <Popup
          className="editPopup invoice-popup"
          show={this.props.show}
          close={this.hideInvoice}
        >
          <div className="printable-area">
            <div className="invoice-header">
              {this.organization.logo && (
                <div className="org-logo">
                  <img alt="" src={this.organization.logo} />
                </div>
              )}
              <div>
                <h3 className="order-number">
                  {this.state.data.clientId ? (
                    <span>
                      {getMessage("order.details.invoice.order")}
                      <span className="text-muted">
                        {getMessage("order.table.clientId.prefix")}
                      </span>
                      {this.state.data.clientId}
                    </span>
                  ) : (
                    <span>
                      {getMessage("order.details.invoice.order")}
                      {this.state.data.referenceNumber}
                    </span>
                  )}
                </h3>
                <div className="order-time">
                  {getPrintableTime(this.state.data.createdAt)}
                </div>
              </div>
            </div>
            {this.state.data.store && (
              <div className="store-address-wrapper">
                <div className="store-name">{this.state.data.store.name}</div>
                <div className="store-address">
                  {this.state.data.store.address}
                </div>
              </div>
            )}
            <div className="flex-around order-addresses">
              <div className="customer-address-wrapper">
                <div className="delivery-address-details">
                  <div className="customer-name">{name}</div>
                  <div className="customer-phone text-muted">
                    {this.state.data.customer &&
                    this.state.data.customer.phones &&
                    !!this.state.data.customer.phones.length > 0 &&
                    Object.keys(parse(this.state.data.customer.phones[0].phone))
                      .length > 0
                      ? format(
                          parse(this.state.data.customer.phones[0].phone),
                          "International"
                        )
                      : this.state.data.customer &&
                        this.state.data.customer.phones &&
                        this.state.data.customer.phones.length
                      ? this.state.data.customer.phones[0].phone
                      : null}
                  </div>

                  {this.state.data.address && (
                    <div className="store-address">
                      {getDisplayAddress(
                        this.state.data.address,
                        this.state.addressSequence
                      )}
                    </div>
                  )}
                  {isExtensionEnabled("DeliverySlots") &&
                    this.state.data.address &&
                    this.state.data.preferredDate && (
                      <div className="slot-details">
                        <div className="section-title-slot-title">
                          {getMessage("order.details.slot.heading")} :{" "}
                          {` ${
                            this.state.data.preferredDate &&
                            formatDate(this.state.data.preferredDate)
                          } , `}
                          {this.state.data.slotType &&
                          this.state.data.slotType === "ASAP" &&
                          this.state.data.slotStartTime &&
                          this.state.data.slotEndTime
                            ? getAsapDuration(
                                this.state.data.slotStartTime,
                                this.state.data.slotEndTime
                              )
                            : this.state.data.slotStartTime
                            ? formatTime(this.state.data.slotStartTime) +
                              " - " +
                              formatTime(this.state.data.slotEndTime)
                            : this.state.data.slotEndTime
                            ? "Within " +
                              this.state.data.slotEndTime.split(":")[2] +
                              " minutes"
                            : null}
                        </div>
                      </div>
                    )}
                  {this.state.data.pickupLocation && (
                    <div className="store-address">
                      {this.state.data.pickupLocation.address}
                    </div>
                  )}
                </div>
                {this.state.data?.billingAddress && (
                  <div className="billing-address">
                    <div className="billing-title" style={{ textAlign: "end" }}>
                      {getMessage(
                        "order.details.location.billing.address.title"
                      )}
                    </div>
                    <div className="store-address" style={{ textAlign: "end" }}>
                      {getDisplayAddress(
                        this.state.data.billingAddress,
                        this.state.addressSequence
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <OrderItemsView
              data={{
                items: this.state.data.items || [],
                payment: this.state.data.payment || [],
                shipping: this.state.data.shipping,
                amount: this.state.data.amount,
                discount: this.state.data.discount,
                couponDiscount: this.state.data.couponDiscount,
                invoiceAmount: this.state.data.invoiceAmount,
                pendingAmount: this.state.data.pendingAmount,
                clickAndCollectCharges: this.state.data.clickAndCollectCharges,
                type: this.state.data.type.name,
                surcharge: this.state.data.surcharge,
                orderDiscount: this.state.data.orderDiscount,
              }}
              loadedFromInvoice
              currency={this.currency}
              status={this.state.data.status}
            />
            <div className="invoice-footer">
              <div className="tax-disclaimer">
                <div className="thank-you">
                  {getMessage(`Thank you for shopping at `, {
                    name: this.organization.name,
                  })}
                </div>
                <div className="thank-you">
                  {getMessage("We look forward to serve you again")}
                </div>
                <div className="thank-you">
                  {getMessage(`Visit us at`, {
                    domain: this.organization.domain,
                  })}
                </div>
              </div>
            </div>
          </div>
        </Popup>
      ) : (
        <div />
      )
    ) : this.props.show ? (
      <Popup
        className="editPopup invoice-popup"
        show={this.props.show}
        close={this.hideInvoice}
      >
        <Loader />
      </Popup>
    ) : (
      <div />
    );
  }
}

export default OrderInvoice;
