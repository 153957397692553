import React from "react";
import { BaseForm, Input, Select } from "../../../../components/Form";
import SingleDatePicker from "../../../../components/Form/Inputs/SingleDatePicker";
import { Consumer } from "../Context";
import { getMessage } from "../../../../lib/translator";
import "./style.css";

const slotTimes = () => {
  let timeStrings = [];
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      timeStrings.push({ text: `0${i}:00`, value: `0${i}:00:00` });
      timeStrings.push({ text: `0${i}:30`, value: `0${i}:30:00` });
    } else {
      timeStrings.push({ text: `${i}:00`, value: `${i}:00:00` });
      timeStrings.push({ text: `${i}:30`, value: `${i}:30:00` });
    }
  }
  return timeStrings;
};
/** *`VehicleForm`*
 * :adds management to **`Vehicles`** component
 */
export default class VehicleForm extends BaseForm {
  componentDidUpdate(prevProps, prevState) {
    let values = Object.assign({}, this.state.values);
    let start = values.startTime;
    let end = values.endTime;
    if (start && end && start > end) {
      if (prevState.formError === "") {
        this.setState({
          formError: getMessage("slots.start.end.mismatch"),
        });
      }
    }
    if (
      start &&
      end &&
      start < end &&
      prevState.formError === getMessage("slots.start.end.mismatch")
    ) {
      this.setState({
        formError: "",
      });
    }
  }
  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div className="VehiclePlannerForm">
        <Consumer>
          {(value) => {
            const editDate = this.props.value && this.props.value.date;
            let countOfCheckedIn =
              value &&
              value.checkedIn &&
              value.checkedIn.filter(
                (details) => details.date === editDate && !details.outTime
              ).length;
            let readOnly = countOfCheckedIn > 0;
            return (
              <Form>
                <div className="vehicle-form-fields">
                  <div className="form-field field">
                    <label>Date</label>
                    <SingleDatePicker
                      {...this.generateStateMappers({
                        stateKeys: ["date"],
                        loseEmphasisOnFill: true,
                      })}
                      enableToday
                      readOnly={readOnly}
                      disabled={readOnly}
                    />
                  </div>
                  <div />
                  <div className="form-field">
                    <Select
                      name="slot-start"
                      label={getMessage("slots.startTime")}
                      placeholder={getMessage("slots.startsAt")}
                      options={slotTimes()}
                      {...this.generateStateMappers({
                        stateKeys: ["shiftStart"],
                        loseEmphasisOnFill: true,
                      })}
                      required
                      disabled={readOnly}
                    />
                  </div>
                  <div className="form-field">
                    <Select
                      name="slot-end"
                      label={getMessage("slots.endTime")}
                      placeholder={getMessage("slots.endsAt")}
                      options={slotTimes()}
                      {...this.generateStateMappers({
                        stateKeys: ["shiftEnd"],
                        loseEmphasisOnFill: true,
                      })}
                      required
                      disabled={readOnly}
                    />
                  </div>
                  <div className="form-field">
                    <Input
                      name="regularCount"
                      label={getMessage(
                        "vehiclePlanner.addShift.form.regular.label"
                      )}
                      placeholder={getMessage(
                        "vehiclePlanner.addShift.form.regular.count"
                      )}
                      {...this.generateStateMappers({
                        stateKeys: ["regularCount"],
                        loseEmphasisOnFill: true,
                      })}
                      required
                    />
                  </div>
                  <div className="form-field">
                    <Input
                      name="adhocCount"
                      label={getMessage(
                        "vehiclePlanner.addShift.form.adhoc.label"
                      )}
                      placeholder={getMessage(
                        "vehiclePlanner.addShift.form.adhoc.count"
                      )}
                      {...this.generateStateMappers({
                        stateKeys: ["adhocCount"],
                        loseEmphasisOnFill: true,
                      })}
                    />
                  </div>
                  {this.state.formError && (
                    <div className="form-error">{this.state.formError}</div>
                  )}
                  <div className={`form-actions`}>
                    <SubmitButton>
                      {getMessage("vehicles.form.submitText")}
                    </SubmitButton>
                    <CancelButton>{getMessage("cancel")}</CancelButton>
                  </div>
                </div>
              </Form>
            );
          }}
        </Consumer>
      </div>
    );
  }
}
