import React, { Component } from "react";
import { getMessage } from "../../../../lib/translator";
import ListingPage from "../../../../containers/ListingPage";
import { Cell, Row } from "../../../../components/Table";
import Image from "../../../../components/Image";
import { Link } from "react-router-dom";
import find from "lodash.find";
import "./style.css";
import ProductFiltersForm from "./Form";
import { isExtensionEnabled } from "../../../../lib/auth";
import { isSellerSection } from "..";

const tableProperties = () => {
  return {
    row: (props) => {
      const { id, images, brand, name, variants, productExist } = props;
      let image = images && images.length && images[0];
      if (!image && variants && variants.length) {
        let varImage = variants.filter((variant) => variant.images)[0];
        if (varImage && varImage.images) {
          image = varImage.images[0];
        }
      }
      let urlParams = new URL(document.location).searchParams;
      let sellerId = parseInt(urlParams.get("sellerId"));
      const isOwnerCreatingRequest = Boolean(sellerId);
      const link = !isOwnerCreatingRequest
        ? `/catalogue/products/add-product/${id}`
        : `/catalogue/seller-catalogue/add-product/${id}?sellerId=${sellerId}`;

      return (
        <Row>
          <Cell>
            <Image src={image} size="sm" bordered />
          </Cell>
          <Cell>
            <div className="product-name">
              {brand ? <span className="brand-name">{brand.name} </span> : null}
              {name}
            </div>
          </Cell>

          <Cell className="product-actions">
            {productExist ? (
              <small className="tick"></small>
            ) : (
              <Link to={link}>
                <small className="add">{getMessage("add.label.text")}</small>
              </Link>
            )}
          </Cell>
        </Row>
      );
    },
  };
};
export default class SearchAndAdd extends Component {
  getAPIUrl = () => {
    let urlParams = new URL(document.location).searchParams;
    let sellerId = parseInt(urlParams.get("sellerId"));
    if (isExtensionEnabled("MarketPlace") && (isSellerSection() || sellerId)) {
      return "/catalogue-service/permissible-seller";
    }
    return "/catalogue-service/product";
  };

  updateProductsList = (products, permissibleSellers) => {
    const productsIdResponse = permissibleSellers;
    products.forEach((product) => {
      if (
        productsIdResponse.some((productIdObj) => {
          return (
            String(productIdObj.productId) === String(product.id) &&
            productIdObj.sellerProductRequestIds
          );
        })
      ) {
        product.productExist = true;
      } else {
        product.productExist = false;
      }
    });
    return products;
  };
  render() {
    const apiUrl = this.getAPIUrl();
    let urlParams = new URL(document.location).searchParams;
    let sellerId = parseInt(urlParams.get("sellerId"));
    return (
      <ListingPage
        helmetTitle="Zopping - Search and Add"
        menu={this.props.menu}
        className="product-search-add-page"
        title={getMessage("product.search.and.add.heading")}
        api={{
          url: apiUrl,
          params: {
            sellerID: sellerId,
          },
          transform: (response) => {
            let products = response.data.product || [];
            let permissibleSellers = response.data.permissibleSellers || [];
            if (Array.isArray(products)) {
              products.forEach((item) => {
                if (!item.images && item.hasVariants) {
                  const variantWithImages = find(
                    item.variants,
                    (v) => v.images !== null
                  );
                  item.images = variantWithImages && variantWithImages.images;
                }
              });
              products = this.updateProductsList(products, permissibleSellers);
              products.sort((x, y) => {
                return x.productExist === y.productExist
                  ? 0
                  : !x.productExist
                  ? -1
                  : 1;
              });
            }
            return products;
          },
        }}
        filters={{
          component: ProductFiltersForm,
          transformSubmit: (formData) => {
            return formData;
          },
        }}
        tableProperties={tableProperties()}
      />
    );
  }
}
