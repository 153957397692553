import React from "react";
import { BaseForm, Select, Input, Radio } from "../../../components/Form";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import Loader from "../../../components/Loader";
import API from "../../../lib/api";
import { getMessage } from "../../../lib/translator";
import toTitleCase from "../../../utils/toTitleCase";
import { Dialog } from "../../../components/Popup";

import "./style.css";

const autoTripGenerationInputs = [
  "fixedPickingTime",
  "perItemPickingTime",
  "fixedPackingTime",
  "perItemPackingTime",
  "fixedLoadingTime",
  "perItemLoadingTime",
  "perContainerLoadingTime",
  "averageServiceTime",
  "averageTravelTime",
  "averageSpeedInKms",
];

class LogisticsConfig extends BaseForm {
  constructor(props) {
    super(props);
    this.closeDialogs = this.closeDialogs.bind(this);
  }
  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
    });
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
    const api = new API({ url: "/account-service/config/logistics" });
    const configServiceApi = new API({
      url: "/config-service/config/logistics",
    });
    const configServiceOrderApi = new API({
      url: "/config-service/config/order",
    });

    Promise.all([
      api.get(),
      configServiceApi.get(),
      configServiceOrderApi.get(),
    ])
      .then(([response, configServiceResponse, configServiceOrderResponse]) => {
        let config = response.data.config.logistics;
        const {
          fixedPickingTime,
          perItemPickingTime,
          fixedPackingTime,
          perItemPackingTime,
          fixedLoadingTime,
          perItemLoadingTime,
          perContainerLoadingTime,
          averageServiceTime,
          averageTravelTime,
          averageSpeedInKms,
        } = configServiceResponse.data.logistics;
        config.tripGeneration =
          configServiceResponse.data.logistics.tripGeneration;
        config.orderBreakingMethod =
          configServiceResponse.data.logistics.orderBreakingMethod;
        config.fixedPickingTime = fixedPickingTime;
        config.perItemPickingTime = perItemPickingTime;
        config.fixedPackingTime = fixedPackingTime;
        config.perItemPackingTime = perItemPackingTime;
        config.fixedLoadingTime = fixedLoadingTime;
        config.perItemLoadingTime = perItemLoadingTime;
        config.perContainerLoadingTime = perContainerLoadingTime;
        config.averageServiceTime = averageServiceTime;
        config.averageTravelTime = averageTravelTime;
        config.averageSpeedInKms = averageSpeedInKms;
        config.otpValidationRequiredForOrderCompletion =
          configServiceOrderResponse.data.order?.otpValidationRequiredForOrderCompletion;
        this.setState({
          loading: false,
          values: config,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(formData) {
    this.setState({
      submitting: true,
    });
    let data = Object.assign({}, this.state.values);
    if (!data.canLeaveAtDoorstep) {
      data.canLeaveAtDoorstep = "false";
    }
    if (!data.shouldCaptureOdometer) {
      data.shouldCaptureOdometer = "false";
    }
    const configServicePayloadKeys = ["tripGeneration", "orderBreakingMethod"];
    const orderServicePayloadKeys = ["otpValidationRequiredForOrderCompletion"];
    if (data.tripGeneration === "AUTOMATED") {
      configServicePayloadKeys.push(...autoTripGenerationInputs);
    }
    const configServicePayload = {};
    configServicePayloadKeys.forEach((key) => {
      configServicePayload[key] = data[key];
      delete data[key];
    });
    const configServiceOrderPayload = {};
    orderServicePayloadKeys.forEach((key) => {
      if (key === "otpValidationRequiredForOrderCompletion") {
        configServiceOrderPayload[key] = data[key] === "true" ? true : false;
      }
      delete data[key];
    });

    const accountServiceApi = new API({
      url: "/account-service/config/logistics",
    });
    const configServiceApi = new API({ url: "/config-service/config" });

    Promise.all([
      accountServiceApi.put({ logistics: data }),
      configServiceApi.post({
        logistics: configServicePayload,
        order: configServiceOrderPayload,
      }),
    ]).then(
      (response) => {
        this.setState({
          showSuccessDialog: true,
          submitting: false,
        });
      },
      (error) => {
        this.setState({
          formError: error.message,
          submitting: false,
        });
      }
    );
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const { tripGeneration } = this.state.values;
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <h1>{getMessage("logistics.config.heading")}</h1>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        {this.state.loading ? (
          <Loader />
        ) : (
          <Form className="instore-config-form logistics-config-form">
            <Dialog
              show={this.state.showSuccessDialog}
              className="success"
              title={getMessage("extensions.seo.success")}
              information={getMessage("extensions.seo.save.successful")}
              close={this.closeDialogs}
              closeText={getMessage("extensions.seo.ok")}
            />
            <Select
              label={getMessage("logistics.config.reseesOrders")}
              name="REseesOrders"
              placeholder={getMessage("logistics.config.resees.placeholder")}
              {...this.generateStateMappers({
                stateKeys: ["RESeesOrders"],
                loseEmphasisOnFill: true,
              })}
              options={[
                {
                  text: getMessage("logistics.config.oneByone"),
                  value: "ONE_BY_ONE",
                },
                {
                  text: getMessage("logistics.config.allOrders"),
                  value: "ALL_ORDERS",
                },
              ]}
            />
            <Radio
              className="logistics-radio"
              label={getMessage("logistics.config.leaveAtDoorstep")}
              name="canLeaveAtDoorstep"
              {...this.generateStateMappers({
                stateKeys: ["canLeaveAtDoorstep"],
                loseEmphasisOnFill: true,
                defaultValue: "false",
              })}
              options={[
                {
                  text: getMessage("logistics.config.yes"),
                  value: true,
                },
                {
                  text: getMessage("logistics.config.no"),
                  value: "false",
                },
              ]}
            />
            <Radio
              className="logistics-radio"
              name="shouldCaptureOdometer"
              label={getMessage("logistics.config.shouldCaptureOdometer")}
              {...this.generateStateMappers({
                stateKeys: ["shouldCaptureOdometer"],
                loseEmphasisOnFill: true,
                defaultValue: "false",
              })}
              options={[
                {
                  text: getMessage("logistics.config.yes"),
                  value: true,
                },
                {
                  text: getMessage("logistics.config.no"),
                  value: "false",
                },
              ]}
            />
            <Select
              label={getMessage("logistics.config.tripGeneration")}
              name="tripGeneration"
              placeholder={getMessage(
                "logistics.config.tripGeneration.placeholder"
              )}
              {...this.generateStateMappers({
                stateKeys: ["tripGeneration"],
                loseEmphasisOnFill: true,
              })}
              options={[
                {
                  text: getMessage("logistics.config.tripGeneration.automated"),
                  value: "AUTOMATED",
                },
                {
                  text: getMessage("logistics.config.tripGeneration.basic"),
                  value: "BASIC",
                },
              ]}
            />
            {tripGeneration === "AUTOMATED" &&
              Object.keys(this.state.values).map(
                (autoTripGenerationInput) =>
                  autoTripGenerationInputs.includes(
                    autoTripGenerationInput
                  ) && (
                    <Input
                      name={autoTripGenerationInput}
                      label={toTitleCase(
                        autoTripGenerationInput.split(/(?=[A-Z])/).join(" ")
                      )}
                      placeholder={`Enter ${toTitleCase(
                        autoTripGenerationInput.split(/(?=[A-Z])/).join(" ")
                      )}`}
                      type="number"
                      key={autoTripGenerationInput}
                      value={this.state.values[autoTripGenerationInput]}
                      step={
                        autoTripGenerationInput === "averageSpeedInKms"
                          ? "0.01"
                          : null
                      }
                      required={tripGeneration === "AUTOMATED"}
                      {...this.generateStateMappers({
                        stateKeys: [autoTripGenerationInput],
                        loseEmphasisOnFill: true,
                      })}
                    />
                  )
              )}
            <Select
              label={getMessage("logistics.config.orderBreakingMethod")}
              name="orderBreakingMethod"
              placeholder={getMessage(
                "logistics.config.orderBreakingMethod.placeholder"
              )}
              {...this.generateStateMappers({
                stateKeys: ["orderBreakingMethod"],
                loseEmphasisOnFill: true,
              })}
              options={[
                {
                  text: getMessage(
                    "logistics.config.orderBreakingMethod.clustering"
                  ),
                  value: "CLUSTERING",
                },
                {
                  text: getMessage(
                    "logistics.config.orderBreakingMethod.zoning"
                  ),
                  value: "ZONING",
                },
              ]}
            />
            <Radio
              className="logistics-radio"
              label={getMessage("logistics.config.orderOtpValidation")}
              name="orderOtpValidation"
              {...this.generateStateMappers({
                stateKeys: ["otpValidationRequiredForOrderCompletion"],
                loseEmphasisOnFill: true,
                defaultValue: "false",
              })}
              options={[
                {
                  text: getMessage("logistics.config.yes"),
                  value: true,
                },
                {
                  text: getMessage("logistics.config.no"),
                  value: false,
                },
              ]}
            />
            <div className="form-action">
              <SubmitButton>{getMessage("logistics.save")}</SubmitButton>
              <button
                type="button"
                className="button"
                onClick={this.props.history.goBack}
              >
                {getMessage("logistics.cancel")}
              </button>
            </div>
          </Form>
        )}
      </AuthenticatedPage>
    );
  }
}

export default LogisticsConfig;
