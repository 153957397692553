import React from "react";
import {
  Input,
  BaseForm,
  Select,
  Radio,
  SingleDatePicker,
  ProductSearch,
  CategorySearch,
  BrandSearch,
  Searchable,
} from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";
import { isExtensionEnabled } from "../../../../lib/auth";

class OfferFilters extends BaseForm {
  constructor(props) {
    super(props);
    this.entityOptions = [
      {
        text: getMessage("offer.all"),
        value: "",
      },
      {
        text: getMessage("offer.product"),
        value: "PRODUCT",
      },
      {
        text: getMessage("offer.category"),
        value: "CATEGORY",
      },
    ];
    if (isExtensionEnabled("MultiBrandSupport")) {
      this.entityOptions.push({
        text: getMessage("offer.brand"),
        value: "BRAND",
      });
    }
    this.offerMapping = this.props.options.offerMapping.slice();
    this.offerMapping &&
      this.offerMapping.splice(0, 0, { text: "ALL", value: "all" });
    this.offerMapping = this.offerMapping.filter(
      (offer) => offer.value !== "SF"
    );
    this.handleEntityTypeChange = this.handleEntityTypeChange.bind(this);
    this.handleEntityIdChange = this.handleEntityIdChange.bind(this);
  }

  handleEntityIdChange(val) {
    let entityId = val && val.id;
    let values = JSON.parse(JSON.stringify(this.state.values));
    values["entityId"] = entityId;
    values["selectedEntity"] = val;
    this.setState({
      values,
    });
  }
  handleEntityTypeChange(val) {
    const values = JSON.parse(JSON.stringify(this.state.values));
    values["entityType"] = val;
    values["entityId"] = null;
    values["selectedEntity"] = null;
    this.setState({
      values,
    });
  }
  render() {
    const { Form } = this.components;
    const { SubmitButton, ClearButton } = this.buttons;
    let { entityType } = this.state.values;
    let entityIdField = null;
    if (entityType === "PRODUCT") {
      entityIdField = (
        <ProductSearch
          required
          label={getMessage(`offer.filter.product.label`)}
          placeholder={getMessage(`offer.product.placeholder`)}
          name={`item-searchable-pdt`}
          key={`item-searchable-pdt`}
          className="product-searchable"
          {...this.generateStateMappers({
            stateKeys: ["selectedEntity"],
          })}
          onChange={this.handleEntityIdChange}
        />
      );
    } else if (entityType === "CATEGORY") {
      entityIdField = (
        <CategorySearch
          label={getMessage("offer.filter.category.label")}
          placeholder={getMessage("offer.filter.searchbycategory.placeholder")}
          name="secondary-categories"
          {...this.generateStateMappers({
            stateKeys: ["selectedEntity"],
            loseEmphasisOnFill: true,
          })}
          onChange={this.handleEntityIdChange}
        />
      );
    } else if (entityType === "BRAND") {
      entityIdField = (
        <BrandSearch
          label={getMessage("offer.filter.brand.label")}
          placeholder={getMessage("offer.filter.searchbybrand.placeholder")}
          name="brand"
          {...this.generateStateMappers({
            stateKeys: ["selectedEntity"],
          })}
          onChange={this.handleEntityIdChange}
        />
      );
    } else if (entityType === "TAG") {
      entityIdField = (
        <Searchable
          label={getMessage("offer.filter.tag.label")}
          placeholder={getMessage("offer.filter.searchbytag.placeholder")}
          name="tag"
          className="tag-search"
          searchUrl="/catalogue-service/tag"
          valueKey="id"
          responseKey="tag"
          nameKey="name"
          searchKey="name"
          transformResponse={(response) => {
            let tags = response.data.tag || [];
            tags = (tags || []).map((tag) => {
              tag.name = `${tag.name}`;
              tag.id = `${tag.id}`;
              return tag;
            });
            return tags;
          }}
          {...this.generateStateMappers({
            stateKeys: ["selectedEntity"],
            loseEmphasisOnFill: true,
          })}
          onChange={this.handleEntityIdChange}
        />
      );
    }

    return (
      <Form className="offer-filters">
        <div className="form-fields">
          <Select
            name="offer-type"
            label={getMessage("offer.type")}
            placeholder={getMessage("offer.type.placeholder")}
            options={this.offerMapping}
            {...this.generateStateMappers({
              stateKeys: ["type"],
              loseEmphasisOnFill: true,
            })}
          />
          <Radio
            name="status"
            label={getMessage("offer.status")}
            options={[
              {
                text: getMessage("offer.all"),
                value: "",
              },
              {
                text: getMessage("offer.enabled"),
                value: "ENABLED",
              },
              {
                text: getMessage("offer.disabled"),
                value: "DISABLED",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              loseEmphasisOnFill: true,
            })}
          />
          <Radio
            name="entity-type"
            key={"entity-type"}
            label={getMessage("offer.entity")}
            options={[
              ...this.props.options.entityIds,
              { text: "Tag", value: "TAG" },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["entityType"],
              loseEmphasisOnFill: true,
            })}
            onChange={this.handleEntityTypeChange}
          />
          {entityIdField}
          <SingleDatePicker
            label={getMessage("offer.validFrom")}
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ["offerValidFrom"],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            label={getMessage("offer.validTill")}
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ["offerValidTill"],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage("offer.filter.clientId")}
            placeholder={getMessage("offer.filter.clientId.placeholder")}
            name="clientId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["clientId"],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage("coupon.filters.couponCode.heading")}
            placeholder={getMessage("coupon.filters.couponCode.placeholder")}
            name="promoCodes"
            className="offers-couponcode-filter"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["promoCodes"],
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
        </div>
        <SubmitButton>{getMessage("offer.submit")}</SubmitButton>
        <ClearButton>{getMessage("offer.clear")}</ClearButton>
      </Form>
    );
  }
}

export default OfferFilters;
