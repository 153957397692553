import React, { Component } from "react";
import MetaDataFormFields from "../../../../../components/MetaDataFormFields";
import API from "../../../../../lib/api";
import {
  getEntityMetaData,
  saveEntityMetaData,
  isExtensionEnabled,
} from "../../../../../lib/auth";

import "./style.css";

class OrderMetaData extends Component {
  constructor(props) {
    super(props);
    this.metaDataWithType = {};
    this.getOrderMetaData = this.getOrderMetaData.bind(this);
  }

  setValuesToEmpty(obj) {
    let object = Object.assign({}, obj);
    obj && Object.keys(obj).forEach((key, index) => (object[key] = ""));
    return object;
  }

  getOrderMetaData() {
    let metaData = getEntityMetaData().order;
    const filteredMetaData = {};
    for (let key in metaData) {
      filteredMetaData[key] = metaData[key];
    }
    this.props.initializeMetaData(this.setValuesToEmpty(filteredMetaData));
    this.metaDataWithType = Object.assign({}, filteredMetaData);
    this.metaData = Object.assign({}, this.props.metaData);
    if (!this.metaData) {
      Object.entries(metaData).forEach(([key, type]) => {
        metaData[key] = "";
      });
      this.metaData = metaData;
    }
    this.setState({
      values: metaData,
    });
  }

  componentDidMount() {
    let orderKeys = getEntityMetaData() && getEntityMetaData().order;
    if (
      (!orderKeys ||
        (Array.isArray(orderKeys) && !orderKeys.length) ||
        (orderKeys.constructor === Object && !Object.keys(orderKeys).length)) &&
      isExtensionEnabled("EntityMetaData")
    ) {
      const api = new API({ url: `/config-service/meta-data` });
      api
        .get()
        .then((response) => {
          let details = response.data.config.entityMetaData;
          saveEntityMetaData(details);
          this.getOrderMetaData();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      if (isExtensionEnabled("EntityMetaData")) {
        this.getOrderMetaData();
      }
    }
  }

  render() {
    const { metaData, onChange } = this.props;
    return (
      Object.keys(this.metaDataWithType).length > 0 && (
        <React.Fragment>
          <MetaDataFormFields
            className="order-meta-data"
            metaDataWithType={this.metaDataWithType}
            page="create-order-form"
            metaData={metaData}
            onChange={onChange}
            _this={this}
          />
        </React.Fragment>
      )
    );
  }
}

export default OrderMetaData;
