const data = {
  "googleMaps.zeroResults.error": "Address not found",
  "googleMaps.addressMap.error":
    "Google Map API failed on Address Map with status",
  "store.add.email.description":
    "Necessary for shipping your orders through 3rd party courier services",
  "order.filters.referenceNumber.heading": "Order number",
  "order.filters.referenceNumber.placeholder": "Enter an order number here",
  "order.filters.status.heading": "Status",
  "order.filters.status.placeholder": "Choose order status",
  "order.filters.submitText": "Search",
  "order.filters.clearFiltersText": "Clear All",
  "order.filters.preorder": "Preorder",
  "order.emptyList.message":
    "Your orders will be shown here. Start selling now.",
  "order.click.collect.empty":
    "This store does not support click and collect orders",
  "order.helpItems.title": "How to get orders?",
  "order.helpItems.seo.title": "Search Engine Optimization",
  "order.helpItems.seo.description":
    "You will be able to target your customers using email and SMS after the import, which will potentially increase your revenue.",
  "order.helpItems.rcm.title": "Repeat Customer Marketing",
  "order.helpItems.rcm.description":
    "You can create a discount coupon for your customers and promote the online purchase.",
  "order.helpItems.nca.title": "New Customer Acquisition",
  "order.helpItems.nca.description":
    "You can put up a banner at a prominent place in your physical store. Include the promotional offer to create buzz.",
  "order.table.header.referenceNumber": "Order no.",
  "order.table.referenceNumber.prefix": "Z",
  "order.table.clientId.prefix": "#",
  "order.details.invoice.order": "Invoice ",
  "order.table.header.customer": "Customer",
  "order.table.header.placedTime": "Placed time",
  "order.table.header.expectedTime": "Order Slot",
  "order.table.header.amount": "Amount",
  "order.table.header.status": "Status",
  "order.table.text.fillrate": "Fill Rate: ",
  "order.table.header.completionTime": "Completion time",
  "order.table.header.courierAndCharge": "Courier & Charge",
  "order.action.dispatch": "Dispatch",
  "order.courier.select.heading": "Dispatch by",
  "order.courier.dispatch.success": "Order dispatch request sent successfully!",
  "order.courier.dispatch.table.header.name": "Courier Name",
  "order.courier.dispatch.no.vendor.found": "No courier vendor found",
  "order.courier.dispatch.table.header.pickup.estimate": "Pick Up Estimate",
  "order.courier.dispatch.table.header.delivery.estimate": "Delivery Estimate",
  "order.dispatch.label.courier.name": "COURIER NAME",
  "order.dispatch.label.courier.charges": "Charges",
  "order.dispatch.label.courier.tracking.id": "Tracking Id",
  "order.dispatch.placeholder.courier.name": "Ex: India Post",
  "order.dispatch.placeholder.courier.charges": "₹10",
  "order.dispatch.placeholder.courier.tracking.id": "AWB87987FRS",
  "order.table.courierCharge": "Courier Charges",
  "okay.text": "Okay",
  "order.vendor.shiprocket": "Shiprocket",
  "order.vendor.dunzo": "Dunzo",
  "order.vendor.other": "Other",
  "order.vendor.self": "Self",
  "order.table.header.actions": "Actions",
  "order.table.itemCount": "{count} Items",
  "order.table.pendingAmount": "{currency}{amount} Pending",
  "order.table.refundAmount": "{currency}{amount} Refund",
  "order.gridItem.status.title": "Order status",
  "order.status.completed": "Completed",
  "order.status.cancelled": "Cancelled",
  "order.status.pending": "Pending",
  "order.status.picked": "Picked",
  "order.status.checked": "Checked",
  "order.status.packed": "Packed",
  "order.action.viewDetails": "View Details",
  "order.action.complete": "Complete Order",
  "order.action.packed": "Pack Order",
  "order.action.cancel": "Cancel Order",
  "order.action.viewInvoice": "View Invoice",
  "order.action.printInvoice": "Print Invoice",
  "order.action.viewPayments": "Payment Details",
  "order.action.return": "Return Order",
  "order.status.picking": "Picking",
  "order.status.partiallypicked": "Partially picked",
  "order.status.checking": "Checking",
  "order.status.packing": "Packing",
  "order.status.dispatched": "Dispatched",
  "order.status.returned": "Returned",
  "order.details.packing.heading": "Packing Details",
  "order.packing.type": "Type",
  "order.packing.labels": "Labels",
  "order.packing.smallBags": "Small Bags",
  "order.packing.largeBags": "Large Bags",
  "order.packing.frozenBoxes": "Frozen Boxes",
  "order.packing.crates": "Crates",
  "order.packing.smallBoxes": "Small Boxes",
  "order.packing.mediumBoxes": "Medium Boxes",
  "order.packing.largeBoxes": "Large Boxes",
  "order.details.popup.heading": "Complete Order",
  "order.details.packing.title": "Your order contains the following packages.",
  "order.details.packing.type": "Package Type: ",
  PENDING: "Pending",
  PICKING: "Picking",
  CHECKING: "Checking",
  PICKED: "Picked",
  CHECKED: "Checked",
  PACKED: "Packed",
  DISPATCHED: "Dispatched",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  RETURNED: "Returned",

  "order.details.heading": "Order",
  "order.details.invoice.buttonText": "View invoice",
  "order.details.invoice.indicator": "New",
  "order.details.invoice.printButtonText": "Print invoice",
  "order.details.cancel.optionText": "Cancel order",
  "order.details.complete.optionText": "Complete order",
  "order.details.original.optionText": "Original Invoice",
  "order.details.return.optionText": "Return Invoice",
  "order.details.returm.optionText": "Return order",
  "order.details.dispute.optionText": "File Dispute",
  "order.details.cancellation.title": "Are you sure?",
  "order.details.cancellation.message": "This action will cancel this order",
  "order.details.cancellation.cancelText": "Cancel",
  "order.details.cancellation.confirmText": "Confirm",
  "order.details.packed.title": "Are you sure?",
  "order.details.completion.title": "Are you sure?",
  "order.details.packed.message":
    "This action will move the order to packed status",
  "order.details.packed.item.return.message":
    "There are returned items in the order",
  "order.details.completion.message": "This action will complete this order",
  "order.details.completion.item.return.message":
    "There are returned items in the order",
  "order.details.return.title": "Are you sure?",
  "order.details.return.message": "This action will return the order",
  "order.details.pending.title": "Are you sure?",
  "order.details.pending.message":
    "This action will move the order to PENDING status",
  "order.action.moveToPending": "Move to pending",
  "order.action.editPackedOrder": "Edit Packed Order",
  "order.details.packed.cancelText": "Cancel",
  "order.details.packed.confirmText": "Confirm",
  "order.details.completion.cancelText": "Cancel",
  "order.details.completion.confirmText": "Confirm",
  "order.details.completion.confirmReturnItem": "confirm items",
  "order.details.device.desktop": "Desktop",
  "order.details.device.ios": "iOS",
  "order.details.device.android": "Android",
  "order.details.productSearch.placeholder": "Search a product to add",
  "order.details.removeItem.title": "Remove this item?",
  "order.details.removeItem.message":
    "Press confirm to remove this item from the order",
  "order.details.removeItem.cancelText": "Cancel",
  "order.details.removeItem.confirmText": "Confirm",
  "order.details.itemsTable.title": "Items in order",
  "order.details.itemsTable.header.image": "Image",
  "order.details.itemsTable.header.name": "Name",
  "order.details.itemsTable.header.quantity": "Original Quantity",
  "order.details.itemsTable.header.picked": "Final Quantity",
  "order.details.itemsTable.header.quantity.abbreviated": "Qty",
  "order.details.itemsTable.header.mrp": "Price",
  "order.details.itemsTable.header.tax": "Tax",
  "order.details.itemsTable.header.discount": "Discount",
  "order.details.itemsTable.header.amount": "Amount",
  "order.details.itemsTable.header.clientItemId": "Client Item Id",
  "order.details.itemsTable.clearingMessage":
    "All items are removed. Order will be cancelled.",
  "order.details.itemsTable.emptyMessage": "There are no items in this order.",
  "order.details.itemsTable.actions.confirmText": "Done",
  "order.details.itemsTable.actions.cancelText": "Cancel",
  "order.details.itemsTable.itemsInOrder.unavailabelText": "Item unavailable",
  "order.details.errors.onSave": "An error occurred. Click here to retry ⟳",
  "order.details.errors.onLoad":
    "Something went wrong. Click here to try again. ⟳",
  "order.details.edit-packed.heading": "Packed Order Editing",
  "order.details.packages.multi-text.label": "Packages",
  "order.details.packages.multi-text.placeholder": "Enter package label",
  "order.details.edit-packed.error": "Something went wrong",
  "order.details.edit-packed.error.closeDialog": "close",
  "order.details.edit-packed.error.dialog.label": "Error",
  "order.details.sample": "SAMPLE",
  "order.reg.payments.sample.heading": "Sample Applicable",

  "order.details.summary.taxTitle": "Tax summary",
  "order.details.summary.tax": "Tax",
  "order.details.summary.price": "Base Price",
  "order.details.summary.taxAmount": "Tax Amount",
  "order.details.summary.netAmount": "Net Amount",
  "order.details.summary.totalTax": "Total Tax",

  "order.details.summary.pendingAmount": "Pending amount",
  "order.details.summary.refundAmount": "Refund amount",
  "order.details.summary.totalAmount": "Total amount",
  "order.details.summary.orderAmount": "Order amount",
  "order.details.summary.coupondiscount": "Coupon discount",
  "order.details.summary.shippingCharge": "Shipping charge",
  "order.details.summary.shippingDiscount": "Shipping discount",
  "order.details.summary.collectionCharge": "Collection charge",
  "order.details.summary.collectionDiscount": "Collection discount",
  "order.details.summary.discount": "Discount",
  "order.details.summary.slotCharge": "Slot charge",
  "order.details.summary.slotDiscount": "Slot Discount",
  "order.details.location.delivery.pending.title": "Delivering to",
  "order.details.location.delivery.completed.title": "Delivered to",
  "order.details.location.billing.address.title": "Billing Address",
  "order.details.location.pickup.pending.title": "Pickup Details",
  "order.details.location.pickup.completed.title": "Pickup Details",
  "order.details.location.b2b.pending.title": "B2B Details",
  "order.details.location.b2b.completed.title": "B2B Details",
  "order.details.location.offline.pending.title": "Offline Details",
  "order.details.location.offline.completed.title": "Offline Details",
  "order.details.location.option.delivery": "Delivery",
  "order.details.location.option.pickup": "Pickup",
  "order.details.location.actions.confirmText": "Done",
  "order.details.location.actions.cancelText": "Cancel",
  "order.details.invoice.title": "Invoice",
  "order.details.invoice.taxDisclaimer": "* All prices are tax inclusive",
  "order.details.invoice.referenceNumber": "Order #{referenceNumber}",
  "order.details.payment.heading": "Payment Details",
  "order.details.payment.amount": "Amount",
  "order.details.payment.mode": "Mode",
  "order.details.payment.card": "Card",
  "order.details.payment.status": "Status",
  "order.details.payment.details": "Details",
  "order.details.payment.transactionId": "Transaction Id",
  "order.details.payment.ref": "Refund reference",
  "order.details.preferredDate": "Preferred date",
  "order.details.today": "Today",
  "order.details.tomorrow": "Tomorrow",
  "order.details.prefferdSlot": "Preferred Slot",
  "order.details.slot.heading": "Order Slot",
  "order.details.pickup.address": "Pickup Address: ",
  "order.details.preorder": "Pre Ordered",
  "order.register.payments": "Register Payments",
  "order.heading": "Order",
  "order.paymentmode.COD": "COD",
  "order.paymentmode.LOYALTY": "Loyalty",
  "order.paymentmode.MSWIPE": "Mswipe",
  "order.paymentmode.ONLINE": "Online",
  "order.paymentmode.PENDING": "Pending",
  "order.paymentmode.PREPAID": "Prepaid",
  "order.register.payments.for": "Register payments for",
  "order.payment.paymentmode": "Payment Method",
  "order.payment.pendingamount": "Pending Amount",
  "order.payment.amount": "Amount",
  "order.payment.transactionid": "Transaction Id",
  "order.payment.submit": "Submit",
  "use.current.location": "Use current location",
  "order.add.text": "Create new order",
  "order.form.add.heading": "Place New Order",
  "order.form.customer.phone.heading": "Customer Phone Number",
  "order.form.productSearch.heading": "Search Product/Scan Barcode",
  "order.form.productSearch.placeholder": "Search Product/Scan Barcode",
  "order.form.paymentMode.heading": "Payment Option",
  "order.form.paymentMode.online": "Online",
  "order.form.paymentMode.cash": "Cash",
  "order.form.customer.phone.placeholder": "Enter Phone Number",
  "order.form.submit.text": "Place Order",
  "order.form.cancel.text": "Cancel",
  "order.form.customer.name.heading": "Customer Name",
  "order.form.customer.name.placeholder": "Enter Customer Name",
  "order.form.customer.email.heading": "Customer Email Address",
  "order.form.customer.email.placeholder": "Enter Customer Email",
  "order.form.save.error.closeText": "Ok",
  "order.form.search.text": "Search",
  "order.form.customerDetails.heading": "Enter Customer Details",
  "order.form.addProduct.heading": "Add Products",
  "order.form.orderType.heading": "Order Type",
  "order.form.orderType.pickup": "PICKUP",
  "order.form.paymentMode.delivery": "DELIVERY",
  "order.form.selectOrderType.heading": "Select Order Type",
  "order.form.pickupLocation.heading": "Pickup location",
  "order.form.customer.address.heading": "Address",
  "order.form.customer.landmark.heading": "Landmark",
  "order.form.customer.city.heading": "City",
  "order.form.customer.state.heading": "State",
  "order.form.customer.state.enterState": "Enter state",
  "order.form.customer.pincode.heading": "Pincode",
  "order.form.customer.address.placeholder": "Enter address",
  "order.form.customer.landmark.placeholder": "Enter landmark",
  "order.form.customer.pincode.placeholder": "Enter pincode",
  "order.form.customer.city.placeholder": "Enter city",
  "order.form.save.success.title": "Order placed successfully!",
  "order.form.save.success.closeText": "Ok",
  "order.form.cash.tendered": "Cash tendered",
  "order.form.balanceAmount": "Balance",
  "order.form.addAddress.heading": "Add Address",
  "order.form.deliveryArea.heading": "Select Delivery Address",
  "order.metaData.heading": "Order Custom Fields",
  "order.metaData.enter": "Enter",
  "order.form.date.heading": "Select Date",
  "order.form.slot.heading": "Select Slot",
  "order.form.slots.today": "Today",
  "order.form.slots.tomorrow": "Tomorrow",
  "order.form.slots.error.message": "Select a slot",
  "order.form.addressnotserved":
    "Address not served. Please select another address or store",
  "order.payment.cod": "COD",
  "order.payment.online": "Online",
  "order.payment.pending": "Pending",
  "order.payment.paid": "Paid",
  "order.payment.refund": "Refund",
  "order.type.picking": "Picking",
  "order.type.pickup": "Pickup",
  "order.type.delivery": "Delivery",
  "order.filters.payment.heading": "Payment Mode",
  "order.filters.payment.placeholder": "Select payment mode",
  "order.filters.paymentStatus": "Payment Status",
  "order.filters.paymentStatus.placeholder": "Select payment status",
  "order.filters.orderType": "Order Type",
  "order.filters.orderType.placeholder": "Select order type",
  "order.filters.customer": "Customer name",
  "order.filters.customer.placholder": "Enter customer name",
  "order.filters.clientId.heading": "Client Id",
  "order.filters.clientId.placeholder": "Enter client id",
  "order.filters.slot": "Order Slot",
  "order.filters.slot.placeholder": "Select order slot",
  "order.select": "Select",
  "order.filters.placedOn": "Placed On",
  "order.filter.placedOn.placeholder": "Date",
  "order.filters.preferredDate": "Preferred Date",
  "order.filters.slot.fetchTitle": "Wait! Fetching slots",
  "product.form.additional.details.heading": "Additional details",

  "order.log.itemName": "Item Name",
  "order.log.oldQuantity": "Old Quantity",
  "order.log.newQuantity": "New Quantity",
  "order.log.orderItemId": "Order Item ID",
  "order.action.order.logs.for": "Order logs for",
  "order.actions.logs": "View logs",
  "order.actions.trips": "View trips",
  "order.logs.emptyMeassage": "No order logs for this order",
  "order.log.mode": "Mode",
  "order.log.amount": "Amount",
  "order.log.status": "Status",
  "order.log.user": "by",
  "order.logs.heading": "Order Log ",
  "order.logs.status": "Status",
  "order.logs.orderRef": "Ref.",
  "order.log.tripId": "Trip Id ",
  "order.cancel": "Cancel",
  "order.submit": "Submit",
  "order.enter": "Enter",
  CALL_TO_SUBSTITUTE: "Call for Substitute",
  SUBSTITUTION_DISABLED: "No call No substitution",
  SUBSTITUTION_ENABLED: "Replace with equivalent",
  "order.log.reason": "Reason",
  "order.log.placedfrom": "Placed from",

  "order.log.payment.changed": "Payment Changed",
  "order.log.from": "From",
  "order.log.to": "To",
  "order.log.address.changed": "Address Changed",
  "order.log.shipping.charges": "Shipping Charges",
  "order.log.type.changed": "Type Changed",
  "order.log.amount.changed": "Amount Changed",
  "order.log.date.changed": "Date Changed",
  "order.log.status.changed": "Status Changed",
  "order.log.slot.changed": "Slot Changed",
  "order.log.return": "Order return",
  "order.log.transactionId": "Transaction Id",
  "order.log.payment.initiated": "Payment initiated",
  "order.log.payment.cancelled": "Payment cancelled",
  "order.log.payment.completed": "Payment completed",
  "order.log.refund.initiated": "Refund initiated",

  "customer.heading": "Customers",
  "customer.helpItems.message":
    "You will see your customers’ details here. Get customers now.",
  "customer.helpItems.title": "How to get Customers?",
  "customer.helpItems.iec.title": "Import your existing customers",
  "customer.helpItems.iec.description":
    "You will be able to target your customers using email and SMS after the import, which will potentially increase your revenue.",
  "customer.helpItems.rpo.title": "Run a promotional offer",
  "customer.helpItems.rpo.description":
    "You can create a discount coupon for your customers and promote the online purchase.",
  "customer.helpItems.om.title": "Offline Marketing",
  "customer.helpItems.om.description":
    "You can put up a banner at a prominent place in your physical store. Include the promotional offer to create buzz.",
  "customer.import.text": "Import Customers",
  "customer.modal.import.text": "Import Customers",
  "customer.import.successText":
    "CSV File Uploaded Successfully! Customers will be added and appear after 15 minutes",
  "customer.tags.import.successText":
    "CSV File Uploaded Successfully! Tags will be added and appear after 15 minutes",
  "customer.table.header.image": "Image",
  "customer.table.header.name": "Name",
  "customer.table.header.contact": "Contact",
  "customer.table.header.joinDate": "Join Date",
  "customer.table.header.lastPurchased": "Last Purchased",
  "customer.table.header.orders": "Orders",
  "customer.table.header.actions": "Actions",
  "customer.table.header.status": "Status",
  "customer.table.actions.enable": "Enable",
  "customer.table.actions.disable": "Disable",
  "customer.table.actions.enable.information":
    "You want to enable this customer ?",
  "customer.table.actions.disable.information":
    "You want to disable this customer ?",
  "customer.details.header.joined": "Joined",
  "customer.details.header.lastPurchase": "Last Purchase",
  "customer.table.header.joinDateLastPurchase": "Join Date / Last Purchase",
  "customer.enterCustomerEmail": "Enter Customer's Email",
  "customer.searchByEmail": "Search By Email",
  "customer.searchByClientId": "Search By Id",
  "customer.enterCustomerPhone": "Enter Customer's Phone",
  "customer.enterClientId": "Enter Client's Id",
  "customer.searchByPhone": "Search By Phone",
  "customer.filters.submittext": "Search",
  "customer.filters.clearFiltersText": "Clear All",
  "customer.details.pages.basicinfo": "Basic Info",
  "customer.details.pages.address": "Address",
  "customer.details.pages.phoneemail": "Phone / Email",
  "customer.details.pages.otherdetails": "Other Details",
  "customer.details.purchase.emptyText":
    "Currently there are no orders for this customer",
  "customer.joinedOn": "Joined On",
  "cutomer.joinedOn.placeholder": "Date",
  "customer.updatedAt": "Updated On",
  "customer.details.pages.basicinfo.totalorders": "Total Orders",
  "customer.details.pages.basicinfo.purchasesworth": "Purchase worth",
  "customer.details.pages.basicinfo.joined": "Joined On",
  "customer.details.pages.basicinfo.lastPurchase": "Last Purchase",
  "customer.details.pages.address.addAddress": "Add Address",
  "customer.details.pages.phonemail.addPhoneNumber": "Add Number",
  "customer.details.pages.phonemail.mailId": "Email Id",
  "customer.details.pages.phonemail.addEmailId": "Add Email ID",
  "customer.details.pages.address.viewOnMap": "View On Map",
  "customer.details.delete": "Delete",
  "customer.details.address.deleted": "Address has been successfully deleted",
  "customer.details.phone.deleted":
    "Phone number has been successfully deleted",
  "customer.details.order.status": "Order Status",
  "customer.details.email.send":
    "An email will be sent to the customer shortly.",
  "customer.details.call.call": "A call will be made to the customer shortly",
  "customer.details.sms.send": "An SMS will shortly be sent to the customer.",
  "customer.details.email.added": "Email added successfully",
  "customer.details.sendMessage": "Send Message",
  "customer.details.sendEmail": "Send email",
  "customer.details.call": "Call",
  "customer.details.address.added": "Address added successfully",
  "customer.details.phone.added": "Phone number added successfully",
  "customer.details.email.deleted": "Email id has been successfully deleted",
  "customer.details.order.purchase.history": "Purchase History",
  "customer.details.address.addAddress": "Add Address",
  "customer.details.address.landmark": "Landmark",
  "customer.details.address.pincode": "Pincode",
  "customer.details.address.city": "City",
  "customer.details.submitText.submit": "Submit",
  "customer.details.email.email": "Email",
  "customer.details.phone.phoneNumber": "Phone Number",
  "customer.details.call.option.lateDelivery": "Late Delivery",
  "customer.details.call.option.orderEdit": "Order Edit",
  "customer.details.call.option.paymentReminder": "Payment Reminder",
  "customer.details.call.option.rescheduling": "Rescheduling",
  "customer.details.call.option.orderConfirmation": "Order Confirmation",
  "customer.details.call.option.missedCallback": "Missed Callback",
  "customer.details.call.option.addressClarification": "Address Clarification",
  "customer.details.call.option.callToInternalEmployees":
    "Call To Internal Employees",
  "customer.details.call.option.tripEnquiry": "Trip Enquiry",
  "customer.details.call.option.surveyOnceShopped": "Survey - Once Shopped",
  "customer.details.call.option.retention": "Rentention",
  "customer.details.call.submitText.call": "Call",
  "customer.details.call.option.other": "Other",
  "customer.details.mail.subject": "Subject",
  "customer.details.mail.body": "Body",
  "customer.details.mail.submitText.send": "Send",
  "customer.details.sms.message": "Message",
  "customer.details.sms.submitText.send": "Send",
  "customer.address.delete.prompt.title": "Remove this address?",
  "customer.phone.delete.prompt.title": "Are you sure?",
  "customer.phone.delete.prompt.message": "This action will remove this number",
  "customer.email.delete.prompt.title": "Are you sure?",
  "customer.email.delete.prompt.message":
    "This action will remove this email ID",
  "customer.address.modal.heading": "Address",
  "customer.cancel.message": "No",
  "customer.confirm.message": "Yes",
  "customer.address.success": "Success",
  "customer.address.saved": "Address updated",
  "customer.address.okay": "Okay",
  "customer.action.reset.password": "Reset Password",
  "customer.save.success.title": "Password successfully changed!",
  "customer.save.success.closeText": "Okay",
  "customer.reset.password.title": "Are you sure ?",
  "customer.reset.password.message": "You want to reset customer's password ?",
  "customer.reset.password.cancelText": "No, Cancel",
  "customer.reset.password.confirmText": "Yes, Do it!",
  "customer.details.page.address.placeholder": "Enter address",
  "customer.details.address.landmark.placeholder": "Enter landmark",
  "customer.details.address.pincode.placeholder": "Enter pincode",
  "customer.details.address.city.placeholder": "Enter city",
  "customer.details.page.address.notServiceable.error":
    "Address is not serviceable",

  "customer.emailWidget.form.title": "Send email to",
  "customer.emailWidget.form.subject.heading": "Subject",
  "customer.emailWidget.form.subject.placeholder": "",
  "customer.emailWidget.form.body.heading": "Body",
  "customer.emailWidget.form.body.placeholder": "",
  "customer.emailWidget.form.submitText": "Submit",
  "customer.callWidget.form.title": "Call",
  "customer.smsWidget.form.title": "Send sms to",
  "customer.callWidget.form.reason.heading": "Reason",
  "customer.callWidget.form.reason.placeholder": "Please select an option",
  "customer.callWidget.form.reason.lateDelivery": "Late delivery",
  "customer.callWidget.form.reason.orderEdit": "Order edit",
  "customer.callWidget.form.reason.paymentReminder": "Payment reminder",
  "customer.callWidget.form.reason.rescheduling": "Rescheduling",
  "customer.callWidget.form.reason.orderConfirmation": "Order confirmation",
  "customer.callWidget.form.reason.missedCallback": "Missed call back",
  "customer.callWidget.form.reason.addressClarifiation": "Address clarifiation",
  "customer.callWidget.form.reason.internalCall": "Call to internal employees",
  "customer.callWidget.form.reason.tripEnquiry": "Trip Enquiry Call",
  "customer.callWidget.form.reason.survey": "Survey - Once Shopped",
  "customer.callWidget.form.reason.retention": "Retention",
  "customer.callWidget.form.reason.other": "Other",
  "customer.callWidget.form.otherReason.heading": "Other reason",
  "customer.callWidget.form.otherReason.placeholder": "",
  "customer.callWidget.form.submitText": "Submit",
  "customer.entityMetaData.successTitle": "Success",
  "customer.entityMetaData.successInformation":
    "Your settings have been successfully saved",
  "customer.entityMetaData.ok": "Okay",
  "customer.entityMetaData.errorTitle": "Error",
  "customer.entityMetaData.heading": "Custom fields",
  "customer.entityMetaData.enter": "Enter",
  "customer.entityMetaData.submit": "Save",
  "customer.entityMetaData.cancel": "Cancel",
  "customer.contact.details.default": "Default",
  "customer.contact.details.set.default": "Set as default",
  "customer.contact.details.delete": "Delete",
  "all.label.text": "All",
  "verified.label.text": "Verified",
  "not.verified.label.text": "Not Verified",
  "status.label.enabled": "Enabled",
  "disabled.text": "Disabled",

  "operations.dashboard.heading": "Operations dashboard",
  "operations.dashboard.sales": "Sales",
  "operations.dashboard.customerAcquired": "Customers acquired",
  "operations.dashboard.customersTransacted": "Customers transacted",
  "operations.dashboard.orders": "Orders",
  "operations.dashboard.yesterday": "Yesterday",
  "operations.dashboard.weeklyAvg": "Weekly avg.",
  "operations.dashboard.lastWeek": "Same day last week",
  "operations.dashboard.topProducts.heading": "Top Products",
  "operations.dashboard.count": "{count, plural, one{1 time} other{# times}}",
  "operations.dashboard.categories.heading": "Categories",
  "operations.dashboard.orderSlot.heading": "Orders Today",
  "operations.dashboard.slot": "Slot",
  "operations.dashboard.counts": "Count",
  "operations.dashboard.total": "Total",
  "order.log.editedBy": "Updated",
  "order.log.customerNA": "Customer not available",
  "order.log.leftAtDoorStep": "Order was left at doorstep",
  "operations.store.heading": "Stores",
  "operations.store.add.text": "Add store",
  "operations.store.table.header.name": "Name",
  "operations.store.table.header.clientId": "Client Id",
  "operations.store.table.header.address": "Address",
  "operations.store.table.header.deliveyHub": "Has Delivey Hub",
  "operations.store.table.header.picking": "Has Picking",
  "operations.store.table.header.status": "Status",
  "operations.store.table.row.picking.yes": "Yes",
  "operations.store.table.row.picking.no": "No",
  "operations.store.table.header.actions": "Actions",
  "operations.store.actions.edit": "Edit",
  "operations.store.actions.delete": "Delete",
  "operations.store.actions.enable": "Enable",
  "operations.store.actions.disable": "Disable",
  "operations.store.default": "Default",
  "operations.store.form.add.heading": "Add a store",
  "operations.store.form.edit.heading": "Edit store",
  "operations.store.form.name.heading": "Name",
  "operations.store.form.clientId.heading": "Client Id",
  "operations.store.form.clientId.placeholder": "Enter client Id",
  "operations.store.form.name.placeholder": "Enter your store's name",
  "operations.store.form.location.heading": "Location",
  "operations.store.form.location.placeholder": "Search for your location",
  "operations.store.form.location.requiredMessage":
    "Please set store's location",
  "operations.store.form.address.heading": "Address",
  "operations.store.form.address.placeholder":
    "Enter your store's complete address",
  "order.form.customer.address.line.one.heading": "Address line 1",
  "order.form.customer.address.line.two.heading": "Address line 2",
  "operations.store.form.submitText": "Submit",
  "operations.store.form.cancelText": "Cancel",
  "operations.store.form.metaData.heading": "Custom Fields",
  "operations.store.empty.message": "There are currently no stores configured",
  "operations.store.form.hasPicking": "Has Picking",
  "operations.store.form.hasDeliveryHub": "Has Delivery Hub",
  "operations.store.filters.clientId.heading": "Client Id",
  "operations.store.filters.clientId.placeholder": "Search by Client Id",
  "operations.store.form.businessHours.title": "Business Hours",
  "operations.store.form.businessHours.day": "Day",
  "operations.store.form.businessHours.alwaysOpen": "Always Open",
  "operations.store.form.businessHours.openingTime": "Opening Time",
  "operations.store.form.businessHours.closingTime": "Closing Time",
  "operations.store.form.hasPicking.label": "is picking done here?",
  "operations.store.form.store.level.payment": "store level payments setting",
  "operations.store.form.hasDeliveryHub.label": "is this a delivery hub?",
  "operations.store.form.hasClickCollect.label": "is this a collection center?",
  "operations.store.form.hasSelfCheckout.label": "self checkout",
  "operations.store.form.status.label": "Status",
  "operations.store.form.status.enable.label": "Enabled",
  "operations.store.form.status.disable.label": "Disabled",
  "operations.order.click.collect.customer.location": "Customer Location",

  "operations.pickingQueue.header.referenceNumber": "Order no.",
  "operations.pickingQueue.header.placedTime": "Placed time",
  "operations.pickingQueue.header.amount": "Amount",
  "operations.pickingQueue.header.status": "Status",
  "operations.pickingQueue.itemCount":
    "{count, plural, one{1 Item} other{# Items}}",
  "operations.pickingQueue.heading": "Picking Queue",
  "operations.pickingQueue.emptyList.message":
    "There are currently no orders for picking",

  "operations.prodStats.heading": "Best Sellers",
  "operations.prodStats.byQty.title": "By Quantity",
  "operations.prodStats.byAmt.title": "By Amount",
  "operations.prodStats.statTable.name": "Name",
  "operations.prodStats.statTable.qty": "Total Quantity",
  "operations.prodStats.statTable.amt": "Total Amount",

  "shifts.edit.heading": "Edit employee",
  "shifts.heading": "Employees",
  "shifts.edit": "Edit",
  "shifts.header.name": "Name",
  "shifts.header.offdays": "Weekly off",
  "shifts.header.timings": "Timings",
  "shifts.header.attendance": "Attendance",
  "shifts.header.actions": "Actions",
  "shifts.empty.message": "There are currently no users",
  "shifts.empty.submessage": "Users can be seen once added",
  "shifts.designation": "Designation",
  "shifts.select.designation": "Select designation",
  "shifts.form.name": "Name",
  "shifts.form.phone": "Phone",
  "shifts.form.weekly.off": "Weekly off",
  "shifts.form.startTime": "Shift Start time",
  "shifts.form.endTime": "Shift end time",
  "shifts.form.submitText": "Save",
  "shifts.form.cancel": "Cancel",
  "shifts.checkout": "Check Out",
  "shifts.checkin": "Check In",
  "shifts.designation.placeholder": "Enter designation",
  "shifts.filters.submitText": "Submit",
  "employee.checkedInAt": "Checked in at",

  "dispute.emptyMessage": "Currently there are no disputes",
  "dispute.view.heading": "Disputes for Order",
  "dispute.view": "View",
  "dispute.heading": "Disputed Orders",
  "dispute.referenceNumber": "Reference Number",
  "dispute.orderPlacedOn": "Placed on",
  "dispute.actions": "Action",
  "dispute.orderItem": "Order Item",
  "dispute.filingTime": "Filing Time",
  "dispute.disputedQuantity": "Quantity",
  "dispute.reason": "Reason",
  "dispute.status": "Status",
  "dispute.approveReject": "Approve/ Reject",
  "dispute.choose": "Choose",
  "dispute.approve": "Approve",
  "dispute.reject": "Reject",
  "dispute.pickup and refund": "Pickup and refund",
  "dispute.only deliver": "Only Deliver",
  "dispute.pickup and replace": "Pickup and replace",
  "dispute.only refund": "Only refund",
  "dispute.save": "Submit",
  "dispute.cancel": "Cancel",
  "dispute.approved": "Approved",
  "dispute.pending": "Pending",
  "dispute.rejected": "Rejected",
  "dispute.name": "Product name",
  "dispute.resolution": "Resolution",
  "dispute.quantity": "Quantity",
  "dispute.reason.placeholder": "Select Reason",
  "dispute.resolution.placeholder": "Select Resolution",
  "order.dispute.heading": "Dispute for order",

  "return.heading": "Returns",
  "returns.emptyMessage": "There are no returns",
  "return.view.heading": "Returns for order",
  "return.resolution": "Resolution",
  "return.wastagereason": "Wastage reason",
  "return.submit": "Submit",
  "return.toStore": "Store return",
  "return.markasWastage": "Wastage",
  "return.wastagereason.placeholder": "Select wastage reason",
  "return.cancel": "Cancel",
  "return.name": "Name",
  "return.item.name": "Product Name",
  "return.retunquantity": "Return Quantity",
  "return.totalquantity": "Total Quantity",
  "return.quantity": "Quantity",
  "return.settledQuantity": "Settled Quantity",
  "return.file.heading": "Return order for",
  "return.filter.referenceNumber": "Reference Number",
  "return.filter.referenceNumber.placeholder": "Enter order reference number",
  "return.filter.submit": "Submit",
  "return.move.order": "Move order to",
  "return.move.placeholder": "Select the status",
  "return.pending": "Pending",
  "return.return": "Returned",
  "return.completed": "Completed",
  "return.packed": "Packed",
  "return.are.you.sure": "Are you sure?",
  "return.heading.for": "Returns for",
  "return.reason.header": "Return reason",
  "return.reasons": "Select the reason",
  "return.no.items.to.return": "No items to return for this order",
  "picker.name": "Name",
  "picker.offDays": "Off days",
  "picker.timings": "Timings",
  "picker.timingsAndAttendance": "Timings / Attendance",
  "picker.status": "Current Status",
  "picker.attendance": "Attendance",
  "picker.actions": "Actions",
  "pickers.title": "Manage Pickers",
  "picker.edit": "Edit",
  "picker.editHeading": "Edit Picker",
  "picker.submit": "Save",
  "picker.cancel": "Cancel",
  "picker.weeklyOff": "Weekly off",
  "picker.phone": "Phone",
  "pickers.emptyMessage": "Currently there are no pickers",
  "picker.filter.name": "Name",
  "picker.filter.name.placeholder": "Enter name",
  "picker.filter.submit": "Search",
  "picker.filter.clear": "Clear All",

  "pickers.today.title": "Pickers Today",
  "pickers.today.slot": "Shift : ",
  "pickers.today.order.slot": "Slot: ",
  "pickers.today.slot.starttime": "Start: ",
  "pickers.today.slot.estimate.time": "Est: ",
  "pickers.today.slot.current.time": "Now: ",
  "pickers.today.picking.item": "Picking ",
  "pickers.today.packing.item": "Packing ",
  "pickers.today.checking.item": "Checking ",
  "pickers.today.packed.item": "Packed ",
  "pickers.today.picking.item2": " items",
  "pickers.idle.header": "Idle Pickers",
  "pickers.active.header": "Active Pickers",
  "pickers.empty": "Currently there are no pickers available",
  "pickers.today.total.pickers": "Total Pickers",
  "pickers.today.active.pickers": "Active",
  "pickers.today.idle.pickers": "Idle",
  "pickers.today.checkin.time": "Checkin Time: ",
  "pickers.today.late": "Late",
  "picker.today.order": "Order ",
  "picker.dialog.error": "Error",
  "picker.dialog.okText": "Okay",
  asapDurationStartText: "Within",
  asapDurationEndText: "minutes",

  "cp.title": "Capacity Planning",
  "cp.edit": "Edit Rule",
  "cp.add": "Add Rule",
  delivery: "Delivery",
  "cp.type": "Type",
  "cp.area": "Area",
  "cp.actions": "Actions",
  pickup: "Pickup",
  "type.placeholder": "Select type",
  "zones.placeholder": "Select zone",
  "slots.placeholder": "Select slot",
  "cutoff.time": "Cutoff time",
  "cutoff.time.placeholder": "Select cutoff time",
  "capacity.placeholder": "Enter capacity",
  "rule.for": "Rule for",
  "day.placeholder": "Select day",
  "cp.empty": "There are no configurations set",
  "cp.today": "Capacity Planning Today",
  "cp.today.button": "Today",
  "cp.today.cutofftime": "Cutoff Time 1",
  "cp.today.table.slot": "Slot",
  "cp.today.delivery": "Delivery",
  "cp.today.pickup": "Pickup",
  "Thank you for shopping at ": "Thank you for shopping at {name}",
  "Visit us at": "Visit us at {domain}",
  "orderDetails.uploadPrescription.prescription": "Prescription",
  "orderDetails.uploadPrescription.addPrescription": "Add Prescription",
  "orderDetails.uploadPrescription.uploadImagePlaceholderText":
    "Click here to upload or drag images",
  "orderDetails.uploadPrescription.enterDoctorName": "Enter Doctor's Name",
  "orderDetails.uploadPrescription.enterPatientName": "Enter Patient's Name",
  "orderDetails.uploadPrescription.patientName": "Patient's Name",
  "orderDetails.uploadPrescription.doctorName": "Doctor's Name",
  "orderDetails.uploadPrescription.upload": "Upload",
  "orderDetails.uploadPrescription.dr": "Dr.",
  "orderDetails.uploadPrescription.uploadedPrescription":
    "Uploaded Prescription",
  "orderDetails.uploadFile": "Uploaded File",
  "order.pack.select.package.label": "SELECT A PACKAGE",
  "order.pack.create.package": "Create Package",
  "order.pack.input.label.name.header": "LABEL NAME",
  "order.pack.input.label.name.placeholder": "Enter a label name",
  "order.pack.input.weight.header": "PACKAGE WEIGHT (in grams)",
  "order.pack.input.weight.placeholder": "Enter weight",
  "order.pack.request.success": "Order pack request sent successfully!",
  "type.text.label": "Type",
  "order.packing.weight": "Weight (in grams)",
  "order.add.bulk.text": "Bulk upload",
  "orderUpload.uploadedCsv.heading": "CSV File Uploaded Successfully!",
  "orderUpload.uploadFileText": "Click here to upload the file",
  "order.create.bulk.text": "Bulk upload",
  "orderUpload.csvDownloadText": "Download sample CSV",
  "delivery.area.kmlUpload.error.title": "Something went wrong",
  "delivery.area.kmlUpload.Success": "Zone added successfully",
  "product.restrictions.import.successText":
    "CSV File Uploaded Successfully! Restrictions will be added and appear after 15 minutes",
  "store.stock.orderType.placeholder": "Select order type",
};

export default data;
