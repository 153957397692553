import React from "react";
import ProductCollection from "../../../../components/Layout/ProductCollection";
import {
  Input,
  BrandSearch,
  Radio,
  Checkbox,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";

const BrandCollection = () => {
  return {
    preview: ProductCollection,
    fields: ({ getState, updateState, parent }) => {
      return (
        <div className="brand-collection">
          <div className="form-sections">
            <div className="form-section">
              <Input
                label={getMessage("themes.cc.name")}
                placeholder={getMessage("themes.cc.name.placeholder")}
                value={getState(["title"]) || ""}
                onChange={(e) => {
                  updateState(["title"], e);
                }}
              />
            </div>
            <div className="form-section">
              <Input
                label="subtitle"
                placeholder="Enter subtitle here"
                value={getState(["subtitle"]) || ""}
                onChange={(value) => {
                  updateState(["subtitle"], value);
                }}
              />
            </div>
            <div className="form-section">
              <Radio
                label={getMessage("themes.cc.layoutType")}
                name="layoutType"
                options={[
                  {
                    text: getMessage("themes.cc.scroller"),
                    value: "SCROLLER",
                  },
                  {
                    text: getMessage("themes.cc.grid"),
                    value: "GRID",
                  },
                ]}
                value={getState(["layoutType"])}
                onChange={(e) => {
                  updateState(["layoutType"], e);
                }}
              />
            </div>
            <div className="form-section no-label">
              <p>{getMessage("themes.cc.config")}</p>
              <Checkbox
                label="hello"
                name="pdtCount"
                inlineLabel={getMessage("themes.cc.pdtCount.inline")}
                value={getState(["showProductCount"])}
                onChange={(e) => {
                  updateState(["showProductCount"], e);
                }}
              />
            </div>
          </div>
          <div className="form-sections">
            <div className="form-section">
              <BrandSearch
                label={getMessage("themes.bc.brands")}
                placeholder={getMessage("themes.bc.categories.placeholder")}
                name="brandCollection"
                multiple
                draggable
                value={getState(["brand"])}
                onChange={(e) => {
                  updateState(["brand"], e);
                }}
              />
            </div>
          </div>
        </div>
      );
    },
  };
};

export default BrandCollection;
