import React, { Component } from "react";
import {
  ProductSearch,
  Input,
  Radio,
  Select,
  BrandSearch,
  CategorySearch,
} from "../../../../components/Form";
import ItemTable from "./ItemTable";
import { isAllRulesValid } from "../../../../lib/commonlyused";
import deleteIcon from "./delete.svg";

import { getMessage } from "../../../../lib/translator";

class BMINXATP extends Component {
  constructor(props) {
    super(props);
    this.submitHandler = this.submitHandler.bind(this);
    this.validationHandler = this.validationHandler.bind(this);
    this.initData = this.initData.bind(this);
    this.handleAddRule = this.handleAddRule.bind(this);
    this.handleDeleteRule = this.handleDeleteRule.bind(this);
  }

  initData() {
    let { method, _this, onUpdateValues } = this.props;
    if (method === "add") {
      let { values } = _this && _this.state;
      if (!(values.rule && values.rule.elementGroups)) {
        onUpdateValues({
          ...values,
          rule: { ...values.rule, elementGroups: [{ total: {} }] },
        });
      }
    }
  }

  validationHandler(parent) {
    let validations = JSON.parse(JSON.stringify(parent.state.validations));
    parent.setState({
      validations,
    });
  }

  handleAddRule(e) {
    e && e.preventDefault();
    let { _this } = this.props;
    let { values } = _this.state;
    let { rule } = values || {};
    let { elementGroups } = rule || {};

    if (!elementGroups) {
      rule = { ...rule, elementGroups: [{ total: {} }] };
    }

    rule = { ...rule, elementGroups: [...rule.elementGroups, { total: {} }] };

    _this.setState({ pressedSubmitWithCurrentData: false });

    this.props.onUpdateValues({ ...values, rule });
  }

  handleDeleteRule(index) {
    let { _this } = this.props;
    let { values } = _this.state;
    let validations = JSON.parse(JSON.stringify(_this.state.validations));
    let validationsRule = validations.rule;
    let { rule } = values || {};

    if (rule && rule.elementGroups) {
      let elementGroups = rule.elementGroups.filter((_, idx) => idx !== index);
      rule = { ...rule, elementGroups };
    }

    if (validationsRule && validationsRule.elementGroups) {
      let elementGroups = validationsRule.elementGroups.filter(
        (_, idx) => idx !== index
      );
      validationsRule = { ...validationsRule, elementGroups };
    }

    this.props.onUpdateValues({ ...values, rule });
    this.props.onUpdateValidations({ ...validations, validationsRule });
  }

  submitHandler(formData) {
    let { method, oldFormat } = formData;
    let data = JSON.parse(JSON.stringify(formData));
    const entityType = data.entityType.toLowerCase();
    const isCombo =
      data.rule && data.rule.itemDiscountType === "COMBO_DISCOUNT";
    let newRule = {
      elementGroups: data.rule.elementGroups.map((eg) => ({
        ...eg,
        maxDiscount: eg.maxDiscount !== "" ? eg.maxDiscount : undefined,
      })),
      entity: {
        type: data.entityType === "PRODUCT" ? "VARIANT" : data.entityType,
        id: data.entityType === "PRODUCT" ? null : data[entityType].id,
      },
    };
    if (entityType === "product") {
      newRule.variants = data.product.map((pdt) => pdt.id).filter(Boolean);
    }
    if (oldFormat && method === "edit") {
      newRule = { ...newRule, ...newRule.elementGroups[0] };
      delete newRule.elementGroups;
    }
    newRule.limit = Number(newRule.limit);

    if (isCombo) {
      newRule.isCombo = true;
    } else {
      delete newRule.isCombo;
    }

    data.rule = newRule;
    // data.entityId = 1
    data.offerType = data.type;
    delete data.pwpTagId;
    delete data.quantity;
    delete data.minQuantity;
    delete data.product;
    delete data.category;
    delete data.brand;
    delete data.entity;
    delete data.includes;
    delete data.itemDiscountType;
    return data;
  }

  componentDidMount() {
    this.initData();
  }

  render() {
    let {
      entityType,
      discountTypes,
      _this,
      type,
      hasOfferStarted,
      itemDiscountTypes,
      method,
    } = this.props;
    const { values } = _this.state;
    const { itemDiscountType } = _this.state.values;
    entityType = this.props.entityTypeMap[entityType];
    const comboDiscount = itemDiscountType === "COMBO_DISCOUNT";
    const items = values[entityType];
    const itemsLength = items ? items.length : 0;

    const { rule } = values || {};
    const { elementGroups } = rule || {};

    return (
      <div className={`offers-form ${type}-offer`}>
        <div className="offer-fields">
          {entityType === "product" && (
            <ProductSearch
              required
              label={getMessage(`offer.${entityType}`)}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              name={`entityType-${entityType}-pdt`}
              key={`entityType-${entityType}-pdt`}
              className="product-searchable"
              onChange={_this.addItem}
              readOnly={hasOfferStarted}
            />
          )}
          {entityType === "category" && (
            <CategorySearch
              name="category-search"
              readOnly={hasOfferStarted}
              required
              label={`CATEGORY`}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              className="category-search"
              {..._this.generateStateMappers({
                stateKeys: [entityType],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {entityType === "brand" && (
            <BrandSearch
              name="brand-search"
              required
              readOnly={hasOfferStarted}
              label={`BRAND`}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              className="category-search"
              {..._this.generateStateMappers({
                stateKeys: [entityType],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <Radio
            name="discount-type-main"
            label={getMessage("offer.main.discount.type")}
            placeholder={getMessage("offer.main.discouont.type.placeholder")}
            options={itemDiscountTypes}
            {..._this.generateStateMappers({
              stateKeys: ["rule", "itemDiscountType"],
              loseEmphasisOnFill: true,
            })}
            readOnly={hasOfferStarted}
          />
          {elementGroups &&
            elementGroups.map((_, index) => (
              <div className="row rule-block" key={`rule-block-${index}`}>
                <div className="offer-rule-header">
                  {Boolean(index) && (
                    <img
                      className="rule-delete-icon"
                      onClick={() => this.handleDeleteRule(index)}
                      src={deleteIcon}
                      alt="delete"
                    />
                  )}
                </div>
                <Input
                  type="number"
                  name="min-quantity"
                  min={1}
                  readOnly={hasOfferStarted}
                  required
                  label={getMessage("offer.min.quantity")}
                  placeholder={getMessage("offer.min.quantity.placeholder")}
                  {..._this.generateStateMappers({
                    stateKeys: ["rule", "elementGroups", index, "minQuantity"],
                    loseEmphasisOnFill: true,
                  })}
                />
                <Select
                  name="discount-type"
                  label={getMessage("offer.discounttype")}
                  required
                  disabled={hasOfferStarted}
                  placeholder={getMessage("offer.discounttype.placeholder")}
                  options={discountTypes}
                  className="discount-type row-element"
                  {..._this.generateStateMappers({
                    stateKeys: ["rule", "elementGroups", index, "total", "t"],
                    loseEmphasisOnFill: true,
                  })}
                  onChange={(type) =>
                    _this.handleDiscountChange(type, ["elementGroups", index])
                  }
                  readOnly={hasOfferStarted}
                />
                <Input
                  name="discount-value"
                  type="number"
                  step={0.01}
                  min={0}
                  max={
                    elementGroups[index].total &&
                    values.rule.elementGroups[index].total.t === "PERCENT_OFF"
                      ? 100
                      : Number.MAX_SAFE_INTEGER
                  }
                  required
                  // readOnly={hasOfferStarted}
                  label={getMessage("offer.discountvalue")}
                  className="discount-value row-element"
                  placeholder={getMessage("offer.discountvalue.placeholder")}
                  {..._this.generateStateMappers({
                    stateKeys: ["rule", "elementGroups", index, "total", "v"],
                    loseEmphasisOnFill: true,
                  })}
                  readOnly={
                    hasOfferStarted ||
                    (elementGroups[index].total &&
                      values.rule.elementGroups[index].total.t === `FREE`)
                  }
                />
                {elementGroups[index].total &&
                  values.rule.elementGroups[index].total.t ===
                    `PERCENT_OFF` && (
                    <Input
                      name="max-discount-value"
                      type="number"
                      className="row-element"
                      min={0}
                      step={0.01}
                      label={getMessage("offer.maxdiscountvalue")}
                      placeholder={getMessage(
                        "offer.discountvalue.placeholder"
                      )}
                      {..._this.generateStateMappers({
                        stateKeys: [
                          "rule",
                          "elementGroups",
                          index,
                          "maxDiscount",
                        ],
                        loseEmphasisOnFill: true,
                      })}
                      readOnly={
                        hasOfferStarted ||
                        (elementGroups[index].total &&
                          elementGroups[index].total.t === `FREE`)
                      }
                    />
                  )}
              </div>
            ))}
          {method === "add" && (
            <button
              className="primary add-rule-btn"
              onClick={this.handleAddRule}
              disabled={
                !isAllRulesValid(rule.elementGroups, ["minQuantity", "total"])
              }
            >
              +
            </button>
          )}
        </div>
        <div>
          {itemsLength ? (
            <ItemTable
              comboDiscount={comboDiscount}
              entityType={entityType}
              items={items}
              discountTypes={discountTypes}
              _this={_this}
              buyAny
              hasOfferStarted={hasOfferStarted}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default BMINXATP;
