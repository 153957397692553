import React, { Component } from "react";
import PaymentGatewayConfiguration from "./PaymentGatewayConfiguration";
import RateConfiguration from "./RateForm";
import API from "../../../../lib/api";
import Loader from "../../../../components/Loader";
import { getMessage } from "../../../../lib/translator";

const PaymentGatewaySelector = (props) => {
  let gateways =
    props &&
    props.paymentGateways.map((paymentgateway, index) => {
      return (
        <option key={paymentgateway.id} value={paymentgateway.id}>
          {paymentgateway.name}
        </option>
      );
    });
  return (
    <select
      className="payment-gateway-selector"
      onChange={props.onSelect}
      placeholder="Select payment gateway"
      value={props.value || "default"}
    >
      <option value="default" disabled>
        Select your payment gateway
      </option>
      {gateways}
    </select>
  );
};

export default class CustomPaymentConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGateway: null,
      showLoader: true,
      addedGateways: props.addedGateways.slice(),
      configDoneGateways: props.addedGateways.slice(),
      configureExistingGatewayMessage: false,
    };
    this.state.values = {
      paymentGateways: null,
      paymentModes: null,
    };
    this.toggle = this.toggle.bind(this);
    this.loadData = this.loadData.bind(this);
    this.selectGateway = this.selectGateway.bind(this);
    this.getAddedGateways = this.getAddedGateways.bind(this);
    this.getSupportedModesForGateways =
      this.getSupportedModesForGateways.bind(this);
    this.updateGateways = this.updateGateways.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  updateGateways(gatewaysData) {
    this.setState({
      addedGateways: gatewaysData,
      configDoneGateways: gatewaysData,
      configureExistingGatewayMessage: false,
    });
  }

  getSupportedModesForGateways() {
    let paymentGateways = this.state.values.paymentGateways;
    let mapping = paymentGateways.map((pg) => {
      var res = pg.paymentModes
        .reduce(function (res, currentValue) {
          if (res.indexOf(currentValue.parentPaymentId) === -1) {
            res.push(currentValue.parentPaymentId);
          }
          return res;
        }, [])
        .map((group) => {
          return {
            paymentModeId: group,
            paymentModeName: this.state.values.paymentModes.filter(
              (pm) => pm.id === group
            )[0],
            paymentModes: pg.paymentModes
              .filter((_el) => {
                return _el.parentPaymentId === group;
              })
              .map((_el) => _el),
          };
        });
      return {
        paymentGatewayId: pg.id,
        support: res,
        configs: pg.requiredConfigs,
      };
    });
    this.setState({
      mapping: mapping,
    });
  }

  loadData() {
    this.setState({
      showLoader: true,
    });

    this.paymentgatewaysApi = new API({
      url: "/account-service/payment-gateway",
    });
    this.paymentModesApi = new API({ url: "/account-service/payment-mode" });

    Promise.all([this.paymentgatewaysApi.get(), this.paymentModesApi.get()])
      .then(([paymentgatewaysResponse, paymentModesResponse]) => {
        let newState = Object.assign({}, this.state.values);
        newState.paymentGateways =
          paymentgatewaysResponse.data.paymentgateway.filter(
            (value) => value.status === "ENABLED"
          );
        newState.paymentModes = paymentModesResponse.data.paymentmode.filter(
          (value) => value.status === "ENABLED"
        );

        this.setState(
          {
            values: newState,
            showLoader: false,
          },
          () => this.getSupportedModesForGateways()
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentWillUnmount() {
    this.paymentModesApi && this.paymentModesApi.cancel();
    this.paymentgatewaysApi && this.paymentgatewaysApi.cancel();
  }

  selectGateway(gateway) {
    this.addGateway(gateway.target.value);
  }

  addGateway(gatewayId) {
    let addedGateways = this.props.addedGateways;
    if (addedGateways.filter((gw) => gw.id === gatewayId).length > 0) {
      this.setState({
        selectedGateway: gatewayId,
      });
    } else {
      let newAddedGateways = this.state.addedGateways.slice();
      if (newAddedGateways.filter((gw) => gw.id === gatewayId).length > 0) {
        this.setState({
          selectedGateway: gatewayId,
        });
        return;
      }
      let paymentGateways = this.state.values.paymentGateways;
      let newGateway = paymentGateways.filter(function (gw) {
        return gw.id === gatewayId;
      });
      newAddedGateways.push(newGateway[0]);
      this.setState({
        addedGateways: newAddedGateways,
        selectedGateway: gatewayId,
        configureExistingGatewayMessage: false,
      });
    }
  }

  getAddedGateways() {
    let addedGateways = this.state.addedGateways;
    let selectedGateway = this.state.selectedGateway;
    let mapping = this.state.mapping;
    if (!mapping) {
      return null;
    }
    let _this = this;
    let add = addedGateways.map((gw) => {
      let gwId = gw.id;
      let support = mapping.filter((pg) => pg.paymentGatewayId === gwId);
      let selected = gw.paymentModes;
      let configs =
        gw.accountConfiguration && gw.accountConfiguration.length !== 0
          ? gw.accountConfiguration
          : mapping.filter((pg) => pg.paymentGatewayId === gwId)[0].configs;
      return (
        <PaymentGatewayConfiguration
          paymentAccountId={this.props.id}
          image={gw.imageUrl}
          name={gw.name || gw.providerName}
          key={gw.id}
          selected={selected}
          supportedPaymentModes={support[0].support}
          config={configs}
          id={`payment-form-${gw.id}`}
          gatewayId={gw.id}
          configuredGateways={this.state.configDoneGateways}
          onSuccess={this.updateGateways}
          onToggle={() => {
            _this.toggle(gw.id);
          }}
          expanded={selectedGateway === gw.id}
        />
      );
    });
    return add;
  }

  toggle(index) {
    this.setState((prevState) => {
      let selectedGateway = prevState.selectedGateway === index ? null : index;
      return {
        selectedGateway,
      };
    });
  }
  render() {
    return (
      <div>
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <div className="custom-payment-configuration">
            <div className="custom-info">
              <h4>Add Payment Gateway</h4>
              {/* Add the below line after zoppay is enabled */}
              {/* <div><button type='button' className='button primary' onClick={this.props.switchToDefault} >{getMessage('settings.online.payment.use.zoppay')}</button></div>  */}
            </div>
            <PaymentGatewaySelector
              paymentGateways={this.state.values.paymentGateways}
              onSelect={this.selectGateway}
              value={this.state.selectedGateway}
            />
            {this.state.addedGateways.length > 0 && (
              <div>
                <h5>Payment Gateways added</h5>
                {this.state.configureExistingGatewayMessage && (
                  <h6 className="form-error">
                    {getMessage(
                      "settings.online.payment.configureexistinggateways"
                    )}
                  </h6>
                )}
                <div className="gateway-configurations">
                  {this.getAddedGateways()}
                </div>
                {this.state.configDoneGateways &&
                  this.state.configDoneGateways.length > 1 && (
                    <RateConfiguration
                      paymentAccountId={this.props.id}
                      addedGateways={this.state.configDoneGateways}
                      mapping={this.state.mapping}
                      paymentModes={this.state.values.paymentModes}
                      onSuccess={this.updateGateways}
                    />
                  )}
              </div>
            )}
            <div className="cancel-button">
              <button
                className="button"
                type="button"
                onClick={() => this.props.back()}
                disabled={this.state.submitting}
              >
                {getMessage("basicInfo.cancel.text")}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
