import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  getPrintableTime,
  getRemainingTime,
  getStandradDateTime,
  utcToLocalTime,
} from "../../../../lib/datetime";

import expandPlus from "../images/expand-plus.svg";
import expandMinus from "../images/expand-minus.svg";

import "./style.css";

class ExpandingStats extends Component {
  constructor() {
    super();
    this.toggleExpand = this.toggleExpand.bind(this);
    this.state = {
      expanded: false,
    };
  }
  toggleExpand() {
    this.setState({ expanded: !this.state.expanded });
  }
  render() {
    let { heading, data, unit, currentRunners, currentVehicles } = this.props;
    let classname = this.props.className;
    let containerStyle = {
      maxHeight: this.state.expanded ? this.contentRef.offsetHeight + "px" : 0,
    };
    let count =
      (currentRunners
        ? currentRunners.length
        : currentVehicles
        ? currentVehicles.length
        : 0) + (data ? data.length : 0);
    unit +=
      (currentRunners
        ? currentRunners.length
        : currentVehicles
        ? currentVehicles.length
        : 0) +
        (data ? data.length : 0) >
      1
        ? "s"
        : "";
    return (
      <div
        className={
          "expanding-box" +
          (this.state.expanded ? " expanded" : "") +
          (classname ? " " + classname : "")
        }
      >
        <div className="brief-heading">
          <div className="heading-wrapper">
            <div className="heading">{heading}</div>
            <div className="count">
              {count} {unit}
            </div>
          </div>
          <div className="toggle-button" onClick={this.toggleExpand}>
            <img
              src={this.state.expanded ? expandMinus : expandPlus}
              alt={this.state.expanded ? "-" : "+"}
            />
          </div>
        </div>
        <div className="content-container" style={containerStyle}>
          <div
            className="expanding-box-details"
            ref={(node) => {
              this.contentRef = node;
            }}
          >
            {currentVehicles &&
              currentVehicles.length !== 0 &&
              currentVehicles.map((elm, index) => (
                <div
                  className="expanding-box-elm"
                  key={`expanding-stat-${index}`}
                >
                  <div className="heading-wrapper">
                    <div className="heading">
                      {`${elm.number} (`}
                      <Link
                        to={`/logistics/trips/${elm.tripId}`}
                      >{` ${elm.tripId} `}</Link>
                      )
                    </div>
                    {elm.vehicleStart &&
                      elm.vehicleStart.split(" ").length > 1 && (
                        <div className="trip-time">
                          {
                            getPrintableTime(
                              getStandradDateTime(
                                utcToLocalTime,
                                elm.vehicleStart
                              )
                            ).split(",")[1]
                          }
                        </div>
                      )}
                  </div>
                  <div className="icon" />
                </div>
              ))}
            {currentRunners &&
              currentRunners.length !== 0 &&
              currentRunners.map((elm, index) => (
                <div
                  className="expanding-box-elm"
                  key={`expanding-stat-${index}`}
                >
                  <div className="heading-wrapper">
                    <div className="heading">
                      {`${elm.name} (`}
                      <Link
                        to={`/logistics/trips/${elm.tripId}`}
                      >{` ${elm.tripId} `}</Link>
                      )
                    </div>
                    {elm.startTime && elm.startTime.split(" ").length > 1 && (
                      <div className="trip-time">
                        {
                          getPrintableTime(
                            getStandradDateTime(utcToLocalTime, elm.startTime)
                          ).split(",")[1]
                        }
                      </div>
                    )}
                  </div>
                  <div className="icon" />
                </div>
              ))}
            {data &&
              data.length &&
              data.map((elm, index) => (
                <div
                  className="expanding-box-elm"
                  key={`expanding-stat-${index}`}
                >
                  <div className="heading-wrapper">
                    <div className="heading">
                      {this.props.unit === "RE" ? elm.name : elm.number}
                    </div>
                    {elm.shiftIn && (
                      <div className="trip-time">
                        {
                          getPrintableTime(
                            getStandradDateTime(
                              utcToLocalTime,
                              `${new Date().toISOString().split("T")[0]} ${
                                elm.shiftIn
                              }`
                            )
                          ).split(",")[1]
                        }
                        <span className="remaining-time">
                          Rem :{" "}
                          {getRemainingTime(
                            moment().utc().format("HH:mm:ss"),
                            elm.shiftOut
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="icon" />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ExpandingStats;
