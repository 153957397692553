import React from "react";
import {
  BaseForm,
  VALIDATION_TYPES,
  Textarea,
} from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";
import API from "../../../../../lib/api";

class SmsForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      submitting: false,
      formError: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    const api = new API({ url: "/communication-service/SMS" });
    let params = {};
    params["to"] = this.props.to;
    params["transactional"] = false;
    params["type"] = "no-template";
    params["data"] = {};
    params["data"]["content"] = data.message;
    return api
      .post(params)
      .then(
        (response) => {
          this.setState({ formError: "" });
        },
        (error) => {
          this.setState({ formError: error.message });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  onSubmit(data) {
    this.setState(
      {
        submitting: true,
      },
      () => {
        this.handleSubmit(data).then(() => {
          this.setState({
            submitting: false,
          });
          if (this.state.formError === "") {
            this.props.onSuccess("sms");
          }
        });
      }
    );
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <div className="SmsForm">
        <Form>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <Textarea
            label={getMessage("customer.details.sms.message")}
            type="text"
            name="content"
            required
            {...this.generateStateMappers({
              stateKeys: ["message"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <div className="form-buttons-container">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage("customer.details.mail.submitText.send")}
            </SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}
export default SmsForm;
