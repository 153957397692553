import React, { Component } from "react";
import { getSession } from "../../../lib/auth";
import {
  getPrintableTime,
  getStandradDateTime,
  utcToLocalTime,
} from "../../../lib/datetime";
import { getMessage } from "../../../lib/translator";
import { Radio } from "../../Form";
import Table, { Cell, Header, Row } from "../../Table";
import "./style.css";

function checkIsMobile() {
  return window.screen.width <= 840;
}

class VendorRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: checkIsMobile(),
    };
    this.setisMobileView = this.setisMobileView.bind(this);
  }

  setisMobileView() {
    this.setState({ isMobileView: checkIsMobile() });
  }

  componentDidMount() {
    window.addEventListener("resize", this.setisMobileView, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setisMobileView, false);
  }
  render() {
    const { vendor, parent } = this.props;
    const { isMobileView } = this.state;
    const _this = parent;
    const organization = (getSession() || {}).organization || {};
    const { name, pickupEstimate, shippingCost, deliveryEndEstimate } = vendor;
    return (
      <Row>
        <Cell className="vendor-radio">
          <Radio
            className="vehicle-type"
            name="vendor"
            required
            options={[
              {
                text: "",
                value: name,
              },
            ]}
            {..._this.generateStateMappers({
              stateKeys: ["vendor"],
            })}
          />
        </Cell>

        <Cell className="vendor-name">
          <small>{name}</small>
        </Cell>

        <Cell className="vendor-charges">
          <small>
            {isMobileView ? (
              <span className="text-muted label">
                {getMessage("order.dispatch.label.courier.charges") + ": "}
              </span>
            ) : (
              ""
            )}
            {((organization.currency && organization.currency.symbol) || "") +
              shippingCost.toFixed(2)}
          </small>
        </Cell>

        <Cell className="vendor-pickup">
          {isMobileView ? (
            <div className="text-muted label">
              {getMessage(
                "order.courier.dispatch.table.header.delivery.estimate"
              )}
            </div>
          ) : (
            ""
          )}
          {pickupEstimate ? (
            <>
              <div className="date">
                {getPrintableTime(
                  getStandradDateTime(utcToLocalTime, pickupEstimate)
                )}
              </div>
              <small className="text-muted time">
                {
                  getPrintableTime(
                    getStandradDateTime(utcToLocalTime, pickupEstimate)
                  ).split(",")[1]
                }
              </small>
            </>
          ) : (
            <div className="date">{getMessage("banners.table.na")}</div>
          )}
        </Cell>
        <Cell className="vendor-delivery">
          {isMobileView ? (
            <div className="text-muted label">
              {getMessage(
                "order.courier.dispatch.table.header.delivery.estimate"
              )}
            </div>
          ) : (
            ""
          )}
          {deliveryEndEstimate ? (
            <>
              <div className="date">
                {getPrintableTime(
                  getStandradDateTime(utcToLocalTime, deliveryEndEstimate)
                )}
              </div>
              <small className="text-muted time">
                {
                  getPrintableTime(
                    getStandradDateTime(utcToLocalTime, deliveryEndEstimate)
                  ).split(",")[1]
                }
              </small>
            </>
          ) : (
            <div className="date">{getMessage("banners.table.na")}</div>
          )}
        </Cell>

        <Cell />
      </Row>
    );
  }
}

export const VendorsTable = (props) => {
  const { vendorsList, parent } = props;
  const VendorRows = [];
  for (let key in vendorsList) {
    let vendor = vendorsList[key];
    VendorRows.push(
      <VendorRow key={vendor.courierVendorId} vendor={vendor} parent={parent} />
    );
  }
  return (
    <div className="vendors-table">
      {vendorsList && (
        <Table tableDynamic={true}>
          <Header>
            <Cell />
            <Cell className="courier-name">
              {getMessage("order.courier.dispatch.table.header.name")}
            </Cell>
            <Cell>{getMessage("order.table.courierCharge")}</Cell>
            <Cell>
              {getMessage(
                "order.courier.dispatch.table.header.pickup.estimate"
              )}
            </Cell>
            <Cell>
              {getMessage(
                "order.courier.dispatch.table.header.delivery.estimate"
              )}
            </Cell>
            <Cell />
          </Header>
          {VendorRows}
        </Table>
      )}
    </div>
  );
};
