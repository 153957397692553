import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Table from "./Table";
import Permissions from "./Permissions";

class UsersRouter extends Component {
  render() {
    let { router, ...props } = this.props;
    if (router.match.params.action === "edit-permissions") {
      return <Permissions {...this.props} />;
    } else {
      return <Table {...props} />;
    }
  }
}

export default withRouter(({ match, ...props }) => (
  <UsersRouter router={{ match }} {...props} />
));
