import React, { Component } from "react";
import { ZOPSMART_URL } from "../../config/app.js";
import logo from "./zopsmart-final-logo.svg";
import zopsmartLogo from "./zopsmartlogo.svg";
import "./style.css";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hide = this.hide.bind(this);
  }
  componentDidMount() {
    window.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick);
  }

  toggle() {
    this.setState(
      (prevState) => {
        return {
          expanded: !this.state.expanded,
        };
      },
      () => {
        document.body.style.overflow = this.state.expanded ? "hidden" : "auto";
      }
    );
  }

  hide() {
    this.setState({
      expanded: false,
    });
    document.body.style.overflow = "auto";
  }

  handleClick(e) {
    if (this.toggleRef.contains(e.target)) {
      this.toggle();
    } else {
      this.hide();
    }
  }
  render() {
    let { current, eazy } = this.props;
    const { expanded } = this.state;
    let image = eazy ? logo : zopsmartLogo;
    return (
      <header className="common-header">
        <div className="relative">
          <div className="wrap">
            <a href={ZOPSMART_URL + "/"} className="logo-link">
              <img className="logo" src={image} alt="ZopSmart Logo" />
            </a>
            <div className="menu-button-container">
              <div
                className={`hamburger ${expanded ? "close" : ""}`.trim()}
                ref={(node) => {
                  this.toggleRef = node;
                }}
              >
                <span />
                <span />
                <span />
              </div>
              <nav className={`site-nav ${expanded ? "expanded" : ""}`.trim()}>
                <ul>
                  <li>
                    <a
                      href={`${ZOPSMART_URL}`}
                      className={`${
                        current === "home" ? "current" : ""
                      }`.trim()}
                    >
                      HOME
                    </a>
                  </li>
                  <li
                    className="submenu-link"
                    ref={(node) => {
                      this.dropdownRef = node;
                    }}
                  >
                    <a
                      href={ZOPSMART_URL + "/product"}
                      className={`${current === "product" ? "current" : ""}`}
                    >
                      PRODUCTS
                    </a>
                    <ul className="submenu">
                      <li>
                        <a href={ZOPSMART_URL + "/eazy"}>SMARTSTORE EAZY</a>
                      </li>
                      <li>
                        <a href={ZOPSMART_URL + "/enterprise"}>
                          SMARTSTORE ENTERPRISE
                        </a>
                      </li>
                      <li>
                        <a href={ZOPSMART_URL + "/product/#product-finder"}>
                          PRODUCT FINDER
                        </a>
                      </li>
                      <li>
                        <a href={ZOPSMART_URL + "/product/#zoppay"}>ZOPPAY</a>
                      </li>
                      <li>
                        <a href={ZOPSMART_URL + "/product/#intellipos"}>
                          INTELLI POS
                        </a>
                      </li>
                      <li>
                        <a href={ZOPSMART_URL + "/product/#grocery-express"}>
                          GROCERY EXPRESS
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a
                      href={ZOPSMART_URL + "/technology"}
                      className={`${current === "technology" ? "current" : ""}`}
                    >
                      TECHNOLOGY
                    </a>
                  </li>
                  <li>
                    <a
                      href={ZOPSMART_URL + "/about"}
                      className={`${current === "about" ? "current" : ""}`}
                    >
                      ABOUT US
                    </a>
                  </li>
                  <li>
                    <a
                      href={ZOPSMART_URL + "/contact"}
                      className={`${current === "contact" ? "current" : ""}`}
                    >
                      CONTACT US
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
