import React from "react";
import Tabs from "../../../../../components/Tabs";
import {
  BaseForm,
  Input,
  Select,
  VALIDATION_TYPES,
  MultiTextInput,
  Checkbox,
} from "../../../../../components/Form";
import Loader from "../../../../../components/Loader";
import { Dialog } from "../../../../../components/Popup";

import { saveEntityMetaData } from "../../../../../lib/auth";
import API from "../../../../../lib/api";
import { getMessage } from "../../../../../lib/translator";

import "./style.css";

const ConfigTypes = [
  {
    text: "Number",
    value: "number",
  },
  {
    text: "String",
    value: "string",
  },
  {
    text: "Text",
    value: "text",
  },
  {
    text: "Boolean",
    value: "boolean",
  },
  {
    text: "Enum",
    value: "enum",
  },
  {
    text: "Country",
    value: "country",
  },
  {
    text: "MultiValued Enum",
    value: "multiValued Enum",
  },
  {
    text: "File",
    value: "file",
  },
  {
    text: "Date",
    value: "date",
  },
  {
    text: "Percentage",
    value: "percentage",
  },
];

class EntityMetaData extends BaseForm {
  constructor(props) {
    super(props);

    this.tabHeaders = [
      "order",
      "customer",
      "product",
      "store",
      "address",
      "offer",
    ];
    this.layouts = ["text", "string", "number"];

    this.state.currentTabIndex = 0;
    this.state.showLoader = 0;
    this.state.showErrorDialog = false;
    this.state.showDeleteDialog = false;
    this.state.showSuccessDialog = false;

    this.handleOnTabClick = this.handleOnTabClick.bind(this);
    this.addLayout = this.addLayout.bind(this);
    this.deleteKey = this.deleteKey.bind(this);
  }

  componentDidMount() {
    // const id = this.props.data.id
    const api = new API({ url: `/config-service/meta-data` });
    this.setState({
      showLoader: true,
    });
    api
      .get()
      .then(
        (response) => {
          let tranformedResponse = response.data.config.entityMetaData;
          let values = {};
          Object.entries(tranformedResponse).forEach(([key, value]) => {
            values[key] = [];
            value &&
              Object.entries(value) &&
              Object.entries(value).forEach(([entity, details, index]) => {
                let entityMetadata = {
                  name: entity,
                  type: details.type,
                  typeMeta: details.typeMeta && details.typeMeta.allowedValue,
                  indexed: details.indexed,
                  searchable: details.searchable,
                  isPrivate: details?.isPrivate,
                  mandatory: details.mandatory,
                  id: details.id,
                };
                if (
                  details.type === "country" &&
                  details.typeMeta &&
                  details.typeMeta.allowedValue
                ) {
                  if (details.typeMeta.allowedValue.length) {
                    entityMetadata.typeMeta = details.typeMeta.allowedValue.map(
                      (c) => {
                        return { name: c };
                      }
                    );
                  }
                }
                values[key].push(entityMetadata);
              });
          });
          this.setState({
            values,
            showLoader: false,
          });
        },
        (error) => {
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
          this.setState({
            formError: error,
            showLoader: false,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  handleOnTabClick(index) {
    this.setState({
      currentTabIndex: index,
    });
  }

  addLayout() {
    let values = Object.assign({}, this.state.values);
    const name = this.tabHeaders[this.state.currentTabIndex];
    const fields = this.state.values[name] || [];
    fields.push({
      name: "",
      type: "string",
    });
    values[name] = fields;
    this.setState({
      values,
    });
  }

  deleteKey(index) {
    const deleteIndex = this.state.deleteIndex;
    let values = Object.assign({}, this.state.values);
    const name = this.tabHeaders[this.state.currentTabIndex];
    let keys = values[name];
    keys.splice(deleteIndex, 1);
    values[name] = keys;
    this.setState({
      values,
      deleteIndex: null,
      showDeleteDialog: false,
    });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    const api = new API({ url: `/config-service/meta-data` });
    let dataToSend = {};
    let values = Object.assign({}, this.state.values);
    Object.entries(values).forEach(([key, value]) => {
      let entityMetaData = {};
      value.forEach((obj, index) => {
        entityMetaData[obj.name] = {
          type: obj.type,
          indexed: obj.indexed,
          mandatory: obj.mandatory,
          searchable: obj?.searchable,
          isPrivate: obj?.isPrivate,
        };
        if (obj.id) {
          entityMetaData[obj.name].id = obj.id;
        }
        if (obj.type === "enum" || obj.type === "multiValued Enum") {
          entityMetaData[obj.name].typeMeta = { allowedValue: obj.typeMeta };
        } else if (obj.type === "country") {
          delete entityMetaData[obj.name].typeMeta;
        }
        if (obj.name === "") {
          delete entityMetaData[obj.name];
        }
      });
      dataToSend[key] = entityMetaData;
    });
    let params = {
      config: {
        entityMetaData: dataToSend,
      },
    };
    api
      .put(params)
      .then(
        (response) => {
          this.setState({
            showSuccessDialog: true,
            submitting: false,
          });
          saveEntityMetaData(response.data.config.entityMetaData);
        },
        (error) => {
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
          this.setState({
            formError: error.message,
            showErrorDialog: true,
            submitting: false,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const name = this.tabHeaders[this.state.currentTabIndex];
    const fields = this.state.values[name];
    return this.state.showLoader ? (
      <React.Fragment>
        <h1>{getMessage("entityMetaData.heading")}</h1>
        <Loader />
      </React.Fragment>
    ) : (
      <div className="entity-meta-data-page">
        <h1>{getMessage("entityMetaData.heading")}</h1>
        <React.Fragment>
          <Dialog
            className="success"
            show={this.state.showSuccessDialog}
            title={getMessage("entityMetaData.success")}
            information={getMessage("entityMetaData.success.information")}
            close={() => {
              this.setState({ showSuccessDialog: false });
            }}
            closeText={getMessage("entityMetaData.okay")}
          />
          <Dialog
            show={this.state.showErrorDialog}
            title={getMessage("entityMetaData.error")}
            information={this.state.formError}
            close={() => this.setState({ showErrorDialog: false })}
            closeText={getMessage("entityMetaData.okay")}
          />
          <Dialog
            show={this.state.showDeleteDialog}
            title={getMessage("entityMetaData.are.you.sure")}
            information={getMessage("entityMetaData.this.key.will.be.deleted")}
            close={() =>
              this.setState({ showDeleteDialog: false, deleteIndex: null })
            }
            closeText={getMessage("entityMetaData.cancel")}
            okText={getMessage("entityMetaData.delete")}
            onOk={this.deleteKey}
          />
          <div className="tab-container">
            <Tabs items={this.tabHeaders} onClick={this.handleOnTabClick} />
            <div className="tab-details">
              <Form>
                <div className={`${name}-tab`}>
                  {fields &&
                    fields.map((field, index) => {
                      const type = field.type;
                      return (
                        <div key={`fragment-${index}`}>
                          <div className="key-container" key={`input-${index}`}>
                            <Input
                              key={`field-${index}`}
                              type="text"
                              name={`text-${index}`}
                              label={getMessage("entityMetaData.textLabel")}
                              placeholder={getMessage(
                                "entityMetaData.text.placeholder"
                              )}
                              {...this.generateStateMappers({
                                stateKeys: [name, index, "name"],
                                validationTypes: VALIDATION_TYPES.ONSUBMIT,
                                loseEmphasisOnFill: true,
                              })}
                            />
                            <Select
                              name={`select-${index}`}
                              placeholder={getMessage(
                                "entityMetaData.type.placeholder"
                              )}
                              label={getMessage("entityMetaData.type")}
                              key={`select-${index}`}
                              options={ConfigTypes}
                              {...this.generateStateMappers({
                                stateKeys: [name, index, "type"],
                                loseEmphasisOnFill: true,
                              })}
                            />
                            {(type === "enum" ||
                              type === "multiValued Enum") && (
                              <MultiTextInput
                                className="enum-fields"
                                label={getMessage("enitityMetaData.values")}
                                placeholder={getMessage(
                                  "enitityMetaData.values.placeholder"
                                )}
                                name={`enum-${index}`}
                                {...this.generateStateMappers({
                                  stateKeys: [name, index, "typeMeta"],
                                  loseEmphasisOnFill: true,
                                })}
                              />
                            )}
                            {name === "product" && (
                              <Checkbox
                                label={getMessage(
                                  "entityMetaData.checkboxLabel"
                                )}
                                className="checkbox"
                                name={`filter-checkbox-${index}`}
                                {...this.generateStateMappers({
                                  stateKeys: [name, index, "indexed"],
                                  loseEmphasisOnFill: true,
                                })}
                              />
                            )}
                            {name === "product" && (
                              <Checkbox
                                label={getMessage(
                                  "entityMetaData.private.checkboxLabel"
                                )}
                                className="checkbox"
                                name={`filter-checkbox-private-${index}`}
                                {...this.generateStateMappers({
                                  stateKeys: [name, index, "isPrivate"],
                                  loseEmphasisOnFill: true,
                                })}
                              />
                            )}
                            {name === "product" && (
                              <Checkbox
                                label={getMessage(
                                  "entityMetaData.searchable.checkboxLabel"
                                )}
                                className="checkbox"
                                name={`filter-checkbox-searchable-${index}`}
                                {...this.generateStateMappers({
                                  stateKeys: [name, index, "searchable"],
                                  loseEmphasisOnFill: true,
                                })}
                              />
                            )}
                            <button
                              type="button"
                              className="delete-button"
                              onClick={() =>
                                this.setState({
                                  showDeleteDialog: true,
                                  deleteIndex: index,
                                })
                              }
                            >
                              {getMessage("entityMetadata.delete")}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="add-button">
                  <button
                    type="button"
                    className="button"
                    onClick={this.addLayout}
                  >
                    {getMessage("entityMetadata.add")}
                  </button>
                </div>
                <div className="form-actions">
                  <SubmitButton disabled={this.state.submitting}>
                    {getMessage("entityMetaData.submitText")}
                  </SubmitButton>
                  <button
                    className="button"
                    type="button"
                    onClick={() => this.props.history.goBack()}
                  >
                    {getMessage("entityMetaData.cancel")}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default EntityMetaData;
