import React from "react";
import {
  BaseForm,
  Select,
  VALIDATION_TYPES,
  Searchable,
  BrandSearch,
  CategorySearch,
  Input,
} from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";
import { isExtensionEnabled } from "../../../../lib/auth";

export default class BannerFilters extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    const { stores, multiStoreEnabled, pages, availableLangs } =
      this.props.options;
    const { pageType } = this.state.values;
    return (
      <Form>
        <div className="form-fields">
          {pages && (
            <Select
              label={getMessage("banners.select.page.type")}
              placeholder={getMessage("banners.select.page.type.placeholder")}
              name="pageType"
              options={pages}
              {...this.generateStateMappers({
                stateKeys: ["pageType"],
                defaultValue: "home",
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {multiStoreEnabled && stores && (
            <Select
              label={getMessage("banners.stores")}
              placeholder={getMessage("banners.stores.placeholder")}
              name="store"
              options={stores
                .filter((store) => store.hasPicking || store.hasSelfCheckout)
                .map((store) => {
                  return {
                    text: store.name,
                    value: store.id,
                  };
                })}
              {...this.generateStateMappers({
                stateKeys: ["storeId"],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {isExtensionEnabled("MultiLingualSupport") && (
            <Select
              label={getMessage("banners.languages")}
              name="language"
              placeholder={getMessage("banners.languages.placeholder")}
              className="language"
              options={(availableLangs || []).map((language) => {
                return {
                  text: language.name,
                  value: language.code,
                };
              })}
              {...this.generateStateMappers({
                stateKeys: ["language"],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {pageType === "CATEGORY" && (
            <CategorySearch
              label={getMessage("banners.select.category.heading")}
              placeholder={getMessage("banners.select.category.placeholder")}
              name="category"
              {...this.generateStateMappers({
                stateKeys: ["CATEGORY"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage(
                  "product.form.category.requiredMessage"
                ),
              }}
            />
          )}
          {pageType === "BRAND" && isExtensionEnabled("MultiBrandSupport") && (
            <BrandSearch
              label={getMessage("banners.select.brand.heading")}
              placeholder={getMessage("banners.select.brand.placeholder")}
              name="brand"
              {...this.generateStateMappers({
                stateKeys: ["BRAND"],
              })}
            />
          )}
          {pageType === "PRODUCT_TAG" &&
            isExtensionEnabled("ProductTagSupport") && (
              <Searchable
                label={getMessage("banners.select.tag.heading")}
                placeholder={getMessage("banners.select.tag.placeholder")}
                name="tag"
                searchUrl="/catalogue-service/tag"
                valueKey="id"
                responseKey="tag"
                nameKey="name"
                searchKey="name"
                transformResponse={(response) => response.data.tag || []}
                createButton={getMessage("product.form.tag.addButton")}
                {...this.generateStateMappers({
                  stateKeys: ["PRODUCT_TAG"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
            )}
          {pageType === "SEARCH" && (
            <Input
              label={getMessage("banners.select.search.term")}
              placeholder={getMessage("banners.select.search.term.placeholder")}
              name="name"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["SEARCH_TERM"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            />
          )}
        </div>

        <SubmitButton>{getMessage("shifts.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("shifts.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
