import React from "react";
import { getMessage } from "../../../lib/translator";
import { Toggle } from "../../../components/Form";
import RichEditor from "../../../components/RichEditor";
import closeAnnouncementIcon from "./announcementBar-close-icon.svg";
import { get } from "../../../lib/storage";

export default function AnnouncementBar(props) {
  const {
    announcementBar,
    isAnnouncementBarEnabled,
    handleAnnouncementBarSave,
    announcementBarSubmitting,
    handleOnChange,
    description,
    updateState,
  } = props;
  return (
    <div>
      <div className="announcement-text">
        {getMessage("themes.customize.announcementBar.text")}
        <Toggle
          name="announcementBar"
          className="announcementBar-toggle"
          value={isAnnouncementBarEnabled}
          onChange={() => {
            handleOnChange();
          }}
          icons={false}
        />
      </div>
      {isAnnouncementBarEnabled && (
        <div className="themes-color-customize announcement">
          <div className="announcement-container">
            <div className="rich-edit">
              <RichEditor
                key={`content`}
                placeholder={getMessage(
                  "themes.customize.announcementBar.placeHolder"
                )}
                value={description}
                onChange={(value) => updateState(["description"], value)}
                allowedMethods={[
                  "BOLD",
                  "ITALIC",
                  "LINK",
                  "STRIKETHROUGH",
                  "UNDERLINE",
                ]}
              />
            </div>
          </div>
          <div className="preview-container announcement">
            <span className="preview-text">
              {getMessage("themes.customize.preview")}
            </span>
            <div className="button-preview announcement">
              <pre
                dir={get("dataLang") === "ar" ? "rtl" : "ltr"}
                className="preview"
                dangerouslySetInnerHTML={{
                  __html: announcementBar.description,
                }}
              />
              <pre
                className="previewTwo"
                dangerouslySetInnerHTML={{
                  __html: `<img
                  src=${closeAnnouncementIcon}
                  alt="close icon"
                  height=${18}
                />`,
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="button-container">
        <button className="cancel-button">{getMessage("cancel.text")}</button>
        <button
          className={`save-button `}
          onClick={() => {
            handleAnnouncementBarSave();
          }}
        >
          {announcementBarSubmitting ? ". . ." : getMessage("save.text")}
        </button>
      </div>
    </div>
  );
}
