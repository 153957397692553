import React from "react";
import imgHorizontal from "./imageHorizontal.png";

export default function RichText(props) {
  return (
    <div>
      <img src={imgHorizontal} alt="" />
    </div>
  );
}
