import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import PlacesWithStandaloneSearchBox from "../../../../../components/StandaloneSearchBox";
import { Input, Select } from "../../../../../components/Form";
import ActionButtons from "../ActionButtons";
import { Dialog } from "../../../../../components/Popup";

import API from "../../../../../lib/api";
import { getCurrency } from "../../../../../lib/userDetails";
import { getMessage } from "../../../../../lib/translator";
import { getSingularPluralForm } from "../../../../../lib/commonlyused";
import deleteIcon from "../icon-dustbin.svg";
import emptyIcon from "../../KMLUpload/trip-upload.svg";

import "./style.css";
import { DeliveryFeeFormulaForm } from "..";

const preventDefaultBehavior = (e) => {
  e && e.preventDefault && e.preventDefault();
  e && e.stopPropagation && e.stopPropagation();
};

const handleKeyDown = (e) => {
  if (e.which === 13) {
    preventDefaultBehavior(e);
  }
};

const ApartmentList = ({
  apartments = [],
  editable,
  onDelete,
  onAddapartment,
  onDeleteZone,
}) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const createHandleDelete = (index) => (e) => {
    preventDefaultBehavior(e);
    onDelete(index);
  };

  const handleAddPincode = (e) => {
    preventDefaultBehavior(e);
    onAddapartment();
  };

  const handleShowDeletePopup = (e) => {
    preventDefaultBehavior(e);
    setShowDeletePopup(true);
  };

  const hanldeDeleteZone = (e) => {
    preventDefaultBehavior(e);
    setShowDeletePopup(false);
    onDeleteZone();
  };

  return (
    <Fragment>
      {apartments?.map(({ name, pincode, legendColor }, index) => {
        return (
          <div
            className={"pincode-item" + (editable ? " edit" : "")}
            key={`pincode-${name}-${pincode}-${index}`}
          >
            {!editable && (
              <span
                className="color-legend"
                style={{ backgroundColor: legendColor }}
              />
            )}
            <span>{name + ", " + pincode.toString().padStart(6, "0")}</span>
            {editable && (
              <img
                src={deleteIcon}
                onClick={createHandleDelete(index)}
                alt="delete icon"
              />
            )}
          </div>
        );
      })}
      {editable && (
        <Fragment>
          <button className="add-btn-dotted" onClick={handleAddPincode}>
            + Add apartment
          </button>
          <button className="delete-group-btn" onClick={handleShowDeletePopup}>
            Delete Group
          </button>
        </Fragment>
      )}
      {showDeletePopup && (
        <Dialog
          show={showDeletePopup}
          className="delivery-group-delete-modal"
          title={getMessage("extensions.deliveryArea.delete.title")}
          close={() => setShowDeletePopup(false)}
          information={getMessage("extensions.deliveryArea.delete.warning")}
          closeText="Cancel"
          okText="Delete"
          onOk={hanldeDeleteZone}
        />
      )}
    </Fragment>
  );
};

const PinCodesDisplay = (props) => {
  const { apartmentGroups, distinctColors } = props;
  const pincodeDisplayRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const [isContentOverflown, setIsContentOverflown] = useState(false);
  const [containerHeight, setContainerHeight] = useState();

  useEffect(() => {
    if (pincodeDisplayRef) {
      const domEl = pincodeDisplayRef.current;
      const containerHeight = domEl.clientHeight;
      const contentHeight = domEl.querySelector(
        ".pincode-group-list-content"
      ).offsetHeight;
      if (contentHeight > containerHeight) {
        setIsContentOverflown(true);
        setContainerHeight(contentHeight + 10); // Adding 10 to match margins
      }
    }
  }, [apartmentGroups]);

  const toggleExpand = (e) => {
    e && e.preventDefault && e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <div className="pincode-display-table">
      <div
        className={"pincode-group-list" + (expanded ? " expanded" : "")}
        style={expanded ? { maxHeight: containerHeight + "px" } : {}}
        ref={pincodeDisplayRef}
      >
        <div className="pincode-group-list-content">
          {apartmentGroups &&
            apartmentGroups.map((group, index) => {
              return (
                <div
                  className="pincode-group-wrapper"
                  key={`pincode-group-${index}`}
                >
                  <div className="pincode-group-header">
                    <span>
                      {group.name
                        ? group.name
                        : getMessage("extensions.deliveryArea.zone.unnamed")}
                    </span>
                    <span>{`${getCurrency().symbol} ${
                      group.area.deliveryFee
                    }`}</span>
                  </div>
                  <div className="pincode-group-subheader">
                    <span>
                      {getSingularPluralForm(
                        group.area.building?.length,
                        "apartment"
                      )}
                    </span>
                    <span>
                      {getMessage("extensions.deliveryArea.deliveryFee")}
                    </span>
                  </div>
                  <div className="pincode-group">
                    <ApartmentList
                      apartments={group.area.building}
                      distinctColors={distinctColors}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {isContentOverflown && (
        <button className="toggle-expand" onClick={toggleExpand}>
          Show {expanded ? "less" : "more"}
        </button>
      )}
    </div>
  );
};

const AddForm = ({ value = {}, onChange, onDeleteZone, setNewLocation }) => {
  const [continuedAddition, setContinuedAddition] = useState(false);
  const [addBuilding, setAddBuilding] = useState(false);
  const [tempBuildingData, setTempBuildingData] = useState({});

  const handleChange = (newValue) => {
    onChange({ ...value, ...newValue });
  };

  const handleDeleteapartment = (index) => {
    const newBuildings = value.building?.filter((_, i) => i !== index);
    handleChange({ building: newBuildings });
  };

  const handleSubmit = (e) => {
    e && e.preventDefault();
    if (addBuilding) {
      const newBuildigData = [...value.building, tempBuildingData];
      handleChange({ building: newBuildigData });
      setAddBuilding(false);
      setTempBuildingData(undefined);
    } else {
      setContinuedAddition(true);
    }
  };

  const handlePlaceSearch = (data) => {
    if (data && data[0] && data[0].geometry && data[0].geometry.location) {
      const location = data[0].geometry.location;
      const latitude = location.lat();
      const longitude = location.lng();
      const name = data[0].name;
      const addressComponents = data[0].address_components;
      const city = addressComponents?.filter(({ types }) =>
        types?.includes("locality")
      )[0]?.short_name;
      const pincode = addressComponents?.filter(({ types }) =>
        types?.includes("postal_code")
      )[0]?.short_name;

      const building = [
        {
          ...(value.building && value.building[0]),
          name,
          pincode,
          latitude,
          longitude,
        },
      ];

      if (addBuilding) {
        setTempBuildingData(building[0]);
      } else {
        handleChange({ building, city });
      }
    }
  };

  const createBuildingValuesChangeHandler = (key) => (val) => {
    const building = [
      {
        ...(value.building && value.building[0]),
        [key]: val,
      },
    ];
    handleChange({ building });
  };

  const createTempBuildingDataChangeHandler = (key) => (val) => {
    setTempBuildingData({ ...tempBuildingData, [key]: val });
  };

  useEffect(() => {
    if (continuedAddition && value.building) {
      const buildingLatlng = value.building?.map(({ latitude, longitude }) => ({
        lat: latitude,
        lng: longitude,
      }));
      setNewLocation(buildingLatlng);
    }

    if (value.building && !value.building?.length) {
      setContinuedAddition(false);
    }
  }, [continuedAddition, value.building, setNewLocation]);

  const canBeSubmitted =
    value.building &&
    value.building?.filter(({ latitude, longitude }) => latitude && longitude)
      .length === value.building?.length;

  return (
    <form onSubmit={handleSubmit}>
      {(!continuedAddition || addBuilding) && (
        <div className="input-wrapper">
          <PlacesWithStandaloneSearchBox
            element={
              <input
                placeholder="Search apartment name"
                name="landmark"
                type="text"
                onKeyDown={handleKeyDown}
                className="standalone-searchbox"
              />
            }
            onPlacesSearched={handlePlaceSearch}
          />
        </div>
      )}
      {!addBuilding && (
        <Input
          placeholder="Zone name"
          name="zone-name"
          type="text"
          value={value.name}
          onChange={(name) => handleChange({ name })}
          required
        />
      )}
      {!continuedAddition && (
        <Input
          placeholder="apartment name"
          name="building"
          type="text"
          value={value.building && value.building[0]?.name}
          onChange={createBuildingValuesChangeHandler("name")}
          required
        />
      )}
      {!addBuilding && (
        <Input
          placeholder="City"
          name="zone-city"
          type="text"
          value={value.city}
          onChange={(city) => handleChange({ city })}
        />
      )}
      {!continuedAddition && (
        <Input
          placeholder="Pincode"
          name="pincode"
          type="text"
          value={value.building && value.building[0]?.pincode}
          onChange={createBuildingValuesChangeHandler("pincode")}
          required
        />
      )}
      {!addBuilding && (
        <DeliveryFeeFormulaForm value={value} onChange={onChange} />
      )}

      {addBuilding && (
        <Fragment>
          <Input
            placeholder="apartment name"
            name="building"
            type="text"
            value={tempBuildingData?.name}
            onChange={createTempBuildingDataChangeHandler("name")}
            required
          />
          <Input
            placeholder="Pincode"
            name="pincode"
            type="text"
            value={tempBuildingData?.pincode}
            onChange={createTempBuildingDataChangeHandler("pincode")}
            required
          />
        </Fragment>
      )}
      {!continuedAddition ? (
        <button
          className="button primary"
          type="submit"
          disabled={!canBeSubmitted}
        >
          + Add Group
        </button>
      ) : !addBuilding ? (
        <div className="pincode-list">
          <span className="section-title">apartments</span>
          <ApartmentList
            apartments={value.building}
            onDelete={handleDeleteapartment}
            onAddapartment={() => setAddBuilding(true)}
            onDeleteZone={onDeleteZone}
            editable
          />
        </div>
      ) : (
        <button
          className="button primary add-submit-btn"
          type="submit"
          disabled={!canBeSubmitted}
        >
          + Add apartment
        </button>
      )}
    </form>
  );
};

const EditForm = ({
  value = {},
  onChange,
  pincodeGroup,
  action,
  onDeleteZone,
  setNewLocation,
}) => {
  const [selectedGroup, setSelectedGroup] = useState();
  const [addBuilding, setAddBuilding] = useState(false);
  const [tempBuildingData, setTempBuildingData] = useState({});

  const groups =
    pincodeGroup &&
    pincodeGroup.map(({ name, id }) => ({
      text: name || getMessage("extensions.deliveryArea.zone.unnamed"),
      value: id,
    }));
  const isEditable = action === "add" || selectedGroup;

  const handleSelectGroup = (groupId) => {
    const groupData = pincodeGroup.filter(({ id }) => id === Number(groupId));
    setSelectedGroup(groupId);
    onChange(groupData[0], true);
  };

  const handlePlaceSearch = (data) => {
    if (data && data[0] && data[0].geometry && data[0].geometry.location) {
      const location = data[0].geometry.location;
      const latitude = location.lat();
      const longitude = location.lng();
      const name = data[0].name;
      const addressComponents = data[0].address_components;
      const pincode = addressComponents?.filter(({ types }) =>
        types?.includes("postal_code")
      )[0]?.short_name;

      setTempBuildingData({
        ...tempBuildingData,
        name,
        pincode,
        latitude,
        longitude,
      });
    }
  };

  const handleChange = (newValue, replace) => {
    if (replace) {
      onChange({ ...newValue });
    } else {
      onChange({ ...value, ...newValue });
    }
  };

  const handleDeleteapartment = (index) => {
    const newPincodes = value.area.building?.filter((_, i) => i !== index);
    handleChange({
      area: {
        ...value.area,
        building: newPincodes,
      },
    });
  };

  const handleAddapartmentSubmit = (e) => {
    e && e.preventDefault();
    const newBuildigData = [...value.area.building, tempBuildingData];
    handleChange({
      area: {
        ...value.area,
        building: newBuildigData,
      },
    });
    setAddBuilding(false);
    setTempBuildingData(undefined);
  };

  const createTempBuildingDataChangeHandler = (key) => (val) => {
    setTempBuildingData({ ...tempBuildingData, [key]: val });
  };

  useEffect(() => {
    if (value.area?.building) {
      const buildingLatlng = value.area.building?.map(
        ({ latitude, longitude }) => ({ lat: latitude, lng: longitude })
      );
      setNewLocation(buildingLatlng);
    }
  }, [value.area, setNewLocation]);

  return !addBuilding ? (
    <Fragment>
      {action === "edit" && (
        <Select
          name="group-selector"
          placeholder={getMessage("Select Group")}
          options={groups}
          value={selectedGroup}
          onChange={handleSelectGroup}
        />
      )}
      <Input
        placeholder="Zone name"
        name="zone-name"
        type="text"
        value={value.name}
        onChange={(name) => handleChange({ name })}
        readOnly={!isEditable}
      />
      <DeliveryFeeFormulaForm value={value} onChange={onChange} />
      {isEditable && (
        <div className="pincode-list">
          <span className="section-title">apartments</span>
          <ApartmentList
            apartments={value.area?.building}
            onDelete={handleDeleteapartment}
            editable
            onAddapartment={() => setAddBuilding(true)}
            onDeleteZone={onDeleteZone}
          />
        </div>
      )}
    </Fragment>
  ) : (
    <form onSubmit={handleAddapartmentSubmit}>
      <div className="input-wrapper">
        <PlacesWithStandaloneSearchBox
          element={
            <input
              placeholder="Search apartment name"
              name="landmark"
              type="text"
              className="standalone-searchbox"
              onKeyDown={handleKeyDown}
            />
          }
          onPlacesSearched={handlePlaceSearch}
        />
      </div>
      <Input
        placeholder="apartment name"
        name="building"
        type="text"
        value={tempBuildingData?.name}
        onChange={createTempBuildingDataChangeHandler("name")}
        required
      />
      <Input
        placeholder="Pincode"
        name="pincode"
        type="text"
        value={tempBuildingData?.pincode}
        onChange={createTempBuildingDataChangeHandler("pincode")}
        required
      />
      <button className="button primary" type="submit">
        + Add apartment
      </button>
    </form>
  );
};
export default class apartmentFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      data: {},
    };
    this.handleBuildingGroupAddEdit =
      this.handleBuildingGroupAddEdit.bind(this);
    this.toggleAction = this.toggleAction.bind(this);
    this.handleToggleExpand = this.handleToggleExpand.bind(this);
    this.handleDeleteZone = this.handleDeleteZone.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.setError = this.setError.bind(this);
  }

  handleBuildingGroupAddEdit() {
    const { action, data } = this.state;
    const { storeId, fetchAndUpdate, toggleStoreError, setNewLocation } =
      this.props;

    if (!storeId) {
      toggleStoreError();
      return;
    }

    // unseting the existing error by passing empty param
    this.setError();

    let params, requestMethod, url;

    if (action === "add") {
      url = "/logistics-service/delivery-area";
      requestMethod = "post";
      params = { ...data, configType: "BUILDING" };
    } else if (action === "edit") {
      url = `/logistics-service/delivery-area/${data.id}`;
      requestMethod = "put";
      params = {
        configType: data.configType,
        name: data.name,
        building: data.area.building,
        deliveryFee: data?.deliveryFee,
        lowerWeightLimit: data?.lowerWeightLimit,
        ratePerWeightUnit: data?.ratePerWeightUnit,
        chargePercentage: data.chargePercentage,
      };
    }
    params.storeId = Number(storeId);

    const api = new API({ url });
    api[requestMethod](params)
      .then(() => fetchAndUpdate(storeId))
      .then(() => {
        this.setAction();
        this.handleDataChange({});
        setNewLocation([]);
      })
      .catch((error) => {
        this.setError(error.message);
        console.error(error);
      });
  }

  toggleAction(nextAction) {
    return (e) => {
      e && e.preventDefault && e.preventDefault();
      const { action } = this.state;
      if (action === nextAction) {
        this.setAction(undefined);
      } else {
        this.setAction(nextAction);
      }
    };
  }

  setAction(action) {
    this.setState({ action });
  }

  setError(error) {
    this.setState({ error });
  }

  handleDataChange(data) {
    this.setState({ data });
  }

  handleToggleExpand() {
    this.props.handleOpenClose("apartment");
    this.setAction(undefined);
    this.setState({ data: {} });
    this.props.setNewLocation([]);
  }

  handleDeleteZone() {
    const { action, data } = this.state;
    const { storeId, fetchAndUpdate, setNewLocation } = this.props;

    if (action === "add") {
      this.setState({ data: {} });
      this.setAction(undefined);
    } else if (action === "edit") {
      const api = new API({
        url: `/logistics-service/delivery-area/${data.id}`,
      });
      api
        .delete({ id: data.id })
        .then(() => fetchAndUpdate(storeId))
        .then(() => {
          this.setAction(undefined);
          this.setState({ data: {} });
          setNewLocation([]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleCancel() {
    this.setError();
    this.setAction();
    this.handleDataChange({});
    this.props.setNewLocation([]);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.active !== this.props.active && !this.props.active) {
      this.handleCancel();
    }

    if (Boolean(prevState.action) ^ Boolean(this.state.action)) {
      this.props.changeEditMode();
    }
  }

  render() {
    const { action, data, error } = this.state;
    const {
      active,
      apartments = [],
      setNewLocation,
      distinctColors,
      editing,
    } = this.props;
    const hasPincodesSet = Boolean(apartments && apartments.length);

    let colorIndex = 0;
    const apartmentsWithColor = apartments.map((group) => ({
      ...group,
      area: {
        ...group.area,
        building: group.area.building?.map((buildingObj) => {
          const index = colorIndex % distinctColors.length;
          colorIndex++;
          return {
            ...buildingObj,
            legendColor: distinctColors[index]?.value,
          };
        }),
      },
    }));

    const FormComp = action === "add" ? AddForm : EditForm;
    const hidden = editing && !action;
    const canBeSubmitted =
      data.area ||
      (data.building &&
        data.building[0] &&
        data.building[0]?.latitude &&
        data.building[0]?.longitude);
    return (
      <div
        className={`pincode-form apartment-form zones-subsection ${
          active ? "" : "collapsed-section"
        } ${hidden ? "hidden" : ""} ${action ? "edit-mode" : ""}`}
      >
        <span onClick={this.handleToggleExpand} className="section-title">
          {getMessage("extensions.deliveryArea.apartment.heading")}
        </span>
        {!active && (
          <span className="section-subtitle">
            {hasPincodesSet
              ? `${getSingularPluralForm(apartments.length, "zone")} covered`
              : "No zone defined"}
          </span>
        )}
        <span onClick={this.handleToggleExpand} className="open-close-icon">
          {active ? "\u2013" : "+"}
        </span>
        {!(hasPincodesSet || action) ? (
          active && (
            <div className="zones-content empty">
              <img src={emptyIcon} alt="no-pincode" className="empty-section" />
              <button
                onClick={this.toggleAction("add")}
                className="button primary"
              >
                + Add New Apartment
              </button>
            </div>
          )
        ) : (
          <div className="zones-content">
            {active && !action && (
              <div className="header-action">
                <button
                  className={
                    "header-action-btn" + (action === "edit" ? " active" : "")
                  }
                  onClick={this.toggleAction("edit")}
                >
                  Edit
                </button>
                <button
                  className={
                    "header-action-btn" + (action === "add" ? " active" : "")
                  }
                  onClick={this.toggleAction("add")}
                >
                  Add New
                </button>
              </div>
            )}
            {action && (
              <FormComp
                value={data}
                action={action}
                onChange={this.handleDataChange}
                clearTempData={this.clearTempData}
                pincodeGroup={apartments}
                onDeleteZone={this.handleDeleteZone}
                setNewLocation={setNewLocation}
              />
            )}

            {!action && active && apartments && apartments.length > 0 && (
              <PinCodesDisplay apartmentGroups={apartmentsWithColor} />
            )}
          </div>
        )}
        {action && (
          <ActionButtons
            onSubmit={this.handleBuildingGroupAddEdit}
            onCancel={this.handleCancel}
            error={error}
            disabled={!canBeSubmitted}
            setError={this.setError}
          />
        )}
      </div>
    );
  }
}
