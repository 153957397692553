import React, { Component } from "react";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { Row, Cell } from "../../../components/Table";
import { getMessage } from "../../../lib/translator";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import Image from "../../../components/Image";
import SearchConfigForm from "./Form";
import EmptyIcon from "./empty.svg";
import { getProductImage, getProductName } from "../../../lib/commonlyused";
import { getPrintableTime } from "../../../lib/datetime";

const emptyState = {
  icon: EmptyIcon,
  message: getMessage("search-configuration.emptystate.message"),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD);
      }}
    >
      + {getMessage("search-configuration.add.button.label")}
    </button>
  ),
};

const categoryTableProperties = (pageType, isMobileView) => ({
  headers: [
    getMessage("search-configuration.header.name"),
    getMessage("sc.header.pdtname"),
    getMessage("Position"),
    getMessage("search-configuration.header.actions"),
  ],
  row: ({
    id,
    products,
    category,
    tag,
    brand,
    url,
    onAction,
    validFrom,
    validTo,
  }) => {
    let entity = category || tag || brand || url || [];
    let names = entity.map((ent) => ent.name).join(", ");
    if (pageType === "SEARCH") {
      names = url && url.join(", ");
    }
    return (
      <Row>
        <Cell className="name">
          <div>
            <div className="search-config-name">
              <span
                onClick={() => {
                  onAction(TABLE_ACTIONS.EDIT, { id });
                }}
              >
                {names}
              </span>
            </div>
            <span className="search-config-time">
              {validFrom && validTo && (
                <small className="text-muted">{`${getPrintableTime(
                  validFrom
                )} - ${getPrintableTime(validTo)}`}</small>
              )}
              {validFrom && !validTo && (
                <small className="text-muted">{`${getMessage(
                  "Valid from"
                )}: ${getPrintableTime(validFrom)}`}</small>
              )}
              {!validFrom && validTo && (
                <small className="text-muted">{`${getMessage(
                  "Valid till"
                )}: ${getPrintableTime(validTo)}`}</small>
              )}
            </span>
          </div>
        </Cell>
        <Cell className="search-config-product-name">
          {!isMobileView ? (
            <span>
              {products &&
                products
                  .filter((_, index) => index < 5)
                  .map((product, position) => (
                    <div
                      className="product-details"
                      key={`product-${position}`}
                    >
                      <Image size="sm" src={getProductImage(product)} />
                      <div className="product-name">
                        {getProductName(product)}
                      </div>
                    </div>
                  ))}
            </span>
          ) : (
            <span>
              <span className="header-product">{`${getMessage(
                "sc.header.pdtname"
              )}`}</span>
              {products &&
                products
                  .filter((_, index) => index < 5)
                  .map((product, position) => (
                    <div
                      className="product-details"
                      key={`product-${position}`}
                    >
                      <span className="search-config-image">
                        <Image size="sm" src={getProductImage(product)} />
                      </span>
                      <div className="product-name">
                        {getProductName(product)}
                      </div>
                    </div>
                  ))}
            </span>
          )}
        </Cell>
        <Cell className="search-config-product-number">
          {!isMobileView ? (
            <span>
              {products &&
                products
                  .filter((_, index) => index < 5)
                  .map((_, i) => (
                    <div
                      className="product-name align-right"
                      key={`sequence-${i}`}
                    >
                      <small className="text-muted">{i + 1}</small>
                    </div>
                  ))}
            </span>
          ) : (
            <span>
              <span className="header-position">{`${getMessage(
                "Position"
              )}`}</span>
              {products &&
                products
                  .filter((_, index) => index < 5)
                  .map((_, i) => (
                    <div
                      className="product-name align-right"
                      key={`sequence-${i}`}
                    >
                      <small className="text-muted">{i + 1}</small>
                    </div>
                  ))}
            </span>
          )}
        </Cell>
        <Cell className="search-config-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.EDIT, { id });
              }}
            >
              {getMessage("search-configuration.action.edit")}
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.DELETE, { id });
              }}
            >
              {getMessage("search-configuration.action.delete")}
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    );
  },
});

function getMobileView() {
  return window.screen.width <= 480;
}

const tableProperties = (isMobileView) => {
  return {
    CATEGORY: categoryTableProperties("CATEGORY", isMobileView),
    SEARCH: categoryTableProperties("SEARCH", isMobileView),
    BRAND: categoryTableProperties("BRAND", isMobileView),
    TAG: categoryTableProperties("TAG", isMobileView),
  };
};
export default class ListingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: getMobileView(),
    };
    this.mobileView = this.mobileView.bind(this);
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() });
  }

  componentDidMount() {
    window.addEventListener("resize", this.mobileView, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.mobileView, false);
  }

  render() {
    const { pageType = "CATEGORY" } = this.props;
    return (
      <ListingPage
        api={{
          url: "/catalogue-service/product-ranking",
          params: {
            pageType,
            include: "entity",
          },
          transform: (response) => {
            let productRanking = response.data.productRanking;
            let product = response.data.product || [];
            let category = response.data.category || [];
            let brand = response.data.brand || [];
            let tag = response.data.tag || [];
            if (Array.isArray(productRanking) && productRanking.length > 0) {
              productRanking.forEach((item) => {
                let pdtIds = item.productIds;
                if (pdtIds) {
                  let pdts = pdtIds
                    .map(
                      (id) => product.filter((product) => product.id === id)[0]
                    )
                    .filter(Boolean);
                  item.products = pdts;
                }
                let slugs = item.url || [];
                if (item.pageType === "CATEGORY") {
                  item.category = slugs
                    .map(
                      (slug) => category.filter((cat) => cat.slug === slug)[0]
                    )
                    .filter(Boolean);
                } else if (item.pageType === "BRAND") {
                  item.brand = slugs
                    .map((slug) => brand.filter((brn) => brn.slug === slug)[0])
                    .filter(Boolean);
                } else if (item.pageType === "TAG") {
                  item.tag = slugs
                    .map((slug) => tag.filter((tg) => tg.slug === slug)[0])
                    .filter(Boolean);
                }
                if (item.validFrom) {
                  let parts = item.validFrom.split(" ");
                  item.validFromDate = parts[0];
                  item.validFromTime = parts[1];
                }
                if (item.validTo) {
                  let parts = item.validTo.split(" ");
                  item.validToDate = parts[0];
                  item.validToTime = parts[1];
                }
              });
            } else {
              if (category.length > 0) {
                productRanking.category = category;
              } else if (brand.length > 0) {
                productRanking.brand = brand;
              } else if (tag.length > 0) {
                productRanking.tag = tag;
              }
              if (productRanking.validFrom) {
                let parts = productRanking.validFrom.split(" ");
                productRanking.validFromDate = parts[0];
                productRanking.validFromTime = parts[1];
              }
              if (productRanking.validTo) {
                let parts = productRanking.validTo.split(" ");
                productRanking.validToDate = parts[0];
                productRanking.validToTime = parts[1];
              }
              let pdtIds = productRanking.productIds;
              if (pdtIds) {
                let pdts = pdtIds
                  .map(
                    (id) => product.filter((product) => product.id === id)[0]
                  )
                  .filter(Boolean);
                productRanking.products = pdts;
              }
            }
            return productRanking;
          },
          updateApiParams: (prevParams, newParams) => {
            if (prevParams.pageType !== newParams.pageType) {
              return {
                shouldUpdate: true,
                params: {
                  pageType: newParams.pageType,
                },
              };
            }
          },
        }}
        modalClassName="search-config-popup"
        className={"search-config-listingpage"}
        emptyState={emptyState}
        addHeading={getMessage("search-configuration.form.heading")}
        editHeading={getMessage("sc.edit.heading")}
        headerActions={({ onAction }) => (
          <button
            className="primary button"
            onClick={() => {
              onAction(TABLE_ACTIONS.ADD);
            }}
          >
            +{" "}
            <span className="text">
              {getMessage("search-configuration.add.button.label")}
            </span>
          </button>
        )}
        form={{
          component: SearchConfigForm,
          options: {
            page: pageType.toLowerCase(),
          },
          transformSubmit: (formData) => {
            let result = JSON.parse(JSON.stringify(formData));
            if (result.products) {
              result.productIds = result.products.map((pdt) => pdt.id);
              delete result.products;
            }
            if (result.category) {
              result.url = result.category
                .map((cat) => cat.slug)
                .filter(Boolean);
              delete result.category;
            }
            if (result.brand) {
              result.url = result.brand.map((brn) => brn.slug).filter(Boolean);
              delete result.brand;
            }
            if (result.tag) {
              result.url = result.tag.map((tag) => tag.slug).filter(Boolean);
              delete result.tag;
            }
            if (result.validFromDate) {
              if (!result.validFromTime) {
                result.validFromTime = "00:00:00";
              }
              result.validFrom = `${result.validFromDate} ${result.validFromTime}`;
            }
            if (result.validToDate) {
              if (!result.validToTime) {
                result.validToTime = "00:00:00";
              }
              result.validTo = `${result.validToDate} ${result.validToTime}`;
            }
            delete result.product;
            return result;
          },
          overwriteWithApiParams: false,
          sendformData: true,
          transformResponse: (response) => {
            let productRanking = response.data.productRanking || {};
            productRanking.category =
              response.data.category && response.data.category[0];
            productRanking.product = response.data.product[0];
            return productRanking;
          },
          filterBeforeAdding: (response, _this) => {
            if (response.pageType === pageType) {
              return response;
            }
            return null;
          },
        }}
        tableProperties={
          tableProperties(this.state && this.state.isMobileView)[pageType]
        }
      />
    );
  }
}
