import React from "react";
import { BaseForm, Select } from "../../../../components/Form";
import API from "../../../../lib/api";
import { Consumer } from "../Context";
import { Dialog, Popup } from "../../../../components/Popup";
import { DropDown, DropDownItem, ICONS } from "../../../../components/DropDown";
import { TABLE_ACTIONS } from "../../../../containers/ListingPage";
import VehicleReplaceForm from "../VehicleReplaceForm";
import moment from "moment";
import { getMessage } from "../../../../lib/translator";
import vehicleIcon from "../Form/4-wheeler.svg";
import "./style.css";
import {
  getStandradDateTime,
  localTimeToUtc,
  utcToLocalTime,
} from "../../../../lib/datetime";

class VehicleListContainer extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      addVehicle: false,
    };
    this.getVehicleNumber = this.getVehicleNumber.bind(this);
    this.updateOnCheckIn = this.updateOnCheckIn.bind(this);
    this.replaceVehicle = this.replaceVehicle.bind(this);
  }
  getVehicleNumber(num) {
    this.setState({ vehicleNumber: num });
  }
  updateOnCheckIn() {
    let checkInApi = new API({ url: "/logistics-service/vehicle-attendance" });
    let params = {
      vehicleId: this.state.vehicleNumber,
      shiftStart: getStandradDateTime(
        localTimeToUtc,
        `${new Date().toISOString().split("T")[0]} ${this.props.shiftStart}`
      ).split(" ")[1],
      shiftEnd: getStandradDateTime(
        localTimeToUtc,
        `${new Date().toISOString().split("T")[0]} ${this.props.shiftEnd}`
      ).split(" ")[1],
      regularityType: this.props.type === "Adhoc" ? "ADHOC" : "REGULAR",
    };
    if (this.props.storeId) {
      params.storeId = this.props.storeId;
    }
    checkInApi
      .post(params)
      .then((response) => {
        this.props.updateOnCheckIn(
          this.state.vehicleNumber,
          response.data.vehicleattendance
        );
        this.setState({ addVehicle: false, vehicleNumber: null });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          formError: error.message,
          showDialog: true,
        });
      });
  }
  updateOnCheckOut(index) {
    let checkOutApi = new API({
      url: `/logistics-service/vehicle-attendance/${this.props.vehicles[index].id}`,
    });
    let params = this.props.vehicles && {
      id: this.props.vehicles[index].id,
      vehicleId: this.props.vehicles[index].vehicleId,
    };
    checkOutApi
      .put(params)
      .then((response) => {
        this.props.updateOnCheckOut(response.data.vehicleattendance);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          formError: error.message,
          showDialog: true,
        });
      });
  }
  replaceVehicle(formData) {
    const pathId = this.props.vehicles.filter(
      (vehicle) => vehicle.vehicleId === parseInt(formData.vehicleId, 10)
    )[0].id;
    let checkOutApi = new API({
      url: `/logistics-service/vehicle-attendance/${pathId}`,
    });
    let params = formData && {
      id: pathId,
      vehicleId: formData.vehicleId,
      replacedBy: formData.replacedBy,
    };
    if (this.props.storeId) {
      params.storeId = this.props.storeId;
    }
    checkOutApi
      .put(params)
      .then((response) => {
        this.setState({ showReplaceForm: false });
        this.props.updateOnReplace(response.data.vehicleattendance);
      })
      .catch((error) => {
        console.error(error);
        this.setState({ formError: error.message, showDialog: true });
      });
  }
  render() {
    let { vehicles, type, availableVehicles, count, date } = this.props;
    let { Form } = this.components;
    return (
      <Consumer>
        {(value) => (
          <div className="vehicle-container-block">
            <div className="vehicle-container-heading">
              <div className="vehicle-count">{count}</div>
              <div className="vehicle-type">
                {getMessage("vehiclePlanner.vehicleShift.vehilceCount", {
                  type,
                })}
              </div>
            </div>
            <div className="vehicle-list">
              {vehicles.length ? (
                <div className="vehicle-number-list">
                  {vehicles.map((vehicle, index) => (
                    <div className="vehicle-number-container" key={vehicle.id}>
                      <div className="vehicle-number">{vehicle.number}</div>
                      <button
                        className="button"
                        onClick={() => this.updateOnCheckOut(index)}
                      >
                        {getMessage("vehiclePlanner.checkout.button")}
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="empty-vehicle-list">
                  <img
                    className="vehicle-icon"
                    src={vehicleIcon}
                    alt="vehicle-icon"
                  />
                  <div className="no-vehicle-text">
                    {getMessage("vehiclePlanner.vehicleList.novehicle", {
                      type,
                    })}
                  </div>
                </div>
              )}
              {!this.state.addVehicle && (
                <button
                  className="primary button add-vehicle-btn"
                  disabled={
                    vehicles.length === count ||
                    date !== moment().format("YYYY-MM-DD")
                  }
                  onClick={() => this.setState({ addVehicle: true })}
                >
                  {getMessage("vehiclePlanner.checkin.button")}
                </button>
              )}
              {count !== 0 &&
                vehicles.length === count &&
                date === moment().format("YYYY-MM-DD") && (
                  <div>
                    <div
                      className="replace-text"
                      onClick={() => this.setState({ showReplaceForm: true })}
                    >
                      {getMessage("vehiclePlanner.vehicleList.replace")}
                    </div>
                  </div>
                )}
              {this.state.addVehicle && (
                <Form>
                  <div className="checkin-vehilce-form">
                    <Select
                      name="vehicle"
                      placeholder={getMessage(
                        "vehiclePlanner.checkin.form.select.plceholder"
                      )}
                      options={availableVehicles.map((vehicle) => ({
                        text: vehicle.number,
                        value: vehicle.id,
                      }))}
                      onChange={this.getVehicleNumber}
                      value={this.state.vehicleNumber}
                    />
                    <div className="checkin-vehicle-btn">
                      <button
                        className="primary button add-vehicle-btn"
                        disabled={
                          this.state.submitting || !this.state.vehicleNumber
                        }
                        onClick={this.updateOnCheckIn}
                      >
                        {getMessage("vehiclePlanner.checkin.form.addbutton")}
                      </button>
                      <button
                        className="button add-vehicle-btn"
                        onClick={() =>
                          this.setState({
                            addVehicle: false,
                            vehicleNumber: null,
                          })
                        }
                      >
                        {getMessage("vehiclePlanner.checkin.form.cancelbutton")}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </div>
            <Popup
              heading={getMessage(
                "vehiclePlanner.replaceVehicle.popup.heading"
              )}
              show={this.state.showReplaceForm}
              close={() => this.setState({ showReplaceForm: false })}
            >
              <VehicleReplaceForm
                vehicles={vehicles}
                availableVehicles={availableVehicles}
                onSubmit={this.replaceVehicle}
              />
            </Popup>
            <Dialog
              show={this.state.showDialog}
              close={() => this.setState({ showDialog: false })}
              information={this.state.formError}
              closeText={getMessage("vehiclePlanner.error.dialog.okay")}
            />
          </div>
        )}
      </Consumer>
    );
  }
}

const VehicleShift = (props) => {
  let {
    shiftStart,
    shiftEnd,
    regularCount,
    adhocCount,
    index,
    id,
    storeId,
    onAction,
    date,
  } = props;
  return (
    <Consumer>
      {(value) => {
        let availableVehicles =
          value.all && value.all.filter((vehicle) => !vehicle.isCheckedIn);
        let checkedInVehilces =
          value.checkedIn &&
          value.checkedIn.filter((vehicle) => {
            let startTime = getStandradDateTime(
              utcToLocalTime,
              `${vehicle.expectedIn.split(" ")[0]} ${
                vehicle.expectedIn.split(" ")[1]
              }`
            ).split(" ")[1];
            let endTime = getStandradDateTime(
              utcToLocalTime,
              `${vehicle.expectedOut.split(" ")[0]} ${
                vehicle.expectedOut.split(" ")[1]
              }`
            ).split(" ")[1];
            return (
              startTime === shiftStart &&
              endTime === shiftEnd &&
              vehicle.outTime === null
            );
          });
        let regularVehicles =
          checkedInVehilces &&
          checkedInVehilces.filter(
            (vehicle) => vehicle.regularityType === "REGULAR"
          );
        let adhocVehicles =
          checkedInVehilces &&
          checkedInVehilces.filter(
            (vehicle) => vehicle.regularityType === "ADHOC"
          );
        regularVehicles =
          regularVehicles &&
          regularVehicles.map((regvehicle) => {
            let idx = value.all.findIndex(
              (vehicle) => vehicle.id === regvehicle.vehicleId
            );
            regvehicle.number = value.all[idx].number;
            return regvehicle;
          });
        adhocVehicles =
          adhocVehicles &&
          adhocVehicles.map((adhvehicle) => {
            let idx = value.all.findIndex(
              (vehicle) => vehicle.id === adhvehicle.vehicleId
            );
            adhvehicle.number = value.all[idx].number;
            return adhvehicle;
          });
        return (
          <div className="vehilce-shift-container">
            <div className="vehicle-shift-header">
              <div className="shift-name-wrapper">
                <div className="shift-name">{`Shift ${index + 1}`}</div>
                <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
                  <DropDownItem
                    onClick={() => {
                      onAction(TABLE_ACTIONS.EDIT, { id });
                    }}
                  >
                    {getMessage("vehiclePlanner.action.edit")}
                  </DropDownItem>
                  <DropDownItem
                    onClick={() => {
                      onAction(TABLE_ACTIONS.DELETE, { id });
                    }}
                  >
                    {getMessage("vehiclePlanner.action.delete")}
                  </DropDownItem>
                </DropDown>
              </div>
              <div className="vehicle-shift">
                <div className="vehicle-shift-time">
                  {getMessage("vehiclePlanner.vehicleShift.startTime", {
                    time: moment(shiftStart, ["hh:mm"]).format("hh:mm A"),
                  })}
                </div>
                <div className="vehicle-shift-time">
                  {getMessage("vehiclePlanner.vehicleShift.endTime", {
                    time: moment(shiftEnd, ["hh:mm"]).format("hh:mm A"),
                  })}
                </div>
              </div>
            </div>
            <div className="vehicle-container">
              <VehicleListContainer
                count={regularCount}
                vehicles={regularVehicles || []}
                id={id}
                shiftStart={shiftStart}
                shiftEnd={shiftEnd}
                type="Regular"
                availableVehicles={availableVehicles}
                storeId={storeId}
                updateOnCheckIn={value.updateOnCheckIn}
                updateOnCheckOut={value.updateOnCheckOut}
                updateOnReplace={value.updateOnReplace}
                date={date}
              />
              <VehicleListContainer
                count={adhocCount}
                vehicles={adhocVehicles || []}
                id={id}
                shiftStart={shiftStart}
                shiftEnd={shiftEnd}
                type="Adhoc"
                availableVehicles={availableVehicles}
                storeId={storeId}
                updateOnCheckIn={value.updateOnCheckIn}
                updateOnCheckOut={value.updateOnCheckOut}
                updateOnReplace={value.updateOnReplace}
                date={date}
              />
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default VehicleShift;
