import React from "react";
import { Link } from "react-router-dom";
import ListingPageWithRoutes from "../../../containers/ListingPage/listingRouter";
import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import StoreForm from "./Form";
import storesFilters from "./Filters";
import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { getMessage } from "../../../lib/translator";
import { setStores, getStores, isExtensionEnabled } from "../../../lib/auth";
import { set } from "../../../lib/storage";

import EmptyIcon from "./store-empty.svg";
import "./style.css";

function AddStore() {
  return (
    <div className="text-center">
      <Link to="/settings/stores/add" className="primary button">
        <span />+{" "}
        <span className="text">{getMessage("operations.store.add.text")}</span>
      </Link>
    </div>
  );
}

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage("operations.store.empty.message"),
  additionalViews: [AddStore],
};

const tableProperties = {
  headers: [
    getMessage("operations.store.table.header.name"),
    getMessage("operations.store.table.header.address"),
    getMessage("operations.store.table.header.deliveyHub"),
    getMessage("operations.store.table.header.picking"),
    getMessage("operations.store.table.header.clientId"),
    getMessage("operations.store.table.header.status"),
    getMessage("operations.store.table.header.actions"),
  ],
  row: ({
    id,
    name,
    address,
    clientId,
    onAction,
    hasDeliveryHub,
    hasPicking,
    status,
  }) => (
    <Row>
      <Cell className="store-name-container">
        <Link to={`/settings/stores/edit/${id}`} className="store-name">
          {name || <strong>{getMessage("operations.store.default")}</strong>}
        </Link>
      </Cell>
      <Cell className="store-address">{address}</Cell>
      <Cell className="store-has-delivery-hub">
        <div className="store-mobile-view-header">
          {getMessage("operations.store.table.header.deliveyHub")}
        </div>
        <span>
          {hasDeliveryHub
            ? getMessage("operations.store.table.row.picking.yes")
            : getMessage("operations.store.table.row.picking.no")}
        </span>
      </Cell>
      <Cell className="store-has-picking">
        <div className="store-mobile-view-header">
          {getMessage("operations.store.table.header.picking")}
        </div>
        <span>
          {hasPicking
            ? getMessage("operations.store.table.row.picking.yes")
            : getMessage("operations.store.table.row.picking.no")}
        </span>
      </Cell>
      <Cell className="store-client-id">
        <div className="store-mobile-view-header">
          {getMessage("operations.store.table.header.clientId")}
        </div>
        <span>{clientId}</span>
      </Cell>
      <Cell className="store-status">
        <div className="store-mobile-view-header">
          {getMessage("operations.store.table.header.status")}
        </div>
        <span>
          {status === "ENABLED"
            ? getMessage("operations.store.form.status.enable.label")
            : getMessage("operations.store.form.status.disable.label")}
        </span>
      </Cell>
      <Cell className="store-actions">
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`/settings/stores/edit/${id}`}>
              {getMessage("operations.store.actions.edit")}
            </Link>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              onAction(TABLE_ACTIONS.DELETE, { id });
              let allStores = getStores();
              allStores.splice(
                allStores.findIndex((store) => store.id === Number(id)),
                1
              );
              setStores(allStores);
              allStores &&
                allStores.length > 0 &&
                set("store", allStores[0].id);
            }}
          >
            {getMessage("operations.store.actions.delete")}
          </DropDownItem>
          {status && status === "ENABLED" && (
            <DropDownItem
              onClick={() =>
                onAction(TABLE_ACTIONS.UPDATE, { id }, { status: "DISABLED" })
              }
            >
              {getMessage("operations.store.actions.disable")}
            </DropDownItem>
          )}
          {status && status === "DISABLED" && (
            <DropDownItem
              onClick={() =>
                onAction(TABLE_ACTIONS.UPDATE, { id }, { status: "ENABLED" })
              }
            >
              {getMessage("operations.store.actions.enable")}
            </DropDownItem>
          )}
        </DropDown>
      </Cell>
    </Row>
  ),
};

export default function Store(props) {
  return (
    <ListingPageWithRoutes
      menu={props.menu}
      className="stores-page"
      title={getMessage("operations.store.heading")}
      addHeading={getMessage("operations.store.form.add.heading")}
      editHeading={getMessage("operations.store.form.edit.heading")}
      headerActions={AddStore}
      showLanguageDropDown
      emptyState={EmptyState}
      api={{
        url: "/account-service/store",
        transform: (response) => response.data.store || [],
      }}
      form={{
        component: StoreForm,
        transformSubmit: (formData) => {
          let data = JSON.parse(JSON.stringify(formData));
          let { businessHours } = data;
          let newBusinessHours = {};
          businessHours &&
            Object.keys(businessHours).map((day) => {
              if (businessHours[day]["o"] || businessHours[day]["c"]) {
                newBusinessHours[day] = businessHours[day];
              }
              return null;
            });
          data.businessHours = newBusinessHours;
          if (!Object.keys(data.businessHours).length) {
            data.businessHours = null;
          }
          !isExtensionEnabled("EntityMetaData") && delete data.metaData;
          delete data.configuredPayments;
          return data;
        },
        afterSubmit: (response) => {
          let store = (response.data && response.data.store) || {};
          let storeId = store.id;
          let allStores = getStores();
          let storeIndex = allStores.findIndex((store) => store.id === storeId);
          if (storeIndex > -1) {
            allStores[storeIndex] = store;
          } else {
            if (Object.keys(store).length) {
              allStores.push(store);
            }
          }
          setStores(allStores);
        },
        transformResponse: (response) => {
          let formattedResponse = Object.assign(
            {},
            response.data && response.data.store
          );
          if (formattedResponse.latitude) {
            formattedResponse.latitude = Number(formattedResponse.latitude);
          }
          if (formattedResponse.longitude) {
            formattedResponse.longitude = Number(formattedResponse.longitude);
          }
          if (
            formattedResponse.businessHours &&
            typeof formattedResponse.businessHours === "object"
          ) {
            if (!Object.keys(formattedResponse.businessHours).length) {
              formattedResponse.businessHours = null;
            }
          }
          return formattedResponse;
        },
      }}
      filters={{
        component: storesFilters,
      }}
      tableProperties={tableProperties}
    />
  );
}
