import React from "react";
import { Input } from "../../../../components/Form";
import AppointmentForm from "../../../../components/Layout/AppointmentForm";
import { getMessage } from "../../../../lib/translator";

const captchaAdminURL = "https://www.google.com/recaptcha/admin";

const AppointmentFormLayout = () => {
  return {
    preview: AppointmentForm,
    fields: ({ getState, updateState }) => {
      return (
        <div className="form-sections appointment-form">
          <div className="form-section">
            <Input
              label={getMessage("integrationSettings.googleCaptchaKey.label")}
              placeholder={getMessage(
                "integrationSettings.googleCaptchaKey.placeholder"
              )}
              value={getState(["googleCaptchaKey"]) || ""}
              onChange={(value) => {
                updateState(["googleCaptchaKey"], value);
              }}
            />
            <Input
              label={getMessage(
                "integrationSettings.googleCaptchaSecret.label"
              )}
              placeholder={getMessage(
                "integrationSettings.googleCaptchaSecret.placeholder"
              )}
              value={getState(["googleCaptchaSecret"]) || ""}
              onChange={(value) => {
                updateState(["googleCaptchaSecret"], value);
              }}
            />
            <div className="google-captcha-message">
              {`If you do not have both keys. You can create from`}
              <a
                rel="noopener noreferrer"
                href={`${captchaAdminURL}`}
                target="_blank"
              >
                Here
              </a>
            </div>
          </div>
        </div>
      );
    },
  };
};
export default AppointmentFormLayout;
