import React from "react";
import Image from "../../Image";
import "./style.css";
import RedImage from "./RedPlaceholderImg";
import GreenImage from "./GreenPlaceholderImg";
import { getMessage } from "../../../lib/translator";

export default function DualImageBanner({ data }) {
  const className = "dual-image-banner";
  const { imageUrl1, imageUrl2, title, subTitle, link } = data;

  return (
    <div className={className}>
      <figure className="banner-image">
        {imageUrl1 ? (
          <Image
            className="banner-img"
            src={imageUrl1}
            alt="uploaded image 1"
          />
        ) : (
          <RedImage className="image banner-img banner-img-svg" />
        )}
      </figure>
      <figure className="banner-image">
        {imageUrl2 ? (
          <Image
            className="banner-img"
            src={imageUrl2}
            alt="uploaded image 2"
          />
        ) : (
          <GreenImage className="image banner-img banner-img-svg" />
        )}
      </figure>
      <div className="banner-text-container">
        <div className="banner-img-title">{title}</div>
        <div className="banner-img-sub-title">{subTitle}</div>
        {link && (
          <button className="banner-img-link-btn">
            {getMessage("dualImageBanner.linkText")}
          </button>
        )}
      </div>
    </div>
  );
}
