import React, { Component } from "react";
import Upload from "../../../../components/FileUpload";
import { getMessage } from "../../../../lib/translator";
import csvIcon from "./csv-icon.svg";
import uploadIcon from "./import-customers.svg";
import sampleTags from "./sample.csv";

function downloadCsv() {
  var hiddenElement = document.createElement("a");
  hiddenElement.href = sampleTags;
  hiddenElement.target = "_blank";
  hiddenElement.download = "sample.csv";
  hiddenElement.click();
}

export default class RestrictedProductFileUpload extends Component {
  constructor(props) {
    super(props);
    this.getSampleCsv = this.getSampleCsv.bind(this);
  }

  getSampleCsv(e) {
    e.preventDefault();
    downloadCsv();
  }

  render() {
    return (
      <div className="product-upload">
        <div className="bordered-box">
          <div className="text-center">
            <img src={uploadIcon} width="140" alt="" />
          </div>
          <Upload
            name="tagsImport"
            accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
            placeholder={getMessage("productUpload.uploadFileText")}
            uploadButtonText="Upload"
            cancelText="Cancel"
            uploadKey="uploadfile"
            uploadUrl={this.props.uploadUrl}
            validationStrings={{
              invalidFileType: getMessage("input.invalidFileType"),
            }}
            strings={{
              defaultMessage: getMessage("fileUpload.importCsv.heading"),
              progressMessage: getMessage("fileUpload.importCsv.inprogress"),
              completionMessage: getMessage(
                "product.restrictions.import.successText"
              ),
            }}
            icon={csvIcon}
            userId={this?.props?.id}
            organizationId={this?.props?.organizationId}
          />
        </div>
        <div className="text-center download-sample-text">
          <button className="download-link" onClick={this.getSampleCsv}>
            {getMessage("productUpload.csvDownloadText")}
          </button>
        </div>
      </div>
    );
  }
}
