import React from "react";
import {
  BaseForm,
  Input,
  Radio,
  VALIDATION_TYPES,
} from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";

export default class TagForm extends BaseForm {
  render() {
    const { Form } = this.components;
    const { SubmitButton, CancelButton } = this.buttons;
    return (
      <Form>
        <Input
          label={getMessage("customer-service-tags.form.tag.name.label")}
          placeholder={getMessage(
            "customer-service-tags.form.tag.name.placeholder"
          )}
          name="name"
          required
          type="text"
          {...this.generateStateMappers({
            stateKeys: ["name"],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />

        <Radio
          label={getMessage("customer-service-tags.form.tag.status.label")}
          name="status"
          options={[
            {
              text: getMessage(
                "customer-service-tags.form.tag.status.enabled.label"
              ),
              value: "ENABLED",
            },
            {
              text: getMessage(
                "customer-service-tags.form.tag.status.disabled.label"
              ),
              value: "DISABLED",
            },
          ]}
          {...this.generateStateMappers({
            stateKeys: ["status"],
            defaultValue: "ENABLED",
            loseEmphasisOnFill: true,
          })}
        />

        <Input
          label={getMessage("customer-service-tags.form.description.label")}
          placeholder={getMessage(
            "customer-service-tags.form.description.placeholder"
          )}
          name="description"
          type="text"
          required
          {...this.generateStateMappers({
            stateKeys: ["description"],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />

        <div className="form-actions">
          <SubmitButton>
            {getMessage("customer-service-tags.save")}
          </SubmitButton>
          <CancelButton>
            {getMessage("customer-service-tags.button.cancel")}
          </CancelButton>
        </div>
      </Form>
    );
  }
}
