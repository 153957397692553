import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { Dialog, Popup } from "../../../components/Popup";
import { Input, BaseForm, Toggle } from "../../../components/Form";

import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import ListingPageWithRoutes from "../../../containers/ListingPage/listingRouter";

import { getMessage } from "../../../lib/translator";
import { isExtensionEnabled, getStores } from "../../../lib/auth";
import { getCurrency } from "../../../lib/userDetails";

import API from "../../../lib/api";

import CouponForm from "./Form";
import CouponFilters from "./Filters";
import emptyIcon from "./coupons-empty.svg";
import downloadIcon from "./download.svg";
import { formatDataBeforeSubmit } from "../../../components/SegmentsForm";
import {
  utcToLocalTime,
  getPrintableTime,
  localTimeToUtc,
  getStandradDateTime,
} from "../../../lib/datetime";

import "./style.css";
import moment from "moment";

function AddCoupon() {
  return (
    <div className="text-center">
      <Link to="/marketing/coupons/add" className="button primary">
        + <span className="text">{getMessage("coupon.add.text")}</span>
      </Link>
    </div>
  );
}

const emptyState = {
  icon: emptyIcon,
  message: getMessage("coupon.helpItems.message"),
  additionalViews: [AddCoupon],
};

class CouponActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
    };
    this.showDialog = this.showDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }

  showDialog(id) {
    this.setState({
      showDialog: id,
    });
  }

  hideDialog() {
    this.setState({
      showDialog: false,
    });
  }

  render() {
    const { id, status, route, onAction, startDate } = this.props;
    let dropDownItem = null;
    let currentDate = new Date();
    let startDateFormat = new Date(startDate);
    if (startDateFormat < currentDate) {
      dropDownItem = (
        <DropDownItem>
          <Link to={`/marketing/coupons/view/${id}`}>
            {getMessage("coupon.action.viewAnalytics")}
          </Link>
        </DropDownItem>
      );
    }
    const targetStatus = status === "ENABLED" ? "DISABLED" : "ENABLED";
    return (
      <div>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`/marketing/${route}/edit/${id}`}>
              {getMessage("coupon.action.edit")}
            </Link>
          </DropDownItem>
          <DropDownItem onClick={() => this.showDialog(id)}>
            {status === "ENABLED"
              ? getMessage("coupon.action.disable")
              : getMessage("coupon.action.enable")}
          </DropDownItem>
          {dropDownItem}
        </DropDown>
        {
          <Dialog
            show={this.state.showDialog}
            title={getMessage("coupon.dialog.title")}
            information={
              status === "ENABLED"
                ? getMessage("coupon.disableDialog.information")
                : getMessage("coupon.enableDialog.information")
            }
            onOk={() => {
              onAction(TABLE_ACTIONS.UPDATE, { id }, { status: targetStatus });
              this.hideDialog();
            }}
            close={this.hideDialog}
            closeText={getMessage("coupon.dialog.cancelText")}
            okText={getMessage("coupon.dialog.okText")}
          />
        }
      </div>
    );
  }
}

const tableProperties = (route, apiKey, isMobileView) => {
  return {
    headers: [
      getMessage("coupon.header.coupon"),
      getMessage("coupon.header.discount"),
      getMessage("coupon.header.limit"),
      getMessage("coupon.header.validity"),
      getMessage("coupon.header.status"),
      getMessage("coupon.header.actions"),
    ],
    row: ({
      id,
      couponCode,
      prefix,
      suffix,
      type,
      discount,
      discountType,
      maxRedemption,
      maxSingleRedemption,
      startDate,
      expiryDate,
      comment,
      minOrderAmount,
      maxDiscountAmount,
      validOnPayment,
      validOnUser,
      validOnDays,
      validOnSlots,
      status,
      onAction,
    }) => (
      <Row>
        <Cell className="coupon-code-main">
          {!isMobileView ? (
            <Link to={`/marketing/${route}/edit/${id}`} className="coupon-code">
              {couponCode || `${prefix} ${suffix}`}
            </Link>
          ) : (
            <span className="text-muted">
              {`${getMessage("coupon.header.coupon")}: `}
              <Link
                to={`/marketing/${route}/edit/${id}`}
                className="coupon-code"
              >
                {couponCode || `${prefix} ${suffix}`}
              </Link>
            </span>
          )}
        </Cell>
        <Cell className="coupon-discount">
          {!isMobileView ? (
            <div>
              <div>
                {type === "PERCENT"
                  ? `${Math.round(discount)}%`
                  : `${getCurrency().symbol} ${discount}`}
              </div>
              <small className="text-muted">
                {discountType === "DIRECT_DISCOUNT"
                  ? "Cart Discount"
                  : "Cashback"}
              </small>
            </div>
          ) : (
            <div>
              <span className="text-muted">{`${getMessage(
                "coupon.header.discount"
              )}: `}</span>

              <div className="coupon-discount-percent">
                {type === "PERCENT"
                  ? `${Math.round(discount)}%`
                  : `${getCurrency().symbol} ${discount}`}
              </div>
              <small className="text-muted cart-discount">
                {discountType === "DIRECT_DISCOUNT"
                  ? "Cart Discount"
                  : "Cashback"}
              </small>
            </div>
          )}
        </Cell>
        <Cell className="coupon-limit">
          {!isMobileView ? (
            <div>
              {maxDiscountAmount && (
                <div>{`${getCurrency().symbol} ${maxDiscountAmount}`}</div>
              )}
              {maxSingleRedemption && (
                <small className="text-muted">{`${maxSingleRedemption} per user`}</small>
              )}
            </div>
          ) : (
            <div>
              <span className="text-muted">{`${getMessage(
                "coupon.header.limit"
              )}: `}</span>
              {maxDiscountAmount && (
                <div className="max-discount-amount">{`${
                  getCurrency().symbol
                } ${maxDiscountAmount}`}</div>
              )}
              {maxSingleRedemption && (
                <small className="text-muted per-user">{`${maxSingleRedemption} per user`}</small>
              )}
            </div>
          )}
        </Cell>
        <Cell className="coupon-validity">
          {!isMobileView ? (
            <div>
              <div>
                {getPrintableTime(startDate).split(",")[0]}
                {getPrintableTime(startDate).split(",")[1]}
              </div>
              <small className="text-muted">
                {getPrintableTime(expiryDate).split(",")[0]}
                {getPrintableTime(expiryDate).split(",")[1]}
              </small>
            </div>
          ) : (
            <div>
              <span className="text-muted">{`${getMessage(
                "coupon.header.validity"
              )}: 
              `}</span>
              <div className="coupon-validity-from">
                {getPrintableTime(startDate).split(",")[0]}
              </div>
              <small className="text-muted coupon-validity-to">
                {getPrintableTime(expiryDate).split(",")[0]}
              </small>

              <div className="coupon-validity-to text-muted">
                {getPrintableTime(startDate).split(",")[1]}
              </div>
              <div className="text-muted coupon-validity-to">
                {getPrintableTime(expiryDate).split(",")[1]}
              </div>
            </div>
          )}
        </Cell>
        <Cell className="coupon-status">
          {!isMobileView ? (
            <small className="text-muted">{status}</small>
          ) : (
            <Toggle
              className={status}
              name={status}
              value={status === "ENABLED"}
              icons={false}
              onChange={() => {
                onAction(
                  TABLE_ACTIONS.UPDATE,
                  { id },
                  { status: status === "ENABLED" ? "DISABLED" : "ENABLED" }
                );
              }}
            />
          )}
        </Cell>
        <Cell className="coupon-actions">
          <CouponActions
            onAction={onAction}
            id={id}
            status={status}
            route={route}
            apiKey={apiKey}
            startDate={startDate}
          />
        </Cell>
      </Row>
    ),
  };
};

class BulkCouponMailForm extends BaseForm {
  constructor(props) {
    super(props);
    this.generateCouponOnSubmit = this.generateCouponOnSubmit.bind(this);
  }
  generateCouponOnSubmit(formData) {
    let data = Object.assign({}, formData);
    const api = new API({ url: `/promo-service/bulk-coupon` });
    api
      .get({
        prefix: data.prefix,
        suffix: data.suffix,
      })
      .then((response) => {
        this.props.callbackgenerateCoupon();
      })
      .catch((error) => {
        console.error(error);
        this.setState({ error: error.message });
        this.setState({
          submitting: false,
        });
      });
  }

  onSubmit(formData) {
    this.setState({
      submitting: true,
      error: "",
    });
    this.generateCouponOnSubmit(formData);
  }
  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const error = this.state && this.state.error;
    return (
      <Form>
        {error && (
          <div className="coupon-download-form-error">
            {getMessage("coupon.download.form.error")}
          </div>
        )}
        <Input
          label="Prefix"
          placeholder="Enter the prefix"
          {...this.generateStateMappers({
            stateKeys: ["prefix"],
            loseEmphasisOnFill: true,
          })}
          required
        />
        <Input
          label="Suffix"
          placeholder="Enter the suffix"
          {...this.generateStateMappers({
            stateKeys: ["suffix"],
            loseEmphasisOnFill: true,
          })}
          required
        />
        <SubmitButton> Submit</SubmitButton>
      </Form>
    );
  }
}

function getMobileView() {
  return window.screen.width <= 480;
}

export default class Coupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      showModal: false,
      showDialog: false,
      isMobileView: getMobileView(),
    };
    this.mobileView = this.mobileView.bind(this);
    this.handleOnclickimg = this.handleOnclickimg.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.generateCoupon = this.generateCoupon.bind(this);
  }

  handleOnclickimg() {
    this.setState({
      showModal: true,
    });
  }

  handleModalClose() {
    this.setState({
      showModal: false,
    });
  }

  closeDialog() {
    this.setState({
      showDialog: false,
    });
  }

  generateCoupon() {
    this.setState({
      showModal: false,
      showDialog: true,
    });
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() });
  }
  componentDidMount() {
    window.addEventListener("resize", this.mobileView, false);
    // If multi store extension is enabled, get the stores data
    if (isExtensionEnabled("MultiStoreSupport")) {
      let stores = getStores() || [];
      this.setState({
        stores: stores,
      });
    }
  }
  componentWillUnmount() {
    this.storeApi && this.storeApi.cancel();
    window.removeEventListener("resize", this.mobileView, false);
  }

  render() {
    const multiStoresEnabled = isExtensionEnabled("MultiStoreSupport");
    const { props } = this;
    const route = props.bulk ? "unique-coupons" : "coupons";
    const apiKey = props.bulk ? "bulkCoupon" : "coupon";
    const showModal = this.state.showModal;
    const showDialog = this.state.showDialog;
    return (
      <ListingPageWithRoutes
        menu={props.menu}
        className="coupons-page"
        title={props.title || getMessage("coupon.table.heading")}
        addHeading={props.addHeading || getMessage("coupon.form.add.heading")}
        editHeading={
          props.editHeading || getMessage("coupon.form.edit.heading")
        }
        api={
          props.api || {
            url: "/promo-service/coupon",
            transform: (response) => {
              if (Array.isArray(response.data.coupon)) {
                response.data.coupon.map((coupon) => {
                  if (coupon.startDate) {
                    coupon.startDate = getStandradDateTime(
                      utcToLocalTime,
                      coupon.startDate
                    );
                  }
                  if (coupon.expiryDate) {
                    coupon.expiryDate = getStandradDateTime(
                      utcToLocalTime,
                      coupon.expiryDate
                    );
                  }
                  return coupon;
                });
              }

              return response.data.coupon;
            },
          }
        }
        emptyState={emptyState}
        headerActions={({ onAction }) => (
          <React.Fragment>
            <div className="download_icon">
              <img
                src={downloadIcon}
                alt="Download"
                onClick={this.handleOnclickimg}
              />
            </div>
            <Popup
              heading="Enter Details"
              show={showModal}
              close={this.handleModalClose}
            >
              <BulkCouponMailForm
                callbackgenerateCoupon={this.generateCoupon}
              />
            </Popup>
            {showDialog && (
              <Dialog
                className="success"
                show={showDialog}
                close={this.closeDialog}
                information={getMessage(
                  "Your coupons will be sent to your email!"
                )}
                closeText="OK"
              />
            )}
            <div className="coupon-header">
              <Link
                to="/marketing/unique-coupons/add"
                className="primary button"
              >
                <span className="text">{`+ ${getMessage(
                  "Add unique coupons"
                )}`}</span>
              </Link>
              <Link to={`/marketing/coupons/add`} className="button">
                <span className="text">{`+ ${getMessage(
                  "coupon.add.text"
                )}`}</span>
              </Link>
            </div>
          </React.Fragment>
        )}
        tableProperties={tableProperties(
          route,
          apiKey,
          this.state && this.state.isMobileView
        )}
        form={{
          component: CouponForm,
          options: {
            bulk: props.bulk,
            multiStores: {
              isEnabled: multiStoresEnabled,
              data: this.state.stores,
            },
          },
          transformResponse: (response) => {
            let coupon = response.data[apiKey];
            let newCoupon = Object.assign({}, coupon);
            let validOnDays = [];
            for (let key in coupon["validOnDays"]) {
              if (coupon["validOnDays"][key] === "1") {
                validOnDays.push(key);
              }
            }
            if (coupon["validOnStores"]) {
              coupon["validOnStores"].forEach(function (element, index) {
                newCoupon["validOnStores"][index] = parseInt(element, 10);
              });
            }
            newCoupon["validOnDays"] = validOnDays;
            newCoupon["dateRange"] = {
              startDate: getStandradDateTime(
                utcToLocalTime,
                coupon.startDate
              ).split(" ")[0],
              endDate: getStandradDateTime(
                utcToLocalTime,
                coupon.expiryDate
              ).split(" ")[0],
            };
            newCoupon["timeRange"] = {
              startTime: getStandradDateTime(
                utcToLocalTime,
                coupon.startDate
              ).split(" ")[1],
              endTime: getStandradDateTime(
                utcToLocalTime,
                coupon.expiryDate
              ).split(" ")[1],
            };

            const newDataArray = coupon?.userSet?.data?.map((userSetData) => {
              if (userSetData.date) {
                userSetData.date = getStandradDateTime(
                  utcToLocalTime,
                  userSetData.date
                ).split(" ")[0];
              }
              if (userSetData.startDate) {
                userSetData.startDate = getStandradDateTime(
                  utcToLocalTime,
                  userSetData.startDate
                ).split(" ")[0];
              }
              if (userSetData.endDate) {
                userSetData.endDate = getStandradDateTime(
                  utcToLocalTime,
                  userSetData.endDate
                ).split(" ")[0];
              }
              return userSetData;
            });

            newCoupon["userSet"] = {
              ...coupon.userSet,
              data: newDataArray,
            };
            return newCoupon;
          },
          overwriteWithApiParams: !props.bulk,

          transformSubmit:
            props.transformSubmit ||
            ((formData) => {
              let response = Object.assign({}, formData);
              response.validOnUser = "ALL";
              if (formData["validOnDays"]) {
                delete response["validOnDays"];
                let validOnDays = {};
                formData["validOnDays"].forEach(function (key) {
                  validOnDays[key] = "1";
                });
                response.validOnDays = validOnDays;
              }
              if (formData.dateRange && formData.timeRange) {
                response.startDate = getStandradDateTime(
                  localTimeToUtc,
                  `${formData.dateRange.startDate} ${formData.timeRange.startTime}`
                );
                response.expiryDate = getStandradDateTime(
                  localTimeToUtc,
                  `${formData.dateRange.endDate} ${formData.timeRange.endTime}`
                );
                delete response["dateRange"];
                delete response["timeRange"];
              }
              if (
                this.props.match.params.action === "edit" &&
                moment(response.startDate).isSame(new Date(), "day")
              ) {
                delete response.startDate;
              }
              if (response && response.userSet) {
                // date inside userSet should be sent in YYYY-MM-DD HH:mm:ss format
                const newUserSetData = response?.userSet?.data?.map(
                  (userSetData) => {
                    if (userSetData.date && userSetData.date.length === 10) {
                      userSetData.date = getStandradDateTime(
                        localTimeToUtc,
                        `${userSetData.date} 00:00:00`
                      );
                    }
                    if (
                      userSetData.startDate &&
                      userSetData.startDate.length === 10
                    ) {
                      userSetData.startDate =
                        userSetData.startDate === moment().format("YYYY-MM-DD")
                          ? getStandradDateTime(
                              localTimeToUtc,
                              `${moment().format("YYYY-MM-DD")} 00:00:00`
                            )
                          : getStandradDateTime(
                              localTimeToUtc,
                              `${userSetData.startDate} 00:00:00`
                            );
                    }
                    if (
                      userSetData.endDate &&
                      userSetData.endDate.length === 10
                    ) {
                      userSetData.endDate = getStandradDateTime(
                        localTimeToUtc,
                        `${userSetData.endDate} 23:59:59`
                      );
                    }
                    return userSetData;
                  }
                );

                if (response.userSet.data) {
                  response.userSet.data = newUserSetData;
                }
                response = formatDataBeforeSubmit(response);
              }
              // remove all null or undefined values from response
              for (let i in response) {
                if (response[i] === null || response[i] === undefined) {
                  delete response[i];
                }
              }
              return response;
            }),
        }}
        filters={{
          component: CouponFilters,
          forceShow: true,
          options: {
            bulk: this.props.bulk,
          },
        }}
      />
    );
  }
}
