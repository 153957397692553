import React from "react";
import {
  BaseForm,
  Input,
  Radio,
  VALIDATION_TYPES,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";

/**
 * *`VehicleVendorFiltersForm`*
 * : adds filtering to **`Vehicles`** component
 */
export default class VehicleVendorsFiltersForm extends BaseForm {
  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage("vehicles.filters.byNumber.title")}
            placeholder={getMessage("vehicles.filters.byNumber.placeholder")}
            name="number"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          />
          <Radio
            label={getMessage("vehicles.filters.byStatus.title")}
            name="status"
            options={[
              {
                text: getMessage("vehicles.filters.byStatus.label.all"),
                value: "",
              },
              {
                text: getMessage("vehicles.filters.byStatus.label.enabled"),
                value: "ENABLED",
              },
              {
                text: getMessage("vehicles.filters.byStatus.label.disabled"),
                value: "DISABLED",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              defaultValue: "",
            })}
          />
        </div>
        <SubmitButton>{getMessage("vehicles.filters.submitText")}</SubmitButton>
      </Form>
    );
  }
}
