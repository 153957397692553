import React, { Component } from "react";
import GoogleMaps, {
  Polygon,
  DrawingManager,
} from "../../../../components/GoogleMaps";
import { Marker, InfoWindow } from "react-google-maps";
import MarkerDefault from "./icon-marker-default.svg";
import MarkerOrange from "./icon-marker-orange.svg";
import MarkerSeagreen from "./icon-marker-seagreen.svg";
import MarkerDarkmagenta from "./icon-marker-darkmagenta.svg";
import MarkerDarkvoilet from "./icon-marker-darkvoilet.svg";
import MarkerMaroon from "./icon-marker-maroon.svg";
import { get } from "../../../../lib/storage";
import { isEqual } from "lodash";
import "./style.css";

const polygonOptions = {
  fillColor: "#7ac8ed",
  fillOpacity: 0.4,
  strokeOpacity: 0.8,
  strokeWeight: 1,
};

const radialOptions = {
  strokeOpacity: 0.8,
  strokeWeight: 2,
};

const apartmentMarkerColor = [
  MarkerOrange,
  MarkerSeagreen,
  MarkerDarkmagenta,
  MarkerDarkvoilet,
];

export function drawCircle(point, radius, dir, pts) {
  let d2r = Math.PI / 180; // degrees to radians
  let r2d = 180 / Math.PI; // radians to degrees
  let earthsradius = 6371000; // 6371000 is the radius of the earth in meters

  let points = pts || 40; // we will get 40 points that lies on circle's circumference

  // find the radius in lat/lng
  let rlat = (radius / earthsradius) * r2d;
  let rlng = rlat / Math.cos(point.lat * d2r);

  let extp = [];
  let start = points + 1;
  let end = 0;
  if (dir === 1) {
    start = 0;
    end = points + 1;
  }
  for (let i = start; dir === 1 ? i < end : i > end; i = i + dir) {
    let theta = Math.PI * (i / (points / 2));
    let ey = point.lng + rlng * Math.cos(theta); // center a + radius x * cos(theta)
    let ex = point.lat + rlat * Math.sin(theta); // center b + radius y * sin(theta)
    extp.push({ lat: ex, lng: ey });
  }
  return extp;
}

function formatDataForAllZones({ pincodeArea, radialArea, polygonalArea }) {
  const pincodeLocations = pincodeArea.map((zone) => ({
    pincodes: zone.area.pincodes,
    id: zone.id,
  }));

  const polygonLocationZones = polygonalArea.map((zone) => ({
    id: zone.id,
    name: zone.name,
    points: zone.area.locations?.map(({ latitude, longitude }) => ({
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    })),
  }));

  const radialZones = radialArea.map((zone) => ({
    id: zone.id,
    markerLocation: {
      lat: parseFloat(zone.area.latitude),
      lng: parseFloat(zone.area.longitude),
    },
    name: zone.name,
    startRadius:
      zone.area.startRadius * (zone.unit === "KILOMETRE" ? 1000 : 1609.34),
    endRadius:
      zone.area.endRadius * (zone.unit === "KILOMETRE" ? 1000 : 1609.34),
  }));

  return { pincodeLocations, polygonLocationZones, radialZones };
}

class MarkerWithInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.infoOpen || false,
    };
    this.closeInfoWindow = this.closeInfoWindow.bind(this);
    this.openInfoWindow = this.openInfoWindow.bind(this);
    this.handlePincodeClick = this.handlePincodeClick.bind(this);
  }

  closeInfoWindow() {
    this.setState({
      isOpen: false,
    });
  }

  openInfoWindow() {
    this.setState({
      isOpen: true,
    });
  }
  handlePincodeClick(groupId) {
    this.props.handlePincodeEdit(groupId);
  }
  render() {
    const { location, MarkerIcon } = this.props;
    return (
      <Marker
        icon={MarkerIcon}
        position={location.location}
        onClick={this.openInfoWindow}
      >
        {this.state.isOpen && location.label && (
          <InfoWindow onCloseClick={this.closeInfoWindow}>
            <div>
              <strong>{location.label}</strong>
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default class CommonMapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationsGroups: [],
      mapMounted: !!window.google,
    };
    this.polygonRef = null;
    this._isMounted = true;
    this.onMapMounted = this.onMapMounted.bind(this);
    this.getLatLngPoints = this.getLatLngPoints.bind(this);
    this.getDefaultLocation = this.getDefaultLocation.bind(this);
    this.setFitBounds = this.setFitBounds.bind(this);
    this.enableDrawingMode = this.enableDrawingMode.bind(this);
    ["onPolygonComplete", "setPolygonRef", "updatePolygon"].forEach((fn) => {
      this[fn] = this[fn].bind(this);
    });
  }

  getDefaultLocation(stores) {
    let defaultCenter = {};
    if (stores && get("store")) {
      let store = stores.filter(
        (store) => Number(store.id) === Number(get("store"))
      )[0];
      if (store && store.latitude && store.longitude) {
        defaultCenter = {
          lat: Number(store.latitude),
          lng: Number(store.longitude),
        };
      }
    }
    return defaultCenter;
  }
  onMapMounted(ref) {
    this.map = ref;
    this.setState({ mapMounted: true });
    if (this._isMounted && window.google) {
      this.setFitBounds(this.getLatLngPoints());
    }
  }

  onPolygonComplete(polygon) {
    let path = polygon.getPath();
    let paths = [];
    for (let i = 0; i < path.getLength(); i++) {
      let xy = path.getAt(i);
      paths.push({ lat: xy.lat(), lng: xy.lng() });
    }
    polygon.setMap(null);
    this.props.setNewLocations({ locations: paths });
  }

  getLatLngPoints() {
    const { storeId, pincodeArea, polygonalArea, radialArea, stores } =
      this.props;
    const { locationsGroups } = this.state;
    const { polygonLocationZones, radialZones } = formatDataForAllZones({
      pincodeArea,
      polygonalArea,
      radialArea,
    });

    const points = [];

    locationsGroups.forEach((pinCodeZone) => {
      pinCodeZone.locations.forEach((pincode) => {
        points.push({ lat: pincode.location.lat, lng: pincode.location.lng });
      });
    });
    polygonLocationZones.forEach((polygonZone) => {
      polygonZone.points.forEach((point) => {
        points.push(point);
      });
    });
    radialZones.forEach((zone) => {
      points.push(...drawCircle(zone.markerLocation, zone.endRadius, 1, 4));
      points.push(...drawCircle(zone.markerLocation, zone.endRadius, -1, 4));
    });

    stores
      .filter(
        (store) =>
          store.latitude &&
          store.longitude &&
          (!storeId || Number(storeId) === store.id)
      )
      .forEach((store) => {
        points.push({
          lat: Number(store.latitude),
          lng: Number(store.longitude),
        });
      });

    return points;
  }

  setFitBounds(points) {
    if (points && points.length > 0 && window.google) {
      let bounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < points.length; i++) {
        bounds.extend(points[i]);
      }
      this.map && this.map.fitBounds(bounds);
    }
  }

  geocodeAddress(pincodeLocations) {
    let geocoder = new window.google.maps.Geocoder();
    let promises = [];
    let locationsGroups = [];
    pincodeLocations &&
      pincodeLocations.forEach((pincodeGroup) => {
        let locations = [];
        pincodeGroup?.pincodes &&
          pincodeGroup.pincodes.forEach((pincode) => {
            let promise = new Promise((resolve, reject) => {
              geocoder.geocode(
                { address: pincode.pincode.toString() },
                (results, status) => {
                  if (status === window.google.maps.GeocoderStatus.OK) {
                    const location = results[0].geometry.location;
                    const lat = location.lat();
                    const lng = location.lng();
                    locations.push({
                      location: { lat, lng },
                      label: pincode.pincode,
                    });
                  }
                  resolve();
                }
              );
            });
            promises.push(promise);
          });
        locationsGroups.push({ id: pincodeGroup.id, locations: locations });
      });
    Promise.all(promises).then(() => {
      this.setState(
        {
          locationsGroups,
        },
        () => {
          this.setFitBounds(this.getLatLngPoints());
        }
      );
    });
  }
  updatePolygon(polygonPath) {
    let path = polygonPath;
    let paths = [];
    for (let i = 0; i < path.getLength(); i++) {
      let xy = path.getAt(i);
      paths.push({ lat: xy.lat(), lng: xy.lng() });
    }
    this.props.setNewEditedLocations({ locations: paths });
  }
  setPolygonRef(polygon) {
    if (polygon) {
      this.polygonRef = polygon.getPath();
    }
  }

  enableDrawingMode() {
    this.props.setShowDrawOption(false);
    this.setState({
      isDrawingEnabled: true,
    });
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;
    const editingCancel = prevProps.editing && !nextProps.editing;
    const prevPincodes = prevProps.pincodeArea;
    const nextPincodes = nextProps.pincodeArea;
    const nextPolygons = nextProps.polygonalArea;
    const prevPolygons = prevProps.polygonalArea;

    let pincodesChanged = !isEqual(prevPincodes, nextPincodes);
    let polygonChanged = !isEqual(prevPolygons, nextPolygons);

    let { pincodeLocations } = formatDataForAllZones({
      pincodeArea: nextPincodes,
      polygonalArea: nextPolygons,
      radialArea: nextProps.radialArea,
    });

    if (window.google && pincodesChanged) {
      this.geocodeAddress(pincodeLocations);
    }
    // if polygon changes dont re-calculate
    if (polygonChanged) {
      this.setFitBounds(this.getLatLngPoints());
    }
    if (editingCancel) {
      this.setFitBounds(this.getLatLngPoints());
    }
    if (prevProps.tempMarkers !== nextProps.tempMarkers) {
      if (nextProps.tempMarkers.length) {
        this.setFitBounds(nextProps.tempMarkers);
      } else {
        this.setFitBounds(this.getLatLngPoints());
      }
    } else {
      if (prevProps !== nextProps) {
        this.setFitBounds(this.getLatLngPoints());
      }
    }

    if (this.props.editing && this.polygonRef) {
      window.google.maps.event.addListener(this.polygonRef, "set_at", () => {
        this.updatePolygon(this.polygonRef);
      });
      window.google.maps.event.addListener(this.polygonRef, "insert_at", () => {
        this.updatePolygon(this.polygonRef);
      });
      window.google.maps.event.addListener(this.polygonRef, "remove_at", () => {
        this.updatePolygon(this.polygonRef);
      });
    }

    if (prevProps.editing && !nextProps.editing) {
      this.setState({
        isDrawingEnabled: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      pincodeArea,
      polygonalArea,
      radialArea,
      apartments,
      editing,
      distinctColors,
      storeId,
      tempMarkers,
      stores,
      editPolygon,
      showDrawOption,
    } = this.props;
    const { locationsGroups, isDrawingEnabled } = this.state;

    // fill colors
    const distinctColorsPolygon = distinctColors.slice(0, 5);
    const distinctColorsRadial = distinctColors.slice(5, 10);

    const defaultCenter = this.getDefaultLocation(stores);
    const allStoresLocations = stores
      .filter(
        (store) =>
          store.hasDeliveryHub &&
          store.latitude &&
          store.longitude &&
          (!storeId || Number(storeId) === store.id)
      )
      .map((store) => ({
        location: { lat: Number(store.latitude), lng: Number(store.longitude) },
        label: `Store: ${store.name}`,
      }));

    const { polygonLocationZones, radialZones } = formatDataForAllZones({
      pincodeArea,
      polygonalArea,
      radialArea,
    });

    // Newly added / edit in progress pincode marker
    const tempMarkersMarker = tempMarkers?.map((location) => (
      <Marker
        position={location}
        key={`${location.lat}-${location.lng}`}
        icon={MarkerDefault}
      />
    ));

    // Pincode markers
    const markers = [];

    // Pincode Markers
    locationsGroups.forEach((group, i) => {
      group.locations.forEach((location, index) => {
        markers.push(
          <MarkerWithInfo
            key={`pincode-${index}-${i}`}
            MarkerIcon={MarkerDefault}
            location={location}
          />
        );
      });
    });

    // Store Markers
    allStoresLocations.forEach((locationObj, index) => {
      markers.push(
        <MarkerWithInfo
          location={locationObj}
          key={`store-location-${index}`}
        />
      );
    });

    // Polygon area
    const polygons =
      polygonLocationZones.length > 0
        ? polygonLocationZones
            .filter(({ id }) => id !== editPolygon.id)
            .map((polygonZone, index) => {
              return (
                polygonZone.points.length > 0 && (
                  <Polygon
                    key={`polygonal${index}`}
                    path={polygonZone.points}
                    options={{
                      ...polygonOptions,
                      fillColor:
                        distinctColorsPolygon[
                          index % distinctColorsPolygon.length
                        ].value,
                    }}
                  />
                )
              );
            })
        : null;

    // Drawn polygon
    const drawnPolygon = editPolygon?.area ? (
      <Polygon
        key="drawn-polygonal-area"
        path={editPolygon?.area.locations?.map(({ latitude, longitude }) => ({
          lat: Number(latitude),
          lng: Number(longitude),
        }))}
        ref={(ref) => this.setPolygonRef(ref)}
        options={{
          ...polygonOptions,
          editable: editing,
          fillColor:
            distinctColors[polygonLocationZones.length % distinctColors.length]
              .value,
        }}
      />
    ) : null;

    // Radial polygon area
    const radialPolygons =
      radialZones.length > 0
        ? radialZones.map((radialZone, index) => {
            let center = radialZone.markerLocation || defaultCenter;
            let { startRadius, endRadius } = radialZone;

            return (
              <Polygon
                key={`radial${index}`}
                paths={[
                  drawCircle(center, endRadius, 1),
                  drawCircle(center, startRadius, -1),
                ]}
                defaultOptions={{
                  ...radialOptions,
                  strokeColor:
                    distinctColorsRadial[index % distinctColorsRadial.length]
                      .value,
                  fillColor:
                    distinctColorsRadial[index % distinctColorsRadial.length]
                      .value,
                }}
              />
            );
          })
        : null;

    // Radial Markers
    const radialMarkers = radialZones.map((radialZone, index) => (
      <MarkerWithInfo
        key={`radial-marker-${index}`}
        MarkerIcon={MarkerMaroon}
        location={{
          location: radialZone.markerLocation,
          label: radialZone.name,
        }}
      />
    ));

    // Apartment Markers
    const apartmentMarkers = apartments.reduce((acc, curr) => {
      let counter = acc.length;
      const buildings = curr.area?.building
        ?.filter(({ latitude, longitude }) => latitude && longitude)
        .map(({ latitude, longitude, name }) => ({
          location: { lat: latitude, lng: longitude },
          label: name,
        }));
      const markers = buildings?.map((building, index) => {
        const colorIndex = counter;
        counter++;
        return (
          <MarkerWithInfo
            key={`building-${index}-${building.label}`}
            MarkerIcon={
              apartmentMarkerColor[colorIndex % apartmentMarkerColor.length]
            }
            location={building}
          />
        );
      });
      return [...acc, ...markers];
    }, []);

    return (
      <React.Fragment>
        <GoogleMaps
          onMapMounted={this.onMapMounted}
          // center={this.getDefaultLocation(stores)}
          containerClassName="map-element"
          defaultOptions={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: true,
            fullscreenControlOptions: {
              position: 0,
            },
          }}
        >
          {isDrawingEnabled && (
            <DrawingManager
              defaultOptions={{
                drawingControl: true,
                drawingControlOptions: {
                  position: 2, // window.google.maps.ControlPosition.TOP_CENTER
                  drawingModes: ["polygon"],
                },
                polygonOptions: {
                  fillColor: "#7ac8ed",
                  fillOpacity: 0.4,
                  strokeOpacity: 0.8,
                  strokeWeight: 1,
                },
              }}
              onPolygonComplete={this.onPolygonComplete}
            />
          )}
          {markers.length > 0 && markers}
          {polygons}
          {drawnPolygon}
          {radialPolygons}
          {radialMarkers.length > 0 && radialMarkers}
          {tempMarkersMarker}
          {apartmentMarkers.length > 0 && apartmentMarkers}
        </GoogleMaps>
        {showDrawOption && (
          <button
            className="draw-btn"
            title="Edit"
            onClick={this.enableDrawingMode}
          >
            Draw on map
          </button>
        )}
      </React.Fragment>
    );
  }
}
