import React, { Component } from "react";
import {
  Input,
  Radio,
  Select,
  ProductSearch,
  CategorySearch,
  BrandSearch,
} from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";
import ItemTable from "./ItemTable";

class SF extends Component {
  constructor(props) {
    super(props);
    this.submitHandler = this.submitHandler.bind(this);
    this.validationHandler = this.validationHandler.bind(this);
  }

  validationHandler(parent) {
    const validations = JSON.parse(JSON.stringify(parent.state.validations));
    const formData = JSON.parse(JSON.stringify(parent.state.values));
    const comboDiscount = formData.itemDiscountType === "COMBO_DISCOUNT";
    const entityType = formData.entityType.toLowerCase();
    if (comboDiscount) {
      entityType === "product" &&
        (validations[entityType] || []).forEach((validation) => {
          delete validation.t;
          delete validation.v;
        });
    }
    if (!comboDiscount) {
      validations.rule && delete validations.rule;
    }
    switch (entityType) {
      case "product":
        delete validations.category;
        delete validations.brand;
        break;
      case "category":
        delete validations.product;
        delete validations.brand;
        break;
      case "brand":
        delete validations.product;
        delete validations.category;
        break;
      default:
        break;
    }
    parent.setState({
      validations: validations,
    });
  }

  submitHandler(formData) {
    const data = Object.assign({}, formData);
    const comboDiscount = formData.itemDiscountType === "COMBO_DISCOUNT";
    const entityType = formData.entityType.toLowerCase();
    let rule = {};
    if (!comboDiscount) {
      data.rule && delete data.rule.total;
    } else {
      rule.total = data.rule.total;
      rule.total.v = Number(rule.total.v);
    }
    delete data.total;
    rule.cartPrice = Number(data.cartPrice);
    delete data.cartPrice;
    rule.limit = data.rule ? Number(data.rule.limit) : null;
    rule.entity = {
      type: data.entityType === "PRODUCT" ? "VARIANT" : data.entityType,
      id: data.entityType === "PRODUCT" ? null : data[entityType].id, // TODO: change this for brands and category
    };
    let get = {};
    data.entityType === "PRODUCT" &&
      data[entityType].forEach((item) => {
        get[item.id] = {
          q: Number(item.q),
        };
        if (!comboDiscount) {
          get[item.id].t = item.t;
          get[item.id].v = (item.v && Number(item.v)) || 0;
        }
      });
    rule.get = get;
    data.rule = rule;
    delete data.product;
    delete data.category;
    delete data.brand;
    return data;
  }

  render() {
    let {
      entityType,
      discountTypes,
      itemDiscountTypes,
      _this,
      hasOfferStarted,
    } = this.props;
    const { values } = _this.state;
    const { itemDiscountType } = _this.state.values;
    entityType = this.props.entityTypeMap[entityType];
    const comboDiscount = itemDiscountType === "COMBO_DISCOUNT";
    const items = values[entityType];
    const itemsLength = items ? items.length : 0;
    return (
      <div className="offers-form">
        <div className="offer-fields">
          {entityType === "product" && (
            <Radio
              name="discount-type-main"
              label={getMessage("offer.main.discount.type")}
              placeholder={getMessage("offer.main.discouont.type.placeholder")}
              options={itemDiscountTypes}
              {..._this.generateStateMappers({
                stateKeys: ["itemDiscountType"],
                loseEmphasisOnFill: true,
              })}
              readOnly={hasOfferStarted}
            />
          )}
          {entityType === "category" && (
            <CategorySearch
              name="category-search"
              readOnly={hasOfferStarted}
              required
              label={getMessage(`offer.${entityType}`)}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              className="category-search"
              {..._this.generateStateMappers({
                stateKeys: [entityType],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {entityType === "brand" && (
            <BrandSearch
              name="brand-search"
              required
              readOnly={hasOfferStarted}
              label={getMessage(`offer.${entityType}`)}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              className="category-search"
              {..._this.generateStateMappers({
                stateKeys: [entityType],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <Input
            name="cart-price"
            type="number"
            step="0.01"
            min={0}
            required
            label={getMessage("offer.cartprice")}
            placeholder={getMessage("offer.cartprice.placeholder")}
            {..._this.generateStateMappers({
              stateKeys: ["cartPrice"],
              loseEmphasisOnFill: true,
            })}
            readOnly={hasOfferStarted}
          />
          {comboDiscount && (
            <React.Fragment>
              <Select
                name="discount-type"
                required
                label={getMessage("offer.discounttype")}
                placeholder={getMessage("offer.discounttype.placeholder")}
                options={discountTypes}
                {..._this.generateStateMappers({
                  stateKeys: ["rule", "total", "t"],
                  loseEmphasisOnFill: true,
                })}
                onChange={_this.handleDiscountChange}
                disabled={hasOfferStarted}
              />
              <Input
                name="discount-value"
                type="number"
                step={0.01}
                min={0}
                max={
                  values &&
                  values.rule &&
                  values.rule.total &&
                  values.rule.total.t === `PERCENT_OFF`
                    ? 100
                    : values && values.cartPrice
                }
                required
                label={getMessage("offer.discountvalue")}
                placeholder={getMessage("offer.discountvalue.placeholder")}
                {..._this.generateStateMappers({
                  stateKeys: ["rule", "total", "v"],
                  loseEmphasisOnFill: true,
                })}
                readOnly={
                  hasOfferStarted ||
                  (values &&
                    values.rule &&
                    values.rule.total &&
                    values.rule.total.t === `FREE`)
                }
              />
            </React.Fragment>
          )}
        </div>
        {entityType === "product" && (
          <React.Fragment>
            <h3 className="top-o">{getMessage("offer.get")}</h3>
            <h3 className="zero-font">smoe</h3>
            {/* {entityType === 'product' && <Searchable
          className='product-searchable-max'
          key={`item-searchable-pdt`}
          name={`item-searchable-pdt`}
          label={getMessage(`offer.${entityType}`)}
          placeholder={getMessage(`offer.${entityType}.placeholder`)}
          searchUrl='/catalogue-service/product'
          searchKey='name'
          valueKey='id'
          renderListElement={renderSearchableListElement}
          transformResponse={response => generateProducts(response.data.product)}
          onChange={_this.addItem}
        />} */}
            <ProductSearch
              required
              label={getMessage(`offer.${entityType}`)}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              name={`search-${entityType}-pdt`}
              key={`search-${entityType}-pdt`}
              className="product-searchable-max"
              onChange={_this.addItem}
              readOnly={hasOfferStarted}
            />
          </React.Fragment>
        )}
        <div>
          {itemsLength ? (
            <ItemTable
              comboDiscount={comboDiscount}
              entityType={entityType}
              items={items}
              discountTypes={discountTypes}
              _this={_this}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default SF;
