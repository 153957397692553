import React from "react";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import IntegrationSettingsForm from "./Form";
import { getMessage } from "../../../lib/translator";

const IntegrationSettings = (props) => (
  <AuthenticatedPage
    className="integration-settings"
    menu={props.menu}
    title={getMessage("settings.integrationSettings.heading")}
  >
    <h1 className="title">
      {getMessage("settings.integrationSettings.heading")}
    </h1>
    <IntegrationSettingsForm />
  </AuthenticatedPage>
);

export default IntegrationSettings;
