import React from "react";
import { BaseForm, Input, Select } from "../../../../../components/Form";
import { Dialog } from "../../../../../components/Popup";

import API from "../../../../../lib/api";
import { getMessage } from "../../../../../lib/translator";
import {
  getExtensionDetails,
  isExtensionEnabled,
} from "../../../../../lib/auth";
import {
  getStandradDateTime,
  localTimeToUtc,
  utcToLocalTime,
} from "../../../../../lib/datetime";

const slotTimes = () => {
  let timeStrings = [];
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      timeStrings.push(`0${i}:00:00`);
      timeStrings.push(`0${i}:30:00`);
    } else {
      timeStrings.push(`${i}:00:00`);
      timeStrings.push(`${i}:30:00`);
    }
  }
  return timeStrings;
};

class SlotConfig extends BaseForm {
  constructor(props) {
    super(props);
    this.state.showSuccessDialog = false;
    this.state.showErrorDialog = false;
    this.state.submitting = false;
    this.state.formError = "";

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    const id = this.state.data.id;
    const api = new API({ url: `/account-service/extension/${id}` });

    let params = {};
    params["status"] = this.state.data.status;
    params["config"] = {};
    for (let field in data) {
      let payloadData = data[field];
      if (field === "globalConfig") {
        if (payloadData?.businessOpeningTime) {
          payloadData.businessOpeningTime = getStandradDateTime(
            localTimeToUtc,
            `${new Date().toISOString().split("T")[0]} ${
              payloadData.businessOpeningTime
            }`
          ).split(" ")[1];
        }
        if (payloadData?.businessClosingTime) {
          payloadData.businessClosingTime = getStandradDateTime(
            localTimeToUtc,
            `${new Date().toISOString().split("T")[0]} ${
              payloadData.businessClosingTime
            }`
          ).split(" ")[1];
        }
      }
      params["config"][field] = payloadData;
    }

    return api
      .put(params)
      .then(
        (response) => {
          this.setState({
            formError: "",
            showSuccessDialog: true,
          });
        },
        (error) => {
          this.setState({
            formError: error.message,
            showErrorDialog: true,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.handleApi();
      });
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleSubmit(data).then(() => this.setState({ submitting: false }));
  }

  handleApi = () => {
    const id =
      isExtensionEnabled("DeliverySlots") &&
      getExtensionDetails("DeliverySlots").id;
    const api = new API({ url: `/account-service/extension/${id}` });
    id &&
      api
        .get()
        .then((response) => {
          const values = response.data.extension.config;
          if (values?.globalConfig) {
            if (values.globalConfig?.businessOpeningTime) {
              values.globalConfig.businessOpeningTime = getStandradDateTime(
                utcToLocalTime,
                `${new Date().toISOString().split("T")[0]} ${
                  values.globalConfig?.businessOpeningTime
                }`
              ).split(" ")[1];
            }
            if (values.globalConfig?.businessClosingTime) {
              values.globalConfig.businessClosingTime = getStandradDateTime(
                utcToLocalTime,
                `${new Date().toISOString().split("T")[0]} ${
                  values.globalConfig?.businessClosingTime
                }`
              ).split(" ")[1];
            }
          }
          this.setState({
            data: response.data.extension,
            values,
          });
        })
        .catch((error) => {
          console.error(error);
        });
  };
  componentDidMount() {
    this.handleApi();
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    return (
      <div className="slot-config-form">
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          title={getMessage("extensions.slot.success")}
          information={getMessage("extension.slots.saved")}
          close={() => this.setState({ showSuccessDialog: false })}
          closeText={getMessage("extensions.slot.okay")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          title={getMessage("extensions.slot.error")}
          information={this.state.formError}
          close={() => this.setState({ showErrorDialog: false, formError: "" })}
          closeText={getMessage("extensions.slot.okay")}
        />
        <Form>
          <div className="form-wrp">
            <Input
              type="number"
              name="noOfDays"
              min={1}
              label={getMessage("extensions.slot.numOfDays")}
              placeholder={getMessage("extensions.slot.numOfDays.placeholder")}
              {...this.generateStateMappers({
                stateKeys: ["globalConfig", "visibleDaysForSlot"],
                loseEmphasisOnFill: true,
              })}
            />
            <div className={"field"}>
              <span className={"labelWrap"}>
                <label htmlFor={"businessOpeningTime"} className={"thin"}>
                  {getMessage("extensions.slot.businessTime.heading")}
                </label>
              </span>
              <div className={"flex-input-fields"}>
                <Select
                  name="businessOpeningTime"
                  placeholder={getMessage(
                    "extensions.slot.businessOpeningTime"
                  )}
                  options={slotTimes()}
                  {...this.generateStateMappers({
                    stateKeys: ["globalConfig", "businessOpeningTime"],
                    loseEmphasisOnFill: true,
                  })}
                />
                <Select
                  name="businessClosingTime"
                  placeholder={getMessage(
                    "extensions.slot.businessClosingTime"
                  )}
                  options={slotTimes()}
                  {...this.generateStateMappers({
                    stateKeys: ["globalConfig", "businessClosingTime"],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            </div>
          </div>
          <SubmitButton disabled={this.state.submitting}>
            {getMessage("extensions.slot.save")}
          </SubmitButton>
        </Form>
      </div>
    );
  }
}

export default SlotConfig;
