import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Cell } from "../../../../components/Table";
import { Dialog, Popup } from "../../../../components/Popup";
import { DropDown, DropDownItem, ICONS } from "../../../../components/DropDown";
import {
  Email as CustomerEmailWidget,
  Call as CustomerCallWidget,
  Address as CustomerMapWidget,
} from "../../../../containers/CustomerWidgets";
import { getMessage } from "../../../../lib/translator";
import {
  getSession,
  isExtensionEnabled,
  hasPermissions,
} from "../../../../lib/auth";
import { getPrintableTime, formatTime } from "../../../../lib/datetime";
import { getAsapDuration } from "../../../../lib/commonlyused";
import { TABLE_ACTIONS } from "../../../../containers/ListingPage";
import OrderInvoice from "../../Orders/Details/OrderInvoice";
import FileForReturn from "../../Returns/Form/FileForReturn";
import CompletePickupPendingOrder from "../../Returns/Form/CompletePickupPending";
import OrderRegisterPayments from "../RegisterPayments";
import { Consumer } from "../index";
import pickupImage from "./pickup.svg";
import deliveyImage from "./delivery.svg";
import offlineImage from "./offline.svg";
import B2BImage from "./b2b.svg";

import Image from "../../../../components/Image";

const orderTypeImages = {
  PICKUP: pickupImage,
  DELIVERY: deliveyImage,
  B2B: B2BImage,
  OFFLINE: offlineImage,
};

const tripStatus = ["DISPATCHED", "COMPLETED", "RETURNED"];

class TableActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompleteDialog: false,
      showCancelDialog: false,
      showInvoice: false,
      printInvoice: false,
      showLog: false,
      showPickupPending: false,
      showMovePendingToPacked: false,
      showCompleteOrderPopup: false,
    };
    this.returnableStatuses = ["COMPLETED"];
    this.isInStoreEnabled = isExtensionEnabled("InStoreProcessing");
    this.isLogisticsEnabled = isExtensionEnabled("LogisticsSupport");
    this.returnableStatuses.push("PACKED");
    this.returnableStatuses.push("DISPATCHED");

    this.printInvoice = this.printInvoice.bind(this);
  }
  printInvoice() {
    this.setState((prevState) => {
      return {
        showInvoice: true,
        printInvoice: true,
      };
    });
  }

  render() {
    let {
      referenceNumber,
      status,
      onAction,
      type,
      paymentStatus,
      pendingAmount,
      clientId,
      url,
      returnsByRE,
    } = this.props;
    const productReturned = (returnsByRE && returnsByRE.length > 0) || false; // to know product returned or not
    let hasOrderEditPermissions = hasPermissions("order", "order", "PUT");
    let hasOrderPostPermissions = hasPermissions("order", "order", "post");
    let canBeCompleted =
      hasOrderEditPermissions && isExtensionEnabled("InStoreProcessing")
        ? isExtensionEnabled("LogisticsSupport")
          ? status === "DISPATCHED"
          : status === "PACKED"
        : isExtensionEnabled("LogisticsSupport")
        ? status === "DISPATCHED"
        : status === "PENDING";
    let canBePendingToPacked =
      hasOrderEditPermissions &&
      isExtensionEnabled("LogisticsSupport") &&
      !isExtensionEnabled("InStoreProcessing") &&
      status === "PENDING";
    let canBeReturned =
      hasOrderPostPermissions &&
      isExtensionEnabled("OrderReturns") &&
      this.returnableStatuses.indexOf(status) > -1;
    const canBeDisputed =
      isExtensionEnabled("OrderDisputes") && status === "COMPLETED";
    let canShowTrip =
      isExtensionEnabled("LogisticsSupport") &&
      tripStatus.indexOf(status) > -1 &&
      hasPermissions("logistics", "trip", "get") &&
      type.name === "DELIVERY";
    if (type && type.name === "PICKUP" && status === "PACKED") {
      canBeCompleted = true;
    }
    let canBeCancelled =
      (status === "PENDING" || status === "RETURNED") &&
      hasPermissions("order", "order", "put");
    let registerPayments =
      paymentStatus === "PENDING" &&
      (!isExtensionEnabled("LogisticsSupport") ||
        (type && type.name === "PICKUP"));
    const isStatusPacked = status === "PACKED" || false;
    const packedOrderEdit = this.props.packedOrderEdit || false;
    const fromOperations = this.props.url === "/operations";
    return (
      <div>
        <Dialog
          show={this.state.showMovePendingToPacked}
          title={getMessage("order.details.packed.title")}
          information={getMessage("order.details.packed.message")}
          onOk={() => {
            onAction(
              TABLE_ACTIONS.UPDATE,
              { referenceNumber },
              { status: "PACKED" }
            );
            this.setState({ showMovePendingToPacked: false });
          }}
          close={() => this.setState({ showMovePendingToPacked: false })}
          okText={getMessage("order.details.packed.confirmText")}
          closeText={getMessage("order.details.packed.cancelText")}
        />
        <Dialog
          show={this.state.showCompleteDialog}
          title={getMessage("order.details.completion.title")}
          information={getMessage("order.details.completion.message")}
          onOk={() => {
            onAction(
              TABLE_ACTIONS.UPDATE,
              { referenceNumber },
              { status: "COMPLETED" }
            );
            this.setState({ showCompleteDialog: false });
          }}
          close={() => this.setState({ showCompleteDialog: false })}
          okText={getMessage("order.details.completion.confirmText")}
          closeText={getMessage("order.details.completion.cancelText")}
        />
        <Dialog
          show={this.state.showCancelDialog}
          title={getMessage("order.details.cancellation.title")}
          information={getMessage("order.details.cancellation.message")}
          onOk={() => {
            onAction(
              TABLE_ACTIONS.UPDATE,
              { referenceNumber },
              { status: "CANCELLED" }
            );
            this.setState({ showCancelDialog: false });
          }}
          close={() => this.setState({ showCancelDialog: false })}
          okText={getMessage("order.details.cancellation.confirmText")}
          closeText={getMessage("order.details.cancellation.cancelText")}
        />
        {this.state.showMoveToPendingDialog && (
          <Dialog
            show={this.state.showMoveToPendingDialog}
            title={getMessage("order.details.pending.title")}
            information={getMessage("order.details.pending.message")}
            onOk={() => {
              onAction(
                TABLE_ACTIONS.UPDATE,
                { referenceNumber },
                { status: "PENDING" }
              );
            }}
            close={() => this.setState({ moveCancelledToPending: false })}
            okText={getMessage("order.details.cancellation.confirmText")}
            closeText={getMessage("order.details.cancellation.cancelText")}
          />
        )}
        {this.state.showInvoice && (
          <OrderInvoice
            referenceNumber={referenceNumber}
            show={this.state.showInvoice}
            close={() =>
              this.setState({ showInvoice: false, printInvoice: false })
            }
            print={this.state.printInvoice}
          />
        )}
        {this.state.showReturn && (
          <Popup
            show={this.state.showReturn}
            referenceNumber={referenceNumber}
            close={() => this.setState({ showReturn: false })}
            className="file-for-return"
            heading={`${getMessage("return.file.heading")} #${referenceNumber}`}
          >
            <FileForReturn
              referenceNumber={referenceNumber}
              closeReturnFilePopup={() => this.setState({ showReturn: false })}
              onAction={onAction}
              status={status}
            />
          </Popup>
        )}
        <Popup
          show={this.state.showCompleteOrderPopup}
          close={() => this.setState({ showCompleteOrderPopup: false })}
          heading={
            <>
              {getMessage("order.register.payments.for")}{" "}
              <span className="text-muted">{clientId ? "#" : "Z"}</span>
              {clientId || referenceNumber}{" "}
            </>
          }
        >
          <OrderRegisterPayments
            referenceNumber={referenceNumber}
            pendingAmount={pendingAmount}
            clientId={clientId}
            closePopup={() => this.setState({ showCompleteOrderPopup: false })}
          />
        </Popup>
        {this.state.showPickupPending && (
          <Popup
            show={this.state.showPickupPending}
            close={() => this.setState({ showPickupPending: false })}
            className="pickup-pending-form"
          >
            <CompletePickupPendingOrder
              productReturned={productReturned}
              referenceNumber={this.props.referenceNumber}
              redirectUrl={`${url}/orders/file-for-return/${referenceNumber}`}
              close={() => this.setState({ showPickupPending: false })}
              onComplete={() => {
                onAction(
                  TABLE_ACTIONS.UPDATE,
                  { referenceNumber },
                  { status: "COMPLETED" }
                );
                this.setState({
                  showPickupPending: false,
                });
              }}
              onPickupPendingComplete={(params) => {
                onAction(TABLE_ACTIONS.UPDATE, { referenceNumber }, params);
              }}
            />
          </Popup>
        )}
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`${url}/orders/${referenceNumber}`}>
              {getMessage("order.action.viewDetails")}
            </Link>
          </DropDownItem>
          <DropDownItem onClick={() => this.setState({ showInvoice: true })}>
            {getMessage("order.action.viewInvoice")}
          </DropDownItem>
          {canBeDisputed && (
            <DropDownItem>
              <Link to={`${url}/orders/dispute/${referenceNumber}`}>
                {getMessage("order.details.dispute.optionText")}
              </Link>
            </DropDownItem>
          )}
          {canShowTrip && (
            <DropDownItem>
              <Link to={`/logistics/trips?orderNumber=${referenceNumber}`}>
                {getMessage("order.actions.trips")}
              </Link>
            </DropDownItem>
          )}
          <DropDownItem onClick={this.printInvoice}>
            {getMessage("order.action.printInvoice")}
          </DropDownItem>
          {canBeCancelled && (
            <DropDownItem
              onClick={(e) => {
                this.setState({
                  showCancelDialog: true,
                });
              }}
            >
              {getMessage("order.action.cancel")}
            </DropDownItem>
          )}
          {canBePendingToPacked && (
            <DropDownItem
              onClick={(e) => {
                this.setState({
                  showMovePendingToPacked: true,
                });
              }}
            >
              {getMessage("order.action.packed")}
            </DropDownItem>
          )}
          {canBeCompleted &&
            (!registerPayments ? (
              <DropDownItem
                onClick={() => {
                  if (!isExtensionEnabled("OrderReturns")) {
                    this.setState({ showCompleteDialog: true });
                  } else {
                    this.setState({ showPickupPending: true });
                  }
                }}
              >
                {getMessage("order.action.complete")}
              </DropDownItem>
            ) : (
              <DropDownItem
                onClick={() => this.setState({ showCompleteOrderPopup: true })}
              >
                {getMessage("order.action.complete")}
              </DropDownItem>
            ))}
          {canBeReturned && (
            <DropDownItem>
              <Link to={`${url}/orders/file-for-return/${referenceNumber}`}>
                {getMessage("order.action.return")}
              </Link>
            </DropDownItem>
          )}
          <DropDownItem>
            <Link to={`${url}/orders/order-log/${referenceNumber}`}>
              {getMessage("order.actions.logs")}
            </Link>
          </DropDownItem>
          {isStatusPacked && packedOrderEdit && fromOperations && (
            <DropDownItem>
              <Link to={`orders/edit-packed/${referenceNumber}`}>
                {getMessage("order.action.editPackedOrder")}
              </Link>
            </DropDownItem>
          )}
        </DropDown>
      </div>
    );
  }
}

const tableHeader = (url) => {
  let headers = [
    getMessage("order.table.header.referenceNumber"),
    getMessage("order.table.header.customer"),
    getMessage("order.table.header.placedTime"),
    getMessage("order.filters.paymentStatus"),
    getMessage("operations.order.click.collect.customer.location"),
    getMessage("order.details.packing.heading"),
    getMessage("order.table.header.actions"),
  ];
  if (!isExtensionEnabled("DeliverySlots")) {
    return headers;
  } else {
    headers.splice(3, 0, getMessage("order.table.header.expectedTime"));
    return headers;
  }
};
const getCustomerLocationDistance = (customerLocation, countryCode) => {
  if (customerLocation?.distance) {
    if (customerLocation.distance > 500) {
      if (countryCode === "US") {
        return `${(customerLocation.distance * 0.000621371).toFixed(2)} mi`;
      } else {
        return `${(customerLocation.distance / 1000).toFixed(2)} km`;
      }
    } else return `${customerLocation.distance} m`;
  }
  return ``;
};
const tableProperties = (url, packedOrderEdit) => {
  return {
    headers: tableHeader(),
    row: ({
      items,
      slotType,
      clientId,
      referenceNumber,
      customer,
      address,
      completedAt,
      createdAt,
      invoiceAmount,
      itemCount,
      status,
      store,
      pickupLocation,
      pendingAmount,
      refundAmount,
      type,
      onAction,
      preferredDate,
      slotStartTime,
      slotEndTime,
      paymentStatus,
      fillRate,
      returnsByRE,
      packingDetails,
      id,
      customerLocation,
    }) => (
      <Consumer>
        {(value) => {
          const organization = (getSession() || {}).organization || {};
          let allowCall =
            value &&
            value.communication &&
            value.communication.allowCall !== false &&
            hasPermissions("communication", "call", "post");
          let allowEmail =
            value &&
            value.communication &&
            value.communication.allowEmail !== false &&
            hasPermissions("communication", "email", "post");
          return (
            <Row>
              <Cell className="column-order-number">
                <div className="container-order-number">
                  <div>
                    {type.name && (
                      <div className="order-type-icons">
                        <Image src={orderTypeImages[type.name]} />
                      </div>
                    )}
                  </div>
                  <div>
                    <div>
                      <Link
                        className="order-number"
                        to={
                          url
                            ? `${url}/orders/${referenceNumber}`
                            : `/operations/orders/${referenceNumber}`
                        }
                      >
                        {clientId ? (
                          <span>
                            <span className="text-muted prefix">
                              {" "}
                              {getMessage("order.table.clientId.prefix")}{" "}
                            </span>{" "}
                            {clientId}
                          </span>
                        ) : (
                          <span>
                            {" "}
                            <span className="text-muted prefix">
                              {" "}
                              {getMessage(
                                "order.table.referenceNumber.prefix"
                              )}{" "}
                            </span>{" "}
                            {referenceNumber}
                          </span>
                        )}
                      </Link>
                    </div>
                    {type.name === "PICKUP" ? (
                      <small className="text-muted store-name">
                        {pickupLocation && pickupLocation.name}
                      </small>
                    ) : (
                      store && (
                        <small className="text-muted store-name">
                          {store.name}
                        </small>
                      )
                    )}
                  </div>
                </div>
              </Cell>
              <Cell className="column-customer-name">
                <div className="cx-name-text">
                  {customer ? (
                    <Link
                      className="customer-name"
                      to={
                        url
                          ? `${url}/customers/view/${customer.id}`
                          : `/operations/customers/view/${customer.id}`
                      }
                    >
                      {customer.name}
                    </Link>
                  ) : null}
                </div>
                <div className="customer-actions">
                  {address && (
                    <CustomerMapWidget
                      address={
                        type.name === "DELIVERY"
                          ? address.address +
                            ", " +
                            address.city +
                            ", " +
                            address.pincode
                          : pickupLocation
                          ? pickupLocation.address
                          : ""
                      }
                    />
                  )}
                  {allowEmail &&
                    customer &&
                    customer.emails &&
                    !!customer.emails.length && (
                      <CustomerEmailWidget
                        emails={customer.emails.slice(0, 1)}
                        showOptions={false}
                      />
                    )}
                  {allowCall &&
                    customer &&
                    customer.phones &&
                    !!customer.phones.length && (
                      <CustomerCallWidget
                        phones={customer.phones.slice(0, 1)}
                        showOptions={false}
                      />
                    )}
                </div>
              </Cell>
              <Cell className="column-creation-time">
                <div>
                  {createdAt
                    ? getPrintableTime(createdAt).split(", ")[0]
                    : null}
                </div>
                <small className="text-muted">
                  {createdAt
                    ? getPrintableTime(createdAt).split(", ")[1]
                    : null}
                </small>
              </Cell>
              {isExtensionEnabled("DeliverySlots") ? (
                <Cell className="column-creation-time">
                  <div>
                    {preferredDate
                      ? getPrintableTime(preferredDate).split(", ")[0]
                      : null}
                  </div>
                  <small
                    className={`text-muted ${
                      !slotEndTime ? "zero-font" : ""
                    }`.trim()}
                  >
                    {slotStartTime && slotEndTime && slotType !== "ASAP"
                      ? formatTime(slotStartTime) +
                        " - " +
                        formatTime(slotEndTime)
                      : getAsapDuration(slotStartTime, slotEndTime)}
                  </small>
                </Cell>
              ) : null}
              <Cell className="column-payment-status">
                <div>{paymentStatus}</div>
                {pendingAmount && (
                  <small className="text-muted pending-amount">{`${
                    organization?.currency?.symbol ?? ""
                  }${pendingAmount}`}</small>
                )}
              </Cell>
              <Cell className="column-customer-location">
                {getCustomerLocationDistance(
                  customerLocation,
                  organization?.countryCode
                )}
              </Cell>
              <Cell className="column-packing-details">
                <div className="order-list-packing-details-wrp">
                  {packingDetails.map((packingDetail) => {
                    const { orderId, details, id: packingId } = packingDetail;
                    return orderId === id ? (
                      <div key={packingId}>
                        {Object.keys(details).map((detail) => (
                          <div
                            key={detail}
                            className="order-list-packing-details"
                          >
                            <div className="order-list-packing-type">
                              {detail}
                            </div>
                            {`: `}&nbsp;
                            <div>{details[detail].join(", ")}</div>
                          </div>
                        ))}
                      </div>
                    ) : null;
                  })}
                </div>
              </Cell>
              <Cell className="column-order-actions">
                <TableActions
                  status={status}
                  clientId={clientId}
                  referenceNumber={referenceNumber}
                  onAction={onAction}
                  type={type}
                  paymentStatus={paymentStatus}
                  pendingAmount={pendingAmount}
                  url={url || "/operations"}
                  packedOrderEdit={packedOrderEdit}
                  returnsByRE={returnsByRE}
                />
              </Cell>
            </Row>
          );
        }}
      </Consumer>
    ),
  };
};

export default tableProperties;

export { tripStatus };
