import React from "react";

export default function ShowPassword() {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none">
        <path d="M0 0h24v24H0z" />
        <g transform="translate(4 6)" stroke="#181912">
          <circle cx="9" cy="5" r="1.5" />
          <path d="m16 4.167-3.075-1.709a8.431 8.431 0 0 0-8.675.292L1.36 4.62a1 1 0 0 0 .036 1.701L4.25 8a8.223 8.223 0 0 0 8.457-.071l3.33-2.035A1 1 0 0 0 16 4.167Z" />
        </g>
      </g>
    </svg>
  );
}
