import React, { Component } from "react";

import "./style.css";

export default class StyledSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.toggle = this.toggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }
  toggle(e) {
    e && e.preventDefault();
    this.setState({
      expanded: !this.state.expanded,
    });
  }
  handleClick(e) {
    if (this.dropdownRef.contains(e.target)) {
      if (this.toggleRef.contains(e.target)) {
        // The toggle handle was clicked
        this.toggle();
      } else if (e.target.classList.contains("dropdown-item")) {
        // An item was clicked
        this.setState({ expanded: false });
      }
    } else if (this.state.expanded) {
      // Click was outside the dropdown. Close the menu if it was open
      this.setState({ expanded: false });
    }
  }
  selectOption(value) {
    this.props.onChange && this.props.onChange(value);
    this.setState({ expanded: false });
  }
  componentDidMount() {
    window.addEventListener("click", this.handleClick);
  }
  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick);
  }
  render() {
    let { options = [], value } = this.props;
    let selected = options.find((option) => option.value === value);
    return (
      <div
        className={`StyledSelect ${this.props.className || ""} ${
          this.state.expanded ? "expanded" : ""
        }`.trim()}
        ref={(dropdownRef) => {
          this.dropdownRef = dropdownRef;
        }}
      >
        <div
          className="container"
          ref={(toggleRef) => {
            this.toggleRef = toggleRef;
          }}
        >
          {selected ? selected.content : this.props.placeholder}
        </div>
        <div
          className={`dropdown ${!this.state.expanded ? "hidden" : ""}`.trim()}
        >
          {options.map(({ content, value }) => (
            <div
              className="dropdown-item"
              key={value}
              onClick={() => {
                this.selectOption(value);
              }}
            >
              {content}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
