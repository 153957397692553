import React, { Component } from "react";
import { format, parse } from "libphonenumber-js";
import ListingPage, { TABLE_ACTIONS } from "../../../containers/ListingPage";
import { getMessage } from "../../../lib/translator";
import { Row, Cell } from "../../../components/Table";
import Image from "../../../components/Image";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { checkIn, checkOut } from "../../hr/UserShifts";
import { Link } from "react-router-dom";
import settingImage from "../PickersToday/svg/settings-inactive.svg";

import { formatTime, getPrintableTime } from "../../../lib/datetime";

import PickerForm from "./Form";
import PickerFilter from "./Filters";

import emptyStateIcon from "./picker-empty.svg";

import "./style.css";
import { Dialog } from "../../../components/Popup";
import {
  getStandradDateTime,
  utcToLocalTime,
  localTimeToUtc,
} from "../../../lib/datetime";
import checkHttpResponseCode from "../../../utils/isApiCallSuccessfull";

const emptyState = {
  icon: emptyStateIcon,
  message: getMessage("pickers.emptyMessage"),
  actions: () => (
    <Link to="/operations/pickers-today" className="primary button">
      <img src={settingImage} className="setting-image" alt="settings" />{" "}
      <span className="text">{getMessage("pickers.today.title")}</span>
    </Link>
  ),
};

class AttendanceButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      showErrorDialog: false,
    };
  }

  render() {
    const { onAction, id, isCheckedIn, attendance } = this.props;
    return (
      <React.Fragment>
        {this.state.showErrorDialog && (
          <Dialog
            show={this.state.showErrorDialog}
            title={getMessage("picker.dialog.error")}
            information={this.state.errorMessage}
            closeText={getMessage("picker.dialog.okText")}
            close={() => this.setState({ showErrorDialog: false })}
          />
        )}
        {isCheckedIn ? (
          <React.Fragment>
            <div className="text-muted">
              {getMessage("employee.checkedInAt") +
                ": " +
                getPrintableTime(attendance.createdAt)}
            </div>
            <button
              type="button"
              disabled={this.state.submitting}
              className="button attendance-button"
              onClick={() => {
                this.setState({
                  submitting: true,
                });
                checkOut(attendance.id, id).then((response) => {
                  if (response.code && !checkHttpResponseCode(response.code)) {
                    this.setState({
                      errorMessage: response.message
                        .split(":")
                        .slice(1)
                        .join(" "),
                      showErrorDialog: true,
                    });
                  } else {
                    onAction(TABLE_ACTIONS.REFRESH, { id });
                  }
                  this.setState({ submitting: false });
                });
              }}
            >
              {getMessage("shifts.checkout")}
            </button>
          </React.Fragment>
        ) : !isCheckedIn ? (
          <button
            type="button"
            disabled={this.state.submitting}
            className="button primary attendance-button"
            onClick={() => {
              this.setState({
                submitting: true,
              });
              checkIn(id).then((response) => {
                if (response.code && !checkHttpResponseCode(response.code)) {
                  this.setState({
                    errorMessage: response.message,
                    showErrorDialog: true,
                  });
                } else {
                  onAction(TABLE_ACTIONS.REFRESH, { id });
                }
                this.setState({ submitting: false });
              });
            }}
          >
            {getMessage("shifts.checkin")}
          </button>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export const tableProperties = {
  headers: [
    getMessage("picker.name"),
    getMessage("picker.offDays"),
    getMessage("picker.timingsAndAttendance"),
    getMessage("picker.attendance"),
    getMessage("picker.actions"),
  ],
  row: ({
    id,
    name,
    shiftStart,
    shiftEnd,
    imageUrl,
    weeklyOff,
    phone,
    isCheckedIn,
    hasCheckedInForDay,
    attendance,
    status,
    onAction,
  }) => (
    <Row>
      <Cell className="column-user-name">
        <Image src={imageUrl} size="sm" />
        <div className="user-details">
          <div
            className="user-name"
            onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}
          >
            {name}
          </div>
          <small className="text-muted">
            {phone &&
              phone.phone &&
              (Object.keys(parse(phone.phone)).length > 0
                ? format(parse(phone.phone), "International")
                : phone.phone)}
          </small>
        </div>
      </Cell>
      <Cell className="user-off-days">
        {weeklyOff && weeklyOff.length > 0 && (
          <div className="weekly-off-days">
            {weeklyOff.map((day) => `${getMessage(day)}`).join(", ")}
          </div>
        )}
      </Cell>
      <Cell className="user-timings">
        {shiftStart && shiftEnd ? (
          <div className="timings">
            {formatTime(`${shiftStart}`)} - {formatTime(shiftEnd)}
          </div>
        ) : null}
        {isCheckedIn ? (
          <div className="text-muted check-inTime">
            {getPrintableTime(attendance.createdAt)}
          </div>
        ) : null}
      </Cell>
      <Cell className="user-attendance">
        <AttendanceButton
          onAction={onAction}
          id={id}
          attendance={attendance}
          isCheckedIn={isCheckedIn}
        />
      </Cell>
      <Cell className="user-actions">
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}>
            {getMessage("picker.edit")}
          </DropDownItem>
        </DropDown>
      </Cell>
    </Row>
  ),
};

class Pickers extends Component {
  render() {
    const { props } = this;
    return (
      <ListingPage
        className="pickers-page"
        menu={props.menu}
        title={getMessage("pickers.title")}
        api={{
          url: "/account-service/employee",
          params: {
            roleId: 1,
          },
          transform: (response) => {
            const employees = response.data.employee;
            if (Array.isArray(employees)) {
              employees.map((employee) => {
                if (employee?.attendance?.createdAt) {
                  if (employee.attendance.createdAt.includes("T")) {
                    const newDate = employee.attendance.createdAt.split(/[TZ]/);
                    employee.attendance.createdAt = `${newDate[0]} ${newDate[1]}`;
                  }
                  employee.attendance.createdAt = getStandradDateTime(
                    utcToLocalTime,
                    employee.attendance.createdAt
                  );
                }
                if (employee.shiftStart) {
                  employee.shiftStart = getStandradDateTime(
                    utcToLocalTime,
                    `${new Date().toISOString().split("T")[0]} ${
                      employee.shiftStart
                    }`
                  ).split(" ")[1];
                }
                if (employee.shiftEnd) {
                  employee.shiftEnd = getStandradDateTime(
                    utcToLocalTime,
                    `${new Date().toISOString().split("T")[0]} ${
                      employee.shiftEnd
                    }`
                  ).split(" ")[1];
                }
                return employee;
              });
            } else {
              if (employees?.attendance?.createdAt) {
                if (employees.attendance.createdAt.includes("T")) {
                  const newDate = employees.attendance.createdAt.split(/[TZ]/);
                  employees.attendance.createdAt = `${newDate[0]} ${newDate[1]}`;
                }
                employees.attendance.createdAt = getStandradDateTime(
                  utcToLocalTime,
                  employees.attendance.createdAt
                );
              }
              if (employees.shiftStart) {
                employees.shiftStart = getStandradDateTime(
                  utcToLocalTime,
                  `${new Date().toISOString().split("T")[0]} ${
                    employees.shiftStart
                  }`
                ).split(" ")[1];
              }
              if (employees.shiftEnd) {
                employees.shiftEnd = getStandradDateTime(
                  utcToLocalTime,
                  `${new Date().toISOString().split("T")[0]} ${
                    employees.shiftEnd
                  }`
                ).split(" ")[1];
              }
            }
            return employees;
          },
        }}
        headerActions={() => (
          <Link to="/operations/pickers-today" className="primary button">
            <img src={settingImage} className="setting-image" alt="settings" />{" "}
            <span className="text">{getMessage("pickers.today.title")}</span>
          </Link>
        )}
        editHeading={getMessage("picker.editHeading")}
        modalClassName="picker-form"
        storeDependent
        emptyState={emptyState}
        tableProperties={tableProperties}
        form={{
          component: PickerForm,
          transformSubmit: (formData) => {
            let picker = Object.assign({}, formData);
            if (picker.shiftStart) {
              picker.shiftStart = getStandradDateTime(
                localTimeToUtc,
                `${new Date().toISOString().split("T")[0]} ${
                  picker.shiftStart
                }:00`
              ).split(" ")[1];
            }
            if (picker.shiftEnd) {
              picker.shiftEnd = getStandradDateTime(
                localTimeToUtc,
                `${new Date().toISOString().split("T")[0]} ${
                  picker.shiftEnd
                }:00`
              ).split(" ")[1];
            }
            return picker;
          },
        }}
        filters={{
          component: PickerFilter,
        }}
      />
    );
  }
}

export default Pickers;

export { AttendanceButton };
